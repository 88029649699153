import { stubTrue } from 'lodash';
import { AccountingConfig } from './accountingConfig';
import { CostCenterConfig } from './costCenterConfig';
import { Dropdown } from './dropdown';

export class DistributionModalParam {
  constructor(
    // disabled/ enable
    public IsDisabled: boolean,

    // contexto (order/invoice/outro)
    public Context: string,

    // ID do context (order/invoice/outro)
    public EntityID: number,

    // contexto (order/invoice)
    public List: Array<CostCenterConfig>,

    // guardar com o objeto a que vem associado ou diretamente quando se fecha a modal da distribuição, por defeito guardar no objeto
    public SaveOnServer: boolean,

    // Valor total sem IVA
    public DefaultValue: number,

    // Tipo documentos
    public DocumentType: string,

    // Listas
    public TypeList: Array<Dropdown>,
    public DebitCreditList: Array<Dropdown>,
    public IncidenceList: Array<Dropdown>,
    public AnalisysAxesList: Array<any>,
    public DivisionKeysList: Array<any>,
    public AllCostCentersList: Array<any>,

    public AccountingConfigs?: Array<AccountingConfig>,

    public showDistributionExpenseAccount: boolean = true,
    public showDistributionInitiativeCode: boolean = true,
    public showDistributionActivityCode: boolean = true,
    public showDistributionFixedAssetRecordType: boolean = true,
    public showDistributionAmortizationBookCode: boolean = true,
    public showDistributionVATaccountingGroup: boolean = true,
    public showDistributionRefactor: boolean = true,
    public showDistributionAccountingType: boolean = true,
    public showDistributionVatTreatmentType: boolean = true,
    public showDistributionCodMensualizacao: boolean = true,

    public enableSaveButton: boolean = false,
    public canChangeExpenseAccountTaxGroup: boolean = false,
    public showRetentionCode: boolean = false,
    public showAccountingVatGroup: boolean = true,

    public invoiceYear: number = null,
    public productID: number = null,
    public tipologiaProdutoServicoID: number = null,
    public vatRate: number = null,
    public showLoadAccountingButton: boolean = false,
    public validateFreeTextMaxLength: boolean = false,
    public enableSavingWhenChangingForm: boolean = false

  ) { }
}