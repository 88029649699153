import { Directive, Optional, HostListener, ElementRef } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-select, mat-option'
})
export class MatSelectTooltipDirective {
  constructor(@Optional() private formControl: NgControl,
    public el: ElementRef) { }

  @HostListener('mouseover', ['$event']) mouseover(event: any) {
    // mat-select se passarmos com o rato por cima do text o event.path[0] é outra coisa qualquer
    // o event.path[0] so fica o select se o rato passar em cima das reticências "..."
    if (event.path && ((event.path[0].clientWidth < event.path[0].scrollWidth) || (event.path[2] && event.path[2].clientWidth < event.path[2].scrollWidth))) {
      this.el.nativeElement.title = event.target.innerText;
    } else {
      this.el.nativeElement.title = '';
    }
  }
}
