import { Injectable } from '@angular/core';

@Injectable()
export class DataTableFunctions {

  constructor() { }

  /**
   * para construir o array de indexes das colunas que a datatable tem de colocar como invisiveis
   * @param  {Array<number>} defaultValue - valor por defeito do parametro (valor que é enviado para o servico)
   * @param  {Array<string>} columnsNames - nomes das colunas (valor que é enviado para o servico)
   * @param  {string} settingValue - valor que é retornado do getSettingPortal ex: this._authenticationService.getSettingPortal('InvoicesSales', 'ColumVisible');
   * @returns Array
   */
  getInvColumns(defaultValue: Array<number>, columnsNames: Array<string>, settingValue: string): Array<number> {
    let arrayInVisivel = Array.apply(null, { length: columnsNames.length }).map(Number.call, Number); // criar um array [0,1,2...] com a length do array do nome das colunas
    if (settingValue) {
      let columns = settingValue.split(','), retirados = 0;
      for (let column of columns) {
        arrayInVisivel.splice(columnsNames.indexOf(column.replaceAll(' ', '')) - retirados, 1); // retirar do array aquele index que encontrou no array das colunas visiveis (para ficar correto o index tem de ter em conta os que já foram retirados)
        retirados++;
      }
      return arrayInVisivel;
    } else {
      return defaultValue;
    }
  }

  getVisibleColumns(defaultValue: Array<number>, columnsNames: Array<string>, settingValue: string): Array<number> {
    let arrayVisivel = [];
    if (settingValue) {
      let columns = settingValue.split(',');
      for (let column of columns) {
        arrayVisivel.push(columnsNames.indexOf(column.replaceAll(' ', ''))); // adicionar ao array aquele index que encontrou no array das colunas visiveis 
      }
      return arrayVisivel;
    } else {
      return defaultValue;
    }
  }
}
