import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelValidators } from '../../models/validators/validators';
import { AppConfig } from '../../configs/app.config';
import { SUPPORT_EMAIL, PORTAL_NAME } from 'src/app/constants/global';
import { Subject } from 'rxjs';

declare var App: any;

@Component({
  templateUrl: './recovery.html',
  styles: [
    // Formulário
    '.login-container .login-columns { margin: 50px auto; display: flex; }',
    '.login-container .slideshow { margin: auto; width: 500px; display: inline-block; }',
    '.login-container .block-flat { margin: 0; border-radius: 0; box-shadow: none; }',
    // '.login-container .block-flat .content { height: 369px; }',
    '.login-container .login-form { margin: auto 20px auto auto; display: inline-block; }',
    '@media screen and (max-width: 965px) { .login-container .login-columns { display: block; } .login-container .login-form { display: block; margin: auto auto 20px; } .login-container .slideshow {display: block; margin: auto auto 20px; width: auto; } .width-950, .width-450 { width: auto; } }',
    '.ngucarousel > .ngu-touch-container > .ngucarousel-items > .item { padding: 0 !important; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RecoveryComponent implements OnInit, AfterViewInit, OnDestroy {
  SUPPORT_EMAIL = SUPPORT_EMAIL;
  destroy$: Subject<boolean> = new Subject<boolean>();
  PORTAL_NAME = PORTAL_NAME;
  year: number = (new Date()).getFullYear();
  step: number = 0;
  email: string;
  form: UntypedFormGroup;
  formAllErrors: string = '';
  formErrors = {
    'email': ''
  };
  validationMessages: any;
  message: string;

  // Configurações
  loginFooterText: string;
  loginFooterUrl: string;

  logoUrl: string = '';

  constructor(private activatedRoute: ActivatedRoute, private renderer: Renderer2, private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder, private translateService: TranslateService, private config: AppConfig) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'texture');

    // Footer
    this.loginFooterText = this.config.getLoginFooterText();
    this.loginFooterUrl = this.config.getLoginFooterUrl();

    let id: string = this.activatedRoute.snapshot.queryParams['ID'];
    let locale: string = this.activatedRoute.snapshot.queryParams['locale'];

    if (id) {
      this.authenticationService.resetPassword(id, locale).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.message = response.ReturnStatus.Successfull ? 'RECOVERY_SUCCESS' : 'RECOVERY_FAIL';
        this.step = 3;
      });
    } else {
      this.translateService.get(['ISEMPTY', 'EMAIL_INVALID']).subscribe(response => {
        this.validationMessages = {
          'email': {
            'required': response.ISEMPTY,
            'email': response.EMAIL_INVALID
          }
        };
      });

      this.buildForm();
    }
  }

  public ngAfterViewInit(): void {
    App.init();
    let logoImg = this.config.getConfig('LOGO_IMG');
    //document.querySelector('.login-theme .logo-img').setAttribute('src', (document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg));
  
    this.logoUrl = document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg;
  }

  buildForm(): void {
    this.step = 1;

    this.form = this.formBuilder.group({
      'email': [this.email, [Validators.required, ModelValidators.emailFormat]]
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onValueChanged(data?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.email = this.form.value.email;

    this.authenticationService.requestPasswordReset(this.email).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.ReturnStatus.Successfull) {
        this.step = 2;
      } else {
        this.translateService.get('AUTHENTICATION_INVALID').subscribe(translation => this.formAllErrors = translation);
      }
    });
  }

  _onContentChanges() {
    return;
  }

  ngOnDestroy() { }
}
