import { Optional } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CURRENCY_DECIMAL } from '../constants/global';

export class CostCenterConfigList {
  constructor(
    @Optional() public Details: CostCenterConfig[] = null
  ) { }
}

export class CostCenterConfig {
  constructor(
    public ID: number = 0, // não permite nulls
    public Context: string = '', // não permite nulls
    public Type: string = 'CostCenter', // não permite nulls - por defeito CostCenter
    public EntityID: number = 0, // não permite nulls
    @Optional() public CostCenterID: number = null,
    @Optional() public DivisionKeyID: number = null,
    @Optional() public Percentage: number = null,
    @Optional() public FixedValue: number = null,
    public DebitOrCredit: boolean = false,
    public Incidence: string = '1',
    @Optional() public IntegrationID: string = null,
    @Optional() public IsCostCenterFreeText: boolean = null,
    @Optional() public AnalisysAxeID: number = null,

    // *********** VARIAVEIS DO FORM (AUXILIARES) ***********
    @Optional() public Percentage_form: string = null,
    @Optional() public FixedValue_form: string = null

  ) { }
}

export class TransformModelCC {

  transformObject(costCenterObject: CostCenterConfig = null, translLanguage: string = 'pt') {
    let decimalPipe = new DecimalPipe(translLanguage);

    costCenterObject.Percentage_form = costCenterObject.Percentage ? decimalPipe.transform(costCenterObject.Percentage, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    costCenterObject.FixedValue_form = costCenterObject.FixedValue ? decimalPipe.transform(costCenterObject.FixedValue, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;

    return costCenterObject;
  }
  revertObjects(costCenterObjs: any = null): CostCenterConfig[] {
    if (costCenterObjs && costCenterObjs.length > 0) {
      costCenterObjs.forEach((cc: any) => {
        cc = this.revertObject(cc);
      });
    }
    return costCenterObjs;
  }
  revertObject(costCenterObj: any = null) {
    if (!costCenterObj) {
      return;
    }
    let costCenterObject: CostCenterConfig = costCenterObj;

    // percentagem
    costCenterObject.Percentage = costCenterObj.Percentage !== null ? ((costCenterObj.Percentage).toString()).revertDecimal() : null;
    // valor fixo
    costCenterObject.FixedValue = costCenterObj.FixedValue !== null ? ((costCenterObj.FixedValue).toString()).revertDecimal() : null;

    return costCenterObject;
  }
}
