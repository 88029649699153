import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { Task } from '../models/task';
import { BillingArea } from '../models/billing-area';
import { BillingAreaSeries } from '../models/billing-area-series';

@Injectable()
export class BillingAreaService{
    private _controller = "BillingArea"
    public _billingAreaSeries: BillingAreaSeries[];
    datatableParametersBillingArea: DatatableParameters = new DatatableParameters([null, null, null], 10, [[1, 'asc']], 0);
    constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions){

    }

    getAll(): Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL + this._controller + '/GetAllForDatatable').pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    get(id:number): Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    getInvoicesTypes():Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL  + 'Invoice/GetTypes').pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    get Areas(): Observable<BillingArea[]> {
        return this.http.get(SERVICE_URL + this._controller + '/GetAreas').pipe(
            map(response => {
                try {
                    let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                    if (responseReturn.ReturnStatus.Successfull) {
                        return responseReturn.ReturnStatus.ReturnObject as BillingArea[];

                    } else {
                        this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                    }
                } catch (error) {
                    this._masterService.handleError(error);
                }
            }));
    }

    get Series(): Observable<BillingAreaSeries[]> {
        return this.http.get(SERVICE_URL + this._controller + '/GetSeries').pipe(
            map(response => {
                try {
                    let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                    if (responseReturn.ReturnStatus.Successfull) {
                        return responseReturn.ReturnStatus.ReturnObject as BillingAreaSeries[];

                    } else {
                        this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                    }
                } catch (error) {
                    this._masterService.handleError(error);
                }
            }));
    }

    delete(id:number):Observable<ReturnStatusHtml>{
        return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    add(model: BillingArea): Observable<ReturnStatusHtml> {
        let formData : FormData = new FormData();

        formData.append('billingArea', JSON.stringify(model));
        
        return this.http.put(SERVICE_URL + this._controller + '/add', formData).pipe(map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    update(model: BillingArea): Observable<ReturnStatusHtml>{
        let formData: FormData = new FormData();

        formData.append('billingArea', JSON.stringify(model));
        

        return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

}