import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ModelValidators } from '../../../models/validators/validators';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  templateUrl: './workflow-emails-modal.html'
})
export class WorkflowEmailsModalComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<WorkflowEmailsModalComponent>, private dialog: MatDialog, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      emails: this.formBuilder.array([this.formBuilder.group({
        email: [null, ModelValidators.emailFormat]
      })])
    });
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    // Lista de endereços a enviar email
    let emailTo = null;
    if (this.form.get('emails')) {
      const control = <UntypedFormArray>this.form.controls['emails'];
      let emails: string[] = [];
      control.value.forEach(element => {
        if (element.email && emails.indexOf(element.email) === -1) {
          emails.push(element.email);
        }
      });

      // Juntar os emails separados por ;
      emailTo = emails.join(';');
    }

    this.dialogRef.close({
      emailTo: emailTo
    });
  }

  addEmail() {
    const control = <UntypedFormArray>this.form.controls['emails'];

    control.push(this.formBuilder.group({
      email: [null, ModelValidators.emailFormat]
    }));
  }

  deleteEmail(index: number) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const control = <UntypedFormArray>this.form.controls['emails'];

        control.removeAt(index);
      }
    });
  }
}
