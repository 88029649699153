import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Cookie } from 'ng2-cookies';
import { Login } from '../../models/login';
import { RETURN_URL, LANGUAGE, SUPPORT_EMAIL, PORTAL_NAME, HIDE_PORTAL_NAME_LOGIN } from '../../constants/global';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Session } from '../../models/session';
import { MessageModalComponent } from '../shared/message-modal/message-modal.component';
import { AppConfig } from '../../configs/app.config';
import { TranslateValueService } from '../../services/translate-value.service';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { Language } from 'src/app/models/language';
import { CommonService } from 'src/app/services/common.service';
import { News } from 'src/app/models/news';
import { NguCarouselConfig } from '@ngu/carousel';
import { ErrorTreatmentFunctions } from 'src/app/modules/treatments.module';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';

declare var App: any;
declare var Functions: any;

@Component({
  templateUrl: './login.html',
  styles: [
    // Formulário
    '.login-container .login-columns { margin: 50px auto; display: flex; }',
    '.login-container .slideshow { margin: auto; width: 500px; display: inline-block; }',
    '.login-container .block-flat { margin: 0; border-radius: 0; box-shadow: none; }',
    // '.login-container .block-flat .content { height: 369px; }',
    '.login-container .login-form { margin: auto 20px auto auto; display: inline-block; }',
    '@media screen and (max-width: 965px) { .login-container .login-columns { display: block; } .login-container .login-form { display: block; margin: auto auto 20px; } .login-container .slideshow {display: block; margin: auto auto 20px; width: auto; } .width-950, .width-450 { width: auto; } }',
    '.ngucarousel > .ngu-touch-container > .ngucarousel-items > .item { padding: 0 !important; }',
    // Línguas
    '.dropdown-country { position: absolute; bottom: 10px; right: 10px; }',
    '.dropdown-country .dropdown-toggle > img { height: 32px; }',
    '.dropdown-country .dropdown-menu { padding: 0; min-width: 46px !important; width: 46px !important; background-color: #ddd; }',
    '.dropdown-country .dropdown-menu > li > a, .dropdown-country .dropdown-menu li > a { padding: 5px 5px;line-height: 1.42857; }',
    '.dropdown-country .dropdown-menu > li > a > img { height: 24px; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  SUPPORT_EMAIL = SUPPORT_EMAIL;
  PORTAL_NAME = PORTAL_NAME;
  HIDE_PORTAL_NAME_LOGIN = HIDE_PORTAL_NAME_LOGIN;
  year: number = (new Date()).getFullYear();
  returnUrl: string;
  isExpired: boolean;
  model: Login;
  form: UntypedFormGroup;
  formAllErrors: string = '';
  formChangePassword: UntypedFormGroup;
  formLoginMFA: UntypedFormGroup;
  formMFA: UntypedFormGroup;
  formChangePasswordAllErrors: string = '';
  hasMFA: boolean = false;
  mfaKey: string;
  successMfaMessage: string;
  errorMfaMessage: string;
  MandatoryMFA: boolean = false;
  passwordExpired: boolean = false;
  formErrors = {
    'username': '',
    'company': '',
    'password': '',
    'NewPassword': '',
    'ConfirmNewPassword': ''
  };
  validationMessages: any;

  // Pedido damiao, adicionar redirecionar para algum lugar especifico apos login
  urlAfterLogin: string = '';
  hideCompany: boolean = false;
  isSignUp: boolean = false;
  isSignUpSupplier: boolean = false;
  cookieMsgRead: boolean = false;
  loginMessage: string;
  loginFooterText: string;
  loginFooterUrl: string;
  showLoginLanguages: boolean = false;
  locale: string;
  languages: Language[] = [];

  // Notícias
  newsList: News[] = [];
  carouselOne: NguCarouselConfig;
  //variável para guardar o valor que estiver em local storage caso o utilizador já tenha dado check nos Terms and Conditions
  termsConditions: string = '';


  //Terms and Conditions
  termsConditionsUrl: string;


  //Authentication Text
  showAuthenticationText: boolean = false;
  authenticationMFAText: string = "";

  //Authentication Microsoft
  showAuthenticationMicrosoft = false;
  
  //Authentication Google
  showAuthenticationGoogle = false; /// quando a setting funcionar meter isto a false
  authenticationGoogleLabel = "Google"; /// se null = Google

  logoUrl: string = '';

  constructor(private activatedRoute: ActivatedRoute, private renderer: Renderer2, private authenticationService: AuthenticationService, private router: Router,
    private fb: UntypedFormBuilder, private translateService: TranslateService, private translateValueService: TranslateValueService, private dialog: MatDialog,
    private config: AppConfig, private commonService: CommonService, private _errorTreat: ErrorTreatmentFunctions, private domSanitizer: DomSanitizer) {
    // Força o logout para prevenir erros de sessão aberta e token vazio
    this.authenticationService.logout().subscribe(() => {
      // preencher os arrays para os selects - colocar um array com todos os observables
      this.commonService.languages.subscribe((response: Array<Language>) => {
        if (response) {
          response.forEach(element => {
            this.languages.push(new Language(
              element.Code.substring(0, 2),
              element.Name,
              element.CountryCode));
          });
        }
      });
    });

    // Obtém as notícias de login, se existirem
    this.authenticationService.getAuthenticationNews().pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        this.newsList = response.ReturnStatus.ReturnObject as News[];

        this.carouselOne = {
          grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
          slide: 1,
          speed: 400,
          interval: { timing: 10000, initialDelay: 0 },
          point: {
            visible: this.newsList.length > 1 ? true : false
          },
          load: 2,
          touch: true,
          loop: true,
          custom: 'banner'
        };

        let self = this;
        this.newsList.forEach(element => {
          this.authenticationService.getAssociatedFile(element.AssociatedImageID).pipe(takeUntil(this.destroy$)).subscribe(response2 => {
            let reader: FileReader = new FileReader();
            reader.readAsDataURL(response2);
            reader.onloadend = function () {
              element.AssociatedImageFile = self.domSanitizer.bypassSecurityTrustUrl(<string>reader.result);
            };
          });
        });
      } else {
        this._errorTreat.treatErrorResponse(response);
      }
    });
  }

  ngOnInit(): void {


    this.activatedRoute.queryParams.subscribe(params => {
      if (params['username'] && params['password']){
        this.onSubmit(new Login(params['username'], params['company'] || '', params['password'], false));
      }
    })

    this.returnUrl = this.activatedRoute.snapshot.queryParams[RETURN_URL] || '/';
    this.isExpired = this.activatedRoute.snapshot.queryParams['isExpired'] || false;

    // SSO 2021-01-21
    let token: string = this.activatedRoute.snapshot.queryParams['token'];
    if (token && token.length > 0) {
      this.authenticationService.loginSSO(token).pipe(takeUntil(this.destroy$)).subscribe((response: Session) => {
        if (response.isAuthenticated) {

          // Altera a língua da aplicação para a língua do perfil
          this.translateService.use(response.language).subscribe(() => {
            // Verifica se a password está expirada
            if (response.user.PasswordExpired) {
              this.form = undefined;
              this.buildFormChangePassword();
            } else {
              this.redirectToPage();
            }
          });
        } else {
          this.translateService.get('AUTHENTICATION_INVALID').subscribe(translation => {
            this.formAllErrors = translation;
          });
        }
      });
    }

    this.renderer.addClass(document.body, 'texture');

    this.hideCompany = this.config.getConfig('HIDE_COMPANY') === 'true';
    this.isSignUp = this.config.getConfig('IS_SIGN_UP');
    this.isSignUpSupplier = this.config.getConfig('IS_SIGN_UP_SUPPLIER');
    this.loginMessage = this.config.getConfig('LOGIN_MESSAGE');
    if (!this.loginMessage || this.loginMessage.length === 0) {
      this.loginMessage = undefined;
    }
    this.showLoginLanguages = this.config.getConfig('SHOW_LOGIN_LANGUAGES');
    if (!this.showLoginLanguages) {
      this.showLoginLanguages = false;
    }

    // Footer
    this.loginFooterText = this.config.getLoginFooterText();
    this.loginFooterUrl = this.config.getLoginFooterUrl();


    //Obter a versão do servidor
    this.authenticationService.getVersion().pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull
        && response.ReturnStatus.ReturnObject != null
        && response.ReturnStatus.ReturnObject != "") {
          this.loginFooterText += " " + response.ReturnStatus.ReturnObject;
      }
    });

    //Terms And Conditions
    this.termsConditionsUrl = this.config.getTermsConditionsrUrl();


    //Show Authentication Text
    this.showAuthenticationText = this.config.showAuthenticationText();

    //Show Authentication Microsoft
    this.showAuthenticationMicrosoft = this.config.showAuthenticationMicrosoft();
    //Show Authentication Google
    this.showAuthenticationGoogle = this.config.showAuthenticationGoogle();
    this.authenticationGoogleLabel = this.config.authenticationGoogleLabel();

    if (Cookie.check('username') && Cookie.check('company')) {
      this.model = new Login(Cookie.get('username'), Cookie.get('company'), '', true);
    } else {
      this.model = new Login('', '', '', false);
    }

    // verificar se já selecionou algum idioma
    if (this.showLoginLanguages) {
      if (Cookie.check('locale')) {
        this.locale = Cookie.get('locale');
      } else {
        // default language
        let defaultLanguage = this.config.getConfig('LANGUAGE');
        if (defaultLanguage && defaultLanguage.length > 0) {
          this.locale = defaultLanguage;
        } else {
          this.locale = 'pt';
        }
      }
      this.translateService.use(this.locale);
    }

    if (this.isExpired && this.authenticationService.session) {
      this.buildFormChangePassword();
    } else {
      this.buildForm();
      let termsConditions = localStorage.getItem('termsConditions');
      if (termsConditions) {
        this.termsConditions = termsConditions;
        if (this.termsConditions == "true") {
          this.form.get('termsConditions').setValue(true);
        }
      }
    }

    // Verifica se é passado o parâmetro da língua
    if (this.activatedRoute.snapshot.queryParams['locale']) {
      // Verifica se está a usar o componente de mudar a língua
      if (this.showLoginLanguages) {
        this.locale = this.activatedRoute.snapshot.queryParams['locale'];
        Cookie.set('locale', this.locale);
      }

      this.translateService.use(this.activatedRoute.snapshot.queryParams['locale']);
    }

    if (this.activatedRoute.snapshot.queryParams['email']) {
      this.authenticationService.activateAccount(this.activatedRoute.snapshot.queryParams['email'], this.translateService.currentLang ? this.translateService.currentLang + '_' + this.translateService.currentLang.toUpperCase() : LANGUAGE + '_' + LANGUAGE.toUpperCase())
        .pipe(takeUntil(this.destroy$)).subscribe(response => {
          if (response.ReturnStatus.ErrorMessage) {
            this.dialog.open(MessageModalComponent, { data: { title: this.translateValueService.get('ACCOUNT_ACTIVATION'), message: response.ReturnStatus.ErrorMessage } });
          } else {
            this.dialog.open(MessageModalComponent, { data: { title: this.translateValueService.get('ACCOUNT_ACTIVATION'), message: response.ReturnStatus.SuccessMessage } });
          }
        });
    }

    if (!this.hideCompany) {
      this.translateService.get(['USERNAME_REQUIRED', 'COMPANY_REQUIRED', 'PASSWORD_REQUIRED']).subscribe(response => {
        this.validationMessages = {
          'username': {
            'required': response['USERNAME_REQUIRED']
          },
          'company': {
            'required': response['COMPANY_REQUIRED']
          },
          'password': {
            'required': response['PASSWORD_REQUIRED']
          },
          'NewPassword': {
            'required': response['PASSWORD_REQUIRED']
          },
          'ConfirmNewPassword': {
            'required': response['PASSWORD_REQUIRED']
          }
        };
      });
    } else {
      this.translateService.get(['USERNAME_REQUIRED', 'PASSWORD_REQUIRED']).subscribe(response => {
        this.validationMessages = {
          'username': {
            'required': response['USERNAME_REQUIRED']
          },
          'password': {
            'required': response['PASSWORD_REQUIRED']
          },
          'NewPassword': {
            'required': response['PASSWORD_REQUIRED']
          },
          'ConfirmNewPassword': {
            'required': response['PASSWORD_REQUIRED']
          }
        };
      });
    }

    this.cookieMsgRead = Cookie.check('cookieMsgRead');
  }

  public ngAfterViewInit(): void {
    App.init();
    Functions.tooltipPassword();
    let logoImg = this.config.getConfig('LOGO_IMG');
    //document.querySelector('.login-theme .logo-img').setAttribute('src', (document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg));
  
    this.logoUrl = document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg;
  }

  // Form do painel de login
  buildForm(): void {
    this.formAllErrors = '';

    if (!this.hideCompany) {
      this.form = this.fb.group({
        'username': [this.model.username, Validators.required],
        'company': [this.model.company, Validators.required],
        'password': [this.model.password, Validators.required],
        'rememberMe': [this.model.rememberMe],
        'termsConditions': false
      });
    } else {
      this.form = this.fb.group({
        'username': [this.model.username, Validators.required],
        'password': [this.model.password, Validators.required],
        'rememberMe': [this.model.rememberMe],
        'termsConditions': false
      });
    }
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onValueChanged(data?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  // Form do painel de alterar password
  buildFormChangePassword(): void {
    this.formAllErrors = '';

    this.formChangePassword = this.fb.group({
      'NewPassword': [null, Validators.required],
      'ConfirmNewPassword': [null, Validators.required],
    });
    this.formChangePassword.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onChangePasswordValueChanged(data));
    this.onChangePasswordValueChanged(); // (re)set validation messages now
  }

  // Form do painel de alterar password
  buildFormLoginMFA(): void {
    this.formAllErrors = '';
    this.formLoginMFA = this.fb.group({
      'AuthCode': [null, Validators.required],
    });
  }

  onInputCodeChange() {
    let authCode = this.formLoginMFA.getRawValue().AuthCode;
    if (authCode) {
      this.authenticationService.checkMFACode(authCode, this.MandatoryMFA).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          if (response.ReturnStatus.ReturnObject.MFACodeChecked as boolean) {
            this.successMfaMessage = this.translateValueService.get('SUCCESSFULLY_VERIFIED');
            this.errorMfaMessage = '';
            if (this.passwordExpired) {
              // Verifica se a password está expirada            
              this.formLoginMFA = undefined;
              this.buildFormChangePassword();
            } else {
              this.redirectToPage();
            }
          } else {
            this.successMfaMessage = '';
            this.errorMfaMessage = this.translateValueService.get('TRY_AGAIN');
          }
        }
      });
    }
  }


  onChangePasswordValueChanged(data?: any) {
    if (!this.formChangePassword) { return; }
    const form = this.formChangePassword;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  // Faz o login do utilizador na aplicação
  onSubmit(login?: Login) {

    this.model = login ?? this.form.getRawValue();

    this.authenticationService.login(this.model).pipe(takeUntil(this.destroy$)).subscribe((response: Session) => {

      if (response.isAuthenticated) {

        // Verifica se é para guardar o utilizador e a empresa nos cookies
        if (this.model.rememberMe) {
          Cookie.set('username', this.model.username);
          Cookie.set('company', this.model.company);
        } else { // Senão apaga o utilizador e a empresa dos cookies
          Cookie.delete('username');
          Cookie.delete('company');
        }


        // Altera a língua da aplicação para a língua do perfil
        this.translateService.use(response.language).subscribe(() => {
          
          if (response.user.HasMFA != null && response.user.HasMFA == true) {
            this.hasMFA = response.user.HasMFA;
            this.passwordExpired = response.user.PasswordExpired != null ? response.user.PasswordExpired : false;
            this.form = undefined;
            this.authenticationMFAText = this.translateValueService.get('ENTER_YOUR_AUTHENTICATOR_CODE_TO_COMPLETE_THE_LOGIN');

            this.buildFormLoginMFA();

          } else if (response.user.HasMFA != null && response.user.HasMFA == false && response.user.MandatoryMFAType != ""
            || response.user.HasMFA == null && response.user.MandatoryMFAType != null) {

            this.MandatoryMFA = true;
            this.passwordExpired = response.user.PasswordExpired != null ? response.user.PasswordExpired : false;
            this.form = undefined;

            if (response.user.MandatoryMFAType == "sms") {

              this.authenticationMFAText = this.translateValueService.get('ENTER_THE_CODE_SENT_TO_YOUR_MOBILE_PHONE_TO_COMPLETE_THE_LOGIN');

            } else if (response.user.MandatoryMFAType == "email") {

              this.authenticationMFAText = this.translateValueService.get('ENTER_THE_CODE_SENT_TO_YOUR_EMAIL_TO_COMPLETE_THE_LOGIN');
            }

            this.buildFormLoginMFA();
          }
          else if (response.user.PasswordExpired) {
            // Verifica se a password está expirada
            this.form = undefined;
            this.buildFormChangePassword();
          } else {
            this.redirectToPage();
          }
        });


      } else {
        if (response.responseMessage && response.responseMessage.length > 0) {
          this.formAllErrors = response.responseMessage;
        }
        else {
          this.translateService.get('AUTHENTICATION_INVALID').subscribe(translation => {
            this.formAllErrors = translation;
          });
        }
      }
    });

  }

  // Altera a password do utilizador logado
  changePassword() {
    let values = this.formChangePassword.getRawValue();
    this.formAllErrors = '';

    if (!this.formChangePassword.valid) {
      this.formAllErrors = this.translateValueService.get('LAYOUT.FORM_INVALID');
    } else if (values.NewPassword !== values.ConfirmNewPassword) {
      this.formChangePassword.controls['NewPassword'].setValue('');
      this.formChangePassword.controls['ConfirmNewPassword'].setValue('');
      this.formAllErrors = this.translateValueService.get('PASSWORD_DOESNT_MATCH');
    }
    if (this.formAllErrors.length > 0) {
      return;
    }

    this.authenticationService.updatePassword(values.NewPassword).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        // A password já foi alterada, deixa de estar expirada
        this.authenticationService.session.user.PasswordExpired = false;
        this.redirectToPage();
      } else {
        Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger');
      }
    });
  }

  // Altera a password do utilizador logado
  changeLocale(locale: string) {
    this.locale = locale;
    this.translateService.use(this.locale);
    Cookie.set('locale', this.locale);
  }

  // Definição da página que retorna depois de fazer login
  redirectToPage() {
    // Setting que obriga sempre a redirecionar para a página configurada quando faz login
    this.urlAfterLogin = this.authenticationService.get_redirectAfterLogin(this.urlAfterLogin);

    // Limpa as línguas para voltar a carregar com a tradução certa
    this.commonService.resetLanguages();

    if (this.urlAfterLogin !== '') { // Verifica se a setting de redirecionamento tem valor
      this.router.navigate([this.urlAfterLogin]);
    } else if (this.returnUrl.indexOf('?') !== -1) { // Verifica se existem parâmetros no URL para além do valor do ReturnUrl
      let paramsRaw = this.returnUrl.split('?')[1].split('&');

      let queryParams = {};
      paramsRaw.forEach(element => {
        let param = element.split('=');
        queryParams[param[0]] = param[1];
      });

      this.router.navigate([this.returnUrl.split('?')[0]], { queryParams: queryParams });
    } else { // Senão redireciona para o valor do ReturnUrl
      this.router.navigate([this.returnUrl]);
    }
  }

  myfunc(event: Event) {
    // carouselLoad will trigger this funnction when your load value reaches
    // it is helps to load the data by parts to increase the performance of the app
    // must use feature to all carousel
  }

  backToLogin() {
    this.successMfaMessage = '';
    this.errorMfaMessage = '';
    this.formChangePassword = undefined;
    this.formLoginMFA = undefined;
    this.buildForm();
  }

  microsoftLogin() {
    this.authenticationService.microsoftLogin().pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull
        && response.ReturnStatus.ReturnObject != null
        && response.ReturnStatus.ReturnObject.URL != ""
        && response.ReturnStatus.ReturnObject.URL != null) {
        window.location.href = response.ReturnStatus.ReturnObject.URL;
      } else {
        Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger');
      }
    });
  }

  googleLogin() {
    this.authenticationService.googleLogin().pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull
        && response.ReturnStatus.ReturnObject != null
        && response.ReturnStatus.ReturnObject.URL != ""
        && response.ReturnStatus.ReturnObject.URL != null) {
        window.location.href = response.ReturnStatus.ReturnObject.URL;
      } else {
        Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger');
      }
    });
  }





  dismissCookieMessage() {
    this.cookieMsgRead = true;
    Cookie.set('cookieMsgRead', 'true');
  }

  _onContentChanges() {
    return;
  }

  onChangeTermsConditions(event: MatCheckboxChange) {
    if (event.checked) {
      localStorage.setItem('termsConditions', event.checked.toString());
    }
  }

  onClickTermsConditions() {

  }

  ngOnDestroy() { }
}
