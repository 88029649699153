import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DashboardService } from '../../../services/dashboard.service';
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { DashboardPanelChart } from 'src/app/models/dashboard-panel-chart';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-counter-chart',
  templateUrl: './counter-chart.html'
})
/// <summary>
/// Componente que mostra um gráfico contador
/// </summary>
export class CounterChartComponent implements OnInit, OnDestroy {
  @Input() chart: DashboardPanelChart;

  // Chart parâmetros
  public chartOptions: any = {};
  public chartLabel: string;
  public chartLegend: boolean = true;
  public chartData: number;
  public chartColor: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private dashboardService: DashboardService, private _errorTreat: ErrorTreatmentFunctions, private router: Router) { }

  ngOnInit(): void {
    this.dashboardService.getDashboardPanelChartData(this.chart.ID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        // Label
        let labels = (response.ReturnStatus.ReturnObject.Labels as string[]);
        this.chartLabel = labels && labels.length > 0 ? labels[0] : '';
        // Valor
        let series = (response.ReturnStatus.ReturnObject.Series as any[]);
        this.chartData = series && series.length > 0 ? series[0].data[0] : 0;

        // Carrega a cor
        if (this.chart.ChartColors) {
          let colors = this.chart.ChartColors.split(';');

          this.chartColor = colors[0];
        }
      } else {
        this._errorTreat.treatErrorResponse(response);
      }
    });
  }

  navigateTo() {
    // Se tiver o url configurado, navega
    if (this.chart.ChartUrl) {
      // Verifica se é um url interno da aplicação
      if (this.chart.ChartUrl.indexOf('http') === -1) {
        this.router.navigate([this.chart.ChartUrl]);
      } else {
        window.location.href = this.chart.ChartUrl;
      }
    }
  }

  ngOnDestroy() { }
}
