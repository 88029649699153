import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelValidators } from '../../models/validators/validators';
import { AppConfig } from '../../configs/app.config';
import { SUPPORT_EMAIL, PORTAL_NAME } from 'src/app/constants/global';
import { Cookie } from 'ng2-cookies';

declare var App: any;

@Component({
  templateUrl: './terms-conditions.html',
  styles: [
    // Formulário
    '.login-container .login-columns { margin: 50px auto; display: flex; }',
    '.login-container .slideshow { margin: auto; width: 600px; display: inline-block; }',
    '.login-container .block-flat { margin: 0; border-radius: 0; box-shadow: none; }',
    // '.login-container .block-flat .content { height: 369px; }',
    '.login-container .login-form { margin: auto 20px auto auto; display: inline-block; }',
    '@media screen and (max-width: 965px) { .login-container .login-columns { display: block; } .login-container .login-form { display: block; margin: auto auto 20px; } .login-container .slideshow {display: block; margin: auto auto 20px; width: auto; } .width-950, .width-450 { width: auto; } }',
    '.ngucarousel > .ngu-touch-container > .ngucarousel-items > .item { padding: 0 !important; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TermsConditionsComponent implements OnInit, AfterViewInit, OnDestroy {
  SUPPORT_EMAIL = SUPPORT_EMAIL;
  PORTAL_NAME = PORTAL_NAME;
  validationMessages: any;
  message: string;

  // Configurações
  loginFooterText: string;
  loginFooterUrl: string;

  //Terms and Conditions
  termsConditionsUrl: string;

  locale: string;
  showLoginLanguages: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private renderer: Renderer2, private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder, private translateService: TranslateService, private config: AppConfig) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'texture');

    // Footer
    this.loginFooterText = this.config.getLoginFooterText();
    this.loginFooterUrl = this.config.getLoginFooterUrl();

    //Terms And Conditions
    this.termsConditionsUrl = this.config.getTermsConditionsrUrl();

    this.showLoginLanguages = this.config.getConfig('SHOW_LOGIN_LANGUAGES');
    if (!this.showLoginLanguages) {
      this.showLoginLanguages = false;
    }

    // verificar se já selecionou algum idioma
    if (this.showLoginLanguages) {
      if (Cookie.check('locale')) {
        this.locale = Cookie.get('locale');
      } else {
        // default language
        let defaultLanguage = this.config.getConfig('LANGUAGE');
        if (defaultLanguage && defaultLanguage.length > 0) {
          this.locale = defaultLanguage;
        } else {
          this.locale = 'pt';
        }
      }
      this.translateService.use(this.locale);
    }

    // Verifica se é passado o parâmetro da língua
    if (this.activatedRoute.snapshot.queryParams['locale']) {
      // Verifica se está a usar o componente de mudar a língua
      if (this.showLoginLanguages) {
        this.locale = this.activatedRoute.snapshot.queryParams['locale'];
        Cookie.set('locale', this.locale);
      }

      this.translateService.use(this.activatedRoute.snapshot.queryParams['locale']);
    }

  }

  public ngAfterViewInit(): void {
    App.init();
    let logoImg = this.config.getConfig('LOGO_IMG');
    document.querySelector('.login-theme .logo-img').setAttribute('src', (document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg));
  }



  _onContentChanges() {
    return;
  }

  ngOnDestroy() { }
}
