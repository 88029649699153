import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil, filter } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
// https://blog.hackages.io/our-solution-to-get-a-previous-route-with-angular-5-601c16621cf0
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';


@Injectable()
export class RoutingState implements OnDestroy {
  private history = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router: Router
  ) { }

  public loadRouting(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.destroy$)).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.history = [this.history, urlAfterRedirects];
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history.length === 0 ? '/dashboard' : this.history[1].indexOf('/login') > -1 ? this.history[0].length === 0 ? '/dashboard' : this.history[0][1] : this.history[1]; // 0 é a lista de historicos e 1 é o url
  }

  ngOnDestroy() { }
}
