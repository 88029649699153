// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';
import { CostCenterConfigService } from '../../../services/costCenterConfig.service';
import { TranslateValueService } from '../../../services/translate-value.service';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { CURRENCY_DECIMAL } from '../../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { ModelValidators } from '../../../models/validators/validators';
import { CostCenterConfig, TransformModelCC } from '../../../models/costCenterConfig';
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { Dropdown } from '../../../models/dropdown';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { DistributionModalParam } from 'src/app/models/distribution-modal-param';

// ***__***_________  MODALS _________ ***__***
import { ChooseModalComponent } from '../choose-modal/choose-modal.component';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './distribution-modal.html'
})
export class DistributionModalComponent implements OnInit, OnDestroy {

  isDisabled: boolean;
  context: string;
  entityID: number;
  selectedType: string;
  saveOnServer: boolean;
  defaultValue: number;
  documentType: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  costCenterConfigList: Array<CostCenterConfig>; // listagem principal (todas as configurações de centro de custo)
  costCentersList: Array<any> = new Array<any>(); // listagem de centros de custo

  /* Listas recebidas do componente "pai" */
  typeList: Array<Dropdown> = new Array<Dropdown>(); // tipo (centro de custo/eixo de analise)
  debitCreditList: Array<Dropdown> = new Array<Dropdown>(); // debito ou credito
  incidenceList: Array<Dropdown> = new Array<Dropdown>(); // listagem de incidencias
  analisysAxesList: Array<any> = new Array<any>(); // listagem de eixos de análise
  divisionKeysList: Array<any> = new Array<any>(); // listagem de chaves de divisão
  allCostCentersList: Array<any> = new Array<any>(); // listagem de todos os centros de custo
  // *** fim listas recebidas

  model = new CostCenterConfig();
  form: UntypedFormGroup;
  submitted: boolean;
  noError: boolean;

  table: any = null;
  idSelec: number;
  currentLanguage: string;
  tranfModelCC = new TransformModelCC(); // necessario para usar as funcoes de configuração de numeros

  validationMessages = {};

  constructor(public dialogRef: MatDialogRef<DistributionModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: DistributionModalParam,
    private translateService: TranslateService,
    private translateValueService: TranslateValueService,
    private formBuilder: UntypedFormBuilder,
    private costCenterConfigService: CostCenterConfigService,
    private dialog: MatDialog,
    private _errorTreat: ErrorTreatmentFunctions) {


    this.currentLanguage = this.translateValueService.translateLanguage;

    // parametros que vai receber
    this.isDisabled = data.IsDisabled;
    this.context = data.Context;
    this.entityID = data.EntityID;
    this.costCenterConfigList = data.List;
    this.saveOnServer = data.SaveOnServer;
    this.defaultValue = +data.DefaultValue;
    this.documentType = data.DocumentType;
    // listas
    this.typeList = data.TypeList;
    this.debitCreditList = data.DebitCreditList;
    this.incidenceList = data.IncidenceList;
    this.analisysAxesList = data.AnalisysAxesList;
    this.divisionKeysList = data.DivisionKeysList;
    this.allCostCentersList = data.AllCostCentersList;

    this.validationMessages = {
      'AnalisysAxeID': {
        'required': 'FIELD_REQUIRED_'
      },
      'DivisionKeyID': {
        'required': 'FIELD_REQUIRED_'
      },
      'Type': {
        'required': 'FIELD_REQUIRED_'
      },
      'CostCenterName': {
        'required': 'FIELD_REQUIRED_'
      },
      'Percentage': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'FixedValue': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'DebitOrCredit': {
        'required': 'FIELD_REQUIRED_'
      },
      'Incidence': {
        'required': 'FIELD_REQUIRED_'
      }
    }
  }

  ngOnInit(): void {

    let that = this;
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    this.buildForm();
    if (this.isDisabled) {
      this.form.disable();
    }
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      'CostCenterConfigList': this.formBuilder.array([])
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação

    this.addCostCenterConfig(false);
  }

  /* tslint:disable:member-ordering */
  formErrors: Array<string> = new Array<string>();
  formErrorsParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */
  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrors = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('CostCenterConfigList');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessages[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrors.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsParam.push('');
                  }

                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }

  initCostCenterConfig(costCenterConfig: CostCenterConfig = null, divisionKeyType: boolean = null) {
    if (costCenterConfig) {
      let isCostCenterRequired = costCenterConfig.Type === 'CostCenter' ? Validators.required : null;
      let isDivisionKeyRequired = costCenterConfig.Type === 'DivisionKey' ? Validators.required : null;
      let inicialFixedValue: number = 0;
      let fixedValue = costCenterConfig.FixedValue != null ? ((costCenterConfig.FixedValue).toString()).revertDecimal() : 0;

      if (!this.isDisabled && this.defaultValue > 0 && fixedValue === 0) {
        inicialFixedValue = this.defaultValue;
      } else {
        inicialFixedValue = fixedValue;
      }

      let isFreeText: boolean = (this.analisysAxesList.find((x: any) => x.ID === (costCenterConfig.AnalisysAxeID)) == null ? false : this.analisysAxesList.find((x: any) => x.ID === (costCenterConfig.AnalisysAxeID)).IsFreeText);

      let costCenterNameOrIntegrationID: string;
      if (costCenterConfig.CostCenterID === null) {
        costCenterNameOrIntegrationID = costCenterConfig.IntegrationID;
      } else {
        costCenterNameOrIntegrationID = this.findInArray(this.allCostCentersList, costCenterConfig.CostCenterID, 'ID', 'Name');
      }

      return this.formBuilder.group({
        'ID': [costCenterConfig.ID, ''],
        'AnalisysAxeID': [costCenterConfig.AnalisysAxeID, isCostCenterRequired],
        'DivisionKeyID': [costCenterConfig.DivisionKeyID, isDivisionKeyRequired],
        'Context': [costCenterConfig.Context, ''],
        'CostCenterID': [costCenterConfig.CostCenterID, ''],
        'CostCenterName': [{ value: costCenterNameOrIntegrationID, disabled: !isFreeText }, isCostCenterRequired],
        'DebitOrCredit': [{ value: costCenterConfig.DebitOrCredit, disabled: divisionKeyType }, ''],
        'EntityID': [costCenterConfig.EntityID, ''],
        'Incidence': [costCenterConfig.Incidence, ''],
        'IntegrationID': [costCenterConfig.IntegrationID, ''],
        'Percentage': [{ value: costCenterConfig.Percentage, disabled: divisionKeyType }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'FixedValue': [{ value: (inicialFixedValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)), disabled: divisionKeyType }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'Type': [costCenterConfig.Type, ''],
        // p/ validar se centro de custo pode ser escrito ou não
        'IsCostCenterFreeText': [isFreeText, '']
      });
    } else {
      return this.formBuilder.group({
        'ID': [0, ''],
        'AnalisysAxeID': [null, Validators.required],
        'DivisionKeyID': [null, ''],
        'Context': ['', ''],
        'CostCenterID': [0, ''],
        'CostCenterName': ['', Validators.required],
        'DebitOrCredit': [false, ''],
        'EntityID': [0, ''],
        'Incidence': ['1', ''], // valor liquido
        'IntegrationID': [null, ''],
        'Percentage': [0, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'FixedValue': [(this.defaultValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)), Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'Type': ['CostCenter', ''],
        'IsCostCenterFreeText': ['', '']
      });
    }
  }

  addCostCenterConfig(newInsert: boolean) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.form.controls['CostCenterConfigList'];
      const newCtrl = this.initCostCenterConfig();
      control.push(newCtrl);
      newCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChanged(value);
      });

    } else { // inserir os dados que ja estao gravados
      if (this.costCenterConfigList != null) {
        for (let costCenter of this.costCenterConfigList) {
          const control = <UntypedFormArray>this.form.controls['CostCenterConfigList'];
          if (costCenter.Type === 'DivisionKey') {
            const costCenterCtrl = this.initCostCenterConfig(costCenter, true);
            control.push(costCenterCtrl);
            costCenterCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
              this.onValueChanged(value);
            });
          } else {
            const costCenterCtrl = this.initCostCenterConfig(costCenter);
            control.push(costCenterCtrl);
            costCenterCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
              this.onValueChanged(value);
            });
          }
        }
      }
    }
  }

  // quando altera percentagem
  onChangePercentage(val: any, positionArrayForm: number) {
    let value = val ? ((val).toString()).revertDecimal() : null;

    if (value !== null) { // se for um numero
      const controlGroup = (<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm];
      (<UntypedFormGroup>controlGroup).controls['FixedValue'].setValue((this.defaultValue * (value / 100)).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
    } else { // se forem letras ou nada força a por zero
      const controlGroup = (<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm];
      (<UntypedFormGroup>controlGroup).controls['FixedValue'].setValue((this.defaultValue * (0 / 100)).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
      (<UntypedFormGroup>controlGroup).controls['Percentage'].setValue(0);
    }
  }

  // quando altera valor fixo
  onChangeFixedValue(val: any, positionArrayForm: number) {
    let value = val ? ((val).toString()).revertDecimal() : null;

    if (value === null) { // se for string ou vazio
      const controlGroup = (<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm];
      (<UntypedFormGroup>controlGroup).controls['FixedValue'].setValue((this.defaultValue * (0 / 100)).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
    }
  }

  // recalcular valores
  recalculate() {
    this.noError = true; // limpar variavel que verifica se há erros
    this.onValueChanged();

    if (this.noError) { // só faz isto se não tiver erros
      const control = <UntypedFormArray>this.form.controls['CostCenterConfigList'];
      if (control.getRawValue().length > 0) {

        let sumPercentages = [];
        let sumFixedValues = [];

        control.controls.forEach((item: UntypedFormGroup, index: number) => {

          let analisysAxeID: number = (+item.controls['AnalisysAxeID'].value);

          let percentageVal: number = (item.controls['Percentage'].value);
          let fixedValueVal: number = (item.controls['FixedValue'].value);

          // conversão para numeros
          let percentage = percentageVal ? ((percentageVal).toString()).revertDecimal() : 0;
          let fixedValue = fixedValueVal ? ((fixedValueVal).toString()).revertDecimal() : 0;

          if (percentage === 0 || percentage === 100) {
            fixedValue = (this.defaultValue).round(2);

          } else {
            fixedValue = ((percentage / 100) * this.defaultValue).round(2);
          }
          item.controls['FixedValue'].setValue((fixedValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));

          // Somatórios por eixo de analise
          if (analisysAxeID > 0) {

            // criar/atualizar objeto sumPercentages {key, value} para cada eixo de análise
            if (sumPercentages.length === 0) { // quando é o primeiro basta adicionar
              sumPercentages.push({ key: analisysAxeID, value: 0 });
            } else {
              if (sumPercentages.findIndex((x: any) => x.key === analisysAxeID) === -1) { // adicionar novos centros de custo (apenas uma vez)
                sumPercentages.push({ key: analisysAxeID, value: 0 });
              }
            }
            // criar/atualizar objeto sumFixedValues {key, value} para cada eixo de análise
            if (sumFixedValues.length === 0) { // quando é o primeiro basta adicionar
              sumFixedValues.push({ key: analisysAxeID, value: 0 });
            } else {
              if (sumFixedValues.findIndex((x: any) => x.key === analisysAxeID) === -1) { // adicionar novos centros de custo (apenas uma vez)
                sumFixedValues.push({ key: analisysAxeID, value: 0 });
              }
            }

            // somar valores das %'s para cada key (que corresponde a cada eixo de análise)
            sumPercentages.forEach((object: any, position: number) => {
              if (object.key === analisysAxeID) {
                object.value += percentage;
              }
            });
            // somar valores dos valores fixos para cada key (que corresponde a cada eixo de análise)
            sumFixedValues.forEach((object: any, position: number) => {
              if (object.key === analisysAxeID) {
                object.value += fixedValue;
              }
            });

            let totalPercentage: number;
            let totalFixedValue: number;

            sumPercentages.forEach((object: any, position: number) => {
              if (object.key === analisysAxeID) {
                // totalPercentage = parseFloat(object.value);
                totalPercentage = (+object.value);
              }
            });
            sumFixedValues.forEach((object: any, position: number) => {
              if (object.key === analisysAxeID) {
                // totalFixedValue = parseFloat(object.value);
                totalFixedValue = (+object.value);
              }
            });

            // retirar ao ultimo fixed value a diferença entre o sum e o defaultValue
            if (totalPercentage === 100 && totalFixedValue !== this.defaultValue) {

              fixedValue -= (totalFixedValue).round(2) - (this.defaultValue).round(2);
              if (fixedValue < 0) {
                item.controls['FixedValue'].setValue(0);
              } else {
                item.controls['FixedValue'].setValue((fixedValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
              }
            }
          }
        });
      }
    }
  }

  // copiar linha
  copy(costCenterOld: UntypedFormGroup) {
    if (costCenterOld) {
      const control = <UntypedFormArray>this.form.controls['CostCenterConfigList'];
      const costConfig = this.initCostCenterConfig(costCenterOld.getRawValue());
      costConfig.controls['ID'].setValue(0);
      control.push(costConfig);

      this.recalculate();
    }
  }

  // remover linha
  remove(i: number) {
    const control = <UntypedFormArray>this.form.controls['CostCenterConfigList'];
    control.removeAt(i);
  }

  // guardar lista de distribuições
  onSubmit() {
    this.submitted = true; // necessário saber que está a submeter para no validar não controllar o dirty(alteração)
    this.noError = true; // limpar variavel que verifica se há erros
    this.onValueChanged();
    const control = <UntypedFormArray>this.form.controls['CostCenterConfigList'];

    let mandatoryErrors: boolean = false; // limpar erros
    let repeatErrors: boolean = false; // limpar erros
    let existingCostCenterIDs: Array<number> = new Array<number>();

    // Somatórios de valores agrupados por eixo de analise (key=AnalisysAxeID)
    let analisysAxesIndexes: Array<any> = new Array<any>();
    let percentagesSumGroupedByAnalisysAxe: Array<any> = new Array<any>();
    let fixeValueSumGroupedByAnalisysAxe: Array<any> = new Array<any>();

    if (control.getRawValue().length > 0) {
      control.controls.forEach((item: UntypedFormGroup, index: number) => {

        // let integrationID: string = null;
        // let integrationID: string = item.controls['IntegrationID'].value; => este valor é alterado posteriormente por isso nao pode ser usado
        let divisionKeyID: number = (+item.controls['DivisionKeyID'].value);
        let costCenterID: number = (+item.controls['CostCenterID'].value);
        let analisysAxeID: number = (+item.controls['AnalisysAxeID'].value);

        let percentageVal: number = (item.controls['Percentage'].value);
        let fixedValueVal: number = (item.controls['FixedValue'].value);

        // conversão para numeros
        let percentage = percentageVal ? ((percentageVal).toString()).revertDecimal() : null;
        let fixedValue = fixedValueVal ? ((fixedValueVal).toString()).revertDecimal() : null;

        /*  se for do tipo "centro de custo" (analisysAxeID)
        * e costCenterID = 0 é porque não é selecionavel, logo
        * transferir valor do 'CostCenterName' para o 'IntegrationID'
        * */
        if (analisysAxeID && costCenterID === 0) { // se não tiver centro de custo, ir buscar o valor que está no campo
          let name = item.controls['CostCenterName'].value;
          item.controls['IntegrationID'].setValue(name);
        }

        /*
         * Notas: se analisysAxeID > 0 => significa que o tipo é "centro de custo" e então
         * o integrationID (escrito à mão) OU o costCenterID (selecionavel) devem estar preenchidos
         *
         * se analisysAxeID = 0 => significa que o tipo é "chave de repartição"
         *
         * */
        if ((analisysAxeID && (item.controls['IntegrationID'].value) === null && costCenterID === 0) || (!analisysAxeID && !divisionKeyID)) {
          mandatoryErrors = true;
        }

        // Validar Centros de Custo repetidos
        if (!repeatErrors && costCenterID > 0) {
          if (existingCostCenterIDs.findIndex((id: number) => id === costCenterID) !== -1) { // se encontar repetido
            repeatErrors = true;

          } else {
            existingCostCenterIDs.push(costCenterID);
          }
        }
        if (analisysAxeID > 0) {
          if (analisysAxesIndexes.findIndex((val: number) => val === analisysAxeID) !== -1) {
            let indexArray: number = analisysAxesIndexes.findIndex((r: any) => r === analisysAxeID);
            let percSum: number = percentagesSumGroupedByAnalisysAxe[indexArray];
            let fixedSum: number = fixeValueSumGroupedByAnalisysAxe[indexArray];

            // Somar as percentagens para os mesmos eixos de analise
            percentagesSumGroupedByAnalisysAxe[indexArray] = ((percSum + percentage)).round(2);

            // Somar os valores fixos para os mesmos eixos de analise
            fixeValueSumGroupedByAnalisysAxe[indexArray] = ((fixedSum + fixedValue)).round(2);

          } else {
            analisysAxesIndexes.push(analisysAxeID);
            percentagesSumGroupedByAnalisysAxe.push(percentage);
            fixeValueSumGroupedByAnalisysAxe.push(fixedValue);
          }
        }

      });
    }

    let errorMsg: string = '';
    if (mandatoryErrors) {
      errorMsg = this.translateValueService.get('LAYOUT.FORM_INVALID') + '<br/>';
    }
    if (repeatErrors) {
      errorMsg += this.translateValueService.get('THERE_ARE_REPEATED_COST_CENTERS') + '<br/>';
    }

    for (let i = 0; i < analisysAxesIndexes.length; i++) {
      // Obter o eixo de analise respectivo
      let analisysAxe = this.analisysAxesList.find((aAxe: any) => aAxe.ID === analisysAxesIndexes[i]);
      if (analisysAxe !== null) {
        let percSum: number = percentagesSumGroupedByAnalisysAxe[i];
        let fixedSum: number = fixeValueSumGroupedByAnalisysAxe[i];

        if ((percSum > 0 && percSum !== 100) || fixedSum !== this.defaultValue) {
          if (percSum > 0 && percSum !== 100) {
            errorMsg += this.translateValueService.get('PERCENTAGE_SUM_NOT_100_PERCENT', { percentage: percSum.toString() }) + '<br/>';
            break;
          }
          if (fixedSum !== Number(this.defaultValue)) { // Forçar o defaultValue para Number ou utilizar != em vez de !==
            errorMsg += this.translateValueService.get('FIXED_VALUE_SUM_NOT_TOTAL_VALUE', { fixedValueSum: fixedSum.toString(), totalValue: this.defaultValue.toString() }) + '<br/>';
            break;
          }
        }
        // LP 18/02/2019: Não parece ser necessário
        // else {
        //   errorMsg = '';
        // }
      }
    }

    if (errorMsg !== '') {
      Functions.gritter(errorMsg, 'danger');
    }

    // Gravar os dados na BD
    if (this.noError && !mandatoryErrors && !repeatErrors && errorMsg === '') { // caso não existam erros
      let that = this;

      control.controls.forEach((item: UntypedFormGroup, index: number) => {
        if (!item.controls['IsCostCenterFreeText'].value) {
          item.controls['IntegrationID'].setValue(null);
        }
      });

      let configList: Array<CostCenterConfig> = this.tranfModelCC.revertObjects(control.getRawValue());

      if (this.saveOnServer && this.context !== '' && this.entityID > 0) {
        this.costCenterConfigService.updateCostCenterConfig(this.context, this.entityID, configList).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
          if (response.ReturnStatus.Successfull) {
            Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
            this.dialogRef.close(control.getRawValue());
          } else {
            that._errorTreat.treatErrorResponse(response);
          }
        });
      } else {
        this.dialogRef.close(configList);
      }

      this.submitted = false;
    }
  }

  // quando altera o tipo da contabilização
  onChange(selectedType: any, positionArrayForm: number) {
    if (selectedType === 'DivisionKey') {

      const controlGroup: UntypedFormGroup = <UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm];
      controlGroup.controls['CostCenterID'].setValue(0);
      controlGroup.controls['AnalisysAxeID'].setValue(0);
      controlGroup.controls['Percentage'].setValue(0);
      controlGroup.controls['FixedValue'].setValue(0);
      // controlGroup.controls['DebitOrCredit'].setValue('D');
      controlGroup.controls['DebitOrCredit'].setValue(false); // false => D

      /*No HTML validar se têm erros (.errors) e não (.valid) porque um control disable é sempre inválido */
      controlGroup.controls['Percentage'].disable();
      controlGroup.controls['FixedValue'].disable();
      controlGroup.controls['DebitOrCredit'].disable();

      controlGroup.controls['DivisionKeyID'].setValidators(Validators.required);
      controlGroup.controls['DivisionKeyID'].updateValueAndValidity();

      controlGroup.controls['CostCenterName'].setValidators(null);
      controlGroup.controls['CostCenterName'].updateValueAndValidity();



    } else if (selectedType === 'CostCenter') {

      const controlGroup = <UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm];
      controlGroup.controls['DivisionKeyID'].setValue(0);

      controlGroup.controls['Percentage'].enable();
      controlGroup.controls['FixedValue'].enable();
      controlGroup.controls['DebitOrCredit'].enable();

      controlGroup.controls['AnalisysAxeID'].setValidators(Validators.required);
      controlGroup.controls['AnalisysAxeID'].updateValueAndValidity();

      controlGroup.controls['CostCenterName'].setValidators(Validators.required);
      controlGroup.controls['CostCenterName'].updateValueAndValidity();

    } else { // outros casos (não deve ocorrer)

      const controlGroup = <UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm];
      controlGroup.controls['DivisionKeyID'].setValue(0);
      controlGroup.controls['AnalisysAxeID'].setValue(0);
      controlGroup.controls['CostCenterID'].setValue(0);
      controlGroup.controls['Percentage'].setValue(0);
      controlGroup.controls['FixedValue'].setValue(0);
      // controlGroup.controls['DebitOrCredit'].setValue('D');
      controlGroup.controls['DebitOrCredit'].setValue(false);
    }
  }

  // quando altera ID eixo de análise
  onSelectAnalysysAxe(selectedID: any, positionArrayForm: number) {
    // limpar valor do campo "CostCenterName"
    (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterName'].setValue('');

    let isCostCenterNameFree: boolean;

    if ((this.analisysAxesList.find((x: any) => x.ID === selectedID))) {
      if ((this.analisysAxesList.find((x: any) => x.ID === selectedID).IsFreeText) === false) { // não pode editar
        /*
         * se é para selecionar pelo iconzinho
         * */
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['IsCostCenterFreeText'].setValue(false);
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterName'].disable();
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['IntegrationID'].setValue(null);
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterID'].setValue(null);
      } else { // pode editar
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['IsCostCenterFreeText'].setValue(true);
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterName'].enable();
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterID'].setValue(null);
      }
    }
  }

  // selecionar centro de custo
  selectCostCenter(positionArrayForm: number) {
    let idEixoAnalise: number = (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['AnalisysAxeID'].value;
    if (idEixoAnalise != null && idEixoAnalise.toString() !== '' && idEixoAnalise !== null && typeof (idEixoAnalise) !== 'undefined') {
      // obter lista centros de custo consoante eixo de análise selecionado
      this.costCenterConfigService.getCostCenters(idEixoAnalise, this.documentType).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          this.costCentersList = response.ReturnStatus.ReturnObject;
          // forçar estar dentro do serviço para esperar o carregamento da lista
          this.costCentersModal(positionArrayForm);
        } else {
          this._errorTreat.treatErrorResponse(response);
        }
      });


    }
  }

  // modal centros de custo
  costCentersModal(positionArrayForm: number) {
    // validar inatividade (só mostar os ativos)
    this.costCentersList = this.costCentersList.filter((x: any) => (x.Inactive === false) || x.Inactive === null);

    this.translateService.get(['SELECT_COST_CENTER', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.costCentersList, null, response['SELECT_COST_CENTER'], aoColumns, columnDefs, null, 1, null),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = (+result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.costCentersList.findIndex((r: any) => r.ID === resultID);
            // this.form.controls['CostCenterConfigList'] => formArray
            // this.form.controls['CostCenterConfigList'].controls[positionArrayForm] => formGroup

            (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterName'].setValue(this.costCentersList[index].Name);
            (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['CostCenterConfigList']).controls[positionArrayForm]).controls['CostCenterID'].setValue(this.costCentersList[index].ID);
          }
        }
      });
    });
  }

  // encontrar um valor num determinado array
  findInArray(array: Array<any>, goal: number, keyGoal: string, keyFind: string): any {
    // let result = array.find((a: any) => <string>a[keyGoal] === goal);
    let result = array.find((a: any) => <number>a[keyGoal] === goal);
    return result ? result[keyFind] : '';
  }

  ngOnDestroy() { }
}