import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';
import { Expense, ExpenseNote, ExpenseType } from '../models/expenseNotes';
import { Settings } from '../models/settings';
import { Role } from '../models/role';
import { stubFalse } from 'lodash';


@Injectable()
export class ExpenseService {

  private expenseNoteSettings: Settings;

  private _controller: string = 'ExpenseNotes';

  EXPENSES_columnInVisible: Array<number>;
  EXPENSES_columnVisible: Array<number>;
  EXPENSES_fillEndDate: boolean;
  EXPENSES_showCreationDate: boolean;
  EXPENSES_showColumnID: boolean;


  filter_dataIni_P: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_P: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataIni_Create_P: string; // para manter os valores do filtro de data de inicio (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_Create_P: string; // para manter os valores do filtro de data de fim (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_produto_P: string; // para manter os valores do filtro do produto, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_isHistory_P: boolean;

  //Datatable Parameters
  datatableParametersExpenses: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);

  private _expenseNotesStates: Array<any>;
  private _expenseNotesTypes: Array<any>;
  private _expenseStates: Array<any>;
  private _expenseTypes: Array<any>;

  constructor(private http: HttpClientCustom,
    private _authenticationService: AuthenticationService,
    private _masterService: MasterService,
    private _dataTableF: DataTableFunctions) {
    this.expenseNoteSettings = new Settings(_authenticationService, 'ExpenseNotes');
  }

  //#region ----------------*** Expenses *** ----------------------------

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  getEXPENSES_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.EXPENSES_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('ExpenseNotes', 'ColumVisible');
      this.EXPENSES_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this._authenticationService.session.company.ServiceProvider && settingValue);
    }
    return this.EXPENSES_columnInVisible;
  }


  // getEXPENSES_columnVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
  //   if (typeof this.EXPENSES_columnVisible === 'undefined') { // verificar se ainda nao tem valor
  //     let settingValue = this._authenticationService.getSettingPortal('ExpenseNotes', 'ColumVisible');
  //     this.EXPENSES_columnVisible = this._dataTableF.getVisibleColumns(defaultValue, columnsNames, settingValue);
  //   }
  //   return this.EXPENSES_columnVisible;
  // }

  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getEXPENSES_fillEndDate(defaultValue: boolean): boolean {
    if (typeof this.EXPENSES_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('ExpenseNotes', 'FillEndDate');
      if (settingValue != null) {
        this.EXPENSES_fillEndDate = settingValue;
      } else {
        this.EXPENSES_fillEndDate = defaultValue;
      }
    }
    return this.EXPENSES_fillEndDate;
  }

  getEXPENSES_showColumnID(defaultValue: boolean): boolean {
    if (typeof this.EXPENSES_showColumnID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('ExpenseNotes', 'ShowColumnID');
      if (settingValue != null) {
        this.EXPENSES_showColumnID = settingValue;
      } else {
        this.EXPENSES_showColumnID = defaultValue;
      }
    }
    return this.EXPENSES_showColumnID;
  }

  //#region ADD
  /**
   * Adiciona ou Submete uma nota de despesa
   * @param  {ExpenseNote} expenseNote Nota de despesa a adcionar
   * @param  {boolean=false} isSubmit Se é para submeter ou não
   * @returns Observable 
   *          response.ReturnStatus.ReturnObject => ID da nova nota de despesa
   */
  addExpenseNote(expenseNote: ExpenseNote, isSubmit: boolean = false, useOnlyOneOffExpense: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    expenseNote.Expenses.forEach(expense => {
      // Adiciona ao formulário o ficheiro
      if (expense.File) {
        formData.append(expense.LineNumber + '', expense.File, expense.File.name);
        expense.File = null;
      }
    });

    formData.append('entity', JSON.stringify(expenseNote));
    formData.append('isSubmit', JSON.stringify(isSubmit));
    formData.append('useOnlyOneOffExpense', JSON.stringify(useOnlyOneOffExpense));
    return this.http.put(SERVICE_URL + this._controller + '/AddExpenseNote', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#endregion

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  /**
   * Download de PDF da nota de despesa
   * @param  {number} id
   * @returns Observable
   */
  downloadPDFExpenseNote(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFExpenseNote?id=' + id);
  }


  //#region GET 
  /** Obter dados de uma nota de despesa através do id
   * 
   * @param  {string} id
   * @returns Observable
   */
  getExpenseNote(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetExpenseNote?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Obter numero documento seguinte
 * @param  {number} OwnerId
 * @param  {number} supplierID
 * @returns Observable
 */
  getNextDocumentNumber(OwnerId: number, supplierID: number, isRequest: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextDocumentNumber?OwnerId=' + OwnerId + '&supplierID=' + supplierID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /** Obter todos os estados das notas de despesa
   * @returns Observable - com o array de estados ou vazio
   */
  get expenseNotesStates(): Observable<Array<any>> {
    if (this._expenseNotesStates === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetExpenseNotesStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._expenseNotesStates = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._expenseNotesStates);
    }
  }

  /** Obter todos os tipos das notas de despesa
   * @returns Observable - com o array de estados ou vazio
   */
  get expenseNotesTypes(): Observable<Array<any>> {
    if (this._expenseNotesTypes === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetExpenseNotesTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._expenseNotesTypes = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._expenseNotesTypes);
    }
  }

  /** Obter todos os estados das despesas
   * @returns Observable - com o array de estados ou vazio
   */
  get expensesStates(): Observable<Array<any>> {
    if (this._expenseStates === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetExpensesStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._expenseStates = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._expenseStates);
    }
  }

  get expensesTypes(): Observable<Array<ExpenseType>> {
    if (this._expenseTypes === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetExpensesTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._expenseTypes = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._expenseTypes);
    }
  }


  //#endregion

  //#region UPDATE
  /**
   * Atualiza uma nota de despesaa
   * @param expenseNote nota de despesa
   * @param isSubmit se foi submetida
   * @returns Observable 
   *          response.ReturnStatus.ReturnObject => ID da nota de despesa
   */
  updateExpenseNote(expenseNote: ExpenseNote, isSubmit: boolean = false, useOnlyOneOffExpense: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();

    expenseNote.Expenses.forEach(expense => {
      // Adiciona ao formulário o ficheiro
      if (expense.File) {
        formData.append(expense.LineNumber + '', expense.File, expense.File.name);
        expense.File = null;
      }
    });

    formData.append('entity', JSON.stringify(expenseNote));
    formData.append('isSubmit', JSON.stringify(isSubmit));
    formData.append('useOnlyOneOffExpense', JSON.stringify(useOnlyOneOffExpense));
    return this.http.post(SERVICE_URL + this._controller + '/UpdateExpenseNote', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion
  //#region DELETE
  /** Apaga uma nota de despesa e as suas despesas
   * @param  {string} id
   * @returns Observable
   */
  deleteExpenseNote(id: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteExpenseNote?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /** 
   * Submete um despesa
   * @param expense despesa a submeter
   * @returns ReturnObject.Item1 => despesa depois de submtida, 
   *          ReturnObject.Item2 => novo estado na nota de despesa
   *          ReturnObject.Item3 => ID do novo ficheiro
   */
  submitExpense(expense: Expense) {
    let formData: FormData = new FormData();

    if (expense.File) {
      formData.append(expense.LineNumber + '', expense.File, expense.File.name);
      expense.File = null;
    }

    formData.append('entity', JSON.stringify(expense));
    return this.http.post(SERVICE_URL + this._controller + '/SubmitExpense', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Submete uma nota de despesa (a partir da lista de notas de despesa)
   * @param id id da nota de despesa a submeter
   * @returns 
   */
  submitExpenseNote(id: number, useOnlyOneOffExpense: boolean = false) {
    let formData: FormData = new FormData();
    formData.append('id', JSON.stringify(id));
    formData.append('useOnlyOneOffExpense', JSON.stringify(useOnlyOneOffExpense));
    return this.http.post(SERVICE_URL + this._controller + '/SubmitExpenseNote', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion


  applyExpenseNoteWorkflowStep(docID: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyExpenseNoteWorkflowStep', { docID, stepID, notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addExpenseNoteStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddExpenseNoteStepsApprover', { entityID, userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  applyExpenseWorkflowStep(docID: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyExpenseWorkflowStep', { docID, stepID, notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addExpenseStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddExpenseStepsApprover', { entityID, userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Download das notas de despesa para excel
   * @param params 
   * @returns 
   */
  downloadExcelExpenseNotesList(params: any, firstDate: string, lastDate?: string): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelExpenseNotesList', { parameters: params, firstDate: firstDate, lastDate: lastDate });
  }


  //#region SETTINGS
  

  //#region EXPENSE_NOTES

  //#region useOnlyOneOffExpenseNote
  private _useOnlyOneOffExpenseNote: boolean;

  /** Permite apenas criar despesas pontuais */
  public get useOnlyOneOffExpenseNote() {
    return this._useOnlyOneOffExpenseNote = this._authenticationService.getBooleanSetting(false, this._useOnlyOneOffExpenseNote, 'ExpenseNotes', 'UseOnlyOneOffExpenseNote');
  }
  //#endregion useOnlyOneOffExpenseNote
  
  //#region hasAuditCounter
  private _hasAuditCounter: boolean;

  /** Permite definir um contador para as notas de despesa para estas irem a auditoria  */
  public get hasAuditCounter() {
    return this._hasAuditCounter = this._authenticationService.getBooleanSetting(false, this._hasAuditCounter, 'ExpenseNotes', 'HasAuditCounter');;
  }
  //#endregion hasAuditCounter

  //#region userGroupsContext
  private _userGroupsContext: boolean;

  /** Filtro dos departamentos por contexto */
  public get userGroupsContext() {
    return this._userGroupsContext = this._authenticationService.getSettingPortal('ExpenseNotes', 'FilterBySpecificUserGroup');
  }

  //#endregion userGroupsContext

  /** Indica as roles que em que a coluna de audicão pode aparecer */
  public get rolesAudit() {
    return this.expenseNoteSettings.getSetting('RolesAudit', [])
  }

  public get hideUserGroup() {
    return this.expenseNoteSettings.getSetting<boolean>('HideUserGroup', false)
  }

  /** Indica as se o espaço fiscal está visível */
  public get showTaxAreas() {
    return this.expenseNoteSettings.getSetting<boolean>('ShowTaxAreas', false)
  }

  /** Valor por defeito no espaço fiscal por integrationID */
  public get defaultTaxAreaValue() {
    return this.expenseNoteSettings.getSetting<string>('DefaultTaxAreaValue', '')
  }

  //#endregion EXPENSE_NOTES

  //#region EXPENSES

  //#region hideStateColumn
  private _hideStateColumn: boolean;

  /** Esconde coluna do estado */
  public get hideStateColumn() {
    return this._hideStateColumn = this._authenticationService.getBooleanSetting(false, this._hideStateColumn, 'Expenses', 'HideStateColumn');
  }
  //#endregion hideStateColumn

  //#region hideNetUnitPrice
  private _hideNetUnitPrice: boolean;

  /** Esconder preço unitario */
  public get hideNetUnitPrice() {
    return this._hideNetUnitPrice = this._authenticationService.getBooleanSetting(false, this._hideNetUnitPrice, 'Expenses', 'HideNetUnitPrice');
  }
  //#endregion hideNetUnitPrice

  //#region showTotalPayableAmount
  private _showTotalPayableAmount: boolean;

  /** Mostrar valor total */
  public get showTotalPayableAmount() {
    return this._showTotalPayableAmount = this._authenticationService.getBooleanSetting(false, this._showTotalPayableAmount, 'Expenses', 'ShowTotalPayableAmount');
  }

  //#endregion _showTotalPayableAmount

  //#region showIBANs
  private _showIBANs: boolean;

  /** Mostrar campo para escolha do IBAN e escolher entre Conta Cartão (IBANs da empresa) ou não (IBANs do utilizador)  */
  public get showIBANs() {
    return this._showIBANs = this._authenticationService.getBooleanSetting(false, this._showIBANs, 'Expenses', 'ShowIBANs');
  }
  //#endregion showIBANs

  //#region ShowCostCenter
  private _showCostCenter: boolean;

  /** Mostrar centro de custo do utilizador */
  public get showCostCenter() {
    return this._showCostCenter = this._authenticationService.getBooleanSetting(false, this._showCostCenter, 'Expenses', 'ShowCostCenter');
  }
  //#endregion ShowCostCenter
  
  //#region totalNetAmountOnModalDisabled
  private _totalNetAmountOnModalDisabled: boolean;
  
  /** Coloca o campo de total disabled quando está no modal (para calcular a partir do preço e quantidade ) */
  public get totalNetAmountOnModalDisabled() {
    return this._totalNetAmountOnModalDisabled = this._authenticationService.getBooleanSetting(false, this._totalNetAmountOnModalDisabled, 'Expenses', 'TotalNetAmountOnModalDisabled');
  }
  //#endregion totalNetAmountOnModalDisabled

  //#region showRefundButton
  private _showRefundButton: boolean;
  
  /** Indica se mostra o botao de reembolso no modal */
  public get showRefundButton() {
    return this._showRefundButton = this._authenticationService.getBooleanSetting(true, this._showRefundButton, 'Expenses', 'ShowRefundButton');
  }
  //#endregion showRefundButton
  
  //#endregion EXPENSES

  //#endregion SETTINGS
}