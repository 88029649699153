import { Injectable, ChangeDetectorRef } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';

@Injectable()
export class TranslateValueService {
  private result: string;
  private _datepickerTranslates: Array<string>;

  constructor(private translateService: TranslateService) { }

  get(value: string, params?: Object): string {
    this.translateService.get(value, params).subscribe((res: string) => {
      this.result = res;
    }, error => {
      this.result = value;
    });
    return this.result;
  }

  /**
   * Usar quando o valor já tiver sido carregado
   * @param  {string|string[]} value
   * @returns string
   */
  translateValue(value: string | string[]): string {
    return this.translateService.instant(value);
  }


  /**
   * @param  {string} value
   * @param  {any} args exemplo: {'0': '123', '1': 'sss'}
   * @param  {ChangeDetectorRef} _cdr
   * @returns string
   */
  translatePipe(value: string, args: any, _cdr: ChangeDetectorRef): string {
    return new TranslatePipe(this.translateService, _cdr).transform(value, args);
  }

  get translateLanguage(): string {
    return this.translateService.currentLang;
  }

  get datepickerTranslates() {
    if (!this._datepickerTranslates) {
      this._datepickerTranslates = this.getTranslateDatePickerTooltips();
    }
    return this._datepickerTranslates;
  }

  getTranslateDatePickerTooltips(): any {
    let resultTans = this.translateValue(
      ['DATETIME.1', 'DATETIME.2', 'DATETIME.3', 'DATETIME.4', 'DATETIME.5', 'DATETIME.6', 'DATETIME.7',
        'DATETIME.8', 'DATETIME.9', 'DATETIME.10', 'DATETIME.11', 'DATETIME.12', 'DATETIME.13', 'DATETIME.14',
        'DATETIME.15', 'DATETIME.16', 'DATETIME.17', 'DATETIME.18', 'DATETIME.19', 'DATETIME.20', 'DATETIME.21',
        'DATETIME.22', 'DATETIME.23', 'DATETIME.24', 'DATETIME.25']);
    return {
      today: resultTans['DATETIME.1'],
      clear: resultTans['DATETIME.2'],
      close: resultTans['DATETIME.3'],
      selectMonth: resultTans['DATETIME.4'],
      prevMonth: resultTans['DATETIME.5'],
      nextMonth: resultTans['DATETIME.6'],
      selectYear: resultTans['DATETIME.7'],
      prevYear: resultTans['DATETIME.8'],
      nextYear: resultTans['DATETIME.9'],
      selectDecade: resultTans['DATETIME.10'],
      prevDecade: resultTans['DATETIME.11'],
      nextDecade: resultTans['DATETIME.12'],
      prevCentury: resultTans['DATETIME.13'],
      nextCentury: resultTans['DATETIME.14'],
      incrementHour: resultTans['DATETIME.15'],
      pickHour: resultTans['DATETIME.16'],
      decrementHour: resultTans['DATETIME.17'],
      incrementMinute: resultTans['DATETIME.18'],
      pickMinute: resultTans['DATETIME.19'],
      decrementMinute: resultTans['DATETIME.20'],
      incrementSecond: resultTans['DATETIME.21'],
      pickSecond: resultTans['DATETIME.22'],
      decrementSecond: resultTans['DATETIME.23'],
      togglePeriod: resultTans['DATETIME.24'],
      selectTime: resultTans['DATETIME.25'],
    };
  }
}
