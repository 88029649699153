import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
  pure: false
})
export class FilterArrayPipe implements PipeTransform {
    transform(items: any[], args: any[]): any {
        if (!items || !args[0] || !args[1]) {
            return items;
        }

        return items.filter(item => item[args[1]] == args[0]);
    }
}
