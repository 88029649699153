import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConfig } from 'src/app/configs/app.config';
import { takeUntil, first, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { SERVICE_URL ,WEBSITE_URL } from 'src/app/constants/global';
import { EventService } from '../../services/event.service';
import { DOCUMENT } from '@angular/common';


declare var App: any;

@Component({
  templateUrl: './approval-screen.html',
  styles: [
    // Formulário
    '.login-container .login-columns { margin: 50px auto; display: flex; }',
    '.login-container .slideshow { margin: auto; width: 600px; display: inline-block; }',
    '.login-container .block-flat { margin: 0; border-radius: 0; box-shadow: none; }',
    // '.login-container .block-flat .content { height: 369px; }',
    '.login-container .login-form { margin: auto 20px auto auto; display: inline-block; }',
    '@media screen and (max-width: 965px) { .login-container .login-columns { display: block; } .login-container .login-form { display: block; margin: auto auto 20px; } .login-container .slideshow {display: block; margin: auto auto 20px; width: auto; } .width-950, .width-450 { width: auto; } }',
    '.ngucarousel > .ngu-touch-container > .ngucarousel-items > .item { padding: 0 !important; }'
  ],

  encapsulation: ViewEncapsulation.None

})


export class ApprovalScreenComponent implements OnInit {


  acceptLink: string = "";
  declineLink: string = "";
  serverUrl: string = SERVICE_URL;
  numberOfApproval: string = "";
  typeOfApproval: string = "";


  constructor(private config: AppConfig,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public ngAfterViewInit(): void {
    App.init();
    let logoImg = this.config.getConfig('LOGO_IMG');
    document.querySelector('.login-theme .logo-img').setAttribute('src', (document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg));
  }



  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {

      let id = params['id'];
      let approveAction = params['approveAction'];
      let local = params['local'];     
      
      this.numberOfApproval = params['number'];     
      this.typeOfApproval = params['type'];      

      this.acceptLink = this.serverUrl + "Services/ManageDocument?id=" + id + "&op=" + approveAction + "&locale=" + local;
      this.declineLink = this.serverUrl + "Services/ManageDocument?id=" + id + "&op=RO&locale=" + local; 
      
      
    });
   

  }
  
  acceptFunc(){
    this.document.location.href = this.acceptLink;
  }
  
  declineFunc(){
    this.document.location.href = this.declineLink;
  }



  //Example Links
  
  //Link From email:
  //http://localhost:4200/approval-screen?requestID=AUCEOROJEKQFJXRZYFOD&approveAction=A1&local=pt_PT&number=545ADWASDWADWDAD64&type=Encomenda
  
  //Link To Approve:
  //http://localhost:58020/Services/ManageDocument?id=HYKLEVFQCDYCAEUSLZOJ&op=A1&locale=pt_PT

  ngOnDestroy() { }
}
