import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { CostCenter } from '../models/costCenter';
import { DistributionList } from '../models/distribution-list';
import { GenericType } from '../models/generic-type';
import { Dropdown } from '../models/dropdown';
import { Currency } from '../models/currency';
import { Channel } from '../models/channel';
import { Country } from '../models/country';
import { UnitOfMeasure, UnitOfMeasureType } from '../models/unit-of-measure';
import { ValueAddedTax, ValueAddedTaxExemptReason } from '../models/value-added-tax';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Category } from '../models/category';
import { Family } from '../models/family';
import { Language } from '../models/language';
import { Company } from '../models/company';
import { AuthenticationService } from './authentication.service';
import { PricesList } from '../models/prices-list';
import { Role } from '../models/role';
import { Location } from '../models/location';
import { CompanyParameter } from '../models/company-parameter';
import { DropdownEditable } from '../models/dropdown-editable';
import { GenericFieldRule } from '../models/generic-field-rule';
import { GenericFieldConfig } from '../models/generic-field-config';
import { MenuOption } from '../models/menu-option';
import { ServiceModule } from '../models/service-module';
import { GenericState } from '../models/generic-state';
import { TradingPartnerParam } from '../models/trading-partner-param';
import { GenericFieldExtension } from '../models/generic-field-extension';
import { TaxArea } from '../models/tax-area';
import { MarketType } from "../models/market-type";
import { AccountingVAT } from '../models/AccountingVAT';
import { PaymentCondition } from '../models/payment-condition';
import { ServiceMethods } from './service-methods';

@Injectable()
export class CommonService extends ServiceMethods {
  private _costCenters: CostCenter[];
  private _distributions: DistributionList[];
  private _genericFieldConfigurations: any[] = [];
  private _genericTypes: GenericType[] = [];
  private _genericStates: GenericState[] = [];
  private _currencies: Currency[];
  private _genders: Dropdown[];
  private _debitOrCredit: Dropdown[];
  private _costCenterConfigType: Dropdown[];
  private _costCenterConfigIncidence: Dropdown[];
  private _personTypes: Dropdown[];
  private _exchangeRateCategories: Dropdown[];
  private _specialVATSchemes: Dropdown[];
  private _dispatchConditions: Dropdown[];
  private _packagings: Dropdown[];
  private _palletTypes: Dropdown[];
  private _packagePellicles: Dropdown[];
  private _packageCorners: Dropdown[];
  private _distributionChannels: Dropdown[];
  private _accessibilitys: Dropdown[];
  private _paymentTypes: Dropdown[];
  private _documentTypes: Dropdown[];
  private _channels: Channel[];
  private _serviceProviders: Company[];
  private _countries: Country[];
  private _languages: Language[];
  private _unitiesOfMeasure: UnitOfMeasure[];
  private _unitiesOfMeasureG: UnitOfMeasure[];
  private _unitiesOfMeasureW: UnitOfMeasure[];
  private _unitiesOfMeasureV: UnitOfMeasure[];
  private _salesOrganizations: Dropdown[];
  private _scriptTypes: Dropdown[];
  private _contractTypes: Dropdown[];
  private _contractDivergences: Dropdown[];
  private _contractContractBillingCycles: Dropdown[];
  private _contractDetailsTaxType: Dropdown[];
  private _valueAddedTaxes: ValueAddedTax[];
  private _valueAddedTaxExemptReasons: ValueAddedTaxExemptReason[];
  public _families: Family[];
  private _categories: Category[];
  private _pricesLists: PricesList[];
  private _deliveryDateEval: Dropdown[];
  private _receivedQuantityEval: Dropdown[];
  private _productEval: Dropdown[];
  private _evaluationEval: Dropdown[];
  private _typologiesOrder: Dropdown[];
  private _importationProfileFileType: Dropdown[];
  private _allowedRoles: any[];
  private _allRoles: Role[];
  private _invoiceTaxesTypes: any[];
  private _otherReferenceTypes: Dropdown[];
  private hideNonSPCompany: boolean;
  private showAboutID4: boolean;
  private _allServiceModules: ServiceModule[];
  private attachmentSize: number;
  private _returnReasons: Dropdown[];

  //Settings
  acceptableFormat: string;
  private hidePriceList: boolean;

  // Listas
  private dropdownLists: Dropdown[][] = [];
  private _taxAreas: TaxArea[];
  private _accountingVats: AccountingVAT[];
  private _taxAreasWithoutAccountingVATs: TaxArea[];
  private _marketTypes: MarketType[];
  private _withHoldings: WithHolding[];

  constructor(http: HttpClientCustom, _masterService: MasterService, _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) {
    super(_errorTreat, _masterService, http, 'Common');
  }

  get costCenters(): Observable<CostCenter[]> {
    if (this._costCenters === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetCostCenters').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._costCenters = responseReturn.ReturnStatus.ReturnObject as CostCenter[];
              return this._costCenters;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._costCenters);
    }
  }

  get distributions(): Observable<DistributionList[]> {
    if (this._distributions === undefined) {
      return this._http.get(SERVICE_URL + 'DistributionList/GetAllForOptions').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._distributions = responseReturn.ReturnStatus.ReturnObject as DistributionList[];
              return this._distributions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._distributions);
    }
  }

  getGenericTabsConfiguration(context: string, id: number): Observable<any> {
    return this._http.get(SERVICE_URL + this._controller + '/GetGenericTabsConfiguration?context=' + context + '&id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject as any[];
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getGenericFieldsConfiguration(context: string, subcontext: string = null, withoutSession: boolean = false): Observable<{ configurations: GenericFieldConfig[], languages: string[] }> {
    if (this._genericFieldConfigurations[context + (subcontext ? subcontext : '')] === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetGenericFieldsConfiguration?context=' + context +
        (subcontext ? '&subcontext=' + subcontext : '') + '&withoutSession=' + withoutSession)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._genericFieldConfigurations[context + (subcontext ? subcontext : '')] = responseReturn.ReturnStatus.ReturnObject as { configurations: GenericFieldConfig[], languages: string[] };
              return this._genericFieldConfigurations[context + (subcontext ? subcontext : '')];
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._genericFieldConfigurations[context + (subcontext ? subcontext : '')]);
    }
  }

  getGenericTypes(context: string, noSession: boolean = false): Observable<GenericType[]> {
    if (this._genericTypes[context] === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetGenericTypes?context=' + context + '&noSession=' + noSession).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._genericTypes[context] = responseReturn.ReturnStatus.ReturnObject;
              return this._genericTypes[context];
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._genericTypes[context].filter(r => r.Context === context));
    }
  }

  getGenericStates(context: string): Observable<GenericState[]> {
    if (this._genericStates[context] === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetGenericStates?context=' + context).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._genericStates[context] = responseReturn.ReturnStatus.ReturnObject;
              return this._genericStates[context];
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._genericStates[context].filter(r => r.Context === context));
    }
  }

  getServiceProviders(excludingID?: number): Observable<Company[]> {
    if (this._serviceProviders === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetServiceProviders' + (excludingID ? '?excludingID=' + excludingID : '')).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._serviceProviders = responseReturn.ReturnStatus.ReturnObject as Company[];
              return this._serviceProviders;
            } else {
              // this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._serviceProviders);
    }
  }

  get currencies(): Observable<Currency[]> {
    if (this._currencies === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetCurrencies').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._currencies = responseReturn.ReturnStatus.ReturnObject as Currency[];
              return this._currencies;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._currencies);
    }
  }

  /// Dropdowns
  get genders(): Observable<Dropdown[]> {
    if (this._genders === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.GENDERS_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._genders = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._genders;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._genders);
    }
  }

  get families(): Observable<Family[]> {
    if (this._families === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetAllFamilies').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._families = responseReturn.ReturnStatus.ReturnObject as Family[];
              return this._families;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._families);
    }
  }

  get categories(): Observable<Category[]> {
    if (this._categories === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetAllCategories').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._categories = responseReturn.ReturnStatus.ReturnObject as Category[];
              return this._categories;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._categories);
    }
  }

  get pricesLists(): Observable<PricesList[]> {
    if (this._pricesLists === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetAllPricesLists').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._pricesLists = responseReturn.ReturnStatus.ReturnObject as PricesList[];
              return this._pricesLists;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._pricesLists);
    }
  }

  get debitCredit(): Observable<Dropdown[]> {
    if (this._debitOrCredit === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.DEBIT_CREDIT).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._debitOrCredit = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._debitOrCredit;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._debitOrCredit);
    }
  }

  get costCenterConfigType(): Observable<Dropdown[]> {
    if (this._costCenterConfigType === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.COST_CENTER_CONFIG_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._costCenterConfigType = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._costCenterConfigType;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._costCenterConfigType);
    }
  }

  get costCenterConfigIncidence(): Observable<Dropdown[]> {
    if (this._costCenterConfigIncidence === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.COST_CENTER_CONFIG_INCIDENCE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._costCenterConfigIncidence = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._costCenterConfigIncidence;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._costCenterConfigIncidence);
    }
  }

  get personTypes(): Observable<Dropdown[]> {
    if (this._personTypes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PERSON_TYPE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._personTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._personTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._personTypes);
    }
  }

  get exchangeRateCategories(): Observable<Dropdown[]> {
    if (this._exchangeRateCategories === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.EXCHANGE_RATE_CATEGORY_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._exchangeRateCategories = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._exchangeRateCategories;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._exchangeRateCategories);
    }
  }

  get specialVATSchemes(): Observable<Dropdown[]> {
    if (this._specialVATSchemes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.SPECIAL_VAT_SCHEME_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._specialVATSchemes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._specialVATSchemes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._specialVATSchemes);
    }
  }

  get shippingConditions(): Observable<Dropdown[]> {
    if (this._dispatchConditions === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.SHIPPING_CONDITION_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._dispatchConditions = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._dispatchConditions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._dispatchConditions);
    }
  }

  get packagings(): Observable<Dropdown[]> {
    if (this._packagings === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PACKAGING_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packagings = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packagings;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packagings);
    }
  }

  get palletTypes(): Observable<Dropdown[]> {
    if (this._palletTypes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PALLET_TYPE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._palletTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._palletTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._palletTypes);
    }
  }

  get packagePellicles(): Observable<Dropdown[]> {
    if (this._packagePellicles === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PACKAGE_PELLICLE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packagePellicles = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packagePellicles;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packagePellicles);
    }
  }

  get packageCorners(): Observable<Dropdown[]> {
    if (this._packageCorners === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PACKAGE_CORNER_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packageCorners = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packageCorners;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packageCorners);
    }
  }

  get distributionChannels(): Observable<Dropdown[]> {
    if (this._distributionChannels === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.DISTRIBUTION_CHANNEL_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._distributionChannels = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._distributionChannels;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._distributionChannels);
    }
  }

  get accessibilitys(): Observable<Dropdown[]> {
    if (this._accessibilitys === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.ACCESSIBILITY_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._accessibilitys = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._accessibilitys;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._accessibilitys);
    }
  }

  get paymentTypes(): Observable<Dropdown[]> {
    if (this._paymentTypes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PAYMENT_TYPE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._paymentTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._paymentTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._paymentTypes);
    }
  }

  get documentTypes(): Observable<Dropdown[]> {
    if (this._documentTypes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.DOCUMENT_TYPE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._documentTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._documentTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._documentTypes);
    }
  }

  get salesOrganizations(): Observable<Dropdown[]> {
    if (this._salesOrganizations === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.SALES_ORGANIZATION_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._salesOrganizations = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._salesOrganizations;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._salesOrganizations);
    }
  }

  get scriptTypes(): Observable<Dropdown[]> {
    if (this._scriptTypes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.SCRIPT_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._scriptTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._scriptTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._scriptTypes);
    }
  }

  get deliveryDateEval(): Observable<Dropdown[]> {
    if (this._deliveryDateEval === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.DELIVERY_DATE_EVAL).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._deliveryDateEval = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._deliveryDateEval;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._deliveryDateEval);
    }
  }

  get receivedQuantityEval(): Observable<Dropdown[]> {
    if (this._receivedQuantityEval === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.RECEIVED_QUANTITY_EVAL).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._receivedQuantityEval = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._receivedQuantityEval;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._receivedQuantityEval);
    }
  }

  get productEval(): Observable<Dropdown[]> {
    if (this._productEval === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.PRODUCT_EVAL).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._productEval = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._productEval;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._productEval);
    }
  }

  get evaluationEval(): Observable<Dropdown[]> {
    if (this._evaluationEval === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.EVALUATION_EVAL).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._evaluationEval = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._evaluationEval;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._evaluationEval);
    }
  }

  get getReturnReasons(): Observable<Dropdown[]> {
    if (this._returnReasons === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.RECEPTION_RETURN_RESONS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._returnReasons = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._returnReasons;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._returnReasons);
    }
  }
  /// END - Dropdowns

  get channels(): Observable<Channel[]> {
    if (this._channels === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetChannels').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._channels = responseReturn.ReturnStatus.ReturnObject as Channel[];
              return this._channels;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._channels);
    }
  }

  get serviceProviders(): Observable<Company[]> {
    if (this._serviceProviders === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetServiceProviders').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._serviceProviders = responseReturn.ReturnStatus.ReturnObject as Company[];
              return this._serviceProviders;
            } else {
              // this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._serviceProviders);
    }
  }

  get countries(): Observable<Country[]> {
    if (this._countries === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetCountries').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._countries = responseReturn.ReturnStatus.ReturnObject as Country[];
              return this._countries;
            } else {
              // this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._countries);
    }
  }

  get languages(): Observable<Language[]> {
    if (this._languages === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetLanguages').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._languages = responseReturn.ReturnStatus.ReturnObject as Language[];
              return this._languages;
            } else {
              // this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._languages);
    }
  }

  resetLanguages() {
    this._languages = undefined;
  }

  get unitiesOfMeasure(): Observable<UnitOfMeasure[]> {
    if (this._unitiesOfMeasure === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetUnitiesOfMeasure').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._unitiesOfMeasure = responseReturn.ReturnStatus.ReturnObject as UnitOfMeasure[];
              return this._unitiesOfMeasure;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._unitiesOfMeasure);
    }
  }

  public unitiesOfMeasureByType(type: string): Observable<UnitOfMeasure[]> {
    return this.unitiesOfMeasure.pipe(map(units => {

      let result: UnitOfMeasure[];

      if (type === UnitOfMeasureType.General) { // Geral
        if (this._unitiesOfMeasureG) {
          result = this._unitiesOfMeasureG;
        } else {
          this._unitiesOfMeasureG = units.filter(x => x.UOMType === UnitOfMeasureType.General || !x.UOMType);
          result = this._unitiesOfMeasureG;
        }
      } else if (type === UnitOfMeasureType.Weight) { // Peso
        if (this._unitiesOfMeasureW) {
          result = this._unitiesOfMeasureW;
        } else {
          this._unitiesOfMeasureW = units.filter(x => x.UOMType === UnitOfMeasureType.Weight || !x.UOMType);
          result = this._unitiesOfMeasureW;
        }
      } else if (type === UnitOfMeasureType.Volume) { // Volume
        if (this._unitiesOfMeasureV) {
          result = this._unitiesOfMeasureV;
        } else {
          this._unitiesOfMeasureV = units.filter(x => x.UOMType === UnitOfMeasureType.Volume || !x.UOMType);
          result = this._unitiesOfMeasureV;
        }
      } else {
        result = [];
      }

      return result;
    }));
  }

  get valueAddedTaxes(): Observable<ValueAddedTax[]> {
    if (this._valueAddedTaxes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetValueAddedTaxes').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._valueAddedTaxes = responseReturn.ReturnStatus.ReturnObject as ValueAddedTax[];
              return this._valueAddedTaxes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._valueAddedTaxes);
    }
  }

  get valueAddedTaxExemptReasons(): Observable<ValueAddedTaxExemptReason[]> {
    if (this._valueAddedTaxExemptReasons === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetValueAddedTaxesReasons').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._valueAddedTaxExemptReasons = responseReturn.ReturnStatus.ReturnObject as ValueAddedTaxExemptReason[];
              return this._valueAddedTaxExemptReasons;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._valueAddedTaxExemptReasons);
    }
  }

  get allowedRoles(): Observable<Array<any>> {
    if (this._allowedRoles === undefined) {
      return this.getComplements('GetAllowedRoles').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._allowedRoles = response.ReturnStatus.ReturnObject.Roles;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._allowedRoles);
    }
  }

  getAllRoles(): Observable<Role[]> {
    if (this._allRoles === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetAllRoles').pipe(
        map((responseRaw: any) => {
          try {
            let response = this._masterService.convertToReturnStatusHtml(responseRaw);

            if (response.ReturnStatus.Successfull) {
              this._allRoles = response.ReturnStatus.ReturnObject as Role[];

              return this._allRoles.sort((n1: Role, n2: Role) => {
                if (n1.Name > n2.Name) {
                  return 1;
                }

                if (n1.Name < n2.Name) {
                  return -1;
                }

                return 0;
              });
            } else if (!response.IsAuthenticated) {
              // this.router.navigate(['/login']);
              document.location.href = document.location.origin;
            } else {
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._allRoles.sort((n1: Role, n2: Role) => {
        if (n1.Name > n2.Name) {
          return 1;
        }

        if (n1.Name < n2.Name) {
          return -1;
        }
        return 0;
      }));
    }
  }

  /**
   * Obter tipos de impostos de faturas, se erro vem vazio
   * @returns Observable - com o array de tipos de impostos ou vazio
   */
  get invoiceTaxesTypes(): Observable<any[]> {
    if (this._invoiceTaxesTypes === undefined) {
      return this._http.get(SERVICE_URL + 'Invoice/GetTaxesTypes').pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return this._invoiceTaxesTypes = responseReturn.ReturnStatus.ReturnObject as any[];
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._invoiceTaxesTypes);
    }
  }

  get otherReferenceTypes(): Observable<Dropdown[]> {
    if (this._otherReferenceTypes === undefined) {
      return this._http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.OTHER_REFERENCE_TYPE)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._otherReferenceTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._otherReferenceTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._otherReferenceTypes);
    }
  }

  get ContractTypes(): Observable<Dropdown[]> {
    if (this._contractTypes === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.CONTRACT_TYPE_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._contractTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._contractTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._contractTypes);
    }
  }
  get ContractDivergences(): Observable<Dropdown[]> {
    if (this._contractDivergences === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.CONTRACT_ERROR_NOTIFICATION_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._contractDivergences = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._contractDivergences;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._contractDivergences);
    }
  }

  get ContractBillingCycles(): Observable<Dropdown[]> {
    if (this._contractContractBillingCycles === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.CONTRACT_BILLING_CYCLE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._contractContractBillingCycles = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._contractContractBillingCycles;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._contractContractBillingCycles);
    }
  }

  get ContractDetailTaxType(): Observable<Dropdown[]> {
    if (this._contractDetailsTaxType === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.CONTRACT_DETAIL_TAX_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._contractDetailsTaxType = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._contractDetailsTaxType;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._contractDetailsTaxType);
    }
  }

  get typologiesOrder(): Observable<Dropdown[]> {
    if (this._typologiesOrder === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.INCOTERM).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._typologiesOrder = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._typologiesOrder;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._typologiesOrder);
    }
  }

  get impProfileFileType(): Observable<Dropdown[]> {
    if (this._importationProfileFileType === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + Dropdown.IMPORTATION_PROFILE_FILE_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._importationProfileFileType = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._importationProfileFileType;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._importationProfileFileType);
    }
  }

  get taxAreasWithoutAccountingVATs(): Observable<TaxArea[]> {
    if (!this._taxAreasWithoutAccountingVATs) {
      return this.getComplements('GetTaxAreas', { getAccountingVATs: false }).pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._taxAreasWithoutAccountingVATs = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<TaxArea>();
        }
      }));
    } else {
      return observableOf(this._taxAreasWithoutAccountingVATs);
    }
  }

  get taxAreas(): Observable<TaxArea[]> {
    if (!this._taxAreas) {
      return this.getComplements('GetTaxAreas').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._taxAreas = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<TaxArea>();
        }
      }));
    } else {
      return observableOf(this._taxAreas);
    }
  }

  get accountingVats(): Observable<AccountingVAT[]> {
    if (!this._accountingVats) {
      return this.getComplements('GetAccountingVats').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._accountingVats = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<AccountingVAT>();
        }
      }));
    } else {
      return observableOf(this._accountingVats);
    }
  }

  get marketTypes(): Observable<MarketType[]> {
    if (!this._marketTypes) {
      return this.getComplements('GetMarketTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._marketTypes = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<MarketType>();
        }
      }));
    } else {
      return observableOf(this._marketTypes);
    }
  }

  get withHoldings(): Observable<WithHolding[]> {
    if (this._withHoldings === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetwithHoldings').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              return this._withHoldings = responseReturn.ReturnStatus.ReturnObject as WithHolding[];;
            } else {
              // this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._withHoldings);
    }
  }
  
  get userCostCenters(): Observable<CostCenter[]> {
    return this.getData({ property: '_userCostCenters', url: 'GetUserCostCenters', params: { userID: this.authenticationService.session.user.ID }});
  }

  getDropdownData(type: string, parentCode: string = null): Observable<Dropdown[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetDropdownData?type=' + type + '&parentCode=' + parentCode).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getDropdownDataByCode(type: string, itemCode: string, parentCode: string = null): Observable<Dropdown> {
    return this._http.get(SERVICE_URL + this._controller + '/GetDropdownDataByCode?type=' + type + '&itemCode=' + itemCode + '&parentCode=' + parentCode).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject as Dropdown;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            return null;
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getDropdownDataWithoutSession(type: string, parentCode: string = null): Observable<Dropdown[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetDropdownDataWithoutSession?type=' + type + '&parentCode=' + parentCode).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getLocationsByType(companyID: number, filterByUser: boolean, onlyActive: boolean, type: number): Observable<Location[]> {
    return this._http.get(SERVICE_URL + 'Location/GetAll?companyID=' + companyID + '&filterByUser=' + filterByUser + '&onlyActive=' + onlyActive
      + (type ? '&type=' + type : '')).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              return responseReturn.ReturnStatus.ReturnObject as Location[];
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
  }

  // Setting layout
  gethideNonSPCompany(): boolean {
    if (typeof this.hideNonSPCompany === 'undefined') { // verificar se ainda nao tem valor
      if (this.authenticationService.getSettingPortal('Layout', 'hideNonSPCompany') != null) {
        this.hideNonSPCompany = this.authenticationService.getSettingPortal('Layout', 'hideNonSPCompany');
      } else {
        this.hideNonSPCompany = false;
      }
    }
    return this.hideNonSPCompany;
  }

  getshowAboutID4(): boolean {
    if (typeof this.showAboutID4 === 'undefined') { // verificar se ainda nao tem valor
      if (this.authenticationService.getSettingPortal('Layout', 'ShowAboutID4') != null) {
        this.showAboutID4 = this.authenticationService.getSettingPortal('Layout', 'ShowAboutID4');
      } else {
        this.showAboutID4 = false;
      }
    }
    return this.showAboutID4;
  }

  getCompanyParameterValue(id: number, parameter: string): Observable<any> {
    return this._http.get(SERVICE_URL + this._controller + '/GetCompanyParameterValue?id=' + id + '&parameter=' + parameter).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject as any[];
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getCompanyParameters(id: number): Observable<CompanyParameter[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetCompanyParameters?id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject as CompanyParameter[];
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getDropDownsEditable(): Observable<DropdownEditable[]> {
    return this._http.get(SERVICE_URL + 'DropDown/GetDropDownsEditable')
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getFieldsRules(context: string): Observable<GenericFieldRule[]> {
    return this._http.get(SERVICE_URL + 'GenericFieldRule/GetList?context=' + context)
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getMenuFolders(): Observable<MenuOption[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetMenuFolders')
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getCategories(context: string = ''): Observable<Dropdown[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetAllCategories?context=' + context).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  //#region Listas de Valores
  getDropdownsList(dropdownID: string, addEmptyFirst = true, parentCode: string = null, filterByCompany: boolean = false): Observable<Dropdown[]> {
    if (this.dropdownLists[dropdownID] === undefined) {
      return this._http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + dropdownID + '&parentCode=' + parentCode + '&filterByCompany=' + filterByCompany).pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            if (addEmptyFirst) {
              this.dropdownLists[dropdownID] = [new Dropdown(null, null, null, null, -1)];
            } else {
              this.dropdownLists[dropdownID] = [];
            }

            this.dropdownLists[dropdownID].push(...responseReturn.ReturnStatus.ReturnObject);
            return this.dropdownLists[dropdownID];
          } else {
            return this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this.dropdownLists[dropdownID]);
    }
  }

  //#endregion Listas de Valores

  getAllServiceModules(): Observable<ServiceModule[]> {

    return this._http.get(SERVICE_URL + this._controller + '/GetAllModules').pipe(
      map((responseRaw: any) => {
        try {
          let response = this._masterService.convertToReturnStatusHtml(responseRaw);

          if (response.ReturnStatus.Successfull) {
            this._allServiceModules = response.ReturnStatus.ReturnObject as ServiceModule[];

            return this._allServiceModules.sort((n1: ServiceModule, n2: ServiceModule) => {
              if (n1.Name > n2.Name) {
                return 1;
              }

              if (n1.Name < n2.Name) {
                return -1;
              }

              return 0;
            });
          } else if (!response.IsAuthenticated) {
            // this.router.navigate(['/login']);
            document.location.href = document.location.origin;
          } else {
            return [];
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getTradingPartnerParamsForPartner(ownerID: number = 0, partnerID: number = 0): Observable<TradingPartnerParam[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetTradingPartnerParamsForPartner?ownerID=' + ownerID + '&partnerID=' + partnerID).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject as TradingPartnerParam[];
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }
  //GetGenericFieldsExtension

  
 get_acceptableFormat(defaultValue: string): string {
    if (typeof this.acceptableFormat === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ScannedDocument', 'AcceptableFormat');
      if (settingValue != null) {
        this.acceptableFormat = settingValue;
      } else {
        this.acceptableFormat = defaultValue;
      }
    }
    return this.acceptableFormat; 
  }

  getGenericFieldsExtension(context: string,referenceID:number ,identifier: string = null): Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetGenericFieldsExtension?context=' + context + '&referenceID=' + referenceID + 
    (identifier != null ? '&identifier=' + identifier : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  createOrUpdateGenericFieldsExtension(genericFields:GenericFieldExtension[]): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/CreateOrUpdateGenericFieldsExtension', { genericFields: genericFields}).pipe( 
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  
  /**
   * Obter ExtendedFieldExtensions pelo contexto, id de referência e código do campo
   * @example getGenericFieldExtension('OrderDetail', 5, 'Process')
   * @param context contexto 
   * @param referenceID id da referencia
   * @param fieldCode codigo do campo
   * @returns 
   */
  getGenericFieldExtension(context: string,referenceID:number ,fieldCode: string): Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetGenericFieldExtension?context=' + context + '&referenceID=' + referenceID + '&fieldCode=' + fieldCode).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  createOrUpdateGenericFieldExtension(genericField:GenericFieldExtension): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/CreateOrUpdateGenericFieldExtension', { genericField: genericField}).pipe( 
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteGenericFieldExtension(genericField:GenericFieldExtension): Observable<ReturnStatusHtml> {
    return this._http.delete(SERVICE_URL + this._controller + '/DeleteGenericFieldExtension?id='+genericField.ID).pipe( 
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  getEventTypes(): Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetEventsTypesEmail').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEventType(subType :string): Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetEventTypeEmail?eventType='+subType).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  
  createEventTypeEmails(eventType : any): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/CreateEventsTypesEmail', {entity: eventType}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateEventTypeEmails(eventType : any): Observable<ReturnStatusHtml> {
    return this._http.put(SERVICE_URL + this._controller + '/UpdateEventsTypesEmail', {entity: eventType}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteEventTypeEmails(eventType : any): Observable<ReturnStatusHtml> {
    return this._http.delete(SERVICE_URL + this._controller + '/DeleteEventsTypesEmail?id='+eventType.ID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region Settings
	deleteGenericFieldsExtensions(genericFields:GenericFieldExtension[]): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/DeleteGenericFieldsExtensions', { genericFields: genericFields}).pipe( 
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_attachmentSize(defaultValue: number): number {
    if (typeof this.attachmentSize === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Digarc', 'AttachmentSize');
      if (settingValue != null) {
        this.attachmentSize = settingValue;
      } else {
        this.attachmentSize = defaultValue;
      }
    }
    return this.attachmentSize;
  }

  get_hidePriceList(defaultValue: boolean): boolean {
    if (typeof this.hidePriceList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ClientCatalog', 'HidePriceList');
      if (settingValue != null) {
        this.hidePriceList = settingValue;
      } else {
        this.hidePriceList = defaultValue;
      }
    }
    return this.hidePriceList; 
  }
  /** usa uma classe de css consoante setting 
   *  normalmente é usado com o ngclass exemplo:
   *  [ngClass]="commonService.useClassBasedOnSetting(setting,'col-md-3','col-md-6')"
   * @param  {boolean} setting setting 
   * @param  {string} defaultClass classe usada caso não tenho o setting
   * @param  {string} settingClass classe usada caso tenho o setting
   */
  useClassBasedOnSetting(setting: boolean, defaultClass: string, settingClass: string){
    if(setting){
      return settingClass;
    }else{
      return defaultClass;
    }
  }


  /*get_OrderNumberLabel(defaultValue: string): string {
    if (typeof this.orderNumberLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Transports', 'OrderNumberLabel');
      if (settingValue != null) {
        this.orderNumberLabel = settingValue;
      } else {
        this.orderNumberLabel = defaultValue;
      }
    }
    return this.orderNumberLabel;
  }*/

  //#endregion}

  getGenericChangesLog(context: string,referenceID:number) : Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetGenericChangeLog?context=' + context + '&referenceID=' + referenceID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEventNotificiationIgnoreList(userID : number ) : Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetEventNotificationIgnoreList?userID='+userID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
} 
