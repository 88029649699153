import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowStep } from '../../../models/workflow-step';
import { User } from '../../../models/user';
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { CONTEXT } from '../../../constants/global';
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { WorkflowEmailsModalComponent } from '../workflow-emails-modal/workflow-emails-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './workflow-change-modal.html'
})
export class WorkflowChangeModalComponent implements OnInit, OnDestroy {
  allowChange: boolean;
  id: number;
  context: string;
  form: UntypedFormGroup;
  steps: WorkflowStep[] = [];
  users: User[] = [];
  isGenericWorkflow: boolean;
  collPnPopup1: boolean = false;
  logs: any[] = [];
  tabs: any[] = [];
  // Settings
  showEmailStates: number[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public dialogRef: MatDialogRef<WorkflowChangeModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private translateService: TranslateService,
    private userService: UserService, private authenticationService: AuthenticationService, private dialog: MatDialog, private _errorTreat: ErrorTreatmentFunctions,
    private workflowService: WorkflowService, private formBuilder: UntypedFormBuilder) {
    this.id = data.id;
    this.context = data.context;
    this.allowChange = data.allowChange;
    this.steps = data.steps;
    // Settings
    this.showEmailStates = this.workflowService.get_showEmailStates(this.showEmailStates);
    // Verificar se o contexto é do tipo genérico
    this.isGenericWorkflow = CONTEXT[this.context] ? CONTEXT[this.context].WORKFLOW.IS_GENERIC : true;
  }

  ngOnInit(): void {
    this.userService.getAll(this.authenticationService.session.company.ID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (!response.ReturnStatus.Successfull) {
        this._errorTreat.treatErrorResponse(response);
      }

      this.users = (response.ReturnStatus.ReturnObject as User[]).filter(r => r.ID !== this.authenticationService.session.user.ID);

      this.buildForm();
    });
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      'Step': null,
      'Notes': null,
      'SelectedUser': null
    });
  }

  // 1 - Área de transições; 2 - Área de atribuir ações a outro utilizador
  valueChanged(area: number) {
    switch (area) {
      case 1:
        this.form.get('SelectedUser').setValue(null);
        break;
      case 2:
        this.form.get('Step').setValue(null);
        this.form.get('Notes').setValue(null);
        break;
      default:
        this.form.get('Step').setValue(null);
        this.form.get('Notes').setValue(null);
        this.form.get('SelectedUser').setValue(null);
    }
  }

  save() {
    if (this.form.get('Step').value) { // Altera o estado para a transição selecionada
      // Se tiver a setting definidade com o ToStateID do step selecionado, enviar email
      if (this.steps && this.steps.length > 0 && this.steps.findIndex(r => r.ID === this.form.get('Step').value && this.showEmailStates.findIndex(r2 => r2 === r.ToStateID) !== -1) !== -1) {
        let dialogRefConfirm = this.dialog.open(WorkflowEmailsModalComponent);

        dialogRefConfirm.afterClosed().subscribe(result => {
          if (result) {
            this.dialogRef.close({
              stepID: this.form.get('Step').value,
              notes: this.form.get('Notes').value,
              actionType: WorkflowService.ActionType.CHANGE_STEP,
              emails: result.emailTo
            });
          }
        });
      } else { // Senão apenas requer confirmação
        this.translateService.get('WORKFLOW_CHANGE_CONFIRMATION').subscribe(translation => {
          let dialogRefConfirm = this.dialog.open(ConfirmationModalComponent, {
            data: {
              text: translation
            }
          });

          dialogRefConfirm.afterClosed().subscribe(result => {
            if (result) {
              this.dialogRef.close({
                stepID: this.form.get('Step').value,
                notes: this.form.get('Notes').value,
                actionType: WorkflowService.ActionType.CHANGE_STEP,
                emails: null
              });
            }
          });
        });
      }
    } else if (this.form.get('SelectedUser').value) { // Atribuir ações ao utilizador selecionado
      this.dialogRef.close({
        userID: this.form.get('SelectedUser').value,
        actionType: WorkflowService.ActionType.ADD_STEPS_APPROVER
      });
    }
  }

  // Histórico
  /**
   * Quando encolhe ou estica o Historico
   */
  changeCollapse() {
    this.collPnPopup1 = !this.collPnPopup1;
    if (this.collPnPopup1 && this.logs && this.logs.length === 0 && this.tabs && this.tabs.length === 0) { // vai abrir
      this.workflowService.getStateTransitionsLog(this.context, this.id).pipe(takeUntil(this.destroy$)).subscribe(response => this.logs = response);
      this.workflowService.getStateTransitionsTabs(this.context, this.id).pipe(takeUntil(this.destroy$)).subscribe(response => this.tabs = response);
    }
  }

  showNotes(notes: string) {
    this.dialog.open(MessageModalComponent, { data: { title: 'NOTES', message: notes } });
  }

  onTabChange($event: any) {
    let tabID = $event.tab.content.viewContainerRef.element.nativeElement.getAttribute('id');
    let tab = this.tabs.find(r => r.StateType === tabID);

    if (tabID !== 'General' && !tab.logs) {
      this.workflowService.getStateTransitionsLog(this.context, this.id, tabID).pipe(takeUntil(this.destroy$)).subscribe(response => tab.logs = response);
    }
  }
  // FIM - Histórico

  ngOnDestroy() { }
}
