import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray, Form } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModelValidators } from '../../../../models/validators/validators';
import { TranslateValueService } from '../../../../services/translate-value.service';
import { EmailModalParam } from 'src/app/models/email-modal-param';
import { ProcessesService } from 'src/app/services/processes.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { TicketsConfigsService } from 'src/app/services/tickets-configs.service';
import { PaymentPrevisionConfigsService } from 'src/app/services/payment-prevision-configs.service';
import { PaymentPrevisionConfig } from 'src/app/models/payment-prevision-config';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './payment-prevision-config-modal.html'
})
export class PaymentPrevisionConfigModalComponent implements OnInit, OnDestroy {
    submitted: boolean = false;
    noError: boolean = true;
    form: UntypedFormGroup;
    formErrors = {};
    validationMessages = {};
    documents: any;
    Data: EmailModalParam;
    checkboxValues: any = [];
    originalIds: any = [];
    copyIds: any = [];
    id: number;
    processNumber: string = '';
    destroy$: Subject<boolean> = new Subject<boolean>();
    documentType: string = '';
    emails: string = '';
    documentTypeID: number = 0;

    model: PaymentPrevisionConfig = new PaymentPrevisionConfig(0, null, null, null, null);;

    constructor(public dialogRef: MatDialogRef<PaymentPrevisionConfigModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _translateValueService: TranslateValueService,
        private _cdr: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private paymentPrevisionConfigsService: PaymentPrevisionConfigsService) {
        this.id = data.id;
        this.validationMessages = {
            'Emails': {
                'required': 'FIELD_REQUIRED_'
            }
        }
    }

    ngOnInit(): void {
        this.paymentPrevisionConfigsService.getPaymentPrevisionConfig(this.id).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if (response.ReturnStatus.Successfull) {
                if (response.ReturnStatus.ReturnObject != null) {
                    this.model = response.ReturnStatus.ReturnObject;
                    this.buildForm(false);
                } else {
                    this.buildForm(true);
                }
            }
        });

    }

    buildForm(creation: boolean): void {
        if (!creation) {
            this.form = this._formBuilder.group({
                'ID': [this.model.ID],
                'CompanyCode': [this.model.CompanyCode],
                'Hierarchy': [this.model.Hierarchy],
                'NomeArea': [this.model.NomeArea],
                'Emails': this._formBuilder.array([])
            });

            this.initFormArray();
        } else {
            this.form = this._formBuilder.group({
                'ID': [0],
                'CompanyCode': [null],
                'Hierarchy': [''],
                'NomeArea': [''],
                'Emails': this._formBuilder.array([])
            });
        }

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChanged(value);
        }); // deteta se houve alterações no form
        this.onValueChanged(); // (re)set validation messages now
    }

    initFormArray() {
        const control = <UntypedFormArray>this.form.controls['Emails'];
        if (this.model.Email != null) {
            let emailsList: Array<string> = this.model.Email.split(';');
            emailsList.forEach(element => {
                control.push(this._formBuilder.group({
                    Email: element
                }));
            });
        }

    }

    onValueChanged(value?: any) {
        if (!this.form) { return; }
        const form = this.form;
        for (const field in this.validationMessages) {
            if (this.validationMessages.hasOwnProperty(field)) {
                // clear previous error message (if any)
                this.formErrors[field] = '';
                const control = form.get(field);
                if ((this.submitted && (control && !control.valid && control.enabled)) ||
                    (!this.submitted && (control && control.dirty && !control.valid))
                ) {
                    this.noError = false;
                    const messages = this.validationMessages[field];
                    for (const key in control.errors) {
                        if (messages.hasOwnProperty(key)) {
                            this.formErrors[field] = messages[key] + ' ';
                            control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                        }
                    }
                }
            }
        }
    } // fim onValueChanged

    save() {
        this.submitted = true;
        this.noError = true;
        this.onValueChanged(this.form.valueChanges);

        let emails: Array<string> = [];
        let control = <UntypedFormArray>this.form.get('Emails');
        control.controls.forEach(element => {
            emails.push(element.value.Email);
        });

        this.model = JSON.parse(JSON.stringify(this.form.getRawValue()));
        this.model.Email = emails.join(";");

        if (this.noError) {
            this.dialogRef.close({ model: this.model });
            return;
        }
    }

    isChecked(id: number): boolean {
        let value = false;
        let BreakException = {};
        try {
            this.checkboxValues.forEach((item, index) => {
                if (item != null) {
                    if (item.id === id) {
                        value = item.checked;
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            // OUT OF FOREACH
        }
        return value;
    }

    toggleCheck(document: any): void {
        let BreakException = {};
        try {
            this.checkboxValues.forEach((item, index) => {
                if (item != null) {
                    if (item.id === document.ID) {
                        item.checked = !item.checked;
                        if (item.checked) {
                            let indexO = this.originalIds.indexOf(document.ID);
                            let indexC = this.copyIds.indexOf(document.ID);

                            if (indexC === null && document.DocumentVersion > 1) {
                                this.copyIds.push(document.ID);
                            }
                            if (indexO === null && document.DocumentVersion === 1) {
                                this.originalIds.push(document.ID)
                            }

                        } else {
                            let indexO = this.originalIds.indexOf(document.ID);
                            let indexC = this.copyIds.indexOf(document.ID);
                            if (indexO > -1) {
                                this.originalIds.splice(indexO, 1);
                            }
                            if (indexC > -1) {
                                this.copyIds.splice(indexC, 1);
                            }
                        }
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            // OUT OF FOREACH
        }
    }

    delete(index: number) {
        const control = <UntypedFormArray>this.form.get('Emails');
        control.removeAt(index);
    }

    addEmail() {
        const control = <UntypedFormArray>this.form.get('Emails');
        control.push(this._formBuilder.group({
            Email: ''
        }));
    }

    ngOnDestroy() { }
}