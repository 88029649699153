import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    let fieldName = args[0];
    let fieldValue = args[1];
    return value.filter((e) => {
      return (e[fieldName] === fieldValue);
    });
  }
}
