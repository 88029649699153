import { Component, OnInit, Inject, NgZone, OnDestroy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { takeUntil, first, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, AbstractControl, FormControl, FormArray } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ModelValidators } from '../../models/validators/validators';

// ***__***_________  MODELOS _________ ***__***
import {
  InvoiceDetail, InvoiceOtherReferenceValue, InvoiceTax, InvoiceDiscountValue,
  InvoiceAllowanceValue, TransformModel
} from '../../models/invoice';
import { CostCenterConfig } from '../../models/costCenterConfig';
import { Dropdown } from '../../models/dropdown';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { UNITARY_PRICE_DECIMAL, CURRENCY_DECIMAL, CURRENCY_SYMBOL, TAX_AMOUNT_DECIMAL, QUANTITY_DECIMAL } from '../../constants/global';

// ***__***_________  MODALS _________ ***__***
import { DistributionModalComponent } from '../shared/distribution-modal/distribution-modal.component';
import { InvoiceService } from 'src/app/services/invoice.service';
import { CommonService } from 'src/app/services/common.service';
import { DistributionModalParam } from 'src/app/models/distribution-modal-param';
import { CostCenterConfigService } from 'src/app/services/costCenterConfig.service';
import { forkJoin, Subject } from 'rxjs';
import { AccountingConfig } from 'src/app/models/accountingConfig';
import { CADistributionModalComponent } from '../shared/distribution-modal/ca/ca-distribution-modal.component';
import { TipologiaProdutoServico } from 'src/app/models/tipologia-produto-servico';
import { ChooseModalComponent } from '../shared/choose-modal/choose-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AccountingConfigService } from 'src/app/services/accountingConfig.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { OrderService } from 'src/app/services/order.service';
import { ErrorTreatmentFunctions } from 'src/app/modules/treatments.module';
import { ProductService } from 'src/app/services/product.service';
import { cloneDeep } from 'lodash';

declare var Functions: any;

@Component({
  moduleId: module.id.toString(),
  templateUrl: './invoiceDetails-modal.html'
})
export class InvoiceDetailsModalComponent implements OnInit, OnDestroy {
  public detailForm: UntypedFormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isEditable: boolean = false; // serve para distinguir entre detalhes purchases / detalhes sales
  formDisabled: boolean; // para saber se os detalhes sales se podem editar ou sao so de consulta

  noError: boolean = true;
  submitted: boolean = false;
  formErrors: Array<string> = new Array<string>(); // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean = false; // se tem permissoes de edicao
  model: InvoiceDetail = null; // valores inseridos na bd

  // variaveis para as options dos selects
  invoiceUnMeasureG: Array<any> = new Array<any>();
  invoiceTaxesTypes: Array<any> = new Array<any>();
  invoiceTaxesTypesALL: Array<any> = new Array<any>();
  invoiceTaxesSubTypes: Array<any> = new Array<any>();
  invoiceValueAddedTaxes: Array<any> = new Array<any>();
  invoiceValueAddedTaxesReasons: Array<any> = new Array<any>();
  otherReferenceTypes: Dropdown[] = [];

  allDetailsEditable: boolean = true; // se o utilizador pode mexer em todos os campos ou so prod/quantidade (pode ser alterado no ficheiro de configuracoes)
  showVatExemption: boolean = false; // se mostra os motivos de isencao do iva
  ivaTaxValues: any = null; // valor do objecto da taxa de iva para poder meter e tirar do array para o select (se ja tiver sido utilizado, so permite ter 1 taxa de iva)

  // usados no addToFormField
  Currency_Form: number = 1;
  UnitPrice_Form: number = 2;
  Int_Form: number = 3;

  // DEFINICOES SETTINGS
  showBonusQuantity: boolean = true;
  showBonusQuantityUOMCode: boolean = true;
  showPackQuantity: boolean = true;
  showPackQuantityUOMCode: boolean = true;
  showPackSize: boolean = true;
  editTotalNetAmount: boolean = true;
  editGrossUnitPrice: boolean = true;
  editTotalGrossAmount: boolean = true;
  showOthersReferencesTypes: boolean = false;
  groupVatExemption: boolean = false;
  showGrossUnitPrice: boolean = true;
  showTotalGrossAmount: boolean = true;
  showTotalTransportAmount: boolean = true;
  showBuyersCostCenter: boolean = true;
  hideBuyerProductCode: boolean = false;
  hideSupplierProductCode: boolean = false;
  hideEANCode: boolean = false;

  showBacthNumber: boolean = false;
  showDeliveryDate: boolean = false;
  showBacthExpiryDate: boolean = false;
  showDistributionButton: boolean = false;
  showPriceUnit: boolean = false;
  disableTaxPanel: boolean = false;
  showOrderLineNumberOnProductPanel: boolean = false;

  showPanelDiscounts: boolean = false;
  showPanelAllowances: boolean = false;
  showPanelReferences: boolean = false;
  hidePanelObservations: boolean = false;
  quantityUOMCodeDisabled: boolean = false;
  totalNetPriceDisabled: boolean = false;
  descriptionDisabled: boolean = false;
  useHalfColumn: boolean = false;

  validateQuantityMaxAllowed: boolean = true;

  // listas~e afins a enviar p/ distribuição
  hasDistribution: boolean = false;
  saveOnServer: boolean = false;
  formDist: UntypedFormGroup;
  typeList: Array<Dropdown> = new Array<Dropdown>(); // tipo (centro de custo/eixo de analise)
  debitCreditList: Array<Dropdown> = new Array<Dropdown>(); // debito ou credito
  incidenceList: Array<Dropdown> = new Array<Dropdown>(); // listagem de incidencias
  analisysAxesList: Array<any> = new Array<any>(); // listagem de eixos de análise
  divisionKeysList: Array<any> = new Array<any>(); // listagem de chaves de divisão
  allCostCentersList: Array<any> = new Array<any>(); // listagem de todos os centros de custo
  documentType: string = ''; // tipo de documento
  useAccountingConfig: boolean = false;

  accountTypeList: Array<Dropdown> = new Array<Dropdown>();
  // ** fim distribuição

  accountProductTypes: Array<TipologiaProdutoServico> = new Array<TipologiaProdutoServico>();
  accountProductSubTypes: Array<TipologiaProdutoServico> = new Array<TipologiaProdutoServico>();
  vatTreatmentTypes: Array<Dropdown> = new Array<Dropdown>();
  getProductServiceType: boolean = false;

  showDatesPanel: boolean = false;
  //esta variável só vai estar a true se a fatura estiver num estado em que possa ser editada e se o utilizador autenticado tiver nas suas roles uma role que lhe permita editar campos da fatura
  enableSaveButton: boolean = false;

  //setting para colocar uma label específica para o botão da contabilização/distribuição
  distributionButtonLabel: string = 'DISTRIBUTION';

  //setting que vai dizer se é possível editar os campos "Conta Gasto" e "Grupo IVA"
  canChangeExpenseAccountTaxGroup: boolean = false;

  invoiceYear: number = null;

  showLoadAccountingButton: boolean = false;
  hideColumsTax: boolean = false;
  showDescriptionAsTextArea: boolean = false;

  showProductServiceTypeFields: boolean = false; //para mostrar os campos da tipologia produto/serviço
  showApprovedProposalNumber: boolean = false; // para mostrar/esconder o nº proposta aprovada

  tranfModel = new TransformModel(); // necessario para usar as funcoes de configuração de hora e numeros
  _cur_symbol: string = CURRENCY_SYMBOL;
  private _decimalPipe: DecimalPipe;
  showDetailsBuyerContractNumber: boolean = false;
  changedForm: boolean = false;
  oldFormJson: string;
  startDateWasNull: boolean = false;
  endDateWasNull: boolean = false;
  enableSavingWhenChangingForm: boolean = false;
  validateFreeTextMaxLength: boolean = false;
  
  //#region GenericFields
  useProductCostCenter: boolean = false; //Setting que define se o Campo Opcional 'CostCenter' é utilizado
  useProductReclassificationCode: boolean = false; //Setting que define se o Campo Opcional 'ReclassificationCode' é utilizado

  costCenterList: any[] = []; //Armazena os Centros de Custo
  reclassificationCodeList: any[] = []; //Armazena os Códigos de Reclassificação

  costCenter: any = {
      code: '',
      description: ''
  }; //Centro de Custo selecionado
  reclassificationCode: any = {
      code: '',
      description: ''
  }; //Código de Reclassificação selecionado
  //#endregion


  supplierIdentifier: string; // Identifier do Fornecedor
  product: any; //Produto associado ao Detalhe de Encomenda

  invoiceType: string; //receber invoice type do ecra pai para desabilitar ecra de impostos caso seja uma nota de credito e esteja parametrizado para o efeito
  showOrderProcess: boolean = false;
  showOrderFraction: boolean = false;
  hideDetailOtherReferences: boolean = false;
  showCostCenter: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<InvoiceDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private _formBuilder: UntypedFormBuilder,
    private _translateService: TranslateService,
    private orderService: OrderService,
    private _errorTreat: ErrorTreatmentFunctions,
    private productService: ProductService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private _zone: NgZone,
    private invoiceService: InvoiceService,
    private commonService: CommonService,
    private ref: ChangeDetectorRef,
    //private costCenterConfigService: CostCenterConfigService,
    private authenticationService: AuthenticationService,
    private accountingConfigService: AccountingConfigService,
    private _renderer: Renderer2
  ) {
    // Settings Gerais
    this.showOthersReferencesTypes = data.showOthersReferencesTypes;
    this.groupVatExemption = data.groupVatExemption;

    // Verifica se é para mostrar os tipos de outras referências
    if (this.showOthersReferencesTypes) {
      // Tipos de Outras Referências
      this.commonService.otherReferenceTypes.pipe(takeUntil(this.destroy$)).subscribe(response => this.otherReferenceTypes = response);
    }

    this._decimalPipe = new DecimalPipe(_translateService.currentLang);

    if (data.context === 'faturaVenda' || data.context === 'faturaCompraEditable') { // são as editáveis
      this.isEditable = true;

      this.validateQuantityMaxAllowed = invoiceService.get_DETAIL_SALE_ValidateQuantityMaxAllowed(this.validateQuantityMaxAllowed);

      // Settings
      if (data.context === 'faturaVenda') {
        this.showBonusQuantity = invoiceService.get_DETAIL_SALE_ShowDetailBonusQuantity(this.showBonusQuantity);
        this.showPackQuantity = invoiceService.get_DETAIL_SALE_ShowDetailPackQuantity(this.showPackQuantity);
        this.showPackSize = invoiceService.get_DETAIL_SALE_ShowDetailPackSize(this.showPackSize);
        this.editTotalNetAmount = invoiceService.get_DETAIL_SALE_EditDetailTotalNetAmount(this.editTotalNetAmount);
        this.editGrossUnitPrice = invoiceService.get_DETAIL_SALE_EditDetailGrossUnitPrice(this.editGrossUnitPrice);
        this.editTotalGrossAmount = invoiceService.get_DETAIL_SALE_EditDetailTotalGrossAmount(this.editTotalGrossAmount);
        this.showGrossUnitPrice = invoiceService.get_DETAIL_SALE_showDetailGrossUnitPrice(this.showGrossUnitPrice);
        this.showTotalGrossAmount = invoiceService.get_DETAIL_SALE_showDetailTotalGrossAmount(this.showTotalGrossAmount);
        this.showTotalTransportAmount = invoiceService.get_DETAIL_SALE_showDetailTotalTransportAmount(this.showTotalTransportAmount);
        this.showBuyersCostCenter = invoiceService.get_DETAIL_SALE_showDetailBuyersCostCenter(this.showBuyersCostCenter);
        this.showPriceUnit = invoiceService.get_DETAIL_SALE_showPriceUnit(this.showPriceUnit);
        this.hideDetailOtherReferences = invoiceService.get_DETAIL_SALE_hideDetailOtherReferences(this.hideDetailOtherReferences);
      } else if (data.context === 'faturaCompraEditable') {
        this.showBonusQuantity = invoiceService.get_DETAIL_PURCHASE_ShowDetailBonusQuantity(this.showBonusQuantity);
        this.showPackQuantity = invoiceService.get_DETAIL_PURCHASE_ShowDetailPackQuantity(this.showPackQuantity);
        this.showPackSize = invoiceService.get_DETAIL_PURCHASE_ShowDetailPackSize(this.showPackSize);
        this.editTotalNetAmount = invoiceService.get_DETAIL_PURCHASE_EditDetailTotalNetAmount(this.editTotalNetAmount);
        this.editGrossUnitPrice = invoiceService.get_DETAIL_PURCHASE_EditDetailGrossUnitPrice(this.editGrossUnitPrice);
        this.editTotalGrossAmount = invoiceService.get_DETAIL_PURCHASE_EditDetailTotalGrossAmount(this.editTotalGrossAmount);
        this.showGrossUnitPrice = invoiceService.get_DETAIL_PURCHASE_showDetailGrossUnitPrice(this.showGrossUnitPrice);
        this.showTotalGrossAmount = invoiceService.get_DETAIL_PURCHASE_showDetailTotalGrossAmount(this.showTotalGrossAmount);
        this.showTotalTransportAmount = invoiceService.get_DETAIL_PURCHASE_showDetailTotalTransportAmount(this.showTotalTransportAmount);
        this.showBuyersCostCenter = invoiceService.get_DETAIL_PURCHASE_showDetailBuyersCostCenter(this.showBuyersCostCenter);
        this.showDistributionButton = invoiceService.get_DETAIL_PURCHASE_showDetailDistributionButton(this.showDistributionButton);
        this.hideDetailOtherReferences = invoiceService.get_DETAIL_PURCHASE_hideDetailOtherReferences(this.hideDetailOtherReferences);
      }

      
      
      // ir buscar os dados que sao enviados no open da model
      this.detailForm = data.detailForm;
      this.model = data.detailModel;
      this.invoiceUnMeasureG = data.invoiceUnMeasureG;
      this.invoiceTaxesTypes = data.invoiceTaxesTypes;
      this.invoiceTaxesSubTypes = data.invoiceTaxesSubTypes;
      this.invoiceValueAddedTaxes = data.invoiceValueAddedTaxes;
      this.invoiceValueAddedTaxesReasons = data.invoiceValueAddedTaxesReasons;
      this.allowEdit = data.allowEdit;
      this.validationMessages = data.validationMessagesDetails;
      this._cur_symbol = data._cur_symbol;
      this.allDetailsEditable = data.allDetailsEditable;
      this.invoiceTaxesTypesALL = data.invoiceTaxesTypes;
      this.showDeliveryDate = data.showDeliveryDate;
      this.showBacthExpiryDate = data.showBacthExpiryDate;

      this.showPanelDiscounts = data.showPanelDiscounts;
      this.showPanelAllowances = data.showPanelAllowances;
      this.showPanelReferences = data.showPanelReferences;

      this.showDetailsBuyerContractNumber = data.showDetailsBuyerContractNumber;
      this.enableSavingWhenChangingForm = data.enableSavingWhenChangingForm;

      this.ivaTaxValues = this.invoiceTaxesTypes.find(tax => tax.ID === 'ValueAddedTax'); // guardar valores da taxa de iva

      this.formDisabled = data.formDisabled;
      this.invoiceType = data.invoiceType;
      this.disableTaxPanel = data.disableTaxPanel;
      this.showOrderLineNumberOnProductPanel = data.showOrderLineNumberOnProductPanel;

      // Se o formulário não for editável, então os detalhes também não são
      if (this.formDisabled) {
        this.detailForm.disable();
      } else {
        if (!this.editTotalNetAmount) {
          this.detailForm.get('TotalNetAmount').disable();
        }
        if (!this.editGrossUnitPrice) {
          this.detailForm.get('GrossUnitPrice').disable();
        }
        if (!this.editTotalGrossAmount) {
          this.detailForm.get('TotalGrossAmount').disable();
        }

        if((<UntypedFormArray>this.detailForm.get('Taxes'))
        && this.disableTaxPanel && (this.invoiceType == 'CreditMemo' || this.invoiceType == 'CreditFinancial')) 
        {
          (<UntypedFormArray>this.detailForm.get('Taxes')).disable();
        }
        else if((<UntypedFormArray>this.detailForm.get('Taxes'))
        && this.disableTaxPanel && (this.invoiceType != 'CreditMemo' && this.invoiceType != 'CreditFinancial')) 
        {
          (<UntypedFormArray>this.detailForm.get('Taxes')).enable();
        }
      }
    } else if (data.context == 'faturaCompra') {
      this.showBonusQuantity = invoiceService.get_DETAIL_PURCHASE_ShowDetailBonusQuantity(this.showBonusQuantity);
      this.showPackQuantity = invoiceService.get_DETAIL_PURCHASE_ShowDetailPackQuantity(this.showPackQuantity);
      this.showPackSize = invoiceService.get_DETAIL_PURCHASE_ShowDetailPackSize(this.showPackSize);
      this.showGrossUnitPrice = invoiceService.get_DETAIL_PURCHASE_showDetailGrossUnitPrice(this.showGrossUnitPrice);
      this.showTotalGrossAmount = invoiceService.get_DETAIL_PURCHASE_showDetailTotalGrossAmount(this.showTotalGrossAmount);
      this.showTotalTransportAmount = invoiceService.get_DETAIL_PURCHASE_showDetailTotalTransportAmount(this.showTotalTransportAmount);
      this.showBuyersCostCenter = invoiceService.get_DETAIL_PURCHASE_showDetailBuyersCostCenter(this.showBuyersCostCenter);
      this.showDistributionButton = invoiceService.get_DETAIL_PURCHASE_showDetailDistributionButton(this.showDistributionButton);

      this.model = data.detailModel;
      this.detailForm = data.detailForm;
      this.invoiceUnMeasureG = data.invoiceUnMeasureG;
      this.typeList = data.typeList;
      this.debitCreditList = data.debitCreditList;
      this.incidenceList = data.incidenceList;
      this.analisysAxesList = data.analisysAxesList;
      this.divisionKeysList = data.divisionKeysList;
      this.allCostCentersList = data.allCostCentersList;
      this.documentType = data.documentType == null ? '' : data.documentType;

      this.formDisabled = data.formDisabled;
      this.showPanelDiscounts = data.showPanelDiscounts;
      this.showPanelAllowances = data.showPanelAllowances;
      this.showPanelReferences = data.showPanelReferences;

      this.invoiceTaxesTypes = data.invoiceTaxesTypes;
      this.invoiceTaxesSubTypes = data.invoiceTaxesSubTypes;
      this.invoiceValueAddedTaxes = data.invoiceValueAddedTaxes;
      this.invoiceValueAddedTaxesReasons = data.invoiceValueAddedTaxesReasons;
      this.invoiceTaxesTypesALL = data.invoiceTaxesTypes;

      this.useAccountingConfig = data.useAccountingConfig;
      this.accountTypeList = data.accountTypeList;

      this.getProductServiceType = data.getProductServiceType;
      this.accountProductTypes = data.accountProductTypes;
      this.accountProductSubTypes = data.accountProductSubTypes;
      this.vatTreatmentTypes = data.vatTreatmentTypes;

      this.showDatesPanel = data.showDatesPanel;
      this.enableSaveButton = data.enableSaveButton;
      this.distributionButtonLabel = data.distributionButtonLabel;
      this.canChangeExpenseAccountTaxGroup = data.canChangeExpenseAccountTaxGroup;
      this.invoiceYear = data.invoiceYear;
      this.showLoadAccountingButton = data.showLoadAccountingButton;
      this.showApprovedProposalNumber = data.showApprovedProposalNumber;

      this.showDetailsBuyerContractNumber = data.showDetailsBuyerContractNumber;
      this.validationMessages = data.validationMessagesDetails;
      
      this.useProductCostCenter = data.useProductCostCenter;
      this.useProductReclassificationCode = data.useProductReclassificationCode;

      this.supplierIdentifier = data.supplierIdentifier;
      this.showOrderLineNumberOnProductPanel = data.showOrderLineNumberOnProductPanel;
      
      this.showCostCenter = data.showCostCenter;

      //Se for para usar Accounting Config
      if (this.useAccountingConfig) {
        this.buildFormAccountingConfig();
        if (this.model.AccountingConfigList && this.model.AccountingConfigList !== null && this.model.AccountingConfigList.length > 0) {
          this.hasDistribution = true;
          this.addAccountingConfigs();
        }
      } else {
        //Se for para usar CostCenterConfig
        this.buildFormCostCentersConfig();
        if (this.model.CostCentersConfig && this.model.CostCentersConfig !== null && this.model.CostCentersConfig.length > 0) {
          this.hasDistribution = true;
          this.addCostCentersConfigs();
        }
      }

      this.showPriceUnit = invoiceService.get_DETAIL_PURCHASE_showPriceUnit(this.showPriceUnit);
    }

    this.showBacthNumber = data.showBacthNumber;

    //se for para editar e o campo TipologiaProdutoServicoID estiver preenchido, vamos buscar o filho com base no pai e no tratamento de IVA
    if (this.detailForm.controls['ID'].value !== 0 && this.getProductServiceType && this.model != null && this.detailForm.controls['TipologiaProdutoServicoID'].value != null) {
      let accountProductType = this.detailForm.controls['AccountProductType'].value;
      let vatTreatmentType = this.detailForm.controls['VatTreatmentType'].value;
      this.getChildsList(accountProductType, vatTreatmentType);
      //se o valor do tipo de produto/serviço vier preenchido, vamos buscar a descrição (o nome)
      let accountProductTypeEntity = this.accountProductTypes.find(x => x.ID === accountProductType);
      let accountProductTypeName = accountProductTypeEntity ? accountProductTypeEntity.Name : null;
      this.detailForm.controls['AccountProductTypeDescription'].setValue(accountProductTypeName);
    }
    
    this.oldFormJson = cloneDeep(this.detailForm.getRawValue());
    this.validateFreeTextMaxLength = data.validateFreeTextMaxLength;
    this.hideBuyerProductCode = data.hideBuyerProductCode;
    this.hideSupplierProductCode = data.hideSupplierProductCode;
    this.hideEANCode = data.hideEANCode;
    this.hidePanelObservations  = data.hidePanelObservations;
    this.quantityUOMCodeDisabled = data.quantityUOMCodeDisabled;
    this.totalNetPriceDisabled = data.totalNetPriceDisabled;
    this.descriptionDisabled = data.descriptionDisabled;
    this.useHalfColumn = data.useHalfColumn;

    this.showBonusQuantityUOMCode = data.showBonusQuantityUOMCode;
    this.showPackQuantityUOMCode = data.showPackQuantityUOMCode;
    this.showDescriptionAsTextArea = data.showDescriptionAsTextArea;
    this.showOrderProcess = data.showOrderProcess;
    this.showOrderFraction = data.showOrderFraction;

    if(this.quantityUOMCodeDisabled){
      this.detailForm.controls['QuantityUOMCode'].disable();
    }
    if(this.totalNetPriceDisabled)
    {
      this.detailForm.controls['TotalNetAmount'].disable();
    }

    this.hideColumsTax = data.hideColumsTax;
   
    this.submitted = true;
    this.noError = true;
    this.checkChanges();
  }

  ngOnInit() {
    let that = this;

    if (this.isEditable) {
      this.detailForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDetails(value);
      });
      this.onValueChangedDetails();
      this._zone.onStable.pipe(first()).subscribe(() => {
        // colocar os eventos de valueChange nos arrays do form
        that.addOtherReferences(false);
        that.addTaxes(false);
        that.addDiscounts(false);
        that.addAllowances(false);
        that.checkChanges(); // correr os valueChange
      });
    }

    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    if (this.getProductServiceType && this.model && this.model.ProductID == null) {
      this.showProductServiceTypeFields = true;
    }
    this.oldFormJson = cloneDeep(this.detailForm.getRawValue());   

    this.startDateWasNull = this.detailForm.get('StartDate') ? this.detailForm.get('StartDate').value == null : false;   
    this.endDateWasNull = this.detailForm.get('EndDate') ? this.detailForm.get('EndDate').value == null : false; 
    //this.populateInvoiceDetailGenFields();  
    
    //Obter informação adicional associada ao produto
    if(this.useProductCostCenter)
    {
      this.getProductInfo();  
    }
  
}

  onValueChangedDetails(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;

    if (this.oldFormJson && this.enableSavingWhenChangingForm) {

      const ctrl1 = form.get('StartDate');
      if (ctrl1.value == '' && this.startDateWasNull) {
        ctrl1.setValue(null);
      }
      const ctrl2 = form.get('EndDate');
      if (ctrl2.value == '' && this.endDateWasNull) {
        ctrl2.setValue(null);
      }

      this.changedForm = cloneDeep(form.getRawValue()) !== this.oldFormJson;
      this.ref.detectChanges();
    }

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);
        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {
          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
    
  }

  // #region Distribution
  buildFormCostCentersConfig(): void {
    this.formDist = this.formBuilder.group({
      'CostCentersConfig': this.formBuilder.array([])
    });

    // this.addCostCentersConfigs();
  }

  buildFormAccountingConfig(): void {
    this.formDist = this.formBuilder.group({
      'AccountingConfigList': this.formBuilder.array([])
    });
  }

  // distribuição cabeçalho encomenda para o costCenterConfig
  openDistribution() {
    //Se for para usar Accounting Config, vamos usar outra modal de Distribuição
    if (this.useAccountingConfig) {
      this.openDistributionAccountConfig();
    } else {
      const control = <UntypedFormArray>this.formDist.controls['CostCentersConfig'];
      // converter incidência para string para fazer match com o select
      control.controls.forEach((costCenter: any) => {
        costCenter.get('Incidence').setValue((costCenter.get('Incidence').value).toString());
      });
      
      let valor = isNaN(this.model.TotalNetAmount) ? this.model.TotalNetAmount.toString().revertDecimal() : +this.model.TotalNetAmount;
      // let documentType = this.form.controls['DocumentType'].value;

      let dialogRef = this.dialog.open(DistributionModalComponent, {
        data: // dados que vai enviar para o componente da modal
          new DistributionModalParam(false, 'InvoiceDetail', this.model.ID, control.getRawValue(),
            this.saveOnServer, valor, this.documentType, this.typeList, this.debitCreditList,
            this.incidenceList, this.analisysAxesList, this.divisionKeysList,
            this.allCostCentersList),
        disableClose: false, // nao permitir fechar modal com escape ou clique fora
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          // limpar formArray original p/ substituir por novo
          this.formDist.controls['CostCentersConfig'] = this.formBuilder.array([]);

          for (let cost of result) {
            const newControl = <UntypedFormArray>this.formDist.controls['CostCentersConfig'];
            const addrCtrl = this.initCostCenterConfig(cost);
            newControl.push(addrCtrl);
          }

          this.hasDistribution = true;
        }
      });
    }
  }

  //Método específico para usar Accounting Config com a modal específica da Distribuição
  openDistributionAccountConfig() {
    const control = <UntypedFormArray>this.formDist.controls['AccountingConfigList'];

    let valor = (+this.model.TotalNetAmount);
    // let documentType = this.form.controls['DocumentType'].value;
    let tipologiaProdutoServicoID = this.detailForm.get('TipologiaProdutoServicoID').value;
    let vatRate = (this.model && this.model.Taxes && this.model.Taxes.find(t => t.TaxTypeCode == 'ValueAddedTax')) ? this.model.Taxes.find(t => t.TaxTypeCode == 'ValueAddedTax').TaxRate : 0;

    if (control != null) {
      let dialogRef = this.dialog.open(CADistributionModalComponent, {
        data: { // dados que vai enviar para o componente da modal
          distributionModalParam: new DistributionModalParam(false, 'InvoiceDetail', this.model.ID, null,
            this.saveOnServer, valor, this.documentType, null, null,
            null, this.analisysAxesList, this.divisionKeysList,
            this.allCostCentersList, control.getRawValue(), true, true, true, true, true, true, true, true, true,
            true, this.enableSaveButton, this.canChangeExpenseAccountTaxGroup, false, true, this.invoiceYear,
            this.model.ProductID, tipologiaProdutoServicoID, vatRate, this.showLoadAccountingButton, this.validateFreeTextMaxLength, this.enableSavingWhenChangingForm)
        },
        disableClose: false, // nao permitir fechar modal com escape ou clique fora
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          // limpar formArray original p/ substituir por novo
          this.formDist.controls['AccountingConfigList'] = this.formBuilder.array([]);
          for (let config of result) {
            const newControl = <UntypedFormArray>this.formDist.controls['AccountingConfigList'];
            const addrCtrl = this.initAccountingConfig(config);
            newControl.push(addrCtrl);
          }

          //Adicionar accountingConfigList aos detalhes
          this.detailForm.controls['AccountingConfigList'] = this.formBuilder.array([]);
          for (let config of result) {
            const newCtrl = <UntypedFormArray>this.detailForm.controls['AccountingConfigList'];
            const addrControl = this.initAccountingConfig(config);
            newCtrl.push(addrControl);
          }
          this.hasDistribution = true;
        }
      });
    }

  }

  initCostCenterConfig(costCenterConfig: CostCenterConfig = null) {
    if (costCenterConfig) {
      return this.formBuilder.group({
        'ID': [costCenterConfig.ID],
        'AnalisysAxeID': [costCenterConfig.AnalisysAxeID],
        'Context': [costCenterConfig.Context],
        'CostCenterID': [costCenterConfig.CostCenterID],
        'DebitOrCredit': [costCenterConfig.DebitOrCredit],
        'DivisionKeyID': [costCenterConfig.DivisionKeyID],
        'EntityID': [costCenterConfig.EntityID],
        'FixedValue': [costCenterConfig.FixedValue],
        'Incidence': [costCenterConfig.Incidence],
        'IntegrationID': [costCenterConfig.IntegrationID],
        'Percentage': [costCenterConfig.Percentage],
        'Type': [costCenterConfig.Type],
        'CostCenterName': [{ value: '', disabled: true }]
      });
    }
  }

  addCostCentersConfigs() { // adicionar os que ja estao na bd
    // distribuição do cabeçalho
    if (this.model != null && this.model.CostCentersConfig != null) {
      for (let cost of this.model.CostCentersConfig) {
        const control = <UntypedFormArray>this.formDist.controls['CostCentersConfig'];
        const addrCtrl = this.initCostCenterConfig(cost);
        control.push(addrCtrl);
      }
    }
  }

  initAccountingConfig(accountingConfig: AccountingConfig = null) {
    if (accountingConfig) {
      let costCenterName: string = null;
      if (accountingConfig.CentroCusto !== null && this.allCostCentersList !== null && this.allCostCentersList.length > 0) {
        let costCenter = this.allCostCentersList.find(x => x.IntegrationID === accountingConfig.CentroCusto);
        costCenterName = costCenter != null ? costCenter.Name : null;
      }
      return this.formBuilder.group({
        'ID': [accountingConfig.ID],
        'Context': [accountingConfig.Context],
        'EntityID': [accountingConfig.EntityID],
        'Percentagem': [accountingConfig.Percentagem, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'ValorFixo': [accountingConfig.ValorFixo, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'CentroCusto': [accountingConfig.CentroCusto],
        'Refaturar': [accountingConfig.Refaturar],
        'Tipo': [accountingConfig.Tipo],
        'ContaGasto': [accountingConfig.ContaGasto],
        'GrupoIVA': [accountingConfig.GrupoIVA],
        'CodAtividade': [accountingConfig.CodAtividade],
        'CodIniciativa': [accountingConfig.CodIniciativa],
        'TipoRegistoImob': [accountingConfig.TipoRegistoImob],
        'CodLivroAmort': [accountingConfig.CodLivroAmort],
        'NomeCentroCusto': [costCenterName],
        'Observacoes': [accountingConfig.Observacoes],
        'CodGrupoContabilizacao': [accountingConfig.CodGrupoContabilizacao],
        'CodRetencao': [accountingConfig.CodRetencao],
        'TipoTratamentoIva': [accountingConfig.TipoTratamentoIva],
        'CodMensualizacao': [accountingConfig.CodMensualizacao]
      });
    }
  }

  addAccountingConfigs() { // adicionar os que ja estao na bd
    // distribuição do cabeçalho
    if (this.model != null && this.model.AccountingConfigList != null) {
      for (let config of this.model.AccountingConfigList) {
        const control = <UntypedFormArray>this.formDist.controls['AccountingConfigList'];
        const addrCtrl = this.initAccountingConfig(config);
        ////este setting está a true apenas quando a fatura está num estado em que possa ser editada e quando o utilizador autenticado tem as roles que lhe permitem editar a fatura
        if (this.enableSaveButton) {
          addrCtrl.disable(); //não vai poder editar campos
        }
        control.push(addrCtrl);
      }
    }
  }
  // #endregion Distribution

  //#region OtherReferences
  initOtherReferences(otRef: InvoiceOtherReferenceValue = null) {
    if (otRef) {
      let formOtherReferences = this._formBuilder.group({
        'ReferenceType': [{ value: otRef.ReferenceType, disabled: !this.allowEdit }],
        'ReferenceValue': [{ value: otRef.ReferenceValue, disabled: !this.allowEdit }],
        'ReferenceDate': [{ value: otRef.ReferenceDate, disabled: !this.allowEdit }, Validators.compose([ModelValidators.validDate])]
      });

      if (this.formDisabled) {
        // colocar todos como disabled
        formOtherReferences.disable();
      }

      return formOtherReferences;
    }
    return this._formBuilder.group({
      'ReferenceType': [''],
      'ReferenceValue': [''],
      'ReferenceDate': ['', Validators.compose([ModelValidators.validDate])]
    });
  }
  addOtherReferences(newInsert: boolean, objectInsert: InvoiceOtherReferenceValue = null) {
    if (newInsert) { // inserir um novo vazio
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      const oRefCtrl = this.initOtherReferences();
      if (!this.allDetailsEditable) {
        oRefCtrl.disable();
      }
      control.push(oRefCtrl);
      oRefCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedOtherReferences(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (objectInsert) { // usado quando faz reset
        const oRefCtrl = this.initOtherReferences(objectInsert);
        if (!this.allDetailsEditable) {
          oRefCtrl.disable();
        }
        control.push(oRefCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedOtherReferences(value);
          });
        }
      }
    }
  }
  removeOtherReferences(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
    control.removeAt(i);
  }

  /* tslint:disable:member-ordering */
  formErrorsOtherReferences: Array<string> = new Array<string>();
  formErrorsOtherReferencesParam: Array<string> = new Array<string>();
  validationMessagesOtherReferences = {
    'ReferenceType': {
      'required': 'FIELD_REQUIRED_'
    },
    'ReferenceValue': {
      'required': 'FIELD_REQUIRED_'
    },
    'ReferenceDate': {
      'required': 'FIELD_REQUIRED_',
      'invalidDate': 'INVALID_DATE'
    },
  };
  /* tslint:enable:member-ordering */

  onValueChangedOtherReferences(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsOtherReferences = new Array<string>();
    this.formErrorsOtherReferencesParam = new Array<string>();
    for (const field in this.validationMessagesOtherReferences) {
      if (this.validationMessagesOtherReferences.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('OtherReferences');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))
            ) {
              this.noError = false;
              const messages = this.validationMessagesOtherReferences[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsOtherReferences.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsOtherReferencesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsOtherReferencesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  //#endregion OtherReferences

  //#region Taxes
  initTaxes(tax: InvoiceTax = null) {
    
    let taxControl: UntypedFormGroup;
    if (tax) {
      taxControl = this._formBuilder.group({
        'TaxTypeCode': [{ value: tax.TaxTypeCode, disabled: (!this.allowEdit || (this.invoiceTaxesTypes.length == 1 && tax.TaxTypeCode == 'ValueAddedTaxes'))}, Validators.compose([Validators.required, ModelValidators.lengthVal({ max: 20 })])],
        'TaxTypeOther': [{ value: tax.TaxTypeOther, disabled: !this.allowEdit }, Validators.compose([ModelValidators.lengthVal({ max: 20 })])],
        'TaxSubTypeCode': [{ value: tax.TaxSubTypeCode, disabled: !this.allowEdit }, Validators.compose([ModelValidators.lengthVal({ max: 20 })])],
        'TaxRate': [{ value: tax.TaxRate, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL })])],
        //'TaxRate': [{ value: tax.TaxRate, disabled: !this.allowEdit }, Validators.required],
        'FreeText': [{ value: tax.FreeText, disabled: !this.allowEdit }],
        'TaxableAmount': [{ value: tax.TaxableAmount, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
        'TaxAmount': [{ value: tax.TaxAmount, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: TAX_AMOUNT_DECIMAL })])],
        'VatExemptReasonID': [tax.VatExemptReasonID],
      });

      if (this.formDisabled) {
        // colocar todos como disabled
        taxControl.disable();
      } else if (tax.TaxTypeCode && tax.TaxTypeCode === 'VATReverseCharge') { // Se a taxa for do tipo IVA Autoliquidação, então a pencentagem de IVA não é editável
        taxControl.get('TaxRate').disable();
        taxControl.get('TaxAmount').disable();
      }

    } else {
      taxControl = this._formBuilder.group({
        'TaxTypeCode': [{ value: this.invoiceTaxesTypes.length == 1 ? this.invoiceTaxesTypes[0].ID : null, disabled: (this.invoiceTaxesTypes.length == 1 && this.invoiceTaxesTypes[0].ID == 'ValueAddedTax' ? true : false)}, Validators.compose([Validators.required, ModelValidators.lengthVal({ max: 20 })])],
        'TaxTypeOther': [null, Validators.compose([ModelValidators.lengthVal({ max: 20 })])],
        'TaxSubTypeCode': [null, Validators.compose([ModelValidators.lengthVal({ max: 20 })])],
        // 'TaxRate': ['', Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL })])],
        'TaxRate': [null, Validators.required],
        'TaxableAmount': [null, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
        'TaxAmount': [null, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: TAX_AMOUNT_DECIMAL })])],
        'FreeText': [null],
        'VatExemptReasonID': [null]
      });
    }

    return taxControl;
  }

  // Ao alterar o tipo de taxa para IVA Autoliquidação, a pencentagem de IVA não é editável
  onChangeTaxType(index: number) {
    const control = (<UntypedFormArray>this.detailForm.get('Taxes')).at(index);

    if (control.get('TaxTypeCode').value !== 'ValueAddedTax') {
      control.get('TaxRate').clearValidators();
      control.get('TaxRate').setValidators(ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL }));
      control.get('TaxAmount').setValidators(Validators.required);
    }

    if (control.get('TaxTypeCode').value === 'VATReverseCharge') {
      control.get('TaxRate').setValue(0);
      control.get('TaxRate').disable();
      control.get('TaxAmount').disable();

      this.checkIfExemption();
      this.valueCalTax(index);
      this.valueCalDiscTax();
    } else {
      control.get('TaxRate').enable();
      control.get('TaxAmount').enable();
    }
  }

  addTaxes(newInsert: boolean, objectInsert: InvoiceTax = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
      const taxCtrl = this.initTaxes();
      if (!this.allDetailsEditable) {
        taxCtrl.disable();
      }
      //Enviar controlo para o formarray de taxas
      control.push(taxCtrl);

      //Apresentar erros logo quando é adicionada taxa (por exemplo: campos obrigatórios)
      this.onValueChangedTaxes();

      //Apresentar/esconder erros após atualização do controlo
      taxCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedTaxes(value);
      });

      //Se a taxa for IVA, vai remover a taxa dos invoiceTaxesTypes, para impossibilitar uma duplicação 
      if(taxCtrl.get('TaxTypeCode').value && taxCtrl.get('TaxTypeCode').value == 'ValueAddedTax')
      {
        this.checkIfExemption();
      }
      
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Taxes'];

      if (objectInsert) { // usado quando faz reset
        const taxCtrl = this.initTaxes(objectInsert);
        if (!this.allDetailsEditable) {
          taxCtrl.disable();
        }
        control.push(taxCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedTaxes(value);
          });
        }
      }
      this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }
  removeTaxes(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
    control.removeAt(i);
    this.valueCalDiscTax();
    this.checkIfExemption();
    // Retira os erros se já não existirem
    this.onValueChangedTaxes();
  }

  /* tslint:disable:member-ordering */
  formErrorsTaxes: Array<string> = new Array<string>();
  formErrorsTaxesParam: Array<string> = new Array<string>();
  validationMessagesTaxes = {
    'TaxTypeCode': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX',
      'duplicateTaxes': 'DUPLICATE_TAXES'
    },
    'TaxTypeOther': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'TaxSubTypeCode': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'TaxRate': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    'TaxableAmount': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    'TaxAmount': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    'FreeText': {
      'required': 'FIELD_REQUIRED_'
    }
  };
  /* tslint:enable:member-ordering */

  onValueChangedTaxes(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsTaxes = new Array<string>();
    this.formErrorsTaxesParam = new Array<string>();

    //Validar se há várias linhas do detalhe com IVA
    this.checkForRepeatedValueAddedTaxes();

    for (const field in this.validationMessagesTaxes) {
      if (this.validationMessagesTaxes.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Taxes');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesTaxes[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsTaxes.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsTaxesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsTaxesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }

  /* tslint:disable:member-ordering */
  formErrorsAccountingConfig: Array<string> = new Array<string>();
  formErrorsAccountingConfigParam: Array<string> = new Array<string>();
  validationMessagesAccountingConfig = {
    'ValorFixo': {
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    }
  };
  /* tslint:enable:member-ordering */

  onValueChangedAccountingConfig(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsAccountingConfig = new Array<string>();
    this.formErrorsAccountingConfigParam = new Array<string>();
    for (const field in this.validationMessagesAccountingConfig) {
      if (this.validationMessagesAccountingConfig.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('AccountingConfigList');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesAccountingConfig[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsAccountingConfig.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsAccountingConfigParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsAccountingConfigParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }

  checkIfExemption()
  {
      // verificar se taxa de iva ja foi utilizada e se é para mostrar os motivos de isencao
      const controls = (<UntypedFormArray>this.detailForm.get('Taxes')).controls;
      let isExempt = false;
      let removeIVA = false;
      let reasonControl = this.detailForm.get('VatExemptReasonID'); // para conseguir dizer se é obrigatorio ou nao

      for (let i = 0; i < controls.length; i++) {
        let valueType = controls[i].get('TaxTypeCode').value;

        if (valueType === 'ValueAddedTax' && controls[i].get('TaxRate').value === 0)
        {
          isExempt = true;

          if (this.groupVatExemption)
          {
            let vatExemptReasonIDControl = controls[i].get('VatExemptReasonID');
            vatExemptReasonIDControl.setValidators(Validators.required);
            vatExemptReasonIDControl.updateValueAndValidity(); // para atualizar
          }
        }
        else
        {
          if (this.groupVatExemption)
          {
            let vatExemptReasonIDControl = controls[i].get('VatExemptReasonID');
            vatExemptReasonIDControl.clearValidators();
            vatExemptReasonIDControl.setValue(null);
            vatExemptReasonIDControl.updateValueAndValidity(); // para atualizar
          }
        }

        if (valueType === 'ValueAddedTax') {
          removeIVA = true;
        }

      }

      // Verifica se tem alguma taxa isenta de IVA
      if (isExempt) {
        this.showVatExemption = true;
      } else {
        this.showVatExemption = false;
        this.detailForm.get('VatExemptReasonID').setValue(null);
      }

      if (removeIVA) {
        this.invoiceTaxesTypes = this.invoiceTaxesTypes.filter(obj => obj !== this.ivaTaxValues);
      } else {
        if (this.invoiceTaxesTypes.filter(obj => obj === this.ivaTaxValues).length === 0 && this.ivaTaxValues != null) { // nao existe ja
          this.invoiceTaxesTypes.push(this.ivaTaxValues);
        }
    }
    
    if (this.showVatExemption && !this.groupVatExemption) {
      reasonControl.setValidators(Validators.required);
    } else {
      reasonControl.setValidators([]);
    }
    reasonControl.updateValueAndValidity(); // para atualizar
  }
  //#endregion Taxes

  //#region Discounts
  initDiscounts(discount: InvoiceDiscountValue = null) {
    if (discount) {
      let formDiscounts = this._formBuilder.group({
        'DiscountType': [{ value: discount.DiscountType, disabled: !this.allowEdit }, Validators.compose([ModelValidators.lengthVal({ max: 50 })])],
        'Percentage': [{ value: discount.Percentage, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL })])],
        'Amount': [{ value: discount.Amount_form, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
        'MultiplicationFactor': [{ value: discount.MultiplicationFactor, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0 })])]
      });
      
      if (this.formDisabled) {
        // colocar todos como disabled
        formDiscounts.disable();
      }

      return formDiscounts;
    }
    return this._formBuilder.group({
      'DiscountType': ['', Validators.compose([ModelValidators.lengthVal({ max: 50 })])],
      'Percentage': ['', Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL })])],
      'Amount': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
      'MultiplicationFactor': [ '', Validators.compose([ModelValidators.numberVal({ min: 0 })])]
    });
  }
  addDiscounts(newInsert: boolean, objectInsert: InvoiceDiscountValue = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
      const discCtrl = this.initDiscounts();
      if (!this.allDetailsEditable) {
        discCtrl.disable();
      }
      control.push(discCtrl);
      discCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDiscounts(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
      if (objectInsert) { // usado quando faz reset
        const discCtrl = this.initDiscounts(objectInsert);
        if (!this.allDetailsEditable) {
          discCtrl.disable();
        }
        control.push(discCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedDiscounts(value);
          });
        }
      }
    }
  }
  removeDiscount(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
    control.removeAt(i);
    this.valueCalDiscTax();
  }

  /* tslint:disable:member-ordering */
  formErrorsDiscounts: Array<string> = new Array<string>();
  formErrorsDiscountsParam: Array<string> = new Array<string>();
  validationMessagesDiscounts = {
    'DiscountType': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'Percentage': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    'Amount': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    }
  };
  /* tslint:enable:member-ordering */

  onValueChangedDiscounts(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsDiscounts = new Array<string>();
    this.formErrorsDiscountsParam = new Array<string>();
    for (const field in this.validationMessagesDiscounts) {
      if (this.validationMessagesDiscounts.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Discounts');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesDiscounts[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {
                  this.formErrorsDiscounts.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsDiscountsParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsDiscountsParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  //#endregion Discounts

  //#region Allowances
  initAllowances(allowance: InvoiceAllowanceValue = null) {
    if (allowance) {
      let formAllowances = this._formBuilder.group({
        'AllowanceType': [{ value: allowance.AllowanceType, disabled: !this.allowEdit }, Validators.compose([ModelValidators.lengthVal({ max: 50 })])],
        'Percentage': [{ value: allowance.Percentage, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL })])],
        'Amount': [{ value: allowance.Amount_form, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
        'MultiplicationFactor': [{ value: allowance.MultiplicationFactor, disabled: !this.allowEdit }, Validators.compose([ModelValidators.numberVal({ min: 0 })])]
      });

      if (this.formDisabled) {
        // colocar todos como disabled
        formAllowances.disable();
      }

      return formAllowances;
    }
    return this._formBuilder.group({
      'AllowanceType': ['', Validators.compose([ModelValidators.lengthVal({ max: 50 })])],
      'Percentage': ['', Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: CURRENCY_DECIMAL })])],
      'Amount': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
      'MultiplicationFactor': ['', Validators.compose([ModelValidators.numberVal({ min: 0 })])]
    });
  }
  addAllowances(newInsert: boolean, objectInsert: InvoiceAllowanceValue = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
      const alloCtrl = this.initAllowances();
      if (!this.allDetailsEditable) {
        alloCtrl.disable();
      }
      control.push(alloCtrl);
      alloCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedAllowances(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
      if (objectInsert) { // usado quando fez reset
        const alloCtrl = this.initAllowances(objectInsert);
        if (!this.allDetailsEditable) {
          alloCtrl.disable();
        }
        control.push(alloCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedAllowances(value);
          });
        }
      }
    }
  }
  removeAllowance(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
    control.removeAt(i);
  }

  /* tslint:disable:member-ordering */
  formErrorsAllowances: Array<string> = new Array<string>();
  formErrorsAllowancesParam: Array<string> = new Array<string>();
  validationMessagesAllowances = {
    'AllowanceType': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'Percentage': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    'Amount': {
      'required': 'FIELD_REQUIRED_',
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberMinMax': 'NUMBER_MINMAX_ERROR',
      'numberMax': 'NUMBER_MAX_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    }
  };
  /* tslint:enable:member-ordering */

  onValueChangedAllowances(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsAllowances = new Array<string>();
    this.formErrorsAllowancesParam = new Array<string>();
    for (const field in this.validationMessagesAllowances) {
      if (this.validationMessagesAllowances.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Allowances');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesAllowances[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsAllowances.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsAllowancesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsAllowancesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  //#endregion Allowances

  //#region CALCULOS
  valueCalTax(index: number) {
    // usado no change do valor tributavel e/ou taxas para calcular automaticamente o valor da taxa
    const taxForm = (<UntypedFormArray>this.detailForm.get('Taxes')).controls[index];
    let taxValues = this.tranfModel.revertObjectTax((<UntypedFormGroup>taxForm).getRawValue());
    let taxableVal = taxValues.TaxableAmount;
    let taxAmount = taxValues.TaxAmount;
    let tax = taxValues.TaxRate ? taxValues.TaxRate.toString().revertDecimal() : null;
    let netUnitPrice = this.detailForm.get('NetUnitPrice').value;
    let quantity = this.detailForm.get('Quantity').value;

    // Se o valor tributável não estiver definido, carregaro com base no valor total liquido
    if (!taxableVal) {
      taxableVal = (netUnitPrice ? (netUnitPrice).toString().revertDecimal() : 0) * (quantity ? (quantity).toString().revertDecimal() : 0);
      this.addToFormField(false, taxForm.get('TaxableAmount'), taxableVal, this.Currency_Form);
    }

    if (taxableVal > 0 && tax && tax >= 0) { // tem o valor tributavel e a taxa vai calcular o valor do imposto
      // this.addToFormField(false, taxForm.get('TaxAmount'), taxableVal * tax / 100, this.Currency_Form);
      // O TaxAmount tem que ser sempre arredondado a 4 casas décimais, porque o total do IVA é o somatório dos IVAS detalhes e só depois é arredondado a 2 casa décimais
      this.addToFormFieldDecimal(false, taxForm.get('TaxAmount'), taxableVal * tax / 100, TAX_AMOUNT_DECIMAL);
    }
    //else if (taxableVal > 0 && taxAmount > 0) { // tem o valor tributavel e o valor do imposto, vai calcular o valor da taxa
    //  this.addToFormField(false, taxForm.get('TaxRate'), taxableVal / taxAmount, this.Int_Form);
    //} 
    //else if (taxAmount > 0 && tax && tax >= 0) { // tem o valor do imposto e a taxa, vai calcular o valor tributavel
    //  this.addToFormField(false, taxForm.get('TaxableAmount'), taxAmount * (100 / tax), this.Currency_Form);
    //}

  }
  onChangeDetailNetUnitPrice() {
    // Colocar o GrossUnitPrice a 0 para recalcular novamente
    this.detailForm.controls['GrossUnitPrice'].setValue(0);
    this.valueCalDiscTax();
  }

  onChangeDetailGrossUnitPrice() {
    // Colocar o GrossUnitPrice a 0 para recalcular novamente
    this.detailForm.controls['NetUnitPrice'].setValue(0);
    this.valueCalDiscTax();
  }

  //estes metodos irao colocar o campo unidade a obrigatorio ou nao consoante o input
  onQuantityChange()
  {
    let that = this;

    if(this.detailForm.controls['QuantityUOMCode'].enabled)
    {
      if(this.detailForm.controls['Quantity'].value.revertDecimal() > 0)
      {
        this.detailForm.controls['QuantityUOMCode'].setValidators(Validators.compose([ModelValidators.lengthVal({ max: 25 }), Validators.required]));
        Array.prototype.forEach.call(document.getElementsByName('QuantityUOMCode'), function (el: Element) {
          that._renderer.addClass(el, 'requiredElement');
        });
      }
      else
      {
        this.detailForm.controls['QuantityUOMCode'].setValidators(Validators.compose([ModelValidators.lengthVal({ max: 25 })]));
        this.detailForm.controls['QuantityUOMCode'].setValue('');
        Array.prototype.forEach.call(document.getElementsByName('QuantityUOMCode'), function (el: Element) {
          that._renderer.removeClass(el, 'requiredElement');
        });
      }
      
      this.detailForm.controls["QuantityUOMCode"].updateValueAndValidity();
    }

  }
  onBonusQuantityChange()
  {
    if (this.showBonusQuantityUOMCode)
    {
      let that = this;
      
      if(this.detailForm.controls['BonusQuantity'].value.revertDecimal() > 0)
      {
        this.detailForm.controls['BonusQuantityUOMCode'].setValidators(Validators.compose([ModelValidators.lengthVal({ max: 25 }), Validators.required]));
        Array.prototype.forEach.call(document.getElementsByName('BonusQuantityUOMCode'), function (el: Element) {
          that._renderer.addClass(el, 'requiredElement');
        });
      }
      else
      {
        this.detailForm.controls['BonusQuantityUOMCode'].setValidators(Validators.compose([ModelValidators.lengthVal({ max: 25 })]));
        this.detailForm.controls['BonusQuantityUOMCode'].setValue('');
        Array.prototype.forEach.call(document.getElementsByName('BonusQuantityUOMCode'), function (el: Element) {
          that._renderer.removeClass(el, 'requiredElement');
        });
      }

      this.detailForm.controls["BonusQuantityUOMCode"].updateValueAndValidity();
    }
       
  }
  onPackageQuantityChange()
  {
    if (this.onPackageQuantityChange)
    {
      let that = this;
    
      if(this.detailForm.controls['PackQuantity'].value.revertDecimal() > 0)
      {
        this.detailForm.controls['PackQuantityUOMCode'].setValidators(Validators.compose([ModelValidators.lengthVal({ max: 25 }), Validators.required]));
        Array.prototype.forEach.call(document.getElementsByName('PackQuantityUOMCode'), function (el: Element) {
          that._renderer.addClass(el, 'requiredElement');
        });
      }
      else
      {
        this.detailForm.controls['PackQuantityUOMCode'].setValidators(Validators.compose([ModelValidators.lengthVal({ max: 25 })]));
        this.detailForm.controls['PackQuantityUOMCode'].setValue('');
        Array.prototype.forEach.call(document.getElementsByName('PackQuantityUOMCode'), function (el: Element) {
          that._renderer.removeClass(el, 'requiredElement');
        });
      }
        this.detailForm.controls["PackQuantityUOMCode"].updateValueAndValidity();
    }
  }

  valueCalDiscTax() {
    // usado no change da percentagem e/ou valor dos descontos,
    // no change da percentagem e/ou valor das taxas
    // e/ou alteração do preço un liq e/ou bruto e quantidade, para calcular automaticamente o valor do desconto
    let detailsValues = this.tranfModel.revertObjectDetail(this.detailForm.getRawValue());
    let netUnitPrice = detailsValues.NetUnitPrice ? detailsValues.NetUnitPrice : 0;
    let grossUnitPrice = detailsValues.GrossUnitPrice ? detailsValues.GrossUnitPrice : 0; // sem descontos ou retencoes
    let priceUnit = detailsValues.PriceUnit ? +detailsValues.PriceUnit : 0;
    let quantity = detailsValues.Quantity;
    let totalNetAmount = netUnitPrice * quantity / (priceUnit > 1 ? priceUnit : 1);
    let totalGrossAmount = grossUnitPrice * quantity / (priceUnit > 1 ? priceUnit : 1);
    let sumDiscountUnit = 0, lengthControl = (<UntypedFormArray>this.detailForm.get('Discounts')).controls.length;
    // -DESCONTOS
    // correr todos os descontos para calcular o valor do desconto
    for (let i = 0; lengthControl > i; i++) {
      const discForm = (<UntypedFormArray>this.detailForm.get('Discounts')).controls[i];
      let discountValues = this.tranfModel.revertObjectDiscount((<UntypedFormGroup>discForm).getRawValue());
      let perct = discountValues.Percentage;
      // se a percentagem estiver carregada
      if (perct > 0) {
        if (grossUnitPrice > 0) { // obter o valor da desconto/unidade a partir do GrossUnitPrice (precedÊncia no GrossUnitPrice)
          let discountUnit = (grossUnitPrice - sumDiscountUnit) * (perct / 100);
          this.addToFormField(false, discForm.get('Amount'), discountUnit * quantity / (priceUnit > 1 ? priceUnit : 1), this.UnitPrice_Form);

          sumDiscountUnit += discountUnit;
        } else if (netUnitPrice > 0) { // obter o valor da desconto/unidade a partir do NetUnitPrice
          let discountUnit = ((netUnitPrice + sumDiscountUnit) * (perct)) / (100 - perct);
          this.addToFormField(false, discForm.get('Amount'), discountUnit * quantity / (priceUnit > 1 ? priceUnit : 1), this.UnitPrice_Form);

          sumDiscountUnit += discountUnit;
        } else {
          let discountUnit = 0;
          this.addToFormField(false, discForm.get('Amount'), discountUnit, this.UnitPrice_Form);
        }
      }
    } 
    
    // O GrossUnitPrice vai ter precedência sobre o NetUnitPrice
    if (grossUnitPrice > 0) {
      grossUnitPrice = grossUnitPrice - sumDiscountUnit;
      this.addToFormField(false, this.detailForm.get('GrossUnitPrice'), grossUnitPrice, this.UnitPrice_Form);
    } else if (netUnitPrice > 0) {
      netUnitPrice = netUnitPrice + sumDiscountUnit;
      this.addToFormField(false, this.detailForm.get('NetUnitPrice'), netUnitPrice, this.UnitPrice_Form);
    } // caso contrário são ambos 0

    
    // Recarregar os totais porque podem ter sido alterados com os calculos
    totalNetAmount = netUnitPrice * quantity / (priceUnit > 1 ? priceUnit : 1);
    totalGrossAmount = grossUnitPrice * quantity / (priceUnit > 1 ? priceUnit : 1);
    this.addToFormField(false, this.detailForm.get('TotalNetAmount'), totalNetAmount, this.Currency_Form);
    this.addToFormField(false, this.detailForm.get('TotalGrossAmount'), totalGrossAmount, this.Currency_Form);
    

    // -IMPOSTOS
    // carregar as taxas != IVA
    lengthControl = (<UntypedFormArray>this.detailForm.get('Taxes')).controls.length;
    let sumTaxAmount = 0;
    for (let i = 0; lengthControl > i; i++) {
      let taxRate = 0
      const formTax = (<UntypedFormArray>this.detailForm.get('Taxes')).controls[i];
      let taxValue = this.tranfModel.revertObjectTax((<UntypedFormGroup>formTax).getRawValue());
      if (taxValue.TaxTypeCode !== 'ValueAddedTax') {
        let taxableAmount = netUnitPrice * quantity / (priceUnit > 1 ? priceUnit : 1);
        
        if(taxValue.TaxTypeCode === 'AlcoholMarkTax'){
          taxableAmount = 0;
          taxRate = 0;
        }
        
        // if (taxValue.TaxableAmount === 0 || taxValue.TaxableAmount == null) {
        this.addToFormField(false, formTax.get('TaxableAmount'), taxableAmount, this.Currency_Form);
        // }
        let taxAmount = (taxValue.TaxAmount ? taxValue.TaxAmount : 0);
        taxRate = taxValue.TaxRate;
        if (taxRate >= 0 && taxValue.TaxTypeCode !== 'AlcoholMarkTax') { // taxrate tem precedência sobre o taxAmount
          taxAmount = taxableAmount * (taxRate / 100);
          // this.addToFormField(false, formTax.get('TaxAmount'), taxAmount, this.Currency_Form);
          // O TaxAmount tem que ser sempre arredondado a 4 casas décimais, porque o total do IVA é o somatório dos IVAS detalhes e só depois é arredondado a 2 casa décimais
          sumTaxAmount += taxAmount;
        }

        //Formatar o tax amount
        this.addToFormFieldDecimal(false, formTax.get('TaxAmount'), taxAmount, TAX_AMOUNT_DECIMAL);

      }
    }
    // carregar o IVA (o calculo inclui as taxas anteriores)
    for (let i = 0; lengthControl > i; i++) {
      const formTax = (<UntypedFormArray>this.detailForm.get('Taxes')).controls[i];
      let taxValue = this.tranfModel.revertObjectTax((<UntypedFormGroup>formTax).getRawValue());

      if (taxValue.TaxTypeCode === 'ValueAddedTax') {
        let taxableAmount = (netUnitPrice * quantity / (priceUnit > 1 ? priceUnit : 1)) + sumTaxAmount;
        // if (taxValue.TaxableAmount === 0 || taxValue.TaxableAmount == null) {
        this.addToFormField(false, formTax.get('TaxableAmount'), taxableAmount, this.Currency_Form);
        // }
        let taxRate = taxValue.TaxRate;
        if (taxRate >= 0) { // taxrate tem precedência sobre o taxAmount
          // this.addToFormField(false, formTax.get('TaxAmount'), taxableAmount * (taxRate / 100), this.Currency_Form);
          // O TaxAmount tem que ser sempre arredondado a 4 casas décimais, porque o total do IVA é o somatório dos IVAS detalhes e só depois é arredondado a 2 casa décimais
          this.addToFormFieldDecimal(false, formTax.get('TaxAmount'), taxableAmount * (taxRate / 100), TAX_AMOUNT_DECIMAL);
        }
      }
    }
  }
  //#endregion CALCULOS

  formatCurrency(value: number) {
    return this._decimalPipe.transform(value, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL);
  }
  formatUnitPrice(value: number) {
    // colocar em formato de preco unitario ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
    return this._decimalPipe.transform(value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL);
  }
  formatInt(value: number) {
    // colocar sem casas decimais ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
    return this._decimalPipe.transform(value, '1.' + 0 + '-' + 0);
  }
  formatDecimal(value: number, decimalPlaces: string) {
    return this._decimalPipe.transform(value, '1.' + decimalPlaces + '-' + decimalPlaces);
  }
  formatQuantity(value: number) {
    return this._decimalPipe.transform(value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL);
  }

  addToFormField(add: boolean, control: AbstractControl, addValue: number, decimalType: number) {
    // add- se é para adicionar ao valor que o form ja tem/ control- controlForm/ addValue - valor a adicionar
    // decimalType -> 1 - formatCurrency, 2 - formatUnitPrice, 3 - sem casas decimais

    if (addValue === 0 && add) { // se nao tiver valores a adicionar
      return;
    }

    // let origValue = add ? this.tranfModel.transformNumber(control.value) : 0; // ir buscar o valor que esta anteriormente, se nao for para adicionar comeca com zero
    let origValue = add ? ((control.value).toString()).revertDecimal() : 0; // ir buscar o valor que esta anteriormente, se nao for para adicionar comeca com zero
    let value: string;
    // transformar o valor para ser considerado um number
    // addValue = this.tranfModel.transformNumber(addValue.toString());
    addValue = (addValue.toString()).revertDecimal();

    if (!isNaN(origValue) && !isNaN(addValue)) {
      switch (decimalType) {
        case 0: {
          value = (+origValue + addValue).toString();
          break;
        }
        case 1: {
          value = this.formatCurrency(+origValue + addValue);
          break;
        }
        case 2: {
          value = this.formatUnitPrice(+origValue + addValue);
          break;
        }
        case 3: {
          value = this.formatInt(+origValue + addValue);
          break;
        }
        default:
          break;
      }
    }
    control.setValue(value);
  }

  addToFormFieldDecimal(add: boolean, control: AbstractControl, addValue: number, decimalPlaces: string) {
    // add- se é para adicionar ao valor que o form ja tem/ control- controlForm/ addValue - valor a adicionar

    if (addValue === 0 && add) { // se nao tiver valores a adicionar
      return;
    }

    let origValue = add ? ((control.value).toString()).revertDecimal() : 0; // ir buscar o valor que esta anteriormente, se nao for para adicionar comeca com zero
    let value: string;
    // transformar o valor para ser considerado um number
    addValue = (addValue.toString()).revertDecimal();

    if (!isNaN(origValue) && !isNaN(addValue)) {
      value = this.formatDecimal(+origValue + addValue, decimalPlaces);
    }
    control.setValue(value);
  }

  checkChanges() {
    this.onValueChangedDetails();
    this.onValueChangedOtherReferences();
    this.onValueChangedTaxes();
    this.onValueChangedDiscounts();
    this.onValueChangedAllowances();
  }

  save() {
    //a fatura pode não ter todos os campos ativos para serem editados, mas ter alguns
    if (this.allowEdit || this.enableSaveButton) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.checkChanges();
      
      // GM 2022-12-12 Validar se as referencias têm o tipo ou o valor carregado 
      const controlOtherReferences = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (controlOtherReferences)
      {
        let index = controlOtherReferences.getRawValue().findIndex(x => !x.ReferenceType && !x.ReferenceValue);
        if (index >= 0) {
          this._translateService.get('INVALID_OTHER_REFERENCES').subscribe(translation => Functions.gritter(translation, 'danger'));
          return;
        }
      }

      if (this.useProductCostCenter) {
        //Validar se o centro de custo foi preenchido e pertence à lista 
        if (this.costCenterList && this.costCenterList.length > 0)
        {
          let selectedCode:string = this.getFieldExtensionFormValue('CostCenter');
          if (!selectedCode) {
            //Centro de custo é obrigatório
            Functions.gritter('O centro de custo é obrigatório!', 'danger');
            return;
          }
          else if (!this.costCenterList.find(x => x.code == selectedCode))
          {
            //Centro de custo inválido
            Functions.gritter('O centro de custo é inválido!', 'danger');
            return;
          }
        }
      }

      if (this.useProductReclassificationCode) {
        //Validar se o código de reclassificaçãofoi preenchido e pertence à lista 
        if (this.reclassificationCodeList && this.reclassificationCodeList.length > 0)
        {
          let selectedCode:string = this.getFieldExtensionFormValue('ReclassificationCode');
          if (!selectedCode) {
            //Centro de custo é obrigatório
            Functions.gritter('O código de reclassificação é obrigatório!', 'danger');
            return;
          }
          else if (!this.reclassificationCodeList.find(x => x.code == selectedCode))
          {
            //Centro de custo inválido
            Functions.gritter('O código de reclassificação é inválido!', 'danger');
            return;
          }
        }
      }
      
      if (this.detailForm.get('Quantity').value){
        let quantity: number = this.detailForm.get('Quantity').value.toString().revertDecimal();
  
        if (this.validateQuantityMaxAllowed) {
          // validar se a quantidade introduzida é inferior à quantidade encomendada (tendo em conta o que já foi faturado anteriormente)
          if (this.detailForm.get('QuantityMaxAllowed').value &&
            quantity > this.detailForm.get('QuantityMaxAllowed').value) {
            this.noError = false;
            this._translateService.get('INVOICE_DETAIL_QUANTITY_VALIDATION', { value: this.detailForm.get('QuantityMaxAllowed').value })
              .subscribe(translation => Functions.gritter(translation, 'danger'));
          }
        }
        if (this.noError) {
          this.dialogRef.close(this.detailForm); // nao pode enviar so o value porcausa dos campos disabled
        }
        this.submitted = false;
      }else {
        this.dialogRef.close(this.detailForm);
      }
    }
  }

  resetForm()
  {
    this.detailForm.reset(this.model);
    let initialValues = null;
    // colocar os arrays com os valores iniciais

    // -Referencias
    const controlsO = <UntypedFormArray>this.detailForm.get('OtherReferences');
    // remover todos
    while (controlsO.length) {
      controlsO.removeAt(controlsO.length - 1);
    }
    if (this.model) {
      initialValues = this.model.OtherReferences;
      initialValues.forEach((other: InvoiceOtherReferenceValue) => {
        this.addOtherReferences(false, other);
      });
    }

    // -Impostos (Taxas)
    const controlsT = <UntypedFormArray>this.detailForm.get('Taxes');
    // remover todos
    while (controlsT.length) {
      controlsT.removeAt(controlsT.length - 1);
    }
    if (this.model) {
      initialValues = this.model.Taxes;
      initialValues.forEach((tax: InvoiceTax) => {
        this.addTaxes(false, tax);
      });
    }
    // -Descontos
    const controlsD = <UntypedFormArray>this.detailForm.get('Discounts');
    // remover todos
    while (controlsD.length) {
      controlsD.removeAt(controlsD.length - 1);
    }
    if (this.model) {
      initialValues = this.model.Discounts;
      initialValues.forEach((disc: InvoiceDiscountValue) => {
        this.addDiscounts(false, disc);
      });
    }
    // -Abonos
    const controlsA = <UntypedFormArray>this.detailForm.get('Allowances');
    // remover todos
    while (controlsA.length) {
      controlsA.removeAt(controlsA.length - 1);
    }
    if (this.model) {
      initialValues = this.model.Allowances;
      initialValues.forEach((allo: InvoiceAllowanceValue) => {
        this.addAllowances(false, allo);
      });
    }
    this.dialogRef.close(null);
  }

  getChildsList(parentID: number, tipoTratamentoIVA: string) {
    let accountProductSubtype: number = 0;
    let accountProductSubtypeName: string = null;
    let accountProductSubtypeEntity: TipologiaProdutoServico = null;

    //vamos buscar a lista de subtipos consoante o pai e o tipo de tratamento de inva selecionados
    this.invoiceService.GetChildsList(parentID, tipoTratamentoIVA).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject != null) {
        this.accountProductSubTypes = response.ReturnStatus.ReturnObject;
        //se a lista de subtipos for diferente de null ou undefined
        if (this.accountProductSubTypes) {
          //vamos obter o id do subtipo (filho)
          accountProductSubtype = this.detailForm.controls['TipologiaProdutoServicoID'].value ? this.detailForm.controls['TipologiaProdutoServicoID'].value : 0;
          //vamos tentar obter a entidade através do id do filho
          accountProductSubtypeEntity = this.accountProductSubTypes.find(x => x.ID === accountProductSubtype);
          //se for diferente de null
          if (accountProductSubtypeEntity) {
            //obtemos o nome (descrição) do subtipo
            accountProductSubtypeName = accountProductSubtypeEntity.Name;
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(accountProductSubtypeName ? accountProductSubtypeName : null);
          }
        }
      }
    });
  }

  //Quando se muda o tipo de Produto/Serviço
  onChangeAccountProductType(value: number) {
    this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
    this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
    let tipoTratamentoIVA = this.detailForm.controls['VatTreatmentType'].value;

    //se foi selecionado algum tipo de produto/serviço e um tipo de tratameno de iva
    if (value != null && tipoTratamentoIVA != null) {
      //temos de ir buscar os subtipos para estes dois tipos
      this.getChildsList(value, tipoTratamentoIVA);
    }
  }

  //Quando se muda o tipo de tratamento de IVA
  onChangeVatTreatmentType($event: any) {
    this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
    this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
    let accountProductType = this.detailForm.controls['AccountProductType'].value;

    //se foi selecionado algum tipo de produto/serviço e um tipo de tratameno de iva
    if ($event.value != null && accountProductType != null) {
      //vamos buscar a lista de subtipos com base nos dois tipos selecionados
      this.getChildsList(accountProductType, $event.value);
    }
  }


  setAccountProductType() {

    this._translateService.get(['SELECT_ACCOUNT_PRODUCT_TYPE', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.accountProductTypes, null, response['SELECT_ACCOUNT_PRODUCT_TYPE'], aoColumns, columnDefs, null, 1, null, null, null, true),
          disableClose: false, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          let resultID = parseInt(result, null);
          if ((resultID != null || resultID.toString().length > 0) && resultID > 0) {
            let index = this.accountProductTypes.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['AccountProductType'].setValue(this.accountProductTypes[index].ID);
            this.detailForm.controls['AccountProductTypeDescription'].setValue(this.accountProductTypes[index].Name);
            this.onChangeAccountProductType(this.accountProductTypes[index].ID);
          } else if (resultID != null && resultID === -1) {
            this.detailForm.controls['AccountProductType'].setValue(null);
            this.detailForm.controls['AccountProductTypeDescription'].setValue(null);
            this.onChangeAccountProductType(null);
          }
        }
      });

    });
  }


  setAccountProductSubtype() {
    this._translateService.get(['SELECT_ACCOUNT_PRODUCT_SUBTYPE', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.accountProductSubTypes, null, response['SELECT_ACCOUNT_PRODUCT_SUBTYPE'], aoColumns, columnDefs, null, 1, null, null, null, true),
          disableClose: false, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          let resultID = parseInt(result, null);
          if ((resultID != null || resultID.toString().length > 0) && resultID > 0) {
            let index = this.accountProductSubTypes.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['AccountProductSubtype'].setValue(this.accountProductSubTypes[index].ID);
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(this.accountProductSubTypes[index].Name);
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(this.accountProductSubTypes[index].ID);
          } else if (resultID != null && resultID === -1) {
            this.detailForm.controls['AccountProductSubtype'].setValue(null);
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
          }
          this.loadAccounting();
        }
      });

    });
  }

  //quando se altera a tipologia produto serviço, é carregada novamente a contabilização
  loadAccounting() {
    this.onValueChangedAccountingConfig();
    let companyID = this.authenticationService.session.company.ID;
    const control = <UntypedFormArray>this.formDist.controls['AccountingConfigList'];
    let tipologiaProdutoServicoID = this.detailForm.get('TipologiaProdutoServicoID').value;
    let vatRate = (this.model && this.model.Taxes && this.model.Taxes.find(t => t.TaxTypeCode == 'ValueAddedTax')) ? this.model.Taxes.find(t => t.TaxTypeCode == 'ValueAddedTax').TaxRate : 0;
    //se não houver registos, inserimos um novo com os dados que vêm
    if (control != null && control.controls.length == 0) {
      this.accountingConfigService.getAccountingConfig(companyID, this.invoiceYear, this.model.ProductID, tipologiaProdutoServicoID, vatRate, false).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          let accountingConfig: AccountingConfig = null;
          if (response.ReturnStatus.ReturnObject != null) {
            accountingConfig = response.ReturnStatus.ReturnObject;
            const newCtrl = this.initAccountingConfig(accountingConfig);
            control.push(newCtrl);
            newCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
              this.onValueChangedAccountingConfig(value);
            });
          }
        }
      });
    } else {
      //se já houverem registos, vamos percorrê-los e para cada um, vamos buscar os dados e carregá-los na linha
      if (control.getRawValue().length > 0) {
        control.controls.forEach((item: UntypedFormGroup, index: number) => {
          let refaturacao = item.controls['Refaturar'].value;
          this.accountingConfigService.getAccountingConfig(companyID, this.invoiceYear, this.model.ProductID, tipologiaProdutoServicoID, vatRate, refaturacao).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if (response.ReturnStatus.Successfull) {
              let accountingConfig: AccountingConfig = null;
              if (response.ReturnStatus.ReturnObject != null) {
                accountingConfig = response.ReturnStatus.ReturnObject;
                item.controls['CodGrupoContabilizacao'].setValue(accountingConfig.CodGrupoContabilizacao);
                item.controls['GrupoIVA'].setValue(accountingConfig.GrupoIVA);
                item.controls['CodAtividade'].setValue(accountingConfig.CodAtividade);
                item.controls['Tipo'].setValue(accountingConfig.Tipo);
                item.controls['TipoTratamentoIva'].setValue(accountingConfig.TipoTratamentoIva);
                item.controls['CodMensualizacao'].setValue(accountingConfig.CodMensualizacao);
                
                //para garantir que o onChangeType já foi executado
                setTimeout(() => {
                  //tem de ser alterado depois do tipo porque depende do tipo
                  item.controls['ContaGasto'].setValue(accountingConfig.ContaGasto);
                  this.ref.detectChanges();
                });

              }
            }
          });
        });
      }
    }
  }

  

    //#region GenericFields

    /**
     * Selector de Centros de Custo, apenas se encontra disponível se for devolvido mais que um Centro de Custo
     */
     selectCostCenter(){
      this._translateService.get(['SELECT_COST_CENTER', 'CODE', 'DESCRIPTION']).subscribe(response => {

          let aoColumns = [
              { 'data': 'code', 'class': 'verticalMiddle', 'title': response['CODE'] },
              { 'data': 'description', 'class': 'verticalMiddle', 'title': response['DESCRIPTION'] }
          ];

          let columnDefs = [
              { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
          ],

          dialogRef = this.dialog.open(ChooseModalComponent, {
              data: // dados que vai enviar para o componente da modal
              new ChooseModalParam(this.costCenterList, null, response['SELECT_COST_CENTER'],
                  aoColumns, columnDefs, null, 1, null, null, null, null, 'code'),
              disableClose: true // nao permitir fechar modal com escape ou clique fora
          });

          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              const chosenCostCenter = this.costCenterList.find(x => x.code == result);
              if (chosenCostCenter) {
                this.costCenter = chosenCostCenter;
                //Carregar o form
                this.setFieldExtensionFormValue('CostCenter', this.costCenter.code);

                //Atualizar os códigos de reclassificação
                this.getReclassificationCodeClassification(this.costCenter.code);
              }
            }  
          });
      });
  }

  
  /**
   * Selector de Códigos de Reclassificação, apenas se encontra disponível se for devolvido mais que um Código de Reclassificação
   */
  selectRecCode(){
      this._translateService.get(['SELECT_RECLASSIFICATION_CODE', 'CODE', 'DESCRIPTION']).subscribe(response => {

          let aoColumns = [
              { 'data': 'code', 'class': 'verticalMiddle', 'title': response['CODE'] },
              { 'data': 'description', 'class': 'verticalMiddle', 'title': response['DESCRIPTION'] }
          ];

          let columnDefs = [
              { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
          ],

          dialogRef = this.dialog.open(ChooseModalComponent, {
              data: // dados que vai enviar para o componente da modal
              new ChooseModalParam(this.reclassificationCodeList, null, response['SELECT_RECLASSIFICATION_CODE'],
                  aoColumns, columnDefs, null, 1, null, null, null, null, 'code'),
              disableClose: true // nao permitir fechar modal com escape ou clique fora
          });

          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              const chosenReclassification = this.reclassificationCodeList.find(x => x.code == result);
              if (chosenReclassification) {
                this.reclassificationCode = chosenReclassification;
                //Carregar o form
                this.setFieldExtensionFormValue('ReclassificationCode', this.reclassificationCode.code);
              }
            } 
          });
      });
  }

  /**
   * Obtém a Listagem de Centros de Custo
   * @param  {return new Promise<void>} -> Devolve uma promise de modo a utilizar o método "await" para impedir erros nos timings
   */
  getCostCenterClassification()
  {  
    if (this.product)
    {        
      this.orderService.getCostCenterClassification(this.product.CodGrupoContabilizacao, this.product.VATRate, this.supplierIdentifier).subscribe((response: ReturnStatusHtml) => 
      {
        if (response.ReturnStatus.Successfull) 
        {    
            //Obtém a Listagem devolvida
            let costCenterJSONObject = response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'costCenterList').Value;
            
            //Adiciona os Centros de Custo obtidos pelo Web Service ao Array de Centros de Custo
            costCenterJSONObject.forEach(jsonObj => {
                this.costCenterList.push({
                    code: jsonObj.find(costCenter => costCenter.Key == 'code').Value,
                    description: jsonObj.find(costCenter => costCenter.Key == 'description').Value
                })
            });
            
            //Se a Listagem devolvida vier vazia, bloqueia-se o FormControl e notifica o utilizador
            if(this.costCenterList.length == 0){

              //Valida se o ExtendedField 'FieldCode' existe na Listagem de ExtendedFields preenchido
              let selectedCode:string = this.getFieldExtensionFormValue('CostCenter');
              
              //limpar o costcenter selecionado - se deixou de existir colocar ?código? na descrição
              this.costCenter = {
                code: '',
                description: (selectedCode ? '?'+selectedCode+'?' : '')
              };
              // limpar o valor do form do cost center
              this.setFieldExtensionFormValue('CostCenter', null);
              if (selectedCode)
              {
                Functions.gritter(response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'message').Value, 'danger');
              }
            }
            //Se tiver centros de custo associados
            else (this.costCenterList.length >= 1)
            {
              //Valida se o ExtendedField 'FieldCode' existe na Listagem de ExtendedFields preenchido
              let selectedCode:string = this.getFieldExtensionFormValue('CostCenter');
              //Caso exista, coloca o valor do Centro de Custo no ExtendedField 'FieldCode'
              if (selectedCode)
              {
                this.costCenter =  this.costCenterList.find(x => x.code == selectedCode);
                if (!this.costCenter) {
                  // se deixou de existir colocar ?código? na descrição
                  this.costCenter = {
                    code: '',
                    description: '?'+selectedCode+'?'
                  }
                  //limpar o valor do form, caso já não exista na lista
                  this.setFieldExtensionFormValue('CostCenter', null);
                  this._translateService.get('COST_CENTER_NOT_FOUND').subscribe(translation => Functions.gritter(translation, 'danger'));
                }
              }
              //Se ainda não existe na BD e só existe um registo -> carregar por defeito
              else if (this.costCenterList.length == 1)
              {
                //carregar o centro de custo selecionado
                this.costCenter = this.costCenterList[0];
                //Carregar o valor no form
                this.setFieldExtensionFormValue('CostCenter', this.costCenter.code);
              }
            }

            //Atualizar o código de reclassificação
            if(this.useProductReclassificationCode){
              this.getReclassificationCodeClassification(this.costCenter ? this.costCenter.code : null);
            }
        } 
        else { // o que acontece se der erro
          this._errorTreat.treatErrorResponse(response);
        }
      });
    }
  }


  /**
   * Obtém a Listagem de Códigos de Reclassificação consoante o ID do Centro de Custo
   * @param  {string} code - código do Centro de Custo
   * @param  {return new Promise<void>} -> Devolve uma promise de modo a utilizar o método "await" para impedir erros nos timings
   */
  getReclassificationCodeClassification(code: string){
    if (code)
    {  
      this.orderService.getReclassificationCodeClassification(this.product.CodGrupoContabilizacao, this.product.VATRate, 
        code, this.supplierIdentifier).subscribe((response: ReturnStatusHtml) => 
      {
          if (response.ReturnStatus.Successfull) 
          {
            //Obtém a Listagem devolvida
            let reclassificationCodeJSONObject = response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'reclassificationCodeList').Value;
            
            //Adiciona os Códigos de Reclassificação obtidos pelo Web Service ao Array de Códs de Reclassificação
            reclassificationCodeJSONObject.forEach(jsonObj => {
              if (jsonObj.find(recCode => recCode.Key == 'code').Value && jsonObj.find(recCode => recCode.Key == 'description').Value)
              {
                this.reclassificationCodeList.push({
                    code: jsonObj.find(recCode => recCode.Key == 'code').Value,
                    description: jsonObj.find(recCode => recCode.Key == 'description').Value
                });
              }
            });

            //Se a Listagem devolvida vier vazia, bloqueia-se o FormControl e notifica o utilizador
            if(this.reclassificationCodeList.length == 0)
            {
              //Valida se o ExtendedField 'FieldCode' existe na Listagem de ExtendedFields preenchido
              let selectedCode:string = this.getFieldExtensionFormValue('ReclassificationCode');
              
              //limpar o código selecionado --> se existia na BD coloca na descrição ?código?
              this.reclassificationCode = {
                code: '',
                description: (selectedCode ? '?'+selectedCode+'?' : '')
              };
              // limpar o valor do form do código de reclassificação
              this.setFieldExtensionFormValue('ReclassificationCode', null);
              
              if (selectedCode)
              {
                Functions.gritter(response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'message').Value, 'danger');
              }
            }
            //Se existirem registos
            else if (this.reclassificationCodeList.length >= 1)
            {
              //Valida se o ExtendedField 'FieldCode' existe na Listagem de ExtendedFields preenchido
              let selectedCode:string = this.getFieldExtensionFormValue('ReclassificationCode');
              //Caso exista na BD, verifica se é válido
              if (selectedCode)
              {
                this.reclassificationCode =  this.reclassificationCodeList.find(x => x.code == selectedCode);
                if (!this.reclassificationCode) {
                  //o objeto deve ficar com o código e a descrição vazia
                  this.reclassificationCode = {
                    code: '',
                    description: '?'+selectedCode+'?'
                  };
                  //limpar o valor do form, caso já não exista na lista
                  this.setFieldExtensionFormValue('ReclassificationCode', null);
                  this._translateService.get('RECLASSIFICATION_CODE_NOT_FOUND').subscribe(translation => Functions.gritter(translation, 'danger'));
                }
              }
              //Se não existe e só existe um item --> carregar por defeito
              else if (this.reclassificationCodeList.length == 1)
              {
                //Carregar a reclassificação selecionada
                this.reclassificationCode = this.reclassificationCodeList[0];
                //Carregar o form com o valor selecionado
                this.setFieldExtensionFormValue('ReclassificationCode', this.reclassificationCode.code);
              }
            }
          } else { // o que acontece se der erro
              this._errorTreat.treatErrorResponse(response);
          }
      });
    }
    else
    {
      //limpar o código de reclassificação
      this.reclassificationCode = {
        code: '',
        description: ''
      };
      // limpar o valor do form do código de reclassificação
      this.setFieldExtensionFormValue('ReclassificationCode', null);
    }
  }

  
  /**
   * Obtém o FieldValue do elemento do FieldExtensions especificado
   * @param  {string} fieldCode
   */
  getExtendedFieldValue(fieldCode: string){
      return this.model.FieldsExtensions.find(fieldExtension => fieldExtension.FieldCode = fieldCode).FieldValue;
  }

  
  /**
   * Obtém a informação do Produto associado à Linha do Contrato
   * @param  {return new Promise<void>} -> Devolve uma promise de modo a utilizar o método "await" para impedir erros nos timings
   */
  getProductInfo()
  {
    //Se não existir, adicionar o form dos fieldsextensions
    if(this.detailForm && !this.detailForm.get('FieldsExtensions'))
    {
      this.detailForm.addControl('FieldsExtensions', this.formBuilder.array([]));
    }

    if(this.model && this.model.ProductID)
    {
        this.productService.get(this.model.ProductID, true).subscribe((response: ReturnStatusHtml) => 
        {
          if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject.Product) 
          {
              this.product = response.ReturnStatus.ReturnObject.Product;

              //Só após obtermos a Info do Produto é que podemos obter a Info dos Centros de Custo e Códigos de Reclassificação
              this.getCostCenterClassification();
          } 
          else 
          { // o que acontece que der erro
              this._errorTreat.treatErrorResponse(response);
          }
      });
    }

  }

  initDetailFieldsExtensions(item: GenericFieldExtension) {
    let fieldExtensionForm: UntypedFormGroup = 
      this.formBuilder.group({
        'ID': item.ID,
        'Context': item.Context,
        'ReferenceID': item.ReferenceID,
        'ListCode': item.ListCode,
        'FieldCode': item.FieldCode,
        'FieldValue': item.FieldValue,
        'LanguageCode': item.LanguageCode,
        'Identifier': item.Identifier
      });
    
    return fieldExtensionForm;
  }

  getFieldExtensionFormValue(fieldCode: string) {
    let result: string = null;
    let fieldsExtensionsFormArray: UntypedFormArray = <UntypedFormArray>this.detailForm.controls['FieldsExtensions'];
    for (let i = 0; i < fieldsExtensionsFormArray.controls.length; i++) {
      let codeControl = fieldsExtensionsFormArray.controls[i].get('FieldCode');
      if (codeControl && codeControl.value == fieldCode)
      {
        let valueControl = fieldsExtensionsFormArray.controls[i].get('FieldValue');
        if (valueControl)
        {
          result = valueControl.value;
        }
        break;
      }
    }

    return result;
  }

  setFieldExtensionFormValue(fieldCode: string, fieldValue: string) {
    let exists: boolean = false;
    let fieldsExtensionsFormArray: UntypedFormArray = <UntypedFormArray>this.detailForm.controls['FieldsExtensions'];
    for (let i = 0; i < fieldsExtensionsFormArray.controls.length; i++) {
      let codeControl = fieldsExtensionsFormArray.controls[i].get('FieldCode');
      if (codeControl && codeControl.value == fieldCode)
      {
        exists = true;
        let valueControl = fieldsExtensionsFormArray.controls[i].get('FieldValue');
        if (valueControl)
        {
          valueControl.setValue(fieldValue);
        }
        break;
      }
    }
    if (!exists)
    {
      //se não existor um item com o código indicado, criar o control com esse código e valor
      fieldsExtensionsFormArray.push(this.initDetailFieldsExtensions(new GenericFieldExtension(0, this.model.ID, 'InvoiceDetail', fieldValue, null, fieldCode, '', null)));
    }
  }

  //Validar se alguma linha das taxas tem o tipo IVA repetido
  checkForRepeatedValueAddedTaxes() {

    if (!this.detailForm.get('Taxes')) { return; }
  
      //Transformar controlo em FormArray para percorrer as linhas de detalhe das taxas
      const formArray = this.detailForm.get('Taxes') as FormArray;
  
      //Variável para verificar se tem linhas de taxas com vários IVAs
      //Mostrar APENAS uma linha com erro de taxas iva duplicadas
      let hasMoreThanValueAddedTax = false;
  
      formArray.controls.forEach((controlForm: FormControl) => {
  
        //Verificar se já existe uma linha do detalhe com IVA
        if (controlForm.get('TaxTypeCode').value === 'ValueAddedTax' && hasMoreThanValueAddedTax) {

          if (!this.formErrorsTaxes.includes(this.validationMessagesTaxes.TaxTypeCode.duplicateTaxes)) {
            this.formErrorsTaxes.push(this.validationMessagesTaxes.TaxTypeCode.duplicateTaxes);
          }
        }
  
        if (controlForm.get('TaxTypeCode').value === 'ValueAddedTax' && !hasMoreThanValueAddedTax) {
          hasMoreThanValueAddedTax = true;
        }

      });
  }

  onCostCenterChange(id: number)
  {
    /*TODO se for necessário
    this.changedForm = true;

    if(this.detailForm)
        this.detailForm.get('CostCenterID').setValue(id);

    if(this.details?.length)
    {
        //Validar se todos os centros de custo detalhes são iguais ao primeiro <=> Validar se todos os centros de custo dos detalhes são iguais
        if (this.details.every(x=> x.CostCenterID == this.details[0].CostCenterID))
        {
            this.form.controls['CostCenterID'].setValue(this.details[0].CostCenterID);
            this.form.get('CostCenterID').setValue(this.details[0].CostCenterID);
        }
        else
        {
            this.form.controls['CostCenterID'].setValue(null);
            this.form.get('CostCenterID').setValue(null);
        }
    }
    */
  }

  ngOnDestroy() { }
}


