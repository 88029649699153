import { Component, ViewEncapsulation, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import {
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import {
  PAGE_TITLE,
  setServiceURL,
  setWebsiteURL,
  setALIAS,
  setGlobalErrorPage,
  LANGUAGE,
  setLanguage,
  setDateFormat,
  setDateHourFormat,
  setStepMin,
  setPortalName,
  PORTAL_NAME,
  setSupportEmail,
  WEBSITE_URL,
  setTimeZone,
  setHidePortalNameOnLogin,
  setPortalTitleName,
  PORTAL_TITLE_NAME,
} from "./constants/global";
import { AppConfig } from "./configs/app.config";
import { AuthenticationService } from "./services/authentication.service";
import { RoutingState } from "./services/routingState.service";
import { TranslateValueService } from "./services/translate-value.service";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

declare var Functions: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: "router-app",
  template: `<router-outlet></router-outlet>`,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router: Router,
    titleService: Title,
    private translateService: TranslateService,
    private translateValueService: TranslateValueService,
    config: AppConfig,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private http : HttpClient,
    routingState: RoutingState
  ) {
    let setting = config.getConfig("SERVICE_URL");
    if (setting !== undefined) {
      setServiceURL(setting);
    }
    setting = config.getConfig("WEBSITE_URL");
    if (setting !== undefined) {
      setWebsiteURL(setting);
    }
    setting = config.getConfig("LOGIN_CSS");
    if (setting) {
      Functions.setLoginCss(WEBSITE_URL, setting);
    }
    setting = config.getConfig("ALIAS");
    if (setting !== undefined) {
      setALIAS(setting);
    }
    setting = config.getConfig("GLOBAL_ERROR_PAGE");
    if (setting !== undefined) {
      setGlobalErrorPage(setting);
    }
    setting = config.getConfig("PORTAL_NAME");
    if (setting !== undefined) {
      setPortalName(setting);
    }
    setting = config.getConfig("HIDE_PORTAL_NAME_LOGIN");
    if (setting !== undefined) {
      setHidePortalNameOnLogin(setting);
    }
    setting = config.getConfig("SUPPORT_EMAIL");
    if (setting !== undefined) {
      setSupportEmail(setting);
    }
    setting = config.getConfig("TIME_ZONE");
    if (setting !== undefined) {
      setTimeZone(setting);
    }

    // Date Settings
    setting = config.getConfig("DATE_FORMAT");
    if (setting !== undefined) {
      setDateFormat(setting);
    }
    setting = config.getConfig("DATE_HOUR_FORMAT");
    if (setting !== undefined) {
      setDateHourFormat(setting);
    }
    setting = config.getConfig("STEP_MIN");
    if (setting !== undefined) {
      setStepMin(setting);
    }

    // Carregar o icon da App
    setting = config.getConfig("ICON");
    if (setting && setting.length > 0) {
      this.loadAppIcon(setting);
    }

    // Carrega otitulo do Portal
    setting = config.getConfig("PORTAL_TITLE_NAME");
    if (setting && setting.length > 0) {
      setPortalTitleName(setting);
    }

    this.translateService.addLangs(["pt", "en"]);
    this.translateService.setDefaultLang(LANGUAGE);

    let settingTranslates = config.getConfig("TRANSLATES_OVERRIDE");
    if (settingTranslates !== undefined && settingTranslates) {
      this.http.get("/assets/configurations/translations/"+settingTranslates).subscribe(
        data => 
        {
          if(data)
          {
            this.translateService.setTranslation(LANGUAGE,data,true);
          }
        }
      )
    }

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        let lang = params["locale"] || params["Local"];

        if (lang) {

          setLanguage(lang.split("_")[0]);
          this.translateService.use(lang.split("_")[0]);
        } else {
          this.authenticationService
            .isAuthenticated()
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
              if (response.language) {

                setLanguage(response.language);
                this.translateService.use(response.language);
              }
            });
        }
      });

    // se tiver definido um titulo fixo para o separador não é preciso fazer o subscribe para o evneto da rota alterar o titulo

    router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => {
        if (PORTAL_TITLE_NAME) {
          titleService.setTitle(translateValueService.get(PORTAL_TITLE_NAME));
        } else {
          let title: any = this.getDeepestTitle(
            this.router.routerState.snapshot.root
          );
          if (title) {
            translateService
              .get([title, PORTAL_NAME])
              .pipe(takeUntil(this.destroy$))
              .subscribe((res: string) => {
                titleService.setTitle(res[title] + " - " + res[PORTAL_NAME]);
              });
          } else {
            translateService
              .get(PORTAL_NAME)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res: string) => {
                titleService.setTitle(res);
              });
          }
        }
      });

    routingState.loadRouting();

    // Adiciona um listener global para ajustar as tabelas quando a janela muda de tamanho
    Functions.autoResizeDatatables();
  }

  private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: any =
      routeSnapshot.data[PAGE_TITLE] !== undefined
        ? routeSnapshot.data[PAGE_TITLE]
        : "";
    if (routeSnapshot.firstChild) {
      title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private loadAppIcon(icon: string) {
    let pathURL = document.getElementsByTagName("base")[0].href;
    let linkEl = document.getElementsByTagName("link")[0];
    linkEl.href = pathURL + "/assets/configurations/icons/" + icon;
    linkEl.type = "image/png";
  }

  ngOnDestroy() {}
}
