import { Component, OnInit, NgZone, ViewChild, OnDestroy, ViewEncapsulation, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ReplaySubject, forkJoin, Subject } from 'rxjs';
import { takeUntil, first, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModelValidators } from '../../../../models/validators/validators';
import { AuthenticationService } from '../../../../services/authentication.service';
import { TranslateValueService } from '../../../../services/translate-value.service';
import { CommonService } from '../../../../services/common.service';
import { GenericFieldExtension } from '../../../../models/generic-field-extension';
import { Dropdown } from '../../../../models/dropdown';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup, MatLegacyTab as MatTab } from '@angular/material/legacy-tabs';
import { DeleteModalComponent } from '../../../shared/delete-modal/delete-modal.component';
import { ConfirmationModalComponent } from '../../../shared/confirmation-modal/confirmation-modal.component';
import { ErrorTreatmentFunctions, FileTreatmentFunctions } from '../../../../modules/treatments.module';
import { DateTimePickerDirective } from '../../../../directives/datepicker.directive';
import { ReturnStatusHtml } from '../../../../models/returnStatus';
import { GenericFieldConfig, GenericFieldType, GenericFieldSubcontext } from 'src/app/models/generic-field-config';
import { CONTEXT_ALTICE_FINE, FineService, AlticeFineState, AlticeFineDropdown, AlticeFineType, AlticeFineRole } from 'src/app/services/altice/fine.service';
import { GenericDocument } from 'src/app/models/generic-document';
import { validateForm, joinAllErrors } from 'src/app/constants/form-validators';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { Country } from 'src/app/models/country';
import { CURRENCY_SYMBOL } from 'src/app/constants/global';
import { Infraction } from 'src/app/interfaces/infraction';
import { CompanyParameter } from 'src/app/models/company-parameter';
import { EntityChangesModalComponent } from 'src/app/components/shared/entity-changes-modal/entity-changes-modal.component';
import { WorkflowService } from 'src/app/services/workflow.service';
import { MessageModalComponent } from 'src/app/components/shared/message-modal/message-modal.component';
import { Console } from 'console';
import { GenericExportService } from 'src/app/services/generic-export.service';

declare var Functions: any;

@Component({
  templateUrl: './fine.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FineComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  canEditExports: boolean = false;
  showSaveButton: boolean;
  allowCreate: boolean;
  allowEdit: boolean;
  allowDelete: boolean;
  menu: string = '';
  isDuplicate: boolean = false;
  context = CONTEXT_ALTICE_FINE;
  CURRENCY_SYMBOL = CURRENCY_SYMBOL;
  // Estado
  isPendingState: boolean = false;
  isEditable: boolean = false;
  isDeletable: boolean = false;
  // Roles
  isClient: boolean;
  // Entidade
  model: GenericDocument;
  // Listas
  fieldsConfig: GenericFieldConfig[] = [];
  fieldsNames: string[] = [];
  // types: GenericType[] = [];
  additionalFieldsConfig: GenericFieldConfig[] = [];
  infractions: Infraction[] = [];

  dataFilters: string[][] = [];

  // Máscaras
  // registrationMask = [/\[A-Z0-9]/, /\[A-Z0-9]/, '-', /\[A-Z0-9]/, /\[A-Z0-9]/, '-', /\[A-Z0-9]/, /\[A-Z0-9]/];
  // Arquivo digital
  showArqTab_open: boolean = false;
  // Minutas
  showExportTab_open: boolean = false;
  // Mensagens
  showMessagesTab_open: boolean = false;
  // Histórico de Estados
  showStateHistoryTab_open: boolean = false;
  // Formulário
  joinAllErrors = joinAllErrors;
  isSubmited: boolean = false;
  form: UntypedFormGroup;
  formAllErrors: string = '';
  formErrors = {
    'DocumentNumber': '',
    'DataNotificacaoARDPEntregue': '',
    'CodAR': '',
    'DataNotificacaoARDPDevolvida': '',
    'MotivoDevolucaoARouDP': '',
    'DataExpedicao': '',
    'MotivoDevolucaoCartaouDP': '',
    'DataApresentacaoPedido': '',
    'DataApresentacaoRecurso': '',


  };
  validationMessages = {
    
  };
  // Validações extra
  codAutoInvalidError: boolean;
  codAutoAutoPagoError: boolean;
  codAutoAutoPrescritoError: boolean;
  codAutoOtherError: boolean;
  codAutoArquivarError: boolean;
  diskFileEntityIds: number[];
  processoAssociadoNotFoundError: boolean;
  processoAssociadoArquivarError: boolean;
  processoAssociadoOtherError: boolean;
  processoAssociadoInvalidError: boolean;

  // Subscrições
  filteredClientID: ReplaySubject<Company[]> = new ReplaySubject<Company[]>();
  filteredEntidadeAutuante: ReplaySubject<Dropdown[]> = new ReplaySubject<Dropdown[]>();
  filteredPaisVeiculo: ReplaySubject<Country[]> = new ReplaySubject<Country[]>();
  filteredCategoriaVeiculo: ReplaySubject<Dropdown[]> = new ReplaySubject<Dropdown[]>();
  filteredTipoVeiculo: ReplaySubject<Dropdown[]> = new ReplaySubject<Dropdown[]>();
  filteredComarcaInfracao: ReplaySubject<Dropdown[]> = new ReplaySubject<Dropdown[]>();
  filteredDistritoInfracao: ReplaySubject<Dropdown[]> = new ReplaySubject<Dropdown[]>();
  filteredCodigoInfracao: ReplaySubject<Infraction[]> = new ReplaySubject<Infraction[]>();

  // Listas de subscrições
  private clients: Company[] = [];
  private paisVeiculo: Country[] = [];
  listEntidadeAutuante: Dropdown[] = [];
  listComarcaInfracao: Dropdown[] = [];
  listDistritoInfracao: Dropdown[] = [];
  categoriaVeiculo: Dropdown[] = [];
  tipoArg: Dropdown[] = [];
  tipoVeiculo: Dropdown[] = [];
  tipoDomFiscalArg: Dropdown[] = [];
  tipoCond: Dropdown[] = [];

  //Referencia multibanco introduzida pelo cliente
  clientReference: string = "";
  referenceGeneratedByClientExportID: string = "";


  dropDownsListToGenericExport: Map<string, Dropdown[]> = null;

  coimaAgravamento: number = 0;
  valorCustas: number = 0;

  showValorCoimaWarning: boolean = false;
  exportFields: GenericFieldExtension[] = [];

  // Outras permissões
  allowEditValores: boolean = false;
  disableExports: boolean = true;

  stateLogs: any[] = undefined;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translateValueService: TranslateValueService,
    private translateService: TranslateService,
    private fineService: FineService,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private zone: NgZone,
    private dialog: MatDialog,
    private _errorTreat: ErrorTreatmentFunctions,
    private _fileTreat: FileTreatmentFunctions,
    private companyService: CompanyService,
    private _renderer: Renderer2,
    private workflowService: WorkflowService,
    private _exportService: GenericExportService,
    private _cdr: ChangeDetectorRef
  ) {
    // Roles
    this.isClient = this.authenticationService.session.roles.indexOf(AlticeFineRole.SCCOR_CLIENTE) !== -1;

    // Tipos (para já não está a ser usado - só há um)
    // this.fineService.types.pipe(takeUntil(this.destroy$)).subscribe(response => this.types = response);

    // PaisVeiculo
    this.commonService.countries.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.paisVeiculo = response;
      this.filteredPaisVeiculo.next(this.paisVeiculo.slice());
    });

    // CategoriaVeiculo
    this.fineService.getDropdownsList(AlticeFineDropdown.CategoriaVeiculo).pipe(takeUntil(this.destroy$))
      .subscribe(response => this.filteredCategoriaVeiculo.next(response.slice()));

    // TipoVeiculo
    this.fineService.getDropdownsList(AlticeFineDropdown.TipoVeiculo).pipe(takeUntil(this.destroy$))
      .subscribe(response => this.filteredTipoVeiculo.next(response.slice()));

    // TipoArg
    this.fineService.getDropdownsList(AlticeFineDropdown.TipoArg, false).pipe(takeUntil(this.destroy$))
      .subscribe(response => this.tipoArg = response);

    // TipoDomFiscalArg
    this.fineService.getDropdownsList(AlticeFineDropdown.TipoDomFiscalArg, false).pipe(takeUntil(this.destroy$))
      .subscribe(response => this.tipoDomFiscalArg = response);

    // TipoCond
    this.fineService.getDropdownsList(AlticeFineDropdown.TipoCond, false).pipe(takeUntil(this.destroy$))
      .subscribe(response => this.tipoCond = response);

      this.validationMessages = {
        'DocumentNumber': {
          'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('ALTICE.PROCESS_N') }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('ALTICE.PROCESS_N'), value: '40' })
        }
      }
  }

  ngOnInit(): void {
    let that = this;

    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      that.showSaveButton = (params.showSaveButton == 1);
    });

    forkJoin([
      // Parceiros
      this.companyService.getPartners(AlticeFineRole.SCCOR_CLIENTE).pipe(map(response => {
        this.clients = response;
        this.filteredClientID.next(response.slice());
      })),
      // EntidadeAutuante
      this.fineService.getDropdownsList(AlticeFineDropdown.EntidadeAutuante).pipe(map(response => {
        this.listEntidadeAutuante = response;
      })),
      // ComarcaInfracao
      this.fineService.getDropdownsList(AlticeFineDropdown.ComarcaInfracao).pipe(map(response => {
        this.listComarcaInfracao = response;
      })),
      // DistritoInfracao
      this.fineService.getDropdownsList(AlticeFineDropdown.DistritoInfracao).pipe(map(response => {
        this.listDistritoInfracao = response;
      })),
      // tipoArg
      this.fineService.getDropdownsList(AlticeFineDropdown.TipoArg).pipe(map(response => {
        this.tipoArg = response;
      })),
      // TipoVeiculo
      this.fineService.getDropdownsList(AlticeFineDropdown.TipoVeiculo).pipe(map(response => {
        this.tipoVeiculo = response;
      })),
      //CategoriaVeiculo
      this.fineService.getDropdownsList(AlticeFineDropdown.CategoriaVeiculo).pipe(map(response => {
        this.categoriaVeiculo = response;
      })),
      //Infrações (lista antes de saber o cliente do form para carregar o control InfrationValueInterval e warnings)
      this.fineService.getInfractions(0).pipe(map(response => {
        this.infractions = response;
      }))
    ]).pipe(takeUntil(this.destroy$)).subscribe(() => {

      // Converter paisVeiculo para dropdown para depois adicionar ao array de dropdowns
      let paisVeiculoAux: Dropdown[] = new Array<Dropdown>();
      that.paisVeiculo.forEach(element => {
        paisVeiculoAux.push(new Dropdown(
          0,
          "paisVeiculo",
          element.Code,
          element.Name,
          null,
          null,
          null,
          false,
          null
        ))
      });

      // Array de dropdowns para enviar para o export
      that.dropDownsListToGenericExport = new Map<string, Dropdown[]>();

      that.dropDownsListToGenericExport.set("manual_TipoVeiculo", that.tipoVeiculo)
      that.dropDownsListToGenericExport.set("manual_Comarca", that.listComarcaInfracao)
      that.dropDownsListToGenericExport.set("manual_Distrito", that.listDistritoInfracao)
      that.dropDownsListToGenericExport.set("manual_Pais", paisVeiculoAux)
      that.dropDownsListToGenericExport.set("manual_Categoria", that.categoriaVeiculo)
      that.dropDownsListToGenericExport.set("", that.listEntidadeAutuante)
      that.dropDownsListToGenericExport.set("", that.tipoArg)
      that.dropDownsListToGenericExport.set("", that.tipoDomFiscalArg)
      that.dropDownsListToGenericExport.set("", that.tipoCond)

      // Obter listas primeiro
      let menu: string = this.activatedRoute.snapshot.data['menu'];

      // Devolve o caminho no menu
      this.authenticationService.getMenuPath(menu.toLowerCase()).pipe(takeUntil(this.destroy$)).subscribe(response => this.menu = response);

      let permission = this.authenticationService.session.permissions.filter((r: any) => r.Name.toLowerCase() === menu.toLowerCase())[0];
      if (permission) {
        this.allowCreate = permission.AllowCreate;
        this.allowEdit = permission.AllowEdit;
        this.allowDelete = permission.AllowDelete;
      }

      // Indicação se é para duplicar
      this.isDuplicate = !!this.activatedRoute.snapshot.queryParams['duplicate'];

      // Campos
      this.commonService.getGenericFieldsConfiguration(this.context).pipe(takeUntil(this.destroy$)).subscribe(response => {
        // Cria os objectos do tipo GenericFieldConfig, com os métodos associados
        (response ? response.configurations as [] : []).forEach((e, i) => this.fieldsConfig[i] = new GenericFieldConfig(e));
        // Campos adicionais
        this.additionalFieldsConfig = this.fieldsConfig.filter(r => r.SubContext === GenericFieldSubcontext.ADDITIONAL_FIELD);

        // Obter parametros da empresa
        if (this.allowEdit) {
          this.commonService.getCompanyParameters(this.authenticationService.session.company.ID).subscribe((parameters: CompanyParameter[]) => {
            let coimaAgravamentoParam = parameters.find(p => p.Key === 'AlticeFinePercAgravamento');
            if (coimaAgravamentoParam) {
              this.coimaAgravamento = +coimaAgravamentoParam.Value.toString().revertDecimal();
            }
            let valorCustasParam = parameters.find(p => p.Key === 'AlticeFineValorCustas');
            if (valorCustasParam) {
              this.valorCustas = +valorCustasParam.Value.toString().revertDecimal();
            }

            // Fazer só depois de ter os dados devido ao assincronismo
            this.onReload();
          });
        } else {
          this.onReload();
        }

      }); // obter a config dos campos

    }); // Obter listas primeiro
  }

  public onReload(): void {

    let id = +this.activatedRoute.snapshot.params['id'];
    let block = this.activatedRoute.snapshot.queryParams['block'];

    if (id !== 0) {
      // Devolve o pedido de transporte
      this.fineService.get(id, block).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          this.model = response.ReturnStatus.ReturnObject;

          // Obter a lista de campos da exportação PDF
          this.exportFields = this.model.Fields.filter(r => r.Identifier !== null && r.Identifier.startsWith('GenericExport_'));

          let title = '';
          if (this.isDuplicate) {
            // Limpar os IDs do pedido de transporte
            this.model.ID = 0;
            this.model.DocumentStateID = this.isClient ? AlticeFineState.CLIENT_RECEPTION_OF_DOCUMENTS : AlticeFineState.RECEPTION_OF_DOCUMENTS;
            this.model.DocumentNumber = null;
            this.model.StateIsPending = false;
            this.model.StateIsEditable = true;
            this.model.StateIsDeletable = false;

            title = 'ALTICE.NEW_ADMINISTRATIVE_OFFENSE';

            this.buildForm(!this.allowCreate);
          } else {
            // Estado atual do workflow
            this.isPendingState = this.model.StateIsPending;
            // Só é editável se o estado for editável e o blockedBy = null ou blockedBy  = user da sessão
            this.isEditable = this.model.StateIsEditable && (this.model.BlockedBy === 0 || this.model.BlockedBy === null || this.model.BlockedBy === this.authenticationService.session.user.ID);
            this.isDeletable = this.model.StateIsDeletable;

            title = 'ALTICE.ADMINISTRATIVE_OFFENSE';

            this.buildForm(!(this.allowEdit && this.isEditable));

            //Ir Buscar o campo que indica a minuta para qual o cliente vai criar uma referencia
            this.commonService.getGenericFieldExtension("AlticeFine", id, "ReferenceGeneratedByClient").pipe(takeUntil(this.destroy$))
              .subscribe(response => {
                let generatedByClient = response.ReturnStatus.ReturnObject as GenericFieldExtension;
                if (generatedByClient != null) {
                  this.referenceGeneratedByClientExportID = generatedByClient.FieldValue;
                }
              });

            //Pode ver o separador, editar campos,  e gravar:
            //404 - Análise Administrativa
            //406 - Análise Jurídica
            //409 - Diligência de Prova
            //410 - Audição de Testemunhas
            //429 - ECC Análise Adm.
            //430 - ECC Análise Jur.
            //425 - IJ Análise Adm.
            //426 - IJ Análise Jur.


            //Processos têm de estar neste estado,
            // tem de ter role ID 407(assin)408(cert)           
            //411 - Decisão ou DP em Validação Cliente
            //431 - ECC Validação Cliente
            //427 - IJ Validação Cliente


            // Permissões para várias opearações //
            const valuesValidStates: number[] = [404, 405, 406, 411];
            this.allowEditValores = this.allowEdit && valuesValidStates.filter(x => x === this.model.DocumentStateID).length > 0;

            // Os ids dentro deste array podem editar os campos
            const exportsValidStates: number[] = [404, 405, 406, 409, 410, 429, 430, 425, 426];
            if (this.form.get('ID').value > 0 && exportsValidStates.filter(x => x === this.model.DocumentStateID).length > 0) {
              this.disableExports = false; // descomentando vai fazer com que só estes estados possam ver as minutas
              this.canEditExports = true;
            }


          }

          // Título
          this.translateService.get(title).subscribe(translation => {
            this.titleService.setTitle(translation);
          });
        } else {
          this._errorTreat.treatErrorResponse(response);
        }

      });
    } else {
      this.model = new GenericDocument(0, this.context, this.authenticationService.session.company.ID, AlticeFineType.ADMINISTRATIVE_OFFENSE,
        this.isClient ? AlticeFineState.CLIENT_RECEPTION_OF_DOCUMENTS : AlticeFineState.RECEPTION_OF_DOCUMENTS);

      this.buildForm(!this.allowCreate);

      // Título
      this.translateService.get('ALTICE.NEW_ADMINISTRATIVE_OFFENSE').subscribe(translation => {
        this.titleService.setTitle(translation);
      });
    }
  }

  buildForm(disabled: boolean): void {
    this.form = this.formBuilder.group({
      // Entidade
      ID: [this.model.ID],
      Context: [this.model.Context],
      OwnerID: [this.model.OwnerID],
      DocumentTypeID: [this.model.DocumentTypeID],
      DocumentStateID: [this.model.DocumentStateID],
      DocumentStateName: [{ value: this.model.DocumentStateName, disabled: true }],
      DocumentNumber: [{ value: this.model.DocumentNumber, disabled: true }],
      CreationDate: [{ value: DateTimePickerDirective.convertToString(this.model.CreationDate, true), disabled: true }],
      CreationBy: [this.model.CreatedBy],
      LastUpdated: [{ value: DateTimePickerDirective.convertToString(this.model.LastUpdated, true), disabled: true }],
      LastUpdatedBy: [this.model.LastUpdatedBy],
      BlockedBy: [this.model.BlockedBy]
    });


    if (this.fieldsConfig) {
      for (let i = 0; i < this.fieldsConfig.length; i++) {
        let validators = [];
        if (this.fieldsConfig[i].isFieldRequired(this.model.DocumentTypeID, this.model.DocumentStateID, this.authenticationService.session.roles)) {
          validators.push(Validators.required);
        }
        if (this.fieldsConfig[i].FieldLength) {
          validators.push(Validators.maxLength(this.fieldsConfig[i].FieldLength));
        }
        if (this.fieldsConfig[i].FieldType === GenericFieldType.NUMBER) {
          validators.push(ModelValidators.numberVal({ decimalPlaces: 2, min: 0 }));
        }

        // Validações extra do CodAuto
        if (this.fieldsConfig[i].FieldCode === 'CodAuto') {

          validators.push(Validators.minLength(this.fieldsConfig[i].FieldLength));
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.codAutoInvalidError ? { 'codAutoInvalidError': true } : null;
          });
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.codAutoAutoPagoError ? { 'codAutoAutoPagoError': true } : null;
          });
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.codAutoAutoPrescritoError ? { 'codAutoAutoPrescritoError': true } : null;
          });
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.codAutoOtherError ? { 'codAutoOtherError': true } : null;
          });
        }

        // Validações extra do ProcessoAssociado
        if (this.fieldsConfig[i].FieldCode === 'ProcessoAssociado') {
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.processoAssociadoInvalidError ? { 'processoAssociadoInvalidError': true } : null;
          });
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.processoAssociadoNotFoundError ? { 'processoAssociadoNotFoundError': true } : null;
          });
          validators.push((control: UntypedFormControl): { [key: string]: any } => {
            return this.processoAssociadoArquivarError ? { 'processoAssociadoArquivarError': true } : null;
          });
        }

        // Validações extra datas
        // DataNascArg
        if (this.fieldsConfig[i].FieldCode === 'DataNascArg') {
          validators.push(ModelValidators.lessThenCurrentDate);
        }
        // Data1Notificacao
        if (this.fieldsConfig[i].FieldCode === 'Data1Notificacao') {
          validators.push(ModelValidators.lessThenCurrentDate);
        }
        // DataInfracao
        if (this.fieldsConfig[i].FieldCode === 'DataInfracao') {
          validators.push(ModelValidators.lessThenCurrentDate);
        }

        // Os seguintes campos vão ser ser alterados automáticamente
        // Para não dar erro nas validações do servidor devido às configurações tem de se colocar os campos editáveis e disable aqui hardcoded
        // - AutoDuplicado
        // - ValorTotal
        if (!this.form.get(this.fieldsConfig[i].FieldCode) && !this.fieldsConfig[i].GroupCode) {

          let fieldValue = this.fieldsConfig[i].getFieldValue(this.model.Fields.find(r => r.FieldCode === this.fieldsConfig[i].FieldCode && r.Identifier === null), this.model.ID === 0);
          // // Valores por defeito das datas com maxDate porque o componente está a apresentar a data atual mesmo tendo o valor a null
          // if (!fieldValue &&
          //   (this.fieldsConfig[i].FieldCode === 'Data1Notificacao'
          //     || this.fieldsConfig[i].FieldCode === 'DataInfracao')) {
          //   fieldValue = DateTimePickerDirective.convertToString(this.currentDate, false);
          // }

          // String / Number / Boolean
          this.form.addControl(this.fieldsConfig[i].FieldCode,
            new UntypedFormControl({
              value: fieldValue,
              disabled:
                (this.model.DocumentStateID == 405 && this.fieldsConfig[i].FieldCode === 'CodAuto')
                || (this.model.DocumentStateID == 405 && this.fieldsConfig[i].FieldCode === 'ClientID')
                || this.fieldsConfig[i].FieldCode === 'AutoDuplicado'
                || this.fieldsConfig[i].FieldCode === 'ValorTotal'
                || this.fieldsConfig[i].FieldCode === 'ValorRecebido'
                || this.fieldsConfig[i].FieldCode === 'ValorCoimaAgravam'
                || this.fieldsConfig[i].FieldCode === 'ValorCustas'
                || !this.fieldsConfig[i].isFieldEditable(this.model.DocumentTypeID, this.model.DocumentStateID, this.authenticationService.session.roles)
            }, validators));


          if (this.fieldsConfig[i].FieldCode === 'ClientID') {
            const clientID: number = this.fieldsConfig[i].getFieldValue(this.model.Fields.find(r => r.FieldCode === this.fieldsConfig[i].FieldCode && r.Identifier === null), this.model.ID === 0);
            // Obtém a lista de infrações do cliente
            this.getInfractions(clientID);
            // Carregar listas de acordo com o cliente selecionado
            this.setClientDependentLists(clientID);
          }
        }

        if (this.fieldsConfig[i].FieldName) {
          this.translateService.get(this.fieldsConfig[i].FieldName).subscribe(translatedField => {
            if (this.validationMessages[this.fieldsConfig[i].FieldCode]) {
              this.validationMessages[this.fieldsConfig[i].FieldCode].required = this.translateValueService.get('FIELD_ISEMPTY', { field: translatedField });
              if (this.fieldsConfig[i].FieldLength) {
                this.validationMessages[this.fieldsConfig[i].FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: translatedField, value: this.fieldsConfig[i].FieldLength.toString() });
              }
            } else {
              this.validationMessages[this.fieldsConfig[i].FieldCode] = {
                //Talvez validar aqui os tipos
                'required': this.translateValueService.get('FIELD_ISEMPTY', { field: translatedField })
              };

              if (this.fieldsConfig[i].FieldLength) {
                this.validationMessages[this.fieldsConfig[i].FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: translatedField, value: this.fieldsConfig[i].FieldLength.toString() });
              }
              if (this.fieldsConfig[i].FieldType === GenericFieldType.NUMBER) {
                this.validationMessages[this.fieldsConfig[i].FieldCode].numberVal = this.translateValueService.get('FIELD_NOT_NUMBER', { field: translatedField });
                this.validationMessages[this.fieldsConfig[i].FieldCode].numberOfDecimalPlaces = this.translateValueService.get('FIELD_NOT_MORE_DECIMALS', { field: translatedField, value: '2' });
                this.validationMessages[this.fieldsConfig[i].FieldCode].numberMin = this.translateValueService.get('FIELD_NOT_LESS', { field: translatedField, value: '0' });
              }

              // CodAuto
              if (this.fieldsConfig[i].FieldCode === 'CodAuto') {
                this.validationMessages[this.fieldsConfig[i].FieldCode].minlength = this.translateValueService.get('FIELD_MINLENGTH', { field: translatedField, value: this.fieldsConfig[i].FieldLength.toString() });
                this.validationMessages[this.fieldsConfig[i].FieldCode].codAutoInvalidError = this.translateValueService.get('ALTICE.COD_AUTO_INVALID_ERROR');
                this.validationMessages[this.fieldsConfig[i].FieldCode].codAutoAutoPagoError = this.translateValueService.get('ALTICE.COD_AUTO_AUTO_PAGO_ERROR');
                this.validationMessages[this.fieldsConfig[i].FieldCode].codAutoAutoPrescritoError = this.translateValueService.get('ALTICE.COD_AUTO_AUTO_PRESCRITO_ERROR');
                this.validationMessages[this.fieldsConfig[i].FieldCode].codAutoOtherError = this.translateValueService.get('ALTICE.COD_AUTO_OTHER_ERROR');
              }

              // ProcessoAssociado
              if (this.fieldsConfig[i].FieldCode === 'ProcessoAssociado') {
                this.validationMessages[this.fieldsConfig[i].FieldCode].minlength = this.translateValueService.get('FIELD_MINLENGTH', { field: translatedField, value: this.fieldsConfig[i].FieldLength.toString() });
                this.validationMessages[this.fieldsConfig[i].FieldCode].processoAssociadoInvalidError = this.translateValueService.get('ALTICE.PROCESSO_ASSOCIADO_INVALID_ERROR');
                this.validationMessages[this.fieldsConfig[i].FieldCode].processoAssociadoNotFoundError = this.translateValueService.get('ALTICE.PROCESSO_ASSOCIADO_NOT_FOUND_ERROR');
                this.validationMessages[this.fieldsConfig[i].FieldCode].processoAssociadoArquivarError = this.translateValueService.get('ALTICE.PROCESSO_ASSOCIADO_ARQUIVAR_ERROR');
              }

              // DataNascArg
              if (this.fieldsConfig[i].FieldCode === 'DataNascArg') {
                this.validationMessages[this.fieldsConfig[i].FieldCode].lessThenCurrentDate = 'A data de nascimento tem de ser inferior à data atual';
              }

              // Data1Notificacao
              if (this.fieldsConfig[i].FieldCode === 'Data1Notificacao') {
                this.validationMessages[this.fieldsConfig[i].FieldCode].lessThenCurrentDate = 'A data da 1ª notificação tem de ser inferior à data atual';
              }

              // DataInfracao
              if (this.fieldsConfig[i].FieldCode === 'DataInfracao') {
                this.validationMessages[this.fieldsConfig[i].FieldCode].lessThenCurrentDate = 'A data da infração tem de ser inferior à data atual';
              }

            }

            this.formErrors[this.fieldsConfig[i].FieldCode] = '';
          });
        }

        // Adiciona o nome do campo para ser mostrado no template
        this.fieldsNames[this.fieldsConfig[i].FieldCode] = this.fieldsConfig[i].getFieldName();
      }

      // Inicia os centros a atualizar
      // this.initGroup('CentrosAtualizar');
    }

    // Ativar eventos para preencher descrição de valores
    this.setFilterEvents();

    // Carrega as validações
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() =>
      this.formErrors = validateForm(this.form, this.formErrors, this.validationMessages, this.isSubmited));
    this.formErrors = validateForm(this.form, this.formErrors, this.validationMessages, this.isSubmited);

    // Validações dinâmicas de campos
    this.onChangeTipoArg();
    this.onChangeTipoCond();

    // Apresentar warning do valor da coima se necessário
    if (this.model.ID > 0) {
      let valorCoima: number = 0;
      const valorCoimaControl = this.form.get('ValorCoima');
      if (valorCoimaControl && valorCoimaControl.value !== '') {
        valorCoima = +valorCoimaControl.value.toString().revertDecimal();
      }
      let showWarn = false;
      const codigoInfracao = this.form.get('CodigoInfracao');
      if (codigoInfracao && codigoInfracao.value) {
        const infraction = this.infractions.find(r => r.Code === codigoInfracao.value);

        //Adicionar control para guardar o intervalo de valores da coima
        if (this.form && infraction) {
          this.form.addControl('InfrationValueInterval',
            new UntypedFormControl({ value: infraction.MinValue + '€ a ' + infraction.MaxValue + '€', disabled: false }, null));

          //Adicionar control para ValorCoimaAgrav = Valor Coima + Valor Agravamento 
          const valorAgravamentoControl = this.form.get('ValorCoimaAgravam');
          this.form.addControl('ValorCoimaAgrav',
            new UntypedFormControl({ value: (valorCoima + valorAgravamentoControl.value), disabled: false }, null));
        }
        if (infraction && (valorCoima < infraction.MinValue || valorCoima > infraction.MaxValue)) {
          showWarn = true;
        }
      }
      this.showValorCoimaWarning = showWarn;
    }



    if (disabled) {
      this.form.disable();
    }
  }

  /**
   * Atualizar todos os controlos do formulário com o mesmo FormControlName
   * @param  {string} controlName
   */
  updateControl(controlName: string) {
    const control = this.form.get(controlName);
    if (control) {
      control.setValue(control.value);
    }
  }

  focusControl(key: string): void {
    let config = this.fieldsConfig.find(r => r.FieldCode === key);

    if (config) {
      let tab: MatTab = this.tabGroup._tabs.find(r => r.content.viewContainerRef.element.nativeElement.id === config.TabCode);
      this.tabGroup.selectedIndex = this.tabGroup.selectedIndex + tab.position;
    } else {
      this.tabGroup.selectedIndex = 0;
    }

    this.zone.onStable.pipe(first()).subscribe(() => {
      Functions.scrollTo('[formControlName=' + key + ']', 70);
    });
  }

  save(): void {
    //Se tiver no Etado controlo de qualidade. // depois ter de sabotar o backend tambem
    if (this.model.DocumentStateID == 405) {
      this.addOrUpdate();
      return;
    }

    this.isSubmited = true;
    this.form.updateValueAndValidity();

    //Retirar a Obrigatoriadade dos campos que vêm por Scan
    delete this.formErrors.DataNotificacaoARDPEntregue;
    delete this.formErrors.DataNotificacaoARDPDevolvida;
    delete this.formErrors.DataExpedicao;
    delete this.formErrors.DataApresentacaoPedido;
    delete this.formErrors.DataApresentacaoRecurso;

    // Valida se o formulário contém erros
    this.formAllErrors = joinAllErrors(this.formErrors)
    // Validar datas
    const controlData1Notificacao = this.form.get('Data1Notificacao');
    const controlDataInfracao = this.form.get('DataInfracao');

    if (controlData1Notificacao && controlData1Notificacao.value && controlDataInfracao && controlDataInfracao.value) {

      let data1Notificacao = DateTimePickerDirective.convertToDate(controlData1Notificacao.value, false);
      let dataInfracao = DateTimePickerDirective.convertToDate(controlDataInfracao.value, false);

      if (dataInfracao > data1Notificacao) {
        this.formAllErrors += '<li>A data de infração tem de ser anterior à data da 1ª notificação</li>';
      }
    }

    if (this.formAllErrors.length > 0) {
      return;
    }

    // Valida o valor da coima
    let valorCoimaError = false;
    const codigoInfracao = this.form.get('CodigoInfracao');
    const valorCoima = this.form.get('ValorCoima');
    if (codigoInfracao && codigoInfracao.value && valorCoima && valorCoima.value) {
      const infraction = this.infractions.find(r => r.Code === codigoInfracao.value);
      if (infraction && (valorCoima.value < infraction.MinValue || valorCoima.value > infraction.MaxValue)) {
        valorCoimaError = true;
      }
    }

    // Valida se o formulário contém erros
    if (this.codAutoArquivarError || this.processoAssociadoOtherError || valorCoimaError) {

      this.translateService.get([
        'ALTICE.COD_AUTO_ARQUIVAR_ERROR',
        'ALTICE.PROCESSO_ASSOCIADO_OTHER_ERROR',
        'ALTICE.VALOR_COIMA_ERROR',
        'ALTICE.CONFIRMATION_MESSAGE'
      ]).subscribe(translations => {
        const separator = '<br/><br/>';

        // Constrói a mensagem
        let message = '';

        if (this.codAutoArquivarError) {
          message += translations['ALTICE.COD_AUTO_ARQUIVAR_ERROR'] + separator;
        }

        if (this.processoAssociadoOtherError) {
          message += translations['ALTICE.PROCESSO_ASSOCIADO_OTHER_ERROR'] + separator;
        }

        if (valorCoimaError) {
          message += translations['ALTICE.VALOR_COIMA_ERROR'] + separator;
        }

        // Adiciona a mensagem de pedido de confirmação no fim da mensagem
        message += '<br/>' + translations['ALTICE.CONFIRMATION_MESSAGE'];

        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          data: {
            text: message,
            textClassAlign: 'text-left'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            // GM 2020-04-17 os processos anteriores com o mesmo código auto passam para Auto-Duplicados (feito no servidor)
            // Se sim, define o auto como duplicado
            // if (this.codAutoArquivarError) {
            //   const control = this.form.get('AutoDuplicado');
            //   if (control) {
            //     control.setValue(true);
            //   }
            // }

            this.addOrUpdate();
          }
        });

      });
    } else if (this.formAllErrors.length === 0) { // Se não tiver erros, grava
      this.addOrUpdate();
    }
  }

  addOrUpdate() {
    let oldFields = this.model.Fields;
    this.model = JSON.parse(JSON.stringify(this.form.getRawValue()));
    this.model.Fields = oldFields;

    // Mapea o formulário para a lista de campos genéricos
    GenericFieldConfig.mapFormFieldsToBDFields(this.model.ID, this.form, this.model.Fields, this.fieldsConfig);

    if (this.model.ID === 0) {
      this.fineService.add(this.model).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.treatResponse(response, true);
      });
    } else {
      this.fineService.update(this.model, this.model.DocumentStateID == 405).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.treatResponse(response, true);
      });
    }
  }

  submit(): void {
    this.translateService.get('SUBMIT_CONFIRMATION').subscribe(translation => {
      let dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: {
          text: translation
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fineService.submit(this.model.ID).pipe(takeUntil(this.destroy$)).subscribe(response => this.treatResponse(response, true));
        }
      });
    });
  }

  delete() {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fineService.delete(this.model.ID).pipe(takeUntil(this.destroy$)).subscribe(response => {
          this.treatResponse(response, true);
        });
      }
    });
  }

  /**
   * Exportar processo para Excel
   */
  exportToExcel() {
    this.fineService.exportToExcel(this.model.ID).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this._fileTreat.afterDownloadFile(response, 'Processo ' + this.model.ID + '.xlsx');
    });
  }

  /**
   * Exportar processo para PDF
   */
  exportToPDF() {
    this.fineService.exportToPDF(this.model.ID).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this._fileTreat.afterDownloadFile(response, 'Processo ' + this.model.ID + '.pdf');
    });
  }

  treatResponse(response: ReturnStatusHtml, redirect: boolean) {
    if (response.ReturnStatus.Successfull) {
      if (redirect) {
        this.router.navigate(['/generic-documents/altice/fine/list']);
      } else {
        this.form.markAsPristine();
      }

      if (!response.ReturnStatus.WarningMessage) {
        Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
      } else {
        Functions.gritter(response.ReturnStatus.WarningMessage, 'warning');
      }
    } else {
      this._errorTreat.treatErrorResponse(response);
    }
  }

  //#region Changes do ChooseModal
  cleanFields(fields: string[], group?: string, groupIndex?: number) {
    if (group && groupIndex) {
      let control = (<UntypedFormArray>this.form.get(group)).at(groupIndex);

      fields.forEach(field => {
        control.get(field).setValue(null);
        control.get(field).updateValueAndValidity();
      });
    } else {
      fields.forEach(field => {
        this.form.get(field).setValue(null);
        this.form.get(field).updateValueAndValidity();
      });
    }

    // Não continua os próximos eventos
    event.stopPropagation();
  }

  setFilterEvents() {
    // ClientID
    if (this.form.get('ClientID')) {
      this.form.addControl('ClientIDFilter', new UntypedFormControl(null));
      this.form.get('ClientIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterCompanies(val, this.clients, this.filteredClientID,
          this.dataFilters['ClientID'] ? this.dataFilters['ClientID'].split(';') : null));
    }

    // EntidadeAutuante
    if (this.form.get('EntidadeAutuante')) {
      this.form.addControl('EntidadeAutuanteFilter', new UntypedFormControl(null));
      this.form.get('EntidadeAutuanteFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterDropdowns(val, this.fineService.dropdownLists[AlticeFineDropdown.EntidadeAutuante], this.filteredEntidadeAutuante,
          this.dataFilters['EntidadeAutuante'] ? this.dataFilters['EntidadeAutuante'].split(';') : null));
    }

    // PaisVeiculo
    if (this.form.get('PaisVeiculo')) {
      this.form.addControl('PaisVeiculoFilter', new UntypedFormControl(null));
      this.form.get('PaisVeiculoFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterCountries(val, this.paisVeiculo, this.filteredPaisVeiculo,
          this.dataFilters['PaisVeiculo'] ? this.dataFilters['PaisVeiculo'].split(';') : null));
    }

    // CategoriaVeiculo
    if (this.form.get('CategoriaVeiculo')) {
      this.form.addControl('CategoriaVeiculoFilter', new UntypedFormControl(null));
      this.form.get('CategoriaVeiculoFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterDropdowns(val, this.fineService.dropdownLists[AlticeFineDropdown.CategoriaVeiculo], this.filteredCategoriaVeiculo,
          this.dataFilters['CategoriaVeiculo'] ? this.dataFilters['CategoriaVeiculo'].split(';') : null));
    }

    // TipoVeiculo
    if (this.form.get('TipoVeiculo')) {
      this.form.addControl('TipoVeiculoFilter', new UntypedFormControl(null));
      this.form.get('TipoVeiculoFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterDropdowns(val, this.fineService.dropdownLists[AlticeFineDropdown.TipoVeiculo], this.filteredTipoVeiculo,
          this.dataFilters['TipoVeiculo'] ? this.dataFilters['TipoVeiculo'].split(';') : null));
    }

    // ComarcaInfracao
    if (this.form.get('ComarcaInfracao')) {
      this.form.addControl('ComarcaInfracaoFilter', new UntypedFormControl(null));
      this.form.get('ComarcaInfracaoFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterDropdowns(val, this.fineService.dropdownLists[AlticeFineDropdown.ComarcaInfracao], this.filteredComarcaInfracao,
          this.dataFilters['ComarcaInfracao'] ? this.dataFilters['ComarcaInfracao'].split(';') : null));
    }

    // Distrito
    if (this.form.get('DistritoInfracao')) {
      this.form.addControl('DistritoInfracaoFilter', new UntypedFormControl(null));
      this.form.get('DistritoInfracaoFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterDropdowns(val, this.fineService.dropdownLists[AlticeFineDropdown.DistritoInfracao], this.filteredDistritoInfracao,
          this.dataFilters['DistritoInfracao'] ? this.dataFilters['DistritoInfracao'].split(';') : null));
    }

    // CodigoInfracao
    if (this.form.get('CodigoInfracao')) {
      this.form.addControl('CodigoInfracaoFilter', new UntypedFormControl(null));
      this.form.get('CodigoInfracaoFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterInfractions(val, this.infractions, this.filteredCodigoInfracao,
          this.dataFilters['CodigoInfracao'] ? this.dataFilters['CodigoInfracao'].split(';') : null));
    }

  }

  // Ativar eventos para preencher descrição de valores no centro a atualizar
  setFilterEventsCentroAtualizar(control: UntypedFormGroup) {
    // // CentroAtualizar
    // if (control.get('CentroAtualizar')) {
    //   control.addControl('CentroAtualizarFilter', new FormControl(null));
    //   control.get('CentroAtualizarFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
    //     this.filterDropdowns(val, this.fineService.dropdownLists[AlticeFineDropdown.DeltaCenters], this.filteredDeltaCenters,
    //       this.dataFilters['CentroAtualizar'] ? this.dataFilters['CentroAtualizar'].split(';') : null));
    // }
  }
  //#endregion - Changes do ChooseModal

  //#region Groups
  // Adicionar ao formulário os grupos de campos
  initGroup(groupCode: string) {
    const control = this.formBuilder.array([]);
    // Inicializa o tratamento de erros para o grupo de campos
    this.formErrors[groupCode] = [];
    this.validationMessages[groupCode] = {};

    // Configurações dos campos do grupo
    let fieldsConfig = this.fieldsConfig.filter(r => r.GroupCode === groupCode);

    // Se não tiver configurações de campos desta lista, ignora
    if (fieldsConfig.length === 0) {
      return;
    }

    // Se já existirem valores preenchidos
    if (this.model.Fields && this.model.Fields.length > 0) {
      // Por cada valor de agrupado por
      Array.from(new Set(this.model.Fields.filter(r => r.ListCode === groupCode && r.Identifier).map((item: GenericFieldExtension) => item.Identifier)))
        .forEach(identifier => {
          let group = this.initGroupFields(fieldsConfig, identifier);

          // Adiciona o grupo ao formulário
          control.push(group.control);
          // Adiciona a estrutura de erros do grupo
          this.formErrors[groupCode].push(group.formErrors);
        });
    }

    this.form.addControl(groupCode, control);

    // Adiciona as mensagens de erro dos campos do grupo
    // Só mostra os erros de campos que podem ser usados nos scripts (para excluir os campos auxíliares)
    fieldsConfig.filter(r => r.InScripting)
      .forEach(fieldConfig => {
        // Adiciona a mensagem de erro traduzida
        this.translateService.get(fieldConfig.FieldName).subscribe(translatedField => {
          this.validationMessages[groupCode][fieldConfig.FieldCode] = {
            'required': this.translateValueService.get('FIELD_ISEMPTY', { field: translatedField })
          };

          if (fieldConfig.FieldLength) {
            this.validationMessages[groupCode][fieldConfig.FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: translatedField, value: fieldConfig.FieldLength.toString() });
          }
          if (fieldConfig.FieldType === GenericFieldType.NUMBER) {
            this.validationMessages[groupCode][fieldConfig.FieldCode].numberVal = this.translateValueService.get('FIELD_NOT_NUMBER', { field: translatedField });
          }
        });
      });
  }

  // Define um registo de grupo de campos
  initGroupFields(fieldsConfig: GenericFieldConfig[], identifier: string = null, fieldValue: string = null): any {
    let groupControl = this.formBuilder.group({});
    let groupFormErrors = {};

    // Por cada configuração de campo
    fieldsConfig.forEach(fieldConfig => {
      // Validações do campo
      let validators = [];
      if (fieldConfig.isFieldRequired(this.model.DocumentTypeID, this.model.DocumentStateID, this.authenticationService.session.roles)) {
        validators.push(Validators.required);
      }
      if (fieldConfig.FieldLength) {
        validators.push(Validators.maxLength(fieldConfig.FieldLength));
      }
      if (fieldConfig.FieldType === GenericFieldType.NUMBER) {
        validators.push(ModelValidators.numberVal());
      }

      // Adicionar o campo ao formulário
      if (!groupControl.get(fieldConfig.FieldCode)) {

        // Boolean / String / Number
        groupControl.addControl(fieldConfig.FieldCode,
          new UntypedFormControl({
            value: fieldValue ? fieldValue : fieldConfig.getFieldValue(this.model.Fields.find(r => r.FieldCode === fieldConfig.FieldCode && r.Identifier === identifier), this.model.ID === 0),
            disabled: !fieldConfig.isFieldEditable(this.model.DocumentTypeID, this.model.DocumentStateID, this.authenticationService.session.roles)
          }, validators));

        // Só mostra os erros de campos que podem ser usados nos scripts (para excluir os campos Description)
        groupFormErrors[fieldConfig.FieldCode] = '';
      }
    });

    return { control: groupControl, formErrors: groupFormErrors };
  }

  // Adicionar ao formulário um grupos de campos
  addGroup(groupCode: string, fieldvalue: string = null) {
    const control = <UntypedFormArray>this.form.controls[groupCode];

    // Configurações dos campos do grupo
    let fieldsConfig = this.fieldsConfig.filter(r => r.GroupCode === groupCode);

    // Adiciona um grupo com valores por defeito
    let group = this.initGroupFields(fieldsConfig, null, fieldvalue);
    // Adiciona o grupo ao formulário
    control.push(group.control);
    // Adiciona a estrutura de erros do grupo
    this.formErrors[groupCode].push(group.formErrors);

    this.setFilterEventsCentroAtualizar(group.control);

    // // Activa os scripts ao grupo
    // this.activateNewGroupScripts(formGroup, groupCode);
  }

  // Remover do formulário um grupos de campos
  deleteGroup(groupCode: string, index: number) {

    if (groupCode !== 'CentrosAtualizar') {
      let dialogRef = this.dialog.open(DeleteModalComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const control = <UntypedFormArray>this.form.controls[groupCode];
          // Remove um grupo
          control.removeAt(index);
        }
      });
    } else {
      const control = <UntypedFormArray>this.form.controls[groupCode];
      // Remove um grupo
      control.removeAt(index);
    }
  }
  //#endregion Groups

  //#region Filtros
  private filterDropdowns(search: string, list: Dropdown[], filteredList: ReplaySubject<Dropdown[]>, filters: string[], parentCode: string = null) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => item.ItemName.toLowerCase().indexOf(search) !== -1
        && (filters ? filters.findIndex(r => r === item.ItemCode) !== -1 : true) && (parentCode ? item.ParentCode === parentCode : true))
    );
  }

  private filterCompanies(search: string, list: Company[], filteredList: ReplaySubject<Company[]>, filters: string[]) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => item.Name.toLowerCase().indexOf(search) !== -1 && (filters ? filters.findIndex(r => r === item.InternalID) !== -1 : true))
    );
  }

  private filterCountries(search: string, list: Country[], filteredList: ReplaySubject<Country[]>, filters: string[]) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => item.Name.toLowerCase().indexOf(search) !== -1 && (filters ? filters.findIndex(r => r === item.Name) !== -1 : true))
    );
  }

  private filterInfractions(search: string, list: Infraction[], filteredList: ReplaySubject<Infraction[]>, filters: string[]) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => (item.Code + '|' + item.Description).toLowerCase().indexOf(search) !== -1 && (filters ? filters.findIndex(r => r === item.Code) !== -1 : true))
    );
  }
  //#endregion Filtros

  //#region Validações/Alterações de campos
  /**
   * Validar o campo CodAuto
   */
  onChangeCodAuto() {
    const control = this.form.get('CodAuto');
    if (control && control.value) {

      // Verificar se é válido
      this.codAutoInvalidError = !this.validateCodAuto(control.value);
      control.updateValueAndValidity();

      // Validar se já existe apenas se o valor é correto
      if (this.codAutoInvalidError === false) {
        this.fineService.getAllByFieldValue('CodAuto', control.value).pipe(takeUntil(this.destroy$)).subscribe(response => {
          if (response.ReturnStatus.Successfull) {
            const documents = response.ReturnStatus.ReturnObject as GenericDocument[];

            this.codAutoAutoPagoError = documents.findIndex(r => r.DocumentStateID === AlticeFineState.AUTO_PAGO) !== -1;
            this.codAutoAutoPrescritoError = documents.findIndex(r => r.DocumentStateID === AlticeFineState.AUTO_PRESCRITO) !== -1;
            this.codAutoOtherError = documents.findIndex(r => r.DocumentStateID !== AlticeFineState.AUTO_PAGO && r.DocumentStateID !== AlticeFineState.AUTO_PRESCRITO && r.DocumentStateID !== AlticeFineState.ARQUIVAR) !== -1;
            this.codAutoArquivarError = documents.findIndex(r => r.DocumentStateID === AlticeFineState.ARQUIVAR) !== -1;

            control.updateValueAndValidity();

          } else {
            this._errorTreat.treatErrorResponse(response);
          }
        });
      } // validar se existe apenas se o numero for correto (não é suposto existirem autos com numeros incorretos)
    } else {
      this.codAutoInvalidError = false;
      this.codAutoAutoPagoError = false;
      this.codAutoAutoPrescritoError = false;
      this.codAutoOtherError = false;
      this.codAutoArquivarError = false;

      control.updateValueAndValidity();
    }
  }

  validateCodAuto(codAuto: string): boolean {
    if (codAuto && codAuto.length === 9) {
      // Verificar se é numerico
      let auxNumber: number = +codAuto;
      if (!isNaN(auxNumber)) {

        const pesos: number[] = [9, 8, 7, 6, 5, 4, 3, 2];
        let soma: number = 0;

        for (let index = 0; index < 8; index++) {
          let intDigit: number = +codAuto[index];
          soma += intDigit * pesos[index];
        }

        // Resto da divisão da soma por 11
        const resto: number = soma % 11;
        let validCheckDigid: number = 11 - resto;
        if (validCheckDigid >= 10) {
          validCheckDigid = 0;
        }

        const checkDigit: number = +codAuto[8];
        if (validCheckDigid === checkDigit) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Validar o campo ProcessoAssociado
   */
  onChangeProcessoAssociado() {
    const control = this.form.get('ProcessoAssociado');
    if (control && control.value) {
      // Verificar se é válido 
      this.processoAssociadoInvalidError = !this.validateCodAuto(control.value);
      control.updateValueAndValidity();

      if (this.processoAssociadoInvalidError === false) {
        this.fineService.getByCodAuto(control.value, false).pipe(takeUntil(this.destroy$)).subscribe(response => {
          if (response.ReturnStatus.Successfull) {
            const document = response.ReturnStatus.ReturnObject as GenericDocument;

            this.processoAssociadoNotFoundError = !document;
            this.processoAssociadoArquivarError = document && document.DocumentStateID === AlticeFineState.ARQUIVAR;
            this.processoAssociadoOtherError = document && document.DocumentStateID !== AlticeFineState.ARQUIVAR;

            control.updateValueAndValidity();

          } else {
            this._errorTreat.treatErrorResponse(response);
          }
        });
      }
    } else {
      this.processoAssociadoInvalidError = false;
      this.processoAssociadoNotFoundError = false;
      this.processoAssociadoArquivarError = false;
      this.processoAssociadoOtherError = false;

      control.updateValueAndValidity();
    }
  }

  setClientDependentLists(clientID: number) {
    const client = this.clients.find(x => x.ID === clientID);
    if (client) {
      // Entidades Autuantes especificos deste cliente
      this.filteredEntidadeAutuante.next(this.listEntidadeAutuante
        .filter(x => x.ParentCode === null || x.ParentCode === client.InternalID).slice());
      // Comarcas especificas do cliente
      this.filteredComarcaInfracao.next(this.listComarcaInfracao
        .filter(x => x.ItemCode === client.Municipality).slice());
      // Distritos especificos do cliente
      this.filteredDistritoInfracao.next(this.listDistritoInfracao
        .filter(x => x.ItemCode === client.District).slice());
    }
  }

  /**
   * Obter as infrações para o cliente selecionado
   */
  onChangeClientID() {
    const control = this.form.get('ClientID');
    let clientID: number = null;
    if (control && control.value) {
      clientID = control.value;
      this.getInfractions(control.value);
      this.setClientDependentLists(control.value);
    } else {
      this.infractions = [];
      this.filteredCodigoInfracao.next(this.infractions.slice());

      this.filteredEntidadeAutuante.next([]);
      this.filteredComarcaInfracao.next([]);
      this.filteredDistritoInfracao.next([]);
    }

    const codigoInfracao = this.form.get('CodigoInfracao');
    if (codigoInfracao) {
      codigoInfracao.setValue(null);
      codigoInfracao.markAsTouched();
    }

    const normasInfrigidas = this.form.get('NormasInfrigidas');
    if (normasInfrigidas) {
      normasInfrigidas.setValue(null);
      normasInfrigidas.markAsTouched();
    }

    // Se as listas só tiverem um valor carregar esse valor caso contrário colocar null
    const client = this.clients.find(x => x.ID === clientID);
    let filteredListEntidadeAutuante: Dropdown[] = [];
    let filteredListComarcaInfracao: Dropdown[] = [];
    let filteredListDistritoInfracao: Dropdown[] = [];
    if (client) {
      filteredListEntidadeAutuante = this.listEntidadeAutuante.filter(x => x.ID > 0 && (x.ParentCode === null || x.ParentCode === client.InternalID));
      filteredListComarcaInfracao = this.listComarcaInfracao.filter(x => x.ID > 0 && (x.ItemCode === client.Municipality));
      filteredListDistritoInfracao = this.listDistritoInfracao.filter(x => x.ID > 0 && (x.ItemCode === client.District));
    }
    const entidadeAutuante = this.form.get('EntidadeAutuante');
    if (entidadeAutuante) {
      if (filteredListEntidadeAutuante.length === 1) {
        entidadeAutuante.setValue(filteredListEntidadeAutuante[0].ItemCode);
      } else {
        entidadeAutuante.setValue(null);
      }
      entidadeAutuante.markAsTouched();
    }

    const comarcaInfracao = this.form.get('ComarcaInfracao');
    if (comarcaInfracao) {
      if (filteredListComarcaInfracao.length === 1) {
        comarcaInfracao.setValue(filteredListComarcaInfracao[0].ItemCode);
      } else {
        comarcaInfracao.setValue(null);
      }
      comarcaInfracao.markAsTouched();
    }

    const distritoInfracao = this.form.get('DistritoInfracao');
    if (distritoInfracao) {
      if (filteredListDistritoInfracao.length === 1) {
        distritoInfracao.setValue(filteredListDistritoInfracao[0].ItemCode);
      } else {
        distritoInfracao.setValue(null);
      }
      distritoInfracao.markAsTouched();
    }
  }

  onChangeCategoriaVeiculo() {
    const controlCategoriaVeiculo = this.form.get('CategoriaVeiculo');
    const controlTipoVeiculo = this.form.get('TipoVeiculo');
    if (controlCategoriaVeiculo && controlTipoVeiculo) {
      controlTipoVeiculo.setValue(controlCategoriaVeiculo.value);
      controlTipoVeiculo.markAsTouched();
    }
  }
  onChangeTipoVeiculo() {
    const controlTipoVeiculo = this.form.get('TipoVeiculo');
    controlTipoVeiculo.markAsTouched();
  }

  /**
   * Ao alterar o CodigoInfracao, alterar também o NormasInfrigidas com a descrição da infração
   */
  onChangeCodigoInfracao() {
    const control = this.form.get('CodigoInfracao');
    const normasInfrigidas = this.form.get('NormasInfrigidas');
    const descricaoInfracao = this.form.get('DescricaoInfracao');
    const sancoes = this.form.get('Sancoes');
    const valorCoima = this.form.get('ValorCoima');
    if (control && control.value) {
      const infraction = this.infractions.find(r => r.Code === control.value);
      if (infraction) {

        //Alterar o control que tem o intervalo de valores da coima
        if (this.form && this.form.get('InfrationValueInterval')) {
          this.form.get('InfrationValueInterval').setValue(infraction.MinValue + '€ a ' + infraction.MaxValue + '€');
        }

        // Construir as normas infringidas com a infração
        if (normasInfrigidas) {
          // Contrói a norma
          let normasInfrigidasValue = 'Art.º ' + infraction.InfractionArticle + ', Nº ' + infraction.InfractionNumber;
          // Verifica se a infração tem alínea, para acrescentar à norma
          if (infraction.InfractionLine) {
            normasInfrigidasValue += ', Al. ' + infraction.InfractionLine;
          }
          //normasInfrigidas.setValue(normasInfrigidasValue);
          normasInfrigidas.setValue(infraction.InfringedNorms);
        }
        // Descricao Infracao
        if (descricaoInfracao) {
          descricaoInfracao.setValue(infraction.Description);
        }
        // Sancoes
        if (sancoes) {
          // Contrói a norma
          let sancoesValue = 'Art.º ' + infraction.PunishArticle + ', Nº ' + infraction.PunishNumber;
          //sancoes.setValue(sancoesValue);
          sancoes.setValue(infraction.Sanctions);
        }
        // Valor da coima
        if (valorCoima) {
          valorCoima.setValue(infraction.MinValue);
          this.updateValorTotal();
        }
      }
    } else if (control) {
      if (normasInfrigidas) {
        normasInfrigidas.setValue(null);
      }
      if (descricaoInfracao) {
        descricaoInfracao.setValue(null);
      }
      if (sancoes) {
        sancoes.setValue(null);
      }
    }
  }

  onChangeTipoCond() {
    // se mudar o tipo condutor para arguido --> limpar os campos adicionais do condutor
    const control = this.form.get('TipoCond');
    if (control) {

      const mandatory: boolean = control && control.value !== '1';

      // NomeCond
      let controlNomeCond = this.form.get('NomeCond');
      const configNomeCond: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'NomeCond');

      // NifCond
      let controlNifCond = this.form.get('NifCond');
      const configNifCond: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'NifCond');

      // DomFiscalCond
      let controlDomFiscalCond = this.form.get('DomFiscalCond');
      const configDomFiscalCond: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'DomFiscalCond');

      // NCCondCond
      let controlNCCondCond = this.form.get('NCCondCond');
      const configNCCondCond: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'NCCondCond');

      // LocalEmissCCondCond
      let controlLocalEmissCCondCond = this.form.get('LocalEmissCCondCond');
      const configLocalEmissCCondCond: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'LocalEmissCCondCond');

      // DataEmissCCondCond
      let controlDataEmissCCondCond = this.form.get('DataEmissCCondCond');
      const configDataEmissCCondCond: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'DataEmissCCondCond');


      // Limpar campos automáticamente porque vão ser escondidos
      if (!mandatory) {
        controlNomeCond.setValue(null);
        controlNifCond.setValue(null);
        controlDomFiscalCond.setValue(null);
        controlNCCondCond.setValue(null);
        controlLocalEmissCCondCond.setValue(null);
        controlDataEmissCCondCond.setValue(null);
      }

      // se mudar o tipo de arguido --> obrigatoriedade de campos muda

      // NomeCond
      if (controlNomeCond && configNomeCond) {
        let validators = [];
        if (mandatory) {
          validators.push(Validators.required);
        }
        if (configNomeCond.FieldLength) {
          validators.push(Validators.maxLength(configNomeCond.FieldLength));
        }
        controlNomeCond.setValidators(validators);
        controlNomeCond.updateValueAndValidity();
      }

      // NifCond
      if (controlNifCond && configNifCond) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configNifCond.FieldLength) {
          validators.push(Validators.maxLength(configNifCond.FieldLength));
        }
        controlNifCond.setValidators(validators);
        controlNifCond.updateValueAndValidity();
      }

      // DomFiscalCond
      if (controlDomFiscalCond && configDomFiscalCond) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configDomFiscalCond.FieldLength) {
          validators.push(Validators.maxLength(configDomFiscalCond.FieldLength));
        }
        controlDomFiscalCond.setValidators(validators);
        controlDomFiscalCond.updateValueAndValidity();
      }

      // NCCondCond
      if (controlNCCondCond && configNCCondCond) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configNCCondCond.FieldLength) {
          validators.push(Validators.maxLength(configNCCondCond.FieldLength));
        }
        controlNCCondCond.setValidators(validators);
        controlNCCondCond.updateValueAndValidity();
      }

      // LocalEmissCCondCond
      if (controlLocalEmissCCondCond && configLocalEmissCCondCond) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configLocalEmissCCondCond.FieldLength) {
          validators.push(Validators.maxLength(configLocalEmissCCondCond.FieldLength));
        }
        controlLocalEmissCCondCond.setValidators(validators);
        controlLocalEmissCCondCond.updateValueAndValidity();
      }

      // DataEmissCCondCond
      if (controlDataEmissCCondCond && configDataEmissCCondCond) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configDataEmissCCondCond.FieldLength) {
          validators.push(Validators.maxLength(configDataEmissCCondCond.FieldLength));
        }
        controlDataEmissCCondCond.setValidators(validators);
        controlDataEmissCCondCond.updateValueAndValidity();
      }

    }
  }

  onChangeTipoArg() {
    let that = this;
    // se mudar o tipo de arguido --> obrigatoriedade de campos muda
    const control = this.form.get('TipoArg');

    if (control) {
      const mandatory: boolean = control && control.value === '1';

      // NumCCondArg
      let controlNumCCondArg = this.form.get('NumCCondArg');
      const configNumCCondArg: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'NumCCondArg');

      // LocalEmissCCondArg
      let controlLocalEmissCCondArg = this.form.get('LocalEmissCCondArg');
      const configLocalEmissCCondArg: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'LocalEmissCCondArg');

      // DataEmissCCondArg
      let controlDataEmissCCondArg = this.form.get('DataEmissCCondArg');
      const configDataEmissCCondArg: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'DataEmissCCondArg');

      // NumDocIdArg
      let controlNumDocIdArg = this.form.get('NumDocIdArg');
      const configNumDocIdArg: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'NumDocIdArg');

      // LocalEmissDocIdArg
      let controlLocalEmissDocIdArg = this.form.get('LocalEmissDocIdArg');
      const configLocalEmissDocIdArg: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'LocalEmissDocIdArg');

      // DataEmissDocIdArg
      let controlDataEmissDocIdArg = this.form.get('DataEmissDocIdArg');
      const configDataEmissDocIdArg: GenericFieldConfig = this.fieldsConfig.find(x => x.FieldCode === 'DataEmissDocIdArg');

      // NumCCondArg
      if (controlNumCCondArg && configNumCCondArg) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configNumCCondArg.FieldLength) {
          validators.push(Validators.maxLength(configNumCCondArg.FieldLength));
        }
        controlNumCCondArg.setValidators(validators);
        controlNumCCondArg.updateValueAndValidity();
      }

      // LocalEmissCCondArg
      if (controlLocalEmissCCondArg && configLocalEmissCCondArg) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configLocalEmissCCondArg.FieldLength) {
          validators.push(Validators.maxLength(configLocalEmissCCondArg.FieldLength));
        }
        controlLocalEmissCCondArg.setValidators(validators);
        controlLocalEmissCCondArg.updateValueAndValidity();
      }

      // DataEmissCCondArg
      if (controlDataEmissCCondArg && configDataEmissCCondArg) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configDataEmissCCondArg.FieldLength) {
          validators.push(Validators.maxLength(configDataEmissCCondArg.FieldLength));
        }
        controlDataEmissCCondArg.setValidators(validators);
        controlDataEmissCCondArg.updateValueAndValidity();
      }

      // NumDocIdArg
      if (controlNumDocIdArg && configNumDocIdArg) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configNumDocIdArg.FieldLength) {
          validators.push(Validators.maxLength(configNumDocIdArg.FieldLength));
        }
        controlNumDocIdArg.setValidators(validators);
        controlNumDocIdArg.updateValueAndValidity();
      }

      // LocalEmissDocIdArg
      if (controlLocalEmissDocIdArg && configLocalEmissDocIdArg) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configLocalEmissDocIdArg.FieldLength) {
          validators.push(Validators.maxLength(configLocalEmissDocIdArg.FieldLength));
        }
        controlLocalEmissDocIdArg.setValidators(validators);
        controlLocalEmissDocIdArg.updateValueAndValidity();
      }

      // DataEmissDocIdArg
      if (controlDataEmissDocIdArg && configDataEmissDocIdArg) {
        let validators = [];
        // if (mandatory) {
        //   validators.push(Validators.required);
        // }
        if (configDataEmissDocIdArg.FieldLength) {
          validators.push(Validators.maxLength(configDataEmissDocIdArg.FieldLength));
        }
        controlDataEmissDocIdArg.setValidators(validators);
        controlDataEmissDocIdArg.updateValueAndValidity();
      }

      // Adiciona o símbolo do obrigatório
      if (mandatory) {
        Array.prototype.forEach.call(document.getElementsByClassName('validatorTipoArg'), function (el: Element) {
          that._renderer.addClass(el, 'requiredElement');
        });
      } else {
        // Retira o símbolo do obrigatório
        Array.prototype.forEach.call(document.getElementsByClassName('validatorTipoArg'), function (el: Element) {
          that._renderer.removeClass(el, 'requiredElement');
        });
      }
    }
  }
  //#endregion Validações/Alterações de campos

  //#region Listas de Valores
  /**
   * Obter as infrações do cliente
   * @param  {number} partnerID ID do cliente
   */
  getInfractions(partnerID: number) {
    if (partnerID) {
      this.fineService.getInfractions(partnerID).pipe(takeUntil(this.destroy$)).subscribe(infractions => {
        this.infractions = infractions;
        this.filteredCodigoInfracao.next(this.infractions.slice());
      });
    } else {
      this.infractions = [];
      this.filteredCodigoInfracao.next(this.infractions.slice());
    }
  }
  //#endregion Listas de Valores

  /**
   * Mudar de tab
   * @param  {any} $event Objeto da tab selecionada
   */
  onTabChange($event: any) {
    let tab = $event.tab.content.viewContainerRef.element.nativeElement.getAttribute('id');
    this.showArqTab_open = false;
    this.showExportTab_open = false;
    this.showMessagesTab_open = false;
    this.showStateHistoryTab_open = false;

    switch (tab) {
      case 'DigitalArchive':
        this.showArqTab_open = true;
        break;
      case 'Export':
        this.showExportTab_open = true;
        break;
      case 'Messages':
        this.showMessagesTab_open = true;
        break;
      case 'StateHistory':
        this.showStateHistoryTab_open = true;
        if (this.stateLogs === undefined) {
          this.workflowService.getStateTransitionsLog(this.context, this.model.ID).pipe(takeUntil(this.destroy$)).subscribe(response => this.stateLogs = response);
        }
        break;
    }
  }

  // getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
  //   let hasError = false;
  //   const result = Object.keys(form.controls).reduce((acc, key) => {
  //     const control = form.get(key);
  //     const errors = (control instanceof FormGroup || control instanceof FormArray)
  //       ? this.getAllErrors(control)
  //       : control.errors;
  //     if (errors) {
  //       acc[key] = errors;
  //       hasError = true;
  //     }
  //     return acc;
  //   }, {} as { [key: string]: any; });
  //   return hasError ? result : null;
  // }

  updateValorCustas() {
    // Valores por defeito
    const valorCustasControl = this.form.get('ValorCustas');
    if (valorCustasControl) {
      if (valorCustasControl.value.toString() === '0') {
        valorCustasControl.setValue(this.valorCustas);
      } else {
        valorCustasControl.setValue('0');
      }
    }

    this.updateValorTotal();
  }

  /**
  * Atualizar o valor total sempre que um valor for alterado
  */
  updateValorAgravamento() {
    const valorAgravamentoControl = this.form.get('ValorCoimaAgravam');
    if (valorAgravamentoControl) {

      if (valorAgravamentoControl.value.toString() === '0') {
        let valorCoima: number = 0;
        const valorCoimaControl = this.form.get('ValorCoima');
        if (valorCoimaControl && valorCoimaControl.value !== '') {
          // Convert to number
          valorCoima = +valorCoimaControl.value.toString().revertDecimal();
        }

        valorAgravamentoControl.setValue((valorCoima * this.coimaAgravamento / 100).round(2));
      } else {
        valorAgravamentoControl.setValue('0');
      }
    }

    this.updateValorTotal();
  }

  /**
  * Atualizar o valor total sempre que um valor for alterado
  */
  updateValorTotal() {

    let valorCoima: number = 0;
    let valorAgravamento: number = 0;
    let valorCustas: number = 0;

    const valorCoimaControl = this.form.get('ValorCoima');
    if (valorCoimaControl && valorCoimaControl.value && valorCoimaControl.value !== '') {
      valorCoima = +valorCoimaControl.value.toString().revertDecimal();
    }
    const valorAgravamentoControl = this.form.get('ValorCoimaAgravam');
    if (valorAgravamentoControl && valorAgravamentoControl.value && valorAgravamentoControl.value !== '') {
      valorAgravamento = +valorAgravamentoControl.value.toString().revertDecimal();
    }
    const valorCustasControl = this.form.get('ValorCustas');
    if (valorCustasControl && valorCustasControl.value && valorCustasControl.value !== '') {
      valorCustas = +valorCustasControl.value.toString().revertDecimal();
    }

    const valorTotalControl = this.form.get('ValorTotal');
    if (valorTotalControl) {
      valorTotalControl.setValue((valorCoima + valorAgravamento + valorCustas).round(2));
    }

    // Valida o valor da coima
    let showWarn = false;
    const codigoInfracao = this.form.get('CodigoInfracao');
    if (codigoInfracao && codigoInfracao.value) {
      const infraction = this.infractions.find(r => r.Code === codigoInfracao.value);
      if (infraction && (valorCoima < infraction.MinValue || valorCoima > infraction.MaxValue)) {
        showWarn = true;
      }
    }

    this.showValorCoimaWarning = showWarn;
  }

  showChanges() {
    this.fineService.getFieldsChanges(this.model.ID).pipe(takeUntil(this.destroy$)).subscribe(response => {
      let changes: any[];
      if (response.ReturnStatus.Successfull) {
        changes = response.ReturnStatus.ReturnObject;
      } else {
        changes = [];
        this._errorTreat.treatErrorResponse(response);
      }

      this.dialog.open(EntityChangesModalComponent, {
        data: {
          context: CONTEXT_ALTICE_FINE,
          changes: changes
        }
      });
    });
  }

  AfterAction(obj: any) {
    //DS:11/03/2022
    //Atualizar os valores do form com os valores da BD se o tipo de anexo é:
    // 154-Pedido Infrator Defesa
    // 157-decisão condenatória
    // 158- decisão absolotoria 
    if (obj && obj.DiskFileTypeID && (obj.DiskFileTypeID === 154 || obj.DiskFileTypeID === 157 || obj.DiskFileTypeID === 158)) {
      this.onReload();
    }

  }


  showNotes(notes: string) {
    this.dialog.open(MessageModalComponent, { data: { title: 'NOTES', message: notes } });
  }

  onDownloadPdf() {

    //guardar nova genericField para esta minuta 
    let field = new GenericFieldExtension(0, this.form.get('ID').value, "AlticeFine", this.clientReference, null, "manual_ReferenciaMB", null, "GenericExport_" + Number(this.referenceGeneratedByClientExportID))


    this.commonService.createOrUpdateGenericFieldExtension(field).pipe(takeUntil(this.destroy$))
      .subscribe(response1 => {
        if (response1.ReturnStatus.Successfull) {
          return this._exportService.getPdf(this.form.get('ID').value, Number(this.referenceGeneratedByClientExportID)).pipe(takeUntil(this.destroy$)).subscribe((response2: any) => {
            if (response2.size && response2.size > 0 ) {
              this._exportService.get("AlticeFine", Number(this.referenceGeneratedByClientExportID)).pipe(takeUntil(this.destroy$))
                .subscribe(response3 => {
                  if (response3.ReturnStatus.Successfull) {
                    let name = response3.ReturnStatus.ReturnObject.Entity.Name;
                    this._fileTreat.afterDownloadFile(response2, name);
                  } else {
                    this._fileTreat.afterDownloadFile(response2, "Minuta");
                  }
                })
            } else {
              this.treatResponse(response2, false);
            }

          });
        } else {
          this.treatResponse(response1, false);
        }

      });


  }

  ngOnDestroy() { }
}
