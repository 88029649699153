import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterService } from './master.service';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL, CONTEXT_PRODUCT } from '../constants/global';
import { GenericExport } from '../models/generic-export';
import { ReturnStatusHtml } from '../models/returnStatus';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { GenericState } from '../models/generic-state';
import { AuthenticationService } from './authentication.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { GenericFieldExtension } from '../models/generic-field-extension';

@Injectable()
export class GenericExportService {
  static ActionType = {
    CHANGE_STEP: 1,
    ADD_STEPS_APPROVER: 2,
  };

  datatableParameters: DatatableParameters[] = [];
  private _genericStates: GenericState[];
  private _productStates: GenericState[];

  private _controller: string = 'GenericExport';

  // Settings
  private showEmailStates: number[];
  partnersLabel: string;
  partnersListLabel: string; 

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
  private authenticationService: AuthenticationService) { }

  getDatatableParameters(context: string): DatatableParameters {
    if (!this.datatableParameters[context]) {
      this.datatableParameters[context] = new DatatableParameters([null, null, null, null, null, null], 10, [[0, 'asc']], 0);
    }
    return this.datatableParameters[context];
  }

  get(context: string, id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(context: string, model: GenericExport, newFile?: File): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('entity', JSON.stringify(model));
    if (newFile) {
      formData.append('file', newFile, newFile.name); // vai adicionar ao formulario o ficheiro escolhido
    }
    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(context: string, model: GenericExport, newFile?: File): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('entity', JSON.stringify(model));
    if (newFile) {
      formData.append('file', newFile, newFile.name); // vai adicionar ao formulario o ficheiro escolhido
    }
    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(context: string, id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getAll(context: string, id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetListForUser?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getFields(context: string, id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetFields?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPdf(entityId: number, exportId: number ,signPdf: boolean = false , certificatePdf:boolean = false): Observable<ArrayBuffer> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDF?entityid=' + entityId + '&exportid=' + exportId + (signPdf ? '&signPdf=' + signPdf : '') + (certificatePdf ? '&certificatePdf=' + certificatePdf : ''));
  }

  saveFields(extensionFields: GenericFieldExtension[]): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(extensionFields));
    return this.http.post(SERVICE_URL + this._controller + '/SaveFields', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  generatePayment(minValue:string,maxValue:string,expireDate:string , clientID:number ,referenceID:number ,identifier:string , extInvoiceid:string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GeneratePayment', { minValue: minValue ,maxValue:maxValue,expireDate:expireDate , clientID : clientID ,referenceID:referenceID , identifier:identifier ,extInvoiceid:extInvoiceid }).pipe( 
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }  

  //settings
  get_partnersLabel(defaultValue: string): string {
    if (typeof this.partnersLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('GenericExport', 'PartnersLabel');
      if (settingValue != null) {
        this.partnersLabel = settingValue;
      } else {
        this.partnersLabel = defaultValue;
      }
    }
    return this.partnersLabel;
  }

  get_partnersListLabel(defaultValue: string): string {
    if (typeof this.partnersListLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('GenericExport', 'PartnersListLabel');
      if (settingValue != null) {
        this.partnersListLabel = settingValue;
      } else {
        this.partnersListLabel = defaultValue;
      }
    }
    return this.partnersListLabel;
  }
}
