import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export enum ButtonType { OK = 1, CONFIRMATION = 2 }

@Component({
  templateUrl: './warning-modal.html'
})
export class WarningModalComponent {
  
  message: string;
  buttonType: number = ButtonType.CONFIRMATION;
  dangerBackground: boolean;

  constructor(public dialogRef: MatDialogRef<WarningModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.message = data.message;
    this.dangerBackground = data.dangerBackground;
    if (data.buttonType) {
      this.buttonType = data.buttonType;
    }
  }
}
