import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ChooseModalComponent } from 'src/app/components/shared/choose-modal/choose-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CostCenter } from 'src/app/models/costCenter';
import { TableColumn } from 'src/app/models/table-column';
import { InputColumns } from 'src/app/models/input-columns';
import { CommonService } from 'src/app/services/common.service';
import { TranslateValueService } from 'src/app/services/translate-value.service';
@Component({
    selector: 'generic-selector',
    templateUrl: './generic-selector.component.html'
})
export class GenericSelectorComponent implements ControlValueAccessor, OnInit, OnChanges {

    //Variáveis
    destroy$: Subject<boolean> = new Subject<boolean>();
    selectedItem: any;
    disabled: boolean;
    private _items: any[];

    //Inputs
    @Input() value: number = 0; //valor do item (para ngmodel)
    @Input() enabled: boolean = true; //campo que habilita o input
    @Input() placeholder: string = null; //placeholder do input
    @Input() displayedColumns: TableColumn[]; //colunas que vão ser apresentadas no modal
    @Input() title: string = 'SELECT_ITEM'; //titulo do modal

    @Input() controller: string; //controlador em que são feitos os endpoints
    @Input() itemEndpoint: string; //endpoint para obter item
    @Input() itemListEndpoint: string; //endpoint para obter lista de items
    @Input() params: object; //objeto de parametros do endpoint da lista

    @Input() property: string; //input para guardar os diferentes tipos de items (encomendas, faturas, etc)
    @Input() propertyKey: string = 'ID'; //propriedade que será usada para procurar determinado item (por exemplo id)
    @Input() propertyToShow: string; //propriedade que será usada mostrar no input (exemplo nome)
    @Input() filterSavedOnCache: object; //objeto para gravar o item (key:value)
    @Input() functionOnSelect: Function = this.onChooseModalDialogClose; //função executada após selecionar item 
    @Input() defaultValueFunction: Function = this.setDefaultValue; //função que define o valor por defeito

    //Outputs
    @Output() change = new EventEmitter<number>(); //Change do Form
    @Output() valueChange: EventEmitter<number> = new EventEmitter(); //Change do ng model
    
    constructor(@Optional() public ngControl: NgControl, private _translateService: TranslateService, private _dialog: MatDialog, private _commonService: CommonService, private _translateValueService: TranslateValueService) {
        if (ngControl)
            ngControl.valueAccessor = this;
    }
    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.value?.currentValue != changes.value?.previousValue && !changes.value?.isFirstChange()) {
            await this.getSelectedItem();
        }
    }
    async ngOnInit(): Promise<void> {
        // se tiver desativado o form (consulta) so vai buscar o registo guardado caso constrario vai buscar todos
        await this.getSelectedItem();
    }

    //Método para ir buscar o item selecionado
    private async getSelectedItem() {

        //Caso não tenha items e o input esteja ativo, buscar items
        if (!this._items && !this.disabled)
            await this.getItems();

        //Caso tenha um value definido (caso para ngmodels), colocar esse valor como item selecionado
        if (this.value) {
            this.selectedItem = this._items.find(pc => pc[this.propertyKey] == this.value);
        }

        //Caso já tenha o item selecionado com a propriedade definida
        //Se o input for disabled => correr endpoint para ir buscar novamente os items (para prevenir que sejam adicionados novos items e não apareçam)
        //Senão => buscar todos os items (sem recorrer a um novo pedido à bd)
        else if (this.selectedItem?.[this.propertyKey]) {
            if (this.disabled) {
                
                const response = await this._commonService.getComplements<any[]>(this.itemEndpoint, {id: this.selectedItem[this.propertyKey]}, this.controller).toPromise();
                
                if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject)
                    this.selectedItem = response.ReturnStatus.ReturnObject;
            }
            else {
                this.selectedItem = this._items.find(pc => pc[this.propertyKey] === this.selectedItem[this.propertyKey]);
            }
        }

    }

    //Método para ir buscar os items
    async getItems() {
        if(this.filterSavedOnCache)
        {
            this._items = await this._commonService.getDataArray<any[]>({ property: this.property, url: this.itemListEndpoint, params: this.params, controler: this.controller, key: this.filterSavedOnCache }).toPromise();
        }
        else
        {
            this._items = await this._commonService.getData<any[]>({ property: this.property, url: this.itemListEndpoint, params: this.params, controler: this.controller }).toPromise();
        }

        //Se não tenho items, não consigo definir um valor por defeito
        if (!this._items || this._items.length === 0)
            return;

        //Caso não tenha item selecionado, definir um por defeito
        if(!this.selectedItem)
        {
            this.selectedItem = this.defaultValueFunction(this._items);
            this.setValue(this.selectedItem[this.propertyKey]);
        }

    }

    //Selecionar item
    selectItem() {
        this._translateService.get([...this.displayedColumns?.map(column => column.ColumnName), this.title]).subscribe(response => {

            const aoColumns = this.displayedColumns?.map(column => {
                return { 'data': column.ColumnValue, 'title': response[column.ColumnName] };
            });

            const columnDefs = [
                { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
            ];
            const dialogRef = this._dialog.open(ChooseModalComponent, {
                data: new ChooseModalParam(this._items, null, response[this.title], aoColumns, columnDefs, null, 1, null, null, null, true),
                disableClose: true // nao permitir fechar modal com escape ou clique fora
            });
            dialogRef.afterClosed().subscribe((itemProperty: any) => {

                //Quando cancela, não limpar valor
                if(itemProperty == null)
                    return;

                //Quando limpa, limpar valor
                if (itemProperty == undefined || itemProperty == -1) {
                    this.setValue(null);
                    return;
                }

                //Definir item selecionado, com a função que acontece ao selecionar
                this.selectedItem = this.functionOnSelect(itemProperty, this._items, this.selectedItem);
                this.setValue(itemProperty);
            });
        });
    }

    //Método que define o valor do controlo
    private setValue(itemProperty: any) {
        if (this.ngControl?.control) {
            this.ngControl.control.setValue((this.selectedItem?.[this.propertyKey] && itemProperty) ? this.selectedItem[this.propertyKey] : null);
            this.change.emit(itemProperty);
        }
        else
            this.valueChange.emit(itemProperty);
    }

    //Método que define o que acontece pós selecionar o item
    onChooseModalDialogClose(itemProperty) {

        const found = this._items.find(item => item[this.propertyKey] === itemProperty);
        if (found) {
            return found;
        }
    }

    //Método para definir valor por defeito
    setDefaultValue(items: any){

        //Validar se só existe um item, se for seleciona por defeito 
        if(items?.length == 1)
        {
            return items[0];
        }
    }

    onChanged: (item: any) => void;
    onTouched: () => void;
    async writeValue(value: number): Promise<void> {
        if (value) {
            if (this._items)
                this.selectedItem = this._items?.find(cc => cc.ID === value);
            else
                this.selectedItem = new CostCenter(value);
        } else if (value == null) {
            this.selectedItem = null;
        }
    }
    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

}