
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { Observable } from 'rxjs';

import { MasterService } from './master.service';
import { ReturnStatusHtml } from '../models/returnStatus';

@Injectable()
export class DigitalArchiveService {

  private _controller: string = 'RepositoryDiskFiles';

  constructor(private http: HttpClientCustom, private _masterService: MasterService) { }

  /**
   * Ir buscar um ficheiro
   * @param  {number} id
   */
  get(id: number, noSession: boolean = false) {
    return this.http.getFile(SERVICE_URL + 'Common/GetDocument/' + id + '?noSession=' + noSession);
  }

   /**
   * Ir buscar um ficheiro
   */
   getDocumentByContext(entityID : number, context : string, diskFileType? : number) {
    return this.http.getFile(SERVICE_URL + `/${this._controller}/GetDocument?entityID=${entityID}&context=${context}&diskFileType=${diskFileType}`);
  }

  /**
   * Obter lista de ficheiro
   * @param  {string} context
   * @param  {number} entityID
   * @param  {boolean} noSession
   * @returns Promise
   */
  getAll(context: string, entityID: number, noSession: boolean, filterByIsPublic: boolean = false, additionalContexts: any = null): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAll?context=' + context + '&entityID=' + entityID + '&noSession=' + noSession + 
    '&filterByIsPublic=' + filterByIsPublic + (additionalContexts ? '&additionalContexts=' + JSON.stringify(additionalContexts) : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar um novo ficheiro
   * @param  {FormData} formData
   * @param  {boolean} noSession
   * @returns Promise
   */
  add(formData: FormData): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Create', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Retirar ficheiros
   * @param  {number} id
   * @param  {boolean} noSession
   * @returns Promise
   */
  delete(id: number, noSession: boolean): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id + '&noSession=' + noSession).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  CertificatePDF(pdfID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + 'GenericDocument' + '/CertificatePDF', {pdfID: pdfID}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }  

}
