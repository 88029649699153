import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GenericFieldConfig, GenericFieldType } from 'src/app/models/generic-field-config';
import { Dropdown } from 'src/app/models/dropdown';
import { CommonService } from 'src/app/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs';
import { ProcessModalComponent } from '../../generic-documents/process/process-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProcuctChooseModalComponent } from '../../shared/product-choose-modal/product-choose-modal.component';
@Component({
  selector: 'app-generic-field',
  templateUrl: './generic-field.html'
})
export class GenericFieldComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() fieldConfig: GenericFieldConfig;
  @Input() parentIndex?: number = null;
  @Input() uppercaseText: boolean = false;
  @Input() isServiceProvider: boolean = false;
  @Output() onFieldChange?: EventEmitter<{ value: any, parentIndex?: number }> = new EventEmitter<{ value: any, parentIndex?: number }>();

  FieldTypeString = GenericFieldType.STRING;
  FieldTypeNumber = GenericFieldType.NUMBER;
  FieldTypeBoolean = GenericFieldType.BOOLEAN;
  FieldTypeArray = GenericFieldType.ARRAY;
  FieldTypeFile = GenericFieldType.FILE;
  FieldTypeTextArea = GenericFieldType.TEXTAREA;
  FieldTypeDate = GenericFieldType.DATE;
  FieldTypeChooseProduct = GenericFieldType.CHOOSEPRODUCT;
  isDisabled: boolean = true;
  // Listas
  items: Dropdown[] = [new Dropdown(null, null, null, null, -1)];
  filteredItems: ReplaySubject<Dropdown[]> = new ReplaySubject<Dropdown[]>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private commonService: CommonService,  private _dialog: MatDialog) { }

  ngOnInit() {
    // Verifica se é um campo do tipo 'array'
    this.form;
    if (this.fieldConfig && this.fieldConfig.FieldType === this.FieldTypeArray) {
      // Obtém a lista de itens
      this.commonService.getDropdownData(this.fieldConfig.DropDownID).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.items.push(...response);
        this.filteredItems.next(this.items.slice());
      });

      // Cria o controlo do filtro da lista
      this.form.addControl(this.fieldConfig.FieldCode + 'Filter', new UntypedFormControl(null));
      // Recria a lista com a pesquisa
      this.form.get(this.fieldConfig.FieldCode + 'Filter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
        this.filterDropdowns(val, this.items, this.filteredItems, null));
    }
  }

  /**
   * Emitir o evento quando há uma alteração no campo
   * @param  {any} event
   */
  onChange(event: any) {
    if (this.onFieldChange) {
      this.onFieldChange.emit({ value: event.value, parentIndex: this.parentIndex });
    }
  }

  onClickInfo(){
    let data = {
      fieldId: this.fieldConfig.ID,
      texto: this.fieldConfig.InformationText,
      idImagem: this.fieldConfig.InformationImageID,
    };
    this._dialog.open(ProcessModalComponent, { data, autoFocus: false });
  }

  setProductValue(){
    let dialogRef = this._dialog.open(ProcuctChooseModalComponent, {
      disableClose: false, // nao permitir fechar modal com escape ou clique fora
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.form.controls[this.fieldConfig.FieldCode].setValue(result);
      }
    });
  }

  /**
   * Filtrar a lista com a pesquisa
   * @param  {string} search
   * @param  {Dropdown[]} list
   * @param  {ReplaySubject<Dropdown[]>} filteredList
   * @param  {string[]} filters
   * @param  {string=null} parentCode
   */
  private filterDropdowns(search: string, list: Dropdown[], filteredList: ReplaySubject<Dropdown[]>, filters: string[], parentCode: string = null) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => (item.ItemCode + ' | ' + item.ItemName).toLowerCase().indexOf(search) !== -1
        && (filters ? filters.findIndex(r => r === item.ItemCode) !== -1 : true) && (parentCode ? item.ParentCode === parentCode : true))
    );
  }

  ngOnDestroy() { }

}
