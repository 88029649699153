import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ALIAS, LANGUAGE } from 'src/app/constants/global';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from '../../../models/dropdown';
import { CommonService } from '../../../services/common.service';

declare var Functions: any;


@Component({
  templateUrl: './dropdown-selector-modal.html',
})
export class DropdownSelectorModalComponent implements AfterViewInit {

  isDropdownModal: boolean;
  dropdownName: string;
  items: CheckItem[] = new Array<CheckItem>();
  dropdown: Array<Dropdown> = new Array<Dropdown>();
  title: string;
  dropdownValue: string = "";

  // Permite edição
  enabled: boolean;
  // Mostra Código
  showCode: boolean;
  // Mostra Nome
  showName: boolean;
  //permitir limpar
  allowClean:boolean;

  // Filtros
  codeFilter: string;
  nameFilter: string;

  constructor(public dialogRef: MatDialogRef<DropdownSelectorModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private translateService: TranslateService, private commonService: CommonService) {
    this.dropdown = JSON.parse(JSON.stringify(data.dropdown));
    this.dropdown = this.dropdown.filter(d => d.Inactive == false);
    this.title = data.title;
    this.enabled = data.enabled;
    this.showCode = data.showCode;
    this.showName = data.showName;   
    this.dropdownValue = data.dropdownValue;
    this.allowClean = data.allowClean;
  }
    
  ngAfterViewInit(): void {
    if (this.dropdown != null && this.dropdown.length != 0) {
      this.dropdown[0].ID == null ? this.dropdown.splice(0,1) : false;
      this.dropdown.forEach(element => {               
        
        let newItem :CheckItem = new CheckItem(element.ID.toString(),false,element.ItemCode,element.ItemName);                
        this.items.push(newItem);
      });
      
    }

    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'REFRESH', 'BUTTON.ALL', 'SERVER_TIMEOUT', 'SERVER_ERROR', 'FILTER']).subscribe(translation => {
      let that = this;

      let translates = {
        clear: translation['BUTTON.CLEAR'],
        update: translation.REFRESH,
        all: translation['BUTTON.ALL'],
        serverTimeout: translation.SERVER_TIMEOUT,
        serverError: translation.SERVER_ERROR,
        search: translation['FILTER']
      }, aoColumns = [
        { 'data': 'ID', 'visible': false, 'orderable': false },
        { 'data': 'Code', 'class': 'verticalMiddle', 'visible': this.showCode },
        { 'data': 'Name', 'class': 'verticalMiddle', 'visible': this.showName },
        {
          'data': 'Selected', 'class': 'verticalMiddle text-center', 'render': function (data: boolean, type: string, row: CheckItem, meta: any) {
            if (type === 'sort' || type === 'type') {
              return data;
            } else {
              return '<div class="md-radio" style="height: 17px"><input id="' + row.ID + '" type="radio" class="check-item" ' + (data ? 'checked' : '') + ' /><label for="' + row.ID + '"></label></div>';
            }
          }
        }
      ], columnDefs = [
        { 'targets': [-1], 'width': '110px' }, // colocar a ultima coluna mais pequena
        { 'targets': '_all', 'defaultContent': '' }
      ];

      let table = Functions.datatablesWithDataSet('#table-listDropdown', this.items, translates, aoColumns, columnDefs,
        ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', this.showName ? 2 : 1, true, false, false, false, 'asc');

      table.on('draw', function () {
        let checkboxList = document.getElementsByClassName('check-item');
        for (let checkbox of Array.from(checkboxList)) {
          // adicionar à checkbox o evento click
          checkbox.addEventListener('click', function (ev: any) {
            that.selectItem(this);
          });
        }
      });
    });
  }

  selectItem(element: HTMLInputElement) {
    // Seleciona / deseleciona o item
    this.items.find(r => r.ID.toString() === element.id).Selected = element.checked;

    // Atualiza a lista da tabela para reflectir na ordenação
    Functions.datatables_newDataSet('#table-listDropdown', this.items);
    
    // Qual o Index Item selecionado
    let index = this.items.findIndex(r => r.Selected)
    //let itemCode = this.items.filter(r => r.Selected).map(r => r.Code)

    //Envia a resposta ao componente que o chamou    
    this.dialogRef.close(this.dropdown[index] as Dropdown); 
    //this.dialogRef.close(this.dropdown.filter(r => r.ItemCode == itemCode)); 
    
  }  
  
}

export class CheckItem {
  ID: string;
  Selected: boolean;
  Code: string;
  Name: string;

  constructor(ID: string, Selected: boolean, Code: string, Name: string) {
    this.ID = ID;
    this.Selected = Selected;
    this.Code = Code;
    this.Name = Name;
  }
}
