import { InputTypes } from "./input-columns";

export class TableColumn {
    constructor(
        /// Nome do Campo passado pela Base dados da Coluna
        public ColumnValue: string,
        /// Nome da Coluna
        public ColumnName : string,
        public Format?: Formats,
        /// Tipo de Input
        public InputType?: InputTypes,

        /** Lista para o select */
        public SelectList?: Array<any>,

        /** Valor Selecionado por defeito no  */
        public DefaultFilterValue?: any,

        /** Alinhar à direita */
        public AlignRight?: boolean,

        public DefaultSort?: 'asc' | 'desc'
    ) { }
}
export enum Formats {
    Date = "date",
    Number = "number",
    Text = "text",
    Boolean = "boolean",
    DateTime = "DateTime"
} 

export class TableFilterRow {
    constructor(
        /// Nome do Campo passado pela Base dados da Coluna
        public ObjectKey: string,
        /// Nome da Coluna
        public FilterValue: string,
        /// Tipo de Input
        public InputType: InputTypes = InputTypes.Text,
        /** Lista para o select */
        public SelectList?: Array<any>,
        /** Alinhar à direita */
        public AlignRight?: boolean,
        public Format?: Formats,

        public SortDirection?: 'asc' | 'desc' | ''
    ) { }
}

export class FooterRow {
    ColumsOrder?: string[] = [];
    FooterColumns: FooterColumn[];
    Condition?: boolean = true;

    constructor(FooterColumns: FooterColumn[], Condition = true, ColumsOrder = []) {
        this.FooterColumns = FooterColumns
        this.ColumsOrder = ColumsOrder;
        this.Condition = Condition;
    }

}

export class FooterColumn {
    /** Valor que vai mostrar no footer */
    Value: string;
    /** Nome da Coluna que o footer vai chamar */
    FooterColumnName?: string;
    /** Numero de colunas que o footer vai ocupar */
    Colspan?: number = 1;
    
    constructor(Value: string, Colspan: number = 1) {
        this.Value = Value
        this.FooterColumnName = '';
        this.Colspan = Colspan;
    }

    
}

export class TableFilter{
    constructor(
    public Page : number,
    public ItemsPerPage : number,
    public Filters : TableFilterRow[]
    ) {}
}
