
import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class SalesService {

  private _wholeSalers: any;
  private _stores: any;
  //Sales History
  filter_DataIni_H: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_DataFim_H: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  //Sales Import
  filter_DataIni_I: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_DataFim_I: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_Archived: string; // para manter o valor do filtro do archived

  showReprocessButton: boolean;

  //Sales History
  showColReportDateSalesHistory: boolean;
  ShowColMessageSenderNameSalesHistory: boolean;
  ShowColStoreSalesHistory: boolean;
  showColAgentName: boolean;
  
  //Sales Import
  showColSalesReportDate: boolean;
  showColMessageSenderName: boolean;
  columnNameAgentName: string;
  showDeleteButton: boolean;
  showArchivedCheckbox: boolean;

  //Sales Import Details
  showMSDDataTable: boolean;
  showColumnToDelete: boolean; 


  //Sales History Details
  columnNameBuyersProductCode: string;
  columnNameStandardProductCode: string;
  columnNameReturnQuantityValue: string;
  columnNameBonusQuantityValue: string;
  showTotalSoldValue:boolean;

  private _controller = 'Sales';

  private columnInVsible:Array<number>;

  datatableParametersImports: DatatableParameters = new DatatableParameters([null, null, null, null, null, null,null], 10, [[6, 'desc']], 0);
  datatableParametersHistory: DatatableParameters = new DatatableParameters([null, null, null, null, null,null,null], 10, [[6, 'desc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, 
    private authenticationService: AuthenticationService, private _dataTableF:DataTableFunctions) {
  }

  get wholeSalers(): Observable<any> {
    if (this._wholeSalers === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetWholesalers').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._wholeSalers = responseReturn.ReturnStatus.ReturnObject;
              return this._wholeSalers;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._wholeSalers);
    }
  }

  get getStores(): Observable<any>{
    if(this._stores === undefined){
      return this.http.get(SERVICE_URL + this._controller + '/GetStores').pipe(
        map(response => {
        try{
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if(responseReturn.ReturnStatus.Successfull){
            this._stores = responseReturn.ReturnStatus.ReturnObject;
            return this._stores;
          }
          else{
            this._errorTreat.treatErrorResponse(responseReturn);
          }
        }catch(error){
          this._masterService.handleError(error);
        }
      }));
    }else{
      return observableOf(this._stores);
    }
  }

  /****************GET METHODS**********************/

  getEDISalesReport(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetEDISalesReport').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEDISaleReport(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetEDISaleReport?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEDISalesReportDetailsTotal(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetEDISalesReportDetailsTotal?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEDISalesReportDetailListLazy(id: number, startFrom: number, until: number, orderby?:string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetEDISalesRepDeListLazy', { id: id, startFrom: startFrom, until: until,orderField:orderby }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEDISalesReportDetailListLazyWithDeletedItemsOrOrdered(id: number, startFrom: number, until: number, deletedRows: any, orderField: string, order: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetEDISalesRepDeListLazy', { id: id, startFrom: startFrom, until: until, deleted: deletedRows, orderField: orderField, order: order }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getSalesReport(parameters: DatatableParameters, dates: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/GetSalesReportForExcel', {parameters: parameters, firstDate: dates.firstdate, lastDate: dates.lastdate});
																					   

  }

  getEDISalesReportDetails(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetEDISalesReportDetails?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getSalesReportDetails(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetSalesReportDetails?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************UPDATE METHODS**********************/

  updateEDISalesReportProductDetail(id: number, deleted: any, edited: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateEDISalesReportProductDetail', { id: id, deleted: deleted, edited: edited }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateEDISaleReport(report: any): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/UpdateEDISaleReport', {report: report}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateSaleReportDiscardSales(productsIDsArrayToInsert: Array<number>, productsIDsArrayToDelete: Array<number>): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/updateSaleReportDiscardSales', {productsIDsArrayToInsert: productsIDsArrayToInsert, productsIDsArrayToDelete:productsIDsArrayToDelete }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  /****************DELETE METHODS**********************/

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteEDISalesReport?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteLineHist(id: number): Observable<ReturnStatusHtml>{
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteLineHist?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************DOWNLOAD METHODS**********************/

  downloadExcelEDISalesReportDetails(id: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelEDISalesReportDetails', { id: id });
  }

  downloadExcelSalesReportDetails(id: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelSalesReportDetails', { id: id });
  }

  downloadExcelImportErrosList(parameters: DatatableParameters, dates: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelImportErrosList', {parameters: parameters, firstDate: dates.firstdate, lastDate: dates.lastdate, archived: dates.archived});
  }

  get_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>, SettingsName:string):Array<number>{
    if(typeof this.columnInVsible === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal(SettingsName, 'ColumnVisible');
      let settingValueSP = this.authenticationService.getSettingPortal(SettingsName, 'ColumnVisibleSP');
      this.columnInVsible = this._dataTableF.getInvColumns(defaultValue, columnsNames,this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValue.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVsible;
  }

  /****************SETTINGS**********************/

  /*SETTINGS SALES IMPORT*/
  getSales_showReprocessButton(defaultValue: boolean): boolean {
    if(typeof this.showReprocessButton === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','ShowReprocessButton');
      if(settingValue != null){
        this.showReprocessButton = settingValue;
      } else{
        this.showReprocessButton = defaultValue;
      }
    }
    return this.showReprocessButton;
  }

  getSales_showColSalesReportDate(defaultValue: boolean): boolean {
    if(typeof this.showColSalesReportDate === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','showColSalesReportDate');
      if(settingValue != null){
        this.showColSalesReportDate = settingValue;
      } else{
        this.showColSalesReportDate = defaultValue;
      }
    }
    return this.showColSalesReportDate;
  }

  getSales_showColMessageSenderName(defaultValue: boolean): boolean {
    if(typeof this.showColMessageSenderName === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','showColMessageSenderName');
      if(settingValue != null){
        this.showColMessageSenderName = settingValue;
      } else{
        this.showColMessageSenderName = defaultValue;
      }
    }
    return this.showColMessageSenderName;
  }

  getSales_columnNameAgentName(defaultValue: string): string {
    if(typeof this.columnNameAgentName === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','ColumnNameAgentName');
      if(settingValue != null){
        this.columnNameAgentName = settingValue;
      } else{
        this.columnNameAgentName = defaultValue;
      }
    }
    return this.columnNameAgentName;
  }

  getSales_showDeleteButton(defaultValue: boolean): boolean {
    if(typeof this.showDeleteButton === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','ShowDeleteButton');
      if(settingValue != null){
        this.showDeleteButton = settingValue;
      } else{
        this.showDeleteButton = defaultValue;
      }
    }
    return this.showDeleteButton;
  }

  getSales_showArchivedCheckbox(defaultValue: boolean): boolean {
    if(typeof this.showArchivedCheckbox === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','ShowArchivedCheckbox');
      if(settingValue != null){
        this.showArchivedCheckbox = settingValue;
      } else{
        this.showArchivedCheckbox = defaultValue;
      }
    }
    return this.showArchivedCheckbox;
  }

  /*SETTINGS SALES IMPORT DETAILS*/
  getSales_showMSDScrean(defaultValue: boolean): boolean {
    if(typeof this.showMSDDataTable === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImport','ShowMSDDataTable');
      if(settingValue != null){
        this.showMSDDataTable = settingValue;
      } else{
        this.showMSDDataTable = defaultValue;
      }
    }
    return this.showMSDDataTable;
  }

  getSales_showColumnToDelete(defaultValue: boolean): boolean {
    if(typeof this.showColumnToDelete === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesImportDetails','ShowColumnToDelete');
      if(settingValue != null){
        this.showColumnToDelete = settingValue;
      } else{
        this.showColumnToDelete = defaultValue;
      }
    }
    return this.showColumnToDelete;
  }
  
  /*SETTINGS SALES HISTORY*/
  getSales_showColReportDateSalesHistory(defaultValue: boolean): boolean {
    if(typeof this.showColReportDateSalesHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistory','showColReportDate');
      if(settingValue != null){
        this.showColReportDateSalesHistory = settingValue;
      } else{
        this.showColReportDateSalesHistory = defaultValue;
      }
    }
    return this.showColReportDateSalesHistory;
  }

  getSales_showColMessageSenderNameSalesHistory(defaultValue: boolean): boolean {
    if(typeof this.ShowColMessageSenderNameSalesHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistory','ShowColMessageSenderName');
      if(settingValue != null){
        this.ShowColMessageSenderNameSalesHistory = settingValue;
      } else{
        this.ShowColMessageSenderNameSalesHistory = defaultValue;
      }
    }
    return this.ShowColMessageSenderNameSalesHistory;
  }

  getSales_showColShowColStoreSalesHistory(defaultValue: boolean): boolean {
    if(typeof this.ShowColStoreSalesHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistory','ShowColStore');
      if(settingValue != null){
        this.ShowColStoreSalesHistory = settingValue;
      } else{
        this.ShowColStoreSalesHistory = defaultValue;
      }
    }
    return this.ShowColStoreSalesHistory;
  }

  getSales_showColShowColAgentName(defaultValue: boolean): boolean {
    if(typeof this.showColAgentName === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistory','ShowColAgentName');
      if(settingValue != null){
        this.showColAgentName = settingValue;
      } else{
        this.showColAgentName = defaultValue;
      }
    }
    return this.showColAgentName;
  }

  getSales_showTotalSoldValue(defaultValue: boolean): boolean {
    if(typeof this.showTotalSoldValue === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistoryDetails','ShowTotalSoldValue');
      if(settingValue != null){
        this.showTotalSoldValue = settingValue;
      } else{
        this.showTotalSoldValue = defaultValue;
      }
    }
    return this.showTotalSoldValue;
  }

  
  /*SETTINGS SALES HISTORY DETAILS*/
  getSales_columnNameBuyersProductCode(defaultValue: string): string {
    if(typeof this.columnNameBuyersProductCode === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistoryDetails','ColumnNameBuyersProductCode');
      if(settingValue != null){
        this.columnNameBuyersProductCode = settingValue;
      } else{
        this.columnNameBuyersProductCode = defaultValue;
      }
    }
    return this.columnNameBuyersProductCode;
  }

  getSales_columnNameStandardProductCode(defaultValue: string): string {
    if(typeof this.columnNameStandardProductCode === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistoryDetails','ColumnNameStandardProductCode');
      if(settingValue != null){
        this.columnNameStandardProductCode = settingValue;
      } else{
        this.columnNameStandardProductCode = defaultValue;
      }
    }
    return this.columnNameStandardProductCode;
  }

  getSales_columnNameReturnQuantityValue(defaultValue: string): string {
    if(typeof this.columnNameReturnQuantityValue === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistoryDetails','ColumnNameReturnQuantityValue');
      if(settingValue != null){
        this.columnNameReturnQuantityValue = settingValue;
      } else{
        this.columnNameReturnQuantityValue = defaultValue;
      }
    }
    return this.columnNameReturnQuantityValue;
  }

  getSales_columnNameBonusQuantityValue(defaultValue: string): string {
    if(typeof this.columnNameBonusQuantityValue === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('SalesHistoryDetails','ColumnNameBonusQuantityValue');
      if(settingValue != null){
        this.columnNameBonusQuantityValue = settingValue;
      } else{
        this.columnNameBonusQuantityValue = defaultValue;
      }
    }
    return this.columnNameBonusQuantityValue;
  }


}
