import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { invalid } from 'moment';
import { Subject } from 'rxjs';
import { ALIAS, LANGUAGE } from 'src/app/constants/global';
import { User } from 'src/app/models/user';
import { TranslateValueService } from 'src/app/services/translate-value.service';

declare var Functions: any;

@Component({
    templateUrl: './emails-details-modal.html',
})

export class EmailsDetailsModalComponent implements OnInit, AfterViewInit, OnDestroy
{
    destroy$: Subject<boolean> = new Subject<boolean>();
    collPnPopup2: boolean;
    collPnPopup1: boolean;

    usersList: Array<any> = new Array<any>();
    usersIDsList: Array<any> = new Array<any>();
    tabelaUsers: any = null;
    emails: string[] = new Array<string>();
    emailsCSV: string = '';

    constructor(public dialogRef: MatDialogRef<EmailsDetailsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any,
        private translateService: TranslateService, private translateValueService: TranslateValueService) 
    {
        this.usersList = data.users;
        if(data.emails && data.emails.length > 0)
        {
            let emailsSplited = data.emails.split(";");
            emailsSplited.forEach(email => 
            {
                this.emails.push(email);
                this.usersList.forEach(user => 
                {
                    if(user.Email == email)
                    {
                        this.usersIDsList.push(user.ID);
                    }
                })
            })

            this.createEmailStringCSV();
        }
        
    }


    ngOnInit(): void 
    {

    }



    ngAfterViewInit(): void
    {
        this.initUsersTable();
    }


    initUsersTable() 
    {
        this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
            'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
            .toPromise().then((response: string[]) =>
            {
                let that = this;

                let buttons = function (id: string): string
                {
                    let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableUsers-choose"><label for="u' + id + '"></label></div>';
                    return chooseThis;
                };

                let translates = {
                    clear: response['BUTTON.CLEAR'],
                    update: response['BUTTON.UPDATE'],
                    all: response['BUTTON.ALL'],
                    serverTimeout: response['SERVER_TIMEOUT'],
                    serverError: response['SERVER_ERROR'],
                    search: response['FILTER']
                },
                    aoColumns = [
                        { 'data': 'Name', 'class': 'verticalMiddle' },
                        { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
                    ],
                    columnDefs = [
                        { 'targets': [-1], 'orderable': false },
                        { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
                    ];

                this.tabelaUsers = Functions.datatablesWithDataSet('#tabelaWorkflowUsers', this.usersList, translates, aoColumns, columnDefs,
                    ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

                this.tabelaUsers.on('draw', function ()
                {
                    let btnsChoose = document.getElementsByClassName('tableUsers-choose');
                    for (let chooseThis of Array.from(btnsChoose))
                    {
                        let id = chooseThis.id;
                        // selecionar checkbox que estiverem na lista
                        if (that.usersIDsList.length > 0)
                        {
                            that.usersIDsList.forEach((idLista: any) =>
                            {
                                let idTabela = 'u' + idLista;
                                if (idTabela === id)
                                {
                                    let element = <HTMLInputElement>document.getElementById(id);
                                    element.checked = true;
                                }
                            });
                        }
                        // adicionar à checkbox o evento click
                        chooseThis.addEventListener('click', function (ev: any)
                        {
                            that.obterUsersID(this.id);
                        });
                    }
                });
            });
    }


    obterUsersID(idRecebido: any)
    {
        // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
        let element = <HTMLInputElement>document.getElementById(idRecebido);
        // retirar "u" do id
        let id = idRecebido.split('u').join('');

        if (this.usersIDsList.length > 0)
        {
            let idEncontrado = this.usersIDsList.find((r: any) => r === parseInt(id, null));
            let index = this.usersIDsList.findIndex((r: any) => r === parseInt(id, null));

            if (element.checked && !idEncontrado)
            {
                this.usersIDsList.push(parseInt(id, null));
                this.addUserToEmailsList(id);
                
            }
            if (!element.checked && idEncontrado)
            {
                this.usersIDsList.splice(index, 1);
                this.removeUserEmailsList(idEncontrado);
            }
        } 
        else
        {
            if (element.checked)
            {
                this.usersIDsList.push(parseInt(id, null));
                this.addUserToEmailsList(id);
            }
        }
    }
    
    addUserToEmailsList(idSelected)
    {
        if(idSelected > 0)
        {
            let user = this.usersList.find(user => user.ID == idSelected) as User;
            if(user && user.Email)
            {
                this.emails.push(user.Email); 
                this.createEmailStringCSV();
            }
        }
    }

    removeUserEmailsList(idSelected)
    {
        if(idSelected > 0)
        {
            let user = this.usersList.find(user => user.ID == idSelected) as User;
            if(user && user.Email &&  this.emails && this.emails.length > 0)
            {
                let indexToRemove = this.emails.indexOf(this.emails.find(email => email == user.Email));
                this.emails.splice(indexToRemove,1);
                this.createEmailStringCSV();
            }
        }
    }

    createEmailStringCSV()
    {
        this.emailsCSV = '';
        this.emails.forEach(email => 
        {
            this.emailsCSV += email+";"
        })
    }


    save()
    {
        const regexExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        let valid: boolean = true;
        if(this.emailsCSV)
        {
            //Elimina todos os espaços em branco
            this.emailsCSV = this.emailsCSV.trim();
            //Se o Ultimo char for igual ; elimina do CSV para não criar um posição no array a mais
            if(this.emailsCSV.substring(this.emailsCSV.length -1) == ';')
            {
                this.emailsCSV = this.emailsCSV.slice(0,-1);
            }
            //Divie a String pelo ;
            let emailsSplited = this.emailsCSV.split(";");

            //Valida se a string CSV tem emails válidos
            emailsSplited.forEach(email => 
            {
               //Se não tiver emails Válidos envia um erro ao utilizador
               if(!regexExpression.test(email))
               {
                    Functions.gritter(this.translateValueService.get("EMAIL_INVALID_ON_INPUT")+email,"warning");
                    valid = false;
               }
            });

            if(valid)
            {
                this.dialogRef.close(this.emailsCSV);
            }
            
        }
    }

    ngOnDestroy(): void
    {

    }
}
