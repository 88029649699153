import { Optional } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CURRENCY_DECIMAL } from '../constants/global';

export class AccountingConfigList {
  constructor(
    @Optional() public Details: AccountingConfig[] = null
  ) { }
}

export class AccountingConfig {
  constructor(
    public ID: number = 0, // não permite nulls
    public Context: string = '', // não permite nulls
    public EntityID: number = 0, // não permite nulls
    @Optional() public Percentagem: number = 0,
    @Optional() public ValorFixo: number = null,
    @Optional() public CentroCusto: string = null,
    @Optional() public Refaturar: boolean = false,
    @Optional() public Tipo: string = 'conta',
    @Optional() public ContaGasto: string = null,
    @Optional() public GrupoIVA: string = null,
    @Optional() public CodAtividade: string = null,
    @Optional() public CodIniciativa: string = null,
    @Optional() public TipoRegistoImob: string = null,
    @Optional() public CodLivroAmort: string = null,
    @Optional() public Observacoes: string = null,
    @Optional() public CodGrupoContabilizacao: string = null,
    @Optional() public CodRetencao: string = null,
    @Optional() public TipoTratamentoIva: string = null,
    @Optional() public CodMensualizacao: string = null,

    // *********** VARIAVEIS DO FORM (AUXILIARES) ***********
    @Optional() public Percentage_form: string = null,
    @Optional() public FixedValue_form: string = null

  ) { }
}

export class TransformModelAC {

  transformObject(accountingConfigObject: AccountingConfig = null, translLanguage: string = 'pt') {
    let decimalPipe = new DecimalPipe(translLanguage);

    accountingConfigObject.Percentage_form = accountingConfigObject.Percentagem !== null ? decimalPipe.transform(accountingConfigObject.Percentagem, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    accountingConfigObject.FixedValue_form = accountingConfigObject.ValorFixo !== null ? decimalPipe.transform(accountingConfigObject.ValorFixo, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;

    return accountingConfigObject;
  }
  revertObjects(accountingConfigObjs: any = null): AccountingConfig[] {
    if (accountingConfigObjs && accountingConfigObjs.length > 0) {
        accountingConfigObjs.forEach((cc: any) => {
        cc = this.revertObject(cc);
      });
    }
    return accountingConfigObjs;
  }
  revertObject(accountingConfigObj: any = null) {
    if (!accountingConfigObj) {
      return;
    }
    let accountingConfigObject: AccountingConfig = accountingConfigObj;

    // percentagem
    accountingConfigObject.Percentagem = accountingConfigObj.Percentagem !== null ? ((accountingConfigObj.Percentagem).toString()).revertDecimal() : null;
    // valor fixo
    accountingConfigObject.ValorFixo = accountingConfigObj.ValorFixo !== null ? ((accountingConfigObj.ValorFixo).toString()).revertDecimal() : null;

    return accountingConfigObject;
  }
}
