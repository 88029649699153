export class DashboardPanelChart {
  public static empty(): DashboardPanelChart {
    return new DashboardPanelChart(0, 0, null, null, 0);
  }

  constructor(
    public ID: number,
    public DashboardPanelID: number,
    public ChartType: string,
    public ChartSize: string,
    public ChartOrder: number,
    public ChartColors?: string,
    public ChartIcon?: string,
    public ChartQuery?: string,
    public ChartUrl?: string
  ) { }
}

export const ChartTypes: any[] = [
  { Label: 'Line Chart', Value: 'line' },
  { Label: 'Bar Chart', Value: 'bar' },
  { Label: 'Radar Chart', Value: 'radar' },
  { Label: 'Pie Chart', Value: 'pie' },
  { Label: 'Polar Area Chart', Value: 'polarArea' },
  { Label: 'Doughnut Chart', Value: 'doughnut' },
  { Label: 'Counter', Value: 'counter' },
  { Label: 'Horizontal Bar', Value: 'horizontalBar'}
];
