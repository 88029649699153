
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { GenericTableComponent } from './generic-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [GenericTableComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatInputModule,
        MatTableModule,
        MatLegacyFormFieldModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule.forChild(),
    ],
    exports: [
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        GenericTableComponent
    ],
    providers: []
})
export class GenericTableComponentModule {}