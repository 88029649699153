import { Component, Inject, NgZone } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './message-modal.html'
})
export class MessageModalComponent {
  title: string;
  message: string;
  closeWithZone: boolean = false;

  constructor(public dialogRef: MatDialogRef<MessageModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private zone: NgZone) {
    this.title = data.title;
    this.message = data.message;
    this.closeWithZone = data.closeWithZone;
  }


  closeDialog()
  {
    if(this.closeWithZone)
    {
      this.zone.run(() => {
        this.dialogRef.close();
      });
    }
    else
    {
      this.dialogRef.close();
    }
    
  }
}
