import { Pipe, PipeTransform } from '@angular/core';
import { DATE_FORMAT, DATE_HOUR_FORMAT } from '../constants/global';

@Pipe({ name: 'formatDate' })

export class DatePipe implements PipeTransform {

  transform(value: string, args: string[]): any {
    // args - index 1 -> tipo de tratamento; index 2 ->separador a utilizar; index 3-> separador a substituir; index 4 -> se incluir horas(true se incluir, false ou nada se nao inclui)
    // tipo de tratamento -> 1 - vem em formato /Date(1452534648620)/; 2- se DateTime enviar vazio; 3 - substituir separador das datas; 4 - Converte de String para Date

    if (!value) { return value; }

    if (args[0] === '1') {
      let pattern = /Date\(([^)]+)\)/;
      let results = pattern.exec(value);
      if (parseFloat(results[1]) > 0) {
        let dt = new Date(parseFloat(results[1]));
        let dt_day = (dt.getDate().toString()).length > 1 ? dt.getDate() : '0' + dt.getDate();
        let dt_month = (dt.getMonth() + 1).toString();
        dt_month = dt_month.length > 1 ? dt_month : '0' + dt_month;
        if (args[3]) {
          // incluir horas
          let dt_seconds = (dt.getSeconds().toString()).length > 1 ? dt.getSeconds() : '0' + dt.getSeconds();
          let dt_mins = (dt.getMinutes().toString()).length > 1 ? dt.getMinutes() : '0' + dt.getMinutes();
          let dt_hours = (dt.getHours().toString()).length > 1 ? dt.getHours() : '0' + dt.getHours();
          return dt_day + args[1] + dt_month + args[1] + dt.getFullYear() + ' ' + dt_hours + ':' + dt_mins + ':' + dt_seconds;
        }
        return dt_day + args[1] + dt_month + args[1] + dt.getFullYear();
      }
      return '';
    }

    if (args[0] === '2') {
      if (value === '01-01-0001') {
        return '';
      }
    }

    if (args[0] === '3') {
      return value.replace(args[2], args[1]);
    }

    if (args[0] === '4') {
      if (value) {
        let format = args[3] ? DATE_HOUR_FORMAT.split(' ')[0].split(args[1]) : DATE_FORMAT.split(args[1]);
        let current = args[3] ? value.split(' ')[0].split(args[1]) : value.split(args[1]);

        let yearIndex = format.findIndex(r => r === 'YYYY');
        let monthIndex = format.findIndex(r => r === 'MM');
        let dayIndex = format.findIndex(r => r === 'DD');

        // incluir horas
        if (args[3]) {
          let formatHour = DATE_HOUR_FORMAT.split(' ')[1].split(':');
          let currentHour = value.split(' ')[1].split(':');
          for (let index = 0; index < formatHour.length; index++) {
            format.push(formatHour[index]);
            current.push(currentHour[index]);
          }


          let hourIndex = format.findIndex(r => r === 'HH');
          let minuteIndex = format.findIndex(r => r === 'mm');

          return new Date(+current[yearIndex], (+current[monthIndex]) - 1, +current[dayIndex], +current[hourIndex], +current[minuteIndex]);
        }
        return new Date(+current[yearIndex], (+current[monthIndex]) - 1, +current[dayIndex]);
      }
      return null;
    }

    return value;

  }

  addWorkingDays(value: Date, addDays: number): Date {
    let newDate: Date = new Date(value);
    newDate.setHours(0, 0, 0, 0);

    if (addDays > 0) {
      while (addDays > 0) {
        newDate.setDate(newDate.getDate() + 1);
        if (this.IsWorkingDay(newDate)) {
          addDays--;
        }
      }
    } else if (addDays < 0) {
      while (addDays < 0) {
        newDate.setDate(newDate.getDate() - 1);
        if (this.IsWorkingDay(newDate)) {
          addDays++;
        }
      }
    }
    return newDate;
  }

  private IsWorkingDay(date: Date): boolean {

    let holidays: Array<number> = new Array<number>();
    holidays.push(new Date(date.getFullYear(), 0, 1).getTime()); // ano novo
    holidays.push(new Date(date.getFullYear(), 3, 25).getTime()); // 25 abril
    holidays.push(new Date(date.getFullYear(), 4, 1).getTime()); // dia trabalhador
    // holidays.push(new Date(date.getFullYear(), 5, 22)); // dia da cidade
    holidays.push(new Date(date.getFullYear(), 5, 10).getTime()); // dia portugal
    holidays.push(new Date(date.getFullYear(), 7, 15).getTime()); // assunção nossa senhora
    holidays.push(new Date(date.getFullYear(), 9, 5).getTime()); // implantação da republica
    holidays.push(new Date(date.getFullYear(), 10, 1).getTime()); // dia de todos os santos
    holidays.push(new Date(date.getFullYear(), 11, 1).getTime()); // restauração da independencia
    holidays.push(new Date(date.getFullYear(), 11, 8).getTime()); // imaculada conceicao
    holidays.push(new Date(date.getFullYear(), 11, 25).getTime()); // natal

    let pascoa: Date = this.CalculateEasterDate(date.getFullYear());
    let sextaSanta: Date = new Date(pascoa);
    sextaSanta.setDate(pascoa.getDate() - 2);
    let corpoDeus: Date = new Date(pascoa);
    corpoDeus.setDate(pascoa.getDate() + 60);
    holidays.push(pascoa.getTime());
    holidays.push(sextaSanta.getTime());
    holidays.push(corpoDeus.getTime());

    // Valida se a data é num fim de semana ou num feriado
    return date.getDay() !== 0 && date.getDay() !== 6 && holidays.indexOf(date.getTime()) < 0;
  }

  private CalculateEasterDate(year: number): Date {
    let nRest: number = (year % 19) + 1;
    let easterDate: Date = new Date();
    switch (nRest) {
        case 1: easterDate = new Date(year, 3, 14, 0, 0, 0, 0); break;
        case 2: easterDate = new Date(year, 3, 3, 0, 0, 0, 0); break;
        case 3: easterDate = new Date(year, 2, 23, 0, 0, 0, 0); break;
        case 4: easterDate = new Date(year, 3, 11, 0, 0, 0, 0); break;
        case 5: easterDate = new Date(year, 2, 31, 0, 0, 0, 0); break;
        case 6: easterDate = new Date(year, 3, 18, 0, 0, 0, 0); break;
        case 7: easterDate = new Date(year, 3, 8, 0, 0, 0, 0); break;
        case 8: easterDate = new Date(year, 2, 28, 0, 0, 0, 0); break;
        case 9: easterDate = new Date(year, 3, 16, 0, 0, 0, 0); break;
        case 10: easterDate = new Date(year, 3, 5, 0, 0, 0, 0); break;
        case 11: easterDate = new Date(year, 2, 25, 0, 0, 0, 0); break;
        case 12: easterDate = new Date(year, 3, 13, 0, 0, 0, 0); break;
        case 13: easterDate = new Date(year, 3, 2, 0, 0, 0, 0); break;
        case 14: easterDate = new Date(year, 2, 22, 0, 0, 0, 0); break;
        case 15: easterDate = new Date(year, 3, 10, 0, 0, 0, 0); break;
        case 16: easterDate = new Date(year, 2, 30, 0, 0, 0, 0); break;
        case 17: easterDate = new Date(year, 3, 17, 0, 0, 0, 0); break;
        case 18: easterDate = new Date(year, 3, 7, 0, 0, 0, 0); break;
        case 19: easterDate = new Date(year, 2, 27, 0, 0, 0, 0); break;
    }
    for (let index = 1; index <= 13; index++) {
      easterDate.setDate(easterDate.getDate() + 1);
      if (easterDate.getDay() === 0) {
          return easterDate;
      }
    }

    return new Date();
  }

}
