import { Filter, FilterColumnTable } from '../modules/dockmodal.module';

export class ChooseModalParam {
  constructor(
    // lista de dados, caso envie os dados já num array
    public List: Array<any>,

    // accao do controlador, caso seja para ir buscar os dados
    public ActionTable: string,

    // nome para colocar na modal
    public ModalTitle: string,

    // Colunas da datatable
    public Columns: Array<any>,

    // definições das colunas da datatable
    public ColumnsDefs?: Array<any>,

    // se for diferente de null é porque vai ter filtros
    public Filters?: Array<Filter>,

    // coluna a ordenar caso nao seja a primeira
    public OrderColumnIndex?: number,

    // se diferente de null tem a definição para os filtros das colunas caso sejam diferentes de um input normal
    public FilterColumns?: Array<FilterColumnTable>,

    // extra parametros para o controlador, que nao vao ser mostrados ao utlizador
    public ActionParams?: Array<Filter>,

    // id da opção que previamente está checada, pode ir a null
    public CheckID?: string,

    // se estiver a true vai adicionar um botao para permitir fechar sem selecionar nenhum
    public AllowNone?: boolean,

    // colocar qual a coluna a usar como identificador os dados não tenha a coluna ID
    public OtherID?: string,
    // ordenação da coluna, asc ou desc
    public orderBy?: string,
    // expandir os filtros
    public filtersExpanded?: boolean,

    public filterDate? : boolean,

    public Text? : string

  ) { }
}