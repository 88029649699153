import { ChooseModalComponent } from '../components/shared/choose-modal/choose-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Injectable } from '@angular/core';
import { SelectElementDataChild, FiltersType, FilterFamily, Filter, FilterGeral, FilterSelect, FilterEntity, DockModalFunctions, SelectElementData, TableElementData } from './dockmodal.module';
import { ChooseModalParam } from '../models/choose-modal-param';

@Injectable()
export class DockModalArticlesFunctions {
  // tslint:disable-next-line:no-empty
  constructor() { }
  _dockFunc: DockModalFunctions = new DockModalFunctions;

  createFilterFamily(resultList: Array<any>): Array<SelectElementData> {
    let families: Array<SelectElementData> = new Array<SelectElementData>();
    if (resultList.length > 0) {
      resultList.forEach(fam => {
        families.push(new SelectElementData(fam.ID, fam.Name));
      });
    }
    return families;
  }
  createFilterSubFamilyOrSubSubFamily(resultList: Array<any>): Array<SelectElementDataChild> {
    let families: Array<SelectElementDataChild> = new Array<SelectElementDataChild>();
    if (resultList.length > 0) {
      resultList.forEach(fam => {
        families.push(new SelectElementDataChild(fam.ID, fam.Name, fam.ParentID));
      });
    }
    return families;
  }
  createListFilterCategory(resultList: Array<any>): Array<SelectElementData> {
    let categories: Array<SelectElementData> = new Array<SelectElementData>();
    if (resultList.length > 0) {
      resultList.forEach(cat => {
        categories.push(new SelectElementData(cat.ID, cat.Name));
      });
    }
    return categories;
  }
  createFilterEntity(resultList: Array<any>): Array<TableElementData> {
    let entities: Array<TableElementData> = new Array<TableElementData>();
    if (resultList.length > 0) {
      resultList.forEach(enti => {
        entities.push(new TableElementData(enti.ID, enti.Name, enti.TaxNumber));
      });
    }
    return entities;
  }

  /**
   * Criar a dockModal dos produtos, já com alguns filtros predefinidos
   * @param  {string} title Titulo traduzido a colocar na janela Dock
   * @param  {Array<string>} translations Array com resultado do serviço de traduções com as string necessárias traduzidas
   * @param  {string} idTable id a colocar na datatable (string tem de conter o #)
   * @param  {string} dataUrl url que vai utilizar para ir buscar os dados ao servidor
   * @param  {string} aliasUrl variável global ALIAS
   * @param  {string} language lingua actual (translateValueService.translateLanguage)
   * @param  {string} postGet se 'POST' ou 'GET'
   * @param  {any} data pode ser null, um objeto ou uma função que devolve um objeto, o objeto tem de ter uma key que é o nome do campo do controlador e o value que é o valor a enviar
   * @param  {Array<any>} aoColumns colunas para a datatables
   * @param  {Array<any>} columnDefs definição das colunas da datatables
   * @param  {(number|null)} maxSelectLines máximo de linhas que pode selecionar
   * @param  {string} idDropZone id do elemento para onde o utilizador pode arrastar as linhas que selecionou
   * @param  {(data:any,component:any)=>any} funcAdd função quando clica no botão adicionar
   * @param  {(data:any,component:any)=>any} funcDoubleClick função quando faz duplo clique na linha
   * @param  {(data:any,component:any)=>any} funcDrag função quando faz o arrastar da linha
   * @param  {any} component this do componente que está a chamar
   * @param  {boolean} initOpen se os filtros começam colapsados ou expandidos (true= expandidos);
   * @param  {FilterGeral} referenceFilter? null se não quer filtro de referência, caso contrario tem de enviar um FilterGeral (o parâmetro Name se não for enviado está predefinido para usar a 'REFERENCE')
   * @param  {FilterGeral} denominationFilter? null se não quer filtro de denominação, caso contrario tem de enviar um FilterGeral (o parâmetro Name se não for enviado está predefinido para usar a 'DENOMINATION')
   * @param  {FilterFamily} familyFilter? null se não quer filtro de famílias, subfamílias e subSubfamilias, caso contrario tem de enviar um FilterFamily (o parâmetro Name se não for enviado está predefinido para usar a 'FAMILY')
   * @param  {FilterSelect} categoryFiltro? null se não quer filtro de categorias, caso contrario tem de enviar um FilterSelect (o parâmetro Name se não for enviado está predefinido para usar a 'CATEGORY')
   * @param  {FilterEntity} entityFilter? null se não quer filtro de categorias, caso contrario tem de enviar um FilterEntity (o parâmetro Name se não for enviado está predefinido para usar a 'PROVIDER')
   * @param  {MatDialog} dialogMD? variável declarada no construtor do componente do tipo MatDialog
   * @returns Promise
   */
  createDockArtigo(title: string, translations: Array<string>, idTable: string, dataUrl: string, aliasUrl: string, language: string, postGet: string, data: any, aoColumns: Array<any>,
    columnDefs: Array<any>, maxSelectLines: (number | null), idDropZone: string, funcAdd: (data: any, component: any) => any, funcDoubleClick: (data: any, component: any) => any, funcDrag: (data: any, component: any) => any, component: any,
    initOpen: boolean, referenceFilter?: FilterGeral, denominationFilter?: FilterGeral, familyFilter?: FilterFamily, categoryFiltro?: FilterSelect, entityFilter?: FilterEntity, dialogMD?: MatDialog, dataSet?:any, filterID?:any): Promise<any> {
    let filters: Array<Filter> = new Array<Filter>();
    let label = '', subFamAddID = 'sub', subSubFamAddID = 'subsub',
      subFamilyExists = familyFilter && familyFilter.SelectElementsSub && familyFilter.SelectElementsSub.length > 0,
      subSubFamilyExists = familyFilter && familyFilter.SelectElementsSubSub && familyFilter.SelectElementsSubSub.length > 0;

    if (referenceFilter) {
      // se tem o filtro de referencia
      label = referenceFilter.Name ? referenceFilter.Name : translations['REFERENCE']; // caso nao envie label especifica
      filters.push(new Filter(label, FiltersType.String, referenceFilter.ID, referenceFilter.IDToFind));
    }
    if (denominationFilter) {
      // se tem o filtro de denominacao
      label = denominationFilter.Name ? denominationFilter.Name : translations['DENOMINATION']; // caso nao envie label especifica
      filters.push(new Filter(label, FiltersType.String, denominationFilter.ID, denominationFilter.IDToFind));
    }
    if (familyFilter) {
      // se tem o filtro de familia
      label = familyFilter.Name ? familyFilter.Name : translations['FAMILY']; // caso nao envie label especifica
      let whenChangeFam = function (evt) {
        // funcao para quando muda a familia por causa das dependencias das subFamilias
        let famValue = evt.currentTarget.value;
        // limpar subfamilia
        let selectSubFam = <HTMLSelectElement>document.getElementById(subFamAddID + familyFilter.ID);
        if (selectSubFam) {
          // retirar options do select de subfamilias
          selectSubFam.options.length = 0;
          // colocar uma em branco
          selectSubFam.options[selectSubFam.options.length] = new Option('', null);
        }

        // limpar subSubfamilia
        let selectSubSubFam = <HTMLSelectElement>document.getElementById(subSubFamAddID + familyFilter.ID);
        if (selectSubSubFam) {
          // retirar options do select de subfamilias
          selectSubSubFam.options.length = 0;
          // colocar uma em branco
          selectSubSubFam.options[selectSubSubFam.options.length] = new Option('', null);
        }


        // filtrar subfamilia
        if (famValue && familyFilter.SelectElementsSub && familyFilter.SelectElementsSub.length > 0) {
          let subFamilias = familyFilter.SelectElementsSub.filter((subf: SelectElementDataChild) => {
            return subf.FatherValue.toString() === famValue.toString();
          });
          // colocar novas options
          for (let index = 0; index < subFamilias.length; index++) {
            const subFamilia = subFamilias[index];
            selectSubFam.options[selectSubFam.options.length] = new Option(subFamilia.Text, subFamilia.Value);
          }
        }
      };
      let whenChangeSubFam = function (evt) {
        // funcao para quando muda de subfamilia, por causa das subSubFamilias
        let subFamValue = evt.currentTarget.value;

        // limpar subSubfamilia
        let selectSubSubFam = <HTMLSelectElement>document.getElementById(subSubFamAddID + familyFilter.ID);
        if (selectSubSubFam) {
          // retirar options do select de subfamilias
          selectSubSubFam.options.length = 0;
          // colocar uma em branco
          selectSubSubFam.options[selectSubSubFam.options.length] = new Option('', null);
        }

        // filtrar subsubfamilia
        if (subFamValue && familyFilter.SelectElementsSubSub && familyFilter.SelectElementsSubSub.length > 0) {
          let subSubFamilias = familyFilter.SelectElementsSubSub.filter((subSubf: SelectElementDataChild) => {
            return subSubf.FatherValue.toString() === subFamValue.toString();
          });
          // colocar novas options
          for (let index = 0; index < subSubFamilias.length; index++) {
            const subSubFamilia = subSubFamilias[index];
            selectSubSubFam.options[selectSubSubFam.options.length] = new Option(subSubFamilia.Text, subSubFamilia.Value);
          }
        }
      };
      // adicionar o filtro da familia e o evento de change
      let changeEventF = subFamilyExists ? { 'event': 'change', 'function': whenChangeFam } : null;
      filters.push(new Filter(label, FiltersType.Select, familyFilter.ID, familyFilter.IDToFind, familyFilter.SelectElements, null, changeEventF));
      if (subFamilyExists) {
        let changeEventSF = subSubFamilyExists ? { 'event': 'change', 'function': whenChangeSubFam } : null;
        filters.push(new Filter('', FiltersType.Select, subFamAddID + familyFilter.ID, subFamAddID + familyFilter.IDToFind, [], null, changeEventSF));
      }
      if (subSubFamilyExists) {
        filters.push(new Filter('', FiltersType.Select, subSubFamAddID + familyFilter.ID, subSubFamAddID + familyFilter.IDToFind, []));
      }
    }
    if (categoryFiltro) {
      // se tem o filtro de categoria
      label = categoryFiltro.Name ? categoryFiltro.Name : translations['CATEGORY'];  // caso nao envie label especifica
      filters.push(new Filter(label, FiltersType.Select, categoryFiltro.ID, categoryFiltro.IDToFind, categoryFiltro.SelectElements));
    }

    if (entityFilter) {
      let initValueName = '';
      if (entityFilter.InitialValue) {
        initValueName = entityFilter.SelectElements.filter((ent) => ent.ID.toString() === entityFilter.InitialValue)[0].Name;
      }
      // se tem o filtro de entidade
      let whenClickEntity = function () {
        let aoColumnsChoose = [
          { 'data': 'TaxNumber', 'class': 'verticalMiddle', 'title': translations['NIF'] },
          { 'data': 'Name', 'class': 'verticalMiddle', 'title': translations['NAME'] },
          { 'data': 'ID', 'class': 'verticalMiddle', 'visible': false, 'orderable': false }
        ],
          // selectValue = entityFilter.InitialValue ? entityFilter.InitialValue.toString() : null,
          selectValue = (<HTMLInputElement>document.getElementById(entityFilter.ID + 'extraID_Aux')).value,
          dialogRef = dialogMD.open(ChooseModalComponent,
            {
              data: // dados que vai enviar para o componente da modal
                new ChooseModalParam(entityFilter.SelectElements, null, label, aoColumnsChoose, null, null, null, null, null, selectValue, true),
              disableClose: true, // nao permitir fechar modal com escape ou clique fora
            });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            let inputEl = (<HTMLInputElement>document.getElementById(entityFilter.ID + 'extra'));
            let inputElID = (<HTMLInputElement>document.getElementById(entityFilter.ID + 'extraID_Aux'));
            if (result === -1) { // é para limpar
              // tem de colocar o valor inicial
              // if (entityFilter.InitialValue) {
              //   inputEl.value = initValueName;
              //   inputElID.value = entityFilter.InitialValue.toString();
              // } else
              {
                inputEl.value = '';
                inputElID.value = '';
              }
            } else {
              // tem de alterar o input do nome e do id
              let entidadeEscolhida = entityFilter.SelectElements.filter((ent) => ent.ID.toString() === result.toString())[0];
              inputEl.value = entidadeEscolhida.Name;
              inputElID.value = entidadeEscolhida.ID;
              // colocar caso nao tenha as classe de withText para a label ficar pequenita
              let labelInput = document.querySelector('label[for="' + entityFilter.ID + 'extra' + '"]');
              labelInput.classList.add('withText');
              labelInput.parentElement.classList.add('withText');
            }
          }
        });
      };

      label = entityFilter.Name ? entityFilter.Name : translations['PROVIDER'];  // caso nao envie label especifica
      filters.push(new Filter(label, FiltersType.String, entityFilter.ID + 'extra', entityFilter.IDToFind, null, initValueName,
        {
          'event': 'click',
          'function': whenClickEntity
        }));
    }
    // criar os elementos dom dos filtros
    this._dockFunc.buildElementsFilters(idTable, filters, initOpen, null, null, translations['FILTERS'],filterID);

    if (entityFilter) { // como a entidade é escolhida pela chooseModal, é necessario saber o ID e o nome
      // vai acrescentar um novo input escondido para guardar o ID
      let inputEntidade = (<HTMLInputElement>document.getElementById(entityFilter.ID + 'extra'));
      let valorInitialInput = entityFilter.InitialValue ? 'value="' + entityFilter.InitialValue + '"' : '';
      inputEntidade.insertAdjacentHTML('afterend', '<input hidden id="' + entityFilter.ID + '" ' + valorInitialInput + '/>');
      inputEntidade.insertAdjacentHTML('afterend', '<input hidden id="' + entityFilter.ID + 'extraID_Aux' + '" ' + valorInitialInput + '/>');
      // colocar o input do nome da entidade como escondido e colocar-lhe um botao ao lado
      inputEntidade.readOnly = true;
      let style = inputEntidade.getAttribute('style');
      inputEntidade.setAttribute('style', style + ';background-color: initial; width: calc(100% - 30px) !important;');
      // por estar como readOnly a label estava a ficar em cinzento, necessario corrigir
      let labelInput = document.querySelector('label[for="' + entityFilter.ID + 'extra' + '"]');
      labelInput.setAttribute('style', 'color: inherit;');
      // colocar o input mais pequeno para caber o icon
      // inputEntidade.width = '75%';
      inputEntidade.setAttribute('style', 'width: 75%;');
      inputEntidade.insertAdjacentHTML('afterend', '<i class="fas fa-external-link-alt" style="margin-left: 2px;" onclick="document.getElementById(\'' + entityFilter.ID + 'extra' + '\').click()"></i>');


      // como o campo do fornecedor é obrigatiorio, envia o valor inicial
      document.getElementById(entityFilter.ID + 'extra').addEventListener('change', function (ev) {
        // se tiver limpo os campos
        if ((<HTMLInputElement>this).value.toString().length === 0) {
          // if (entityFilter.InitialValue) {
          //   let initValueName = entityFilter.SelectElements.filter((ent) => ent.ID.toString() === entityFilter.InitialValue)[0].Name;
          //   (<HTMLInputElement>document.getElementById(entityFilter.ID)).value = initValueName;
          //   (<HTMLInputElement>document.getElementById(entityFilter.ID + 'extraID')).value = entityFilter.InitialValue.toString();
          // } else
          {
            (<HTMLInputElement>document.getElementById(entityFilter.ID + 'extraID_Aux')).value = '';
          }
        }
      });
    }

    // adicionar aos parametros que já envia para o controlador estes novos dos filtros
    let novoData = function () {
      let objToServer;

      if (typeof data === 'function') {
        objToServer = data();
      } else {
        objToServer = data;
      }

      if (referenceFilter) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + referenceFilter.ID)).value;
        let idFind = referenceFilter.IDToFind ? referenceFilter.IDToFind : referenceFilter.ID;
        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInput.toString().length !== 0) {
          objToServer[idFind] = valueInput;
        } else {
          delete objToServer[idFind];
        }
      }
      if (denominationFilter) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + denominationFilter.ID)).value;
        let idFind = denominationFilter.IDToFind ? denominationFilter.IDToFind : denominationFilter.ID;
        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInput.toString().length !== 0) {
          objToServer[idFind] = valueInput;
        } else {
          delete objToServer[idFind];
        }
      }
      let idFindFamilia = '';
      if (familyFilter) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + familyFilter.ID)).value;
        let idFind = familyFilter.IDToFind ? familyFilter.IDToFind : familyFilter.ID;
        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInput.toString().length !== 0) {
          objToServer[idFind] = valueInput;
        } else {
          delete objToServer[idFind];
        }
        idFindFamilia = idFind;
      }

      if (subFamilyExists) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + subFamAddID + familyFilter.ID)).value;
        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInput.toString().length !== 0) {
          objToServer[idFindFamilia] = valueInput;
        }
      }
      if (subSubFamilyExists) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + subSubFamAddID + familyFilter.ID)).value;
        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInput.toString().length !== 0) {
          objToServer[idFindFamilia] = valueInput;
        }
      }
      if (categoryFiltro) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + categoryFiltro.ID)).value;
        let idFind = categoryFiltro.IDToFind ? categoryFiltro.IDToFind : categoryFiltro.ID;
        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInput.toString().length !== 0) {
          objToServer[idFind] = valueInput;
        } else {
          delete objToServer[idFind];
        }
      }
      if (entityFilter) {
        let valueInput = (<HTMLInputElement>document.querySelector('#' + entityFilter.ID + 'extra')).value;
        let idFind = entityFilter.IDToFind ? entityFilter.IDToFind : entityFilter.ID;
        // objToServer[idFind] = valor;
        // vai usar o ID e nao o nome para enviar para o controlador
        let valueInputID = (<HTMLInputElement>document.querySelector('#' + entityFilter.ID + 'extraID_Aux')).value;
        (<HTMLInputElement>document.querySelector('#' + entityFilter.ID)).value = valueInputID;

        if (valueInput && valueInput !== 'undefined' && valueInput !== 'null' && valueInputID.toString().length !== 0) {
          objToServer[idFind] = valueInputID;
        } else {
          objToServer[idFind] = '0';
        }
      }
      return objToServer;
    };
    return this._dockFunc.createDock(title, translations, idTable, dataUrl, dataSet, aliasUrl, language, postGet, novoData, aoColumns, columnDefs, maxSelectLines, idDropZone,
      funcAdd, funcDoubleClick, funcDrag, component, false);
  }
}

