import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { User, UserIban } from '../models/user';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { Role } from '../models/role';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { CONTEXT_USER } from './../constants/global';

@Injectable()
export class UserService {
  private _controller: string = 'User';
  private _partners: Array<any>;
  private _allowedRoles: Role[];

  filterUsersByRoles: number[] = [];
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);
  datatableParametersContacts: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null], 10, [[4, 'asc']], 0);

  //Settings
  showLocation: boolean;
  showEmailManagement: boolean;
  showTabLocations: boolean;
  showMatriculaInput: boolean;
  showUserNotes: boolean;
  showSalesOffices: boolean;
  showTaxNumber: boolean;
  private showSectionApprovalCode: boolean; //Valida se mostra o Campo SectionApprovalCode

  columnInVisible: Array<number>;
  private _userIBANs: Array<any>;
  constructor(private http: HttpClientCustom,
    private _masterService: MasterService,
    private _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) { }

  get(id: number, partnerID: number, menuID?: string, getLocations: boolean = false, getFieldsExtensions = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&partnerID=' + partnerID + '&getLocations=' + getLocations + '&getFieldsExtension=' + getFieldsExtensions + (menuID ? '&menuID=' + menuID : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: User, partnerID: number, photo?: File, menuID?: string): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData(); // necessario criar um formData para conseguir enviar o ficheiro

    formData.append('menuID', JSON.stringify(menuID));
    formData.append('user', JSON.stringify(model));
    formData.append('roles', JSON.stringify(model.roles));
    formData.append('partnerID', JSON.stringify(partnerID));
    if (photo) {
      formData.append('photo', photo, photo.name); // vai adicionar ao formulario o ficheiro escolhido
    }

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: User, partnerID: number, hasPhoto: boolean, photo?: File, menuID?: string): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData(); // necessario criar um formData para conseguir enviar o ficheiro

    formData.append('menuID', JSON.stringify(menuID));
    formData.append('user', JSON.stringify(model));
    formData.append('roles', JSON.stringify(model.roles));
    formData.append('partnerID', JSON.stringify(partnerID));
    formData.append('hasPhoto', JSON.stringify(hasPhoto));
    if (photo) {
      formData.append('photo', photo, photo.name); // vai adicionar ao formulario o ficheiro escolhido
    }

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number, partnerID?: number, menuID?: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id + (partnerID ? '&partnerID=' + partnerID : '') + (menuID ? '&menuID=' + menuID : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getAll(companyID: number, onlyResponsibles: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAll?companyID=' + companyID + (onlyResponsibles ? '&onlyResponsibles=true' : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  verifyExistence(email: string, partnerID: number, menuID?: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/VerifyExistence?email=' + email + '&partnerID=' + partnerID + (menuID ? '&menuID=' + menuID : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Obter info clientes/fornecedores
  * @param  {number} filterByRole
  * @returns Observable
  */
  getAllCompanyUsers(companyID: number = null, filterRoleID: number = null): Observable<Array<any>> {
    if (this._partners === undefined || (this._partners && this._partners['\'' + companyID + '\''] === undefined)) {

      let request: string = '/GetAll';
      if (companyID > 0) {
        request += '?companyID=' + companyID;
      }
      if (filterRoleID > 0) {
        request += (companyID > 0 ? '&' : '?') + 'filterRole=' + filterRoleID;
      }

      return this.http.get(SERVICE_URL + this._controller + request).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              if (this._partners === undefined) {
                this._partners = [];
              }
              return this._partners['\'' + companyID + '\''] = responseReturn.ReturnStatus.ReturnObject;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._partners['\'' + companyID + '\'']);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get allowedRoles(): Observable<Role[]> {
    if (this._allowedRoles === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetAllowedRoles').pipe(map((responseRaw: any) => {
        try {
          let response = this._masterService.convertToReturnStatusHtml(responseRaw);

          if (response.ReturnStatus.Successfull) {
            this._allowedRoles = response.ReturnStatus.ReturnObject as Role[];
            return this._allowedRoles.sort((n1: Role, n2: Role) => {
              if (n1.Name > n2.Name) {
                return 1;
              }

              if (n1.Name < n2.Name) {
                return -1;
              }

              return 0;
            });
          } else if (!response.IsAuthenticated) {
            document.location.href = document.location.origin;
          } else {
            return [];
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._allowedRoles.sort((n1: Role, n2: Role) => {
        if (n1.Name > n2.Name) {
          return 1;
        }

        if (n1.Name < n2.Name) {
          return -1;
        }
        return 0;
      }));
    }
  }

  /**
   * Devolve as moradas associadas ao utilizador
   * @param  {number} id ID do utilizador
   * @returns Observable
   */
  getUserLocations(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetUserLocations?userID=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Download para Excel da lista de empresas
  */
  downloadExcelUsersList(params: any, companyID: number, filterByRoles: number[]): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelUsersList', { parameters: params, companyID: companyID, filterByRoles: filterByRoles });
  }

  /** Obter todos os IBANs do Utilizador
   * @returns Observable - com o array de dos IBANs do Utilizador ou vazio
   */
  get userIBANs(): Observable<Array<UserIban>> {
    if (this._userIBANs === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetUserIBANs?userID=' + this.authenticationService.session.user.ID).pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._userIBANs = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._userIBANs);
    }
  }

  //SETTINGS
  get_showLocation(defaultValue: boolean): boolean {
    if (typeof this.showLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowLocation');
      if (settingValue !== null) {
        this.showLocation = settingValue;
      } else {
        this.showLocation = defaultValue;
      }
    }
    return this.showLocation;
  }

  get_showEmailManagement(defaultValue: boolean): boolean {
    if (typeof this.showEmailManagement === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowEmailManagement');
      if (settingValue !== null) {
        this.showEmailManagement = settingValue;
      } else {
        this.showEmailManagement = defaultValue;
      }
    }
    return this.showEmailManagement;
  }

  //SETTINGS
  get_showTabLocations(defaultValue: boolean): boolean {
    if (typeof this.showTabLocations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowTabLocations');
      if (settingValue !== null) {
        this.showTabLocations = settingValue;
      } else {
        this.showTabLocations = defaultValue;
      }
    }
    return this.showTabLocations;
  }

  get_showMatriculaInput(defaultValue: boolean): boolean {
    if (typeof this.showMatriculaInput === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'showMatriculaInput');
      if (settingValue !== null) {
        this.showMatriculaInput = settingValue;
      } else {
        this.showMatriculaInput = defaultValue;
      }
    }
    return this.showMatriculaInput;
  }

  get_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ColumVisible');
      this.columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible;
  }

  get_showUserNotes(defaultValue: boolean): boolean {
    if (typeof this.showUserNotes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowUserNotes');
      if (settingValue !== null) {
        this.showUserNotes = settingValue;
      } else {
        this.showUserNotes = defaultValue;
      }
    }
    return this.showUserNotes;
  }

  get_showSalesOffices(defaultValue: boolean): boolean {
    if (typeof this.showSalesOffices === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowSalesOffices');
      if (settingValue !== null) {
        this.showSalesOffices = settingValue;
      } else {
        this.showSalesOffices = defaultValue;
      }
    }
    return this.showSalesOffices;
  }

  get_showTaxNumber(defaultValue: boolean): boolean {
    if (typeof this.showTaxNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowTaxNumber');
      if (settingValue !== null) {
        this.showTaxNumber = settingValue;
      } else {
        this.showTaxNumber = defaultValue;
      }
    }
    return this.showTaxNumber;
  }

  

  get_showSectionApprovalCode(defaultValue: boolean): boolean {
    if (typeof this.showSectionApprovalCode === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('User', 'ShowSectionApprovalCode');
      if (settingValue != null) {
        this.showSectionApprovalCode = settingValue;
      }
      else {
        this.showSectionApprovalCode = defaultValue;
      }
    }
    return this.showSectionApprovalCode;
  }
}
