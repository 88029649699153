import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './confirmation-modal.html'
})
export class ConfirmationModalComponent {
  text: string;
  textClassAlign: string;
  subtext: string;
  alternativeIcon: boolean = false;
  textColor: Array<number> = [0,0,0];

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.text = data.text;
    this.textClassAlign = data.textClassAlign;
    this.subtext = data.subtext;
    this.alternativeIcon = data.alternativeIcon;
    this.textColor = data.textColor;
  }
}
