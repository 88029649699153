// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, AfterViewInit, Optional, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { DateTimePickerDirective } from '../../../directives/datepicker.directive';

// ***__***_________  SERVICOS _________ ***__***
import { HttpClientCustom } from '../../../services/http-client';
import { TranslateValueService } from '../../../services/translate-value.service';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { ALIAS, LANGUAGE, CONTEXT_INVOICE } from '../../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';

// ***__***_________  MODALS _________ ***__***
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { User } from '../../../models/user';
import { AdditionalField } from '../confirmation-with-notes-modal/confirmation-with-notes-modal.component';
import { ConfirmationWithFieldsModalComponent } from '../confirmation-with-fields-modal/confirmation-with-fields-modal.component';
import { Subject } from 'rxjs';
import { s } from '@fullcalendar/core/internal-common';
import { MatLegacyRadioGroup } from '@angular/material/legacy-radio';

declare var Functions: any;

export class StateHistoryModalParam {
  constructor(
    /// accao para ir buscar o historico
    public HistoryAction: string,

    /// accao para ir buscar os steps
    public StatesAction: string,

    /// id do documento que se vai alterar
    @Optional() public DocumentID?: number,

    /// nome a colocar no titulo da modal, se for null = 'STATE_OPTIONS'
    @Optional() public ModalTitle?: string,

    /// se as colunas do historico forem diferentes
    @Optional() public aoColumns?: Array<any>,

    /// se as definições das colunas do historico forem diferentes
    @Optional() public columnDefs?: Array<any>,

    /// contexto da operação
    @Optional() public Context?: string,

    /// se vai mostrar o painel de alterações de estado (por defeito vai mostrar, se for para esconder tem de ser enviado a true)
    @Optional() public HideChangeStates: boolean = false,

    // indicação se a caixa de confirmação deve apresentar um campo de input (texto)
    @Optional() public showConfirmationTextInput: boolean = false,

    // indicação se é para mostrar o nome do utilizador que realizou a transição
    @Optional() public showUserName: boolean = true,

    //indicação se é para mostrar na modal utilizadores para delegar ações
    @Optional() public hideAssignActionsToAnotherUser: boolean = false,

    /** Estados em que as observacoes ficam obrigatorias */
    @Optional() public requiredNotesStates: number[] = []
  ) { }
}

export class StateHistoryReturnParam {
  constructor(
    @Optional() public DocumentID: number,
    @Optional() public StepID: number,
    @Optional() public EventDate?: string,
    @Optional() public Notes?: string,
    @Optional() public StepData?: any,
    /// Se a acção for do tipo atribuir privilégios de aprovação, é necessário enviar o utilizador selecionado
    public userID?: number,
    @Optional() public DocumentNumber?: string
  ) { }
}

@Component({
  templateUrl: './state-history-modal.html'
})
export class StateHistoryModalComponent implements AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  parameters: StateHistoryModalParam = new StateHistoryModalParam(null, null, null); // parametros que vai receber do componente que esta a abrir a modal
  hideChangeStates: boolean = false; // saber se vai mostrar os paineis de alteração de estado - definido logo na chamada da model
  canChangeStates?: boolean = null; // se é para mostrar acções ou não
  steps: Array<any> = new Array<any>(); // vai ter o array com as acções para as radio
  shrink: boolean = true; // para saber se o historico está colapsado
  table: any;
  context: string;

  form: UntypedFormGroup;
  submitted: boolean; // saber se o formulario está a ser submetido
  noError: boolean; // saber se o formulario tem erros

  selectedUser: number; // Utilizador selecionado para atribuir privilégios
  users: User[] = [];

  showConfirmationTextInput: boolean = false; //Apresentar um campo de texto na confirmação de alteração de estado
  showUserName: boolean = true; //apresentar coluna com o nome do utilizador que realizou a transição de estado
  hideAssignActionsToAnotherUser: boolean = false;

  validationMessages = {};

  constructor(public dialogRef: MatDialogRef<StateHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: StateHistoryModalParam,
    private _formBuilder: UntypedFormBuilder,
    private _http: HttpClientCustom,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _translateValueService: TranslateValueService,
    private _errorTreat: ErrorTreatmentFunctions,
    private userService: UserService,
    private authenticationService: AuthenticationService) {
    this.parameters.DocumentID = data.DocumentID;
    this.parameters.ModalTitle = data.ModalTitle ? data.ModalTitle : 'STATE_OPTIONS';
    this.parameters.HistoryAction = data.HistoryAction;
    this.parameters.StatesAction = data.StatesAction;
    this.context = data.Context;
    this.showConfirmationTextInput = data.showConfirmationTextInput;
    this.showUserName = data.showUserName;
    this.hideAssignActionsToAnotherUser = data.hideAssignActionsToAnotherUser;
    this.parameters.requiredNotesStates = data.requiredNotesStates;
    if (data.HideChangeStates) {
      this.hideChangeStates = true;
    }
    // Utilizadores, se 'hideAssignActionsToAnotherUser' estiver a false
    if (!this.hideAssignActionsToAnotherUser) {
      this.userService.getAll(this.authenticationService.session.company.ID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (!response.ReturnStatus.Successfull) {
          this._errorTreat.treatErrorResponse(response);
        } else {
          this.users = (response.ReturnStatus.ReturnObject as User[]).filter(r => r.ID !== this.authenticationService.session.user.ID);
        }
      });
    }

    this.validationMessages = {
      'StepID': {
        'required': 'FIELD_REQUIRED_'
      },
      'Notes': {
        'required': 'FIELD_REQUIRED_'
      }
    }
  }

  ngAfterViewInit() {
    let aoColumns, columnDefs = [];
    if (this.data.aoColumns) { // se tiver enviado as colunas (nao sao as por defeito)
      let headersTh = document.querySelectorAll('#tableHistory' + ' thead tr th');
      if (headersTh.length !== this.data.aoColumns.length) { // verificar se tem as que precisa
        for (let index = 0; index < this.data.aoColumns.length; index++) {
          let header = document.querySelector('#tableHistory' + ' thead tr');
          header.appendChild(document.createElement('th')); // criar os th e adiciona-los
        }
      }
      aoColumns = this.data.aoColumns;
    } else {
      let buttonNote = function (id: number, notes: string): string {
        return '<a class="btn btn-sm table-notes" data-atrrid="' + id + '" onclick="return false;" data-value="' + notes + '"><span><i class="fas fa-file-alt purple_icon backwhite"></i></span></a>';
      };

      // colunas por defeito
      aoColumns = [
        { 'data': 'ID', 'visible': false },
        { 'data': 'EventDate', 'class': 'verticalMiddle', 'render': function (data: any, type: string, full: any, meta: any) { return DateTimePickerDirective.convertToString(data, true); } }, // 0
        { 'data': 'FromStateName', 'class': 'verticalMiddle' }, // 1
        { 'data': 'ToStateName', 'class': 'verticalMiddle' }, // 2
        { 'data': 'UserName', 'class': 'verticalMiddle', 'visible': this.showUserName }, // 3
        {
          'data': 'Notes', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) {
            if (data && (<string>data).length > 0) {
              return buttonNote(full.ID, data);
            } else {
              return '';
            }
          }
        } // 4 (alterado Mariana)

        /*{ 'data': 'Notes', 'class': 'verticalMiddle', 'render': function (data: any, type: string, full: any, meta: any) {
          if (data && (<string>data).length > 0) {
              return '<span class="tooltipAbs"><span class="tooltip--triangle" data-tooltip2="' + data + '"><i class="fas fa-sticky-note color-notes backwhite"></i></span></span>';
          } else {
            return '';
            }
        }
        }*/ // 4
      ];
    }

    if (this.data.columnDefs) { // se tiver enviado definições das colunas alem das por defeito
      columnDefs = this.data.columnDefs;
    }

    // columnDefs.push({ 'targets': '_all', 'orderable': false }); // nao permitir ordenar pelas colunas

    // alterado (Mariana) p/ ordenar todas as colunas
    columnDefs.push({ 'targets': '_all', 'ordering': true }); // nao permitir ordenar pelas colunas
    columnDefs.push({ 'targets': [-1], 'orderable': false }); // nao permitir ordenar pelas colunas

    // construir a tabela sem dados
    this.table = Functions.datatablesWithDataSet('#tableHistory', [], [],
      aoColumns, columnDefs, ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 1, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

    let that = this;

    this.table.on('draw', function () {
      let btnsObs = document.getElementsByClassName('table-notes');
      for (let btnObs of Array.from(btnsObs)) {
        $(btnObs).off('click');
        $(btnObs).on('click', function (ev: any) {
          that.onShowMessage($(this).attr('data-value'));
        });
        /*btnObs.addEventListener('click', function (ev: any) {
            that.onShowMessage(this.getAttribute('data-value'));
        });*/
      }

      // por ser um tooltip numa modal e ser muito grande é necessário esconde-lo para nao alterar as colunas da tabela
      /*[].map.call(document.querySelectorAll('#tableHistory [class^="tooltip"]'), function(el: any) {
        el.classList.add('bigTooltip');
        });*/
      // that.table.columns.adjust();
      // Functions.tooltipCSS();

    });

    if (!this.hideChangeStates) {
      // ir buscar os dados dos estados, segundo parametro a false porque não é para bloquear a pagina enquanto processa
      this._http.getBackend(this.parameters.StatesAction + this.parameters.DocumentID).toPromise().then((response: any) => {
        return response as ReturnStatusHtml;
      }).then((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) { // obteve resposta
          if (response.ReturnStatus.ReturnObject.WorkflowSteps && response.ReturnStatus.ReturnObject.WorkflowSteps.length > 0) { // tem estado para alterar
            this.steps = response.ReturnStatus.ReturnObject.WorkflowSteps;
            this.canChangeStates = true;
            this.buildForm();
          } else { // nao tem estados para alterar
            this.canChangeStates = false;
          }
        }
        else {
          this.canChangeStates = false;
          this._errorTreat.treatErrorResponse(response);
        }
      });
    }

  }

  onShowMessage(e: any) {

    let htmlMessage:string = (e as string).replaceAll('\n','<br/>'); 

    this._dialog.open(MessageModalComponent,
      {
        data: {
          // title: 'Observações',
          title: this._translateValueService.get('OBSERVATIONS'),
          message: htmlMessage,
        }
      });
  }

  /**
   * Quando encolhe ou estica o Historico
   */
  changeShrink() {
    this.shrink = !this.shrink;
    if (!this.shrink) { // vai abrir
      this.table.processing(true); // colocar o div a informar que esta a processar
      // ir buscar os dados para preencher a tabela de historico
      this._http.getBackend(this.parameters.HistoryAction + this.parameters.DocumentID).toPromise().then((response: any) => {
        return response as ReturnStatusHtml;
      }).then((response: ReturnStatusHtml) => {
        this.table.processing(false); // retirar a informação de processamento
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject.History) {
          if (this.table) {
            this.table.clear(); // limpar a tabela
            this.table.rows.add(response.ReturnStatus.ReturnObject.History).draw(); // colocar os novos dados
          }
        } else {
          this._errorTreat.treatErrorResponse(response);
        }
      });
    }
  }

  // -Início validação formulário - de alteração de estados
  buildForm(): void {
    this.form = this._formBuilder.group({
      'DocumentID': [this.parameters.DocumentID],
      'StepID': [null, Validators.required],
      'Notes': [null]
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  /* tslint:disable:member-ordering */
  formErrors = {};
  formErrorsParam = {};

  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        this.formErrorsParam[field] = null;

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key] + ' ';
              // verificar se o erro tem algum parametro (ex: min, max, length...)
              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  } // fim onValueChanged

  /**
   * Quando clica para alterar estado
   */
  save() {
    if(this.form.invalid)
    {
      this.form.markAllAsTouched();
      return;
    }

    let formData = this.form.getRawValue();
    if (formData.StepID !== null) {

      // Obter o step selecionado
      let selectedStep: any = this.steps.filter((step) => step.ID === formData.StepID)[0];

      //Mostrar modal de confirmação com texto se for para o estado 19 (guias renault)
      if (this.showConfirmationTextInput && selectedStep.ToStateID == 19) {
        let additionalFields = []
        let additionalField = new AdditionalField("Nº documento", "Nº documento")
        additionalFields.push(additionalField)

        let dialogRef = this._dialog.open(ConfirmationWithFieldsModalComponent,
          {
            data: {
              text: 'Confirmação de documento',
              subtext: 'Insira o Nº do Documento',
              additionalFields: additionalFields,
              mandatoryFields: true
            },
            disableClose: true, // nao permitir fechar modal com escape ou clique fora
          });
        dialogRef.afterClosed().subscribe(returnResult => {
          if (returnResult && returnResult.Confirmed) {
            //Dados da caixa de cofirmação
            let docNumber: string = returnResult.AdditionalFields[0].Value;

            // ir buscar dados do form
            formData = this.form.getRawValue();
            let response: StateHistoryReturnParam = new StateHistoryReturnParam(formData.DocumentID, formData.StepID,
              DateTimePickerDirective.convertToString(new Date(), true), formData.Notes, selectedStep, null, docNumber);
            this.dialogRef.close(response);
          }
        });

      } else {
        // mostrar modal de confirmação
        let msg = this._translateValueService.translatePipe('CONFIRM_STATE_TO_CHANGE', null, this._cdr),
          stepName: string = document.querySelector('[formControlname="StepID"] .mat-radio-checked .mat-radio-label-content') ? document.querySelector('[formControlname="StepID"] .mat-radio-checked .mat-radio-label-content').textContent : '',
          dialogRef = this._dialog.open(WarningModalComponent, {
            data: { // dados que vai enviar para o componente da modal
              message: msg + ' "' + stepName + '"?'
            },
            disableClose: true, // nao permitir fechar modal com escape ou clique fora
          });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) { // confirmou que quer alterar o estado
            // ir buscar dados do form
            formData = this.form.getRawValue();
            let response: StateHistoryReturnParam = new StateHistoryReturnParam(formData.DocumentID, formData.StepID,
              DateTimePickerDirective.convertToString(new Date(), true), formData.Notes, selectedStep);
            this.dialogRef.close(response);
          }
        });
      }
    }
  }

  addStepsApprover() {
    this.dialogRef.close({
      userID: this.selectedUser
    });
  }

  validateState(event: MatLegacyRadioGroup) {
    if (this.parameters.requiredNotesStates?.length) {
      let found = this.steps.find(step => step.ID === event.value && this.parameters.requiredNotesStates.includes(step.ToStateID));

      if (found)
      {
        if (!this.form.controls.Notes.hasValidator(Validators.required)){}
          this.form.controls.Notes.setValidators(Validators.required);
      }
      else
        this.form.controls.Notes.clearValidators();

      this.form.controls.Notes.markAsTouched();
      this.form.controls.Notes.updateValueAndValidity();
    }
  }

  ngOnDestroy() { }
}
