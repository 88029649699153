import { Directive, HostListener, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[changeJs]'
})
export class ChangeJsEventDirective {
  // evento que dispara quando se dá o (change) mas o javascript nao assume o on('change')
  // usado nos md-select dos filtros das datatables
  constructor(@Optional() private model: NgModel) { }

  // Escolher o evento 'change' do MatSelect, para fazer ações que não sejam do Angular
  @HostListener('selectionChange', ['$event'])
  changeEvent(event: any) {
    if (event.target) { // quando é o javascript tem de ir atualizar o modelo que o input esta a usar ( na vista é preciso ter o [(ngModel)]="...")
      if (this.model) {
        let valueN = event.target.value;
        if (valueN != null && valueN.length === 0) {
          valueN = null;
        }
        this.model.valueAccessor.writeValue(valueN);
        this.model.control.setValue(valueN);
      }
    } else if (event.source) { // quando é o angular a disparar o evento
      // atribui o valor ao elemento e emite o evento change do javascript
      event.source._elementRef.nativeElement.value = (typeof event.value === 'undefined') ? '' : event.value;
      // event.source._elementRef.nativeElement.dispatchEvent(new Event('change'));
      let aux = document.createEvent('Event');
      aux.initEvent('change', false, true);
      event.source._elementRef.nativeElement.dispatchEvent(aux);
    }
  }
}
