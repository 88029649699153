import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../http-client';
import { SERVICE_URL } from '../../constants/global';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { WorkflowStep } from '../../models/workflow-step';
import { MasterService } from '../master.service';
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';
import { DatatableParameters } from '../../models/datatable-parameters';
import { Dropdown } from '../../models/dropdown';
import { GenericType } from 'src/app/models/generic-type';
import { GenericDocument } from 'src/app/models/generic-document';
import { Infraction } from 'src/app/interfaces/infraction';
import { GenericScan } from 'src/app/models/generic-scan';
import { GenericState } from 'src/app/models/generic-state';
import { AuthenticationService } from '../authentication.service';

// Contexto
export const CONTEXT_TICKET = 'Ticket';
// Permissões

// Estado
export const enum TicketState { SAVED = 470, SUBMITTED = 471, PROCESSED = 472 }

// Detalhes
export const enum TicketDetailState { REGISTERED = 480, PENDING = 490, TREATED = 491 }
// Tipo
export const enum TicketType {
  ADMINISTRATIVE_OFFENSE = 140,
  SAVED
}


@Injectable()
export class TicketService {
  private _controller: string = 'GenericDocument';
  private _types: GenericType[];
  private _states: GenericState[];
  // Listas
  public dropdownLists: Dropdown[][] = [];

  filter: any = {};
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[2, 'desc']], 0);

  datatableParametersPaymentPrevision: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null], 10, [[3, 'desc']], 0);

  datatableTicketsParameters: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[2, 'desc']], 0);

  showDisclaimer: boolean;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) { }

  get(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?context=' + CONTEXT_TICKET + '&id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: GenericDocument, newFile?: File, newFile2?: File): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(CONTEXT_TICKET));
    formData.append('entity', JSON.stringify(model));
    if (newFile) {
      formData.append('file', newFile, newFile.name); // vai adicionar ao formulario o ficheiro escolhido
    }

    if (newFile2) {
      formData.append('file', newFile2, newFile2.name); // vai adicionar ao formulario o ficheiro escolhido
    }

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: GenericDocument): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(CONTEXT_TICKET));
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?context=' + CONTEXT_TICKET + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submit(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitDocument', { context: CONTEXT_TICKET, id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel dos documentos
  exportListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList', {
      context: CONTEXT_TICKET,
      parameters: params,
      beginDate: this.filter.BeginDate ? this.filter.BeginDate : null,
      endDate: this.filter.EndDate ? this.filter.EndDate : null,
    });
  }

  // Devolver excel de um documento
  exportToExcel(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcel?context=' + CONTEXT_TICKET + '&id=' + id);
  }

  // Devolver pdf de um documento
  exportToPDF(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDF?context=' + CONTEXT_TICKET + '&id=' + id);
  }

  // Devolver os tipos de documento
  get types(): Observable<GenericType[]> {
    if (this._types === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_TICKET)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._types = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              return this._types;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._types);
    }
  }

  // Devolver os tipos de documento
  get states(): Observable<GenericState[]> {
    if (this._states === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericStates?context=' + CONTEXT_TICKET)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._states = responseReturn.ReturnStatus.ReturnObject as GenericState[];
              return this._states;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._states);
    }
  }

  getActiveTypes(companyID: number = 0): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Common/GetGenericTypesActive?context=' + CONTEXT_TICKET + '&companyID=' + companyID)
      .pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
  }
 
  //#endregion Listas de Valores

  //#region Validações
  getAllByFieldValue(fieldCode: string, fieldValue: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllByFieldValue?context=' + CONTEXT_TICKET + '&fieldCode=' + fieldCode + '&fieldValue=' + fieldValue)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Validações

  getFieldsChanges(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetFieldsChanges?context=' + CONTEXT_TICKET + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
    * Obter lista de utilizadores por perfil associados a um dado documento
    * @param  {number} filterByRole
    * @returns Observable
    */
  getDocumentUsers(documentID: number, filterRoleID: number = null): Observable<Array<any>> {

    let request: string = '/GetDocumentUsers?documentID=' + documentID;
    if (filterRoleID > 0) {
      request += '&filterRole=' + filterRoleID;
    }

    return this.http.get(SERVICE_URL + 'AlticeFine' + request).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  get_showDisclaimer(defaultValue: boolean): boolean {
    if (typeof this.showDisclaimer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Ticket', 'ShowDisclaimer');
      if (settingValue != null) {
        this.showDisclaimer = settingValue;
      } else {
        this.showDisclaimer = defaultValue;
      }
    }
    return this.showDisclaimer;
  }

}
