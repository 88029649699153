import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelValidators } from '../../models/validators/validators';
import { AppConfig } from '../../configs/app.config';
import { SUPPORT_EMAIL, PORTAL_NAME } from 'src/app/constants/global';
import { event } from 'jquery';
import { CommonService } from 'src/app/services/common.service';
import { Country } from 'src/app/models/country';
import { Company } from 'src/app/models/company';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { RepositoryDiskFile } from 'src/app/models/repository-disk-file';
import { DeleteModalComponent } from '../shared/delete-modal/delete-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GenericDocument } from 'src/app/models/generic-document';
import { CompanyRegistrationState, CONTEXT_COMPANY_REGISTRATION, RegisterSupplierService } from 'src/app/services/register-supplier.service';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';
import { GenericType } from 'src/app/models/generic-type';
import { GenericFieldConfig, GenericFieldSubcontext, GenericFieldType } from 'src/app/models/generic-field-config';
import { joinAllErrors, validateForm } from 'src/app/constants/form-validators';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { ErrorTreatmentFunctions } from 'src/app/modules/treatments.module';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { Dropdown } from 'src/app/models/dropdown';
import { Currency } from 'src/app/models/currency';
import { Subject } from 'rxjs';

declare var App: any;
declare var Functions: any;

@Component({
  templateUrl: './registerSupplier.html',
  encapsulation: ViewEncapsulation.None
})
export class RegisterSupplierComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  //FormProperties
  joinAllErrors = joinAllErrors;
  form: UntypedFormGroup;
  formAllErrors: string = '';
  formErrors = {
    'Name': '',
    'Adress': '',
    'Street': '',
    'DoorNumber': '',
    'PostalCode': '',
    'Location': '',
    'Country': '',
    'MobilePhone': '',
    'Email': '',
    'OrdersEmail': '',
    'TaxPayer': '',
    'Vat': '',
    'EconomicActivityCode': '',
    'IBAN': '',
    'IBANRadio': '',
    'BankDataRadio': '',
    'BankCountry': '',
    'BankAgencyNumber': '',
    'SwiftBic': '',
    'BankAccount': '',
    'FileName': '',
    'IbanProof': '',
    'OtherDocuments': '',
    'AceptRules': '',
    'Company': '',
  };
  validationMessages = {};
  
  message: string;

  //UploadFileProperties
  uploader: FileUploader = new FileUploader({});
  hasBaseDropZoneOver: any;

  //Radios
  iBANRadio: boolean = true;
  bankDataRadio: boolean = false;
  
  bankCurrencies: Currency[];

  //auxFields
  countries: Country[];
  companies: Company[];
  ibanProof: RepositoryDiskFile;
  otherDocument: RepositoryDiskFile;
  ibanProofFile: File;
  otherDocumentFile: File;
  model: GenericDocument;
  context = CONTEXT_COMPANY_REGISTRATION;

  fieldsConfig: GenericFieldConfig[] = [];
  fieldsNames: string[] = [];
  types: GenericType[] = [];
  additionalFieldsConfig: GenericFieldConfig[] = [];
  isSubmited: boolean = false;
  fields: GenericFieldExtension[] = [];
  excludingID: number = 1;
  caes: Dropdown[] = [];

  constructor(private activatedRoute: ActivatedRoute, private renderer: Renderer2, private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder, private ref: ChangeDetectorRef, private translateService: TranslateService, private commonService: CommonService,
    private config: AppConfig, private translateValueService: TranslateValueService, private dialog: MatDialog, private registerSupplierService: RegisterSupplierService,
    private router: Router, private _errorTreat: ErrorTreatmentFunctions) {
    this.commonService.countries.pipe(takeUntil(this.destroy$)).subscribe(response => this.countries = response);
    this.commonService.getDropdownDataWithoutSession(Dropdown.CAES).pipe(takeUntil(this.destroy$)).subscribe(response => this.caes = response);
    this.commonService.currencies.pipe(takeUntil(this.destroy$)).subscribe(response => this.bankCurrencies = response);

    this.validationMessages = {
      'Name': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('NAME') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('NAME'), value: '64' })
      },
      'Address': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('ADDRESS') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('ADDRESS'), value: '64' })
      },
      'Street': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('STREET') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('STREET'), value: '64' })
      },
      'DoorNumber': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('DOOR_NUMBER') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('DOOR_NUMBER'), value: '64' })
      },
      'PostalCode': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('POSTAL_CODE') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('POSTAL_CODE'), value: '64' })
      },
      'Location': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('CITY') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('CITY'), value: '64' })
      },
      'Country': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('COUNTRY') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('COUNTRY'), value: '64' })
      },
      'MobilePhone': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('PHONE') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('PHONE'), value: '64' })
      },
      'Email': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('EMAIL') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('EMAIL'), value: '64' })
      },
      'OrdersEmail': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('ORDERS_EMAIL') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('ORDERS_EMAIL'), value: '64' })
      },
      'TaxPayer': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('TAXPAYER') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('TAXPAYER'), value: '64' })
      },
      'Vat': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('VAT') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('VAT'), value: '64' })
      },
      'EconomicActivityCode': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('CAE') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('CAE'), value: '64' })
      },
      'IBAN': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('IBAN') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('IBAN'), value: '64' })
      },
      'IBANRadio': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('IBAN') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('IBAN'), value: '64' })
      },
      'BankDataRadio': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('BANK_DATA') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('BANK_DATA'), value: '64' })
      },
      'BankCountry': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('BANK_COUNTRY') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('BANK_COUNTRY'), value: '64' })
      },
      'BankAgencyNumber': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('BANK_BRANCH_N') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('BANK_BRANCH_N'), value: '64' })
      },
      'SwiftBic': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('SWIFT_BIC') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('SWIFT_BIC'), value: '64' })
      },
      'BankAccount': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('BANK_ACCOUNT') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('BANK_ACCOUNT'), value: '64' })
      },
      'FileName': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('FILE') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('FILE'), value: '64' })
      },
      'IbanProof': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('IBAN_PROOF') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('IBAN_PROOF'), value: '64' })
      },
      'OtherDocuments': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('OTHER_DOCUMENTS') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('OTHER_DOCUMENTS'), value: '64' })
      },
      'AceptRules': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('ACCEPT_RULES_TEXT') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('ACCEPT_RULES_TEXT'), value: '64' })
      },
      'Company': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('COMPANY') }),
        'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: this.translateValueService.get('COMPANY'), value: '64' })
      },
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'texture');
    // Campos
    this.commonService.getGenericFieldsConfiguration(this.context, null, true).pipe(takeUntil(this.destroy$)).subscribe(response => {
      // Cria os objectos do tipo GenericFieldConfig, com os métodos associados
      (response ? response.configurations as [] : []).forEach((e, i) => this.fieldsConfig[i] = new GenericFieldConfig(e));
      this.buildForm();
      //obter as empresas
      this.commonService.getServiceProviders(this.excludingID).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.companies = response;
        //se só existir uma empresa (na qual os fornecedores se vão registar)
        //então essa vai ser a empresa selecionada por defeito
        if (this.companies && this.companies.length == 1) {
          this.form.get('Company').setValue(this.companies[0].ID);
        }
      });
    }); // obter a config dos campos */

  }

  public ngAfterViewInit(): void {
    App.init();
    let logoImg = this.config.getConfig('LOGO_IMG');
    document.querySelector('.login-theme .logo-img').setAttribute('src', (document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg));
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      // Entidade
      ID: [0],
      Context: [this.context],
      OwnerID: [2],
      DocumentTypeID: [GenericType.COMPANY_REGISTRATION],
      DocumentStateID: [CompanyRegistrationState.EM_APROVACAO],
      DocumentNumber: [null],
      CreationDate: [null],
      CreationBy: [null],
      LastUpdated: [null],
      LastUpdatedBy: [null],
      BlockedBy: [null]
    });

    if (this.fieldsConfig) {
      for (let i = 0; i < this.fieldsConfig.length; i++) {
        let validators = [];
        if (this.fieldsConfig[i].isFieldRequired(GenericType.COMPANY_REGISTRATION, CompanyRegistrationState.EM_APROVACAO, null)) {
          validators.push(Validators.required);
        }
        if (this.fieldsConfig[i].FieldLength) {
          validators.push(Validators.maxLength(this.fieldsConfig[i].FieldLength));
        }
        if (this.fieldsConfig[i].FieldType === GenericFieldType.NUMBER) {
          validators.push(ModelValidators.numberVal({ decimalPlaces: 2, min: 0 }));
        }

        // Validações extra do Email
        if (this.fieldsConfig[i].FieldCode === 'Email') {
          validators.push(Validators.email);
        }

        if (this.fieldsConfig[i].isFieldVisible(GenericType.COMPANY_REGISTRATION, CompanyRegistrationState.EM_APROVACAO, null)) {
          if (!this.form.get(this.fieldsConfig[i].FieldCode) && !this.fieldsConfig[i].GroupCode) {

            this.form.addControl(this.fieldsConfig[i].FieldCode,
              new UntypedFormControl({
                value: null,
                disabled:
                  !this.fieldsConfig[i].isFieldEditable(GenericType.COMPANY_REGISTRATION, CompanyRegistrationState.EM_APROVACAO, null)
              }, validators));
          }

          if (this.fieldsConfig[i].FieldName) {
            this.translateService.get(this.fieldsConfig[i].FieldName).subscribe(translatedField => {
              if (this.validationMessages[this.fieldsConfig[i].FieldCode]) {
                this.validationMessages[this.fieldsConfig[i].FieldCode].required = this.translateValueService.get('FIELD_ISEMPTY', { field: translatedField });
                if (this.fieldsConfig[i].FieldLength) {
                  this.validationMessages[this.fieldsConfig[i].FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: translatedField, value: this.fieldsConfig[i].FieldLength.toString() });
                }
              } else {
                this.validationMessages[this.fieldsConfig[i].FieldCode] = {
                  'required': this.translateValueService.get('FIELD_ISEMPTY', { field: translatedField })
                };

                if (this.fieldsConfig[i].FieldLength) {
                  this.validationMessages[this.fieldsConfig[i].FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: translatedField, value: this.fieldsConfig[i].FieldLength.toString() });
                }
                if (this.fieldsConfig[i].FieldType === GenericFieldType.NUMBER) {
                  this.validationMessages[this.fieldsConfig[i].FieldCode].numberVal = this.translateValueService.get('FIELD_NOT_NUMBER', { field: translatedField });
                  this.validationMessages[this.fieldsConfig[i].FieldCode].numberOfDecimalPlaces = this.translateValueService.get('FIELD_NOT_MORE_DECIMALS', { field: translatedField, value: '2' });
                  this.validationMessages[this.fieldsConfig[i].FieldCode].numberMin = this.translateValueService.get('FIELD_NOT_LESS', { field: translatedField, value: '0' });
                }
              }
              this.formErrors[this.fieldsConfig[i].FieldCode] = '';
            });
          }
        }

        // Adiciona o nome do campo para ser mostrado no template
        this.fieldsNames[this.fieldsConfig[i].FieldCode] = this.fieldsConfig[i].getFieldName();
      }
    }
    // Carrega as validações
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() =>
      this.formErrors = validateForm(this.form, this.formErrors, this.validationMessages, this.isSubmited));
    this.formErrors = validateForm(this.form, this.formErrors, this.validationMessages, this.isSubmited);
  }

  onSubmit() {
    this.isSubmited = true;
    this.form.updateValueAndValidity();
    this.model = JSON.parse(JSON.stringify(this.form.getRawValue()));

    // Valida se o formulário contém erros
    this.formAllErrors = joinAllErrors(this.formErrors);
    if (this.formAllErrors.length > 0) {
      return;
    }

    if (this.iBANRadio && !this.form.get('IBAN').value) {
      this.formAllErrors = this.translateValueService.get('FIELD_REQUIRED', { field: this.translateValueService.get('IBAN') });
      return;
    }

    if (this.bankDataRadio && (!this.form.get('BankCountry').value || !this.form.get('BankAgencyNumber').value || !this.form.get('SwiftBic').value || !this.form.get('BankAccount').value)) {
      this.formAllErrors = this.translateValueService.get('FIELD_REQUIRED', { field: this.translateValueService.get('BANK_DATA') });
      return;
    }
    this.model.Fields = [];

    // Mapea o formulário para a lista de campos genéricos
    GenericFieldConfig.mapFormFieldsToBDFields(this.model.ID, this.form, this.model.Fields, this.fieldsConfig);

    if (this.model) {
      this.registerSupplierService.add(this.model, this.ibanProofFile, this.otherDocumentFile, true).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.treatResponse(response, true);
      });
    }
  }

  treatResponse(response: ReturnStatusHtml, redirect: boolean) {
    if (response.ReturnStatus.Successfull) {
      if (redirect) {
        this.router.navigate(['/login']);
      } else {
        this.form.markAsPristine();
      }

      if (!response.ReturnStatus.WarningMessage) {
        Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
      } else {
        Functions.gritter(response.ReturnStatus.WarningMessage, 'warning');
      }
    } else {
      this._errorTreat.treatErrorResponse(response);
    }
  }

  checkRadios() {
    this.ref.detectChanges();
    if (this.bankDataRadio) {
      this.form.get('IBAN').setValue('');
      this.form.get('IBAN').disable();
      this.form.get('SwiftBic').setValue('');
      this.form.get('SwiftBic').disable();
    } else if (this.iBANRadio) {
      this.form.get('BankKey').setValue('');
      this.form.get('BankAgencyNumber').setValue('');
      this.form.get('BankAccount').setValue('');
      this.form.get('Region').setValue('');
      this.form.get('IBAN').enable();
      this.form.get('SwiftBic').enable();
    }
  }

  //#region File
  addIbanProofFile(event: any) {
    let file: File;
    if (event) { // chegou aqui atraves da janela normal de adição do ficheiro
      let listFiles: FileList = event.target.files;
      if (listFiles.length > 0) { // se tiver algum ficheiro
        file = listFiles[0]; // so queremos o primeiro
      }
    }

    if (file) {
      this.ibanProofFile = file;
      this.form.get('IbanProof').setValue(file.name);
    }
  }

  addOtherDocumentFile(event: any) {
    let file: File;
    if (event) { // chegou aqui atraves da janela normal de adição do ficheiro
      let listFiles: FileList = event.target.files;
      if (listFiles.length > 0) { // se tiver algum ficheiro
        file = listFiles[0]; // so queremos o primeiro
      }
    }

    if (file) {
      this.otherDocumentFile = file;
      this.form.get('OtherDocuments').setValue(file.name);
    }
  }

  removeIbanProofFile() {
    let dialogRef = this.dialog.open(DeleteModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ibanProofFile = undefined;
        this.form.get('IbanProof').setValue(null);
      }
    });
  }

  removeOtherDocumentFile() {
    let dialogRef = this.dialog.open(DeleteModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.otherDocumentFile = undefined;
        this.form.get('OtherDocuments').setValue(null);
      }
    });
  }

  _onContentChanges() {
    return;
  }

  onValueChanged(data?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onChangeCompany(event: any) {
    if (event.value) {
      this.form.get('OwnerID').setValue(event.value);
    }

  }

  ngOnDestroy() { }
}
