import { OnInit, Component, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { SUPPORT_EMAIL } from '../../constants/global';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';

declare var App: any;
declare var Functions: any;

@Component({
  templateUrl: './custom-error.html'
})
export class CustomErrorComponent implements OnInit, AfterViewInit, OnDestroy {
  message: string = '';
  returnUrl: string;
  mailTo: any;
  styleError: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private activatedRoute: ActivatedRoute, private renderer: Renderer2, private translateService: TranslateService, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'texture');

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.message = params['message'];
      this.returnUrl = params['returnUrl'];
    });

    if (SUPPORT_EMAIL && SUPPORT_EMAIL.length > 0 && SUPPORT_EMAIL.indexOf('mailto:') !== -1) {
      this.translateService.get('SUPPORT_SUBJECT').subscribe(translate => this.mailTo = this.domSanitizer.bypassSecurityTrustUrl(SUPPORT_EMAIL + '&subject=' + translate + '&body=' + this.message));
    } else if (SUPPORT_EMAIL && SUPPORT_EMAIL.length > 0) {
      this.mailTo = SUPPORT_EMAIL;
    }
    if(this.message.startsWith("TypeError: Cannot read properties of undefined (reading 'style')")){
      this.styleError=true;
      //this.translateService.get('ERROR_CONNECTION_OR_CACHE').subscribe(translate => this.styleError = translate)
    }

    
  }

  public ngAfterViewInit(): void {
    App.init();
    
  }

  returnToPortal() {
    window.location.href = document.getElementsByTagName('base')[0].href;
  }

  copyToClipboard() {
    let that = this;

    document.addEventListener('copy', function copyMessage(e: ClipboardEvent) {
      e.clipboardData.setData('text/plain', that.message);
      e.preventDefault();
      document.removeEventListener('copy', copyMessage);

      that.translateService.get('MESSAGE_COPIED').subscribe(translate => Functions.gritter(translate, 'success'));
    });
    document.execCommand('copy');
  }

  ngOnDestroy() { }
}
