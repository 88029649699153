import { Component, EnvironmentInjector, Inject, OnDestroy, inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CONTEXT } from '../../../constants/global';
import { CommonService } from 'src/app/services/common.service';
import { TableColumn } from 'src/app/models/table-column';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { InputColumns, InputTypes } from 'src/app/models/input-columns';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';
import { forEach } from 'lodash';

@Component({
  templateUrl: './generic-changes-modal.component.html'
})
export class GenericChangesModal implements OnDestroy {
  context: string;
  tabs: string[];
  referenceID: number;
  changes: any[] = [];
  displayedColumns : TableColumn[] = [];
  inputColumns : InputColumns[];
  pageStateType: string;

  constructor(public dialogRef: MatDialogRef<GenericChangesModal>, @Inject(MAT_DIALOG_DATA) data: any, translateService: TranslateService, public translateValueService : TranslateValueService,
  public commonService : CommonService, private injector: EnvironmentInjector) {

    this.context = data.context;
    this.tabs = data.tabs;
    this.referenceID = data.referenceID;

    this.commonService.getGenericChangesLog(this.context,this.referenceID)
    .subscribe(response => 
    {
      if(response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject)
      {
        var returnObject = response.ReturnStatus.ReturnObject
        returnObject.forEach(e => 
        {
          e.FieldLabel = this.translateValueService.get(e.FieldLabel);
          e.SectionName = this.translateValueService.get(e.SectionName);
        })

        this.changes = returnObject;
      }
    });

    if(this.tabs && this.tabs.length > 1)
    {
      this.displayedColumns.push({ColumnValue: 'SectionName', ColumnName: this.translateValueService.get("TAB")},)
    }

    this.displayedColumns = this.displayedColumns.concat(  
    [
        {ColumnValue: 'FieldLabel', ColumnName: this.translateValueService.get("FIELD")},
        {ColumnValue: 'FieldPreviousValue', ColumnName: this.translateValueService.get("PREVIOUS_VALUE")},
        {ColumnValue: 'FieldActualValue', ColumnName: this.translateValueService.get("NEW_VALUE")}, 
        {ColumnValue: 'LastUpdated', ColumnName: this.translateValueService.get("DATE")},
        {ColumnValue: 'LastUpdatedByName', ColumnName: this.translateValueService.get("USERNAME")},
    ]);

    this.inputColumns = [
      {Name: "LastUpdated", InputType: InputTypes.Function, Function: this.convertDate },
      {Name: "FieldPreviousValue", InputType: InputTypes.Function, Function: this.translateValues },
      {Name: "FieldActualValue", InputType: InputTypes.Function, Function: this.translateValues },
    ];

  }

  translateValues(value,translateService)
  {
    if(value)
    {
      switch(value.toLowerCase())
      {
        case "true":
            return translateService.get("ACTIVE") 
        case "false":
            return translateService.get("INACTIVE") 
        default:
          return value;
      }
    }
  }

  convertDate(date)
  {
      return DateTimePickerDirective.convertToString(date,true);
  }

  ngOnDestroy(): void {

  }
}
