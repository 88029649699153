import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';

@Component({
  templateUrl: './confirmation-with-fields-modal.html'
})
export class ConfirmationWithFieldsModalComponent {
  text: string;
  subtext: string;
  form: UntypedFormGroup;
  mandatoryFields: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmationWithFieldsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private formBuilder: UntypedFormBuilder) {
    this.text = data.text;
    this.subtext = data.subtext;
    this.mandatoryFields = data.mandatoryFields;

    this.form = this.formBuilder.group({
      AdditionalFields: this.formBuilder.array([])
    });

    // Adiciona os campos adicionais para preenchimento
    if (data.additionalFields && data.additionalFields.length > 0) {
      const additionalFieldsControl = <UntypedFormArray>this.form.get('AdditionalFields');

      data.additionalFields.forEach((field: AdditionalField) => {
        additionalFieldsControl.push(this.formBuilder.group({
          Label: [field.Label],
          Name: [field.Name],
          Value: [field.Value, this.mandatoryFields ? Validators.required : null]
        }));
      });
    }
  }

  /**
   * Fechar o popup
   * @param  {boolean} confirmed Confirmação da operação (true/false)
   */
  close(confirmed: boolean) {
    const additionalFieldsControl = <UntypedFormArray>this.form.get('AdditionalFields');
    let additionalFields;
    if (additionalFieldsControl.length > 0) {
      additionalFields = additionalFieldsControl.getRawValue();
    } else {
      additionalFields = [];
    }

    if (this.form.valid || !confirmed) {
      this.dialogRef.close({ Confirmed: confirmed, AdditionalFields: additionalFields });
    }
  }
}

export class AdditionalField {
  constructor(
    public Label: string = null,
    public Name: string = null,
    public Value: string = null
  ) { }
}
