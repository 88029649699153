import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { Warehouse } from '../models/warehouse';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class WarehouseService{
    private _controller: string = 'warehouse';

    datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[1, 'asc']], 0);
    filter: any = new Object();
    private columnInVisible_Details_P: Array<number>;

    constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationservice: AuthenticationService,
        private _dataTableF: DataTableFunctions) { }

    get(id: number): Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    getList(id?:number): Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/GetList', {ownerID: id}).pipe(
            map ((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    getWarehouseByIDAndLocationID(WarehouseID:number, LocationID:number): Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/GetWarehouseByIDAndLocationID', {warehouseID:WarehouseID, locationID:LocationID}).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    getOwnerStores(onwerID: number): Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/GetOwnerStores' , {onwerID:onwerID}).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    CalculateForecast(ownerID: number, storeCode: string): Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/CalculateForecast', {ownerID:ownerID, storeCode:storeCode}).pipe(   
        map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    GetForecastExistancesList(storeID:string, start:number, length:number, orderField?:string, order?:string, searchFilter?:Array<string> ):Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/GetForecastExistancesList', {store:storeID, start:start, length: length, orderField: orderField, order:order, searchFilters: searchFilter}).pipe(
        map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    update(model: Warehouse, menuID?: number): Observable<ReturnStatusHtml>{
        let formData: FormData = new FormData();
        formData.append('entity', JSON.stringify(model));
        formData.append('menuID', JSON.stringify(menuID));
        return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    updateWarehouseInventoryLines(editedRows: any): Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/updateWarehouseInventoryLines', { editedRows: editedRows }).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    create(model: Warehouse, menuID?:number): Observable<ReturnStatusHtml>{
        let formData: FormData = new FormData();
        formData.append('entity', JSON.stringify(model));
        formData.append('menuID', JSON.stringify(menuID));
        return this.http.post(SERVICE_URL + this._controller + '/Create', formData).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    GetInventoryList(ownerId: number, searchOptions: number, code?:string, description?:string){
        return this.http.post(SERVICE_URL + this._controller + '/GetInventoryList', {ownerID: ownerId, searchOption: searchOptions, code: code, description: description }).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    UpdateProductsData(storeID:string){
        return this.http.post(SERVICE_URL + this._controller + '/UpdateProductsData', {id:storeID}).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    UpdateProductsDataPrimeiraComunicacao(storeID:string){
        return this.http.post(SERVICE_URL + this._controller + '/UpdateProductsDataPrimeiraComunicacao', {id:storeID}).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    DownloadExcelForecastDetails(store:string, ownerID?:number ):Observable<any>{
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelForecastDetails', {OwnerID:ownerID, storeId:store});
      }

      get_ColumnInVisible_Details_P(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
        if (typeof this.columnInVisible_Details_P === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationservice.getSettingPortal('WarehouseForecast', 'ColumnVisible');
          let settingValueSP = this.authenticationservice.getSettingPortal('WarehouseForecast', 'ColumnVisible');
          this.columnInVisible_Details_P = this._dataTableF.getInvColumns(defaultValue, columnsNames,
            this.authenticationservice.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
        }
        return this.columnInVisible_Details_P;
      }


}