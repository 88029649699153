import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL, CONTEXT_LOAD_DOCUMENT } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { GenericType } from '../models/generic-type';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { WorkflowStep } from '../models/workflow-step';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';
import { LoadDocument } from '../models/load-document';

@Injectable()
export class LoadDocumentService {
  private _controller: string = 'ScannedDocument';
  private _types: GenericType[];

  // Filtros
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null], 10, [[5, 'desc']], 0);
  datatableParametersForAllCompanies: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null], 10, [[5, 'desc']], 0);
  filter: any = new Object();
  filterAllCompanies: any = new Object();

  // Settings
  classificationRole?: number;
  ignoreOwnerValidation: boolean;
  digitalArchiveAlwaysEditable: boolean;
  showOrderNumber: boolean;
  showTotalGrossAmount: boolean;
  showTotalTransportAmount: boolean;
  showTotalRetencaoIrsAmount: boolean;
  showTotalRetencaoIrsPercentage: boolean;
  showTotalTaxAmount: boolean;
  showTotalAmount: boolean;
  showTotalNetAmount: boolean;
  showCurrencyCode: boolean;
  showOtherReferences: boolean;
  showTaxas: boolean;
  showCostCenters: boolean;
  showDetails: boolean;
  hideNotesToSupplier: boolean;
  totalAmountRequired: boolean;
  otherRefFields: Array<any>;
  IsNecessaryFilterbyCompany: boolean;
  optionalQuantityUOM: boolean;
  showSupplierProductCode: boolean;
  hideStatusButtonWhenEmailNotContain: string;
  limitNumberOfFiles: boolean;
  private hideExportExcelButton: boolean; //Setting para esconder o Botão de Exportação Excel
  private setBeginingOfTheYearAsStartDate: boolean; //Setting que coloca a Data Inicial do Header Filter como o 1º dia do ano
  private setTodayAsEndDate: boolean; //Setting que coloca a Data Atual como a Data Final

  newButtonName:string ;
  titleName:string ;
  itsToSaveAndSubmit: boolean;
  showOthersReferencesOnlyEdition:boolean;



  showSelectCompanyRole: number;
  showContractNumber: boolean;
  showOrderDate: boolean;
  showCreationDate: boolean;
  creationDateLabel: string;
  optionalOrderNumber: boolean;
  defaultVATdecimals: string;
  showATCUD: boolean;
  hideProductSupplierCode: boolean;

  showDetailsContractNumber: boolean;
  showDetailsOrderDate: boolean;
  showDetailsOrderNumber: boolean;
  showDetailsPanelReferences: boolean;
  showDetailsCreationDate: boolean;
  showDetailsQuantityUOM: boolean;
  showDetailsVatValue: boolean;
  showVatExemptReasonID: boolean;
  showOrderLineNumber: boolean;
  orderNumberLabel: string;
  creditMandatoryInvoiceNumber: boolean;
  fixedOrderLabel: boolean;
  moveOrderLineNumberNextToLineNumber: boolean;
  
  acceptableFormat:string;

  private columnInVisibleSP: Array<number>;
  private columnInVisibleNonSP: Array<number>;


  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) { }

  // Devolver uma digitalização
  get(id: number, allCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&allCompanies=' + allCompanies)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Adicionar uma digitalização
  add(model: LoadDocument, file: any = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    if (file != null) {
      formData.append('File', file, file.name);
    }

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar uma digitalização
  update(model: LoadDocument, file: any = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    if (file != null) {
      formData.append('File', file, file.name);
    }
    return this.http.post(SERVICE_URL + this._controller + '/Update', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Eliminar uma digitalização
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Submeter a digitalização para workflow
  submit(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Submit', { id: id })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

   // Submeter a digitalização para workflow
   validate(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL  + 'Rangel/ValidateDocumentOnSAP', { id: id })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver as transições disponíveis para o estado corrente da entidade
  getWorkflowSteps(id: number, allCompanies: boolean = false, context: string = null): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?id=' + id + '&allCompanies=' + allCompanies + '&context=' + context)
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  // Alterar a transição atual da entidade para uma outra transição
  changeWorkflowStep(id: number, stepID: number, notes: string, emails: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangeWorkflowStep', { id: id, stepID: stepID, notes: notes, emails: emails })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atribuir privilégios de workflow de uma entidade a um dado utilizador
  addStepsApprover(entityID: number, userID: number, allCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID, allCompanies: allCompanies })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel das digitalizações
  exportListToExcel(menuNumber: number, params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList',
      { menuNumber: menuNumber, parameters: params, beginDate: this.filter.BeginDate, endDate: this.filter.EndDate, beginCreationDate: this.filter.BeginCreationDate,  
        endCreationDate: this.filter.EndCreationDate, supplier: this.filter.Supplier });
  }

  // Devolver excel das digitalizações todas emp.
  exportListToExcelAllCompanies(menuNumber: number, params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList',
      { menuNumber: menuNumber, parameters: params, beginDate: this.filterAllCompanies.BeginDate, endDate: this.filterAllCompanies.EndDate, beginCreationDate: this.filterAllCompanies.BeginCreationDate,  
        endCreationDate: this.filterAllCompanies.EndCreationDate, supplier: this.filterAllCompanies.Supplier });
  }
  
  // Devolver excel das digitalizações
  getPDF(ID: number, allCompanies: boolean = false): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/Getpdf',
      { id: ID, allCompanies: allCompanies });
  }

  // Devolver os tipos de digitalização
  get types(): Observable<GenericType[]> {
    if (this._types === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_LOAD_DOCUMENT)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._types = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              return this._types;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._types);
    }
  }

  // Settings
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  get_columnInVisibleSP(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisibleSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ColumnVisibleSP');
      this.columnInVisibleSP = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisibleSP;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  get_columnInVisibleNonSP(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisibleNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ColumnVisibleNonSP');
      this.columnInVisibleNonSP = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisibleNonSP;
  }

  get_classificationRole(defaultValue?: number): number {
    if (typeof this.classificationRole === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ClassificationRole');
      if (settingValue != null) {
        this.classificationRole = settingValue;
      } else {
        this.classificationRole = defaultValue;
      }
    }
    return this.classificationRole;
  }

  get_ignoreOwnerValidation(defaultValue: boolean): boolean {
    if (typeof this.ignoreOwnerValidation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'IgnoreOwnerValidation');
      if (settingValue != null) {
        this.ignoreOwnerValidation = settingValue;
      } else {
        this.ignoreOwnerValidation = defaultValue;
      }
    }
    return this.ignoreOwnerValidation;
  }

  get_hideExportExcelButton(defaultValue: boolean): boolean {
    if (typeof this.hideExportExcelButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'HideExportExcelButton');
      if (settingValue != null) {
        this.hideExportExcelButton = settingValue;
      } else {
        this.hideExportExcelButton = defaultValue;
      }
    }
    return this.hideExportExcelButton;
  }

  get_setBeginingOfTheYearAsStartDate(defaultValue: boolean): boolean {
   if (typeof this.setBeginingOfTheYearAsStartDate === 'undefined') { // verificar se ainda nao tem valor
     let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'SetBeginingOfTheYearAsStartDate');
     if (settingValue != null) {
       this.setBeginingOfTheYearAsStartDate = settingValue;
     } else {
       this.setBeginingOfTheYearAsStartDate = defaultValue;
     }
   }
   return this.setBeginingOfTheYearAsStartDate;
 }

  get_setTodayAsEndDate(defaultValue: boolean): boolean {
   if (typeof this.setTodayAsEndDate === 'undefined') { // verificar se ainda nao tem valor
     let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'SetTodayAsEndDate');
     if (settingValue != null) {
       this.setTodayAsEndDate = settingValue;
     } else {
       this.setTodayAsEndDate = defaultValue;
     }
   }
   return this.setTodayAsEndDate;
 }

  get_digitalArchiveAlwaysEditable(defaultValue: boolean): boolean {
    if (typeof this.digitalArchiveAlwaysEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'DigitalArchiveAlwaysEditable');
      if (settingValue != null) {
        this.digitalArchiveAlwaysEditable = settingValue;
      } else {
        this.digitalArchiveAlwaysEditable = defaultValue;
      }
    }
    return this.digitalArchiveAlwaysEditable;
  }

  /**
   * se mostra campo de n.encomenda
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showOrderNumber(defaultValue: boolean): boolean {
    if (typeof this.showOrderNumber === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowOrderNumber');
      if (settingValue != null) {
        this.showOrderNumber = settingValue;
      } else {
        this.showOrderNumber = defaultValue;
      }
    }
    return this.showOrderNumber;
  }

  /**
   * se mostra campo de total bruto
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.showTotalGrossAmount === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.showTotalGrossAmount = settingValue;
      } else {
        this.showTotalGrossAmount = defaultValue;
      }
    }
    return this.showTotalGrossAmount;
  }

  /**
   * se mostra campo de total transporte
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalTransportAmount(defaultValue: boolean): boolean {
    if (typeof this.showTotalTransportAmount === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalTransportAmount');
      if (settingValue != null) {
        this.showTotalTransportAmount = settingValue;
      } else {
        this.showTotalTransportAmount = defaultValue;
      }
    }
    return this.showTotalTransportAmount;
  }
  /**
   * se mostra campo de total retencao
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalRetencaoIrsAmount(defaultValue: boolean): boolean {
    if (typeof this.showTotalRetencaoIrsAmount === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalRetencaoIrsAmount');
      if (settingValue != null) {
        this.showTotalRetencaoIrsAmount = settingValue;
      } else {
        this.showTotalRetencaoIrsAmount = defaultValue;
      }
    }
    return this.showTotalRetencaoIrsAmount;
  }
  /**
   * se mostra campo de percentagem de retencao
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalRetencaoIrsPercentage(defaultValue: boolean): boolean {
    if (typeof this.showTotalRetencaoIrsPercentage === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalRetencaoIrsPercentage');
      if (settingValue != null) {
        this.showTotalRetencaoIrsPercentage = settingValue;
      } else {
        this.showTotalRetencaoIrsPercentage = defaultValue;
      }
    }
    return this.showTotalRetencaoIrsPercentage;
  }
  /**
   * se mostra campo de total taxas
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalTaxAmount(defaultValue: boolean): boolean {
    if (typeof this.showTotalTaxAmount === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalTaxAmount');
      if (settingValue != null) {
        this.showTotalTaxAmount = settingValue;
      } else {
        this.showTotalTaxAmount = defaultValue;
      }
    }
    return this.showTotalTaxAmount;
  }
  /**
   * se mostra campo de a pagar
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalAmount(defaultValue: boolean): boolean {
    if (typeof this.showTotalAmount === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalAmount');
      if (settingValue != null) {
        this.showTotalAmount = settingValue;
      } else {
        this.showTotalAmount = defaultValue;
      }
    }
    return this.showTotalAmount;
  }
  /**
   * se mostra campo de a pagar
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTotalNetAmount(defaultValue: boolean): boolean {
    if (typeof this.showTotalNetAmount === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTotalNetAmount');
      if (settingValue != null) {
        this.showTotalNetAmount = settingValue;
      } else {
        this.showTotalNetAmount = defaultValue;
      }
    }
    return this.showTotalNetAmount;
  }
  /**
   * se mostra campo da moeda
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showCurrencyCode(defaultValue: boolean): boolean {
    if (typeof this.showCurrencyCode === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowCurrencyCode');
      if (settingValue != null) {
        this.showCurrencyCode = settingValue;
      } else {
        this.showCurrencyCode = defaultValue;
      }
    }
    return this.showCurrencyCode;
  }
  /**
   * se mostra painel de outras referencias
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.showOtherReferences === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowOtherReferences');
      if (settingValue != null) {
        this.showOtherReferences = settingValue;
      } else {
        this.showOtherReferences = defaultValue;
      }
    }
    return this.showOtherReferences;
  }  /**
   * se mostra painel de taxas
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showTaxas(defaultValue: boolean): boolean {
    if (typeof this.showTaxas === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowTaxas');
      if (settingValue != null) {
        this.showTaxas = settingValue;
      } else {
        this.showTaxas = defaultValue;
      }
    }
    return this.showTaxas;
  }
  /**
   * se mostra botao de distribuicao
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showCostCenters(defaultValue: boolean): boolean {
    if (typeof this.showCostCenters === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowCostCenters');
      if (settingValue != null) {
        this.showCostCenters = settingValue;
      } else {
        this.showCostCenters = defaultValue;
      }
    }
    return this.showCostCenters;
  }
  /**
   * se mostra painel de detalhes
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_showDetails(defaultValue: boolean): boolean {
    if (typeof this.showDetails === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowDetails');
      if (settingValue != null) {
        this.showDetails = settingValue;
      } else {
        this.showDetails = defaultValue;
      }
    }
    return this.showDetails;
  }
  /**
   * se esconde painel de observações ao fornecedor
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_hideNotesToSupplier(defaultValue: boolean): boolean {
    if (typeof this.hideNotesToSupplier === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'HideNotesToSupplier');
      if (settingValue != null) {
        this.hideNotesToSupplier = settingValue;
      } else {
        this.hideNotesToSupplier = defaultValue;
      }
    }
    return this.hideNotesToSupplier;
  }
  /**
   * se o campo totalAmount é de preenchimento obrigatorio e maior que zero
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_totalAmountRequired(defaultValue: boolean): boolean {
    if (typeof this.totalAmountRequired === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'TotalAmountRequired');
      if (settingValue != null) {
        this.totalAmountRequired = settingValue;
      } else {
        this.totalAmountRequired = defaultValue;
      }
    }
    return this.totalAmountRequired;
  }


  get_otherRefFields(defaultValue: Array<any>): Array<any> {
    if (typeof this.otherRefFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'OtherRefFields');
      if (settingValue != null) {
        this.otherRefFields = settingValue;
      } else {
        this.otherRefFields = defaultValue;
      }
    }
    return this.otherRefFields;
  }

  get_IsNecessaryFilterbyCompany(defaultValue: boolean): boolean {
    if (typeof this.IsNecessaryFilterbyCompany === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'IsNecessaryFilterbyCompany');
      if (settingValue != null) {
        this.IsNecessaryFilterbyCompany = settingValue;
      } else {
        this.IsNecessaryFilterbyCompany = defaultValue;
      }
    }
    return this.IsNecessaryFilterbyCompany;
  }

  /**
   * se o campo totalAmount é de preenchimento obrigatorio e maior que zero
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_optionalQuantityUOM(defaultValue: boolean): boolean {
    if (typeof this.optionalQuantityUOM === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'OptionalQuantityUOM');
      if (settingValue != null) {
        this.optionalQuantityUOM = settingValue;
      } else {
        this.optionalQuantityUOM = defaultValue;
      }
    }
    return this.optionalQuantityUOM;
  }

  /**
  * se o campo totalAmount é de preenchimento obrigatorio e maior que zero
  * @param  {boolean} defaultValue
  * @returns boolean
  */
  get_showSupplierProductCode(defaultValue: boolean): boolean {
    if (typeof this.showSupplierProductCode === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowSupplierProductCode');
      if (settingValue != null) {
        this.showSupplierProductCode = settingValue;
      } else {
        this.showSupplierProductCode = defaultValue;
      }
    }
    return this.showSupplierProductCode;
  }


    get_newButtonName(defaultValue: string): string {
      if (typeof this.newButtonName === 'undefined') {
        let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'NewButtonName');
        if (settingValue != null) {
          this.newButtonName = settingValue;
        } else {
          this.newButtonName = defaultValue;
        }
      }
      return this.newButtonName;
    }

    get_newTitleName(defaultValue: string): string {
      if (typeof this.titleName === 'undefined') {
        let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'TitleName');
        if (settingValue != null) {
          this.titleName = settingValue;
        } else {
          this.titleName = defaultValue;
        }
      }
      return this.titleName;
    }

    get_isToSaveAndSubmit(defaultValue:boolean):boolean{
      if (typeof this.itsToSaveAndSubmit === 'undefined') {
        let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'IsToSaveAndSubmit');
        if (settingValue != null) {
          this.itsToSaveAndSubmit = settingValue;
        } else {
          this.itsToSaveAndSubmit = defaultValue;
        }
      }
      return this.itsToSaveAndSubmit;
    }


    get_showOthersReferencesOnlyEdition(defaultValue:boolean):boolean{
      if (typeof this.showOthersReferencesOnlyEdition === 'undefined') {
        let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowOthersReferencesOnlyEdition');
        if (settingValue != null) {
          this.showOthersReferencesOnlyEdition = settingValue;
        } else {
          this.showOthersReferencesOnlyEdition = defaultValue;
        }
      }
      return this.showOthersReferencesOnlyEdition;
    }



  get_showSelectCompanyRole(defaultValue: number): number {
    if (typeof this.showSelectCompanyRole === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowSelectCompanyRole');
      if (settingValue != null) {
        this.showSelectCompanyRole = settingValue;
      } else {
        this.showSelectCompanyRole = defaultValue;
      }
    }
    return this.showSelectCompanyRole;
  }

  get_showContractNumber(defaultValue: boolean): boolean {
    if (typeof this.showContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowContractNumber');
      if (settingValue != null) {
        this.showContractNumber = settingValue;
      } else {
        this.showContractNumber = defaultValue;
      }
    }
    return this.showContractNumber;
  }

  get_showOrderDate(defaultValue: boolean): boolean {
    if (typeof this.showOrderDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowOrderDate');
      if (settingValue != null) {
        this.showOrderDate = settingValue;
      } else {
        this.showOrderDate = defaultValue;
      }
    }
    return this.showOrderDate;
  }

  get_showCreationDate(defaultValue: boolean): boolean {
    if (typeof this.showCreationDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowCreationDate');
      if (settingValue != null) {
        this.showCreationDate = settingValue;
      } else {
        this.showCreationDate = defaultValue;
      }
    }
    return this.showCreationDate;
  }

  get_creationDateLabel(defaultValue: string): string {
    if (typeof this.creationDateLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'CreationDateLabel');
      if (settingValue != null) {
        this.creationDateLabel = settingValue;
      } else {
        this.creationDateLabel = defaultValue;
      }
    }
    return this.creationDateLabel;
  }

  get_orderNumberLabel(defaultValue: string): string {
    if (typeof this.orderNumberLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'OrderNumberLabel');
      if (settingValue != null) {
        this.orderNumberLabel = settingValue;
      } else {
        this.orderNumberLabel = defaultValue;
      }
    }
    return this.orderNumberLabel;
  }

  get_optionalOrderNumber(defaultValue: boolean): boolean {
    if (typeof this.optionalOrderNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'OptionalOrderNumber');
      if (settingValue != null) {
        this.optionalOrderNumber = settingValue;
      } else {
        this.optionalOrderNumber = defaultValue;
      }
    }
    return this.optionalOrderNumber;
  }

  get_defaultVATdecimals(defaultValue: string): string {
    if (typeof this.defaultVATdecimals === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'DefaultVATdecimals');
      if (settingValue != null) {
        this.defaultVATdecimals = settingValue;
      } else {
        this.defaultVATdecimals = defaultValue;
      }
    }
    return this.defaultVATdecimals;
  }

  //SCANNED DOCUMENT DETAILS
  get_showDetailsOrderDate(defaultValue: boolean): boolean {
    if (typeof this.showDetailsOrderDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowOrderDate');
      if (settingValue != null) {
        this.showDetailsOrderDate = settingValue;
      } else {
        this.showDetailsOrderDate = defaultValue;
      }
    }
    return this.showDetailsOrderDate;
  }

  get_showDetailsOrderNumber(defaultValue: boolean): boolean {
    if (typeof this.showDetailsOrderNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowOrderNumber');
      if (settingValue != null) {
        this.showDetailsOrderNumber = settingValue;
      } else {
        this.showDetailsOrderNumber = defaultValue;
      }
    }
    return this.showDetailsOrderNumber;
  }

  get_showDetailsContractNumber(defaultValue: boolean): boolean {
    if (typeof this.showDetailsContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowContractNumber');
      if (settingValue != null) {
        this.showDetailsContractNumber = settingValue;
      } else {
        this.showDetailsContractNumber = defaultValue;
      }
    }
    return this.showDetailsContractNumber;
  }

  get_showDetailsPanelReferences(defaultValue: boolean): boolean {
    if (typeof this.showDetailsPanelReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowPanelReferences');
      if (settingValue != null) {
        this.showDetailsPanelReferences = settingValue;
      } else {
        this.showDetailsPanelReferences = defaultValue;
      }
    }
    return this.showDetailsPanelReferences;
  }


  get_showDetailsCreationDate(defaultValue: boolean): boolean {
    if (typeof this.showDetailsCreationDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowDetailsCreationDate');
      if (settingValue != null) {
        this.showDetailsCreationDate = settingValue;
      } else {
        this.showDetailsCreationDate = defaultValue;
      }
    }
    return this.showDetailsCreationDate;
  }


  get_showDetailsQuantityUOM(defaultValue: boolean): boolean {
    if (typeof this.showDetailsQuantityUOM === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowDetailsQuantityUOM');
      if (settingValue != null) {
        this.showDetailsQuantityUOM = settingValue;
      } else {
        this.showDetailsQuantityUOM = defaultValue;
      }
    }
    return this.showDetailsQuantityUOM;
  }

  get_showDetailsVatValue(defaultValue: boolean): boolean {
    if (typeof this.showDetailsVatValue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowDetailsVatValue');
      if (settingValue != null) {
        this.showDetailsVatValue = settingValue;
      } else {
        this.showDetailsVatValue = defaultValue;
      }
    }
    return this.showDetailsVatValue;
  }

  get_showVatExemptReasonID(defaultValue: boolean): boolean {
    if (typeof this.showVatExemptReasonID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowVatExemptReasonID');
      if (settingValue != null) {
        this.showVatExemptReasonID = settingValue;
      } else {
        this.showVatExemptReasonID = defaultValue;
      }
    }
    return this.showVatExemptReasonID;
  }

  get_moveOrderLineNumberNextToLineNumber(defaultValue: boolean): boolean {
    if (typeof this.moveOrderLineNumberNextToLineNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'MoveOrderLineNumberNextToLineNumber');
      if (settingValue != null) {
        this.moveOrderLineNumberNextToLineNumber = settingValue;
      } else {
        this.moveOrderLineNumberNextToLineNumber = defaultValue;
      }
    }
    return this.moveOrderLineNumberNextToLineNumber;
  }

  get_hideStatusButtonWhenEmailNotContain(defaultValue: string): string {
    if (typeof this.hideStatusButtonWhenEmailNotContain === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'HideStatusButtonWhenEmailNotContain');
      if (settingValue != null) {
        this.hideStatusButtonWhenEmailNotContain = settingValue;
      } else {
        this.hideStatusButtonWhenEmailNotContain = defaultValue;
      }
    }
    return this.hideStatusButtonWhenEmailNotContain;
  }
  
  get_acceptableFormat(defaultValue: string): string {
    if (typeof this.acceptableFormat === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'AcceptableFormat');
      if (settingValue != null) {
        this.acceptableFormat = settingValue;
      } else {
        this.acceptableFormat = defaultValue;
      }
    }
    return this.acceptableFormat;
  }

  get_limitNumberOfFiles(defaultValue: boolean): boolean {
    if (typeof this.limitNumberOfFiles === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'LimitNumberOfFiles');
      if (settingValue != null) {
        this.limitNumberOfFiles = settingValue;
      } else {
        this.limitNumberOfFiles = defaultValue;
      }
    }
    return this.limitNumberOfFiles;
  }

  get_showOrderLineNumber(defaultValue: boolean): boolean {
    if (typeof this.showOrderLineNumber === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'ShowOrderLineNumber');
      if (settingValue != null) {
        this.showOrderLineNumber = settingValue;
      } else {
        this.showOrderLineNumber = defaultValue;
      }
    }
    return this.showOrderLineNumber;
  }

  get_fixedOrderLabel(defaultValue: boolean): boolean {
    if (typeof this.fixedOrderLabel === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'FixedOrderLabel');
      if (settingValue != null) {
        this.fixedOrderLabel = settingValue;
      } else {
        this.fixedOrderLabel = defaultValue;
      }
    }
    return this.fixedOrderLabel;
  }

  get_creditMandatoryInvoiceNumber(defaultValue: boolean): boolean {
    if (typeof this.creditMandatoryInvoiceNumber === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'CreditMandatoryInvoiceNumber');
      if (settingValue != null) {
        this.creditMandatoryInvoiceNumber = settingValue;
      } else {
        this.creditMandatoryInvoiceNumber = defaultValue;
      }
    }
    return this.creditMandatoryInvoiceNumber;
  }

  get_showATCUD(defaultValue: boolean): boolean {
    if (typeof this.showATCUD === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocument', 'ShowATCUD');
      if (settingValue != null) {
        this.showATCUD = settingValue;
      } else {
        this.showATCUD = defaultValue;
      }
    }
    return this.showATCUD;
  }

  get_hideProductSupplierCode(defaultValue: boolean): boolean {
    if (typeof this.hideProductSupplierCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LoadDocumentDetails', 'HideProductSupplierCode');
      if (settingValue != null) {
        this.hideProductSupplierCode = settingValue;
      } else {
        this.hideProductSupplierCode = defaultValue;
      }
    }
    return this.hideProductSupplierCode;
  }
  // END - Settings
}
