// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, NgZone, OnDestroy } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';

// ***__***_________  MODELOS _________ ***__***
import { OrderDetail, OrderNote, OrderReference } from '../../../models/order';
import { ModelValidators } from '../../../models/validators/validators';

// ***__***_________  MODALS _________ ***__***
import { ChooseModalComponent } from '../../shared/choose-modal/choose-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './orderDetails-modal.html'
})
export class OrderDetailBaglassModalComponent implements OnInit, OnDestroy {
  public detailForm: UntypedFormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  noError: boolean = true;
  submitted: boolean = false;
  formErrors: Array<string> = new Array<string>(); // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean = false; // se tem permissoes de edicao
  model: OrderDetail = null;

  decimalPlaces3: number = 3;

  // arrays para options dos selects
  orderTaxes: Array<any> = new Array<any>();
  orderAllowances: Array<any> = new Array<any>();
  orderDiscounts: Array<any> = new Array<any>();

  detailTaxes: Array<any> = new Array<any>();
  detailUnits: Array<any> = new Array<any>();

  listaMoradasLocaisEntrega: Array<any> = new Array<any>();
  allCountries: Array<any> = new Array<any>();
  providers: Array<any> = new Array<any>();

  isEditable: boolean;

  formDisabled: boolean;
  productQuantityValidations: boolean;
  context: string = '';

  orderedQuantityUN: any;
  deliveryDateMinDays: number = 0;
  minDate: Date = new Date();

  validationMessagesOtherReferences = {};
  validationMessagesNotes = {};

  constructor(public dialogRef: MatDialogRef<OrderDetailBaglassModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private zone: NgZone,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder) {
    this.context = data.context;

    if (data.context === 'encomendaCompra') { // encomenda de compra (as editaveis)
      this.isEditable = true;
      this.detailForm = data.detailForm;
      this.model = data.detailModel;
      this.allowEdit = data.allowEdit;
      this.validationMessages = data.validationMessagesDetails;
      this.detailTaxes = data.detailTaxes;
      this.detailUnits = data.detailUnits;
      this.listaMoradasLocaisEntrega = data.locations;
      this.allCountries = data.allCountries;

      this.formDisabled = data.formDisabled;
      this.productQuantityValidations = data.productQuantityValidations;
      this.deliveryDateMinDays = data.deliveryDateMinDays;

      // this.minDate.setDate(new Date().getDate() + this.deliveryDateMinDays);
      this.minDate.setDate(new Date().getDate());
      this.minDate.setHours(0, 0, 0, 0);
      for (let index = 0; index < this.deliveryDateMinDays; index++) {
        let day = this.minDate.getDay(), add = 1;
        if (day === 5) {
          add = 3;
        } else if (day === 6) {
          add = 2;
        }
        this.minDate.setDate(this.minDate.getDate() + add);
      }


    } else {
      this.isEditable = false;
      this.model = data.detailModel;

      // if (this.model.OrderedQuantity && this.model.PackSize) {
      this.orderedQuantityUN = (this.model.OrderedQuantity * this.model.PackSize);
      // }
    }

    this.validationMessagesOtherReferences = {
      'ReferenceDate ': {
        'invalidDate': 'INVALID_DATE'
      },
    }
    
    this.validationMessagesNotes = {}
  }

  ngOnInit() {
    if (this.isEditable) {
      this.detailForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDetails(value);
      });
      this.onValueChangedDetails(); // p/ 1ªx

      let that = this;
      this.zone.onStable.pipe(first()).subscribe(() => {
        // that.addTaxe(false);
        // that.addAllowance(false);
        // that.addDiscount(false);
        that.addOtherReference(false);
        that.addNote(false);
      });
    }
  }

  // #region Detail
  onValueChangedDetails(value?: any) {

    if (!this.detailForm) { return; }
    const form = this.detailForm;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];

          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  checkChanges() {
    this.onValueChangedDetails();
  }

  save() {
    if (this.allowEdit) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.checkChanges();

      if (this.noError) {
        this.dialogRef.close(this.detailForm); // nao pode enviar so o value por causa dos campos disabled
      }
      this.submitted = false;
    }
  }

  // obter info locais de entrega (moradas)
  selectLocalDeliveryDetails() {
    this.translateService.get(['SELECT_DELIVERY_LOCAL', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.listaMoradasLocaisEntrega, null, response['SELECT_DELIVERY_LOCAL'], aoColumns, columnDefs, null, 1, null),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.listaMoradasLocaisEntrega.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['DeliveryLocationID'].setValue(this.listaMoradasLocaisEntrega[index].ID);
            this.detailForm.controls['DeliveryLocationIdentifier'].setValue(this.listaMoradasLocaisEntrega[index].IntegrationID);
            this.detailForm.controls['DeliveryLocationName'].setValue(this.listaMoradasLocaisEntrega[index].Name);
            this.detailForm.controls['DeliveryLocationStreet'].setValue(this.listaMoradasLocaisEntrega[index].Street);
            this.detailForm.controls['DeliveryLocationPostalCode'].setValue(this.listaMoradasLocaisEntrega[index].PostalCode);
            this.detailForm.controls['DeliveryLocationCity'].setValue(this.listaMoradasLocaisEntrega[index].City);
            this.detailForm.controls['DeliveryLocationCountryCode'].setValue(this.listaMoradasLocaisEntrega[index].CountryCode);
          }
        }
      });

    });

  }

  selectProvider() {
    this.translateService.get(['SELECT_SUPPLIER_TITLE', 'CODE', 'TAX_NUMBER', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'TaxNumber', 'class': 'verticalMiddle', 'title': response['TAX_NUMBER'] }, // 2 - nif
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.providers, null, response['SELECT_SUPPLIER_TITLE'], aoColumns, columnDefs, null, 1, null),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.providers.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['SupplierID'].setValue(this.providers[index].ID);
            this.detailForm.controls['SupplierIdentifier'].setValue(this.providers[index].InternalID);
            this.detailForm.controls['SupplierName'].setValue(this.providers[index].Name);
          }
        }
      });
    });
  }

  quantityOrNetUnitPriceChange(val: any) {
    if (!isNaN(val)) {
      let quantity = (+this.detailForm.get('OrderedQuantity').value);
      let price = (+this.detailForm.get('NetUnitPrice').value);
      let result = quantity * price;
      this.detailForm.get('TotalNetAmount').setValue((+result).round(2));
    }
  }

  updateOrderedQuantityUN() {
    let qtd = this.detailForm.controls['OrderedQuantity'].value;
    let size = this.detailForm.controls['PackSize'].value;
    let val = (qtd * size);
    this.detailForm.controls['OrderedQuantityUN'].setValue(val);
  }
  // #endregion Detail

  // #region OrderQtdMethods
  /* Atenção!!!
  Aqui (modal), quando clica no increaseOrderedQtd() ou no reduceOrderedQtd() vai sempre fazer o checkQuantityIncrement()
  porque clicar nos dois primeiros desencadeia o (change) do input

  Ou entao nao...
  */
  increaseOrderedQtd() {
    let quantity = (+this.detailForm.get('OrderedQuantity').value);
    if (!isNaN(quantity)) {
      if (this.model.OrderedQuantity && this.model.MinimumQuantity && this.model.QuantityIncrement) {
        let val = quantity + this.model.QuantityIncrement;
        this.detailForm.controls['OrderedQuantity'].setValue(val);
        this.updateOrderedQuantityUN();
      }
    }
  }

  reduceOrderedQtd() {
    let quantity = (+this.detailForm.get('OrderedQuantity').value);
    if (!isNaN(quantity)) {
      if (this.model.OrderedQuantity && this.model.MinimumQuantity && this.model.QuantityIncrement) {
        let val = quantity - this.model.QuantityIncrement;
        if (val >= this.model.MinimumQuantity) {
          this.detailForm.controls['OrderedQuantity'].setValue(val);
          this.updateOrderedQuantityUN();
        }
      }
    }
  }

  // altera valor da quantidade encomendada caso nao seja divisivel pela qauntidade incrmentável. Ex: se só puder fazer incrementos de 5 em 5;
  checkQuantityIncrement() {
    if (this.productQuantityValidations) {
      let quantity = (+this.detailForm.get('OrderedQuantity').value);
      if (!isNaN(quantity)) { // se for numero
        if (this.model.OrderedQuantity && this.model.MinimumQuantity && this.model.QuantityIncrement) {

          let currentValue = quantity - this.model.MinimumQuantity;

          if (currentValue <= 0) { // valida se valor <= 0
            currentValue = this.model.MinimumQuantity;

          } else { // valida se valor > 0

            let isInteger = currentValue % this.model.QuantityIncrement === 0 ? true : false;

            if (!isInteger) {
              currentValue = (Math.ceil(currentValue / this.model.QuantityIncrement) * this.model.QuantityIncrement);
            }
            currentValue = currentValue + this.model.MinimumQuantity;
          }
          this.detailForm.controls['OrderedQuantity'].setValue(currentValue);
        }
      }
    }
    this.updateOrderedQuantityUN();
  }
  // #endregion OrderQtdMethods

  // #region OtherReferences
  initOtherReference(reference: OrderReference = null) {
    if (reference) {
      return this.formBuilder.group({
        'ReferenceType': [reference.ReferenceType, ''],
        'ReferenceValue': [reference.ReferenceValue, ''],
        'ReferenceDate': [reference.ReferenceDate, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])]
      });
    } else {
      return this.formBuilder.group({
        'ReferenceType': ['', ''],
        'ReferenceValue': ['', ''],
        'ReferenceDate': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])]
      });
    }
  }

  addOtherReference(newInsert: boolean, objectInsert: OrderReference = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      const refeCtrl = this.initOtherReference();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(refeCtrl);
      refeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedOtherReferences(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (objectInsert) { // usado quando faz reset
        const refeCtrl = this.initOtherReference(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(refeCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedOtherReferences(value);
          });
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeOtherReference(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
    control.removeAt(i);
  }

  /* tslint:disable:member-ordering */
  formErrorsOtherReferences: Array<string> = new Array<string>();
  formErrorsOtherReferencesParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedOtherReferences(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsOtherReferences = new Array<string>();
    this.formErrorsOtherReferencesParam = new Array<string>();
    for (const field in this.validationMessagesOtherReferences) {
      if (this.validationMessagesOtherReferences.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('OtherReferences');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesOtherReferences[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsOtherReferences.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsOtherReferencesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsOtherReferencesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion OtherReferences

  // #region Notes
  initNote(note: OrderNote = null) {
    // iniciar validacao do formulario de notsa
    if (note) {
      return this.formBuilder.group({
        'NoteType': [note.NoteType, ''],
        'NoteValue': [note.NoteValue, '']
      });

    }
    return this.formBuilder.group({
      'NoteType': ['', ''],
      'NoteValue': ['', '']
    });
  }

  addNote(newInsert: boolean, objectInsert: OrderNote = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Notes'];
      const noteCtrl = this.initNote();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(noteCtrl);
      noteCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedNotes(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Notes'];
      if (objectInsert) { // usado quando faz reset
        const noteCtrl = this.initNote(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(noteCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedNotes(value);
          });
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeNote(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['Notes'];
    control.removeAt(i);
  }
  /* tslint:disable:member-ordering */
  formErrorsNotes: Array<string> = new Array<string>();

  formErrorsNotesParam: Array<string> = new Array<string>();


  /* tslint:enable:member-ordering */

  onValueChangedNotes(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsNotes = new Array<string>();
    this.formErrorsNotesParam = new Array<string>();
    for (const field in this.validationMessagesNotes) {
      if (this.validationMessagesNotes.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Notes');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesNotes[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsNotes.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsNotesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsNotesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Notes

  // #region Métodos Gerais e Comuns
  resetForm() { // reset do form (quando faz cancelar mantém valores da BD + criados não guardados)
    if (this.model) {
      this.detailForm.reset(this.model);

      let initialValues = null;
      // colocar os arrays com os valores iniciais

      // outras referências
      const controlsO = <UntypedFormArray>this.detailForm.get('OtherReferences');
      while (controlsO.length) { // remover todos(as)
        controlsO.removeAt(controlsO.length - 1);
      }
      if (this.model.OtherReferences != null) { // adicionar
        initialValues = this.model.OtherReferences;
        initialValues.forEach((reference: OrderReference) => {
          this.addOtherReference(false, reference);
        });
      }
    }
  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
