import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { Task } from '../models/task';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class TaskService{
    private _controller = "Tasks"
    datatableParametersImports: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 50, [[0, 'asc']], 0);
    datatableParametersHistory: DatatableParameters = new DatatableParameters([null, null, null, null, null], 10, [[1, 'asc']], 0);
    private tasksType: Array<any>;
    private task: any;
    constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService){
    }
    
    //Settings
    showColumnRunningOrder : boolean;


    

    getAllTasks(): Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL + this._controller + '/GetAllTasks').pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    getAllTasksTypes(): Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL + this._controller + '/GetAllTasksTypes').pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    getTasksById(id: number):Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/GetTaskById', {id:id}).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    delete(id:number):Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/Delete', {id:id}).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    create(model: Task): Observable<ReturnStatusHtml> {
        let formData : FormData = new FormData();

        formData.append('task', JSON.stringify(model));
        
        return this.http.put(SERVICE_URL + this._controller + '/Create', formData).pipe(map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    update(model: Task): Observable<ReturnStatusHtml>{
        let formData: FormData = new FormData();

        formData.append('task', JSON.stringify(model));
        

        return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    reprocess(type: string):Observable<ReturnStatusHtml>{
        return this.http.post(SERVICE_URL + this._controller + '/Reprocess', {type:type}).pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    reprocessUpdateInventory():Observable<ReturnStatusHtml>{
        return this.http.get(SERVICE_URL + this._controller + '/ReprocessUpdateInventory').pipe(
            map((response:any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    //Settings
    getTasks_showColumnRunningOrder(defaultValue: boolean): boolean {
        if(typeof this.showColumnRunningOrder === 'undefined'){
          let settingValue = this.authenticationService.getSettingPortal('Tasks','ShowColumnRunningOrder'); 
          if(settingValue != null){
            this.showColumnRunningOrder = settingValue;
          } else{
            this.showColumnRunningOrder = defaultValue;
          }
        }
        return this.showColumnRunningOrder;
      }
}