import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ValueIteratee } from 'lodash';

@Pipe({
  name: 'uniqFilter',
  pure: false
})
@Injectable()
export class UniquePipe implements PipeTransform {
  transform(items: any[], args: ValueIteratee<any>): any {
    // filter items array, items which match and return true will be kept, false will be filtered out

    return _.uniqBy(items, args);
  }
}
