import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mdSelectContainsPipe'
})
export class MdSelectContainsPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    if (args[0] && args[1]) {
      let fieldName = args[0];
      let fieldValue = args[1].toUpperCase();

      return value.filter(e => e[fieldName].toUpperCase().indexOf(fieldValue) !== -1);
    } else {
      return value;
    }
  }
}
