import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateValueService } from 'src/app/services/translate-value.service';

@Component({
  selector: 'error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @Input() control: AbstractControl;

  /** Indica se valida se o form control esta touched */
  @Input() checkForTouched: boolean = true;

  /** Permite dar overide a uma mensagem de erro ou incluir novos erros e as suas mensagens de erro */
  @Input() specificErrors: { [key: string]: string }[];

  constructor(private _translateValueService: TranslateValueService) { }

  ngOnInit(): void { }

  get error() {
    if (!this.showError())
      return null;

    return this.validateAndGetErrorMessage();
  }

  /** Indica se mostra os erros, se o formcontrol tiver erros e tiver sido tocado ou nao tiver a validacao de ser tocado mostra */
  showError(): boolean {
    return this.control?.errors && (this.control.touched || !this.checkForTouched);
  }

  validateAndGetErrorMessage() {
    const controlErrors = Object.keys(this.control.errors);

    const firstError = controlErrors?.at(0);

    const getErrorTranslation = (errors: object) => {
      if ('params' in this.control.errors) {
        return this._translateValueService.get(errors[firstError], JSON.parse(this.control.errors['params']));
      }
      return this._translateValueService.get(errors[firstError]);
    }

    if (this.specificErrors && firstError in this.specificErrors) {
      return getErrorTranslation(this.specificErrors);
    }
    else if (firstError in this.allErrors) {
      return getErrorTranslation(this.allErrors);
    }
    return null;
  }

  allErrors = {
    required: 'FIELD_REQUIRED_',
    lengthMax: 'LENGTH_MAX',
    invalidDate: 'INVALID_DATE',
    invalidRangeDates: 'DATES_BETWEEN',
    areEqual: 'DOES_NOT_ALLOW_SAME_DATES',
    email: 'EMAIL_INVALID',
    numberVal: 'NUMBER_INVALID',
    numberMin: 'NUMBER_MIN_ERROR',
    numberOfDecimalPlaces: 'NUMBER_DECIMAL_ERROR',
    numberMinMax: 'NUMBER_MINMAX_ERROR',
    numberMax: 'NUMBER_MAX_ERROR',
    lessThenCurrentDate: 'DATE_INFERIOR_THAN_CURR_DATE',
    higherThenCurrentDate: 'DATE_HIGHER_THAN_CURR_DATE'
  }
}