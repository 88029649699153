export class PartnerIBAN {
    /** Identificador da entidade */
    ID: number;
  
    /** IBAN que pertence à empresa */
    IBAN: string;
  
    /** ID que refere a tabela de Partners */
    PartnerID: number | null;
  
    /** SWIFT que pertence à empresa */
    Swift: string;
  
    /** IBAN predefinido */
    isDefault: boolean;
  
    /** Observações */
    Observations: string;
  
    /** ID que refere o Owner */
    OwnerID: number;

    constructor(ID?: number, iban?: string ) {
        this.ID = ID;
        this.IBAN = iban;
    }
  }