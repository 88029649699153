import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';

@Component({
  templateUrl: './delete-modal.html'
})
export class DeleteModalComponent {
  message = 'LAYOUT.ARE_YOU_SURE';

  constructor(public dialogRef: MatDialogRef<DeleteModalComponent>, private _cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) data: {text: string}) {

    // parametros que vai receber
    if(data?.text)
    {
      this.message = data.text;
    }
   }

}
