import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray, Form, UntypedFormControl, RequiredValidator } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModelValidators } from '../../../../models/validators/validators';
import { TranslateValueService } from '../../../../services/translate-value.service';
import { EmailModalParam } from 'src/app/models/email-modal-param';
import { ProcessesService } from 'src/app/services/processes.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { TicketsConfigsService } from 'src/app/services/tickets-configs.service';
import { GenericTypesConfig, TransformModel } from 'src/app/models/generic-types-config';
import { TranslateService } from '@ngx-translate/core';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { Filter, FiltersType } from 'src/app/modules/dockmodal.module';
import { ChooseModalComponent } from 'src/app/components/shared/choose-modal/choose-modal.component';
import { Country } from 'src/app/models/country';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ROLES } from 'src/app/constants/global';
import { CompanyService } from 'src/app/services/company.service';
import { Dropdown } from 'src/app/models/dropdown';
import { GenericType } from 'src/app/models/generic-type';

declare var Functions: any;
@Component({
    templateUrl: './ticket-config-modal.html'
})
export class TicketConfigModalComponent implements OnInit, OnDestroy {
    submitted: boolean = false;
    noError: boolean = true;
    form: UntypedFormGroup;
    formErrors = {};
    validationMessages = {};
    documents: any;
    Data: EmailModalParam;
    checkboxValues: any = [];
    originalIds: any = [];
    copyIds: any = [];
    id: number;
    processNumber: string = '';
    destroy$: Subject<boolean> = new Subject<boolean>();
    documentType: string = '';
    emails: string = '';
    documentTypeID: number = 0;
    model: GenericTypesConfig = new GenericTypesConfig();
    allCountries: Array<Country> = new Array<Country>();

    tranfModel = new TransformModel();

    showCompany: boolean = true;
    showCompanySendEmail: boolean = true;
    showCountry: boolean = true;
    showTDConnect: boolean = true;
    genericTypeContext: string = '';

    companiesList: Array<any> = new Array<any>();
    types: GenericType[] = [];

    constructor(public dialogRef: MatDialogRef<TicketConfigModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _translateValueService: TranslateValueService,
        public ticketsConfigsService: TicketsConfigsService,
        private _cdr: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private ticketsConfigs: TicketsConfigsService,
        private translateService: TranslateService,
        private translateValueService: TranslateValueService,
        private companyService: CompanyService,
        private dialog: MatDialog,
        private commonService: CommonService) {
        this.id = data.id;
        this.validationMessages = {
            'Emails': {
                'required': 'FIELD_REQUIRED_'
            }
        }
    }

    ngOnInit(): void {
        let arrayObservables = [];
        this.genericTypeContext = this.ticketsConfigs.get_genericTypeContext(this.genericTypeContext);

        arrayObservables.push(this.commonService.countries.pipe(map((response: Array<Country>) => this.allCountries = response))); // 3
        arrayObservables.push(this.commonService.getGenericTypes(this.genericTypeContext).pipe(map((response: Array<GenericType>) => this.types = response)));
        forkJoin(arrayObservables).pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.id) {
                this.ticketsConfigs.getGenericTypesConfig(this.id).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
                    if (response.ReturnStatus.Successfull) {
                        if (response.ReturnStatus.ReturnObject != null) {
                            this.model = response.ReturnStatus.ReturnObject;
                            this.buildForm(this.model);
                        }
                    }
                });
            } else {
                this.buildForm(this.model)
                this.addEmail();
            }
           
        });
        this.showCompany =  this.ticketsConfigs.get_showCompany(true);
        this.showCountry =  this.ticketsConfigs.get_showCountry(true);
        this.showTDConnect =  this.ticketsConfigs.get_showTDConnect(true);
        this.showCompanySendEmail = this.ticketsConfigs.get_showCompanySendEmail(false);
        this.companyService.getPartners(null, false, true).pipe(takeUntil(this.destroy$)).subscribe((response: Array<any>) => this.companiesList = response);
    }

    buildForm(model: GenericTypesConfig): void {
        this.form = this._formBuilder.group({
            'ID': [model.ID],
            'GenericTypeID': [model.GenericTypeID, Validators.required],
            'Emails': this._formBuilder.array([]),
            'OwnerID': [model.OwnerID],
            'SendConnectTD': [model.SendConnectTD],
            'Country': [model.Country],
            'GenericTypeDescription': [model.GenericTypeDescription],
            'OwnerInternalID': [model.OwnerInternalID],
            'OwnerInternalName': [model.OwnerInternalName],
            'Active': [!model.Inactive],
            'CompanyID':[model.CompanyID],
            'CompanyName':{ value: model.CompanyName, disabled: true }
        });
        this.initFormArray();
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChanged(value);
        }); // deteta se houve alterações no form
        this.onValueChanged(); // (re)set validation messages now
    }

    initFormArray() {
        const control = <UntypedFormArray>this.form.controls['Emails'];
        let emailsList: Array<string> = new Array<string>();
        if (this.model.Email) {
            emailsList = this.model.Email.split(';');
            emailsList.forEach(element => {
                control.push(this._formBuilder.group({
                    Email: element
                }));
            });
        }
    }

    onValueChanged(value?: any) {
        if (!this.form) { return; }
        const form = this.form;
        for (const field in this.validationMessages) {
            if (this.validationMessages.hasOwnProperty(field)) {
                // clear previous error message (if any)
                this.formErrors[field] = '';
                const control = form.get(field);
                if ((this.submitted && (control && !control.valid && control.enabled)) ||
                    (!this.submitted && (control && control.dirty && !control.valid))
                ) {
                    this.noError = false;
                    const messages = this.validationMessages[field];
                    for (const key in control.errors) {
                        if (messages.hasOwnProperty(key)) {
                            this.formErrors[field] = messages[key] + ' ';
                            control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                        }
                    }
                }
            }
        }
    } // fim onValueChanged

    save() {
        this.submitted = true;
        this.noError = true;
        this.onValueChanged(this.form.valueChanges);

        let emails: Array<string> = [];
        let control = <UntypedFormArray>this.form.get('Emails');
        control.controls.forEach(element => {
            emails.push(element.value.Email);
        });

        let email: string = emails.join(";");
        if (email.length <= 0) {
            this.noError = false;
        }
        this.model = this.tranfModel.revertObject(this.form.getRawValue());
        this.model.Email = email;
        this.model.Inactive = !this.form.get('Active').value;
        if (this.noError) {
            this.ticketsConfigsService.getCountGenericTypesConfig(this.model.GenericTypeID, this.model.OwnerID == null ? '' : this.model.OwnerID, this.model.CompanyID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
                if (response.ReturnStatus.Successfull) {
                    if (response.ReturnStatus.ReturnObject > 0) {
                        Functions.gritter(this.translateValueService.get('RECORD_ALREADY_EXISTS_EMAILS_CONFIGURATION'), 'danger'); 
                        return;
                    } else {
                        this.dialogRef.close(this.model);
                        return;
                    }
                  
                } else {
                    Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger'); 
                    return;
                }
            });
        } else {
            Functions.gritter(this.translateValueService.get('ONE_EMAIL_REQUIRED'), 'danger');
            return;
        }
    }

    isChecked(id: number): boolean {
        let value = false;
        let BreakException = {};
        try {
            this.checkboxValues.forEach((item, index) => {
                if (item != null) {
                    if (item.id === id) {
                        value = item.checked;
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            // OUT OF FOREACH
        }
        return value;
    }

    toggleCheck(document: any): void {
        let BreakException = {};
        try {
            this.checkboxValues.forEach((item, index) => {
                if (item != null) {
                    if (item.id === document.ID) {
                        item.checked = !item.checked;
                        if (item.checked) {
                            let indexO = this.originalIds.indexOf(document.ID);
                            let indexC = this.copyIds.indexOf(document.ID);

                            if (indexC === null && document.DocumentVersion > 1) {
                                this.copyIds.push(document.ID);
                            }
                            if (indexO === null && document.DocumentVersion === 1) {
                                this.originalIds.push(document.ID)
                            }

                        } else {
                            let indexO = this.originalIds.indexOf(document.ID);
                            let indexC = this.copyIds.indexOf(document.ID);
                            if (indexO > -1) {
                                this.originalIds.splice(indexO, 1);
                            }
                            if (indexC > -1) {
                                this.copyIds.splice(indexC, 1);
                            }
                        }
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            // OUT OF FOREACH
        }
    }

    addEmail() {
        const control = <UntypedFormArray>this.form.get('Emails');
        control.push(this._formBuilder.group({
            Email: ''
        }));
    }

    delete(index: number) {
        const control = <UntypedFormArray>this.form.get('Emails');
        control.removeAt(index);
    }

    selectTDCompaniesInfo() {
        // Só permite alterar os valores se o formulário for de edição
        if (this.form.enabled) {
            this.translateService.get(['SEARCH', 'COMPANY_CODE', 'NAME']).subscribe(response => {
                let aoColumns = [
                    { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['COMPANY_CODE'] },
                    { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] },
                    { 'data': 'ID' }
                ], columnDefs = [
                    { 'targets': [2], 'visible': false } // colocar como hidden
                ], dialogRef = this.dialog.open(ChooseModalComponent, {
                    data: new ChooseModalParam(null, 'Company/GetConsumersForDatatable',
                        response['SEARCH'], aoColumns, columnDefs, null, null, null,
                        [
                            //new Filter('filterServiceProviders', FiltersType.Boolean, 'filterServiceProviders', 'filterServiceProviders', null, true),
                            new Filter('providerID', FiltersType.Number, 'providerID', 'providerID', null, 0),
                            new Filter('filterByRole', FiltersType.Number, 'filterByRoles', 'filterByRoles', null, ROLES.SUPPLIER),
                            new Filter('filterServiceProvider', FiltersType.Boolean, 'filterServiceProvider', 'filterServiceProvider', null, true),
                        ])
                });

                dialogRef.afterClosed().subscribe((result: any) => {
                    if (result) {
                        this.form.get('OwnerID').setValue(result.ID);
                        this.form.get('OwnerInternalID').setValue(result.InternalID);
                        this.form.get('OwnerInternalName').setValue(result.Name);
                    }
                });
            });
        }
    }

    clearCompanies() {
        if (this.form.enabled) {
            this.form.get('OwnerID').setValue(null);
            this.form.get('OwnerInternalID').setValue(null);
        }
    }

    selectCompanyIDEmail() {

        this.translateService.get(['SELECT_COMPANY', 'CODE', 'NAME']).subscribe(response => {
            let that = this;

            let aoColumns = [
            { 'data': 'ID' }, // 0
            { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
            { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
            ];

            let columnDefs = [
            { 'targets': [0], 'visible': false }, // colocar como hidden
            { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
            ],

            dialogRef = this.dialog.open(ChooseModalComponent, {
                data: // dados que vai enviar para o componente da modal
                new ChooseModalParam(this.companiesList, null, response['SELECT_COMPANY'], aoColumns, columnDefs, null, 1,
                    null, null),
                disableClose: true // nao permitir fechar modal com escape ou clique fora
            });

            dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                let resultID = parseFloat(result);
                if (resultID != null || resultID.toString().length > 0) {
                let index = this.companiesList.findIndex((r: any) => r.ID === resultID);
                this.form.controls['CompanyID'].setValue(this.companiesList[index].ID);
                this.form.controls['CompanyName'].setValue(this.companiesList[index].Name);
                }
            }
            });
        });
    }

    clearCompaniesEmail() {
        if (this.form.enabled) {
            this.form.get('CompanyID').setValue(null);
            this.form.get('CompanyName').setValue(null);
        }
    }

    ngOnDestroy() { }
}