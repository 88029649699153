import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';

/**
 * Validar o formulário e retorna a estrutura do FormErrors preenchida com os erros encontrados.
 * @param  {FormGroup} form
 * @param  {any} formErrors
 * @param  {any} validationMessages
 * @param  {boolean} isSubmited
 * @returns any
 */
export function validateForm(form: UntypedFormGroup, formErrors: any, validationMessages: any, isSubmited: boolean): any {
  if (!form) { return ''; }

  for (const field in formErrors) {
    if (formErrors.hasOwnProperty(field)) {
      const control = form.get(field);

      if (control instanceof UntypedFormControl) {
        // clear previous error message (if any)
        formErrors[field] = '';
        if ((control && control.dirty && !control.valid) || (control && control.touched && !control.valid) || (control && isSubmited && !control.valid)) {
          const messages = validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key) && messages.hasOwnProperty(key)) {
              formErrors[field] += messages[key] + ' ';
              control.markAsTouched();
            }
          }
        }
      } else if (control instanceof UntypedFormArray) {
        // Se for FormArray faz pedido recursivo até chegar ao FormGroup
        for (let index = 0; index < formErrors[field].length; index++) {
          formErrors[field][index] = validateForm(<UntypedFormGroup>control.at(index), formErrors[field][index], validationMessages[field], isSubmited);
        }
      }
    }
  }

  return formErrors;
}

/**
 * Validar o array do formulário e retorna a estrutura do FormErrors preenchida com os erros encontrados.
 * @param  {FormArray} formArray
 * @param  {any[]} formErrors
 * @param  {any} validationMessages
 * @param  {boolean} isSubmited
 * @returns any
 */
export function validateFormArray(formArray: UntypedFormArray, formErrors: any[], validationMessages: any, isSubmited: boolean): any {
  if (!formArray) { return ''; }

  for (let i = 0; i < formArray.length; i++) {
    formErrors[i] = validateForm(<UntypedFormGroup>formArray.at(i), formErrors[i], validationMessages, isSubmited);
  }

  return formErrors;
}

/**
 * Função genérica que valida o formulário e retorna a sua estrutura com as keys que têm erro.
 * @param  {FormGroup|FormArray} form
 * @returns any
 */
export function getAllErrors(form: UntypedFormGroup | UntypedFormArray): { [key: string]: any; } | null {
  let hasError = false;
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors = (control instanceof UntypedFormGroup || control instanceof UntypedFormArray)
      ? this.getAllErrors(control)
      : control.errors;
    if (errors) {
      acc[key] = errors;
      hasError = true;
    }
    return acc;
  }, {} as { [key: string]: any; });
  return hasError ? result : null;
}


/**
 * Junta numa STRING todos os erros encontrados no FormErrors, agrupados por tags <li>
 * @param  {any} formErrors
 * @returns string
 */
export function joinAllErrors(formErrors: any): string {
  let errors: string = '';

  if (formErrors instanceof Array) {
    formErrors.forEach(formError => {
      errors += joinAllErrors(formError);
    });
  } else {
    for (const field in formErrors) {
      if (formErrors.hasOwnProperty(field) && formErrors[field].length > 0) {

        if (formErrors[field] instanceof Array) {
          formErrors[field].forEach(formError => {
            errors += joinAllErrors(formError);
          });
        } else {
          errors += '<li>' + formErrors[field] + '</li>';
        }
      }
    }
  }

  return errors;
}
