import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ChooseModalComponent } from 'src/app/components/shared/choose-modal/choose-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { PaymentCondition } from 'src/app/models/payment-condition';
import { PaymentConditionService } from 'src/app/services/payments/payment-conditions.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CostCenterService } from 'src/app/services/costCenter.service';
import { CostCenterConfigService } from 'src/app/services/costCenterConfig.service';
import { CostCenter } from 'src/app/models/costCenter';
import { ROLES } from 'src/app/constants/global';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'cost-center-modal',
  templateUrl: './cost-center-modal.component.html'
})
export class CostCenterModalComponent implements ControlValueAccessor, OnInit, OnChanges {
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedCostCenter: CostCenter;
  disabled: boolean;

  @Input() documentType: string = null;
  @Input() value: number = 0;
  @Input() showLabel: boolean = true;
  @Input() enabled: boolean = true;
  
  @Output() change = new EventEmitter<number>(); //Change do Form
  @Output() valueChange: EventEmitter<number> = new EventEmitter(); //Change do ng model
  private _costCenters: CostCenter[];

  constructor(private _costCenterConfigService: CostCenterConfigService, private _costCenterService: CostCenterService, @Optional() public ngControl: NgControl, private _translateService: TranslateService, private _dialog: MatDialog, private _authenticationService: AuthenticationService) {
    if(ngControl)
      ngControl.valueAccessor = this;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.value?.currentValue != changes.value?.previousValue && !changes.value?.isFirstChange())
    {
      await this.getSelectedCostCenter();
    }
  }

  async ngOnInit(): Promise<void> {
    // se tiver desativado o form (consulta) so vai buscar o registo guardado caso constrario vai buscar todos
    await this.getSelectedCostCenter();
  }

  private async getSelectedCostCenter() {
    if (!this._costCenters && !this.disabled)
      await this.getCostCenters();

    if (this.value) {
      this.selectedCostCenter = this._costCenters.find(pc => pc.ID == this.value);
    }
    else if (this.selectedCostCenter?.ID) {
      if (this.disabled) {
        const response = await this._costCenterService.get(this.selectedCostCenter.ID).toPromise();

        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject && response.ReturnStatus.ReturnObject.CostCenter)
          this.selectedCostCenter = response.ReturnStatus.ReturnObject.CostCenter;
      }
      else {
        this.selectedCostCenter = this._costCenters.find(pc => pc.ID === this.selectedCostCenter.ID);
      }
    }
  }

  async getCostCenters() {
  
    const response = await this._costCenterConfigService.getCostCenters(2, null).toPromise();
    
    if (response && response.ReturnStatus.ReturnObject) {
      this._costCenters = response.ReturnStatus.ReturnObject;
    }
  }

  selectCostCenter() {
    this._translateService.get(['CODE', 'COST_CENTER', 'SELECT_COST_CENTER']).subscribe(response => {
      const aoColumns = [
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'] },
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['COST_CENTER'] },
      ];

      const columnDefs = [
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ];

      const dialogRef = this._dialog.open(ChooseModalComponent, {
        data: new ChooseModalParam(this._costCenters, null, response['SELECT_COST_CENTER'], aoColumns, columnDefs, null, 1, null),
        disableClose: true // nao permitir fechar modal com escape ou clique fora
      });

      dialogRef.afterClosed().subscribe((costCenterID: number) => {
        if (costCenterID) {
          const found = this._costCenters.find(pc => pc.ID === costCenterID);
          if (found) {
            this.selectedCostCenter = found;
            
            if(this.ngControl)
            {
              this.ngControl.control.setValue(found.ID);
              this.change.emit(costCenterID);
            }
            else
              this.valueChange.emit(costCenterID);
            
          }
        }
      });
    });
  }

  onChanged: (costCenterID: number) => void;
  onTouched: () => void;

  async writeValue(value: number): Promise<void> {
    if (value) {
      if (this._costCenters)
        this.selectedCostCenter = this._costCenters?.find(cc => cc.ID === value);
      else
        this.selectedCostCenter = new CostCenter(value);
    } else if (value == null) {
      this.selectedCostCenter = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
}
