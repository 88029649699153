import { map } from "rxjs/operators";
import { ReturnStatusHtml } from "../models/returnStatus";
import { Observable, of } from "rxjs";
import { ErrorTreatmentFunctions } from "../modules/treatments.module";
import { SERVICE_URL } from "../constants/global";
import { MasterService } from "./master.service";
import { HttpClientCustom } from "./http-client";
import * as _ from "lodash";

export class ServiceMethods {
    constructor(protected _errorTreat: ErrorTreatmentFunctions, protected _masterService: MasterService, protected _http: HttpClientCustom, protected _controller: string) { }

    getUrl(action: string, controller?: string) {
        return SERVICE_URL + (controller ?? this._controller) + '/' + action;
    }

    getData<T>({ property, url, params, controler }: { property: string; url: string; params?: object; controler?: string; }): Observable<T> {
        if (this[property])
            return of<T>(this[property]);

        return this.getComplements<T>(url, params, controler).pipe(map((response: ReturnStatusHtml<T>) => {
            try {
                if (response.ReturnStatus.Successfull) {
                    this[property] = response.ReturnStatus.ReturnObject as T;
                    return this[property];
                } else {
                    this._errorTreat.treatErrorResponseSendEmpty(response);
                }
            } catch (error) {
                this._masterService.handleError(error);
            }
        }));
    }

    getDataArray<T>({ property, key, url, params, controler }: { property: string; key: any; url: string; params?: object; controler?: string; }): Observable<T> {
        this[property] ??= [];

        // validar se encontra dados com esta key
        const existingData = this[property].find(item => _.isEqual(item.key, key));

        if (existingData)
            return of<T>(existingData.value);

        return this.getComplements<T>(url, params, controler).pipe(map((response: ReturnStatusHtml<T>) => {
            try {
                if (response.ReturnStatus.Successfull) {
                    const newData = { key, value: response.ReturnStatus.ReturnObject };

                    // guardar novos valores
                    this[property].push(newData)

                    return newData.value;
                } else {
                    this._errorTreat.treatErrorResponseSendEmpty(response);
                }
            } catch (error) {
                this._masterService.handleError(error);
            }
        }));
    }

    /**
     * Limpar a cache de uma variavel
     *
     * @param {string} property propiedade a apagar 
     * @param {*} [key] key da propiedade a apagar
     */
    clearCache(property: string, key?: any) {
        if (!property) 
            return;

        // se tiver key pesquisar por ela e apagar os valores la colocados
        if (key[property]) { 
            const index = (this[property] as any[]).findIndex(item => _.isEqual(item.key, key));

            if(index != -1)
                this[property].splice(index, 1);
            return;
        }

        this[property] = null;
        return;
    }

    getComplements<T>(action: string, params?: object, controller?: string): Observable<ReturnStatusHtml<T>> {
        let url = SERVICE_URL + (controller ?? this._controller) + '/' + action;

        if (params) {
            const paramsString = Object.keys(params)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
                .join('&');
            url += '?' + paramsString;
        }

        return this._http.get(url).pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
    }

    postComplements<T>(action: string, params?: object, formDataObject?: object, controller?: string): Observable<ReturnStatusHtml<T>> {
        const url = SERVICE_URL + (controller ?? this._controller) + '/' + action;

        const formData = new FormData();
        if (formDataObject) {
            for (const key in formDataObject) {
                if (formDataObject.hasOwnProperty(key)) {
                    formData.append(key, JSON.stringify(formDataObject[key]));
                }
            }
        }

        return this._http.post(url, formData ?? params ?? {}).pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
    }

    deleteComplements<T>(action: string, params?: object, controller?: string): Observable<ReturnStatusHtml<T>> {
        let url = SERVICE_URL + (controller ?? this._controller) + '/' + action;

        if (params) {
            const paramsString = Object.keys(params)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
                .join('&');
            url += '?' + paramsString;
        }

        return this._http.delete(url).pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
    }
}