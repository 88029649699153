// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ***__***_________  MODELOS _________ ***__***
import { CompanyOrderTypeWorkflowMap } from '../../models/orderWorkflow';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './companyOrderTypeWorkflow-modal.html'
})
export class CompanyOrderWorflowTypeModalComponent implements OnInit, OnDestroy {

  ordersWorkflowList: Array<any> = new Array<any>();
  orderTypes: Array<any> = new Array<any>();

  companyOrderTypeWorkForm: UntypedFormGroup;
  model: CompanyOrderTypeWorkflowMap;

  noError: boolean = true;
  submitted: boolean = false;
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  formErrors: Array<string> = new Array<string>(); // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  allowEdit: boolean; // se tem permissoes de edicao
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public dialogRef: MatDialogRef<CompanyOrderWorflowTypeModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.ordersWorkflowList = data.ordersWorkflowList;
    this.orderTypes = data.orderTypes;
    this.companyOrderTypeWorkForm = data.docTypeWorkForm;
    this.model = data.docTypeWorkModel;
    this.validationMessages = data.validationMessagesDetails;
    this.allowEdit = data.allowEdit;
  }

  ngOnInit(): void {
    this.companyOrderTypeWorkForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    });
  }

  // #region CompanyOrderTypeWorkForm
  onValueChanged(value?: any) {
    if (!this.companyOrderTypeWorkForm) { return; }
    const form = this.companyOrderTypeWorkForm;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  save() {
    if (this.allowEdit) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.onValueChanged();

      if (this.noError) {
        this.dialogRef.close(this.companyOrderTypeWorkForm); // nao pode enviar so o value por causa dos campos disabled
      }
      this.submitted = false;
    }
  }
  // #endregion CompanyOrderTypeWorkForm

  // #region Métodos Gerais e Comuns

  resetForm() {
    this.companyOrderTypeWorkForm.reset(this.model);
  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
