import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './invoicesFilters-modal.html'
})
export class InvoicesFilterModalComponent {

  product: string;

  constructor(
    public dialogRef: MatDialogRef<InvoicesFilterModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    if (data && data.product) {
      this.product = data.product;
    }
  }

}
