export class DashboardPanel {
  public static empty(): DashboardPanel {
    return new DashboardPanel(0, null, null, 0, null, []);
  }

  constructor(
    public ID: number,
    public PanelDescription: string,
    public PanelSize: string,
    public PanelOrder: number,
    public DisplayArea: number,
    public Charts: any[],
    public Roles?: string,
    public Admin?: boolean,
    public ServiceProvider?: boolean,
    public PanelLabel?: string,
    public PanelIcon?: string
  ) { }
}

export const Sizes: any[] = [
  { Label: '1', Value: 'col-sm-1' },
  { Label: '2', Value: 'col-sm-2' },
  { Label: '3', Value: 'col-sm-3' },
  { Label: '4', Value: 'col-sm-4' },
  { Label: '5', Value: 'col-sm-5' },
  { Label: '6', Value: 'col-sm-6' },
  { Label: '7', Value: 'col-sm-7' },
  { Label: '8', Value: 'col-sm-8' },
  { Label: '9', Value: 'col-sm-9' },
  { Label: '10', Value: 'col-sm-10' },
  { Label: '11', Value: 'col-sm-11' },
  { Label: '12', Value: 'col-sm-12' }
];

export const DisplayAreas: any[] = [
  { Label: 'Dashboard', Value: 1 },
  { Label: 'NAVIGATION_BAR', Value: 2 }
];

export enum DisplayAreasEnum { Dashboard = 1, NavigationBar = 2 }
