import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientCustom } from './http-client';
import { ReturnStatusHtml } from '../models/returnStatus';
import { AuthenticationService } from './authentication.service';
import { MasterService } from './master.service';
import { SERVICE_URL } from '../constants/global';
// ***__***_________  MODULOS _________ ***__***
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class PodService {


  private _podStates: Array<any>;

  // Filtros
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [0, 'desc'], 0);
  filter: any = new Object();

  private _controller: string = 'Pod';

  constructor(private http: HttpClientCustom, private authenticationService: AuthenticationService,
    private _masterService: MasterService, private _dataTableF: DataTableFunctions) {
  }

  public get podStates(): Observable<Array<any>> {
    if (this._podStates === undefined) {
      return this.getComplements('GetAllStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._podStates = response.ReturnStatus.ReturnObject.States;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._podStates);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter confirmação pelo id
   * @param  {number} id
   * @returns Observable
   */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar confirmação de entrega
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeletePod?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Devolver excel das confirmações de entrega
   * @param  {any} params Parâmetros da Datatable
   * @param  {string} moreFilters filtros alem das colunas da datatable
   * @returns Observable
   */
  exportListToExcel(params: any, moreFilters: any = {}): Observable<any> {
    moreFilters.parameters = params;
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelPodsList', moreFilters);
  }

  /**
   * Devolver excel de uma confirmação de entrega
   * @param  {number} id ID do documento
   * @returns Observable
   */
  exportToExcel(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelPod?id=' + id);
  }
    /**
   * Devolver pdf de uma confirmação de entrega
   * @param  {number} id ID do documento
   * @returns Observable
   */
  exportToPDF(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFPod?id=' + id);
  }
}
