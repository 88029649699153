import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../http-client';
import { SERVICE_URL } from '../../constants/global';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { WorkflowStep } from '../../models/workflow-step';
import { MasterService } from '../master.service';
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';
import { DatatableParameters } from '../../models/datatable-parameters';
import { Dropdown } from '../../models/dropdown';
import { GenericType } from 'src/app/models/generic-type';
import { GenericDocument } from 'src/app/models/generic-document';
import { Infraction } from 'src/app/interfaces/infraction';

// Contexto
export const CONTEXT_ALTICE_FINE = 'AlticeFine';
// Permissões
export const enum AlticeFineRole { SCCOR_CLIENTE = 401, SCCOR_JURISTA = 402 }
// Estado
export const enum AlticeFineState { RECEPTION_OF_DOCUMENTS = 400, AUTO_PAGO = 408, AUTO_PRESCRITO = 416, ARQUIVAR = 419, CLIENT_RECEPTION_OF_DOCUMENTS = 420 }
// Tipo
export const enum AlticeFineType { ADMINISTRATIVE_OFFENSE = 140 }
// Lista de Dropdowns
export const enum AlticeFineDropdown {
  EntidadeAutuante = 'EntidadeAutuante',
  TipoArg = 'TipoArg',
  TipoDomFiscalArg = 'TipoDomFiscalArg',
  CategoriaVeiculo = 'CategoriaVeiculo',
  TipoVeiculo = 'TipoVeiculo',
  TipoCond = 'TipoCond',
  ComarcaInfracao = 'Municipality',
  DistritoInfracao = 'District'
}

@Injectable()
export class FineService {
  private _controller: string = 'GenericDocument';
  private _types: GenericType[];
  // Listas
  public dropdownLists: Dropdown[][] = [];

  filter: any = {};
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[2, 'desc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions) { }

  get(id: number, block: string = 'false'): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?context=' + CONTEXT_ALTICE_FINE + '&id=' + id + '&block=' + block)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: GenericDocument): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(CONTEXT_ALTICE_FINE));
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

   update(model: GenericDocument , ignoreFieldErrors: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(CONTEXT_ALTICE_FINE));
    formData.append('entity', JSON.stringify(model));
    formData.append('ignoreFieldErrors', JSON.stringify(ignoreFieldErrors));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateBloquedBy(documentId: number, userId: number = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', CONTEXT_ALTICE_FINE);
    formData.append('id', documentId.toString());
    if (userId > 0) {
      formData.append('userid', userId.toString());
    }

    return this.http.post(SERVICE_URL + this._controller + '/UpdateBlockedBy', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?context=' + CONTEXT_ALTICE_FINE + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submit(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitDocument', { context: CONTEXT_ALTICE_FINE, id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel dos documentos
  exportListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList', {
      context: CONTEXT_ALTICE_FINE,
      parameters: params,
      beginDate: this.filter.BeginDate ? this.filter.BeginDate : null,
      endDate: this.filter.EndDate ? this.filter.EndDate : null,
    });
  }

  // Devolver excel de um documento
  exportToExcel(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcel?context=' + CONTEXT_ALTICE_FINE + '&id=' + id);
  }

  // Devolver pdf de um documento
  exportToPDF(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDF?context=' + CONTEXT_ALTICE_FINE + '&id=' + id);
  }

  // Devolver os tipos de documento
  get types(): Observable<GenericType[]> {
    if (this._types === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_ALTICE_FINE)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._types = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              return this._types;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._types);
    }
  }

  getWorkflowSteps(id: number): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?context=' + CONTEXT_ALTICE_FINE + '&id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  applyWorkflowStep(id: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { context: CONTEXT_ALTICE_FINE, id: id, stepID: stepID, notes: notes })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region Listas de Valores
  getDropdownsList(dropdownID: AlticeFineDropdown, addEmptyFirst = true): Observable<Dropdown[]> {
    if (this.dropdownLists[dropdownID] === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + dropdownID).pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            if (addEmptyFirst) {
              this.dropdownLists[dropdownID] = [new Dropdown(null, null, null, null, -1)];
            } else {
              this.dropdownLists[dropdownID] = [];
            }

            this.dropdownLists[dropdownID].push(...responseReturn.ReturnStatus.ReturnObject);
            return this.dropdownLists[dropdownID];
          } else {
            return this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this.dropdownLists[dropdownID]);
    }
  }

  getInfractions(partnerID: number): Observable<Infraction[]> {
    return this.http.get(SERVICE_URL + 'Infraction/GetActiveList?partnerID=' + partnerID)
      .pipe(map((response: any) => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            return this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }
  //#endregion Listas de Valores

  //#region Validações
  getAllByFieldValue(fieldCode: string, fieldValue: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllByFieldValue?context=' + CONTEXT_ALTICE_FINE + '&fieldCode=' + fieldCode + '&fieldValue=' + fieldValue)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getByCodAuto(documentNumber: string, getFields: boolean): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'AlticeFine/GetActiveProcessByCodAuto?context=' + CONTEXT_ALTICE_FINE + '&codAuto=' + documentNumber + '&getFields=' + getFields)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Validações

  getFieldsChanges(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetFieldsChanges?context=' + CONTEXT_ALTICE_FINE + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
    * Obter lista de utilizadores por perfil associados a um dado documento
    * @param  {number} filterByRole
    * @returns Observable
    */
  getDocumentUsers(documentID: number, filterRoleID: number = null): Observable<Array<any>> {

    let request: string = '/GetDocumentUsers?documentID=' + documentID;
    if (filterRoleID > 0) {
      request += '&filterRole=' + filterRoleID;
    }

    return this.http.get(SERVICE_URL + 'AlticeFine' + request).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

}
