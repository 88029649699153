import { Optional } from '@angular/core';

export class OrderWorkflow {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public Name: string = null,
    @Optional() public IsActive: string = null,
    @Optional() public BuyerID: number = null,
    @Optional() public SupplierID: number = null,
    @Optional() public IsRequestType: boolean = null,
    // listas
    @Optional() public Steps: OrderWorkflowSteps[] = null

  ) { }
}

export class OrderWorkflowSteps {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public WorkflowID: number = null,
    @Optional() public FromStateID: number = null,
    @Optional() public ToStateID: number = null,
    @Optional() public ActionVerb: string = null,
    @Optional() public HasLimit: string = null,
    @Optional() public MinimumLimit: number = null,
    @Optional() public MaximumLimit: number = null,
    @Optional() public CostCenterID: number = null,
    @Optional() public DirectPurchase: string = null,

    // listas
    @Optional() public Users: OrderWorkflowStepUser[] = null,
    @Optional() public Approvers: OrderWorkflowStepApprover[] = null,
    @Optional() public UsersRoles: OrderWorkflowStepUser[] = null,
    @Optional() public ApproversRoles: OrderWorkflowStepApprover[] = null,
    @Optional() public ExcessAmountHasLimit: string = null,
    @Optional() public ExcessAmountMinimumLimit: number = null,
    @Optional() public ExcessAmountMaximumLimit: number = null,

  ) { }
}

export class OrderWorkflowStepUser {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public WorkflowStepID: number = null,
    @Optional() public UserID: number = null,
    @Optional() public RoleID: number = null,

  ) { }
}

export class OrderWorkflowStepApprover {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public WorkflowStepID: number = null,
    @Optional() public UserID: number = null,
    @Optional() public RoleID: number = null,

  ) { }
}

export class CompanyOrderTypeWorkflowMap {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public BuyerID: number = null,
    @Optional() public SupplierID: number = null,
    @Optional() public TypeID: string = null,
    @Optional() public WorkflowID: number = null,

  ) { }
}

export class ListCompanyOrderTypeWorkflowMap {
  constructor(
    @Optional() public ListCompanyOrderTypeWorkflow: ListCompanyOrderTypeWorkflowMap[] = null
  ) { }
}
