// Bibliotecas
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonService } from '../../../services/common.service';
import { FileUploader, FileItem } from 'ng2-file-upload';

// Modelos
import { ModelValidators } from '../../../models/validators/validators';

// Constantes
import { CONTEXT_REPDISKFILES } from '../../../constants/global';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { Subject } from 'rxjs';
import { GenericType } from 'src/app/models/generic-type';
import { functions } from 'lodash';
import { AppConfig } from 'src/app/configs/app.config';
import { Session } from 'protractor';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare var Functions: any;
@Component({
  templateUrl: './digArc-modal.html'
})
export class DigitalArchiveTabModalComponent implements OnInit, OnDestroy {
  entityContext: string;
  entityID: number;
  noSession: boolean;
  isDigitalCertificate: boolean;
  form: UntypedFormGroup;
  noError: boolean = true; // saber se o formulario tem erros
  submitted: boolean = false; // saber se o formulario está a ser submetido  
  DiskFileTypes: any = null;
  newFile = []; // vai guardar o ficheiro para depois anexar no formulario
  uploader: FileUploader = new FileUploader({});
  destroy$: Subject<boolean> = new Subject<boolean>();

  repFile_context: string = CONTEXT_REPDISKFILES;
  showIsPublic: boolean = false;
  showExpiredDate: boolean = false;
  acceptableFormat: string = '';
  hideDescription: boolean = false;
  hideType: boolean = false;
  onFormSave: boolean = false;
  stringAllowedFileTypes: string;

  allowedFileTypes: Array<string> = [];
  validateDuplicatedFileTypes: Array<number> = [];

  todayDate: Date;
  allowedFileSize: number = 0;
  totalFilesSize: number = 0;

  validationMessages = {};


  constructor(
    public dialogRef: MatDialogRef<DigitalArchiveTabModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _commonService: CommonService,
    private _formBuilder: UntypedFormBuilder,
    private translateValueService: TranslateValueService,
    private authenticationService: AuthenticationService,
    private config: AppConfig) { 
      this.validationMessages = {
        'File': {
          'required': 'FIELD_REQUIRED_',
          'lengthMax': 'LENGTH_MAX',
          'requiredFile': 'REQUIRED_FILE'
        },
        'DiskFileTypeID': {
          'required': 'FIELD_REQUIRED_',
          'lengthMax': 'LENGTH_MAX',
        },
        'Description': {
          'required': 'FIELD_REQUIRED_',
          'lengthMax': 'LENGTH_MAX',
        },
        'FilesInfo': {
          'required': 'FIELD_REQUIRED_',
          'OriginalFileName': {
            'required': 'FIELD_REQUIRED_',
            'lengthMax': 'LENGTH_MAX',
            'requiredFile': 'REQUIRED_FILE'
          },
          'FileType': {}
        }
      }
    }

  ngOnInit() {
    // ir buscar os tipos possiveis
    let context = this.data && this.data.context ? this.data.context : this.repFile_context;
    this.entityContext = this.data.entityContext;
    this.entityID = this.data.entityID;
    this.noSession = this.data.noSession;
    this.showIsPublic = this.data.showIsPublic;
    this.showExpiredDate = this.data.showExpiredDate;
    this.hideDescription = this.data.hideDescription;
    this.hideType = this.data.hideType;
    this.onFormSave = this.data.onFormSave;
    this.acceptableFormat = this._commonService.get_acceptableFormat(this.acceptableFormat); 
    this.allowedFileTypes = this.data.allowedFileTypes;
    this.validateDuplicatedFileTypes = this.data.validateDuplicatedFileTypes;
    this.stringAllowedFileTypes = this.allowedFileTypes.join(', ');
    this.allowedFileSize = this.data.allowedFileSize;
    this.totalFilesSize = this.data.totalFilesSize;
    this._commonService.getGenericTypes(context, this.noSession).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.DiskFileTypes = response;

      // se for ExpenseScan não permite colocar ficheiros apenas vê-los
      for (let index = 0; index < this.DiskFileTypes.length; index++) {
        if(this.DiskFileTypes[index].ID == GenericType.EXPENSE_SCAN){
          this.DiskFileTypes.splice(index,1)
        }
      }

      if (!this.authenticationService.session.company.ServiceProvider ) {
        for (let index = 0; index < this.DiskFileTypes.length; index++) {
          if(this.DiskFileTypes[index].ID == GenericType.PROCESS_REQUESTS_ANSWER || this.DiskFileTypes[index].ID == GenericType.PROCESS_COMPLAINTS_ANSWER){
            this.DiskFileTypes.splice(index,1)
          }
        }
      }
      this.buildForm(); // construir o formulario
      this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
        // o que vai fazer quando faz o drop do ficheiro
        this.addFile(null, fileItem);
      };
    });

  }

  /* tslint:disable:member-ordering */
  formErrors = {};
  formErrorsParam = {};
  
  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        this.formErrorsParam[field] = null;

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {
              this.formErrors[field] = messages[key] + ' ';
              // verificar se o erro tem algum parametro (ex: min, max, length...)
              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
        // verificar se foi introduzido algum ficheiro
        if (((this.submitted) || (!this.submitted && control && control.dirty))
          && field === 'OriginalFileName' && this.newFile == null) { // esta a criar e nao adicionou ficheiro
          this.noError = false;
          const messages = this.validationMessages[field];
          let requiredFile = 'requiredFile';
          if (messages.hasOwnProperty(requiredFile)) {
            this.formErrors[field] += messages[requiredFile] + ' ';
          }
        }
      }
    }
  } // fim onValueChanged

  /**
   * Construir formulário
   */
  buildForm() {

    //2022-06-20 GM Comentar opção por defeito  quando apenas existe 1
    /*
    let defaultOption:any = null;
    if(this.DiskFileTypes && this.DiskFileTypes.length == 1){
      defaultOption= this.DiskFileTypes[0];
    }
    */
    this.form = this._formBuilder.group({
      'File': [null],
      // 'OriginalFileName': [{ value: null, disabled: true }, Validators.compose([Validators.required, ModelValidators.lengthVal({ max: 160 })])],
      // 'FileType': [null],
      'DiskFileTypeID': [null], //[defaultOption ? defaultOption.ID : null],
      'Description': [null, Validators.compose([ModelValidators.lengthVal({ max: 500 })])],
      'FilesInfo': this._formBuilder.array([], Validators.required),

      'Context': [this.entityContext ? this.entityContext : null],
      'EntityID': [this.entityID],
      'IsPublic': [this.showIsPublic ? false : true],
      'ExpiredDate': [null]
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);      
      if (value.DiskFileTypeID == 184) {
        this.isDigitalCertificate = true;
      } else {
        this.isDigitalCertificate = false;
      }      
      
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação

  }
  onDiskFileTypeChange()
  {
    if(this.validateDuplicatedFileTypes?.length)
    {
      if(this.validateDuplicatedFileTypes.includes(GenericType.FISCAL_RESIDENCY_CERTIFICATE) && this.validateDuplicatedFileTypes.includes(GenericType.RFI_DOCUMENT))
      {
        if(this.form.get('DiskFileTypeID').value == GenericType.FISCAL_RESIDENCY_CERTIFICATE || this.form.get('DiskFileTypeID').value == GenericType.RFI_DOCUMENT)
        {
          this.form.get('ExpiredDate').setValidators(Validators.required);
        }
        else
        {
          this.form.get('ExpiredDate').setValidators(null);
        }
        this.form.controls['ExpiredDate'].updateValueAndValidity();
      }
    }
  }

  /**
   * Quando carrega no botao de guardar da modal
   */
  save() {
    this.noError = true;
    this.submitted = true;
    this.onValueChanged();
    if (this.noError && this.form.valid) { // se nao tiver erros é que vai prosseguir
      
        let formData: FormData = new FormData(); // necessario criar um formData para conseguir enviar o ficheiro
        let models = [];
        const filesInfo = <UntypedFormArray>this.form.get('FilesInfo');

        for (let i = 0; i < filesInfo.length; i++) {
          let model = new Object();

          for (const field in this.form.controls) {
            if (this.form.controls.hasOwnProperty(field) && field !== 'FilesInfo') {
              if (this.form.controls[field].value != null) {
                // formData.append(field, this.form.controls[field].value);
                model[field] = this.form.controls[field].value;
              }
            }
          }

          if(!model["Context"] && this.DiskFileTypes?.length)
          {
            model['Context'] = this.DiskFileTypes.find(e=> e.ID == model["DiskFileTypeID"])["IntegrationID"];
          }

          if (this.DiskFileTypes) {
            let fileType: any = this.DiskFileTypes.find(e => e.ID == model["DiskFileTypeID"]);
            if (fileType)
            {
              model['ContextDescription'] = fileType["Description"];
            }
          }
          // formData.append('OriginalFileName', filesControl.at(i).get('OriginalFileName').value); // vai adicionar ao formulário o nome do ficheiro
          // Adiciona o nome e o tipo do ficheiro
          model['OriginalFileName'] = filesInfo.at(i).get('OriginalFileName').value;
          model['FileType'] = filesInfo.at(i).get('FileType').value;
          model['Size'] = this.newFile[i].size;
          // Adiciona ao formulário o ficheiro
          
          model['CreationDate'] = new Date();

          // formData.append('OriginalFileName', filesControl.at(i).get('OriginalFileName').value); // vai adicionar ao formulário o nome do ficheiro
          // Adiciona o nome e o tipo do ficheiro
          model['OriginalFileName'] = filesInfo.at(i).get('OriginalFileName').value;
          model['FileType'] = filesInfo.at(i).get('FileType').value;
          // Adiciona ao formulário o ficheiro

          if(this.validateDuplicatedFileTypes?.length && this.validateDuplicatedFileTypes.includes(GenericType.RFI_DOCUMENT) && this.validateDuplicatedFileTypes.includes(GenericType.FISCAL_RESIDENCY_CERTIFICATE))
          {
            if(model['DiskFileTypeID'] == GenericType.RFI_DOCUMENT || model['DiskFileTypeID'] == GenericType.FISCAL_RESIDENCY_CERTIFICATE)
            {
              var expiredDate= new Date(model['ExpiredDate']);
              if(expiredDate < new Date())
              {
                Functions.gritter(this.translateValueService.get('DATE_INFERIOR_THAN_CURR_DATE'), 'danger');
                return;
              }
            }
          }
          
          formData.append('File[' + i + ']', this.newFile[i], this.newFile[i].name);
          models.push(model);
        }

       
        if (this.onFormSave && this.entityID == 0) {//Se este for um form de criação
          this.dialogRef.close({models: models, files: this.newFile });
        } else {
          // Adiciona ao formulário a entidade
          formData.append('models', JSON.stringify(models));
          formData.append('noSession', JSON.stringify(this.noSession));
          this.dialogRef.close(formData); // fechar janela
        }
    } else {
      // mostrar os erros
      this.form.markAllAsTouched();
    }
  }
  

  
  /**
   * Quando altera o campo do ficheiro, para adicionar o novo ficheiro ao modelo
   * @param event
   */
  addFile(event: any, fileItem: FileItem = null) {
    let file: File;
    if (event) { // chegou aqui atraves da janela normal de adição do ficheiro
      let listFiles: FileList = event.target.files;
      if (listFiles.length > 0) { // se tiver algum ficheiro
        file = listFiles[0]; // so queremos o primeiro
      }
    } else if (fileItem) { // chegou aqui porque fez um drop do ficheiro
      file = fileItem._file;
    }
    if (file) { // se tiver algum ficheiro
      if(this.allowedFileTypes.length > 0){ // verifica se o ficheiro é do tipo pretendido
        if(!this.allowedFileTypes.includes(file.name.split('.').pop().toLowerCase()))
        {
          Functions.gritter(this.translateValueService.get('INVALID_FILE_TYPE')+
          ' ('+this.allowedFileTypes+')', 'danger');
          return;
        }
      }

      let attachmentSize =  this._commonService.get_attachmentSize(0);
      if (attachmentSize > 0) {
        if (file.size > attachmentSize) {
          Functions.gritter(this.translateValueService.get('INVALID_FILE_SIZE'), 'danger');
            return;
        }
      }
     

      if (this.allowedFileSize > 0) {
        let sizeAvailable = this.allowedFileSize - this.totalFilesSize
        if (sizeAvailable >= 0) {
          if (file.size > sizeAvailable) {
            Functions.gritter(this.translateValueService.get('INVALID_TOTAL_FILE_SIZE', {size: this.allowedFileSize/1000000})+ ' ' + file.name + ' not added!', 'danger');
            return;
          }
        }
      }
      
      this.totalFilesSize += file.size;
      this.newFile.push(file);

      (<UntypedFormArray>this.form.get('FilesInfo')).push(this._formBuilder.group({
        'OriginalFileName': [{ value: file.name, disabled: true }, Validators.compose([Validators.required, ModelValidators.lengthVal({ max: 160 })])],
        'FileType': file.name.split('.').pop()
      }));

      this.form.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  /**
   * Quando adiciona um ficheiro e depois quer remover
   */
  removeFile(index: number) {
    this.totalFilesSize -= this.newFile[index].Size;
    this.newFile.splice(index, 1);

    (<UntypedFormArray>this.form.get('FilesInfo')).removeAt(index);
  }
  
  getAcceptableFormat(): string {
    if (this.acceptableFormat) {
      return this.acceptableFormat;
    }else return '';
  }

  ngOnDestroy() { }
}
