import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './ordersFilters-modal.html'
})
export class OrdersFilterModalComponent {

  requestNumber: string;

  constructor(
    public dialogRef: MatDialogRef<OrdersFilterModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    if (data && data.product) {
      this.requestNumber = data.requestNumber;
    }
  }

}
