// Bibliotecas
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileUploader, FileItem } from 'ng2-file-upload';

// Modelos
import { ModelValidators } from '../../../models/validators/validators';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { Subject } from 'rxjs';


@Component({
  templateUrl: './upload-file-modal.html'
})
export class UploadFileModalComponent implements OnInit, OnDestroy {
  entityContext: string;
  entityID: number;
  noSession: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  form: UntypedFormGroup;
  noError: boolean = true; // saber se o formulario tem erros
  submitted: boolean = false; // saber se o formulario está a ser submetido
  newFile = []; // vai guardar o ficheiro para depois anexar no formulario
  uploader: FileUploader = new FileUploader({});
  allowedExtensions: string[] = [];
  validationMessages = {

  };
  constructor(
    public dialogRef: MatDialogRef<UploadFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _formBuilder: UntypedFormBuilder,
    private translateValueService: TranslateValueService) { 
      this.validationMessages = {
        'File': {
          'required': 'FIELD_REQUIRED_',
          'lengthMax': 'LENGTH_MAX',
          'requiredFile': 'REQUIRED_FILE'
        },
        'FilesInfo': {
          'required': 'FIELD_REQUIRED_',
          'OriginalFileName': {
            'required': 'FIELD_REQUIRED_',
            'lengthMax': 'LENGTH_MAX',
            'requiredFile': 'REQUIRED_FILE'
          },
          'FileType': {}
        }
      }
    }

  ngOnInit() {   
    this.entityContext = this.data.entityContext;
    this.entityID = this.data.entityID;
    this.noSession = this.data.noSession;
    this.allowedExtensions = this.data.allowedExtensions ? this.data.allowedExtensions : [];

    this.buildForm(); // construir o formulario
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      // o que vai fazer quando faz o drop do ficheiro
      this.addFile(null, fileItem);
    };
  }

  /* tslint:disable:member-ordering */
  formErrors = {};
  formErrorsParam = {};
 

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        this.formErrorsParam[field] = null;

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {
              this.formErrors[field] = messages[key] + ' ';
              // verificar se o erro tem algum parametro (ex: min, max, length...)
              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
        // verificar se foi introduzido algum ficheiro
        if (((this.submitted) || (!this.submitted && control && control.dirty))
          && field === 'OriginalFileName' && this.newFile == null) { // esta a criar e nao adicionou ficheiro
          this.noError = false;
          const messages = this.validationMessages[field];
          let requiredFile = 'requiredFile';
          if (messages.hasOwnProperty(requiredFile)) {
            this.formErrors[field] += messages[requiredFile] + ' ';
          }
        }
      }
    }
  } // fim onValueChanged

  /**
   * Construir formulário
   */
  buildForm() {
    this.form = this._formBuilder.group({
      'File': [null],
      'FilesInfo': this._formBuilder.array([], Validators.required)
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  /**
   * Quando carrega no botao de guardar da modal
   */
  save() {
    this.noError = true;
    this.submitted = true;
    this.onValueChanged();
    if (this.noError) { // se nao tiver erros é que vai prosseguir
      let formData: FormData = new FormData(); // necessario criar um formData para conseguir enviar o ficheiro

      const filesInfo = <UntypedFormArray>this.form.get('FilesInfo');
      for (let i = 0; i < filesInfo.length; i++) {
        // Adiciona ao formulário o ficheiro
        formData.append('File[' + i + ']', this.newFile[i], this.newFile[i].name);
      }
      this.dialogRef.close(formData); // fechar janela
    }
  }

  /**
   * Quando altera o campo do ficheiro, para adicionar o novo ficheiro ao modelo
   * @param event
   */
  addFile(event: any, fileItem: FileItem = null) {
    let file: File;
    if (event) { // chegou aqui atraves da janela normal de adição do ficheiro
      let listFiles: FileList = event.target.files;
      if (listFiles.length > 0) { // se tiver algum ficheiro
        file = listFiles[0]; // so queremos o primeiro
      }
    } else if (fileItem) { // chegou aqui porque fez um drop do ficheiro
      file = fileItem._file;
    }
    if (file) { // se tiver algum ficheiro

      // verificar se a extensão é permitida
      if (this.allowedExtensions && this.allowedExtensions.length > 0) {
        let ext: string = file.name.split('.').pop();
        if (ext.length === 0 || this.allowedExtensions.findIndex(x => x.toLowerCase() === ext) < 0) {
          this.formErrors['FilesInfo'] = this.translateValueService.get('INVALID_FILE');
          return;
        }
      }

      this.newFile.push(file);

      (<UntypedFormArray>this.form.get('FilesInfo')).push(this._formBuilder.group({
        'OriginalFileName': [{ value: file.name, disabled: true }, Validators.compose([Validators.required, ModelValidators.lengthVal({ max: 160 })])],
        'FileType': file.name.split('.').pop()
      }));

      this.form.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  /**
   * Quando adiciona um ficheiro e depois quer remover
   */
  removeFile(index: number) {
    this.newFile.splice(index, 1);

    (<UntypedFormArray>this.form.get('FilesInfo')).removeAt(index);
  }

  ngOnDestroy() { }
}
