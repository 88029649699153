export class UnitOfMeasure {
  constructor(
    /// Código único e PK da unidade de medida.
    /// É suposto ser o código EDI.
    public Code: string,

    /// Código de apresentação. É este código que deverá ser mostrado ao utilizador.
    public Symbol: string,

    /// Denominação da unidade de medida.
    public Name: string,

    /// Define a precisão em casas decimais.
    public StandardPrecision: number,

    /// Define, quando a true, que o registo está em utilização
    public IsActive: boolean,

    /// Descrição longa da unidade de medida - útil para descrever o significado de uma UnitOfMeasure em detalhe.
    public Description?: string,

    /// Tipo de unidade de medida: G="Geral", W="Peso" (Weight), V="Volume", Null="Comum a todos os tipos"
    public UOMType?: string
  ) { }
}

export enum UnitOfMeasureType { General = 'G', Weight = 'W', Volume = 'V' }
