import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONTEXT_PROCESS, CONTEXT_PROCESS_DOCUMENTS, SERVICE_URL } from '../constants/global';
import { DatatableParameters } from '../models/datatable-parameters';
import { GenericTypesConfig } from '../models/generic-types-config';
import { ReturnStatusHtml } from '../models/returnStatus';
import { DataTableFunctions } from '../modules/datatables.module';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { AuthenticationService } from './authentication.service';
import { HttpClientCustom } from './http-client';
import { MasterService } from './master.service';
import { CONTEXT_TICKET } from './ticket/ticket.service';


@Injectable()
export class TicketsConfigsService {

    datatableParametersTicketsEmails: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  
   
    private _controller = 'TeixeiraDuarte';
    private _types: any;
    private showCompany: boolean;
    private showPartner: boolean;
    private showCompanySendEmail: boolean;
    private showCountry: boolean;
    private showTDConnect: boolean;
    private showNewButton: boolean;
    private genericTypeContext: string;


    constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
        private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) {
    }


    get types(): Observable<any> {
        if (this._types === undefined) {
            return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_TICKET).pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._types = responseReturn.ReturnStatus.ReturnObject;
                            return this._types;
                        } else {
                            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._types);
        }

    }

   
    /****************POST METHODS**********************/

    updateGenericTypesConfig(entity: GenericTypesConfig): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/UpdateGenericTypeConfig', { entity: entity }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    createGenericTypesConfig(entity: GenericTypesConfig): Observable<ReturnStatusHtml> {
      return this.http.post(SERVICE_URL + this._controller + '/CreateGenericTypeConfig', { entity: entity }).pipe(
          map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    deleteGenericTypesConfig(id: number): Observable<ReturnStatusHtml> {
      return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
          map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }



    /****************GET METHODS**********************/

    getCountGenericTypesConfig(typeID: number, ownerID: string, companyID: number): Observable<ReturnStatusHtml> {
      return this.http.get(SERVICE_URL + this._controller + '/GetCountGenericTypesConfig?typeID='+typeID+'&ownerID='+ownerID+'&companyID='+companyID).pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response))
    );
    }

    getGenericTypesConfig(configID: number): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/GetGenericTypesConfig?configID=' + configID).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response))
        );
    }

    
    get_showCompany(defaultValue: boolean): boolean {
        if (typeof this.showCompany === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'ShowCompany');
          if (settingValue != null) {
            this.showCompany = settingValue;
          } else {
            this.showCompany = defaultValue;
          }
        }
        return this.showCompany;
      }

      get_showPartner(defaultValue: boolean): boolean {
        if (typeof this.showPartner === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'ShowCompanySendEmail');
          if (settingValue != null) {
            this.showPartner = settingValue;
          } else {
            this.showPartner = defaultValue;
          }
        }
        return this.showPartner;
      }

      get_genericTypeContext(defaultValue: string): string {
        if (typeof this.genericTypeContext === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'GenericTypeContext');
          if (settingValue != null) {
            this.genericTypeContext = settingValue;
          } else {
            this.genericTypeContext = defaultValue;
          }
        }
        return this.genericTypeContext;
      }

      get_showCompanySendEmail(defaultValue: boolean): boolean {
        if (typeof this.showCompanySendEmail === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'ShowCompanySendEmail');
          if (settingValue != null) {
            this.showCompanySendEmail = settingValue;
          } else {
            this.showCompanySendEmail = defaultValue;
          }
        }
        return this.showCompanySendEmail;
      }

      get_showCountry(defaultValue: boolean): boolean {
        if (typeof this.showCountry === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'ShowCountry');
          if (settingValue != null) {
            this.showCountry = settingValue;
          } else {
            this.showCountry = defaultValue;
          }
        }
        return this.showCountry;
      }

      get_showTDConnect(defaultValue: boolean): boolean {
        if (typeof this.showTDConnect === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'ShowTDConnect');
          if (settingValue != null) {
            this.showTDConnect = settingValue;
          } else {
            this.showTDConnect = defaultValue;
          }
        }
        return this.showTDConnect;
      }

      get_showNewButton(defaultValue: boolean): boolean {
        if (typeof this.showNewButton === 'undefined') { // verificar se ainda nao tem valor
          let settingValue = this.authenticationService.getSettingPortal('EmailConfigColumns', 'ShowNewButton');
          if (settingValue != null) {
            this.showNewButton = settingValue;
          } else {
            this.showNewButton = defaultValue;
          }
        }
        return this.showNewButton;
      }


  

}