import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModelValidators } from 'src/app/models/validators/validators';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './notes-modal.html'
})
export class NotesModalComponent implements OnInit {
  disabled: boolean;
  notes: string;
  form: UntypedFormGroup;

  changedForm: boolean = false;
  oldFormJson: string;
  enableSavingWhenChangingForm: boolean = false;
  notesWasNull: boolean = false;
  validateNotesMaxLength: boolean = false;
  mandatoryNotes: boolean = false;
  submitted: boolean = false; // saber se o formulario está a ser submetido
  noError: boolean; // saber se o formulario tem erros
  destroy$: Subject<boolean> = new Subject<boolean>();
  validationMessages = {};
  constructor(
    public dialogRef: MatDialogRef<NotesModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef) {
    this.disabled = data.disabled;
    this.notes = data.notes;
    this.validateNotesMaxLength = data.validateNotesMaxLength;
    this.mandatoryNotes = data.mandatoryNotes;

    if (data.enableSavingWhenChangingForm != undefined || data.enableSavingWhenChangingForm != null) {
      this.enableSavingWhenChangingForm = data.enableSavingWhenChangingForm;
      this.notesWasNull = data.notesWasNull;
    }

    this.validationMessages = {
      'Notes': {
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX'
      }
    }

  }



  ngOnInit(): void {
    this.form = this.formBuilder.group({
      'Notes': [this.notes, this.validateNotesMaxLength ? Validators.compose([this.mandatoryNotes ? Validators.required : null, ModelValidators.lengthVal({ max: 100 })]) : '']
    });

    if (this.enableSavingWhenChangingForm) {
      this.oldFormJson = JSON.stringify(this.form.getRawValue());
    }

    if (this.disabled) {
      this.form.disable();
    }

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  /* tslint:disable:member-ordering */
  formErrors = {
    'Notes': '',
  };
  formErrorsParam = {};

  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {

    if (!this.form) { return; }
    const form = this.form;

    //DS: Código para verificar se o form foi alterado e desbloquear o botão guardar se assim for           
    if (this.oldFormJson && this.enableSavingWhenChangingForm) {
      this.changedForm = JSON.stringify(this.form.getRawValue()) !== this.oldFormJson
      this.ref.detectChanges();
    }

    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        this.formErrorsParam[field] = null;


        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))) {
          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key] + ' ';

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }

  }

  save() {
    this.submitted = true;
    this.noError = true;
    this.dialogRef.close(this.form.value);
  }

  ngOnDestroy() { }
}
