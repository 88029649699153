
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';

import { CompanyDeliverySchedule } from '../models/companyDeliverySchedule';

import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';

@Injectable()
export class CompanyDeliveryScheduleService {

  private _controller: string = 'CompanyDeliverySchedule';

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions) { }

  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: CompanyDeliverySchedule): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', { model: model }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: CompanyDeliverySchedule, deleteAllPartners: boolean): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', { model: model, deleteAllPartners: deleteAllPartners }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getBySupplierAndBuyer(supplierID: number, buyerID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetBySupplierAndBuyer?supplierID=' + supplierID + '&buyerID=' + buyerID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getByProductAndBuyer(productID: number, buyerID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByProductAndBuyer?productID=' + productID + '&buyerID=' + buyerID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

}
