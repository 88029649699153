
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Observable } from 'rxjs';
import { MasterService } from './master.service';
import { AuthenticationService } from './authentication.service';
// ***__***_________  MODULOS _________ ***__***
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class FileSharingService {

  private _controller: string = 'FileSharing';
  private showButtonProfiles: boolean;
  private columnInVisible_E: Array<number>;
  private columnInVisible_V: Array<number>;
  private showAllFilesByDefault_E: boolean;
  private showAllFilesByDefault_V: boolean;
  private partnersLabel: string;
  private partnersListLabel: string;

  datatableParametersEditor: DatatableParameters;
  datatableParametersViewer: DatatableParameters;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService,
    private _dataTableF: DataTableFunctions) {

    this.showAllFilesByDefault_E = this.get_showAllFilesByDefault_E(false);
    this.showAllFilesByDefault_V = this.get_showAllFilesByDefault_V(false);
    this.datatableParametersEditor = new DatatableParameters([null, null, null, null, null, null, null], this.showAllFilesByDefault_E ? -1 : 10, [[1, 'asc']], 0);
    this.datatableParametersViewer = new DatatableParameters([null, null, null, null, null, null, null], this.showAllFilesByDefault_V ? -1 : 10, [[1, 'asc']], 0);

  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Ir buscar um ficheiro
 * @param  {number} id
 */
  getFile(id: number) {
    // return this.http.getFile(SERVICE_URL + 'Common/GetDocument/' + id).toPromise().then((response: any) => response);
    return this.http.getFile(SERVICE_URL + 'Common/GetDocument/' + id);
  }

  createOrUpdate(model: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/CreateOrUpdate', model).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // #region Settings

  get_showButtonProfiles(defaultValue: boolean): boolean {
    if (typeof this.showButtonProfiles === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FileSharingEditor', 'ShowButtonProfiles');
      if (settingValue != null) {
        this.showButtonProfiles = settingValue;
      } else {
        this.showButtonProfiles = defaultValue;
      }
    }
    return this.showButtonProfiles;
  }

  get_ColumnInVisible_E(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_E === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FilesSharingEditor', 'ColumnVisible');
      this.columnInVisible_E = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_E;
  }

  get_ColumnInVisible_V(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_V === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FilesSharingViewer', 'ColumnVisible');
      this.columnInVisible_V = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_V;
  }

  get_showAllFilesByDefault_E(defaultValue: boolean): boolean {
    if (typeof this.showAllFilesByDefault_E === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FilesSharingEditor', 'ShowAllFilesByDefault');
      if (settingValue != null) {
        this.showAllFilesByDefault_E = settingValue;
      } else {
        this.showAllFilesByDefault_E = defaultValue;
      }
    }
    return this.showAllFilesByDefault_E;
  }

  get_showAllFilesByDefault_V(defaultValue: boolean): boolean {
    if (typeof this.showAllFilesByDefault_V === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FilesSharingViewer', 'ShowAllFilesByDefault');
      if (settingValue != null) {
        this.showAllFilesByDefault_V = settingValue;
      } else {
        this.showAllFilesByDefault_V = defaultValue;
      }
    }
    return this.showAllFilesByDefault_V;
  }

  get_partnersLabel(defaultValue: string): string {
    if (typeof this.partnersLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FileSharingEditor', 'PartnersLabel');
      if (settingValue != null) {
        this.partnersLabel = settingValue;
      } else {
        this.partnersLabel = defaultValue;
      }
    }
    return this.partnersLabel;
  }

  get_partnersListLabel(defaultValue: string): string {
    if (typeof this.partnersListLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('FileSharingEditor', 'PartnersListLabel');
      if (settingValue != null) {
        this.partnersListLabel = settingValue;
      } else {
        this.partnersListLabel = defaultValue;
      }
    }
    return this.partnersListLabel;
  }

  // #endregion Settings
}
