import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModelValidators } from 'src/app/models/validators/validators';
import { takeUntil,map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IntegrationProfile } from 'src/app/models/integrationProfile';
import { FoldersService } from 'src/app/services/folders.service';
import { Subject, forkJoin } from 'rxjs';
import { IntegrationProfileService } from 'src/app/services/integrationProfile.service';
import { FoldersContexts } from 'src/app/models/folders-contexts';

@Component({
  templateUrl: './folders-modal.html'
})
export class FoldersModalComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  name: string;
  context:string;
  filesIntegrationRoleID:string;
  fileIntegrationID:string;

  form: FormGroup;

  contexts: Array<FoldersContexts> = [];
  integrationsProfiles: Array<IntegrationProfile> = [];

  changedForm: boolean = false;
  oldFormJson: string;
  enableSavingWhenChangingForm: boolean = false;
  notesWasNull: boolean = false;
  validateNotesMaxLength: boolean = false;
  submitted: boolean = false; // saber se o formulario está a ser submetido
  noError: boolean; // saber se o formulario tem erros
  errorTreat: any;
  disabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FoldersModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    public foldersService: FoldersService,
    public integrationsProfilesService:IntegrationProfileService ) {
  }

  ngOnInit(): void {

    let arrayObservables = [];
    arrayObservables.push(this.foldersService.foldersContexts.pipe(map((r: Array<any>) => this.contexts = r))); 
    arrayObservables.push(this.integrationsProfilesService.integrationProfiles.pipe(map((r: Array<IntegrationProfile>) => this.integrationsProfiles = r))); 

    forkJoin(arrayObservables).pipe(takeUntil(this.destroy$)).subscribe(() => { // para fazer depois de terminado todos os observables do array

    });

    this.form = this.formBuilder.group({
      'Name': [this.name, this.validateNotesMaxLength ? Validators.compose([ModelValidators.lengthVal({ max: 100 })]) : ''],
      'Context': [this.context, this.validateNotesMaxLength ? Validators.compose([ModelValidators.lengthVal({ max: 100 })]) : ''],
      'FilesIntegrationRoleID': [this.filesIntegrationRoleID, this.validateNotesMaxLength ? Validators.compose([ModelValidators.lengthVal({ max: 100 })]) : ''],
      'FileIntegrationID': [this.fileIntegrationID, this.validateNotesMaxLength ? Validators.compose([ModelValidators.lengthVal({ max: 100 })]) : ''],
    });

    if (this.enableSavingWhenChangingForm) {
      this.oldFormJson = JSON.stringify(this.form.getRawValue());
    }

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  /* tslint:disable:member-ordering */
  formErrors = {
    'Name': '',
    'Context':'',
    'FilesIntegrationRoleID':'',
    'FileIntegrationID':'',
  };
  formErrorsParam = {};
  validationMessages = {
    'Name': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'Context': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'FilesIntegrationRoleID': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    },
    'FileIntegrationID': {
      'required': 'FIELD_REQUIRED_',
      'lengthMax': 'LENGTH_MAX'
    }
  };
  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {

    if (!this.form) { return; }
    const form = this.form;

    //DS: Código para verificar se o form foi alterado e desbloquear o botão guardar se assim for           
    if (this.oldFormJson && this.enableSavingWhenChangingForm) {
      this.changedForm = JSON.stringify(this.form.getRawValue()) !== this.oldFormJson
      this.ref.detectChanges();
    }

    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        this.formErrorsParam[field] = null;

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))) {
          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key] + ' ';

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  save() {
    this.submitted = true;
    this.noError = true;
    this.dialogRef.close(this.form.value);
  }

  ngOnDestroy() { }
}
