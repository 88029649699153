import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONTEXT_PROCESS, CONTEXT_PROCESS_DOCUMENTS, SERVICE_URL } from '../constants/global';
import { DatatableParameters } from '../models/datatable-parameters';
import { ReturnStatusHtml } from '../models/returnStatus';
import { DataTableFunctions } from '../modules/datatables.module';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { AuthenticationService } from './authentication.service';
import { HttpClientCustom } from './http-client';
import { MasterService } from './master.service';


@Injectable()
export class ProcessesService {
    //filtros datas ecrã dos processos
    filter_DataIni_P: string;
    filter_DataFim_P: string;

    //filtros datas ecrã dos documentos dos processos
    filter_DataIni_D: string;
    filter_DataFim_D: string;

    datatableParametersProcesses: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null], 10, [[8, 'desc']], 0);
    datatableParametersProcessDocuments: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);
    datatableParametersProcessesDocuments: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null], 10, [[5, 'desc']], 0);
    private _controller = 'Processes';
    private _states: any;
    private _types: any;
    private _countries: any;
    private _documentTypes: any;
    private _allDocumentTypes: any;
    private _documentStates: any;


    constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
        private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) {
    }



    get states(): Observable<any> {
        if (this._states === undefined) {
            return this.http.get(SERVICE_URL + this._controller + '/GetAllProcessStates').pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._states = responseReturn.ReturnStatus.ReturnObject;
                            return this._states;
                        } else {
                            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._states);
        }
    }

    get types(): Observable<any> {
        if (this._types === undefined) {
            return this.http.get(SERVICE_URL + this._controller + '/GetAllProcessTypes').pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._types = responseReturn.ReturnStatus.ReturnObject;
                            return this._types;
                        } else {
                            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._types);
        }

    }

    get countries(): Observable<any> {
        if (this._countries === undefined) {
            return this.http.get(SERVICE_URL + 'Common/GetCountries').pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._countries = responseReturn.ReturnStatus.ReturnObject;
                            return this._countries;
                        } else {
                            this._errorTreat.treatErrorResponse(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._countries);
        }
    }

    get documentTypes(): Observable<any> {
        if (this._documentTypes === undefined) {
            return this.http.get(SERVICE_URL + this._controller + '/GetPossibleUploadTypes?context=' + CONTEXT_PROCESS_DOCUMENTS).pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._documentTypes = responseReturn.ReturnStatus.ReturnObject;
                            return this._documentTypes;
                        } else {
                            this._errorTreat.treatErrorResponse(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._documentTypes);
        }
    }

    get allDocumentTypes(): Observable<any> {
        if (this._allDocumentTypes === undefined) {
            return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_PROCESS_DOCUMENTS).pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._allDocumentTypes = responseReturn.ReturnStatus.ReturnObject;
                            return this._allDocumentTypes;
                        } else {
                            this._errorTreat.treatErrorResponse(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._allDocumentTypes);
        }
    }

    get documentStates(): Observable<any> {
        if (this._documentStates === undefined) {
            return this.http.get(SERVICE_URL + 'Common/GetGenericStates?context=' + CONTEXT_PROCESS_DOCUMENTS).pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._documentStates = responseReturn.ReturnStatus.ReturnObject;
                            return this._documentStates;
                        } else {
                            this._errorTreat.treatErrorResponse(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._documentStates);
        }
    }

    /****************DOWNLOAD METHODS**********************/

    /**Procesess Methods */
    downloadExcelProcessesList(parameters: DatatableParameters, dates: any): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProcessesList', { parameters: parameters, firstDate: dates.firstdate, lastDate: dates.lastdate });
    }

    downloadExcelProcessDetails(processID: number): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProcessDetails', { processID: processID });
    }

    /**Procesess Documents Methods */
    downloadExcelProcessesDocumentsList(parameters: DatatableParameters, dates: any): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProcessesDocumentsList', { parameters: parameters, firstDate: dates.firstdate, lastDate: dates.lastdate });
    }

    downloadExcelProcessDocumentDetails(processDocumentID: number): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProcessDocumentDetails', { processDocumentID: processDocumentID });
    }


    /****************GET METHODS**********************/

    /**Procesess Methods */
    getDocumentPDF(documentID: number, isCopy: boolean): Observable<any> {
        return this.http.getFile(SERVICE_URL + this._controller + '/GetDocumentPDFById?id=' + documentID + '&isCopy=' + isCopy);
    }

    getAllDocumentPDFs(processID: number): Observable<any> {
        return this.http.getFile(SERVICE_URL + this._controller + '/GetAllDocumentPDFs?processId=' + processID);
    }

    getProcess(processID: number): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/GetProcess?processID=' + processID).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response))
        );
    }

    getWorkflowSteps(processID: number): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?id=' + processID).pipe(
            map(response => {
                try {
                    let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                    if (responseReturn.ReturnStatus.Successfull) {
                        return responseReturn.ReturnStatus.ReturnObject;
                    } else {
                        this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                    }
                } catch (error) {
                    this._masterService.handleError(error);
                }
            }));
    }

    /**Procesess Documents Methods */
    getProcessDocument(processDocumentID: number): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/GetProcessDocument?processDocumentID=' + processDocumentID).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response))
        );
    }

    /****************POST METHODS**********************/

    sendEmail(id: number, emails: string, originalDocumentsIDs: any, copyDocumentsIDs: any): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/SendProcessByEmail', { processID: id, emails: emails, originalDocumentsIDs: originalDocumentsIDs, copyDocumentsIDs: copyDocumentsIDs }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    applyWorkflowStep(context: string, docID: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { context: context, id: docID, stepID: stepID, notes: notes }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    addStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    addDocumentToProcess(processID: number, documentTypeID: number, documentNumber: string, file: File): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('processID', processID.toString());
        formData.append('documentTypeID', documentTypeID.toString());
        formData.append('documentNumber', documentNumber);
        formData.append('file', file, file.name);

        return this.http.put(SERVICE_URL + this._controller + '/AddDocumentToProcess', formData);
    }

    getProcessDocuments(id: number): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/GetProcessDocuments', { processID: id }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    /****************PUT METHODS**********************/

    /**Procesess Methods */
    concludeProcess(id: number, emails: string, notes: string): Observable<ReturnStatusHtml> {
        return this.http.put(SERVICE_URL + this._controller + '/ConcludeProcess', { id: id, emails: emails, notes: notes }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    generateDocumentCopy(documentID: number): Observable<ReturnStatusHtml> {
        return this.http.put(SERVICE_URL + this._controller + '/GenerateDocumentCopy', { documentID: documentID }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response))
        );
    }

    /**Procesess Documents Methods */
    changeState(documentID: number, newState: number, notes: string): Observable<ReturnStatusHtml> {
        return this.http.put(SERVICE_URL + this._controller + '/ChangeState', { documentID: documentID, newState: newState, notes: notes }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    validateProcessDocuments(documentsIDs: any): Observable<ReturnStatusHtml> {
        return this.http.put(SERVICE_URL + this._controller + '/ValidateProcessDocuments', { documentsIDs: documentsIDs }).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }
}