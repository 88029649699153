import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from '../authentication.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

declare var Functions: any;

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  // Contador para remover o spinner quando o valor volta a 0
  private loadingCounter: number = 0;

  constructor(public authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // request = request.clone({
    //   setHeaders: {
    //     Token: this.authenticationService.token
    //   }
    // });

    // Incrementa o contador de loading
    this.loadingCounter++;
    if (this.loadingCounter === 1) { Functions.loading(true); }

    return next.handle(request).pipe(finalize(() => {
      // Quando finaliza ou dá erro
      this.loadingCounter--;
      if (this.loadingCounter === 0) { Functions.loading(false); }
    }));
  }
}
