
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { CostCenterConfig } from '../models/costCenterConfig';
import { of as observableOf, Observable } from 'rxjs';
import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { CostCenter } from '../models/costCenter';
import { AccountingConfig } from '../models/accountingConfig';
import { AuthenticationService } from './authentication.service';
import { DecimalPipe } from '@angular/common';
import { filter } from 'lodash';

@Injectable()
export class AccountingConfigService {
  private _analisysAxes: Array<any>;
  private _allCostCenters: Array<any>;
  private _allCostCentersForAllCompanies: Array<any>;
  private _userCostCenters: Array<any>;

  private _controller: string = 'CostCenter';

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) { }


  public get allCostCenters(): Observable<Array<any>> {
    if (this._allCostCenters === undefined) {
      return this.getComplements('GetAll').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._allCostCenters = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._allCostCenters);
    }
  }

  public get allCostCentersForAllCompanies(): Observable<Array<any>> {
    if (this._allCostCentersForAllCompanies === undefined) {
      return this.getComplements('GetAllForAllCompanies').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._allCostCentersForAllCompanies = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._allCostCentersForAllCompanies);
    }
  }


  public getuserCostCenters(filteredByUser: boolean = true, filterActive: boolean = true): Observable<CostCenter[]> {
    if (this._userCostCenters === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetAll?filteredByUser=' + filteredByUser + '&filterActive=' + filterActive).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._userCostCenters = responseReturn.ReturnStatus.ReturnObject as CostCenter[];
              return this._userCostCenters;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._userCostCenters);
    }
  }

  public get analisysAxes(): Observable<Array<any>> {
    if (this._analisysAxes === undefined) {
      return this.http.get(SERVICE_URL + 'AnalisysAxes/GetAll').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              return this._analisysAxes = responseReturn.ReturnStatus.ReturnObject;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._analisysAxes);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Criar/editar/eliminar centro de custo config
   * @param  {string} context
   * @param  {number} entityID
   * @param  {Array<AccountingConfig>} configList
   * @returns Observable
   */
  updateAccountingConfig(context: string, entityID: number, configList: Array<AccountingConfig>): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateAccountingConfig', { context, entityID, configList }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Ver as configurações da contabilização
   * @param  {number} companyID
   * @param  {number} year
   * @param  {number} productID
   * @param  {number} tipologiaProdutoServicoID
   * @param  {number} vatRate
   * @param  {boolean} refaturacao
   * @returns Observable
   */
  getAccountingConfig(companyID: number, year: number, productID: number, tipologiaProdutoServicoID: number, vatRate: number, refaturacao: boolean): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'GrupoContabilizacao/GetAccountigConfig?companyID=' + companyID + '&year=' + year + '&productID=' + productID + '&tipologiaProdutoServicoID=' + tipologiaProdutoServicoID + '&vatRate=' + vatRate + '&refaturacao=' + refaturacao).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public getCostCenters(analisysAxeID: number, documentType: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetCostCentersListByAnalisysAxeAndDocumentType?analisysAxeID=' + analisysAxeID + '&documentType=' + documentType).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }



}
