import { OnInit, Component, Renderer2, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { LANGUAGE } from '../../constants/global';
import { ServicesService } from '../../services/services.service';
import { FileTreatmentFunctions } from '../../modules/treatments.module';
import { Subject } from 'rxjs';

declare var App: any;

@Component({
  templateUrl: './service.html',
  styles: ['.service-success { background-color: #5fbf5f !important; }', '.service-error { background-color: #ee5037 !important; }'],
  encapsulation: ViewEncapsulation.None
})

export class ServiceComponent implements OnInit, AfterViewInit, OnDestroy {
  title: string = '';
  message: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private activatedRoute: ActivatedRoute, private renderer: Renderer2, private authenticationService: AuthenticationService, private translateService: TranslateService,
    private servicesService: ServicesService, private _fileTreat: FileTreatmentFunctions) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'texture');

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      let operation = params['op'];
      let id = params['ID'] || params['id'];

      if (operation) {
        // let currentLang = this.translateService.currentLang ? this.translateService.currentLang + '_' + this.translateService.currentLang.toUpperCase() : LANGUAGE + '_' + LANGUAGE.toUpperCase();
        let locale = params['locale'] || params['Local'] || (this.translateService.currentLang ? this.translateService.currentLang + '_' + this.translateService.currentLang.toUpperCase() : LANGUAGE + '_' + LANGUAGE.toUpperCase());

        switch (operation) {
          // - Link: "CoreDefaultSettings.PublicUrl + "services?op=ChangeEmail&ID=" + requestID;"   método: SessionController.ChangeEmail
          case 'ChangeEmail':
            if (id) {
              this.authenticationService.changeEmail(id, locale).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
                if (response.ReturnStatus.Successfull) {
                  this.title = 'SUCCESS';
                  this.message = response.ReturnStatus.SuccessMessage;
                } else {
                  this.title = 'ERROR';
                  this.message = response.ReturnStatus.ErrorMessage;
                }
              });
            } else {
              this.title = 'ERROR';
              this.message = 'INVALID_PARAMETERS';
            }
            break;
          // - Link: "CoreDefaultSettings.PublicUrl + "services?op=ManageDocument&id=1&action=IA&stepid=1 (stepid opcional)  método: ServicesController.ManageDocument
          case 'ManageDocument':
            let action = params['ACTION'] || params['action'];
            let stepid = params['STEPID'] || params['stepid'];

            if (id && action) {
              this.servicesService.manageDocument(id, action, stepid, locale).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
                if (response.ReturnStatus.Successfull) {
                  this.title = 'SUCCESS';
                  this.message = response.ReturnStatus.SuccessMessage;
                } else {
                  this.title = 'ERROR';
                  this.message = response.ReturnStatus.ErrorMessage;
                }
              });
            } else {
              this.title = 'ERROR';
              this.message = 'INVALID_PARAMETERS';
            }
            break;
          // - Link: "CoreDefaultSettings.PublicUrl + "services?op=ViewDocument&ID=1&Local=pt_PT&DT=RDF  método: ServicesController.ViewDocument (id, documentType, local) - Este método vai retornar StatusCode e StatusDescription e um ficheiro se tudo correr bem
          case 'ViewDocument':
            let DT = params['DT'];

            if (id && DT) {
              this.servicesService.viewDocument(id, DT, locale).pipe(takeUntil(this.destroy$)).subscribe(response => {
                if (response.status === 200) {
                  this.title = 'SUCCESS';
                  this.message = 'FILE_SUCCESSFULLY_DOWNLOADED';

                  let self = this;
                  const blob = new Blob([response._body], { type: response.headers.get('Content-Type') });
                  if (response.statusText !== null) {
                    self._fileTreat.afterBlobFile(blob, response.statusText);
                  }
                } else {
                  this.title = 'ERROR';
                  this.message = response.statusText;
                }
              });
            } else {
              this.title = 'ERROR';
              this.message = 'INVALID_PARAMETERS';
            }
            break;
        }
      }
    });
  }

  public ngAfterViewInit(): void {
    App.init();
  }

  ngOnDestroy() { }
}
