import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { MasterService } from './master.service';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';
import { IntegrationProfile } from '../models/integrationProfile';
import { DatatableParameters } from '../models/datatable-parameters';

@Injectable()
export class IntegrationProfileService {

  private integrationProfileLabel: string;
  name: string;
  private columnInVisible: number[];

  filter_dataIni_P: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_P: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataIni_Create_P: string; // para manter os valores do filtro de data de inicio (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_Create_P: string; // para manter os valores do filtro de data de fim (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem

  datatableParametersIntegrationProfiles: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);
  private _integrationsProfiles: Array<IntegrationProfile>;


  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService,
    private dataTableFunctions: DataTableFunctions) { }
  

  //Serviço comum para IntegrationProfile

  //#region *** INICIO IntegrationProfile ***
  integrationProfile = new IntegrationProfile();


  private _controller: string = 'IntegrationProfile';


  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  
  get_integrationProfileLabel(defaultValue: string): string {
    if (typeof this.integrationProfileLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('IntegrationProfiles', 'IntegrationProfileLabel');
      if (settingValue != null) {
        this.integrationProfileLabel = settingValue;
      } else {
        this.integrationProfileLabel = defaultValue;
      }
    }
    return this.integrationProfileLabel;
  }

  get_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('IntegrationProfiles', 'ColumnsVisible');
      this.columnInVisible = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible;
  }

  get_IntegrationProfile(id: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetIntegrationProfile?id=' + id ).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateIntegrationProfile(integrationProfile: IntegrationProfile): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(integrationProfile));
    return this.http.post(SERVICE_URL + this._controller + '/UpdateIntegrationProfile', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteIntegrationProfile(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteIntegrationProfile?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addIntegrationProfile(integrationProfile: IntegrationProfile): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(integrationProfile));
    return this.http.put(SERVICE_URL + this._controller + '/AddIntegrationProfile', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  get integrationProfiles(): Observable<Array<IntegrationProfile>> {
    if (this._integrationsProfiles === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetIntegrationProfilesList').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._integrationsProfiles = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._integrationsProfiles);
    }
  }


}
