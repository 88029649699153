import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericFieldConfig } from 'src/app/models/generic-field-config';

@Component({
  selector: 'app-generic-fields-area',
  templateUrl: './generic-fields-area.html'
})
export class GenericFieldsAreaComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() fields: GenericFieldConfig[];
  @Input() formErrors: any[];
  @Input() uppercaseText: boolean = false;
  @Input() isServiceProvider: boolean = false;

  constructor() { }

  ngOnInit() { }

}
