import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GLOBAL_ERROR_PAGE } from '../../constants/global';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error) {
    if (GLOBAL_ERROR_PAGE && error.status !== 599) {
      const message = error.stack ? error.stack : error.toString();

      this.router.navigate(['/error'], { queryParams: { message: message, returnUrl: this.router.url } });
    }

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }

  public get router(): Router { // this creates router property on your service.
    return this.injector.get(Router);
  }
}
