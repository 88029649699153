import { GenericDocumentDetail } from './generic-document-detail';
import { GenericFieldExtension } from './generic-field-extension';

export class GenericDocument {
  constructor(
    // ID (PK)
    public ID: number,

    // Contexto que identifica o tipo de registo
    public Context: string,

    // ID do owner do registo
    public OwnerID: number,

    // ID do tipo
    public DocumentTypeID: number,

    // ID do estado
    public DocumentStateID: number,

    // Número/Código do registo
    public DocumentNumber: string = null,

    // Data de criação
    public CreationDate: string = null,

    // ID do utilizador que criou o registo
    public CreatedBy: number = null,

    // Data da ultima alteração
    public LastUpdated: string = null,

    // ID do utilizador que alterou o registo
    public LastUpdatedBy: number = null,

    // ID do utilizador que alterou o registo
    public BlockedBy: number = null,
    
    // ID da empresa do registo
    public CompanyID: number = null,

    // Contacto da entidade
    public EntityContact: number = null,

    // Prioridade do Documento
    public DocumentPriority: string = null,

    // Prioridade do Interna
    public InternPriority: string = null,

    // Assunto do Documento
    public DocumentSubject: number = null,

    // ID da Integração
    public IntegrationID: number = null,

    // ID da Localização
    public LocationID: number = null,

    // Nome da Entidade
    public EntityName: string = null,

    // Email da Entidade
    public EntityEmail: string = null,

    //Telefone da Entidade
    public EntityPhone: number = null,

    //Descrição do Documento
    public DocumentDescription: string = null,

    // Flag que indica se o estado permite submissão
    public StateIsPending: boolean = false,

    // Flag que indica se o estado permite edição
    public StateIsEditable: boolean = true,

    // Flag que indica se o estado permite eliminação
    public StateIsDeletable: boolean = false,

    // Descrição do estado
    public DocumentStateName: string = null,

    // Código do Fornecedor 
    public InternalID: string = null,

    //Nome do Empregado Owner
    public EmployeeOwner: string = null,

    //ID do Empregado Owner
    public EmployeeOwnerID: number = null,

    //Nome do Empregado Intern
    public EmployeeIntern: string = null,

    //Employee Intern ID
    public EmployeeInternID: number = null,

    //ID do Departamento
    public DepartmentID: number = null,

    //Nome do Departamento
    public Department: string = null,

    //Related Document
    public RelatedDocument: string = null,

    //Registration ID
    public RegistrationID: string = null,

    //External Code
    public ExternalCode: string = null,

    public ProcessSummary: string = null,
    
    // Valores dos campos
    public Fields: GenericFieldExtension[] = [],

    // Detalhes
    public Details: GenericDocumentDetail[] = []
  ) { }
}
