export class PricesListProduct {
    constructor(
        /// Identificador interno do registo (PK)
        public ID: number,

        /// ID da lista de preços a quem pertencem o detalhe.
        /// Identificador para uma entidade PriceListEntity
        public PriceListID: number,

        /// ID do produto a esta associado o detalhe.
        /// Identificador para uma entidade ProductEntity
        public ProductID: number,

        /// Preço do produto associado a praticar dentro do contexto da PriceList.
        /// (O preço tem precedência sobre o desconto para manter a compatibilidade com versões anteriores)
        public NetUnitPrice: number,

        /// Data de update
        public LastUpdated: Date,

        /// Referência do produto
        public IntegrationID: string,

        /// Nome do produto
        public Name: string,

        /// Desconto a aplicar ao Preço do produto associado à PriceList.
        public Discount?: number,

        /// Código da unidade de faturação
        public BillingUnitCode?: string,

        /// Observações
        public Notes?: string,

        /// Data a partir do qual o produto entra em vigor.
        public BeginDate?: Date,

        /// Data a partir do qual validade o produto expira.
        public EndDate?: Date,

        /// Lista de detalhes por produto
        public ProductDetails?: PricesListProductDetails[]
    ) { }
}

export class PricesListProductDetails {
    constructor(
        /// Identificador interno do registo (PK)
        public ID: number,

        /// ID do produto da lista de preços a quem pertencem o detalhe.
        /// Identificador para uma entidade PriceListProductValue
        public PriceListProductID: number,

        /// ID da entidade ligada ao detalhe da lista de preços.
        public EntityID: number,

        /// Typo da entidade ligada ao detalhe da lista de preços.
        /// Tipos: 1-Parceiro / 2-Grupo Económico / 3-Canal
        public EntityType: number,

        /// Preço do produto associado a praticar dentro do contexto da PriceList.
        /// (O preço tem precedência sobre o desconto para manter a compatibilidade com versões anteriores)
        public NetUnitPrice: number,

        /// Desconto a aplicar ao Preço do produto associado à PriceList.
        public Discount1?: number,

        /// Desconto a aplicar ao Preço do produto associado à PriceList.
        public Discount2?: number,

        /// Desconto a aplicar ao Preço do produto associado à PriceList.
        public Discount3?: number,

        /// Desconto a aplicar ao Preço do produto associado à PriceList.
        public Discount4?: number
    ) { }
}
