import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { ProductServiceTypology } from '../models/product-service-typology'; 
import { TipologiaProdutoServico } from '../models/tipologia-produto-servico';
import { of as observableOf, Observable } from 'rxjs';

@Injectable()
export class ProductServiceTypologyService {
  private _controller: string = 'TipologiaProdutoServico';
  private tpsp = new Map<number,Array<TipologiaProdutoServico>>();  

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[2, 'asc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions) { }

  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
 
  getList(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetList')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getParentsList(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetParentsList')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public getFilteredParentsList(companyID: number = 0): Observable<Array<any>> {
    if (!this.tpsp.has(companyID)) {
      return this.http.get(SERVICE_URL + 'TipologiaProdutoServico/GetParentsList' + (companyID != 0 ? '?companyID='+companyID:'')).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {              
              this.tpsp.set(companyID, responseReturn.ReturnStatus.ReturnObject); 
              return this.tpsp.get(companyID);
            } else {
              return new Array<any>();
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {      
      return observableOf(this.tpsp.get(companyID));
    }
  }
  
  add(model: ProductServiceTypology): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', { entity: model })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: ProductServiceTypology): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', { entity: model })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  exportListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList', { parameters: params});
  }

 
}
