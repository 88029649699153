import { Injectable } from "@angular/core";
import { HttpClientCustom } from "./http-client";
import { MasterService } from "./master.service";
import { ErrorTreatmentFunctions } from "../modules/treatments.module";
import { AuthenticationService } from "./authentication.service";
import { of as observableOf, Observable } from 'rxjs';
import { ReturnStatusHtml } from "../models/returnStatus";
import { SERVICE_URL } from "../constants/global";
import { map } from "rxjs/operators";
import { Role } from "../models/role";
import { OrderDetailWorkflow } from "../models/orderDetailWorkflow";

@Injectable()
export class OrderDetailWorkflowService {

  private _orderDetailWorkflowUsers: Array<any>;
  private _orderDetailWorkflowRoles: Role[];
  private _controller: string = 'OrderDetailWorkflow';
  showCreationPopupR: boolean;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) {
  }

  /**
   * Obter todos workflow's
   * @returns Observable
   */
  getAll(PurchasesOrSales: string, context?: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllWorkflows', { PurchasesOrSales: PurchasesOrSales, context: context }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Obter workflow por id
 * @param  {number} id
 * @returns Observable
 */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

    
  /**
   * Adicionar workflow
   * @param  {OrderDetailWorkflow} orderDetailWorkflow
   * @returns Observable
   */
  add(orderDetailWorkflow: OrderDetailWorkflow): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', orderDetailWorkflow).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Eliminar workflow
 * @param  {number} id
 * @returns Observable
 */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar workflow
   * @param  {OrderDetailWorkflow} orderDetailWorkflow
   * @returns Observable
   */
  update(orderDetailWorkflow: OrderDetailWorkflow): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', orderDetailWorkflow).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  
  get_showCreationPopup(defaultValue: boolean): boolean {
    if (typeof this.showCreationPopupR === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersDetailWorkflow', 'ShowCreationPopup');
      if (settingValue != null) {
        this.showCreationPopupR = settingValue;
      } else {
        this.showCreationPopupR = defaultValue;
      }
    }
    return this.showCreationPopupR;
  }

     /**
   * Obter utilizadores
   * @returns Observable
   */
  public get orderDetailWorkflowUsers(): Observable<Array<any>> {
    if (this._orderDetailWorkflowUsers === undefined) {
      return this.http.get(SERVICE_URL + 'User/GetAll').pipe(map((response) => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return this._orderDetailWorkflowUsers = responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._orderDetailWorkflowUsers);
    }
  }

  /**
   * Obter roles
   * @returns Observable
   */
  public get orderDetailWorkflowRoles(): Observable<Role[]> {
    if (this._orderDetailWorkflowRoles === undefined) {
      return this.http.get(SERVICE_URL + 'User/GetAllowedRoles').pipe(map((responseRaw: any) => {
        try {
          let response = this._masterService.convertToReturnStatusHtml(responseRaw);

          if (response.ReturnStatus.Successfull) {
            this._orderDetailWorkflowRoles = response.ReturnStatus.ReturnObject as Role[];
            return this._orderDetailWorkflowRoles.sort((n1: Role, n2: Role) => {
              if (n1.Name > n2.Name) {
                return 1;
              }

              if (n1.Name < n2.Name) {
                return -1;
              }

              return 0;
            });
          } else if (!response.IsAuthenticated) {
            document.location.href = document.location.origin;
          } else {
            return [];
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._orderDetailWorkflowRoles.sort((n1: Role, n2: Role) => {
        if (n1.Name > n2.Name) {
          return 1;
        }

        if (n1.Name < n2.Name) {
          return -1;
        }
        return 0;
      }));
    }
  }

}