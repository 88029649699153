import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DashboardService } from '../../../services/dashboard.service';
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { DashboardPanelChart } from 'src/app/models/dashboard-panel-chart';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multiserie-chart',
  templateUrl: './multiserie-chart.html'
})
/// <summary>
/// Componente que mostra um gráfico de multiseries
/// </summary>
export class MultiserieChartComponent implements OnInit, OnDestroy {
  @Input() chart: DashboardPanelChart;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // Chart parâmetros
  public chartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public chartLabels: string[] = [];
  public chartLegend: boolean = true;
  public chartData: any[] = [];
  public chartColors: any[] = [];

  constructor(private dashboardService: DashboardService, private _errorTreat: ErrorTreatmentFunctions, private router: Router) { }

  ngOnInit(): void {
    this.dashboardService.getDashboardPanelChartData(this.chart.ID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        // Eixo X
        this.chartLabels = response.ReturnStatus.ReturnObject.Labels as string[];
        // Eixo Y
        this.chartData = response.ReturnStatus.ReturnObject.Series as any[];

        // Carrega as cores
        if (this.chart.ChartColors) {
          let colors = this.chart.ChartColors.split(';');

          for (let index = 0; index < this.chartData.length; index++) {
            this.chartColors[index] = { backgroundColor: colors[index] };
          }
        }
      } else {
        this._errorTreat.treatErrorResponse(response);
      }
    });
  }

  navigateTo() {
    // Se tiver o url configurado, navega
    if (this.chart.ChartUrl) {
      // Verifica se é um url interno da aplicação
      if (this.chart.ChartUrl.indexOf('http') === -1) {
        this.router.navigate([this.chart.ChartUrl]);
      } else {
        window.location.href = this.chart.ChartUrl;
      }
    }
  }

  ngOnDestroy() { }
}
