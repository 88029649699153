import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
// Suplementos ao material
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
//  imports: [
//     MatFormFieldModule,
//     MatCheckboxModule,
//     MatInputModule,
//     MatRadioModule,
//     MatSelectModule,
//     MatListModule,
//     MatTabsModule,
//     MatIconModule,
//     MatDialogModule,
//     DragDropModule,
//     MatExpansionModule,
//     NgxMatSelectSearchModule
//  ],
  exports: [
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    DragDropModule,
    MatExpansionModule,
    NgxMatSelectSearchModule
  ]
})
export class MaterialModule { }
