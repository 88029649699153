import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { AuthenticationService } from './authentication.service';
import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class ShareWalletService {

    dataIniSale: string;
    dataFimSale: string;
    // Filtros
    datatableParametersSharedWallet: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, null, 0);
    datatableParametersSharedWalletList: DatatableParameters = new DatatableParameters([null, null, null,null], 10, null, 0);

    private _controller: string = 'ShareWallet';
   

    constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) {
    }

    
    getShareWalletList(StartDate: Date, EndDate: Date): Observable<ReturnStatusHtml> {
      return this.http.post(SERVICE_URL + this._controller + '/GetSharedWalletForDataTable', {startDate: StartDate, endDate: EndDate }) // primeiro nome igual ao c#
        .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }
    
    getShareWalletDetails(ID: number): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/GetSharedWalletDetailsForDataTable', {ID: ID}) // primeiro nome igual ao c#
          .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
      }
    
    getShareWalletHeader(ID: number): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/GetSharedWalletHeader', {ID: ID}) // primeiro nome igual ao c#
          .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
      }


    // fazer serviço delete
    delete(id: number): Observable<ReturnStatusHtml> {
        return this.http.delete(SERVICE_URL + this._controller + '/Delete?shareWalletId=' + id).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    // fazer o serviço download list
    downloadExcelShareWalletList(params: any, firstdate: string, lastdate: string): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelShareWalletList',
            {
                parameters: params,
                firstdate: firstdate,
                lastdate: lastdate
            });
    }
    // fazer o serviço download sharewallet
    downloadExcelShareWallet(params: any,ID: number): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelShareWallet', { parameters: params,shareWalletId: ID})
    }
    
    
    
    
    
}
