import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'controlContainsText'
})
export class ControlContainsTextPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    let fieldName = args[0];
    let fieldValue = args[1].toUpperCase();

    return value.filter(e => e.get(fieldName).value.toUpperCase().indexOf(fieldValue) !== -1);
  }
}
