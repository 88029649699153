import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
// import { Dropdown } from '../models/dropdown';
import { DatatableParameters } from '../models/datatable-parameters';
import { DropdownEditable } from '../models/dropdown-editable';
import { TaxGroup } from '../models/tax-group';

@Injectable()
export class TaxGroupService {
  private _controller: string = 'GrupoImposto';

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[2, 'asc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions) { }

  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getList(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetCodesList')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: TaxGroup): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', { entity: model })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: TaxGroup): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', { entity: model })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  exportListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList', { parameters: params});
  }

  getGrupoIVARefaturacao(companyId: number, codGrupoContabilizacao: string, taxaIVA: number, metodoDeducaoIVA: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetGrupoIVARefaturacao?companyID=' + companyId + '&codGrupoContabilizacao=' + 
    codGrupoContabilizacao + '&taxaIVA=' + taxaIVA + '&metodoDeducaoIVA=' + metodoDeducaoIVA)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
 
}
