import { Injectable } from '@angular/core';

declare var Functions: any;

export class Filter {
  constructor(
    /// nome do campo, para colocar na label
    public Name: string,

    /// tipo de dados
    public Type: number,

    /// id do element dom
    public ID: string,

    // id da coluna que este campo vai filtrar
    public IDToFind?: string,

    // se for do tipo select tem de enviar quais os elementos
    public SelectElements?: Array<SelectElementData>,

    /// valor que o input tem inicialmente
    public InitialValue?: string | number | boolean | Array<any>,

    public EventsDom?: any
  ) { }
}
export class FilterSelect {
  constructor(
    /// id do element dom
    public ID: string,

    // os elementos para o select
    public SelectElements: Array<SelectElementData>,

    /// nome do campo, para colocar na label
    public Name?: string,

    // id da coluna que este campo vai filtrar
    public IDToFind?: string,

    /// valor que o input tem inicialmente
    public InitialValue?: string | number | boolean,
  ) { }
}
export class FilterEntity {
  constructor(
    /// id do element dom
    public ID: string,

    // os elementos para o datatable
    public SelectElements: Array<TableElementData>,

    /// nome do campo, para colocar na label
    public Name?: string,

    // id da coluna que este campo vai filtrar
    public IDToFind?: string,

    /// valor que o input tem inicialmente (id)
    public InitialValue?: string | number | boolean,
  ) { }
}
export class FilterFamily {
  constructor(
    /// id do element dom
    public ID: string,

    // os elementos para o select
    public SelectElements: Array<SelectElementData>,

    // os elementos para o select das subfamilias
    public SelectElementsSub: Array<SelectElementDataChild>,

    // os elementos para o select das subsubfamilias
    public SelectElementsSubSub: Array<SelectElementDataChild>,

    /// nome do campo, para colocar na label
    public Name?: string,

    // id da coluna que este campo vai filtrar
    public IDToFind?: string,

    /// valor que o input tem inicialmente
    public InitialValue?: string | number | boolean,
  ) { }
}
export class FilterGeral {
  constructor(
    /// id do element dom
    public ID: string,

    /// nome do campo, para colocar na label
    public Name?: string,

    // id da coluna que este campo vai filtrar
    public IDToFind?: string,

    /// valor que o input tem inicialmente
    public InitialValue?: string | number | boolean,
  ) { }
}

export class FilterColumnTable {// filtros das colunas da datable
  constructor(
    /// index da coluna na tabela
    public Index: number,

    /// tipo de dados
    public Type: number,

    /// valor que o input tem inicialmente
    public InitialValue?: string | number | boolean,

    // se for do tipo select tem de enviar quais os elementos
    public SelectElements?: Array<SelectElementData>,

  ) { }
}

export enum FiltersType {
  String = 1,
  Number = 2,
  Boolean = 3,
  Datetime = 4,
  Select = 5,
}

export class SelectElementData {
  constructor(
    /// value para colocar no select
    public Value: string,

    /// texto para colocar no select
    public Text: string,

  ) { }
}
export class TableElementData {
  constructor(
    /// value para ID
    public ID: string,

    /// valor para Nome
    public Name: string,

    // valor para NIF
    public TaxNumber: string,

  ) { }
}
export class SelectElementDataChild {
  constructor(
    /// value para colocar no select
    public Value: string,

    /// texto para colocar no select
    public Text: string,

    /// value do pai (depende do pai)
    public FatherValue: string,

  ) { }
}

@Injectable()
export class DockModalFunctions {
  // tslint:disable-next-line:no-empty
  constructor() { }

  /**
   * Controi os elementos DOM necessários
   * @param  {number} numberCol - numero de colunas que a tabela vai ter
   * @param  {string} idTable - id da tabela
   */
  buildElements(numberCol: number, idTable: string) {
    let idTableSmall = idTable.replace('#', '');
    let divContainer = document.createElement('div');
    divContainer.id = idTableSmall + '-container';
    divContainer.className = 'all-dockmodal';
    let divTable = document.createElement('div');
    divTable.className = 'table-responsive';
    let divTable2 = document.createElement('div');
    divTable2.className = 'col-md-12';
    let table = document.createElement('table');
    table.id = idTableSmall;
    table.className = 'table table-bordered display noselect';

    table.appendChild(document.createElement('thead')).appendChild(document.createElement('tr'));
    table.appendChild(document.createElement('tbody'));
    divTable2.appendChild(table);
    divTable.appendChild(divTable2);
    divContainer.appendChild(divTable);

    document.getElementById('div-dockmodal-dynamics').appendChild(divContainer);

    // adicionar na tabela do dockmodal o header
    let header = document.querySelector(idTable + ' thead tr');
    for (let index = 0; index < numberCol; index++) {
      header.appendChild(document.createElement('th'));
    }
  }


  /**
   * Se for para colocar outros filtros alem do filtro global
   * @param  {string} idTable - id da tabela
   * @param  {Filters} arrayFilters - array com nome e tipo de filters
   * @param  {boolean} initOpen - se o div aberto ou fechado (colapse), por feito é false = fechado
   * @param  {string} lang - lingua para colocar no filtro de data
   * @param  {string} formatDate - formato da data para colocar no filtro de data
   */
  buildElementsFilters(idTable: string, arrayFilters: Array<Filter>, initOpen: boolean = false, lang: string = null, formatDate: string = null, filterTitle: string = null, filterID: number = 1) {
    let divContainer = document.createElement('div');
    divContainer.className = 'col-sm-12 no-padding';

    let divPanel = document.createElement('div');
    divPanel.className = 'panel panel-default';

    let divHeading = document.createElement('div');
    divHeading.className = 'panel-heading clearfix pointer';
    divHeading.setAttribute('data-toggle', 'collapse');
    divHeading.setAttribute('data-target', '#FilterSearchDock'+filterID);
    divHeading.insertAdjacentHTML('beforeend', '<h5 class="panel-title"><i class="fas fa-filter"></i><strong>' + (filterTitle ? filterTitle : 'Filtros') + '</strong><i class="collapse-icon fas fa-caret-down"></i></h5>');

    let divBody = document.createElement('div');
    divBody.id = 'FilterSearchDock'+filterID;
    divBody.setAttribute('style', 'padding: 5px 0 0;');
    divBody.className = 'panel-body no-horizontal-padding collapse';
    if (initOpen) {
      divBody.className += ' in';
    }

    let divFormGroup = document.createElement('div');
    divFormGroup.className = 'form-group';

    let arrayDate = [];

    arrayFilters.forEach((filter: Filter) => {

      let element; // para usar caso seja necessario adicionar um evento
      // criar a label
      let label = document.createElement('label');
      label.innerHTML = filter.Name;
      label.htmlFor = filter.ID;
      // criar div onde a label e o input vao inserir
      let divFormLine = document.createElement('div');

      if (filter.Type === FiltersType.Select) {
        // criar elemento select
        let select = document.createElement('select');
        select.id = filter.ID;
        select.className = 'form-control materialStyle';
        divFormLine.className = 'col-xs-12 no-padding';
        // criar option vazia
        let option = document.createElement('option');
        option.text = '';
        option.value = '';
        select.appendChild(option);
        // criar as restantes option
        filter.SelectElements.forEach((selectEl: SelectElementData) => {
          option = document.createElement('option');
          option.text = selectEl.Text;
          option.value = selectEl.Value;

          select.appendChild(option);
        });
        label.className = 'materialStyleDiv';

        let divEx = document.createElement('div');
        if (filter.Name.length === 0) {
          divEx.className = 'col-xs-10 pull-right no-padding';
        } else {
          divEx.className = 'col-xs-12 no-padding';
        }

        divEx.setAttribute('style', 'display:flex; height:35px;margin-bottom:5px');
        divFormLine.appendChild(divEx);
        divEx.appendChild(label);
        divEx.appendChild(select);

        // valor inicial
        if (filter.InitialValue) {
          select.value = <string>filter.InitialValue;
        }
        element = select;
      } else {
        // criar o input
        let input = document.createElement('input');
        input.id = filter.ID;
        if (filter.Type === FiltersType.Boolean) {
          // é um checkbox, tem o div tem primeiro o input e depois a label
          divFormLine.className = 'md-checkbox col-xs-12 no-padding';
          input.type = 'checkbox';
          input.setAttribute('style', 'width: 15px;');
          divFormLine.appendChild(input);
          label.htmlFor = filter.ID;
          divFormLine.appendChild(label);
          if (filter.InitialValue) {
            input.checked = <boolean>filter.InitialValue;
          }
        } else {

          // nao é um checkbox, a label é inserida primeiro que o input
          input.className = 'form-control materialStyle';
          input.setAttribute('style', 'margin-bottom: 5px;');
          divFormLine.className = 'form-group form-inline materialStyle';
          if (filter.Name.length === 0) {
            divFormLine.setAttribute('style', 'width: 85%;float:right');
          } else {
            divFormLine.setAttribute('style', 'width: 100%;');
          }

          if (filter.Type === FiltersType.Datetime) {
            // é um datetime, vai adicionar o id a um array
            arrayDate.push(filter.ID);
          }
          input.addEventListener('focus', function () {
            if (!this.parentElement.classList.contains('withText')) {
              this.parentElement.classList.add('withText');
            }
          });
          input.addEventListener('blur', function () {
            if ((<HTMLInputElement>this).value !== '') {
              document.querySelector('label[for="' + this.id + '"]').className = 'materialStyle withText';
            } else {
              document.querySelector('label[for="' + this.id + '"]').className = 'materialStyle';
              this.parentElement.classList.remove('withText');
            }
          });
          label.className += ' materialStyle';
          divFormLine.appendChild(input);
          divFormLine.appendChild(label);

          if (filter.InitialValue) {
            input.value = <string>filter.InitialValue;
            input.parentElement.classList.add('withText');
            label.classList.add('withText');
          }
        }
        element = input;
      }

      divFormGroup.appendChild(divFormLine);

      // se o filtro tiver algum evento
      if (filter.EventsDom) {
        element.addEventListener(filter.EventsDom['event'], function (evt) {
          filter.EventsDom['function'](evt);
        });
      }

    });

    // adicionar botao de procura
    let button = document.createElement('button');
    button.className = 'btn btn-primary btn-flat pull-right';
    button.id = idTable.replace('#', '') + 'btn_search';
    button.insertAdjacentHTML('beforeend', '<i class="fas fa-search" aria-hidden="true"></i>');

    divFormGroup.appendChild(button);

    // adicionar botao de limpar
    let buttonLimpar = document.createElement('button');
    buttonLimpar.className = 'btn btn-default btn-flat pull-right';
    buttonLimpar.id = idTable.replace('#', '') + 'btn_clean';
    buttonLimpar.addEventListener('click', function () {

      arrayFilters.forEach((filter: Filter) => {
        let elementFilter = document.getElementById(filter.ID);
        if (filter.Type === FiltersType.Boolean) {
          (<HTMLInputElement>elementFilter).checked = false;
        } else if (filter.Type === FiltersType.Select) {
          (<HTMLSelectElement>elementFilter).selectedIndex = -1;
        } else {
          (<HTMLInputElement>elementFilter).value = '';
        }
        if ('createEvent' in document) {
          let evt = document.createEvent('HTMLEvents');
          evt.initEvent('change', false, true);
          elementFilter.dispatchEvent(evt);
        }
      });
      document.getElementById(idTable.replace('#', '') + 'btn_search').click();
    });
    buttonLimpar.insertAdjacentHTML('beforeend', '<i class="fas fa-broom" aria-hidden="true"></i>');
    divFormGroup.appendChild(buttonLimpar);

    divBody.appendChild(divFormGroup);

    divPanel.appendChild(divHeading);
    divPanel.appendChild(divBody);
    divContainer.appendChild(divPanel);

    document.querySelector(idTable).parentElement.insertBefore(divContainer, document.querySelector(idTable));

    // se existem inputs do formato data
    if (arrayDate.length > 0) {
      arrayDate.forEach(inputID => {
        Functions.dataTimePicker('#' + inputID, lang, 1, formatDate, 0, false, {}, null, null, null, null, null, null, null, true);
      });
    }

  }

  /**
   * Controi a janela dockModal com a datatable
   * @param  {string} title - titulo a aplicar na dockmodal
   * @param  {Array<string>} translations -array de traducoes necessarias
   * @param  {string} idTable - id a criar para colocar a tabela
   * @param  {string} dataUrl - url para ir buscar dados ao servidor
   * @param  {Array<any>} dataSet - array de dados se dataset
   * @param  {string} aliasUrl - url base do angular para conseguir aceder aos assets e ir buscar o ficheiro da datatables de traducao
   * @param  {string} language - lingua atualmente utilizada (currentLanguage)
   * @param  {string} postGet - - se for para ir buscar os dados ao servidor- 'post' ou 'get', caso contrario null
   * @param  {any} data - se for para ir buscar os dados ao servidor- dados que possam existir para filtrar
   * @param  {Array<any>} aoColumns - colunas a usar no datatables
   * @param  {Array<any>} columnDefs - definicoes das colunas do datatables
   * @param  {(number|null)} maxSelectLines - numero máximo de linhas que pode selecionar, se null nao tem limite
   * @param  {string} idDropZone - id do elemento DOM que vai ser a drop zone do drag and drop
   * @param  {(data:any, component: any)=>any} funcAdd - funcao a executar quando clica no botao de adicionar (recebe o array de selecionados)
   * @param  {(data:any, component: any)=>any} funcDoubleClick - funcao a executar quando faz duplo clique numa linha (recebe o selecionados)
   * @param  {(data:any, component: any)=>any} funcDrag - funcao a executar quando arrasta uma linha (recebe o arrastado)
   * @param  {any} component - componente que esta a chamar (this ou this.form...), pode-se colocar aqui os objectos que necessita para as funcoes
   * @param  {boolean|null} globalSearch - se é para colocar a procura global, por defeito coloca
   * @param  {(data:any, component: any)=>any} funcImgClick - funcao a executar quando faz clique numa iamgem da linha (recebe o selecionados)
   * @returns {Promise} com a funcao de reload, e a funcao de reload de apenas 1 parametro ( resolve([reloadTabelaDock, reloadParameterTabelaDock]);)
   */
  createDock(title: string, translations: Array<string>, idTable: string, dataUrl: string, dataSet: Array<any>, aliasUrl: string, language: string, postGet: string, data: any, aoColumns: Array<any>,
    columnDefs: Array<any>, maxSelectLines: (number | null), idDropZone: string, funcAdd: (data: any, component: any) => any, funcDoubleClick: (data: any, component: any) => any, funcDrag: (data: any, component: any) => any, component: any,
    globalSearch?: boolean, funcImgClick?: (data: any, component: any) => any): Promise<any> {

    return new Promise(function (resolve, reject) {
      let tableDockProm = Functions.dockmodalN(title, translations, idTable, dataUrl, dataSet, aliasUrl, globalSearch, language, postGet, data, aoColumns, columnDefs, maxSelectLines, idDropZone, funcDrag, component);

      tableDockProm.then(resultProm => {
        if (!resultProm || resultProm[0].rows().count() < 0) { // se nao construiu agora a tabela ou se nao tem dados
          return;
        }
        let tableDock = resultProm[0];

        tableDock.on('draw', function () {
          // apos criar a tabela vai adicionar as acoes dos botoes
          let btnAdd = document.getElementsByClassName('add' + idTable)[0];

          if (btnAdd) {

            // Fazer clone do node, para remover todos os events listeners associados
            var btnAddCloned = btnAdd.cloneNode(true);
            btnAdd.parentNode.replaceChild(btnAddCloned, btnAdd);

            btnAddCloned.addEventListener('click', function (ev: any) {
              let objSelec = [];
              // ir buscar as linhas selecionadas
              let tableData = tableDock.rows({ selected: true }).data();
              for (let i = 0; i < tableData.length; i++) {
                // so devolver os dados das linhas
                objSelec.push(tableData[i]);
              }
              // retirar a seleccao das linhas
              funcAdd(objSelec, component);
            });
            
          }

          let btnsAddDoubleClick = document.querySelectorAll(idTable + ' tbody tr');
          if (btnsAddDoubleClick) {
            for (let btnAddDoubleClick of Array.from(btnsAddDoubleClick)) {
              btnAddDoubleClick.addEventListener('dblclick', function (ev: any) {
                let objSelec = tableDock.row(this).data();
                funcDoubleClick(objSelec, component);
              });
            }
          }

          let btnsClickImage = document.getElementsByClassName('table-img');
          if(btnsClickImage){
            for (let btnClickImage of Array.from(btnsClickImage)) {
              btnClickImage.addEventListener('click', function (ev: any) {
                let objSelec = tableDock.row(this).data();
                funcImgClick(objSelec, component);
              });
            }
          }
        });

        return resolve(resultProm[1]);
      });
    });
  }
  /**
   * Minimizar ou maximizar dock
   * @param  {string} idTable
   * @param  {boolean} soMax - se true, só vai maximizar, se false só vai minimizar, se null (ou nao enviar) faz o oposto/toggle do que está
   */
  minimizeMaxDockmodal(idTable: string, soMax: boolean = null) {
    Functions.minimizeMaxDockmodal(idTable, soMax);
  }

  /**
   * Fechar dock
   * @param  {string} idTable
   */
  closeDockmodal(idTable: string) {
    Functions.closeDockmodal(idTable);
  }

  reloadDockmodal(idTable: string, data:any) {
    Functions.datatables_reload(idTable, data);
  }
}
