import { DecimalPipe } from '@angular/common';
import { Optional } from '@angular/core';
import { CURRENCY_DECIMAL, QUANTITY_DECIMAL, UNITARY_PRICE_DECIMAL } from '../constants/global';
import { GenericFieldExtension } from './generic-field-extension';
import { QuotationSupplierResponse, QuotationSupplierResponseDetail } from './quotationSupplierResponse';

export enum QuotationContext { PURCHASES = 'Purchases', SALES = 'Sales' }
export enum QuotationState { SAVED = 1, SUBMITTED = 5, CANCELLED = 7, SUPPLIER_ANALYSIS = 11, ASWERED_CENTRAL_COMPRAS = 14, TO_QUOTE = 15, QUOTED = 16, PARTIALLY_QUOTED = 18,
    EXPORTED = 8, ACCEPTED = 91, PARCIALLY_ACCEPTED = 92, PARCIALLY_REJECTED = 93, DECLINADA = 94, RESPONDIDO_POR_EMAIL = 95}

export class Quotation {
    constructor(
        @Optional() public dataInicio: string = null,
        @Optional() public dataFim: string = null,
        @Optional() public ID: number = null,
        @Optional() public OwnerID: number = null,
        @Optional() public DocumentState: number = null,
        @Optional() public DocumentType: string = null,
        @Optional() public DocumentNumber: string = null,
        @Optional() public DocumentDate: string = null,
        @Optional() public ExpirationDate: string = null,
        @Optional() public CurrencyCode: string = null,
        @Optional() public CurrencyCodeOther: string = null,
        @Optional() public SupplierID: number = null,
        @Optional() public BuyerID: number = null,
        @Optional() public Notes: string = null,
        @Optional() public TotalNetAmount: number = null,
        @Optional() public SupplierIdentifier: string = null,
        @Optional() public SupplierInternalID: string = null,
        @Optional() public SupplierName: string = null,
        @Optional() public SupplierStreet: string = null,
        @Optional() public SupplierCity: string = null,
        @Optional() public SupplierPostalCode: string = null,
        @Optional() public SupplierCountryCode: string = null,
        @Optional() public BuyerIdentifier: string = null,
        @Optional() public BuyerInternalID: string = null,
        @Optional() public BuyerName: string = null,
        @Optional() public BuyerStreet: string = null,
        @Optional() public BuyerCity: string = null,
        @Optional() public BuyerPostalCode: string = null,
        @Optional() public BuyerCountryCode: string = null,
        @Optional() public CreationDate: string = null,
        @Optional() public CreatedBy: number = null,
        @Optional() public LastUpdated: string = null,
        @Optional() public LastUpdatedBy: number = null,
        @Optional() public WorkflowID: number = null,
        @Optional() public Details: QuotationDetail[] = null,
        @Optional() public BlockedBy: number = null,
        public StateIsPending: boolean = false,
        @Optional() public StateIsEditable: boolean = null,
        public StateIsDeletable: boolean = false,
        public SupplierWorkflowID: number = null,
        public EmailsTo: string = null,
        public Urgent: boolean = null,
        public SupplierNotes: string = null,
        public FieldsExtensions: GenericFieldExtension[] = [],
        public OrderedBy: string = null,
        @Optional() public Source: string = null,
        @Optional() public LastAcceptableDate: string = null,
        @Optional() public DeliveryLocationID: string = null,
        @Optional() public DeliveryLocationIdentifier: string = null,
        @Optional() public DeliveryLocationName: string = null,
        @Optional() public DeliveryLocationCountryCode: string = null,
        @Optional() public DeliveryLocationStreet: string = null,
        @Optional() public DeliveryLocationPostalCode: string = null,
        @Optional() public DeliveryLocationCity: string = null,
        @Optional() public OtherReferences: QuotationOtherReference[] = null,
        @Optional() public QuotationSupplierResponse: QuotationSupplierResponse[] = null,
        @Optional() public UserGroupID: number = null,
        @Optional() public BuyerIntegrationNumber: number = null,
        @Optional() public ValidBudgetDate: string = null
    ) { }
}

export class QuotationDetail {
    constructor(
        @Optional() public ID: number = null,
        @Optional() public QuotationID: number = null,
        @Optional() public LineNumber: number = null,
        @Optional() public ProductID: number = null,
        @Optional() public StandardProductCode: string = null,
        @Optional() public BuyerProductCode: string = null,
        @Optional() public SupplierProductCode: string = null,
        @Optional() public ProductDescription: string = null,
        @Optional() public OrderedQuantity: number = null,
        @Optional() public OrderedQuantityUOM: string = null,
        @Optional() public GrossUnitPrice: number = null,
        @Optional() public NetUnitPrice: number = null,
        @Optional() public TotalGrossAmount: number = null,
        @Optional() public TotalNetAmount: number = null,
        @Optional() public Notes: string = null,
        @Optional() public SupplierNotes: string = null,
        @Optional() public DeliveryDate: string = null,
        @Optional() public VATRate: number = null,
        @Optional() public OrderedQuantity_form: string = null,
        @Optional() public TotalNetAmount_form: string = null,
        @Optional() public NetunitPrice_form: string = null,

        
    ) { }
}

export class QuotationOtherReference {
    constructor(
        @Optional() public ReferenceType: string = null,
        @Optional() public ReferenceValue: string = null,
        @Optional() public ReferenceDate: string = null
    ) { }
}

export class TransformModel {
  private _decimalPipe: DecimalPipe;

    transformObjectDetail(detail: QuotationDetail, translLanguage: string): QuotationDetail {
      if (detail) {
        this._decimalPipe = new DecimalPipe(translLanguage);

        detail.NetunitPrice_form = detail.NetUnitPrice ? this.formatCurrency(parseInt(detail.NetUnitPrice.toString().replace(".",","))) : null;
        detail.OrderedQuantity_form = detail.OrderedQuantity ? this.formatQuantity(parseInt(detail.OrderedQuantity.toString().replace(".",","))) : null;
        detail.TotalNetAmount_form = detail.TotalNetAmount ? this.formatCurrency(parseInt(detail.TotalNetAmount.toString().replace(".",","))) : null;

        return detail;
      }
      return null;
    }

  
    /**
    * Transforma o FORMULÁRIO conforme settings
    *
    */
    transformFormDetail(detail: any, translLanguage: string): any {
      if (detail) {
        detail.get('OrderedQuantity').setValue(detail.get('OrderedQuantity').value ? (this.formatQuantity(detail.get('OrderedQuantity').value)) : null);
        return detail;
      }
      return null;
    }
  
    revertObjectDetails(detailsObj: any): QuotationDetail[] {
      if (detailsObj && detailsObj.length > 0) {
        detailsObj.forEach((detail: any) => {
          detail = this.revertObjectDetail(detail);
        });
      }
      return detailsObj;
    }
    revertObjectDetail(detailsObj: any): QuotationDetail {
      let detailsInvObj: QuotationDetail = detailsObj;

      detailsInvObj.OrderedQuantity = detailsObj.OrderedQuantity ? ((detailsObj.OrderedQuantity).toString()).revertDecimal() : null;
      detailsInvObj.NetUnitPrice = detailsObj.NetUnitPrice ? ((detailsObj.NetUnitPrice).toString()).revertDecimal() : null;

  
      return detailsInvObj;
    }
    revertObjectDetailQuotationSupplierResponse(detailsObj: any): QuotationSupplierResponseDetail {
      let quationSupplierResponseDetail: QuotationSupplierResponseDetail = detailsObj;

      quationSupplierResponseDetail.NetUnitPrice = detailsObj.NetUnitPrice ? ((detailsObj.NetUnitPrice).toString()).revertDecimal() : null;
      quationSupplierResponseDetail.TotalNetAmount = detailsObj.TotalNetAmount ? ((detailsObj.TotalNetAmount).toString()).revertDecimal() : null;
  
      return quationSupplierResponseDetail;
    }
    formatCurrency(value: number) {
      // colocar em formato de moeda ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
      return this._decimalPipe.transform(value, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL);
    }
  
    formatUnitPrice(value: number) {
      // colocar em formato de preco unitario ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
      return this._decimalPipe.transform(value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL);
    }
  
    formatQuantity(value: number) {
      // colocar em formato de quantidade ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
      return this._decimalPipe.transform(value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL);
    }
  
    formatDecimal(value: number, decimalPlaces: string) {
      // colocar em formato de moeda ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
      return this._decimalPipe.transform(value, '1.' + decimalPlaces + '-' + decimalPlaces);
    }

  
  }

