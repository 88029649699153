import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { interval as observableInterval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EventService } from '../../services/event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { CompanyService } from '../../services/company.service';
import { CommonService } from '../../services/common.service';
import { MessageService } from '../../services/message.service';
import { Session } from '../../models/session';
import { WEBSITE_URL } from '../../constants/global';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyInfoModalComponent } from './company-info-modal/company-info-modal.component';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { GraphicsModalComponent } from './graphics-modal/graphics-modal.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DashboardPanel, DisplayAreasEnum } from 'src/app/models/dashboard-panel';
import { ImageModalComponent } from '../shared/image-modal/image-modal.component';
import { TranslateValueService } from 'src/app/services/translate-value.service';

declare var App: any;
declare var Functions: any;

@Component({
  templateUrl: './layout.html'
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  session: Session;
  hideNonSPCompany: boolean = false;
  showAboutID4: boolean = false;
  // Listas
  messages: any[] = [];
  navBarPanels: DashboardPanel[] = [];

  logoUrl: string = '';

  constructor(private renderer: Renderer2, private authenticationService: AuthenticationService, private router: Router, private messageService: MessageService,
    private _eventService: EventService, private dialog: MatDialog, private companyService: CompanyService, private activatedRoute: ActivatedRoute,
    private commonService: CommonService, private dashboardService: DashboardService, private translateValueService: TranslateValueService) {
    this._eventService.eventReloadSession.pipe(takeUntil(this.destroy$)).subscribe((reloadW?: boolean) => this.reloadSession(reloadW));
  }

  ngOnInit(): void {
    this.renderer.removeClass(document.body, 'texture');
    this.session = this.authenticationService.session;

    // Verificar mensagens
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe(() => {
      let permission = this.authenticationService.session.permissions.filter(r => r.Name.toLowerCase() === 'messages')[0];

      if (permission) {
        this.messageService.getAllNotReadGroupedByOwner().pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => { if (response.ReturnStatus.Successfull) { this.messages = response.ReturnStatus.ReturnObject as any[]; } });
        observableInterval(120000).pipe(takeUntil(this.destroy$)).subscribe(x => {
          this.messageService.getAllNotReadGroupedByOwner().pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => { if (response.ReturnStatus.Successfull) { this.messages = response.ReturnStatus.ReturnObject as any[]; } });
        });

        this.messageService.share.pipe(takeUntil(this.destroy$)).subscribe((server: Observable<ReturnStatusHtml>) => server.subscribe((response: ReturnStatusHtml) => { if (response.ReturnStatus.Successfull) { this.messages = response.ReturnStatus.ReturnObject as any[]; } }));
      } else {
        this.messages = undefined;
      }
    });

    // Gráficos da barra de navegação
    this.dashboardService.getDashboardPanels(DisplayAreasEnum.NavigationBar).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        this.navBarPanels = response.ReturnStatus.ReturnObject;
      }
    });

    this.hideNonSPCompany = this.commonService.gethideNonSPCompany();
    this.showAboutID4 = this.commonService.getshowAboutID4();

    Functions.setTemplateSettings(WEBSITE_URL, this.session.css, this.session.logo, this.session.logoUrl);
  }

  ngAfterViewInit(): void {
    App.init();
    Functions.modalEffects();

    if (document.getElementById('sidebar-collapse')) {
      document.getElementById('sidebar-collapse').addEventListener('click', this.resizeAllDatatables);
    }

    if (this.session.logoUrl) {
      this.logoUrl = this.session.logoUrl;
    } 
    else if(this.session.logo)
    {
      this.logoUrl = WEBSITE_URL + 'assets/configurations/logos/' + this.session.logo;
    }
  }

  logout(): void {
    this.authenticationService.logout().pipe(takeUntil(this.destroy$)).subscribe(response => {

      if (!response.isAuthenticated && response.URL != null && response.URL != "") {
        document.location.href = response.URL;
      }
      else if (!response.isAuthenticated && response.URL == null) {
        Functions.cleanLayout();
        document.location.href = document.getElementsByTagName('base')[0].href;
      }
    });
  }

  reAuthenticate(): void {
    this.authenticationService.logout().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (!response.isAuthenticated) {
        Functions.cleanLayout();
        this.companyService.cleanPartners(); // forçar reset aos partners
        this.session = null;
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        // document.location.href = document.getElementsByTagName('base')[0].href + '/login?returnUrl=' + this.router.url;
      }
    });
  }

  reloadSession(reloadWindows: boolean = false) {
    this.companyService.cleanPartners(); // forçar reset aos partners
    this.session = this.authenticationService.session;
    if (reloadWindows) {
      // por causa das funcoes de javascript nos menus (open e parent) é necessário fazer reload
      this.router.navigate(['/dashboard']).then(() => {
        Functions.loading(true);
        document.location.reload();
      });
    }
  }

  openCompanyInfo() {
    this.dialog.open(CompanyInfoModalComponent);
  }

  resizeAllDatatables() {
    Functions.resizeAllDatatables();
  }

  isLinkActive(url: string): boolean {
    let urlParts = url.split('/');
    urlParts.splice(0, 1);

    let activeUrlParts = this.router.url.split('/');
    activeUrlParts.splice(0, 1);

    if (!isNaN(parseInt(urlParts[urlParts.length - 1], null))) { // se forem id's'
      let activeUrl = this.router.url;
      activeUrl = activeUrl.split('/')[1];

      // activeUrlParts.splice(activeUrlParts.length - 1, 1);
      // urlParts.splice(urlParts.length - 1, 1);

      return (activeUrlParts.join('/') === urlParts.join('/'));

    } else { // se não forem id's'
      let queryParams = activeUrlParts[1] ? activeUrlParts[1].split('?') : null; //vamos separar o url pelo ? caso tenha
      if (queryParams) {
        let result = queryParams[1] ? this.tryParseJSONObject(queryParams[1].split('=')[1]) : null; //se tiver algum query param, vamos buscar o valor, que está depois do igual
        if (result == null || result === false) { //se não tivermos nada ou se o valor vier a false significa que deve fazer o comportamento que já fazia
          // se o url ativo tiver ID, então removemos a última parte de ambos os urls
          if (!isNaN(parseInt(activeUrlParts[urlParts.length - 1], null))) {
            activeUrlParts.splice(activeUrlParts.length - 1, 1);
            urlParts.splice(urlParts.length - 1, 1);
          }
        } //caso contrário não faz nada
      } else {
        // se o url ativo tiver ID, então removemos a última parte de albos os urls
        if (!isNaN(parseInt(activeUrlParts[urlParts.length - 1], null))) {
          activeUrlParts.splice(activeUrlParts.length - 1, 1);
          urlParts.splice(urlParts.length - 1, 1);
        }
      }

      return (activeUrlParts.join('/') === urlParts.join('/'));
    }
  }

  returnRoute(route: any) {
    return route.split('?')[0];
  }

  returnQueryParams(route: any) {
    //TODO: prever múltiplos parametros
    //para já estamos a assumir apenas o primeiro
    let obj: any = {};
    let queryParam = route.split('?')[1];
    let prop, value;
    if (queryParam) {
      prop = queryParam.split('=')[0];
      if (prop) {
        value = JSON.parse(queryParam.split('=')[1]);
        obj[prop] = value;

        if (obj != null) {
          return obj;
        }
      }
    }
  }


  /**
   * Tenta executar o Parse do Objecto JSON [o JSON.parse direto estava a retornar exceções]
   * @param  {} value
   */
  tryParseJSONObject(value){
    try { 
      return JSON.parse(value) 
    } catch(e){ 
      return false
    }
  }

  // Gráficos
  openGraphics() {
    this.dialog.open(GraphicsModalComponent, {
      data: { panels: this.navBarPanels },
      minWidth: '80vw'
    });
  }
  // End - Gráficos

  openAboutID4Modal()
  {
    this.dialog.open(ImageModalComponent, {
      data: { // dados que vai enviar para o componente da modal
        imagePath: WEBSITE_URL + 'assets/img/id4software.png',
        message: this.translateValueService.get('ABOUT_ID4'),
      },
      disableClose: false, // nao permitir fechar modal com escape ou clique fora
      width: '360px'
    });
  }

  ngOnDestroy() {
   }
}
