export class GenericTypesConfig {

    constructor(
        /// O ID do tipos de documentos
        public ID: number = 0,

        /// ID do tipo de documento
        public GenericTypeID: number = null,

        /// email
        public Email: string = null,

        /// se é para tratar via connectTD
        public SendConnectTD: boolean = false,

        /// se está inativo
        public Inactive: boolean = false,

        /// empresa associada
        public OwnerID?: string,

        public OwnerInternalName: string = null,

        /// país
        public Country?: string,

        /// Descrição do Tipo
        public GenericTypeDescription?: string,

        /// InternalID da empresa associada
        public OwnerInternalID?: string,

        public CompanyID?: number,

        public CompanyName?: string

    ) { }


}

export class TransformModel {
    revertObject(configObj: any = null) {
        if (!configObj) {
            return;
        }
        let configObject: GenericTypesConfig = configObj;

        return configObject;
    }
}
