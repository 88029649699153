// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { validateForm } from '../../../constants/form-validators';


// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { ALIAS, LANGUAGE } from '../../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { QuotationWorkflowStep } from '../../../models/quotationWorkflow';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './transition-modal.html'
})
export class QuotationSupplierResponseTransitionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  statesList: Array<any> = new Array<any>();

  usersList: Array<any> = new Array<any>();
  rolesList: Array<any> = new Array<any>();

  usersIDsList: Array<any> = new Array<any>();
  approversIDsList: Array<any> = new Array<any>();
  usersRolesIDsList: Array<any> = new Array<any>();
  approversRolesIDsList: Array<any> = new Array<any>();

  transitionForm: UntypedFormGroup;
  model: QuotationWorkflowStep = null;
  tabelaUsers: any = null;
  tabelaApprovers: any = null;
  tabelaUsersRoles: any = null;
  tabelaApproversRoles: any = null;

  noError: boolean = true;
  submitted: boolean = false;
  formErrors: any = {
    'FromStateID': '',
    'ToStateID': '',
    'ActionVerb': '',
    'HasLimit': '',
    'MinimumLimit': '',
    'MaximumLimit': ''
  }; // erros do formulario, array para campos
  formErrorsParam: any = {}; // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean; // se tem permissoes de edicao

  constructor(public dialogRef: MatDialogRef<QuotationSupplierResponseTransitionModalComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private translateService: TranslateService, private formBuilder: UntypedFormBuilder) {

    this.statesList = data.states;
    this.usersList = data.users;
    this.rolesList = data.roles;
    this.transitionForm = data.stepForm;
    this.model = data.stepModel;
    this.validationMessages = data.validationMessagesSteps;
    this.allowEdit = data.allowEdit;

    if (this.model != null) {
      this.usersIDsList = JSON.parse(JSON.stringify(data.usersIDsList));
      this.approversIDsList = JSON.parse(JSON.stringify(data.approversIDsList));
      this.usersRolesIDsList = JSON.parse(JSON.stringify(data.usersRolesIDsList));
      this.approversRolesIDsList = JSON.parse(JSON.stringify(data.approversRolesIDsList));
    }
  }

  ngOnInit(): void {

    let that = this;
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    this.transitionForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() =>
      this.formErrors = validateForm(this.transitionForm, this.formErrors, this.validationMessages, this.submitted));
    this.formErrors = validateForm(this.transitionForm, this.formErrors, this.validationMessages, this.submitted);
  }

  ngAfterViewInit() {
    this.initUsersRolesTable();
    this.initUsersTable();
    this.initApproversRolesTable();
    this.initApproversTable();
  }

  save() {
    if (this.allowEdit) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.transitionForm.updateValueAndValidity();

      if (this.noError) {
        this.transitionForm.setControl('Approvers', this.formBuilder.array(this.approversIDsList));
        this.transitionForm.setControl('Users', this.formBuilder.array(this.usersIDsList));
        this.transitionForm.setControl('ApproversRoles', this.formBuilder.array(this.approversRolesIDsList));
        this.transitionForm.setControl('UsersRoles', this.formBuilder.array(this.usersRolesIDsList));

        if (this.transitionForm.controls['HasLimit'].value) {
          if (this.transitionForm.controls['MaximumLimit'].value == null || this.transitionForm.controls['MaximumLimit'].value === '') {
            this.transitionForm.controls['MaximumLimit'].setValue(0);
          }
          if (this.transitionForm.controls['MinimumLimit'].value == null || this.transitionForm.controls['MinimumLimit'].value === '') {
            this.transitionForm.controls['MinimumLimit'].setValue(0);
          }
        }

        this.dialogRef.close(this.transitionForm); // nao pode enviar so o value por causa dos campos disabled
      }
      this.submitted = false;
    }
  }

  // UsersRoles
  initUsersRolesTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableUsersRoles-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaUsersRoles = Functions.datatablesWithDataSet('#tabelaWorkflowUsersRoles', this.rolesList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaUsersRoles.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableUsersRoles-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.usersRolesIDsList.length > 0) {
              that.usersRolesIDsList.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterUsersRolesID(this.id);
            });
          }
        });
      });
  }

  obterUsersRolesID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.usersRolesIDsList.length > 0) {
      let idEncontrado = this.usersRolesIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.usersRolesIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.usersRolesIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.usersRolesIDsList.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.usersRolesIDsList.push(parseInt(id, null));
      }
    }
  }

  // Users
  initUsersTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableUsers-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaUsers = Functions.datatablesWithDataSet('#tabelaWorkflowUsers', this.usersList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaUsers.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableUsers-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.usersIDsList.length > 0) {
              that.usersIDsList.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterUsersID(this.id);
            });
          }
        });
      });
  }

  obterUsersID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.usersIDsList.length > 0) {
      let idEncontrado = this.usersIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.usersIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.usersIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.usersIDsList.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.usersIDsList.push(parseInt(id, null));
      }
    }
  }

  // Approvers Roles
  initApproversRolesTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="a' + id + '" type="checkbox" class="tableApproversRoles-choose"><label for="a' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaApproversRoles = Functions.datatablesWithDataSet('#tabelaWorkflowApproversRoles', this.rolesList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaApproversRoles.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableApproversRoles-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.approversRolesIDsList.length > 0) {
              that.approversRolesIDsList.forEach((idLista: any) => {
                let idTabela = 'a' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterApproversRolesID(this.id);
            });
          }
        });
      });
  }

  obterApproversRolesID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "a" do id
    let id = idRecebido.split('a').join('');

    if (this.approversRolesIDsList.length > 0) {
      let idEncontrado = this.approversRolesIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.approversRolesIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) { // "checou" novo approv
        this.approversRolesIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) { // "des-checou" approv
        this.approversRolesIDsList.splice(index, 1);
      }
    } else { // se a lista estiver vazia há de ser sempre adicionar
      if (element.checked) {
        this.approversRolesIDsList.push(parseInt(id, null));
      }
    }
  }

  // Approvers
  initApproversTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="a' + id + '" type="checkbox" class="tableApprovers-choose"><label for="a' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaApprovers = Functions.datatablesWithDataSet('#tabelaWorkflowApprovers', this.usersList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaApprovers.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableApprovers-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.approversIDsList.length > 0) {
              that.approversIDsList.forEach((idLista: any) => {
                let idTabela = 'a' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterApproversID(this.id);
            });
          }
        });
      });
  }

  obterApproversID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "a" do id
    let id = idRecebido.split('a').join('');

    if (this.approversIDsList.length > 0) {
      let idEncontrado = this.approversIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.approversIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) { // "checou" novo approv
        this.approversIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) { // "des-checou" approv
        this.approversIDsList.splice(index, 1);
      }
    } else { // se a lista estiver vazia há de ser sempre adicionar
      if (element.checked) {
        this.approversIDsList.push(parseInt(id, null));
      }
    }
  }

  // #endregion Step

  // #region Métodos Gerais e Comuns

  resetForm() {
    // reset às listas de id's (senão fica com os id's das checkbook selecionadas)
    this.transitionForm.reset(this.model);
  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
