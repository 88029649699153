import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SERVICE_URL } from 'src/app/constants/global';

export enum ButtonType { OK = 1, CONFIRMATION = 2 }

@Component({
  templateUrl: './image-modal.html'
})
export class ImageModalComponent {
  serverUrl: string = SERVICE_URL;
  message: string;
  buttonType: number = ButtonType.CONFIRMATION;
  ProductMediaID:any;
  ProductID:any;
  ProductName: string

  // MM 27/09/2022 -> variavél para receber o caminho de uma imagem (Sobre da Gulbenkian)
  imagePath: string;

  constructor(public dialogRef: MatDialogRef<ImageModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {

    //apenas usado pela gulbenkian para mostrar uma página sobre 
    this.imagePath = data.imagePath;

    this.message = data.message;
    if (data.ProductMediaID && data.ProductID && data.Name) {
      this.ProductMediaID = data.ProductMediaID;
      this.ProductID = data.ProductID;
      this.ProductName = data.Name;
    }
  }
}
