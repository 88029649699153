export class PaymentPrevisionConfig {
    constructor(
        // Identificar interno do registo
        public ID: number,

        // Empresa
        public CompanyCode: string,

        // Hierarquia
        public Hierarchy: string = null,

        // Nome da Area/Empresa
        public NomeArea: string,

        // Email
        public Email: string
    ) { }
}
