import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { CostCenter } from '../models/costCenter';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class CostCenterService {

  costCenter = new CostCenter();

  private _costCenterTypes: Array<any>;
  private _allCostCenters: Array<any>;

  private _controller: string = 'CostCenter';

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[2, 'asc']], 0);

  //SETTINGS
  private showAnalisysAxes: boolean;
  private showDocumentsTypes: boolean;
  private showCheckMandatoryOnOrders: boolean;
  private showLocationsPanel: boolean;
  private showCheckMandatoryOnRequests: boolean;
  private showCheckMandatoryOnContracts: boolean;

  constructor(
    private http: HttpClientCustom, 
    private _masterService: MasterService, 
    private authenticationService: AuthenticationService) { }

  /**
   * Obter tipos
   * @returns Observable
   */
  public get types(): Observable<Array<any>> {
    if (this._costCenterTypes === undefined) {
      return this.getComplements('GetAllTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._costCenterTypes = response.ReturnStatus.ReturnObject.Types;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._costCenterTypes);
    }
  }

  public get allCostCenters(): Observable<Array<any>> {
    if (this._allCostCenters === undefined) {
      return this.getComplements('GetAll').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._allCostCenters = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._allCostCenters);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar centro de custo
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar centro de custo
   * @param  {CostCenter} costCenter
   * @returns Observable
   */
  update(costCenter: CostCenter): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', costCenter).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar centro de custo
   * @param  {CostCenter} costCenter
   * @returns Observable
   */
  add(costCenter: CostCenter): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', costCenter).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter centro de custo por id
   * @param  {number} id
   * @returns Observable
   */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  exportCostCenterListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelCostCenterList', { parameters: params });
  }


  //SETTINGS
  get_showAnalisysAxes(defaultValue: boolean): boolean {
    if (typeof this.showAnalisysAxes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CostCenter', 'ShowAnalisysAxes');
      if (settingValue != null) {
        this.showAnalisysAxes = settingValue;
      } else {
        this.showAnalisysAxes = defaultValue;
      }
    }
    return this.showAnalisysAxes;
  }

  get_showDocumentsTypes(defaultValue: boolean): boolean {
    if (typeof this.showDocumentsTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CostCenter', 'ShowDocumentsTypes');
      if (settingValue != null) {
        this.showDocumentsTypes = settingValue;
      } else {
        this.showDocumentsTypes = defaultValue;
      }
    }
    return this.showDocumentsTypes;
  }

  get_showCheckMandatoryOnOrders(defaultValue: boolean): boolean {
    if (typeof this.showCheckMandatoryOnOrders === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CostCenter', 'ShowCheckMandatoryOnOrders');
      if (settingValue != null) {
        this.showCheckMandatoryOnOrders = settingValue;
      } else {
        this.showCheckMandatoryOnOrders = defaultValue;
      }
    }
    return this.showCheckMandatoryOnOrders;
  }

  get_showCheckMandatoryOnRequests(defaultValue: boolean): boolean {
    if (typeof this.showCheckMandatoryOnRequests === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CostCenter', 'ShowCheckMandatoryOnRequests');
      if (settingValue != null) {
        this.showCheckMandatoryOnRequests = settingValue;
      } else {
        this.showCheckMandatoryOnRequests = defaultValue;
      }
    }
    return this.showCheckMandatoryOnRequests;
  }

  get_showCheckMandatoryOnContracts(defaultValue: boolean): boolean {
    if (typeof this.showCheckMandatoryOnContracts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CostCenter', 'ShowCheckMandatoryOnContracts');
      if (settingValue != null) {
        this.showCheckMandatoryOnContracts = settingValue;
      } else {
        this.showCheckMandatoryOnContracts = defaultValue;
      }
    }
    return this.showCheckMandatoryOnContracts;
  }
  
  get_showLocationsPanel(defaultValue: boolean): boolean {
    if (typeof this.showLocationsPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CostCenter', 'ShowLocationsPanel');
      if (settingValue != null) {
        this.showLocationsPanel = settingValue;
      } else {
        this.showLocationsPanel = defaultValue;
      }
    }
    return this.showLocationsPanel;
  }
}
