
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReturnStatusHtml } from '../models/returnStatus';

@Injectable()
export class MasterService {

  private _environmentDep: boolean = false; // se esta em desenvolvimento

  constructor() { }

  public get enviromenDept() {
    return this._environmentDep;
  }

  public set enviromenDept(value: boolean) {
    this._environmentDep = value;
  }

  convertToReturnStatusHtml(response: any): ReturnStatusHtml {
    try {
      return response as ReturnStatusHtml;
    } catch (error) {
      console.error('An error occurred', error); // for demo purposes only
    }
  }

  handleErrorObservable(error: any): Observable<any> {
    console.error(error);
    return observableThrowError(error.message || 'Server error');
  }

  handleError(error: any): void {
    console.error(error);
  }
}
