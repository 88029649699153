
import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { Location, LocationType } from '../models/location';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';
import { Dropdown } from '../models/dropdown';
import { LocationPostalCode } from '../models/location-postal-code';

@Injectable()
export class LocationService {

  private _controller: string = 'Location';
  private _locations: Array<any>;
  private _filterByUser: boolean;
  private _onlyActive: boolean;
  private showCounty: boolean;
  private showWarehouseCode: boolean;
  private showTaxNumber: boolean;
  private shipToAddressLabel: string;
  private invoiceToAddressLabel: string;
  private hideBooleanFields: Array<string>;
  private showPostalCodeDistrictAndRegion: boolean;
  private showRegion: boolean;
  private showIntegrationCodes: boolean;
  private showControlOrdersTypes: boolean;
  private showProvisioning: boolean;
  private showAggregate: boolean;
  private show_hasWms: boolean;
  private show_exportDeliveryNote: boolean;
  private show_xDock: boolean;
  private show_WmsCode: boolean;
  private _transportLocations: Array<any>;
  private show_ShowWmsTab: boolean;
  private showShipToInstructions: boolean;
  private showUsersTab: boolean;
  private showOtherData: boolean;
  private showClassificationConfigurations: boolean;
  private showReplacementPeriod: boolean;
  private showColor: boolean;

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[6, 'asc']], 0);
  datatableParametersPostalCodes: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);

  // Listas
  private _associationTypes: Dropdown[];
  private _districts: string[];
  private _regions: string[];
  

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService) { }

  get(id: number, menuID?: string, showABCClassificationPanel: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + (menuID ? '&menuID=' + menuID : '') + '&getABCClassificationConfigs=' + showABCClassificationPanel).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getByWarehouseGLNCode(warehouseGLNCode: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByWarehouseGLNCode?warehouseGLNCode='+warehouseGLNCode).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: Location, menuID?: string): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', { model: model, menuID: menuID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: Location, menuID?: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', { model: model, menuID: menuID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number, menuID?: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id + (menuID ? '&menuID=' + menuID : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  downloadExcelLocationList(params: any, companyID: number): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelLocationList', { parameters: params, companyID: companyID });
  }

  public getLocationUsers(locationID: number, menuID?: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetLocationUsers?locationID=' + locationID + (menuID ? '&menuID=' + menuID : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public getAll(companyID: number, filterByUser: boolean, onlyActive: boolean, type: number = null, alwaysGet: boolean = true): Observable<Array<any>> {
    if ((this._locations === undefined || (this._locations && this._locations['\'' + companyID + '\''] === undefined) || this._filterByUser !== filterByUser || this._onlyActive !== onlyActive) || alwaysGet) {
      this._filterByUser = filterByUser;
      this._onlyActive = onlyActive;
      return this.http.get(SERVICE_URL + this._controller + '/GetAll?companyID=' + companyID + '&filterByUser=' + filterByUser + '&onlyActive=' + onlyActive + (type ? '&type=' + type : '')).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              if (this._locations === undefined) {
                this._locations = [];
              }
              return this._locations['\'' + companyID + '\''] = responseReturn.ReturnStatus.ReturnObject;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._locations['\'' + companyID + '\'']);
    }
  }

  getLocationPostalCode(countryCode: string, postalCode: string): Observable<ReturnStatusHtml> {
    // Obtém apenas os 4 primeiros dígitos
    let postalCodeBegin = postalCode ? postalCode.split('-')[0] : '';

    return this.http.get(SERVICE_URL + this._controller + '/GetLocationPostalCode?countryCode=' + countryCode + '&postalCode=' + postalCodeBegin).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region Listas de Valores
  get associationTypes(): Observable<Dropdown[]> {
    if (this._associationTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.LOCATION_ASSOCIATION_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._associationTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._associationTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._associationTypes);
    }
  }

  get districts(): Observable<string[]> {
    if (this._districts === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetAllDistricts').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._districts = responseReturn.ReturnStatus.ReturnObject as string[];
              return this._districts;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._districts);
    }
  }

  get regions(): Observable<string[]> {
    if (this._regions === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetAllRegions').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._regions = responseReturn.ReturnStatus.ReturnObject as string[];
              return this._regions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._regions);
    }
  }
  //#endregion Listas de Valores

  //#region Códigos Postais
  getPostalCode(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetPostalCode?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addPostalCode(model: LocationPostalCode): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddPostalCode', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updatePostalCode(model: LocationPostalCode): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdatePostalCode', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deletePostalCode(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeletePostalCode?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Códigos Postais

  // Settings
  get_showCounty(defaultValue: boolean): boolean {
    if (typeof this.showCounty === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowCounty');
      if (settingValue !== null) {
        this.showCounty = settingValue;
      } else {
        this.showCounty = defaultValue;
      }
    }
    return this.showCounty;
  }
  get_showWarehouseCode(defaultValue: boolean): boolean {
    if (typeof this.showWarehouseCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowWarehouseCode');
      if (settingValue !== null) {
        this.showWarehouseCode = settingValue;
      } else {
        this.showWarehouseCode = defaultValue;
      }
    }
    return this.showWarehouseCode;
  }
  get_showTaxNumber(defaultValue: boolean): boolean {
    if (typeof this.showTaxNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowTaxNumber');
      if (settingValue !== null) {
        this.showTaxNumber = settingValue;
      } else {
        this.showTaxNumber = defaultValue;
      }
    }
    return this.showTaxNumber;
  }
  get_shipToAddressLabel(defaultValue: string): string {
    if (typeof this.shipToAddressLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShipToAddressLabel');
      if (settingValue !== null) {
        this.shipToAddressLabel = settingValue;
      } else {
        this.shipToAddressLabel = defaultValue;
      }
    }
    return this.shipToAddressLabel;
  }
  get_invoiceToAddressLabel(defaultValue: string): string {
    if (typeof this.invoiceToAddressLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'InvoiceToAddressLabel');
      if (settingValue !== null) {
        this.invoiceToAddressLabel = settingValue;
      } else {
        this.invoiceToAddressLabel = defaultValue;
      }
    }
    return this.invoiceToAddressLabel;
  }
  get_hideBooleanFields(defaultValue: Array<string>): Array<string> {
    if (typeof this.hideBooleanFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'HideBooleanFields');
      if (settingValue !== null) {
        this.hideBooleanFields = settingValue;
      } else {
        this.hideBooleanFields = defaultValue;
      }
    }
    return this.hideBooleanFields;
  }
  get_showPostalCodeDistrictAndRegion(defaultValue: boolean): boolean {
    if (typeof this.showPostalCodeDistrictAndRegion === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowPostalCodeDistrictAndRegion');
      if (settingValue !== null) {
        this.showPostalCodeDistrictAndRegion = settingValue;
      } else {
        this.showPostalCodeDistrictAndRegion = defaultValue;
      }
    }
    return this.showPostalCodeDistrictAndRegion;
  }
  get_showRegion(defaultValue: boolean): boolean {
    if (typeof this.showRegion === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowRegion');
      if (settingValue !== null) {
        this.showRegion = settingValue;
      } else {
        this.showRegion = defaultValue;
      }
    }
    return this.showRegion;
  }
  get_showIntegrationCodes(defaultValue: boolean): boolean {
    if (typeof this.showIntegrationCodes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowIntegrationCodes');
      if (settingValue !== null) {
        this.showIntegrationCodes = settingValue;
      } else {
        this.showIntegrationCodes = defaultValue;
      }
    }
    return this.showIntegrationCodes;
  }

  get_showControlOrdersTypes(defaultValue: boolean): boolean {
    if (typeof this.showControlOrdersTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowControlOrdersTypes');
      if (settingValue !== null) {
        this.showControlOrdersTypes = settingValue;
      } else {
        this.showControlOrdersTypes = defaultValue;
      }
    }
    return this.showControlOrdersTypes;
  }

  get_showProvisioning(defaultValue:boolean):boolean{
    if(typeof this.showProvisioning === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'allowProvisioning');
      if(settingValue !== null){
        this.showProvisioning = settingValue;
      }else{
        this.showProvisioning = defaultValue;
      }
    }
    return this.showProvisioning
  }

  get_showAggregate(defaultValue:boolean):boolean{
    if(typeof this.showAggregate === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowAggregateNonSP');
      let settingValueSP = this.authenticationService.getSettingPortal('Location', 'ShowAggregateSP');
      if(settingValue !== null && !this.authenticationService.session.company.ServiceProvider){
        this.showAggregate = settingValue;
      } else if(settingValueSP !== null && this.authenticationService.session.company.ServiceProvider){
          this.showAggregate = settingValueSP;
      }else{
        this.showAggregate = defaultValue;
      }
    }
    return this.showAggregate
  }

  get_show_hasWms(defaultValue:boolean):boolean{
    if(typeof this.show_hasWms === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'HasWms');
      let settingValueSP = this.authenticationService.getSettingPortal('Location', 'HasWms');
      if(settingValue !== null && !this.authenticationService.session.company.ServiceProvider){
        this.show_hasWms = settingValue;
      } else if(settingValueSP !== null && this.authenticationService.session.company.ServiceProvider){
          this.show_hasWms = settingValueSP;
      }else{
        this.show_hasWms = defaultValue;
      }
    }
    return this.show_hasWms
  }

  get_show_exportDeliveryNotes(defaultValue:boolean):boolean{
    if(typeof this.show_exportDeliveryNote === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ExportDeliveryNote');
      let settingValueSP = this.authenticationService.getSettingPortal('Location', 'ExportDeliveryNote');
      if(settingValue !== null && !this.authenticationService.session.company.ServiceProvider){
        this.show_exportDeliveryNote = settingValue;
      } else if(settingValueSP !== null && this.authenticationService.session.company.ServiceProvider){
          this.show_exportDeliveryNote = settingValueSP;
      }else{
        this.show_exportDeliveryNote = defaultValue;
      }
    }
    return this.show_exportDeliveryNote
  }

  get_show_xDock(defaultValue:boolean):boolean{
    if(typeof this.show_xDock === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'XDock');
      let settingValueSP = this.authenticationService.getSettingPortal('Location', 'XDock');
      if(settingValue !== null && !this.authenticationService.session.company.ServiceProvider){
        this.show_xDock = settingValue;
      } else if(settingValueSP !== null && this.authenticationService.session.company.ServiceProvider){
          this.show_xDock = settingValueSP;
      }else{
        this.show_xDock = defaultValue;
      }
    }
    return this.show_xDock
  }


  get_WmsCode(defaultValue:boolean):boolean{
    if(typeof this.show_WmsCode === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'WmsCode');
      let settingValueSP = this.authenticationService.getSettingPortal('Location', 'WmsCode');
      if(settingValue !== null && !this.authenticationService.session.company.ServiceProvider){
        this.show_WmsCode = settingValue;
      } else if(settingValueSP !== null && this.authenticationService.session.company.ServiceProvider){
          this.show_WmsCode = settingValueSP;
      }else{
        this.show_WmsCode = defaultValue;
      }
    }
    return this.show_WmsCode
  }

  get_showWmsTab(defaultValue:boolean):boolean{
    if(typeof this.show_ShowWmsTab === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowWmsTab');
      let settingValueSP = this.authenticationService.getSettingPortal('Location', 'ShowWmsTab');
      if(settingValue !== null && !this.authenticationService.session.company.ServiceProvider){
        this.show_ShowWmsTab = settingValue;
      } else if(settingValueSP !== null && this.authenticationService.session.company.ServiceProvider){
          this.show_ShowWmsTab = settingValueSP;
      }else{
        this.show_ShowWmsTab = defaultValue;
      }
    }
    return this.show_ShowWmsTab
  }

  get_showShipToInstructions(defaultValue:boolean):boolean{
    if(typeof this.showShipToInstructions === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowShipToInstructions');
      if(settingValue !== null){
        this.showShipToInstructions = settingValue;
      }else{
        this.showShipToInstructions = defaultValue;
      }
    }
    return this.showShipToInstructions;
  }

  get_showUsersTab(defaultValue:boolean):boolean{
    if(typeof this.showUsersTab === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowUsersTab');
      if(settingValue !== null){
        this.showUsersTab = settingValue;
      }else{
        this.showUsersTab = defaultValue;
      }
    }
    return this.showUsersTab;
  }

  get_showOtherData(defaultValue:boolean):boolean{
    if(typeof this.showOtherData === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowOtherData');
      if(settingValue !== null){
        this.showOtherData = settingValue;
      }else{
        this.showOtherData = defaultValue;
      }
    }
    return this.showOtherData;
  }

  get_showClassificationConfigurations(defaultValue:boolean):boolean{
    if(typeof this.showClassificationConfigurations === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowClassificationConfigurations');
      if(settingValue !== null){
        this.showClassificationConfigurations = settingValue;
      }else{
        this.showClassificationConfigurations = defaultValue;
      }
    }
    return this.showClassificationConfigurations;
  }

  get_showReplacementPeriod(defaultValue:boolean):boolean{
    if(typeof this.showReplacementPeriod === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowReplacementPeriod');
      if(settingValue !== null){
        this.showReplacementPeriod = settingValue;
      }else{
        this.showReplacementPeriod = defaultValue;
      }
    }
    return this.showReplacementPeriod;
  }

  get_showColor(defaultValue:boolean):boolean{
    if(typeof this.showColor === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('Location', 'ShowColor');
      if(settingValue !== null){
        this.showColor = settingValue;
      }else{
        this.showColor = defaultValue;
      }
    }
    return this.showColor;
  }

  get_Algorithms():Observable<ReturnStatusHtml>{
    return this.http.get(SERVICE_URL + this._controller + '/GetAllProvisioningAlgorithms').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_Stores():Observable<ReturnStatusHtml>{
    return this.http.get(SERVICE_URL + this._controller + '/GetStores').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public GetTransportLocations(transportID: number , onlyActive: boolean,
    onlyLoadingLocations: boolean = false, onlyDeliveryLocations: boolean = false, locationWithWMS:boolean = false): Observable<Array<any>> {
      if (this._transportLocations === undefined || (this._transportLocations && this._transportLocations['\'' + transportID + '\''] === undefined) 
      || this._onlyActive !== onlyActive) {
    
            this._onlyActive = onlyActive;
            return this.http.get(SERVICE_URL + this._controller + '/GetTransportLocations?transportID=' + transportID + '&onlyActive=' + onlyActive 
            + '&onlyLoadingLocations=' + onlyLoadingLocations + '&onlyDeliveryLocations=' + onlyDeliveryLocations + '&locationWithWMS=' + locationWithWMS).pipe(
              map(response => {
                try {
                  let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                  if (responseReturn.ReturnStatus.Successfull) {
                    if (this._locations === undefined) {
                      this._locations = [];
                    }
                    return this._locations['\'' + transportID + '\''] = responseReturn.ReturnStatus.ReturnObject;
                  } else {
                    this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                  }
                } catch (error) {
                  this._masterService.handleError(error);
                }
              }));
          } else {
            return observableOf(this._locations['\'' + transportID + '\'']);
          }
        }

            //Buscar as transportLocations
    getLocations(companyID:number):Observable<ReturnStatusHtml>{
      return this.http.get(SERVICE_URL + this._controller + '/GetAll?companyID='+ companyID +
      '&type='+LocationType.IsWareHouse + '&onlyActive=true').pipe(map((response:any) => this._masterService.convertToReturnStatusHtml(response)));

    }
     
}
