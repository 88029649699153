import { type } from 'jquery';
import { GenericFieldExtension } from './generic-field-extension';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

// Tipos de valor
export const enum GenericFieldType { STRING = 'string', TEXTAREA = 'textArea', NUMBER = 'number', BOOLEAN = 'boolean', ARRAY = 'array', FILE = 'file', DATE = 'date', CHOOSEPRODUCT = 'chooseProduct' }

// Lista de Tipos de valor
export const GENERIC_FIELD_TYPES = [
  { key: 'string', value: 'TEXT' },
  { key: 'textArea', value: 'TEXT' },
  { key: 'number', value: 'NUMBER' },
  { key: 'boolean', value: 'BOOLEAN' },
  { key: 'array', value: 'LIST' },
  { key: 'file', value: 'FILE' },
  { key: 'date', value: 'DATE' },
  { key: 'chooseProduct', value: 'CHOOSEPRODUCT' },
];

// Tipos de area
export const enum GenericFieldSubcontext { ATTRIBUTE_FIELD = 'AttributeField', ADDITIONAL_FIELD = 'AdditionalField', RULE_FIELD = 'GenericRuleField' }

export class GenericSettings {
  constructor(
    public Types: number[] = [],
    public States: number[] = [],
    public Roles: number[] = [],
    public AllTypes: boolean = false,
    public AllStates: boolean = false,
    public AllRoles: boolean = false,
    public AlwaysTrue: boolean = false,
    // Campos auxiliares
    public TypesList: any[] = [],
    public StatesList: any[] = [],
    public RolesList: any[] = []
  ) { }
}

export class GenericSettingsEntry {
  constructor(
    public Visible: GenericSettings = new GenericSettings(),
    public Editable: GenericSettings = new GenericSettings(),
    public Required: GenericSettings = new GenericSettings(),
    public Approvable: GenericSettings = new GenericSettings()
  ) { }
}

export class GenericFieldConfig {
  // ID único e PK da categoria.
  // Incrementado automáticamente.
  public ID: number;

  // Contexto dos registos de configuração
  public Context: string;

  // Define, quando a true, que o registo está em utilização
  public IsActive: boolean;

  // O tipo de dados do field
  public FieldType: string;

  // ID da empresa a quem pertence o registo (null = todas).
  public OwnerID: number;

  // Referencia para um separdor GenericTabsConfig
  public TabID: number;

  // Código do campo
  public FieldCode: string;

  // Denominação do campo
  public FieldName: string;

  // Breve descrição do campo
  public FieldDescription: string;

  // Linguas a que corresponde a configuração separadas por ;
  public LanguagesCodes: string;

  // Settings deste campo
  public SettingsXml: string;

  // Grupo a que pertence o field, caso exista
  public GroupCode: string;

  // O valor por defeito do field, caso exista
  public DefaultValue: string;

  // Se é para usar nos scripts e em condições de validação de erros
  public InScripting: boolean;

  // Tamanho máximo de carateres caso não seja null
  public FieldLength: number;

  // Settings estruturados obtidos com base no xml
  public Settings: GenericSettingsEntry;

  // Tipo de tab
  public TabCode: string;

  // Denominação da tab
  public TabName: string;

  // SubContext
  public SubContext: string;

  // ID da Dropdown
  public DropDownID: string;

    // ordem dos campos
  public FieldOrder: number;

  // Informação extra dos campos
  public InformationText: string;

  // Imagem com informação extra dos campos
  public InformationImageID: number;

  // Informação extra dos campos
  public FileTypes: string;

   // Validação
   public RegExpr: string;

  constructor(entity: any) {
    if (entity) {
      this.ID = typeof entity.ID !== 'undefined' ? entity.ID : 0;
      this.Context = typeof entity.Context !== 'undefined' ? entity.Context : null;
      this.IsActive = typeof entity.IsActive !== 'undefined' ? entity.IsActive : true;
      this.FieldType = typeof entity.FieldType !== 'undefined' ? entity.FieldType : null;
      this.OwnerID = typeof entity.OwnerID !== 'undefined' ? entity.OwnerID : null;
      this.TabID = typeof entity.TabID !== 'undefined' ? entity.TabID : null;
      this.FieldCode = typeof entity.FieldCode !== 'undefined' ? entity.FieldCode : null;
      this.FieldName = typeof entity.FieldName !== 'undefined' ? entity.FieldName : null;
      this.FieldDescription = typeof entity.FieldDescription !== 'undefined' ? entity.FieldDescription : null;
      this.LanguagesCodes = typeof entity.LanguagesCodes !== 'undefined' ? entity.LanguagesCodes : null;
      this.SettingsXml = typeof entity.SettingsXml !== 'undefined' ? entity.SettingsXml : null;
      this.GroupCode = typeof entity.GroupCode !== 'undefined' ? entity.GroupCode : null;
      this.DefaultValue = typeof entity.DefaultValue !== 'undefined' ? entity.DefaultValue : null;
      this.InScripting = typeof entity.InScripting !== 'undefined' ? entity.InScripting : true;
      this.FieldLength = typeof entity.FieldLength !== 'undefined' ? entity.FieldLength : null;
      this.Settings = typeof entity.Settings !== 'undefined' ? entity.Settings : new GenericSettingsEntry();
      this.TabCode = typeof entity.TabCode !== 'undefined' ? entity.TabCode : null;
      this.TabName = typeof entity.TabName !== 'undefined' ? entity.TabName : null;
      this.SubContext = typeof entity.SubContext !== 'undefined' ? entity.SubContext : null;
      this.DropDownID = typeof entity.DropDownID !== 'undefined' ? entity.DropDownID : null;
      this.FieldOrder = typeof entity.FieldOrder !== 'undefined' ? entity.FieldOrder : null;
      this.InformationText = typeof entity.InformationText !== 'undefined' ? entity.InformationText : null;
      this.InformationImageID = typeof entity.InformationImageID !== 'undefined' ? entity.InformationImageID : null;
      this.FileTypes = typeof entity.FileTypes !== 'undefined' ? entity.FileTypes : null;
      this.RegExpr = typeof entity.RegExpr !== 'undefined' ? entity.RegExpr : null;
    }
  }

  /**
   * Mapear os campos do Form para os campos da Base de Dados
   * @param  {number} referenceID
   * @param  {FormGroup} form
   * @param  {GenericFieldExtension[]} fields
   * @param  {GenericFieldConfig[]} fieldsConfig
   * @param  {{{ListCode:string} groups, {FormControlName:string}}[]} groups
   */
  static mapFormFieldsToBDFields(referenceID: number, form: UntypedFormGroup, fields: GenericFieldExtension[], fieldsConfig: GenericFieldConfig[],
    groups: { ListCode: string, FormControlName: string }[] = []) {
    // Se não tiver valor, inicializa o array
    if (!fields) {
      fields = [];
    }

    if (fieldsConfig !== undefined) {

      // Mapeia todos os campos extendidos do form
      fieldsConfig.filter(r => r.GroupCode === null).forEach(config => {
        if (form.get(config.FieldCode)) {
          this.insertOrUpdateBDFields(referenceID, config.Context, fields, config.FieldCode, form.get(config.FieldCode).value, null);
        }
      });

      // Mapeia as listas agrupadas extendidas do form
      groups.forEach(group => {
        this.insertOrUpdateBDGroups(referenceID, form, fields, fieldsConfig, group.ListCode, group.FormControlName);
      });
    }
  }

  /**
   * Sincronizar os campos do objecto da BD com as alterações
   * @param  {number} referenceID
   * @param  {string} context
   * @param  {GenericFieldExtension[]} fields
   * @param  {string} fieldCode
   * @param  {string} fieldValue?
   * @param  {string} identifier?
   */
  static insertOrUpdateBDFields(referenceID: number, context: string, fields: GenericFieldExtension[], fieldCode: string, fieldValue?: string, identifier?: string) {
    let field: GenericFieldExtension = fields.find(r => r.ListCode === null && r.FieldCode === fieldCode && r.Identifier === identifier);
    if (field) { // Atualiza o valor caso já exista na BD
      field.FieldValue = fieldValue;
    } else { // Senão cria um novo registo na BD
      fields.push(new GenericFieldExtension(0, referenceID, context, fieldValue, null, fieldCode, null, identifier, null));
    }
  }

  /**
   * Sincronizar as listas do objecto da BD com as alterações
   * @param  {number} referenceID
   * @param  {FormGroup} form
   * @param  {GenericFieldExtension[]} fields
   * @param  {GenericFieldConfig[]} fieldsConfig
   * @param  {string} listCode
   * @param  {string} formControlName
   */
  static insertOrUpdateBDGroups(referenceID: number, form: UntypedFormGroup, fields: GenericFieldExtension[], fieldsConfig: GenericFieldConfig[],
    listCode: string, formControlName: string) {
    let configs = fieldsConfig.filter(r => r.GroupCode === listCode);

    // Se não tiver configurações de campos desta lista, ignora
    if (configs.length === 0) {
      return;
    }

    let control = (<UntypedFormArray>form.get(formControlName)).controls;

    let list = JSON.parse(JSON.stringify(fields)) as any[];

    // Elimina na BD todos os valores que já não existem
    list.filter(r => r.ListCode === listCode).forEach(element => {
      if (control.findIndex(r => r.get(element.FieldCode) && (+element.Identifier) < control.length) === -1) {
        fields.splice(fields.findIndex(r => r.ID === element.ID), 1);
      }
    });

    // Cria/atualiza na BD todos os registos que não existem ou são diferentes
    control.forEach((controlElement: UntypedFormGroup, index: number) => {
      configs.forEach(config => {
        if (fields.findIndex(r => r.ListCode === listCode && r.FieldCode === config.FieldCode && r.Identifier === index.toString()) !== -1) {
          fields.find(r => r.ListCode === listCode && r.FieldCode === config.FieldCode && r.Identifier === index.toString()).FieldValue = controlElement.get(config.FieldCode).value;
        } else {
          fields.push(new GenericFieldExtension(0, referenceID, config.Context, controlElement.get(config.FieldCode).value, listCode, config.FieldCode, null, index.toString()));
        }
      });
    });
  }

  getFieldName(): string {
    return this.FieldName ? this.FieldName : '';
  }

  getFieldValue(field: GenericFieldExtension, isCreation: boolean): any {
    switch (this.FieldType) {
      case GenericFieldType.NUMBER:
        return this.getFieldValueNumber(field, isCreation);
      case GenericFieldType.STRING:
      case GenericFieldType.ARRAY:
        return this.getFieldValueString(field, isCreation);
      case GenericFieldType.BOOLEAN:
        return this.getFieldValueBoolean(field, isCreation);
    }
  }

  isFieldVisible(typeID: number, stateID: number, rolesIDs: number[]): boolean {
    return (this.Settings.Visible.AlwaysTrue
      || ((this.Settings.Visible.AllTypes || this.Settings.Visible.Types.findIndex(val => val === typeID) !== -1)
        && (this.Settings.Visible.AllStates || this.Settings.Visible.States.findIndex(val => val === stateID) !== -1)
        && (this.Settings.Visible.AllRoles || this.Settings.Visible.Roles.findIndex(val => rolesIDs.findIndex(role => role === val) !== -1) !== -1)));
  }

  isFieldEditable(typeID: number, stateID: number, rolesIDs: number[]): boolean {
    return (this.Settings.Editable.AlwaysTrue
      || ((this.Settings.Editable.AllTypes || this.Settings.Editable.Types.findIndex(val => val === typeID) !== -1)
        && (this.Settings.Editable.AllStates || this.Settings.Editable.States.findIndex(val => val === stateID) !== -1)
        && (this.Settings.Editable.AllRoles || this.Settings.Editable.Roles.findIndex(val => rolesIDs.findIndex(role => role === val) !== -1) !== -1)));
  }

  isFieldRequired(typeID: number, stateID: number, rolesIDs: number[]): boolean {
    return (this.Settings.Required.AlwaysTrue
      || ((this.Settings.Required.AllTypes || this.Settings.Required.Types.findIndex(val => val === typeID) !== -1)
        && (this.Settings.Required.AllStates || this.Settings.Required.States.findIndex(val => val === stateID) !== -1)
        && (this.Settings.Required.AllRoles || this.Settings.Required.Roles.findIndex(val => rolesIDs.findIndex(role => role === val) !== -1) !== -1)));
  }

  private getFieldValueNumber(field: GenericFieldExtension, isCreation: boolean): number {
    if (field) {
      if (field.FieldValue) {
        if (field.FieldValue.indexOf(',') >= 0) {
          return field.FieldValue.revertDecimal();
        } else {
          return +field.FieldValue;
        }
      } else {
        return null;
      }
    } else {
      return isCreation && this.DefaultValue ? +this.DefaultValue : null;
    }
  }

  private getFieldValueString(field: GenericFieldExtension, isCreation: boolean): string {
    if (field) {
      return field.FieldValue;
    } else {
      return isCreation && this.DefaultValue ? this.DefaultValue : null;
    }
  }

  private getFieldValueBoolean(field: GenericFieldExtension, isCreation: boolean): boolean {
    if (field) {
      return field.FieldValue === 'True' || field.FieldValue === 'true';
    } else {
      return isCreation && (this.DefaultValue === 'True' || this.DefaultValue === 'true');
    }
  }

}
