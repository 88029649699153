import { ErrorTreatmentFunctions, FileTreatmentFunctions } from '../modules/treatments.module';
import { DataTableFunctions } from '../modules/datatables.module';
import { DockModalFunctions } from '../modules/dockmodal.module';
import { DockModalArticlesFunctions } from '../modules/dockmodal_Articles.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileUploadModule } from 'ng2-file-upload';
import { TextMaskModule } from 'angular2-text-mask';
import { NgChartsModule } from 'ng2-charts';

// shared componentes
import { DeleteModalComponent } from '../components/shared/delete-modal/delete-modal.component';
import { DropdownSelectorModalComponent } from '../components/shared/dropdown-selector-modal/dropdown-selector-modal.component';
import { DropdownSelectorModalHtmlComponent } from '../components/shared/dropdown-selector-modal/dropdown-selector-modal-html.component';
import { WarningModalComponent } from '../components/shared/warning-modal/warning-modal.component';
import { WorkflowChangeModalComponent } from '../components/shared/workflow-change-modal/workflow-change-modal.component';
import { WorkflowEmailsModalComponent } from '../components/shared/workflow-emails-modal/workflow-emails-modal.component';
import { ConfirmationModalComponent } from '../components/shared/confirmation-modal/confirmation-modal.component';
import { MessageModalComponent } from '../components/shared/message-modal/message-modal.component';
import { EntityChangesModalComponent } from '../components/shared/entity-changes-modal/entity-changes-modal.component';
import { MapComponent } from '../components/shared/map/map.component';
import { OrderDetailModalComponent } from '../components/orders/orderDetails-modal.component';
import { InvoiceDetailsModalComponent } from '../components/invoices/invoiceDetails-modal.component';
import { OrderDetailBaglassModalComponent } from '../components/orders/ordersBaglass/orderDetails-modal.component';
import { MessageTabComponent } from '../components/shared/tab_msg/msg.component';
import { DigitalArchiveTabComponent } from '../components/shared/tab_digArc/digArc.component';
import { DeliveryNoteDetailModalComponent } from '../components/deliveryNotes/deliveryNoteDetails-modal.component';
import { LMDeliveryNoteDetailModalComponent } from '../components/deliveryNotes/LMDeliveryNotes/deliveryNoteDetails-modal.component';
import { DigitalArchiveTabModalComponent } from '../components/shared/tab_digArc/digArc-modal.component';
import { ChooseModalComponent } from '../components/shared/choose-modal/choose-modal.component';
import { DistributionModalComponent } from '../components/shared/distribution-modal/distribution-modal.component';
import { StateHistoryModalComponent } from '../components/shared/state-history-modal/state-history-modal.component';
import { EmailModalComponent } from '../components/shared/email-modal/email-modal.component';
import { TransactionModalComponent } from '../components/orders/transaction-modal.component';
import { CompanyOrderWorflowTypeModalComponent } from '../components/orders/companyOrderTypeWorkflow-modal.component';
import { InvoicesFilterModalComponent } from '../components/invoices/invoicesFilters-modal.component';
import { NotesModalComponent } from '../components/shared/notes-modal/notes-modal.component';
import { ConfirmationWithNotesModalComponent } from '../components/shared/confirmation-with-notes-modal/confirmation-with-notes-modal.component';
import { CodeListModalComponent } from '../components/shared/code-list-modal/code-list-modal.component';
import { GraphicsModalComponent } from '../components/layout/graphics-modal/graphics-modal.component';
import { MultiserieChartComponent } from '../components/dashboard/charts/multiserie-chart.component';
import { UniserieChartComponent } from '../components/dashboard/charts/uniserie-chart.component';
import { CounterChartComponent } from '../components/dashboard/charts/counter-chart.component';
import { GenericFieldsAreaComponent } from '../components/generic-field-config/generic-fields-area/generic-fields-area.component';
import { GenericFieldComponent } from '../components/generic-field-config/generic-field/generic-field.component';
import { FieldsRulesAreaComponent } from '../components/generic-field-rule/fields-rules-area/fields-rules-area.component';
import { ExpenseModalComponent } from '../components/expense-notes/expense-modal.component';
import { ProcuctChooseModalComponent } from '../components/shared/product-choose-modal/product-choose-modal.component';
// pipes
import { FilterPipe } from '../pipes/filter.pipe';
import { UniquePipe } from '../pipes/unique.pipe';
import { ControlContainsTextPipe } from '../pipes/control-contains-text.pipe';
import { DatePipe } from '../pipes/date.pipe';
import { MdSelectContainsPipe } from '../pipes/md-select-contains.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';

// directives
// import { MatInputCustomDirective } from '../directives/mat-input-custom.directive';
//import { MatSelectTooltipDirective } from '../directives/mat-select-tooltip.directive';
import { MatSelectTooltipDirective } from '../directives/mat-select-tooltip.directive';
import { MatInputTooltipDirective } from '../directives/mat-input-tooltip.directive';
import { DateTimePickerDirective } from '../directives/datepicker.directive';
import { OnlyNumber } from '../directives/onlynumber.directive';
import { FocusDirective } from '../directives/focus.directive';
import { TdTooltipDirective } from '../directives/td-tooltip.directive';
import { UppercaseTextDirective } from '../directives/uppercase-text.directive';


// servicos
import { TranslateValueService } from '../services/translate-value.service';
import { MasterService } from '../services/master.service';

// eventos
import { ChangeJsEventDirective } from '../directives/changeJs.event';
import { InitEventDirective } from '../directives/init.event';
import { EventService } from '../services/event.service';
// import { ReCaptchaDirective } from '../directives/recaptcha.directive';

// Import WYSIWYG text editor
import { CKEditorModule } from 'ng2-ckeditor';
import 'ckeditor';

import { NguCarouselModule } from '@ngu/carousel';
import { HttpClient } from '@angular/common/http';
import { UploadFileModalComponent } from '../components/shared/upload-file-modal/upload-file-modal.component';
import { GenericExportTabComponent } from '../components/shared/tab-generic-export/generic-export-tab.component';
import { ConfirmationWithFieldsModalComponent } from '../components/shared/confirmation-with-fields-modal/confirmation-with-fields-modal.component';
import { DeliveryNoteWorkflowStepModalComponent } from '../components/deliveryNotes/workflow/delivery-note-workflow-step-modal.component';
import { OrdersFilterModalComponent } from '../components/orders/ordersFilters-modal.component';
import { FilterArrayPipe } from '../pipes/filter-array.pipe';
import { EmailsDetailsModalComponent } from '../components/email/emails-details-modal.component';
import { PanelComponent } from '../components/shared/panel/panel.component';
import { ErrorComponent } from '../components/shared/error/error.component';
import { TemplateOrderComponent } from '../components/shared/template-order/template-order.component';
import { NumberFormatDirective } from '../directives/number-format.directive';
import { GenericSelectorComponent } from '../components/shared/generic-selector/generic-selector.component';
import { CostCenterModalComponent } from '../components/shared/cost-center-modal/cost-center-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IbanSelectorComponent } from '../components/shared/iban-selector/iban-selector.component';
import { DetailWorkflowModalcomponent } from '../components/shared/detailworkflow-modal/detailworkflow-modal.component';

// aot requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './resources/', '.json');
}

// Add override CSS styles for inside editable contents area.
CKEDITOR.addCss('p { margin: 0; }');
// Definir o caminho do ficheiro de configurações do CKEditor
// CKEDITOR.config.customConfig = '../../assets/lib/ckeditor/config.js';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
    FileUploadModule,
    CKEditorModule,
    NguCarouselModule,
    TextMaskModule,
    NgChartsModule,
  ],
  declarations: [
    // MatInputCustomDirective,
    MatSelectTooltipDirective,
    MatInputTooltipDirective,
    DateTimePickerDirective,
    TdTooltipDirective,
    FilterPipe,
    FilterArrayPipe,
    UniquePipe,
    ControlContainsTextPipe,
    DeleteModalComponent,
    DropdownSelectorModalComponent,
    DropdownSelectorModalHtmlComponent,
    WorkflowChangeModalComponent,
    WorkflowEmailsModalComponent,
    ConfirmationModalComponent,
    DetailWorkflowModalcomponent,
    MessageModalComponent,
    EntityChangesModalComponent,
    OnlyNumber,
    DatePipe,
    MdSelectContainsPipe,
    MapComponent,
    WarningModalComponent,
    OrderDetailModalComponent,
    InvoiceDetailsModalComponent,
    ExpenseModalComponent,
    OrderDetailBaglassModalComponent,
    ChangeJsEventDirective,
    MessageTabComponent,
    DigitalArchiveTabComponent,
    DeliveryNoteDetailModalComponent,
    LMDeliveryNoteDetailModalComponent,
    DigitalArchiveTabModalComponent,
    ChooseModalComponent,
    DistributionModalComponent,
    StateHistoryModalComponent,
    FocusDirective,
    InitEventDirective,
    EmailModalComponent,
    SafeHtmlPipe,
    TransactionModalComponent,
    EmailsDetailsModalComponent,
    CompanyOrderWorflowTypeModalComponent,
    InvoicesFilterModalComponent,
    // ReCaptchaDirective,
    NotesModalComponent,
    ConfirmationWithNotesModalComponent,
    ConfirmationWithFieldsModalComponent,
    CodeListModalComponent,
    GraphicsModalComponent,
    MultiserieChartComponent,
    UniserieChartComponent,
    CounterChartComponent,
    GenericFieldsAreaComponent,
    GenericFieldComponent,
    FieldsRulesAreaComponent,
    UppercaseTextDirective,
    UploadFileModalComponent,
    GenericExportTabComponent,
    DeliveryNoteWorkflowStepModalComponent,
    OrdersFilterModalComponent,
    PanelComponent,
    ErrorComponent,
    ProcuctChooseModalComponent,
    TemplateOrderComponent,
    NumberFormatDirective,
    GenericSelectorComponent,
    CostCenterModalComponent,
    IbanSelectorComponent,
  ],
  exports: [
    MaterialModule,
    FileUploadModule,
    TranslateModule,
    // MatInputCustomDirective,
    MatSelectTooltipDirective,
    MatInputTooltipDirective,
    DateTimePickerDirective,
    TdTooltipDirective,
    FilterPipe,
    FilterArrayPipe,
    UniquePipe,
    ControlContainsTextPipe,
    DeleteModalComponent,
    DropdownSelectorModalComponent,
    DropdownSelectorModalHtmlComponent,
    WorkflowChangeModalComponent,
    WorkflowEmailsModalComponent,
    ConfirmationModalComponent,
    MessageModalComponent,
    EntityChangesModalComponent,
    OnlyNumber,
    DatePipe,
    MdSelectContainsPipe,
    MapComponent,
    WarningModalComponent,
    OrderDetailModalComponent,
    InvoiceDetailsModalComponent,
    OrderDetailBaglassModalComponent,
    ChangeJsEventDirective,
    MessageTabComponent,
    DigitalArchiveTabComponent,
    DeliveryNoteDetailModalComponent,
    LMDeliveryNoteDetailModalComponent,
    DigitalArchiveTabModalComponent,
    ChooseModalComponent,
    DistributionModalComponent,
    StateHistoryModalComponent,
    FocusDirective,
    EmailModalComponent,
    CKEditorModule,
    NguCarouselModule,
    TextMaskModule,
    SafeHtmlPipe,
    InitEventDirective,
    InvoicesFilterModalComponent,
    // ReCaptchaDirective,
    NotesModalComponent,
    ConfirmationWithNotesModalComponent,
    ConfirmationWithFieldsModalComponent,
    CodeListModalComponent,
    GraphicsModalComponent,
    MultiserieChartComponent,
    UniserieChartComponent,
    CounterChartComponent,
    GenericFieldsAreaComponent,
    GenericFieldComponent,
    FieldsRulesAreaComponent,
    UppercaseTextDirective,
    UploadFileModalComponent,
    GenericExportTabComponent,
    OrdersFilterModalComponent,
    PanelComponent,
    ErrorComponent,
    ProcuctChooseModalComponent,
    TemplateOrderComponent,
    NumberFormatDirective,
    GenericSelectorComponent,
    CostCenterModalComponent,
    IbanSelectorComponent,
    TemplateOrderComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<NgModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
        TranslateValueService,
        MasterService,
        DockModalFunctions,
        DockModalArticlesFunctions,
        ErrorTreatmentFunctions,
        DataTableFunctions,
        FileTreatmentFunctions,
        EventService,
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => DropdownSelectorModalHtmlComponent),
          multi: true
        }
      ]
    };
  }
}
