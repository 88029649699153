import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { AuthenticationService } from './authentication.service';
import { Order, OrderPod, LogisticOrderType } from '../models/order';
import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class OrderTempService {

    dataIniSale: string;
    dataFimSale: string;
    // Filtros
    datatableParametersIntegration: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
    datatableParametersOrderTempDetails: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);

    private _controller: string = 'OrderTemp';
    private showDigitalFileTab_S: boolean;
    private showMessagesTab_S: boolean;

    constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService) {
    }

    delete(id: number): Observable<ReturnStatusHtml> {
        return this.http.delete(SERVICE_URL + this._controller + '/Delete?orderTempId=' + id).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    downloadExcelOrdersTempList(params: any, firstdate: string, lastdate: string): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelOrdersTempList',
            {
                parameters: params,
                firstdate: firstdate,
                lastdate: lastdate
            });
    }

    getOrderTempDetails(orderTempId: number, details: boolean): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/get?orderTempId=' + orderTempId + '&details=' + details)
            .pipe((map((response: any) => this._masterService.convertToReturnStatusHtml(response))))
    }

    downloadExcelOrderTemp(orderTempId: number): Observable<any> {
        return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelOrderTemp', {
            orderTempId
        })
    }

    get_showDigitalFileTab_S(defaultValue: boolean): boolean {
        if (typeof this.showDigitalFileTab_S === 'undefined') { // verificar se ainda nao tem valor
            let settingValue = this.authenticationService.getSettingPortal('OrderTemp', 'ShowDigitalFileTab');
            if (settingValue != null) {
                this.showDigitalFileTab_S = settingValue;
            } else {
                this.showDigitalFileTab_S = defaultValue;
            }
        }
        return this.showDigitalFileTab_S;
    }

    get_showMessagesTab_S(defaultValue: boolean): boolean {
        if (typeof this.showMessagesTab_S === 'undefined') { // verificar se ainda nao tem valor
            let settingValue = this.authenticationService.getSettingPortal('OrderTemp', 'ShowMessagesTab');
            if (settingValue != null) {
                this.showMessagesTab_S = settingValue;
            } else {
                this.showMessagesTab_S = defaultValue;
            }
        }
        return this.showMessagesTab_S;
    }

    update(model): Observable<ReturnStatusHtml> {
        let formData: FormData = new FormData();
        formData.append('entity', JSON.stringify(model));

        return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }
}
