
import { throwError as observableThrowError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from './master.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { ReturnStatusHtml } from '../models/returnStatus';

declare var Functions: any;

@Injectable()
export class HttpClientCustom {
  options: any;
  optionsFile: any;

  constructor(private http: HttpClient, private handler: HttpBackend, private router: Router, private _masterService: MasterService) {
    let headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': '-1', 'Token': '' });
    this.options = { withCredentials: true, headers: headers };
    this.optionsFile = { withCredentials: true, headers: headers, responseType: 'blob' };
  }

  setToken(token: string) {
    let headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': '-1', 'Token': token });
    this.options = { withCredentials: true, headers: headers };
    this.optionsFile = { withCredentials: true, headers: headers, responseType: 'blob' };
  }

  get(url: string) {
    return this.http.get<ReturnStatusHtml>(url, this.options).pipe(tap(
      data => this.treatResponse(data),
      error => this.handleError(error)));
  }

  // Não passa no Interceptor
  getBackend(url: string) {
    let httpBackend: HttpClient = new HttpClient(this.handler);

    return httpBackend.get<ReturnStatusHtml>(url, this.options).pipe(tap(
      data => this.treatResponse(data),
      error => this.handleError(error)));
  }

  post(url: string, data: any) {
    return this.http.post<ReturnStatusHtml>(url, data, this.options).pipe(tap(
      data2 => this.treatResponse(data2),
      error => this.handleError(error)));
  }

  delete(url: string) {
    return this.http.delete<ReturnStatusHtml>(url, this.options).pipe(tap(
      data => this.treatResponse(data),
      error => this.handleError(error)));
  }

  put(url: string, data: any) {
    return this.http.put<ReturnStatusHtml>(url, data, this.options).pipe(tap(
      data2 => this.treatResponse(data2),
      error => this.handleError(error)));
  }

  request(method: string,url: string, data: any) {
    if(data)
      this.options.body = data;
      
    return this.http.request<ReturnStatusHtml>(method,url,this.options).pipe(tap(
      data2 => this.treatResponse(data2),
      error => this.handleError(error)));
  }

  getFile(url: string, hasTreatment: boolean = true) {
    return this.http.get(url, this.optionsFile).pipe(
      catchError(error => {
        if (error.statusText) {
          if (hasTreatment) {
            Functions.gritter(error.statusText, 'danger');
          }
          if (error.status === 401) { // perdeu a sessao, vai esperar um pouco e fazer reload
            setTimeout(() => {
              location.reload();
            }, 500);
          }
        }
        if (this._masterService.enviromenDept) {
          console.error('An error occurred', error); // for demo purposes only
          return observableThrowError(error.message || 'Server error');
        }
      }));
  }

  postFile(url: string, data: any) {
    return this.http.post(url, data, this.optionsFile).pipe(
      catchError(error => this.handleError(error)));
  }

  private treatResponse(data: any) {
    if (data.IsCompanyChanged) {
      this.router.navigate([this.router.url]);
    }
  }

  private handleError(error: HttpErrorResponse) {
    // Executa quando não consegue contactar o servidor
    // Status code 0 indicates a CORS failure.
    if (error.status === 0) {
      // document.location.href = document.location.origin + '/login';
      this.router.navigate(['/login']);
    }

    if (error.statusText) {
      Functions.gritter(error.status !== 0 ? error.statusText : 'Server Not Found', 'danger');
    }
    if (this._masterService.enviromenDept) {
      console.error('An error occurred', error); // for demo purposes only
      return observableThrowError(error.message || 'Server error');
    }
  }
}
