import { FormArray, UntypedFormControl } from '@angular/forms';
// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateValueService } from '../../services/translate-value.service';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { QUANTITY_DECIMAL } from '../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { ModelValidators } from '../../models/validators/validators';
import { OrderDetail } from '../../models/order';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { ChooseModalComponent } from '../shared/choose-modal/choose-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryNote } from 'src/app/models/deliveryNote';
import { Subject } from 'rxjs';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';


declare var Functions: any;

@Component({
  templateUrl: './receptions-modal.html'
})
export class ReceptionsModalComponent implements OnInit, OnDestroy {

  /* ***************************************************
   * *********** MODAL --> Confirmar receção ***********
   * ***************************************************
   * */
  destroy$: Subject<boolean> = new Subject<boolean>();
  details: Array<any> = new Array<any>();
  receptionStates: Array<number> = new Array<number>();
  // 3 avaliações p/ Otis
  deliveryDateEvalList: Array<any> = new Array<any>();
  receivedQuantityEvalList: Array<any> = new Array<any>();
  productEvalList: Array<any> = new Array<any>();
  // avaliação por defeito
  evaluationEvalList: Array<number> = new Array<number>();
  // array validações num receção
  receptionNumberValidators: Array<any> = new Array<any>();
  returnReasonsList: any[] = [];

  form: UntypedFormGroup;

  open: boolean = true;
  noError: boolean = true;
  submitted: boolean = false;

  areSelectsRequired: boolean; // se os 3 selects da modal sao obrigatorios
  isDelegation: boolean; // se os 3 selects da modal sao obrigatorios
  // isReceptionNumberInt: boolean; // para saber se nº de receção é numero ou nao (nao está a ser usada para ja)

  earliestAcceptableDate: Date;
  currentDate = new Date(); // data de hoje
  currentDateMinus7 = null; // data atual - 7
  currentDateMinus14 = null; // data atual - 14
  currentDatePlus7 = null; // data atual + 7
  currentDatePlus14 = null; // data atual + 14
  // detalhe tem prioridade
  detailCurrentDateMinus7 = null; // data atual - 7
  detailCurrentDateMinus14 = null; // data atual - 14
  detailCurrentDatePlus7 = null; // data atual + 7
  detailCurrentDatePlus14 = null; // data atual + 14
  documentNumber: string;
  documentType: string;
  deliveryLocationIdentifier: string;
  documentState: number;
  startBy: any;
  charactersToValidate: number = 0;
  lengthOfControl: number;

  isReceptionHasExtendedEvals: boolean = false;
  otisReceptionNumberValidation: boolean = false;
  showReturnedQuantity: boolean = false;
  showReturnReasons: boolean = false;
  hideReceptionNumber: boolean = false;
  showCloseLine: boolean = false;
  mandatoryNotesOnCloseDetail: boolean = false;

  receptionEvaluationDefaultValue: string = null;
  showReceptionReceivedQuantityDefaultValue: boolean = false;
  receptionReceivedQuantityDefaultValue: number = 0;

  //Setting para alterar a Quantidade a Validar/Mostrar para seguir uma Lógica de Hierarquia:
  //(Qtd. Expedida [DespatchedQuantity] -> Qtd. a Entregar [RevisedQuantity] -> Qtd. Encomendada [OrderedQuantity])
  useHierarchicalOrderDetailsQuantities: boolean = false;

  showDispatchedQuantity: boolean = false; //Adiciona a Coluna "DespatchedQuantity" ao Popup Confirmação Receção
  showRevisedQuantity: boolean = false; //Adiciona a Coluna "RevisedQuantity" ao Popup Confirmação Receção
  hideOrderedQuantity: boolean = false; //Esconde a Coluna "OrderedQuantity" no Popup Confirmação Receção
  allowSelectDeliveryNoteOnReception: boolean = false; //Setting que permite selecionar o Nº das Guias relacionadas com a Encomenda na receção da mesma

  associatedDeliveryNotes: Array<DeliveryNote> = []; //Armazena as Guias associadas à Encomenda selecionada

  validationMessages = {};
  validationMessagesDetails = {};
  guideNotMandatory: boolean = false;
  idsToClose: Array<number> = [];
  closeAll: boolean = false;
  checkboxValues: any[];
  allowQuantitiesSuperiorOrders: boolean;
  showUOMCodeOnReception: boolean = false;

  constructor(public dialogRef: MatDialogRef<ReceptionsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private formBuilder: UntypedFormBuilder,
  private dialog: MatDialog, private translateService: TranslateService, private translateValueService: TranslateValueService) {
    this.isReceptionHasExtendedEvals = data.isReceptionHasExtendedEvals;

    if (this.isReceptionHasExtendedEvals) {
      this.deliveryDateEvalList = data.deliveryDateEvalList;
      this.receivedQuantityEvalList = data.receivedQuantityEvalList;
      this.productEvalList = data.productEvalList;
    } else {
      this.evaluationEvalList = data.evaluationEvalList;
    }

    this.returnReasonsList = data.returnReasonsList || [];
    this.details = data.modelDetails;
    this.earliestAcceptableDate = data.earliestAcceptableDate;
    this.currentDateMinus7 = data.currentDateMinus7;
    this.currentDateMinus14 = data.currentDateMinus14;
    this.currentDatePlus7 = data.currentDatePlus7;
    this.currentDatePlus14 = data.currentDatePlus14;
    this.documentNumber = data.documentNumber;
    this.documentState = data.documentState;
    this.receptionStates = data.receptionStates;
    this.isDelegation = data.isDelegation;
    this.documentType = data.documentType;
    this.deliveryLocationIdentifier = data.deliveryLocationIdentifier;

    this.otisReceptionNumberValidation = data.otisReceptionNumberValidation;
    this.showReturnedQuantity = data.showReturnedQuantity;
    this.showReturnReasons = data.showReturnReasons;
    this.hideReceptionNumber = data.hideReceptionNumber;
    this.receptionEvaluationDefaultValue = data.receptionEvaluationDefaultValue;
    this.showReceptionReceivedQuantityDefaultValue = data.showReceptionReceivedQuantityDefaultValue;
    this.showCloseLine = data.showCloseLine;
    this.mandatoryNotesOnCloseDetail = data.mandatoryNotesOnCloseDetail
    //JJ 06/07/2022 -> Responsável pela Hierarquia a usar nas Quantidades
    this.useHierarchicalOrderDetailsQuantities = data.useHierarchicalOrderDetailsQuantities

    this.allowSelectDeliveryNoteOnReception = data.allowSelectDeliveryNoteOnReception;
    if(data.associatedDeliveryNotes && data.allowSelectDeliveryNoteOnReception){
      this.associatedDeliveryNotes = data.associatedDeliveryNotes;
    }

    this.showRevisedQuantity = data.showRevisedQuantity;
    this.showDispatchedQuantity = data.showDispatchedQuantity;

    this.hideOrderedQuantity = data.hideOrderedQuantity;

    this.guideNotMandatory = data.guideNotMandatory;

    this.allowQuantitiesSuperiorOrders = data.allowQuantitiesSuperiorOrders;

    this.showUOMCodeOnReception = data.showUOMCodeOnReception;
    this.validationMessages = {
      'ReceptionNumber': {
        'lengthEquals': 'LENGTH_EQUALS',
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR',
        'charactersPosition': 'CHARACTRS_BEGIN'
      },
      'GuideNumber': {
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      }
    }

    this.validationMessagesDetails = {
      'EvalDateID': {
        'required': 'FIELD_REQUIRED_'
      },
      'EvalQtdID': {
        'required': 'FIELD_REQUIRED_'
      },
      'EvalProdID': {
        'required': 'FIELD_REQUIRED_'
      },
      'ReceivedQuantity': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Evaluation': {
        'required': 'FIELD_REQUIRED_'
      },
      'ReturnedQuantity': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
    }
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.otisReceptionNumberValidation) {

      this.receptionNumberValidators.push(ModelValidators.numberVal({ min: 0, decimalPlaces: 0 }));

      // se não for delegação aplicar regras de validação (ignorar para delegações porque o campo fica bloqueado)
      if (!this.isDelegation) {
        if (this.form.get('ReceptionNumber')) {
          /**
          * Estas validações são feitas aqui e nao no inicio porque é necessário ja existir o form
          If Requisição = ‘OrderMatStock’ then  Obrigar a começar por 7  e comprimento total igual a 6 dígitos (7xxxxx)
          If Requisição <>’ OrderFabUnComp’ e Local de entrega = ‘Sede’ then  Obrigar a começar por 6 e comprimento igual a 5 dígitos (6xxxx)
          If Requisição = ’ OrderFabUnComp’ then  Obrigar a começar por 9 e comprimento igual a 4 dígitos (9xxx)
          */
          if (this.documentType === 'OrderMatStock') {
            this.receptionNumberValidators.push(Validators.required);
            this.receptionNumberValidators.push(ModelValidators.validCharacters({ startBy: 7, charactersToValidate: 0 }));
            this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 6 }));
            this.form.controls['ReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
            this.form.controls['ReceptionNumber'].updateValueAndValidity();

          } else if (this.documentType !== 'OrderFabUnComp') {
            this.receptionNumberValidators.push(Validators.required);
            this.receptionNumberValidators.push(ModelValidators.validCharacters({ startBy: 6, charactersToValidate: 0 }));
            this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 5 }));
            this.form.controls['ReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
            this.form.controls['ReceptionNumber'].updateValueAndValidity();

          } else if (this.documentType === 'OrderFabUnComp') {
            this.receptionNumberValidators.push(Validators.required);
            this.receptionNumberValidators.push(ModelValidators.validCharacters({ startBy: 9, charactersToValidate: 0 }));
            this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 4 }));
            this.form.controls['ReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
            this.form.controls['ReceptionNumber'].updateValueAndValidity();
          }
        }
      }
    } else {
      // GM 2018-10-11 o Numero da receção opcional (verificar necessidade de colocar como obr por setting)
      // this.receptionNumberValidators.push(Validators.required);
      this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 20 }));
      this.form.controls['ReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
      this.form.controls['ReceptionNumber'].updateValueAndValidity();
    }

    //AM 2022-12-09 verificar se tem apenas uma guia, se sim insere essa guia automaticamente
    if(this.allowSelectDeliveryNoteOnReception && this.associatedDeliveryNotes.length==1)
    {
        this.form.get('GuideNumber').setValue(this.associatedDeliveryNotes.find(obj => obj.ID == this.associatedDeliveryNotes[0].ID).DeliveryNoteNumber);
    }
  }

  buildForm(): void {
    // se for delegação ignorar as validações ao nº da receção
    // let isReceptionNumberRequired = this.isDelegation === false ? Validators.required : null;

    let lengthOfControl = this.validationLengthOfControl() !== null ? ModelValidators.lengthVal({ min: this.validationLengthOfControl(), max: this.validationLengthOfControl() }) : null;
    this.receptionNumberValidators.push(lengthOfControl);

    this.form = this.formBuilder.group({
      'ReceptionNumber': [{ value: '', disabled: this.isDelegation }, Validators.compose(this.receptionNumberValidators)],
      // 'GuideNumber': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 0 }), Validators.required, Validators.required, ModelValidators.lengthVal({ max: 20 })])],
      // alteração a pedido da OTIS : 17-05-2018
      'GuideNumber': ['', Validators.compose([!this.guideNotMandatory ? Validators.required : Validators.nullValidator, ModelValidators.lengthVal({ max: 40 })])], //JJ 20/09/2022 -> Aumentei o tamanho de 20, não detetei problemas ao inserir na BD
      'DetailsList': this.formBuilder.array([]),
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.addDetail();

    this.onValueChanged(); // para apresentar mensagens de validação


  }

  /* tslint:disable:member-ordering */
  formErrors: Array<string> = new Array<string>();
  formErrorsParam: Array<string> = new Array<string>();
  
  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        // if ((this.submitted && (control && !control.valid && control.enabled)) ||
        // (!this.submitted && (control && control.dirty && !control.valid))
        // ) {

        if (control && !control.valid && control.enabled && control.dirty) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  // #region Details
  initDetail(detail: OrderDetail) {

    let areSelectsRequired = (this.areSelectsRequired === true && this.isReceptionHasExtendedEvals) ? Validators.required : null;
    let isSelectAccordingRequired = (!this.isReceptionHasExtendedEvals) ? Validators.required : null;
    let satisfiedQuantity = detail.ReceivedQuantity ? detail.ReceivedQuantity : 0;

    let returnedQuantityPreviously = detail.ReturnedQuantity ? detail.ReturnedQuantity : 0;

    let orderedQuantity: number = detail.OrderedQuantity;
    if(this.useHierarchicalOrderDetailsQuantities){
      if(detail.DespatchedQuantity || detail.DespatchedQuantity == 0){
        orderedQuantity = detail.DespatchedQuantity - detail.ReturnedQuantity;
      }else if (detail.RevisedQuantity || detail.RevisedQuantity == 0){
        orderedQuantity = detail.RevisedQuantity;
      }
    }

    let quantityToReceive: number = orderedQuantity - satisfiedQuantity;

    if (this.isReceptionHasExtendedEvals) {
      // se a linha já foi satisfeita na totalidade as avaliação serão 5
      if (quantityToReceive === 0) {
        detail.DeliveryDateEval = '5';
        detail.ReceivedQuantityEval = '5';
        detail.ProductEval = '5';
      } else { // calcular as avaliações do prazo de entrega e da quantidade
        this.calculateDeliveryDateAndReceivedQuantity(detail, quantityToReceive);
      }
    }

    //se o setting para mostrar um valor por defeito na quantidade recebida estiver a true
    if (this.showReceptionReceivedQuantityDefaultValue) {
      //carregarmos por defeito a quantidade encomendada - quantidade recebida ant.
      this.receptionReceivedQuantityDefaultValue = quantityToReceive < 0 ? 0 : quantityToReceive;
    }


    return this.formBuilder.group({
      'ID': [detail.ID],
      'Close':[{ value: false, disabled: true }],
      'LineNumber': [{ value: detail.LineNumber, disabled: true }],
      'Code': [{
        value: detail.BuyerProductCode ? detail.BuyerProductCode :
          detail.StandardProductCode ? detail.StandardProductCode :
            detail.SupplierProductCode ? detail.SupplierProductCode : '', disabled: true
      }],
      'Description': [{ value: detail.ProductDescription, disabled: true }],
      'OrderedQuantity': [{ value: detail.OrderedQuantity, disabled: true }], // quantidade encomendada
      'OrderedQuantityUOMCode': [{value: detail.OrderedQuantityUOMCode, disabled: true}],
      'ReceivedQuantityPreviously': [{ value: satisfiedQuantity, disabled: true }], // quantidade recebida anteriormente
      'ReceivedQuantity': [this.showReceptionReceivedQuantityDefaultValue ? this.receptionReceivedQuantityDefaultValue : 0, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: QUANTITY_DECIMAL })])], // quantidade recebida
      'ReturnReasonID': [{ value: null, disabled: true }],
      'EvalDateID': [detail.DeliveryDateEval, areSelectsRequired],
      'EvalQtdID': [detail.ReceivedQuantityEval, areSelectsRequired],
      'EvalProdID': [detail.ProductEval, areSelectsRequired],
      'Observations': [''],
      'Evaluation': [detail.Evaluation ? detail.Evaluation : this.receptionEvaluationDefaultValue ? this.receptionEvaluationDefaultValue : null, isSelectAccordingRequired],
      'ReturnedQuantityPreviously': [{ value: returnedQuantityPreviously, disabled: true }], // quantidade devolvida anteriormente
      'ReturnedQuantity': [0, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: QUANTITY_DECIMAL })])], // quantidade devolvida
    });
  }

  addDetail() {
    // inserir os dados que ja estao gravados
    if (this.details != null) {
      for (let detail of this.details) {
        const control = <UntypedFormArray>this.form.controls['DetailsList'];
        const ctrl = this.initDetail(detail);

        //Adiciona o FormControl 'RevisedQuantity' consoante o valor no Setting 'showRevisedQuantity'
        if(this.showRevisedQuantity){
          ctrl.addControl("RevisedQuantity", new UntypedFormControl({value: this.showReceptionReceivedQuantityDefaultValue ? this.receptionReceivedQuantityDefaultValue : 0, disabled: true}));
        }

        //Adiciona o FormControl 'DespatchedQuantity' consoante o valor no Setting 'showDespatchedQuantity'
        if(this.showDispatchedQuantity){
          ctrl.addControl("DespatchedQuantity", new UntypedFormControl({value: detail.DespatchedQuantity, disabled: true}));
        }

        control.push(ctrl);
        ctrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          this.onValueChangedDetails(value);
        });
      }
    }
  }

  /* tslint:disable:member-ordering */
  formErrorsDetails: Array<string> = new Array<string>();
  formErrorsDetailsParam: Array<string> = new Array<string>();
  
  /* tslint:enable:member-ordering */

  onValueChangedDetails(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    // clear previous error message (if any)
    this.formErrorsDetails = new Array<string>();
    this.formErrorsDetailsParam = new Array<string>();
    for (const field in this.validationMessagesDetails) {
      if (this.validationMessagesDetails.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('DetailsList');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesDetails[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsDetails.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsDetailsParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsDetailsParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // endregion Details

  // guardar
  save() {
    /*
    *Esta modal tem o atributo 'cdkFocusInitial' nos detalhes para não abrir logo a apresentar erros
    *pq o 1º elemento selecionavel tem logo validações.
    *Problema: quando abria e fazia imedatamente "guardar" nao assumia os erros
    *Resolução: duas linhas que estão a seguir para o obrigar a lá ir
    * */


    this.form.controls['ReceptionNumber'].markAsDirty();
    this.form.controls['GuideNumber'].markAsDirty();
    //this.form.controls['LinesToClose'].setValue(this.idsToClose.map(r => ({ ID: r})));

    this.submitted = true;
    this.noError = true;

    this.onValueChanged();
    this.onValueChangedDetails();

    let validDetails: boolean = true;
    let validDetailsEvals: boolean = true;
    let validDetailsReceivedQuantity: boolean = true;
    let validDetailsNotes: boolean = true;
    let validDetailsNotesReturnedQuantity: boolean = true;
    let validDetailsCloseNotes: boolean = true;
    let validReturnReasons: boolean = true;
    let msg: string = '';

    if (this.noError) {
      const control = <UntypedFormArray>this.form.controls['DetailsList'];

      // o documento está num estado passível de confirmação de receção ?
      if (this.receptionStates.findIndex((x: any) => (+x) === (+this.documentState)) === -1) {
        msg += this.translateValueService.get('INVALID_DOCUMENT_STATE') + '<br/>';
      }

      control.controls.forEach((detail: any, index: number) => {
        // a quantidade recebida + quantidade recebida anteriormente não pode ser superior à quantidade encomendada
        // Contar com a quantidade devolvida

        let orderedQuantity: number = +detail.get('OrderedQuantity').value;

        if(this.useHierarchicalOrderDetailsQuantities){
          if(detail.get('DespatchedQuantity') && +detail.get('DespatchedQuantity').value){
            orderedQuantity = +detail.get('DespatchedQuantity').value;
          }else if (this.showRevisedQuantity && +detail.get('RevisedQuantity').value){
            orderedQuantity = +detail.get('RevisedQuantity').value;
          }
        }

        if (!this.allowQuantitiesSuperiorOrders && (orderedQuantity) <
          (+detail.get('ReceivedQuantityPreviously').value) + (+detail.get('ReceivedQuantity').value )
        ) {
          validDetails = false;
        }

        if (this.isReceptionHasExtendedEvals) {
          // As avals são obrigatórias
          // 2017-11-21 Validar a aval da data apenas se a quantidade recebida seja > 0
          if (((+detail.get('ReceivedQuantity').value) > 0 && !detail.get('EvalDateID').value) || !detail.get('EvalQtdID').value || !detail.get('EvalProdID').value) {
            validDetailsEvals = false;
          }
          // caso o produto não esteja conforme, não podem receber
          if ((+detail.get('ReceivedQuantity').value) > 0 && (!detail.get('EvalProdID').value || detail.get('EvalProdID').value === '1' || detail.get('EvalProdID').value === '2')) {
            validDetailsReceivedQuantity = false;
          }

          if ((detail.get('Observations').value === '' || !detail.get('Observations').value) && ((!detail.get('EvalProdID').value || detail.get('EvalProdID').value === '1' || detail.get('EvalProdID').value === '2'))) {
            validDetailsNotes = false;
          }
          else if (this.mandatoryNotesOnCloseDetail && (detail.get('Observations').value === '' || !detail.get('Observations').value) && ((!detail.get('Close').value || detail.get('Close').value === true))) {
            validDetailsCloseNotes = false;
          }
        } else {
          if ((detail.get('Observations').value === '' || !detail.get('Observations').value) && ((detail.get('Evaluation').value === 'NC'))) {
            validDetailsNotes = false;
          } else if ((detail.get('Observations').value === '' || !detail.get('Observations').value) && ((+detail.get('ReturnedQuantity').value > 0)) && !this.showReturnReasons) {
            validDetailsNotesReturnedQuantity = false;
          } else if (!(detail.get('ReturnReasonID').value > 0) && ((+detail.get('ReturnedQuantity').value > 0)) && this.showReturnReasons) {
            validReturnReasons = false;
          } else if (((detail.get('Close').value === true)) &&  this.mandatoryNotesOnCloseDetail && (detail.get('Observations').value === '' || !detail.get('Observations').value)) {
            validDetailsCloseNotes = false;
          }
        }
      });

      if (!validDetails) {
        msg += this.translateValueService.get('RECEIVED_QUANTITIES_CAN_NOT_EXCEED_THE_QUANTITIES_ORDERED') + '<br/>';
      }
      if (!validDetailsEvals) {
        msg += this.translateValueService.get('ALL_RECEPTION_EVALUATION_FIELDS_ARE_MANDATORY') + '<br/>';
      }
      if (!validDetailsReceivedQuantity) {
        msg += this.translateValueService.get('IF_THE_CONFORMITY_EVALUATION_OF_THE_PRODUCT_IS_<=_2_THE_QUANTITY_RECEIVED_MUST_BE_0') + '<br/>';
      }

      if (!validReturnReasons){
        msg += this.translateValueService.get('IF_RETURNED_QUANTITY_FILLED_YOU_MUST_FILL_THE_RETURNREASONS') + '<br/>';
      }

      if (!validDetailsNotes) {
        //Se returnreasons for true, penso que não vale a pena ter observações como obrigatórias
        if (this.isReceptionHasExtendedEvals) {
          msg += this.translateValueService.get('IF_THE_PRODUCT_CONFORMITY_EVALUATION_IS_<=_2_YOU_HAVE_TO_FILL_THE_OBSERVATIONS') + '<br/>';
        } else {
          msg += this.translateValueService.get('IF_THE_EVALUATION_IS_NONCONFORMING_YOU_MUST_FILL_THE_OBSERVATIONS');
        }
      } else if (!validDetailsNotesReturnedQuantity) {
        msg += this.translateValueService.get('IF_RETURNED_QUANTITY_FILLED_YOU_MUST_FILL_THE_OBSERVATIONS');
      } else if(!validDetailsCloseNotes){
        msg += this.translateValueService.get('MANDATORY_NOTES_ON_CLOSE') + '<br/>';
      }

      if (msg.length > 0) {
        Functions.gritter(msg, 'danger');
      } else {
        if (this.closeAll) {
          this.translateService.get('TOTAL_RECEPTION_CONFIRMATION').subscribe(translation => {
            let dialogRef = this.dialog.open(ConfirmationModalComponent, {
              data: {
                text: translation
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.dialogRef.close(this.form.getRawValue());
              }
            });
          });
       } else {
        this.dialogRef.close(this.form.getRawValue());
       }
      }
    }
    // this.submitted = false;
  }

  // validação nº caracteres
  validationLengthOfControl(): any {
    if (this.otisReceptionNumberValidation) {
      // se não for delegação
      if (!this.isDelegation) {
        // Enc. Material Stock
        if (this.documentType === 'OrderMatStock') {
          return this.lengthOfControl = 6;
          // Diferente de Enc. Fabrica Un. Completas
        } else if (this.documentType !== 'OrderFabUnComp') {
          return this.lengthOfControl = 5;
          // Enc. Fabrica Un. Completas
        } else if (this.documentType === 'OrderFabUnComp') {
          return this.lengthOfControl = 4;

        }
      }
    }

    return null;
  }

  // validação caracteres (não está a ser usado, mas manter)
  validationCharacters(): any {
    // se não for delegação
    if (!this.isDelegation) {

      if (this.documentType === 'OrderMatStock') {
        return this.startBy = 7;

      } else if (this.documentType !== 'OrderFabUnComp') {
        return this.startBy = 6;

      } else if (this.documentType === 'OrderFabUnComp') {
        return this.startBy = 9;

      }
    }
    return null;
  }

  // #region Métodos Gerais e Comuns

  // calcular as avaliações do prazo de entrega e da quantidade
  calculateDeliveryDateAndReceivedQuantity(detail: OrderDetail, quantityToReceive: number) {
    let pattern = /Date\(([^)]+)\)/;
    let dateDetail = null;
    let currentDate = new Date(); // data de hoje

    if (detail.DeliveryEarliestAcceptableDate !== null) {
      let results = pattern.exec(detail.DeliveryEarliestAcceptableDate);

      if (parseFloat(results[1]) > 0) {
        dateDetail = new Date(parseFloat(results[1]));

        this.detailCurrentDateMinus7 = new Date(dateDetail.getFullYear(), dateDetail.getMonth(), dateDetail.getDate());
        this.detailCurrentDateMinus7.setDate(this.detailCurrentDateMinus7.getDate() - 7); // - 7

        this.detailCurrentDateMinus14 = new Date(dateDetail.getFullYear(), dateDetail.getMonth(), dateDetail.getDate());
        this.detailCurrentDateMinus14.setDate(this.detailCurrentDateMinus14.getDate() - 7); // - 14

        this.detailCurrentDatePlus7 = new Date(dateDetail.getFullYear(), dateDetail.getMonth(), dateDetail.getDate());
        this.detailCurrentDatePlus7.setDate(this.detailCurrentDatePlus7.getDate() + 7); // + 7

        this.detailCurrentDatePlus14 = new Date(dateDetail.getFullYear(), dateDetail.getMonth(), dateDetail.getDate());
        this.detailCurrentDatePlus14.setDate(this.detailCurrentDatePlus14.getDate() + 14); // + 14

        if (this.detailCurrentDatePlus14 < currentDate) { // entrega mais do que 2 semanas do prazo limite
          detail.DeliveryDateEval = '1';
        } else if (this.detailCurrentDatePlus7 < currentDate) { // entrega mais do que 1 semanas do prazo limite
          detail.DeliveryDateEval = '2';
        } else if (this.detailCurrentDateMinus7 > currentDate) { // entrega antes 2 semanas do prazo limite
          detail.DeliveryDateEval = '3';
        } else if (this.detailCurrentDateMinus14 > currentDate) { // entrega antes 1 semanas do prazo limite
          detail.DeliveryDateEval = '4';
        } else { // entrega dentro de uma semana do prazo limite
          detail.DeliveryDateEval = '5';
        }
      }
    } else if (this.earliestAcceptableDate !== null) { // pela data de entrega do cabeçalho
      if (this.detailCurrentDatePlus14 < currentDate) {
        detail.DeliveryDateEval = '1';
      } else if (this.detailCurrentDatePlus7 < currentDate) {
        detail.DeliveryDateEval = '2';
      } else if (this.currentDateMinus7 > currentDate) {
        detail.DeliveryDateEval = '3';
      } else if (this.currentDateMinus14 > currentDate) {
        detail.DeliveryDateEval = '4';
      } else {
        detail.DeliveryDateEval = '5';
      }
    }

    // verificar a avaliação da quantidade
    if (detail.ReceivedQuantity < quantityToReceive * 0.85) {
      detail.ReceivedQuantityEval = '1';
    } else if (detail.ReceivedQuantity < quantityToReceive * 0.95) {
      detail.ReceivedQuantityEval = '2';
    } else if (detail.ReceivedQuantity < quantityToReceive * 0.99) {
      detail.ReceivedQuantityEval = '3';
    } else if (detail.ReceivedQuantity > quantityToReceive) {
      detail.ReceivedQuantityEval = '4';
    } else {
      detail.ReceivedQuantityEval = '5';
    }
    detail.ProductEval = '5';
  }

  // alteração avaliação data
  onSelectEvalDateID(e: any, positionArrayForm: number) {
    const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];
    (<UntypedFormGroup>detailForm).controls['EvalDateID'].setValue(e);
  }

  // alteração avaliação quantidade
  onSelectEvalQtdID(e: any, positionArrayForm: number) {
    const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];
    (<UntypedFormGroup>detailForm).controls['EvalQtdID'].setValue(e);
  }

  onSelectReturnReasonID(e: any, positionArrayForm: number) {
    const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];
    (<UntypedFormGroup>detailForm).controls['ReturnReasonID'].setValue(e);
  }

  // alteração avaliação produto
  onSelectEvalProdID(e: any, positionArrayForm: number) {
    const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];
    (<UntypedFormGroup>detailForm).controls['EvalProdID'].setValue(e);
  }

  // alteração avaliação geral
  onSelectEvaluation(e: any, positionArrayForm: string) {
    const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];
    (<UntypedFormGroup>detailForm).controls['Evaluation'].setValue(e);
  }

  // alteração do valor da quantidade recebida
  receivedQuantityChange(receivedQuantity: number, positionArrayForm: number) {
    if (!isNaN(receivedQuantity)) {

      let detailModel = this.details[positionArrayForm] as OrderDetail;
      const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];

      // quantidade que ainda não foi recebida
      let quantityToReceive: number = detailModel.OrderedQuantity - detailModel.SatisfiedQuantity

      // verificar a avaliação da quantidade
      if (receivedQuantity < quantityToReceive * 0.85) {
        (<UntypedFormGroup>detailForm).controls['EvalQtdID'].setValue('1');
      } else if (receivedQuantity < quantityToReceive * 0.95) {
        (<UntypedFormGroup>detailForm).controls['EvalQtdID'].setValue('2');
      } else if (receivedQuantity < quantityToReceive * 0.99) {
        (<UntypedFormGroup>detailForm).controls['EvalQtdID'].setValue('3');
      } else if (receivedQuantity > quantityToReceive) {
        (<UntypedFormGroup>detailForm).controls['EvalQtdID'].setValue('4');
      } else {
        (<UntypedFormGroup>detailForm).controls['EvalQtdID'].setValue('5');
      }

      if ((<UntypedFormGroup>detailForm).controls['RevisedQuantity'].getRawValue() == receivedQuantity) {
        (<UntypedFormGroup>detailForm).controls['Close'].setValue(false);
        (<UntypedFormGroup>detailForm).controls['Close'].disable()
        if (this.closeAll) {
          (<UntypedFormGroup>detailForm).controls['Close'].setValue(false)
        }
      } else {
        (<UntypedFormGroup>detailForm).controls['Close'].enable()
        if (this.closeAll) {
          (<UntypedFormGroup>detailForm).controls['Close'].setValue(true)
        }
      }
    }

  }

  returnedQuantityChange(qt: number, positionArrayForm: number) {
    if (!isNaN(qt)) {

      const detailForm = (<UntypedFormGroup>this.form.controls['DetailsList']).controls[positionArrayForm];

      //Desativa/Ativa os motivos de devolução
      if (qt > 0){
        (<UntypedFormGroup>detailForm).controls['ReturnReasonID'].enable();
      }else{
        (<UntypedFormGroup>detailForm).controls['ReturnReasonID'].disable();
        (<UntypedFormGroup>detailForm).controls['ReturnReasonID'].setValue(null);
      }
    }
  }

  /**
   * Abre o Modal responsável por Selecionar a Guia associada à Encomenda
   */
   selectAssociatedDeliveryNote() {
    this.translateService.get(['SELECT_DELIVERY_NOTE','DOCUMENT', 'DATE']).subscribe(response => {
      let that = this;

      let aoColumns = [
        { 'data': 'DeliveryNoteNumber', 'class': 'verticalMiddle', 'title': response['DOCUMENT'] },
        { 'data': 'DeliveryNoteDate', 'class': 'verticalMiddle', 'title': response['DATE'] }
      ];

      let columnDefs = [
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

      dialogRef = this.dialog.open(ChooseModalComponent, {
        data: // dados que vai enviar para o componente da modal
          new ChooseModalParam(this.associatedDeliveryNotes, null, response['SELECT_DELIVERY_NOTE'],
            aoColumns, columnDefs, null, 1, null),
        disableClose: true // nao permitir fechar modal com escape ou clique fora
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = (+result);
          if (resultID != null || resultID.toString().length > 0) {
           this.form.get('GuideNumber').setValue(this.associatedDeliveryNotes.find(obj => obj.ID == resultID).DeliveryNoteNumber);
          }
        }
      });
    });
  }

  resetForm() {
    // this.form.reset(this.model);
  }

  checkboxesAction() {
    if (this.closeAll) {
      this.clearCheckboxesSelection();
    } else {
      this.selectAllCheckboxes();
    }
  }

  selectAllCheckboxes() {
    this.idsToClose = new Array();
    if (this.details && this.details.length > 0) {
      for (let index = 0; index < this.details.length; index++) {
        this.idsToClose.push(Number(this.details[index].ID));
      }

      // Adicionar o closeAll das checkboxes da tabela
      for (let control of (<FormArray>this.form.get('DetailsList')).controls) {
        if (control.get('Close').enabled) {
          control.get('Close').setValue(true);
        }
      }
    }
  }

  clearCheckboxesSelection() {
    // Limpar o array
    this.idsToClose = new Array();
    // Tirar o closeAll das checkboxes da tabela
    for (let control of (<FormArray>this.form.get('DetailsList')).controls) {
      if (control.get('Close').enabled) {
        control.get('Close').setValue(false);
      }
    }
  }

  onChangeClose(idCheckClose: string){
    if (this.idsToClose.indexOf(Number(idCheckClose)) > -1) {
      this.idsToClose.splice(this.idsToClose.indexOf(Number(idCheckClose)), 1);
      if (this.closeAll) {
        this.closeAll = false;
      }
    } else {
      this.idsToClose.push(Number(idCheckClose));
    }
  }

  addRowObservations(position: number, open: boolean) {
    // quando clico em adicionar linha retirar class hide p/ mostrar
    document.getElementById('obs-' + position).classList.remove('hide');

    if (open) { // se for abrir
      document.getElementById('obs-icon-' + position).classList.remove('fa-plus');
      document.getElementById('obs-icon-' + position).classList.add('fa-minus');
    } else { // se for fechar
      document.getElementById('obs-icon-' + position).classList.remove('fa-minus');
      document.getElementById('obs-icon-' + position).classList.add('fa-plus');
      document.getElementById('obs-' + position).classList.add('hide');
    }
  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
