export class TableRequest {
    constructor(
        /// Url para executar o Pedido
        public Url : string,
        /// Metodo
        public Method : Method,
        //Corpo do request
        public Body : any = {},
        //Body semelhante ao que já exisita em JQuery
        public JQueryDataTableParamModel? : JQueryDataTableParamModel
    ) { }
}

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH"
}

/* Old Method para estar disponivel em todos os ecrã */

export class JQueryDataTableParamModel {
    constructor(
        public draw : number,
        public start : number,
        public length : number,
        public search : DTSearch,
        public order : DTOrder[],
        public columns : DTColumn[]
    ){}
    
}

export class DTSearch
{
    constructor
    (
        //value to search
        public  value : string,
        //true if the global filter should be treated as a regular expression for advanced searching, false otherwise.
        public  regex : boolean
    ){}
    
}

export class DTOrder
{
    constructor
    (
        //Number of Column to which ordering should be applied
        public  column : number,
        //Ordering direction for this column.
        //It will be asc or desc to indicate ascending ordering or descending ordering, respectively.
        public dir : string
    ){}
    
}
export class DTColumn
{
    constructor(
        //Column's data source, as defined by columns.data
        public data : string,
        //Column's name, as defined by columns.name.
        public name : string ,
        //Flag to indicate if this column is searchable (true) or not (false). This is controlled by columns.searchable.
        public  searchable : boolean,
        //Flag to indicate if this column is orderable (true) or not (false). This is controlled by columns.orderable.
        public  orderable : boolean,
        //Search value to apply to this specific column.
        public  search : DTSearch
    ) {}
}