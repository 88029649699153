import { Component, OnDestroy, Inject } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { validateFormArray, joinAllErrors } from 'src/app/constants/form-validators';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './code-list-modal.html'
})
export class CodeListModalComponent implements OnDestroy {
  joinAllErrors = joinAllErrors;
  // Formulário
  form: UntypedFormArray;
  formAllErrors = '';
  formErrors: any[];
  validationMessages: any;
  isSubmited: boolean = false;
  // Nome da lista
  listName: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public dialogRef: MatDialogRef<CodeListModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder) {
    this.form = data.form;
    this.formErrors = data.formErrors;
    this.validationMessages = data.validationMessages;
    this.listName = data.listName;

    // Carrega as validações
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() =>
      this.formErrors = validateFormArray(this.form, this.formErrors, this.validationMessages, this.isSubmited));
    this.formErrors = validateFormArray(this.form, this.formErrors, this.validationMessages, this.isSubmited);
  }

  /**
   * Criar/Atualizar os detalhes do pedido de transporte
   */
  save() {
    this.isSubmited = true;
    this.form.updateValueAndValidity();

    // Valida se o formulário contém erros
    this.formAllErrors = joinAllErrors(this.formErrors);
    if (this.formAllErrors.length > 0) {
      return;
    }

    // Juntar os Nºs de Encomenda
    let codes: string[] = [];
    for (let i = 0; i < this.form.controls.length; i++) {
      if (this.form.controls[i].get('Code').value) {
        codes.push(this.form.controls[i].get('Code').value);
      }
    }

    this.dialogRef.close(codes.length > 0 ? codes.join(',') : null);
  }

  // Códigos
  addRow() {
    // Adiciona a linha de erros no código
    this.formErrors.push({
      'Code': ''
    });

    this.form.controls.push(this.formBuilder.group({
      Code: [null, [Validators.required, Validators.maxLength(30)]]
    }));
  }

  deleteRow(index: number) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.formErrors.splice(index, 1);
        this.form.removeAt(index);
      }
    });
  }
  // FIM - Códigos

  ngOnDestroy() { }
}
