import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { GenericFieldConfig, GenericFieldType } from 'src/app/models/generic-field-config';
import { CommonService } from 'src/app/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GenericFieldRule } from 'src/app/models/generic-field-rule';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModelValidators } from 'src/app/models/validators/validators';
import { MessageModalComponent } from '../../shared/message-modal/message-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-fields-rules-area',
  templateUrl: './fields-rules-area.html'
})
export class FieldsRulesAreaComponent implements OnInit, OnDestroy {
  @Input() context: string;
  @Input() form: UntypedFormGroup;
  @Input() formErrors: any[];
  @Input() validationMessages: any;
  @Input() fieldsConfig: GenericFieldConfig[];
  @Input() fieldsValues: GenericFieldExtension[];
  @Input() documentTypeID: number;
  @Input() documentStateID: number;
  @Input() fieldsRulesSelections: GenericFieldExtension[];
  @Input() fieldsNames: string[];
  @Input() isCreation: boolean = false;
  @Input() uppercaseText: boolean = false;

  FieldTypeString = GenericFieldType.STRING;
  FieldTypeArray = GenericFieldType.ARRAY;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // Listas
  rules: GenericFieldRule[][] = [];
  // Formulário
  collPnRule: boolean[] = [];

  constructor(private commonService: CommonService, private formBuilder: UntypedFormBuilder, private translateValueService: TranslateValueService,
    private authenticationService: AuthenticationService, private dialog: MatDialog) { }

  ngOnInit() {
    this.validationMessages.FieldsRules = {
      'FieldRule': {
        'required': this.translateValueService.get('FIELD_ISEMPTY', { field: this.translateValueService.get('DESCRIPTION_RULE') })
      },
      'Levels': {}
    };

    // Regras de Descrição
    this.commonService.getFieldsRules(this.context).pipe(takeUntil(this.destroy$)).subscribe(response => {
      // Constrói a lista de regras no formulário
      this.form.addControl('FieldsRules', new UntypedFormArray([]));
      // Inicializa as regras
      this.initFieldsRules(response);
    });
  }

  /**
   * Atualizar níveis quando a regra é alterada
   * @param  {number} index
   */
  onChangeRule(index: number) {
    const control = <UntypedFormGroup>(<UntypedFormArray>this.form.controls['FieldsRules']).at(index);
    const ruleID = control.get('FieldRule').value;

    // Preenche as notas da regra, se estiver selecionada e existir
    let selectedRule: GenericFieldRule;
    if (ruleID) {
      selectedRule = this.rules[control.get('FieldCode').value].find((r: GenericFieldRule) => r.ID === ruleID);
      control.get('RuleNotes').setValue(selectedRule.Notes);
    } else {
      control.get('RuleNotes').setValue(null);
    }

    if (ruleID) {
      this.initLevels(<UntypedFormArray>control.controls['Levels'], this.formErrors['FieldsRules'][index], this.fieldsConfig.filter(r => +r.GroupCode === ruleID));
    } else {
      this.initLevels(<UntypedFormArray>control.controls['Levels'], this.formErrors['FieldsRules'][index], []);
    }
  }

  showNotes(title: string, message: string) {
    this.dialog.open(MessageModalComponent, { data: { title: title, message: message } });
  }

  /**
   * Atualizar o campo quando o nível é alterado
   * @param  {{value:any} event, {number} parentIndex?} event
   */
  onFieldChange(event: { value: any, parentIndex?: number }) {
    if (event.parentIndex !== null) {
      const fieldRuleControl = (<UntypedFormArray>this.form.get('FieldsRules')).at(event.parentIndex);

      const control = this.form.get(fieldRuleControl.get('FieldCode').value);

      if (control) {
        control.setValue(this.joinAllLevels(<UntypedFormArray>fieldRuleControl.get('Levels')));
      }

    }
  }

  /**
   * Mapear os campos dos níveis das regras
   * @param  {number} referenceID
   */
  mapFormFieldsToBDFields(referenceID: number, globalFieldsValues: GenericFieldExtension[]): GenericFieldExtension[] {
    let fieldsValuesMap: GenericFieldExtension[] = [];

    // Se não tiver valor, inicializa o array
    if (!this.fieldsValues) {
      this.fieldsValues = [];
    }

    if (this.fieldsConfig !== undefined) {
      (<UntypedFormArray>this.form.controls['FieldsRules']).controls.forEach((ruleControl: UntypedFormGroup) => {
        // Guarda o valor da regra selecionada
        GenericFieldConfig.insertOrUpdateBDFields(referenceID, this.context, globalFieldsValues, ruleControl.get('FieldCode').value + 'Rule',
          ruleControl.get('FieldRule').value, null);

        // Níveis
        (<UntypedFormArray>ruleControl.controls['Levels']).controls.forEach(levelControl => {
          let config = this.fieldsConfig.find(r => r.FieldCode === levelControl.get('FieldCode').value);

          let value = this.fieldsValues.find(r => r.FieldCode === config.FieldCode);
          if (value) {
            fieldsValuesMap.push(JSON.parse(JSON.stringify(value)));
          }

          // Guarda o valor do nível
          GenericFieldConfig.insertOrUpdateBDFields(referenceID, config.Context, fieldsValuesMap, config.FieldCode, levelControl.get(config.FieldCode).value, null);
        });
      });
    }

    // Destrói os valores antigos, para garantir que não guarda valores de níveis que já não esyão selecionados
    for (let i = this.fieldsValues.length - 1; i >= 0; i--) {
      delete this.fieldsValues[i];
    }

    return fieldsValuesMap;
  }

  //#region Field
  private initFieldsRules(fieldsRules: GenericFieldRule[]) {
    const control = <UntypedFormArray>this.form.controls['FieldsRules'];

    while (control.length > 0) {
      control.removeAt(0);
    }
    this.formErrors['FieldsRules'] = [];

    if (fieldsRules && fieldsRules.length > 0) {
      const fieldCodes = Array.from(new Set(fieldsRules.map(r => r.FieldCode)));

      for (let i = 0; i < fieldCodes.length; i++) {
        // Preenche a lista de regras disponíveis para o campo
        this.rules[fieldCodes[i]] = fieldsRules.filter(r => r.FieldCode === fieldCodes[i]);

        let field = this.buildFieldForm(fieldCodes[i]);

        // Adiciona o nível ao formulário
        control.push(field.control);
        // Adiciona a estrutura de erros do nível
        this.formErrors['FieldsRules'].push(field.formErrors);

        // Inicializa os níveis, se tiver alguma regra selecionada
        this.onChangeRule(i);

        // Bloqueia os campos do formulário/regras
        const fieldControl = this.form.get(fieldCodes[i]);
        if (fieldControl) {
          if (fieldControl.enabled) {
            // Bloqueia o campo, se existir
            fieldControl.disable();
          } else {
            // Bloqueia a regra
            control.disable();
          }
        }
      }
    }
  }

  /**
   * Construir o formulário dos níveis
   * @param  {TransportRequestDetail} field Entidade do nível para construir o formulário
   * @returns any Devolve o formulário (control) e o objeto de erros (formErrors)
   */
  private buildFieldForm(fieldCode: string): any {
    // Valor selecionado da regra
    let value = this.fieldsRulesSelections.find(r => r.FieldCode === fieldCode + 'Rule');

    let selectedRule: GenericFieldRule;
    if (value) {
      selectedRule = this.rules[fieldCode].find((r: GenericFieldRule) => r.ID === +value.FieldValue);
    }

    const control = this.formBuilder.group({
      // Entidade
      FieldRule: [value ? +value.FieldValue : null, Validators.required],
      FieldCode: [fieldCode],
      RuleNotes: [selectedRule ? selectedRule.Notes : null],
      Levels: this.formBuilder.array([])
    });

    let formErrors = {
      'FieldRule': '',
      'Levels': []
    };

    return { control: control, formErrors: formErrors };
  }

  /**
   * Devolver o resultado da concatenação de todos os níveis
   * @param  {number} index Índice da regra do campo
   */
  private joinAllLevels(levelsControl: UntypedFormArray): string {
    let result = '';

    levelsControl.controls.forEach(levelControl => {
      // const config = levelControl.get('FieldConfig').value as GenericFieldConfig;
      const value = levelControl.get((levelControl.get('FieldConfig').value as GenericFieldConfig).FieldCode).value;

      if (value) {
        result += ' ' + value;
      }
    });

    if (result.length > 0) {
      result = result.substring(1);
    }

    return result;
  }
  //#endregion Field

  //#region Level
  private initLevels(control: UntypedFormArray, formErrors: any[], fieldsLevels: GenericFieldConfig[]) {
    while (control.length > 0) {
      control.removeAt(0);
    }
    formErrors['Levels'] = [];

    if (fieldsLevels && fieldsLevels.length > 0) {
      for (let i = 0; i < fieldsLevels.length; i++) {
        let level = this.buildLevelForm(fieldsLevels[i]);

        // Adiciona o nível ao formulário
        control.push(level.control);
        // Adiciona a estrutura de erros do nível
        formErrors['Levels'].push(level.formErrors);

        // Mensagens de erro
        if (this.validationMessages.FieldsRules.Levels[fieldsLevels[i].FieldCode]) {
          this.validationMessages.FieldsRules.Levels[fieldsLevels[i].FieldCode].required = this.translateValueService.get('FIELD_ISEMPTY', { field: fieldsLevels[i].FieldName });
          if (this.fieldsConfig[i].FieldLength) {
            this.validationMessages.FieldsRules.Levels[fieldsLevels[i].FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: fieldsLevels[i].FieldName, value: this.fieldsConfig[i].FieldLength.toString() });
          }
        } else {
          this.validationMessages.FieldsRules.Levels[fieldsLevels[i].FieldCode] = {
            'required': this.translateValueService.get('FIELD_ISEMPTY', { field: fieldsLevels[i].FieldName })
          };

          if (this.fieldsConfig[i].FieldLength) {
            this.validationMessages.FieldsRules.Levels[fieldsLevels[i].FieldCode].maxlength = this.translateValueService.get('FIELD_MAXLENGTH', { field: fieldsLevels[i].FieldName, value: this.fieldsConfig[i].FieldLength.toString() });
          }
          if (this.fieldsConfig[i].FieldType === GenericFieldType.NUMBER) {
            this.validationMessages.FieldsRules.Levels[fieldsLevels[i].FieldCode].numberVal = this.translateValueService.get('FIELD_NOT_NUMBER', { field: fieldsLevels[i].FieldName });
          }
        }
      }
    }
  }

  /**
   * Construir o formulário dos níveis
   * @param  {TransportRequestDetail} levelField Entidade do nível para construir o formulário
   * @returns any Devolve o formulário (control) e o objeto de erros (formErrors)
   */
  private buildLevelForm(fieldLevel: GenericFieldConfig): any {
    // Limpa o XML de settings para prevenir erros de validação do pedido de gravação
    fieldLevel.SettingsXml = null;

    const control = this.formBuilder.group({
      FieldCode: [fieldLevel.FieldCode],
      FieldConfig: [fieldLevel]
    });

    let validators = [];
    if (fieldLevel.isFieldRequired(this.documentTypeID, this.documentStateID, this.authenticationService.session.roles)) {
      validators.push(Validators.required);
    }
    if (fieldLevel.FieldLength) {
      validators.push(Validators.maxLength(fieldLevel.FieldLength));
    }
    if (fieldLevel.FieldType === GenericFieldType.NUMBER) {
      validators.push(ModelValidators.numberVal());
    }

    control.addControl(fieldLevel.FieldCode,
      new UntypedFormControl({
        value: fieldLevel.getFieldValue(this.fieldsValues.find(r => r.FieldCode === fieldLevel.FieldCode && r.Identifier === null), this.isCreation),
        disabled: !fieldLevel.isFieldEditable(this.documentTypeID, this.documentStateID, this.authenticationService.session.roles)
      }, validators));

    let formErrors = {};
    formErrors[fieldLevel.FieldCode] = '';

    return { control: control, formErrors: formErrors };
  }
  //#endregion Level

  ngOnDestroy() { }

}
