// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormGroup, FormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateValueService } from '../../services/translate-value.service';

// ***__***_________  MODELOS _________ ***__***
import { Order, OrderDetail } from '../../models/order';
import { Subject } from 'rxjs';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';
import { OrderService } from 'src/app/services/order.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { ErrorTreatmentFunctions } from 'src/app/modules/treatments.module';
import { ModelValidators } from 'src/app/models/validators/validators';

declare var Functions: any;

@Component({
  templateUrl: './change-delivery-date-modal.html'
})
export class ChangeDeliveryDateComponent implements OnInit, OnDestroy {

  /* ***************************************************
   * *********** MODAL --> Atualizar data de entrega ***********
   * ***************************************************
   * */
  destroy$: Subject<boolean> = new Subject<boolean>();
  details: Array<any> = new Array<any>();
  // array validações num receção
  receptionNumberValidators: Array<any> = new Array<any>();

  form: UntypedFormGroup;

  open: boolean = true;
  submitted: boolean = false;

  areSelectsRequired: boolean; // se os 3 selects da modal sao obrigatorios

  earliestAcceptableDate: Date;
  latestAcceptableDate: Date;
  currentDate = new Date(); // data de hoje
  documentNumber: string;
  documentType: string;
  documentState: number;
  startBy: any;
  charactersToValidate: number = 0;
  lengthOfControl: number;
  allowEdit: boolean = false;

  receptionReceivedQuantityDefaultValue: number = 0;

  showDispatchedQuantity: boolean = false; //Adiciona a Coluna "DespatchedQuantity" ao Popup Confirmação Receção

  validationMessages = {};
  validationMessagesDetails = {};

  constructor(public dialogRef: MatDialogRef<ChangeDeliveryDateComponent>, @Inject(MAT_DIALOG_DATA) data: any, private formBuilder: UntypedFormBuilder, private translateValueService: TranslateValueService, private orderService: OrderService, private _errorTreat: ErrorTreatmentFunctions) {

    this.details = data.modelDetails;
    this.earliestAcceptableDate = data.earliestAcceptableDate;
    this.latestAcceptableDate = data.latestAcceptableDate;
    this.documentState = data.documentState;
    this.documentType = data.documentType;
    this.documentNumber = data.documentNumber;
    this.allowEdit = data.allowEdit;

    this.validationMessages = {
    }

    this.validationMessagesDetails = {
    }
  }

  ngOnInit(): void {
    this.buildForm();    
  }

  buildForm(): void {

    this.form = this.formBuilder.group({
      'DetailsList': this.formBuilder.array([]),
      'EarliestAcceptableDate' : {value: DateTimePickerDirective.convertToString(this.earliestAcceptableDate, false), disabled: true},
      'LatestAcceptableDate' : [{value: DateTimePickerDirective.convertToString(this.latestAcceptableDate, false), disabled: !this.allowEdit}, Validators.compose([ModelValidators.validDate, ModelValidators.lessThenCurrentDate])]
    },
    {
      validators: (form: FormGroup) => {
        const {
          EarliestAcceptableDate: earliestAcceptableDateControl,
          LatestAcceptableDate: latestAcceptableDateControl
        } = form.controls;

        if (earliestAcceptableDateControl.value === latestAcceptableDateControl.value) {
          const error = { equalDate: true };
          latestAcceptableDateControl.setErrors(error)
          return { equalDate: true }
        }
        return null;
      }
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.addDetail();

    this.onValueChanged(); // para apresentar mensagens de validação


  }

  /* tslint:disable:member-ordering */
  formErrors: Array<string> = new Array<string>();
  formErrorsParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        // if ((this.submitted && (control && !control.valid && control.enabled)) ||
        // (!this.submitted && (control && control.dirty && !control.valid))
        // ) {

        if (control && !control.valid && control.enabled && control.dirty) {

          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  // #region Details
  initDetail(detail: OrderDetail) {

    return this.formBuilder.group({
      'ID': [detail.ID],
      'LineNumber': [{ value: detail.LineNumber, disabled: true }],
      'Code': [{
        value: detail.BuyerProductCode ? detail.BuyerProductCode :
          detail.StandardProductCode ? detail.StandardProductCode :
            detail.SupplierProductCode ? detail.SupplierProductCode : '', disabled: true
      }],
      'Description': [{ value: detail.ProductDescription, disabled: true }],
      'DeliveryEarliestAcceptableDate': [{ value: DateTimePickerDirective.convertToString(detail.DeliveryEarliestAcceptableDate, false), disabled: true }], // data prevista de entrega
      'DeliveryLatestAcceptableDate': [ { value:DateTimePickerDirective.convertToString(detail.DeliveryLatestAcceptableDate, false), disabled: !this.allowEdit },  Validators.compose([ModelValidators.validDate, ModelValidators.lessThenCurrentDate])], // data de entrega atualizada
    },
    {
      validators: (form: FormGroup) => {
        const {
          DeliveryEarliestAcceptableDate: deliveryEarliestAcceptableDateControl,
          DeliveryLatestAcceptableDate: deliveryLatestAcceptableDateControl
        } = form.controls;

        if (deliveryLatestAcceptableDateControl.value === deliveryEarliestAcceptableDateControl.value) {
          const error = { equalDate: true };
          deliveryLatestAcceptableDateControl.setErrors(error)
          return { equalDate: true }
        }
        return null;
      }
    });
  }

  addDetail() {
    // inserir os dados que ja estao gravados
    if (this.details != null) {
      for (let detail of this.details) {
        const control = <UntypedFormArray>this.form.controls['DetailsList'];
        const ctrl = this.initDetail(detail);
        control.push(ctrl);
        ctrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          this.onValueChangedDetails(value);
        });
      }
    }
  }

  /* tslint:disable:member-ordering */
  formErrorsDetails: Array<string> = new Array<string>();
  formErrorsDetailsParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedDetails(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    // clear previous error message (if any)
    this.formErrorsDetails = new Array<string>();
    this.formErrorsDetailsParam = new Array<string>();
    for (const field in this.validationMessagesDetails) {
      if (this.validationMessagesDetails.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('DetailsList');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              
              const messages = this.validationMessagesDetails[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsDetails.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsDetailsParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsDetailsParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // endregion Details

  // guardar
  save() {

    this.submitted = true;

    this.onValueChanged();
    this.onValueChangedDetails();

    let msg: string = '';

    if (!this.form.invalid) {

      // guardar
      this.orderService.updateDeliveryDates(this.details, this.form.get('LatestAcceptableDate').value ? DateTimePickerDirective.convertToDate(this.form.get('LatestAcceptableDate').value, false) : null).pipe(takeUntil(this.destroy$)).subscribe((resp: ReturnStatusHtml) => {
        if (resp.ReturnStatus.Successfull) {
          Functions.gritter(resp.ReturnStatus.SuccessMessage, 'success');
        } else {
          this._errorTreat.treatErrorResponse(resp);
        }
      });
    
      this.dialogRef.close(this.form.getRawValue());
      
    }
    // this.submitted = false;
  }

  /**
   * Método para alterar data de entrega atualizada
   */
  onLatestAcceptableDate(context: string = null, newDate: any = null, position: number = null) {
    const detailsListControl = this.form.get('DetailsList') as FormArray;
    const latestAcceptableDateControl = this.form.get('LatestAcceptableDate');
  
    let newDateString  = null;
    if(newDate)
    {
      newDateString = newDate; //(DateTimePickerDirective.convertToDate(newDate, false) as Date);
    }

    if (context == 'Order') {
      if (this.details) {

        if (latestAcceptableDateControl.valid) {
          this.details.forEach((detail: OrderDetail) => {
            if (detail.DeliveryLatestAcceptableDate !== newDateString) {
              detail.DeliveryLatestAcceptableDate = newDateString;
            }
          });

          detailsListControl.controls.forEach(control => {
            control.get('DeliveryLatestAcceptableDate').setValue(newDate, { emitEvent: false });
          });
        }
        
      }
    } else if (context == 'OrderDetail') {
      if (position !== null) {
        const deliveryLatestAcceptableDateControl = detailsListControl.at(position).get('DeliveryLatestAcceptableDate');
        
        deliveryLatestAcceptableDateControl.setValue(newDate, {emitEvent: false});
        this.details[position].DeliveryLatestAcceptableDate = newDateString;
      
  
        // Validar se todos os detalhes sao iguais = mudar cabeçalho
        const allDetailsAreEqual = detailsListControl.controls.every(control =>
          control.get('DeliveryLatestAcceptableDate').value === newDate
        );
  
        if (allDetailsAreEqual) {
          if (latestAcceptableDateControl.value !== newDate) {
            latestAcceptableDateControl.setValue(newDate, {emitEvent: false});
          }
          detailsListControl.controls.forEach(control => {
            control.get('DeliveryLatestAcceptableDate').setValue(newDate, {emitEvent: false});
          });
        } else {
          latestAcceptableDateControl.setValue('', {emitEvent: false});
        }
      }
    }
  }
  
  

  // #region Métodos Gerais e Comuns

  resetForm() {
    // this.form.reset(this.model);
  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
