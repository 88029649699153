
import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Cookie } from 'ng2-cookies';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateValueService } from '../../services/translate-value.service';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { AppConfig } from '../../configs/app.config';
import { Country } from '../../models/country';
import { CommonService } from '../../services/common.service';
import { Language } from '../../models/language';
import { ModelValidators } from '../../models/validators/validators';
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';
import { Company } from '../../models/company';

declare var App: any;
declare var Functions: any;

@Component({
  templateUrl: './register.html'
  // ,providers: [{
  //   provide: RECAPTCHA_URL,
  //   useValue: 'Services/ValidateCaptcha'
  // }]
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  year: number = (new Date()).getFullYear();
  serviceProviders: Company[];
  countries: Country[];
  languages: Language[];
  form: UntypedFormGroup;
  formAllErrors: string = '';
  formErrors = {
    'OwnerID': '',
    'CompanyName': '',
    'CompanyCountry': '',
    'CompanyTaxNumber': '',
    'UserName': '',
    'UserEmail': '',
    'UserLocale': ''
    // ,'Captcha': ''
  };
  validationMessages: any;
  submitted: boolean = false;
  cookieMsgRead: boolean = false;

  // Configurações
  isSignUp: boolean = false;
  loginFooterText: string;
  loginFooterUrl: string;

  constructor(private renderer: Renderer2, private authenticationService: AuthenticationService, private router: Router, private formBuilder: UntypedFormBuilder, private translateValueService: TranslateValueService,
    private translateService: TranslateService, private config: AppConfig, private commonService: CommonService, private _errorTreat: ErrorTreatmentFunctions) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'texture');

    if (!this.config.getConfig('IS_SIGN_UP')) {
      this.router.navigate(['/']);
    }

    // Footer
    this.loginFooterText = this.config.getLoginFooterText();
    this.loginFooterUrl = this.config.getLoginFooterUrl();

    this.translateService.get(['PARTNER', 'COMPANY_NAME', 'COUNTRY', 'TAX_NUMBER', 'USER_NAME', 'EMAIL', 'LANGUAGE']).subscribe(fields =>
      this.validationMessages = {
        'OwnerID': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.PARTNER })
        },
        'CompanyName': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.COMPANY_NAME }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: fields.COMPANY_NAME, value: '100' })
        },
        'CompanyCountry': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.COUNTRY }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: fields.COUNTRY, value: '3' })
        },
        'CompanyTaxNumber': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.TAX_NUMBER }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: fields.TAX_NUMBER, value: '25' })
        },
        'UserName': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.USER_NAME }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: fields.USER_NAME, value: '50' })
        },
        'UserEmail': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.EMAIL }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: fields.EMAIL, value: '100' }),
          'email': this.translateValueService.get('EMAIL_INVALID')
        },
        'UserLocale': {
          'required': this.translateValueService.get('FIELD_REQUIRED', { field: fields.LANGUAGE }),
          'maxlength': this.translateValueService.get('FIELD_MAXLENGTH', { field: fields.LANGUAGE, value: '5' })
        }
        // ,'Captcha': {
        //   'required': this.translateValueService.get('FIELD_REQUIRED', { field: 'reCaptcha' }),
        //   'tokenInvalid': this.translateValueService.get('INVALID_TOKEN')
        // }
      }
    );

    forkJoin([
      this.commonService.serviceProviders.pipe(map(response => this.serviceProviders = response)),
      this.commonService.countries.pipe(map(response => this.countries = response)),
      this.commonService.languages.pipe(map(response => this.languages = response))
    ]).pipe(takeUntil(this.destroy$)).subscribe(() => this.buildForm());

    this.cookieMsgRead = Cookie.check('cookieMsgRead');
  }

  public ngAfterViewInit(): void {
    App.init();
    Functions.tooltipPassword();
    let logoImg = this.config.getConfig('LOGO_IMG');
    document.querySelector('.login-theme .logo-img').setAttribute('src', (document.getElementsByTagName('base')[0].href + 'assets/configurations/logos/' + logoImg));
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      'OwnerID': [this.serviceProviders && this.serviceProviders.length === 1 ? this.serviceProviders[0].ID : null, Validators.required],
      'CompanyName': [null, [Validators.required, Validators.maxLength(100)]],
      'CompanyCountry': ['PT', [Validators.required, Validators.maxLength(3)]],
      'CompanyTaxNumber': [null, [Validators.required, Validators.maxLength(25)]],
      'UserName': [null, [Validators.required, Validators.maxLength(50)]],
      'UserEmail': [null, [Validators.required, Validators.maxLength(100), ModelValidators.emailFormat]],
      'UserLocale': ['pt_PT', [Validators.required, Validators.maxLength(5)]]
      // ,'Captcha': [null]
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onValueChanged(data?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      this.formAllErrors = this.translateValueService.get('LAYOUT.FORM_INVALID');
      return;
    }
    this.formAllErrors = '';

    this.authenticationService.register(this.form.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        this.submitted = true;
      } else {
        this._errorTreat.treatErrorResponse(response);
      }
    });
  }

  dismissCookieMessage() {
    this.cookieMsgRead = true;
    Cookie.set('cookieMsgRead', 'true');
  }

  _onContentChanges() {
    return;
  }

  ngOnDestroy() { }
}
