import { AbstractControl, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export function s2ab(s: string): ArrayBuffer {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  /* eslint-disable no-bitwise */
  for (let i = 0; i !== s.length; ++i) {
    // tslint:disable-next-line:no-bitwise
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  /* eslint-enable no-bitwise */
  return buf;
}

export function triggerEvent(el: any, type: any) {
  if ('createEvent' in document) {
    // modern browsers, IE9+
    let e = document.createEvent('HTMLEvents');
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  }
}

// export function copyFormControl(control: AbstractControl) {
//   if (control instanceof FormControl) {
//     return new FormControl(control.value);
//   } else if (control instanceof FormGroup) {
//     const copy = new FormGroup({});
//     Object.keys(control.getRawValue()).forEach(key => {
//       copy.addControl(key, copyFormControl(control.controls[key]));
//     });
//     return copy;
//   } else if (control instanceof FormArray) {
//     const copy = new FormArray([]);
//     control.controls.forEach(arrayControl => {
//       copy.push(copyFormControl(arrayControl));
//     })
//     return copy;
//   }
// }

/**
 * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
 * @param control AbstractControl
 * @returns AbstractControl
 */
export function cloneAbstractControl(control: AbstractControl) {
  let newControl: AbstractControl;

  if (control instanceof UntypedFormGroup) {
    const formGroup = new UntypedFormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;

    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });

    newControl = formGroup;
  } else if (control instanceof UntypedFormArray) {
    const formArray = new UntypedFormArray([], control.validator, control.asyncValidator);

    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)));

    newControl = formArray;
  } else if (control instanceof UntypedFormControl) {
    newControl = new UntypedFormControl(control.value, control.validator, control.asyncValidator);
  } else {
    throw new Error('Error: unexpected control value');
  }

  if (control.disabled) { newControl.disable({ emitEvent: false }); }

  return newControl;
}

export function randomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
