import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EmailModalParam } from 'src/app/models/email-modal-param';
import { ProcessesService } from 'src/app/services/processes.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { FileTreatmentFunctions } from 'src/app/modules/treatments.module';
import { Subject } from 'rxjs';


@Component({
    templateUrl: './export-documents-modal.html'
})
export class ExportDocumentsModalComponent implements OnInit, OnDestroy {
    values: EmailModalParam = new EmailModalParam();
    submitted: boolean = false;
    noError: boolean = true;
    documents: any;
    Data: EmailModalParam;
    checkboxValues: any = [];
    originalIds: any = [];
    copyIds: any = [];
    processId: number;
    processNumber: string = '';
    destroy$: Subject<boolean> = new Subject<boolean>();
    constructor(public dialogRef: MatDialogRef<ExportDocumentsModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _fileTreat: FileTreatmentFunctions,
        private processesService: ProcessesService,) {
        this.processId = data.id;
        this.processNumber = data.processNumber;
    }

    ngOnInit(): void {
        this.processesService.getProcessDocuments(this.processId).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if(response.ReturnStatus.Successfull){
                this.documents = response.ReturnStatus.ReturnObject.aaData;
            
            }
        });
    }

    getDocumentPDF(id: number){
        if(id){
            this.processesService.getDocumentPDF(id, false).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
                this._fileTreat.afterDownloadFile(response, 'ProcessDocument_' + id + '.pdf');
            });
        }
    }

    getAllPDFsDocuments(){
        if(this.processId){
            this.processesService.getAllDocumentPDFs(this.processId).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
                this._fileTreat.afterDownloadFile(response, 'Process_' + this.processNumber + '.zip');
            });
        }

    }

    ngOnDestroy() { }
}