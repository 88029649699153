import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { AuthenticationService } from './authentication.service';
import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';
import { QuotationType } from '../interfaces/quotation-type';
import { Dropdown } from '../models/dropdown';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { QuotationContext } from '../models/quotation';
import { QuotationSupplierResponse } from '../models/quotationSupplierResponse';

@Injectable()
export class QuotationService {

  dataIniPurchase: string;
  dataFimPurchase: string;
  dataIniSale: string;
  dataFimSale: string;

  // filter_filters: Array<string>;
  datatableParametersPurchases: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, null, 0);
  // Não está em uso porque ainda não está feito o módulo cotações de vendas (inacabado)
  datatableParametersSales: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, null, 0);

  datatableParameters: DatatableParameters[] = [];
  filter: any[] = [];

  private _states: Array<any>;
  private _types: QuotationType[];
  private _controller: string = 'Quotation';

  // Listas
  private _equipmentBrands: Dropdown[];

  /*** SETTINGS ***/
  private showDigitalFileTab_P: boolean;
  private showMessagesTab_P: boolean;
  private showDetailsTab_P: boolean;
  private showSupplierPanel_P: boolean;
  private autoGenerateDocumentNumber_P: boolean;
  private filterDockModalProductsByFamily_P: boolean;
  private filterDockModalProductsBySubFamily_P: boolean;
  private filterDockModalProductsBySubSubFamily_P: boolean;
  private filterDockModalProductsByCategory_P: boolean;
  private showDigitalFileTab_S: boolean;
  private showMessagesTab_S: boolean;
  private showProductsAutomatically: boolean;
  private columnInVisible_P: Array<number>;
  private possibleStates_P: Array<number>;
  private columnInVisible_S: Array<number>;
  private possibleStates_S: Array<number>;
  private detailsColumnInVisible_P: Array<number>;
  private detailsColumnInVisible_S: Array<number>;
  private allowUnblockAllUsers: boolean;
  private hideAssignActionsToAnotherUser: boolean;
  private showInstalationNumber_P: boolean;
  private showInstalationNumber_S: boolean;
  private showEquipmentBrand_P: boolean;
  private showEquipmentBrand_S: boolean;
  private defaultUOM: string;
  private hideSuplierNotes_P: boolean;
  private hideSuplierNotes_S: boolean;
  private showInstalationFilter_P: boolean;
  private showInstalationFilter_S: boolean;
  private showAllowCreateEmptyDetail: boolean;
  private showDetailsPrices: boolean;
  private showQuantities: boolean;
  private hideUnidentifiedProductIcon: boolean;
  private hideDocumentDateHours: boolean;
  private isHeaderEditable: boolean;
  private showValidBudget: boolean;
  private hideCodeArticleClient: boolean;
  private hideCodeArticleProvider: boolean;
  private productDescriptionRequired: boolean;
  
  private filterBySpecificUserGroup: string; //Define se é necessário filtrar os UserGroups por algum valor específico
  private showPanelPaymentConditions: boolean; // setting para mostrar painel "Condições de pagamento" 
  private useSupplierPaymentCondition: boolean; //JJ 13/06/2022 -> Define se mostra o Campo "PaymentCondition"
  private afterSaveRemainOnSamePage: boolean;
  private redirectOnUpdate: boolean;
  private departmentMandatory:boolean;
  private allowInternalMessages_PURCHASE:boolean;
  private setNetUnitPriceAsText: boolean;
  private hideRequestDescription: boolean;
  private showDropAreaDocument: boolean;
  private hideDeliveryEarliestAcceptableDate: boolean;
  private hideDeliveryLatestAcceptableDate: boolean;
  private showDeadline: boolean;

  constructor(private http: HttpClientCustom, private authenticationService: AuthenticationService, private _masterService: MasterService,
    private _dataTableF: DataTableFunctions, private _errorTreat: ErrorTreatmentFunctions) {
    // tamanho do array = ao nº de colunas no html correspondente
    // this.filter_filters = new Array<string>(20).fill('');

    // Carrega as posições e os valores dos filtros
    this.datatableParameters[QuotationContext.PURCHASES] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filter[QuotationContext.PURCHASES] = new Object();
    this.datatableParameters[QuotationContext.SALES] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filter[QuotationContext.SALES] = new Object();
  }

  // #region Cotação
  public get quotationStates(): Observable<Array<any>> {
    if (this._states === undefined) {
      return this.getComplements('GetAllStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._states = response.ReturnStatus.ReturnObject.States;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._states);
    }
  }

  public get quotationsTypes(): Observable<Array<QuotationType>> {
    if (this._types === undefined) {
      return this.getComplements('GetAllTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._types = response.ReturnStatus.ReturnObject.Types;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._types);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter encomenda pelo id
   * @param  {number} id
   * @param  {string} context Purchases/Sales
   * @returns Observable
   */
  get(id: number, context: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&saleOrPurchase=' + context).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter numero documento seguinte
   * @returns Observable
   */
  getNextDocumentNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextDocumentNumber').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar encomenda
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteQuotation?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar quotação
   * @param  {any} quotation
   * @returns Observable
   */
  update(quotation: any, stateID?: number,modelResponse?:Array<QuotationSupplierResponse>,onlyUpdateSupplierResponseStates? : boolean): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(quotation));
    formData.append('stateID', JSON.stringify(stateID));
    formData.append('entityResponse', JSON.stringify(modelResponse));
    formData.append('onlyUpdateSupplierResponseStates', JSON.stringify(onlyUpdateSupplierResponseStates));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateQuotation', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateQuotationResponse(quotation: any, stateID?: number,file?:any,  context:string = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(quotation));
    formData.append('stateID', JSON.stringify(stateID));
    formData.append('saleOrPurchase', JSON.stringify(context));
    if (file != null) {
      formData.append('File', file, file.name);
    }

    return this.http.post(SERVICE_URL + this._controller + '/UpdateQuotationResponse', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  } 

  updateQuotationResponseQuotation(quotation: any, stateID?: number,file?:any,  context:string = null, returnID:boolean = true): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(quotation));
    formData.append('stateID', JSON.stringify(stateID));
    formData.append('saleOrPurchase', JSON.stringify(context));
    formData.append('returnID', JSON.stringify(returnID))
    if (file != null) {
      formData.append('File', file, file.name);
    }

    return this.http.post(SERVICE_URL + this._controller + '/UpdateQuotationResponseQuotation', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  } 

  /**
   * Adicionar quotação
   * @param  {any} quotation
   * @returns Observable
   */
  add(quotation: any): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(quotation));

    return this.http.put(SERVICE_URL + this._controller + '/AddQuotation', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyWorkflowStep(docID: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { docID: docID, stepID: stepID, notes: notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Submeter encomenda
   * @param  {number} id
   * @returns Observable
   */
  submit(id: number, quotation: any = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Submit', { id: id, quotation : quotation }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Guardar e Submeter o Pedido de Cotação
   * @returns Observable
   */
   saveAndSubmitQuotation(quotation: any): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(quotation));

    return this.http.put(SERVICE_URL + this._controller + '/AddAndSubmitQuotation', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Submeter Alteração do Estado do Pedido
   * @param  {number} id
   * @returns Observable
   */
  submitState(id: number, stateId: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitState', { id: id, stateId: stateId }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Download de PDF da encomenda
   * @param  {number} id
   * @returns Observable
   */
  downloadPDFQuotation(id: number, request : boolean = true, responseId : string = null): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFQuotation?id=' + id + '&requestQuotation=' + request + '&quotationResponseID=' + responseId);
  }

  /**
 * Download de Excel da encomenda
 * @param  {number} id
 * @returns Observable
 */
  downloadExcelQuotation(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelQuotation?id=' + id);
  }

  /**
 * Download para Excel da lista de encomendas
 * @param  {number} id
 * @returns Observable
 */
  downloadExcelQuotationsList(context: string, params: any, firstdate: string, lastdate?: string): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelQuotationsList',
      { saleOrPurchase: context, parameters: params, firstdate: firstdate, lastdate: lastdate, extraFilters: this.filter[context].ExtraFilters });
  }

  /**

  */
  updateQuotationProcess(quotationID: number, currentStateID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateQuotationProcess', { quotationID: quotationID, currentStateID: currentStateID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
     * Editar BlockedBy
     * @param  {number} quotationID
     * @param  {number} userID
     * @returns Observable
     */
  updateBlockedBy(quotationID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateBlockedBy', { quotationID: quotationID, userID: userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region Público
  /**
   * Obter cotação pelo token
   * @param  {string} token
   * @returns Observable
   */
  getByToken(token: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByToken?token=' + token).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar cotação sem sessão
   * @param  {any} quotation
   * @param  {string} token
   * @returns Observable
   */
  updateQuotationNoSession(quotation: any, token: string, stateID: number): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(quotation));
    formData.append('token', token);
    formData.append('stateID', JSON.stringify(stateID));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateQuotationNoSession', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion

  //#region Dropdowns
  get equipmentBrands(): Observable<Dropdown[]> {
    if (this._equipmentBrands === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.QUOTATION_BRAND).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._equipmentBrands = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._equipmentBrands;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._equipmentBrands);
    }
  }
  //#endregion Dropdowns

  /****** SETTINGS ******/

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  get_ColumnInVisible_P(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsPurchases', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('QuotationsPurchases', 'ColumVisibleSP');
      
      this.columnInVisible_P = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValue.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_P;
  }

  get_possibleStates_P(defaultValue: Array<number>): Array<number> {
    if (typeof this.possibleStates_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsPurchases', 'PossibleStates');
      if (settingValue != null) {
        this.possibleStates_P = settingValue;
      } else {
        this.possibleStates_P = defaultValue;
      }
    }
    return this.possibleStates_P;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  get_ColumnInVisible_S(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsSales', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('QuotationsSales', 'ColumVisibleSP');
      this.columnInVisible_S = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValue.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_S;
  }

  get_possibleStates_S(defaultValue: Array<number>): Array<number> {
    if (typeof this.possibleStates_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsSales', 'PossibleStates');
      if (settingValue != null) {
        this.possibleStates_S = settingValue;
      } else {
        this.possibleStates_S = defaultValue;
      }
    }
    return this.possibleStates_S;
  }


  get_showDigitalFileTab_P(defaultValue: boolean): boolean {
    if (typeof this.showDigitalFileTab_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowDigitalFileTab');
      if (settingValue != null) {
        this.showDigitalFileTab_P = settingValue;
      } else {
        this.showDigitalFileTab_P = defaultValue;
      }
    }
    return this.showDigitalFileTab_P;
  }

  get_showMessagesTab_P(defaultValue: boolean): boolean {
    if (typeof this.showMessagesTab_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowMessagesTab');
      if (settingValue != null) {
        this.showMessagesTab_P = settingValue;
      } else {
        this.showMessagesTab_P = defaultValue;
      }
    }
    return this.showMessagesTab_P;
  }

  get_showDetailsTab_P(defaultValue: boolean): boolean {
    if (typeof this.showDetailsTab_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowDetailsTab');
      if (settingValue != null) {
        this.showDetailsTab_P = settingValue;
      } else {
        this.showDetailsTab_P = defaultValue;
      }
    }
    return this.showDetailsTab_P;
  }

  get_showSupplierPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showSupplierPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowSupplierPanel');
      if (settingValue != null) {
        this.showSupplierPanel_P = settingValue;
      } else {
        this.showSupplierPanel_P = defaultValue;
      }
    }
    return this.showSupplierPanel_P;
  }

  get_autoGenerateDocumentNumber_P(defaultValue: boolean): boolean {
    if (typeof this.autoGenerateDocumentNumber_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'AutoGenerateDocumentNumber');
      if (settingValue != null) {
        this.autoGenerateDocumentNumber_P = settingValue;
      } else {
        this.autoGenerateDocumentNumber_P = defaultValue;
      }
    }
    return this.autoGenerateDocumentNumber_P;
  }

  get_filterDockModalProductsByFamily_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsByFamily_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'FilterDockModalProductsByFamily');
      if (settingValue != null) {
        this.filterDockModalProductsByFamily_P = settingValue;
      } else {
        this.filterDockModalProductsByFamily_P = defaultValue;
      }
    }
    return this.filterDockModalProductsByFamily_P;
  }

  get_filterDockModalProductsBySubFamily_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsBySubFamily_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'FilterDockModalProductsBySubFamily');
      if (settingValue != null) {
        this.filterDockModalProductsBySubFamily_P = settingValue;
      } else {
        this.filterDockModalProductsBySubFamily_P = defaultValue;
      }
    }
    return this.filterDockModalProductsBySubFamily_P;
  }

  get_filterDockModalProductsBySubSubFamily_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsBySubSubFamily_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'FilterDockModalProductsBySubSubFamily');
      if (settingValue != null) {
        this.filterDockModalProductsBySubSubFamily_P = settingValue;
      } else {
        this.filterDockModalProductsBySubSubFamily_P = defaultValue;
      }
    }
    return this.filterDockModalProductsBySubSubFamily_P;
  }

  get_filterDockModalProductsByCategory_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsByCategory_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'FilterDockModalProductsByCategory');
      if (settingValue != null) {
        this.filterDockModalProductsByCategory_P = settingValue;
      } else {
        this.filterDockModalProductsByCategory_P = defaultValue;
      }
    }
    return this.filterDockModalProductsByCategory_P;
  }

  get_showDigitalFileTab_S(defaultValue: boolean): boolean {
    if (typeof this.showDigitalFileTab_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ShowDigitalFileTab');
      if (settingValue != null) {
        this.showDigitalFileTab_S = settingValue;
      } else {
        this.showDigitalFileTab_S = defaultValue;
      }
    }
    return this.showDigitalFileTab_S;
  }

  get_showMessagesTab_S(defaultValue: boolean): boolean {
    if (typeof this.showMessagesTab_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ShowMessagesTab');
      if (settingValue != null) {
        this.showMessagesTab_S = settingValue;
      } else {
        this.showMessagesTab_S = defaultValue;
      }
    }
    return this.showMessagesTab_S;
  }

  get_showProductsAutomatically_P(defaultValue: boolean): boolean {
    if (typeof this.showProductsAutomatically === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowProductsAutomatically');
      if (settingValue != null) {
        this.showProductsAutomatically = settingValue;
      } else {
        this.showProductsAutomatically = defaultValue;
      }
    }
    return this.showProductsAutomatically;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  get_DetailsColumnInVisible_P(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.detailsColumnInVisible_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('QuotationPurchase', 'ColumVisibleSP');
      this.detailsColumnInVisible_P = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValue.length > 0 ? settingValueSP : settingValue);
    }
    return this.detailsColumnInVisible_P;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  get_DetailsColumnInVisible_S(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.detailsColumnInVisible_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('QuotationSale', 'ColumVisibleSP');
      this.detailsColumnInVisible_S = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValue.length > 0 ? settingValueSP : settingValue);
    }
    return this.detailsColumnInVisible_S;
  }

  get_allowUnblockAllUsers(defaultValue: boolean): boolean {
    if (typeof this.allowUnblockAllUsers === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsPurchases', 'AllowUnblockAllUsers');
      if (settingValue != null) {
        this.allowUnblockAllUsers = settingValue;
      } else {
        this.allowUnblockAllUsers = defaultValue;
      }
    }
    return this.allowUnblockAllUsers;
  }

  get_hideAssignActionsToAnotherUser(defaultValue: boolean): boolean {
    if (typeof this.hideAssignActionsToAnotherUser === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsPurchases', 'HideAssignActionsToAnotherUser');
      if (settingValue != null) {
        this.hideAssignActionsToAnotherUser = settingValue;
      } else {
        this.hideAssignActionsToAnotherUser = defaultValue;
      }
    }
    return this.hideAssignActionsToAnotherUser;
  }

  get_showInstalationNumber_P(defaultValue: boolean): boolean {
    if (typeof this.showInstalationNumber_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowInstalationNumber');
      if (settingValue != null) {
        this.showInstalationNumber_P = settingValue;
      } else {
        this.showInstalationNumber_P = defaultValue;
      }
    }
    return this.showInstalationNumber_P;
  }

  get_showInstalationNumber_S(defaultValue: boolean): boolean {
    if (typeof this.showInstalationNumber_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ShowInstalationNumber');
      if (settingValue != null) {
        this.showInstalationNumber_S = settingValue;
      } else {
        this.showInstalationNumber_S = defaultValue;
      }
    }
    return this.showInstalationNumber_S;
  }

  get_showEquipmentBrand_P(defaultValue: boolean): boolean {
    if (typeof this.showEquipmentBrand_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowEquipmentBrand');
      if (settingValue != null) {
        this.showEquipmentBrand_P = settingValue;
      } else {
        this.showEquipmentBrand_P = defaultValue;
      }
    }
    return this.showEquipmentBrand_P;
  }

  get_showEquipmentBrand_S(defaultValue: boolean): boolean {
    if (typeof this.showEquipmentBrand_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ShowEquipmentBrand');
      if (settingValue != null) {
        this.showEquipmentBrand_S = settingValue;
      } else {
        this.showEquipmentBrand_S = defaultValue;
      }
    }
    return this.showEquipmentBrand_S;
  }

  get_defaultUOMCode(defaultValue: string): string {
    if (typeof this.defaultUOM === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'DefaultUOMCode');
      if (settingValue != null) {
        this.defaultUOM = settingValue;
      } else {
        this.defaultUOM = defaultValue;
      }
    }
    return this.defaultUOM;
  }

  get_hideSupplierNotes_P(defaultValue: boolean, ): boolean {
    if (typeof this.hideSuplierNotes_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'HideSupplierNotes');
      if (settingValue != null) {
        this.hideSuplierNotes_P = settingValue;
      } else {
        this.hideSuplierNotes_P = defaultValue;
      }
    }
    return this.hideSuplierNotes_P;
  }

  get_AllowCreateEmptyDetail_P(defaultValue: boolean, ): boolean {
    if (typeof this.showAllowCreateEmptyDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'AllowCreateEmptyDetail');
      if (settingValue != null) {
        this.showAllowCreateEmptyDetail = settingValue;
      } else {
        this.showAllowCreateEmptyDetail = defaultValue;
      }
    }
    return this.showAllowCreateEmptyDetail;
  }


  get_hideSupplierNotes_S(defaultValue: boolean, ): boolean {
    if (typeof this.hideSuplierNotes_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'HideSupplierNotes');
      if (settingValue != null) {
        this.hideSuplierNotes_S = settingValue;
      } else {
        this.hideSuplierNotes_S = defaultValue;
      }
    }
    return this.hideSuplierNotes_S;
  }

  get_showInstalationFilter_P(defaultValue: boolean): boolean {
    if (typeof this.showInstalationFilter_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsPurchases', 'ShowInstalation');
      if (settingValue != null) {
        this.showInstalationFilter_P = settingValue;
      } else {
        this.showInstalationFilter_P = defaultValue;
      }
    }
    return this.showInstalationFilter_P;
  }
  get_showInstalationFilter_S(defaultValue: boolean): boolean {
    if (typeof this.showInstalationFilter_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationsSales', 'ShowInstalation');
      if (settingValue != null) {
        this.showInstalationFilter_S = settingValue;
      } else {
        this.showInstalationFilter_S = defaultValue;
      }
    }
    return this.showInstalationFilter_S;
  }

  getQuotationSupplierResponse(id: number, context: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetQuotationSupplierResponses?id=' + id + '&saleOrPurchase=' + context).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPDF(ID: number, context:string): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/Getpdf',
      { id: ID, context:context });
  }

  get_showDetailsPrices(defaultValue: boolean): boolean {
    if (typeof this.showDetailsPrices === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowDetailsPrices');
      if (settingValue != null) {
        this.showDetailsPrices = settingValue;
      } else {
        this.showDetailsPrices = defaultValue;
      }
    }
    return this.showDetailsPrices;
  }

  get_showQuantities(defaultValue: boolean): boolean {
    if (typeof this.showQuantities === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowQuantities');
      if (settingValue != null) {
        this.showQuantities = settingValue;
      } else {
        this.showQuantities = defaultValue;
      }
    }
    return this.showQuantities;
  }

  get_hideUnidentifiedProductIcon(defaultValue: boolean): boolean {
    if (typeof this.hideUnidentifiedProductIcon === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'HideUnidentifiedProductIcon');
      if (settingValue != null) {
        this.hideUnidentifiedProductIcon = settingValue;
      } else {
        this.hideUnidentifiedProductIcon = defaultValue;
      }
    }
    return this.hideUnidentifiedProductIcon;
  }

  
  get_hideDocumentDateHours(defaultValue: boolean): boolean {
    if (typeof this.hideDocumentDateHours === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'HideDocumentDateHours');
      if (settingValue != null) {
        this.hideDocumentDateHours = settingValue;
      } else {
        this.hideDocumentDateHours = defaultValue;
      }
    }
    return this.hideDocumentDateHours;
  }

  get_isHeaderEditable(defaultValue: boolean): boolean {
    if (typeof this.isHeaderEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'IsHeaderEditable');
      if (settingValue != null) {
        this.isHeaderEditable = settingValue;
      } else {
        this.isHeaderEditable = defaultValue;
      }
    }
    return this.isHeaderEditable;
  }
  get_showValidBudget(defaultValue: boolean): boolean {
    if (typeof this.showValidBudget === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowValidBudget');
      if (settingValue != null) {
        this.showValidBudget = settingValue;
      } else {
        this.showValidBudget = defaultValue;
      }
    }
    return this.showValidBudget;
  }


  get_setNetUnitPriceAsText(defaultValue: boolean): boolean {
    if (typeof this.setNetUnitPriceAsText === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'SetNetUnitPriceAsText');
      if (settingValue != null) {
        this.setNetUnitPriceAsText = settingValue;
      } else {
        this.setNetUnitPriceAsText = defaultValue;
      }
    }
    return this.setNetUnitPriceAsText;
  }
  
  get_hideRequestDescription(defaultValue: boolean): boolean {
    if (typeof this.hideRequestDescription === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'HideRequestDescription');
      if (settingValue != null) {
        this.hideRequestDescription = settingValue;
      } else {
        this.hideRequestDescription = defaultValue;
      }
    }
    return this.hideRequestDescription;
  }

  get_showDropAreaDocument(defaultValue: boolean): boolean {
    if (typeof this.showDropAreaDocument === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ShowDropAreaDocument');
      if (settingValue != null) {
        this.showDropAreaDocument = settingValue;
      } else {
        this.showDropAreaDocument = defaultValue;
      }
    }
    return this.showDropAreaDocument;
  }
  get_hideDeliveryEarliestAcceptableDate(defaultValue: boolean): boolean {
    if (typeof this.hideDeliveryEarliestAcceptableDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'HideDeliveryEarliestAcceptableDate');
      if (settingValue != null) {
        this.hideDeliveryEarliestAcceptableDate = settingValue;
      } else {
        this.hideDeliveryEarliestAcceptableDate = defaultValue;
      }
    }
    return this.hideDeliveryEarliestAcceptableDate;
  }

  get_hideDeliveryLatestAcceptableDate(defaultValue: boolean): boolean {
    if (typeof this.hideDeliveryLatestAcceptableDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'HideDeliveryLatestAcceptableDate');
      if (settingValue != null) {
        this.hideDeliveryLatestAcceptableDate = settingValue;
      } else {
        this.hideDeliveryLatestAcceptableDate = defaultValue;
      }
    }
    return this.hideDeliveryLatestAcceptableDate;
  }

  get_showDeadline(defaultValue: boolean): boolean {
    if (typeof this.showDeadline === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'ShowDeadline');
      if (settingValue != null) {
        this.showDeadline = settingValue;
      } else {
        this.showDeadline = defaultValue;
      }
    }
    return this.showDeadline;
   }
 
  

  get_hideCodeArticleClient(defaultValue: boolean): boolean {
    if (typeof this.hideCodeArticleClient === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationDetails', 'HideCodeArticleClient');
      if (settingValue != null) {
        this.hideCodeArticleClient = settingValue;
      } else {
        this.hideCodeArticleClient = defaultValue;
      }
    }
    return this.hideCodeArticleClient;
  }

  get_hideCodeArticleProvider(defaultValue: boolean): boolean {
    if (typeof this.hideCodeArticleProvider === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationDetails', 'HideCodeArticleProvider');
      if (settingValue != null) {
        this.hideCodeArticleProvider = settingValue;
      } else {
        this.hideCodeArticleProvider = defaultValue;
      }
    }
    return this.hideCodeArticleProvider;
  }

  get_productDescriptionRequired(defaultValue: boolean): boolean {
    if (typeof this.productDescriptionRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationDetails', 'ProductDescriptionRequired');
      if (settingValue != null) {
        this.productDescriptionRequired = settingValue;
      } else {
        this.productDescriptionRequired = defaultValue;
      }
    }
    return this.productDescriptionRequired;
  }

  get_filterBySpecificUserGroup(defaultValue: string): string {
    if (typeof this.filterBySpecificUserGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterBySpecificUserGroup');
      if (settingValue != null) {
        this.filterBySpecificUserGroup = settingValue;
      } else {
        this.filterBySpecificUserGroup = defaultValue;
      }
    }
    return this.filterBySpecificUserGroup;
  }

  get_showPanelPaymentConditions(defaultValue: boolean): boolean {
    if (typeof this.showPanelPaymentConditions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'ShowPanelPaymentConditions');
      if (settingValue != null) {
        this.showPanelPaymentConditions = settingValue;
      } else {
        this.showPanelPaymentConditions = defaultValue;
      }
    }
    return this.showPanelPaymentConditions;
  }

  get_useSupplierPaymentCondition(defaultValue: boolean): boolean {
    if (typeof this.useSupplierPaymentCondition === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'UseSupplierPaymentCondition');
      if (settingValue != null) {
        this.useSupplierPaymentCondition = settingValue;
      } else {
        this.useSupplierPaymentCondition = defaultValue;
      }
    }
    return this.useSupplierPaymentCondition;
  }

  get_departmentMandatory(defaultValue: boolean): boolean {
    if (typeof this.departmentMandatory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'DepartmentMandatory');
      if (settingValue != null) {
        this.departmentMandatory = settingValue;
      } else {
        this.departmentMandatory = defaultValue;
      }
    }
    return this.departmentMandatory;
  }

  getPURCHASE_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      } else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }

  getPURCHASE_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.redirectOnUpdate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.redirectOnUpdate = settingValue;
      } else {
        this.redirectOnUpdate = defaultValue;
      }
    }
    return this.redirectOnUpdate;
  }

  getSALE_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      } else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }

  getSALE_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.redirectOnUpdate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationSale', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.redirectOnUpdate = settingValue;
      } else {
        this.redirectOnUpdate = defaultValue;
      }
    }
    return this.redirectOnUpdate;
  }

  get_allowInternalMessages_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.allowInternalMessages_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('QuotationPurchase', 'AllowInternalMessages');
      if (settingValue != null) {
        this.allowInternalMessages_PURCHASE = settingValue;
      } else {
        this.allowInternalMessages_PURCHASE = defaultValue;
      }
    }
    return this.allowInternalMessages_PURCHASE;
  }
  /**
   * Devolve os Pedidos de Cotação num determinado Estado
   * @param  {number} stateID - Estado do Pedido de Cotação
   */
  getByStateID(stateID: number, filterByUserGroup?: boolean): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByStateID?stateId=' + stateID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // #endregion Cotação
}
