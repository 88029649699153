// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';
import { CostCenterConfigService } from '../../../../services/costCenterConfig.service';
import { TranslateValueService } from '../../../../services/translate-value.service';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { CURRENCY_DECIMAL } from '../../../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { ModelValidators } from '../../../../models/validators/validators';
import { CostCenterConfig, TransformModelCC } from '../../../../models/costCenterConfig';
import { ReturnStatusHtml } from '../../../../models/returnStatus';
import { Dropdown } from '../../../../models/dropdown';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { DistributionModalParam } from 'src/app/models/distribution-modal-param';

// ***__***_________  MODALS _________ ***__***
import { ChooseModalComponent } from '../../choose-modal/choose-modal.component';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../../../../modules/treatments.module';
import { AnalisysAxes } from 'src/app/models/analisysAxes';
import { CostCenter } from 'src/app/models/costCenter';
import { AccountingConfig, TransformModelAC } from 'src/app/models/accountingConfig';
import { AccountingConfigService } from 'src/app/services/accountingConfig.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { NotesModalComponent } from '../../notes-modal/notes-modal.component';
import { TaxGroupService } from 'src/app/services/tax-group.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './ca-distribution-modal.html'
})
export class CADistributionModalComponent implements OnInit, OnDestroy {

  isDisabled: boolean;
  context: string;
  entityID: number;
  selectedType: string;
  saveOnServer: boolean;
  defaultValue: number;
  documentType: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  accountingConfigList: Array<AccountingConfig>; // listagem principal (todas as configurações de centro de custo)
  costCentersList: Array<any> = new Array<any>(); // listagem de centros de custo

  /* Listas recebidas do componente "pai" */
  analisysAxesList: Array<any> = new Array<any>(); // listagem de eixos de análise
  divisionKeysList: Array<any> = new Array<any>(); // listagem de chaves de divisão
  allCostCentersList: Array<any> = new Array<any>(); // listagem de todos os centros de custo
  // *** fim listas recebidas

  model = new CostCenterConfig();
  form: UntypedFormGroup;
  formAnalisysAxes: UntypedFormGroup;
  submitted: boolean;
  noError: boolean;

  table: any = null;
  idSelec: number;
  currentLanguage: string;
  tranfModelAC = new TransformModelAC(); // necessario para usar as funcoes de configuração de numeros
  cont: number = 0;

  //Settings para a modal da distribuição
  showDistributionExpenseAccount: boolean = true;
  showDistributionInitiativeCode: boolean = true;
  showDistributionActivityCode: boolean = true;
  showDistributionFixedAssetRecordType: boolean = true;
  showDistributionAmortizationBookCode: boolean = true;
  showDistributionVATaccountingGroup: boolean = true;
  showDistributionRefactor: boolean = true;
  showDistributionAccountingType: boolean = true;
  showRetentionCode: boolean = false;
  showAccountingVatGroup: boolean = true;

  enableSaveButton: boolean = false;
  //setting que vai dizer se é possível editar os campos "Conta Gasto" e "Grupo IVA"
  canChangeExpenseAccountTaxGroup: boolean = false;

  invoiceYear: number = null;
  productID: number = null;
  tipologiaProdutoServicoID: number = null;
  vatRate: number = null;
  showLoadAccountingButton: boolean = false;
  showDistributionVatTreatmentType: boolean = true;
  showDistributionCodMensualizacao: boolean = true;

  activityCodesList: Dropdown[];
  initiativeCodesList: Dropdown[];
  fixedAssetRecordTypesList: Dropdown[];
  amortizationBookCodesList: Dropdown[];
  accountingTypesList: Dropdown[];
  expenseAccountsList: Dropdown[];
  productChargeAccountList: Dropdown[];
  immobilizedAccountList: Dropdown[];
  productsAccountList: Dropdown[];
  resourcesAccountList: Dropdown[];
  vatDeductionMethodList: Dropdown[];
  vatGroupsList: Dropdown[];
  monthlyCodesList: Dropdown[];

  changedForm: boolean = false;
  oldFormJson: string;
  enableSavingWhenChangingForm: boolean = false;
  notesWasNull: boolean = false;
  validateFreeTextMaxLength: boolean = false;

  constructor(public dialogRef: MatDialogRef<CADistributionModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private translateService: TranslateService,
    private translateValueService: TranslateValueService,
    private formBuilder: UntypedFormBuilder,
    private accountingConfigService: AccountingConfigService,
    private dialog: MatDialog,
    private _errorTreat: ErrorTreatmentFunctions,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private grupoImpostoService: TaxGroupService,
    private router: Router) {

    // constructor(public dialogRef: MatDialogRef<CADistributionModalComponent>,
    let dataAux: DistributionModalParam = data.distributionModalParam as DistributionModalParam;

    this.currentLanguage = this.translateValueService.translateLanguage;

    // parametros que vai receber
    this.isDisabled = dataAux.IsDisabled;
    this.context = dataAux.Context;
    this.entityID = dataAux.EntityID;
    this.accountingConfigList = dataAux.AccountingConfigs;
    this.saveOnServer = dataAux.SaveOnServer;
    this.defaultValue = +dataAux.DefaultValue;
    this.documentType = dataAux.DocumentType;
    // listas

    this.analisysAxesList = dataAux.AnalisysAxesList;
    this.divisionKeysList = dataAux.DivisionKeysList;
    this.allCostCentersList = dataAux.AllCostCentersList;

    this.showDistributionExpenseAccount = dataAux.showDistributionExpenseAccount;
    this.showDistributionActivityCode = dataAux.showDistributionActivityCode;
    this.showDistributionInitiativeCode = dataAux.showDistributionInitiativeCode;
    this.showDistributionAmortizationBookCode = dataAux.showDistributionAmortizationBookCode;
    this.showDistributionFixedAssetRecordType = dataAux.showDistributionFixedAssetRecordType;
    this.showDistributionVATaccountingGroup = dataAux.showDistributionVATaccountingGroup;
    this.showDistributionRefactor = dataAux.showDistributionRefactor;
    this.showDistributionAccountingType = dataAux.showDistributionAccountingType;
    this.showRetentionCode = dataAux.showRetentionCode;
    this.showAccountingVatGroup = dataAux.showAccountingVatGroup;

    this.enableSaveButton = dataAux.enableSaveButton;
    this.canChangeExpenseAccountTaxGroup = dataAux.canChangeExpenseAccountTaxGroup;
    this.invoiceYear = dataAux.invoiceYear;
    this.tipologiaProdutoServicoID = dataAux.tipologiaProdutoServicoID;
    this.productID = dataAux.productID;
    this.vatRate = dataAux.vatRate;
    this.showLoadAccountingButton = dataAux.showLoadAccountingButton;
    this.showDistributionVatTreatmentType = dataAux.showDistributionVatTreatmentType;
    this.showDistributionCodMensualizacao = dataAux.showDistributionCodMensualizacao;

    this.enableSavingWhenChangingForm = dataAux.enableSavingWhenChangingForm;
    this.validateFreeTextMaxLength = dataAux.validateFreeTextMaxLength;
  }

  ngOnInit(): void {

    let that = this;
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    this.buildForm();
    if (this.enableSavingWhenChangingForm) {
      this.oldFormJson = JSON.stringify(this.form.getRawValue()).replaceAll('"', '');
    }

    if (this.isDisabled) {
      this.form.disable();
    }


    if (this.showDistributionInitiativeCode) {
      this.commonService.getDropdownsList('InitiativeCode', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.initiativeCodesList = response;
        if (!this.initiativeCodesList || (this.initiativeCodesList && this.initiativeCodesList.length === 0)) {
          this.showDistributionInitiativeCode = false;
        }
      });
    }
    if (this.showDistributionFixedAssetRecordType) {
      this.commonService.getDropdownsList('FixedAssetRecordType', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.fixedAssetRecordTypesList = response;
        if (!this.fixedAssetRecordTypesList || (this.fixedAssetRecordTypesList && this.fixedAssetRecordTypesList.length === 0)) {
          this.showDistributionFixedAssetRecordType = false;
        }
      });
    }
    if (this.showDistributionAmortizationBookCode) {
      this.commonService.getDropdownsList('AmortizationBookCode', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.amortizationBookCodesList = response;
        if (!this.amortizationBookCodesList || (this.amortizationBookCodesList && this.amortizationBookCodesList.length === 0)) {
          this.showDistributionAmortizationBookCode = false;
        }
      });
    }
    if (this.showDistributionAccountingType) {
      this.commonService.getDropdownsList('AccountingConfigType', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.accountingTypesList = response;
        if (!this.accountingTypesList || (this.accountingTypesList && this.accountingTypesList.length === 0)) {
          this.showDistributionAccountingType = false;
        }
      });
    }
    if (this.showDistributionExpenseAccount) {
      this.commonService.getDropdownsList('ExpenseAccount', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => this.expenseAccountsList = response);
      this.commonService.getDropdownsList('ProductChargeAccount', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => this.productChargeAccountList = response);
      this.commonService.getDropdownsList('ImmobilizedAccount', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => this.immobilizedAccountList = response);
      this.commonService.getDropdownsList('ProductAccount', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => this.productsAccountList = response);
      this.commonService.getDropdownsList('ResourceAccount', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => this.resourcesAccountList = response);
    }

    if (this.showDistributionVatTreatmentType) {
      this.commonService.getDropdownsList('VatDeductionMethod', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.vatDeductionMethodList = response;
        if (!this.vatDeductionMethodList || (this.vatDeductionMethodList && this.vatDeductionMethodList.length === 0)) {
          this.showDistributionVatTreatmentType = false;
        }
      });
    }
    if (this.showDistributionVATaccountingGroup) {
      this.commonService.getDropdownsList('VatGroup', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.vatGroupsList = response;
        if (!this.vatGroupsList || (this.vatGroupsList && this.vatGroupsList.length === 0)) {
          this.showDistributionVATaccountingGroup = false;
        }
      });
    }

    if (this.showDistributionCodMensualizacao) {
      this.commonService.getDropdownsList('MonthlyCode', false).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.monthlyCodesList = response;
        if (!this.monthlyCodesList || (this.monthlyCodesList && this.monthlyCodesList.length === 0)) {
          this.showDistributionCodMensualizacao = false;
        }
      });
    }
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      'AccountingConfigList': this.formBuilder.array([])
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação

    this.addAccountingConfig(false);
  }

  /* tslint:disable:member-ordering */
  formErrors: Array<string> = new Array<string>();
  formErrorsParam: Array<string> = new Array<string>();
  validationMessages = {
  };


  /* tslint:enable:member-ordering */
  onValueChanged(value?: any) {

    if (!this.form) { return; }
    const form = this.form;

    //DS: Código para verificar se o form foi alterado e desbloquear o botão guardar se assim for           
    if (this.oldFormJson && this.enableSavingWhenChangingForm) {
      this.changedForm = JSON.stringify(this.form.getRawValue()).replaceAll('"', '') !== this.oldFormJson;
      this.changeDetectorRef.detectChanges();
    }

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('AccountingConfigList');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessages[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrors.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsParam.push('');
                  }

                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }

  initAccountingConfig(accountingConfig: AccountingConfig = null) {
    if (accountingConfig) {

      let inicialFixedValue: number = 0;
      let fixedValue = accountingConfig.ValorFixo != null ? ((accountingConfig.ValorFixo).toString()).revertDecimal() : 0;

      if (!this.isDisabled && this.defaultValue > 0 && fixedValue === 0) {
        inicialFixedValue = this.defaultValue;
      } else {
        inicialFixedValue = fixedValue;
      }

      let costCenterName: string = null;
      if (accountingConfig.CentroCusto !== null && this.allCostCentersList !== null && this.allCostCentersList.length > 0) {
        let costCenter = this.allCostCentersList.find(x => x.IntegrationID === accountingConfig.CentroCusto);
        costCenterName = costCenter != null ? costCenter.Name : null;
      }

      return this.formBuilder.group({
        'ID': [{ value: accountingConfig.ID, disabled: !this.enableSaveButton }],
        'Context': [{ value: accountingConfig.Context, disabled: !this.enableSaveButton }],
        'EntityID': [{ value: accountingConfig.EntityID, disabled: !this.enableSaveButton }],
        'Percentagem': [{ value: accountingConfig.Percentagem ? accountingConfig.Percentagem : 100, disabled: !this.enableSaveButton }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'ValorFixo': [{ value: (inicialFixedValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)), disabled: !this.enableSaveButton }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'CentroCusto': [{ value: accountingConfig.CentroCusto, disabled: !this.enableSaveButton }],
        'Refaturar': [{ value: accountingConfig.Refaturar, disabled: !this.enableSaveButton }],
        'Tipo': [{ value: accountingConfig.Tipo ? accountingConfig.Tipo : 'conta', disabled: !this.enableSaveButton && !this.canChangeExpenseAccountTaxGroup }, Validators.required],
        'ContaGasto': [{ value: accountingConfig.ContaGasto, disabled: !this.enableSaveButton && !this.canChangeExpenseAccountTaxGroup }],
        'GrupoIVA': [{ value: accountingConfig.GrupoIVA, disabled: !this.enableSaveButton && !this.canChangeExpenseAccountTaxGroup }],
        'CodAtividade': [{ value: accountingConfig.CodAtividade, disabled: !this.enableSaveButton }],
        'CodIniciativa': [{ value: accountingConfig.CodIniciativa, disabled: !this.enableSaveButton }],
        'TipoRegistoImob': [{ value: accountingConfig.TipoRegistoImob, disabled: !this.enableSaveButton }],
        'CodLivroAmort': [{ value: accountingConfig.CodLivroAmort, disabled: !this.enableSaveButton }],
        'NomeCentroCusto': [{ value: costCenterName, disabled: !this.enableSaveButton }],
        'Observacoes': [accountingConfig.Observacoes],
        'CodGrupoContabilizacao': [{ value: accountingConfig.CodGrupoContabilizacao, disabled: true }],
        'CodRetencao': [accountingConfig.CodRetencao],
        'TipoTratamentoIva': [accountingConfig.TipoTratamentoIva],
        'CodMensualizacao': [accountingConfig.CodMensualizacao]
      });
    } else {
      return this.formBuilder.group({
        'ID': [{ value: 0, disabled: !this.enableSaveButton }],
        'Context': [{ value: null, disabled: !this.enableSaveButton }],
        'EntityID': [{ value: 0, disabled: !this.enableSaveButton }],
        'Percentagem': [{ value: 100, disabled: !this.enableSaveButton }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'ValorFixo': [{ value: (this.defaultValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)), disabled: !this.enableSaveButton }, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'CentroCusto': [{ value: null, disabled: !this.enableSaveButton }],
        'Refaturar': [{ value: false, disabled: !this.enableSaveButton }],
        'Tipo': [{ value: 'conta', disabled: !this.enableSaveButton }, Validators.required],
        'ContaGasto': [{ value: null, disabled: !this.enableSaveButton }],
        'GrupoIVA': [{ value: null, disabled: !this.enableSaveButton }],
        'CodAtividade': [{ value: null, disabled: !this.enableSaveButton }],
        'CodIniciativa': [{ value: null, disabled: !this.enableSaveButton }],
        'TipoRegistoImob': [{ value: null, disabled: !this.enableSaveButton }],
        'CodLivroAmort': [{ value: null, disabled: !this.enableSaveButton }],
        'NomeCentroCusto': [{ value: null, disabled: !this.enableSaveButton }],
        'Observacoes': [null],
        'CodGrupoContabilizacao': [{ value: null, disabled: true }],
        'CodRetencao': [null],
        'TipoTratamentoIva': [null],
        'CodMensualizacao': [null]
      });
    }
  }

  addAccountingConfig(newInsert: boolean) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];
      const newCtrl = this.initAccountingConfig(null);
      control.push(newCtrl);
      newCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChanged(value);
      });

    } else { // inserir os dados que ja estao gravados
      if (this.accountingConfigList != null) {
        for (let accountingConfig of this.accountingConfigList) {
          const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];
          const accountingConfigCtrl = this.initAccountingConfig(accountingConfig);
          control.push(accountingConfigCtrl);
          accountingConfigCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChanged(value);
          });
        }
      }
      //se as quantidades dos produtos forem alteradas depois do produto ser adicionado, temos de forçar a atualização do valor fixo 
      this.recalculate();
    }
  }


  // quando altera percentagem
  //DS: TODO
  onChangePercentage(val: any, positionArrayForm: number) {
    let value = val ? ((val).toString()).revertDecimal() : null; //percentagem

    if (value !== null) { // se for um numero
      const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm];
      (<UntypedFormGroup>controlGroup).controls['ValorFixo'].setValue((this.defaultValue * (value / 100)).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
    } else { // se forem letras ou nada força a por zero
      const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm];
      (<UntypedFormGroup>controlGroup).controls['ValorFixo'].setValue((this.defaultValue * (0 / 100)).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
      (<UntypedFormGroup>controlGroup).controls['Percentagem'].setValue(0);
    }
  }

  // quando altera valor fixo
  //DS: TODO
  onChangeFixedValue(val: any, positionArrayForm: number) {
    let value = val ? ((val).toString()).revertDecimal() : null;

    if (value === null) { // se for string ou vazio
      const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm];
      (<UntypedFormGroup>controlGroup).controls['ValorFixo'].setValue((this.defaultValue * (0 / 100)).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
    } else { //se for um número
      const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm];
      let percentagemAux = ((value * 100) / this.defaultValue);

      if (!Number.isInteger(percentagemAux)) {
        percentagemAux.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL));
      }
      (<UntypedFormGroup>controlGroup).controls['Percentagem'].setValue(percentagemAux);

    }
  }

  // recalcular valores
  //DS: TODO
  recalculate() {
    this.noError = true; // limpar variavel que verifica se há erros
    this.onValueChanged();

    if (this.noError) { // só faz isto se não tiver erros
      const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];
      if (control.getRawValue().length > 0) {

        let sumPercentages = 0;
        let sumFixedValues = 0;

        control.controls.forEach((item: UntypedFormGroup, index: number) => {
          let percentageVal: number = (item.controls['Percentagem'].value);
          let fixedValueVal: number = (item.controls['ValorFixo'].value);

          // conversão para numeros
          let percentage = percentageVal ? ((percentageVal).toString()).revertDecimal() : 0;
          let fixedValue = fixedValueVal ? ((fixedValueVal).toString()).revertDecimal() : 0;

          if (percentage === 0 || percentage === 100) {
            fixedValue = (this.defaultValue).round(2);
          } else {
            fixedValue = ((percentage / 100) * this.defaultValue).round(2);
          }
          item.controls['ValorFixo'].setValue((fixedValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));

          // somar valores das %'s 
          sumPercentages += percentage;

          // somar valores dos valores fixos
          sumFixedValues += fixedValue;
          // retirar ao ultimo fixed value a diferença entre o sum e o defaultValue
          if (sumPercentages === 100 && sumFixedValues !== this.defaultValue) {
            fixedValue -= (sumFixedValues).round(2) - (this.defaultValue).round(2);
            if (fixedValue < 0) {
              item.controls['ValorFixo'].setValue(0);
            } else {
              item.controls['ValorFixo'].setValue((fixedValue).formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
            }
          }
        });
      }
    }
  }

  // copiar linha
  copy(accountingConfigOld: UntypedFormGroup) {
    if (accountingConfigOld) {
      const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];
      const accountingConfig = this.initAccountingConfig(accountingConfigOld.getRawValue());
      accountingConfig.controls['ID'].setValue(0);
      control.push(accountingConfig);
      this.recalculate();
    }
  }

  // remover linha
  remove(i: number) {
    const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];
    control.removeAt(i);
  }

  // guardar lista de distribuições
  onSubmit() {
    this.submitted = true; // necessário saber que está a submeter para no validar não controllar o dirty(alteração)
    this.noError = true; // limpar variavel que verifica se há erros
    this.onValueChanged();
    const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];

    let mandatoryErrors: boolean = false; // limpar erros
    let repeatErrors: boolean = false; // limpar erros

    // Somatórios de valores agrupados por eixo de analise (key=AnalisysAxeID)
    let percentagesSum: number = null;
    let fixeValueSum: number = null;

    let defaultValueFormatted: string = null;

    //o defaultValue não tem a mesma formatação que o valor fixo, por isso vamos formatá-lo com as mesmas casas decimais para comparar
    defaultValueFormatted = this.defaultValue.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL));
    //como a formatação deixa a variável como uma string, vamos transformá-la em number
    let defaultValueNumber: number = defaultValueFormatted.revertDecimal();

    if (control.getRawValue().length > 0) {
      control.controls.forEach((item: UntypedFormGroup, index: number) => {
        let percentageVal: number = (item.controls['Percentagem'].value);
        let fixedValueVal: number = (item.controls['ValorFixo'].value);

        // conversão para numeros
        let percentage = percentageVal ? ((percentageVal).toString()).revertDecimal() : null;
        let fixedValue = fixedValueVal ? ((fixedValueVal).toString()).revertDecimal() : null;

        // Somar as percentagens 
        percentagesSum += percentage ? (percentage).round(2) : 0;

        // Somar os valores fixos 
        fixeValueSum += fixedValue ? (fixedValue).round(2) : 0;
      });

      let errorMsg: string = '';
      if (mandatoryErrors) {
        errorMsg = this.translateValueService.get('LAYOUT.FORM_INVALID') + '<br/>';
      }
      if (repeatErrors) {
        errorMsg += this.translateValueService.get('THERE_ARE_REPEATED_COST_CENTERS') + '<br/>';
      }

      //a variável defaultValueNumber contém o valor do defaultValue, é do tipo Number e está com a mesma formatação que o FixedValue
      if ((percentagesSum > 0 && percentagesSum !== 100) || fixeValueSum !== defaultValueNumber) {
        if (percentagesSum > 0 && percentagesSum !== 100) {
          errorMsg += this.translateValueService.get('PERCENTAGE_SUM_NOT_100_PERCENT', { percentage: percentagesSum.toString() }) + '<br/>';
        }
        if (fixeValueSum !== defaultValueNumber) {
          errorMsg += this.translateValueService.get('FIXED_VALUE_SUM_NOT_TOTAL_VALUE', { fixedValueSum: fixeValueSum.toString(), totalValue: defaultValueNumber.toString() }) + '<br/>';
        }
      }

      if (errorMsg !== '') {
        Functions.gritter(errorMsg, 'danger');
      }

      // Gravar os dados na BD
      if (this.noError && !mandatoryErrors && !repeatErrors && errorMsg === '') { // caso não existam erros
        let that = this;
        let accountingConfigList: Array<AccountingConfig> = this.tranfModelAC.revertObjects(control.getRawValue());


        if (this.context !== '' && this.entityID > 0) {
          this.accountingConfigService.updateAccountingConfig(this.context, this.entityID, accountingConfigList).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if (response.ReturnStatus.Successfull) {
              Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
              this.dialogRef.close(response.ReturnStatus.ReturnObject);
            } else {
              that._errorTreat.treatErrorResponse(response);
            }
          });
        } else {
          this.dialogRef.close(accountingConfigList);
        }
      }
      this.submitted = false;
    }
  }

  // selecionar centro de custo
  //TODO
  selectCostCenter(positionArrayForm: number) {
    //já temos a lista de centros de custo carregada
    this.costCentersList = this.allCostCentersList;

    //chama a modal dos centros de custo
    this.costCentersModal(positionArrayForm);
  }

  // modal centros de custo
  costCentersModal(positionArrayForm: number) {
    // validar inatividade (só mostar os ativos)
    this.costCentersList = this.costCentersList.filter((x: any) => (x.Inactive === false) || x.Inactive === null);

    this.translateService.get(['SELECT_COST_CENTER', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.costCentersList, null, response['SELECT_COST_CENTER'], aoColumns, columnDefs, null, 1, null),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = (+result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.costCentersList.findIndex((r: any) => r.ID === resultID);
            //vamos ao form control buscar o eixo de análise selecionado, para lhe atribuir o valor
            (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm]).controls['CentroCusto'].setValue(this.costCentersList[index].IntegrationID);
            (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm]).controls['NomeCentroCusto'].setValue(this.costCentersList[index].Name);
          }
        }
      });
    });
  }

  // encontrar um valor num determinado array
  findInArray(array: Array<any>, goal: number, keyGoal: string, keyFind: string): any {
    // let result = array.find((a: any) => <string>a[keyGoal] === goal);
    let result = array.find((a: any) => <number>a[keyGoal] === goal);
    return result ? result[keyFind] : '';
  }

  // quando altera ID eixo de análise
  onSelectAnalysysAxe(selectedID: any, positionArrayForm: number) {
    // limpar valor do campo "CostCenterName"
    (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm]).controls['CentroCusto'].setValue('');

    if ((this.analisysAxesList.find((x: any) => x.ID === selectedID))) {
      if ((this.analisysAxesList.find((x: any) => x.ID === selectedID).IsFreeText) === false) { // não pode editar
        /*
         * se é para selecionar pelo iconzinho
         * */
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm]).controls['CentroCusto'].setValue(null);
      } else { // pode editar
        (<UntypedFormGroup>(<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm]).controls['CentroCusto'].setValue(null);
      }
    }
  }

  onChangeRefactor(event: any, positionArrayForm: number) {
    const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[positionArrayForm];
    let codGrupoContabilizacao = (<UntypedFormGroup>controlGroup).controls['CodGrupoContabilizacao'].value;
    let metodoDeducaoIVA = (<UntypedFormGroup>controlGroup).controls['TipoTratamentoIva'].value;
    //Se a checkbox refaturar está checked
    if (event.source.checked) {
      //Vamos buscar à tabela de parâmetros, os parâmetros da empresa logada, e se esta tiver a Conta Gasto e o Grupo IVA definidos, atualizamos os valores
      this.commonService.getCompanyParameterValue(this.authenticationService.session.company.ID, 'ExpenseAccountRefaturar').subscribe((response: string) => {
        if (response != null) {
          (<UntypedFormGroup>controlGroup).controls['ContaGasto'].setValue(response);
        }
      });
      //para já não é para carregar o grupo iva refaturação com os parâmetros da empresa
      this.grupoImpostoService.getGrupoIVARefaturacao(this.authenticationService.session.company.ID, codGrupoContabilizacao, this.vatRate, metodoDeducaoIVA).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          let grupoIVARefaturacao = response.ReturnStatus.ReturnObject.GrupoIVARefaturacao;
          (<UntypedFormGroup>controlGroup).controls['GrupoIVA'].setValue(grupoIVARefaturacao);
        }
      });
    } else {
      (<UntypedFormGroup>controlGroup).controls['ContaGasto'].setValue(null);
      (<UntypedFormGroup>controlGroup).controls['GrupoIVA'].setValue(null);
    }
  }

  /** Mostrar as observações da configuração de contabilização
   * @param  {number} index Índice da configuração
   */
  openNotes(index: number) {
    const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[index];

    if (this.notesWasNull == false) {
      this.notesWasNull = (<UntypedFormGroup>controlGroup).get('Observacoes').value == null
    }

    let dialogRef = this.dialog.open(NotesModalComponent, {
      data: {
        disabled: this.isDisabled || !this.enableSaveButton,
        notes: (<UntypedFormGroup>controlGroup).get('Observacoes').value,
        enableSavingWhenChangingForm: this.enableSavingWhenChangingForm,
        notesWasNull: this.notesWasNull,
        validateNotesMaxLength: this.validateFreeTextMaxLength
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        (<UntypedFormGroup>controlGroup).controls['Observacoes'].setValue(result.Notes);
        this.onValueChanged();
      }
    });
  }

  loadAccounting() {
    this.onValueChanged();
    let companyID = this.authenticationService.session.company.ID;
    const control = <UntypedFormArray>this.form.controls['AccountingConfigList'];
    //se não houver registos, inserimos um novo com os dados que vêm
    if (control != null && control.controls.length == 0) {
      this.accountingConfigService.getAccountingConfig(companyID, this.invoiceYear, this.productID, this.tipologiaProdutoServicoID, this.vatRate, false).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          let accountingConfig: AccountingConfig = null;
          if (response.ReturnStatus.ReturnObject != null) {
            accountingConfig = response.ReturnStatus.ReturnObject;
            const newCtrl = this.initAccountingConfig(accountingConfig);
            control.push(newCtrl);
            newCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
              this.onValueChanged(value);
            });
          }
        } else {
          this._errorTreat.treatErrorResponse(response, false, '/login', this.router.url, true);
        }
      });
    } else {
      //se já houverem registos, vamos percorrê-los e para cada um, vamos buscar os dados e carregá-los na linha
      if (control.getRawValue().length > 0) {
        control.controls.forEach((item: UntypedFormGroup, index: number) => {
          let refaturacao = item.controls['Refaturar'].value;
          this.accountingConfigService.getAccountingConfig(companyID, this.invoiceYear, this.productID, this.tipologiaProdutoServicoID, this.vatRate, refaturacao).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if (response.ReturnStatus.Successfull) {
              let accountingConfig: AccountingConfig = null;
              if (response.ReturnStatus.ReturnObject != null) {
                accountingConfig = response.ReturnStatus.ReturnObject;
                item.controls['CodGrupoContabilizacao'].setValue(accountingConfig.CodGrupoContabilizacao);
                item.controls['GrupoIVA'].setValue(accountingConfig.GrupoIVA);
                item.controls['CodAtividade'].setValue(accountingConfig.CodAtividade);
                item.controls['Tipo'].setValue(accountingConfig.Tipo);
                item.controls['TipoTratamentoIva'].setValue(accountingConfig.TipoTratamentoIva);
                item.controls['CodMensualizacao'].setValue(accountingConfig.CodMensualizacao);

                //para garantir que o onChangeType já foi executado
                setTimeout(() => {
                  //tem de ser alterado depois do tipo porque depende do tipo
                  item.controls['ContaGasto'].setValue(accountingConfig.ContaGasto);
                  this.changeDetectorRef.detectChanges();
                });
              }
            } else {
              this._errorTreat.treatErrorResponse(response, false, '/login', this.router.url, true);
            }
          });
        });
      }
    }
  }

  onChangeAccountType(e: any, index: number) {
    const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[index];
    (<UntypedFormGroup>controlGroup).controls['ContaGasto'].setValue(null);
  }

  onChangeExpenseAccount(e: any, index: number) {
    const controlGroup = (<UntypedFormGroup>this.form.controls['AccountingConfigList']).controls[index];
    let contaGasto = (<UntypedFormGroup>controlGroup).controls['ContaGasto'].value;
    (<UntypedFormGroup>controlGroup).controls['CodAtividade'].setValue(null);
    if (this.showDistributionActivityCode) {
      this.commonService.getDropdownData('ActivityCode', contaGasto).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        this.activityCodesList = response;
      });
    }
  }


  ngOnDestroy() { }
}