import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModelValidators } from '../../models/validators/validators';
import { TranslateValueService } from '../../services/translate-value.service';
import { EmailModalParam } from 'src/app/models/email-modal-param';
import { ProcessesService } from 'src/app/services/processes.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './email-documents-modal.html'
})
export class EmailDocumentsModalComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    values: EmailModalParam = new EmailModalParam();
    submitted: boolean = false;
    noError: boolean = true;
    form: UntypedFormGroup;
    formErrors = {};
    validationMessages = {};
    documents: any;
    Data: EmailModalParam;
    checkboxValues: any = [];
    originalIds: any = [];
    copyIds: any = [];
    id: number;
    processNumber: string = '';

    constructor(public dialogRef: MatDialogRef<EmailDocumentsModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _translateValueService: TranslateValueService,
        private _cdr: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private processesService: ProcessesService) {
        this.Data = data.data;
        this.id = data.id;
        this.processNumber = data.processNumber;
        this.validationMessages = {
            'Emails': {
                'required': 'FIELD_REQUIRED_'
            }
        }
    }

    ngOnInit(): void {
        this.processesService.getProcessDocuments(this.id).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if(response.ReturnStatus.Successfull){
                this.documents = response.ReturnStatus.ReturnObject.aaData;
                if (this.documents != null) {
                    for (let i = 0; i < this.documents.length; i++) {
                        this.checkboxValues.push({ id: this.documents[i].ID, checked: true });
                        if(this.documents[i].DocumentVersion > 1){
                            this.copyIds.push(this.documents[i].ID);
                        }
                        if(this.documents[i].DocumentVersion === 1){
                            this.originalIds.push(this.documents[i].ID);
                        }
                    }
                }
            }
        });
        this.values.Subject_params = this.Data && this.Data.Subject_params && this.Data.Has_subject ? this.Data.Subject_params : null;
        this.values.Subject = this.Data && this.Data.Subject && this.Data.Has_subject ? this._translateValueService.translatePipe(this.Data.Subject, this.values.Subject_params, this._cdr) : '';
        this.values.Emails = this.Data && this.Data.Emails ? this.Data.Emails : '';
        this.buildForm();
    }

    buildForm(): void {
        this.form = this._formBuilder.group({
            'Subject': [this.values.Subject],
            'Emails': [this.values.Emails, Validators.compose([Validators.required])]
        });
        if (this.data && this.data.Has_subject) { // só se tiver assunto é que vai ter a validação de ser obrigatorio
            let subject_control = this.form.get('Subject');
            subject_control.setValidators([Validators.compose([Validators.required])]);
            subject_control.updateValueAndValidity();
        }
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChanged(value);
        }); // deteta se houve alterações no form
        this.onValueChanged(); // (re)set validation messages now
    }

    onValueChanged(value?: any) {
        if (!this.form) { return; }
        const form = this.form;
        for (const field in this.validationMessages) {
            if (this.validationMessages.hasOwnProperty(field)) {
                // clear previous error message (if any)
                this.formErrors[field] = '';
                const control = form.get(field);
                if ((this.submitted && (control && !control.valid && control.enabled)) ||
                    (!this.submitted && (control && control.dirty && !control.valid))
                ) {
                    this.noError = false;
                    const messages = this.validationMessages[field];
                    for (const key in control.errors) {
                        if (messages.hasOwnProperty(key)) {
                            this.formErrors[field] = messages[key] + ' ';
                            control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                        }
                    }
                }
            }
        }
    } // fim onValueChanged

    save() {
        this.submitted = true;
        this.noError = true;
        this.onValueChanged(this.form.valueChanges);

        if (this.noError) {
            this.dialogRef.close({emails: this.form.getRawValue(), originalIds: this.originalIds, copyIds: this.copyIds});
            return;
        }
    }

    isChecked(id: number): boolean {
        let value = false;
        let BreakException = {};
        try {
            this.checkboxValues.forEach((item, index) => {
                if (item != null) {
                    if (item.id === id) {
                        value = item.checked;
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            // OUT OF FOREACH
        }
        return value;
    }

    toggleCheck(document: any): void {
        let BreakException = {};
        try {
            this.checkboxValues.forEach((item, index) => {
                if (item != null) {
                    if (item.id === document.ID) {
                        item.checked = !item.checked;
                        if (item.checked) {
                            let indexO = this.originalIds.indexOf(document.ID);
                            let indexC = this.copyIds.indexOf(document.ID);

                            if(indexC === null && document.DocumentVersion > 1){
                                this.copyIds.push(document.ID);
                            }
                            if(indexO === null && document.DocumentVersion === 1){
                                this.originalIds.push(document.ID)
                            }
                            
                        } else {
                            let indexO = this.originalIds.indexOf(document.ID);
                            let indexC = this.copyIds.indexOf(document.ID);
                            if (indexO > -1) {
                                this.originalIds.splice(indexO, 1);
                            }
                            if(indexC > -1) {
                                this.copyIds.splice(indexC, 1);
                            }
                        }
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            // OUT OF FOREACH
        }
    }

    ngOnDestroy() { }
}