export class GenericFieldExtension {
  constructor(
    /// Identificador interno do registo (PK)
    public ID: number,

    /// Referência
    public ReferenceID: number,

    /// Código que identifica o contexto dos campos
    public Context: string,

    /// Valor do campo/lista extendidas
    public FieldValue: string,

    /// Código que identifica uma lista de valores associados
    public ListCode?: string,

    /// Código que identifica um valor associado
    public FieldCode?: string,

    /// Lingua a que corresponde o valor
    public LanguageCode?: string,

    /// Identificador que permite ter uma lista de campos dinâmicos por cada valor para a mesma entidade (diferentes owners)
    public Identifier?: string,

    // Nome do campo
    public FieldName: string = null,

    // Subcontexto da configuração do campo associado
    public SubContext: string = null,

    public AddressName: string = null

  ) { }
}

export enum FieldExtensionContext  {
  PARTNER    = 'Partner',
  COMPANY    = 'Company',
  ALTICEFINE = 'AlticeFine'
}

export enum FieldExtensionCode {
  TAX_AREA    = 'TaxArea',
  MARKET_TYPE = 'MarketType',
}


