
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { Script } from '../models/script';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { CIMConfiguration } from '../models/cim-configuration';
import { cloneAbstractControl } from '../constants/functions-angular';
import { GenericFieldType } from '../models/generic-field-config';

export class ScriptFieldType {
  constructor(
    public Code: number,
    public Name: string,
    public HasValue: boolean
  ) { }
}

export const EVENT_ON_CHANGE: string = 'OnChange';
export const EVENT_ON_SUBMIT: string = 'OnSubmit';
export const EVENT_ON_PAGE_LOAD: string = 'OnPageLoad';

export enum ConditionOP {
  and = 0,
  or = 1
}

export enum Format {
  upper = 0,
  lower = 1
}

export enum ConditionConditionType {
  FieldHasValue = 0,
  FieldIsEmpty = 1,
  FieldValueEqualTo = 2,
  FieldValueNotEqualTo = 3,
  FieldValueContains = 4,
  ListContainsValue = 5
}

export enum ActionActionType {
  SetRequired = 0,
  SetOptional = 1,
  SetEnabled = 2,
  SetDisabled = 3,
  SetValue = 4,
  SetVisible = 5,
  SetHidden = 6,
  FilterList = 7,
  CopyValue = 8
}

export const ConditionTypes: ScriptFieldType[] = [
  new ScriptFieldType(ConditionConditionType.FieldHasValue, 'FIELD_HAS_VALUE', false),
  new ScriptFieldType(ConditionConditionType.FieldIsEmpty, 'FIELD_IS_EMPTY', false),
  new ScriptFieldType(ConditionConditionType.FieldValueEqualTo, 'FIELD_VALUE_EQUAL_TO', true),
  new ScriptFieldType(ConditionConditionType.FieldValueNotEqualTo, 'FIELD_VALUE_NOT_EQUAL_TO', true),
  new ScriptFieldType(ConditionConditionType.FieldValueContains, 'FIELD_VALUE_CONTAINS', true),
  new ScriptFieldType(ConditionConditionType.ListContainsValue, 'LIST_CONTAINS_VALUE', true)
];

export const ActionTypes: ScriptFieldType[] = [
  new ScriptFieldType(ActionActionType.SetRequired, 'SET_REQUIRED', false),
  new ScriptFieldType(ActionActionType.SetOptional, 'SET_OPTIONAL', false),
  new ScriptFieldType(ActionActionType.SetEnabled, 'SET_ENABLED', false),
  new ScriptFieldType(ActionActionType.SetDisabled, 'SET_DISABLED', false),
  new ScriptFieldType(ActionActionType.SetValue, 'SET_VALUE', true),
  new ScriptFieldType(ActionActionType.SetVisible, 'SET_VISIBLE', false),
  new ScriptFieldType(ActionActionType.SetHidden, 'SET_HIDDEN', false),
  new ScriptFieldType(ActionActionType.FilterList, 'FILTER_LIST', true),
  new ScriptFieldType(ActionActionType.CopyValue, 'COPY_VALUE', true)
];

@Injectable()
export class ScriptService {

  private _controller: string = 'Script';

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[1, 'asc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService) { }

  get(id: number, context: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&context=' + context).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: Script): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', { entity: model }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: Script): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', { entity: model }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number, context: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id + '&context=' + context).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Funções de usabilidade dos scripts
  public applyScripting(that: any, form: UntypedFormGroup, scripting: any, eventType: string, configs: any[], typeID: number, stateID: number, roles: number[], dataFilters: string[][]): any {
    this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
    try {
      scripting.Conditions.forEach(conditionsBlock => {
        conditionsBlock.Condition.forEach(condition => {
          if (condition.FieldCode) {
            form.get(condition.FieldCode.Value).valueChanges.pipe(takeUntil(componentDestroyed(that))).subscribe(() => {
              this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
            });
          }

          if (condition.FormArray) {
            const control = <UntypedFormArray>form.controls[condition.FormArray.FormCode];
            // Aplica a todos os campos com aquele nome
            control.controls.filter(r => r.get(condition.FormArray.FormFieldCode.LineIdentifier)).forEach(formGroup => {
              // Campo
              formGroup.get(condition.FormArray.FormFieldCode.Value).valueChanges.pipe(takeUntil(componentDestroyed(that))).subscribe(() => {
                this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
              });
              // Campo identificador
              formGroup.get(condition.FormArray.FormFieldCode.LineIdentifier).valueChanges.pipe(takeUntil(componentDestroyed(that))).subscribe(() => {
                this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
              });
            });
          }
        });
      });
    } catch (e) { }
  }

  // Funções de usabilidade dos scripts de um campo específico de um FormArray
  public applyScriptingFormArray(that: any, form: UntypedFormGroup, scripting: any, eventType: string, configs: any[], typeID: number, stateID: number, roles: number[],
    dataFilters: string[][], formGroup: UntypedFormGroup, formArrayName: string): any {
    try {
      scripting.Conditions.forEach(conditionsBlock => {
        conditionsBlock.Condition.forEach(condition => {
          // Verifica se é um FormArray e se é a lista pretendida
          if (condition.FormArray && condition.FormArray.FormCode === formArrayName) {

            // Campo
            let formControl: UntypedFormControl = <UntypedFormControl>formGroup.get(condition.FormArray.FormFieldCode.Value);
            // Verifica se existe o campo na linha do FormArray
            if (formControl) {
              this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
              formGroup.get(condition.FormArray.FormFieldCode.Value).valueChanges.pipe(takeUntil(componentDestroyed(that))).subscribe(() => {
                this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
              });
            }

            // Campo identificador
            formControl = <UntypedFormControl>formGroup.get(condition.FormArray.FormFieldCode.LineIdentifier);
            // Verifica se existe o campo na linha do FormArray
            if (formControl) {
              formGroup.get(condition.FormArray.FormFieldCode.LineIdentifier).valueChanges.pipe(takeUntil(componentDestroyed(that))).subscribe(() => {
                this.executeScripting(form, scripting, eventType, configs, typeID, stateID, roles, dataFilters);
              });
            }

          }
        });
      });
    } catch (e) { }
  }

  public executeScripting(form: UntypedFormGroup, scripting: any, eventType: string, configs: any[], typeID: number, stateID: number, roles: number[], dataFilters: string[][]): any {
    let conditionBuild: string = '';

    try {
      if (eventType !== EVENT_ON_PAGE_LOAD) {
        if (scripting.Conditions) {
          scripting.Conditions.forEach(conditionsBlock => {
            if (conditionsBlock.OpSpecified && conditionsBlock.Op === ConditionOP.and) {
              conditionBuild += ' && ';
            } else if (conditionsBlock.OpSpecified && conditionsBlock.Op === ConditionOP.or) {
              conditionBuild += ' || ';
            }

            conditionBuild += '(';

            conditionsBlock.Condition.forEach(condition => {
              if (condition.OpSpecified && condition.Op === ConditionOP.and) {
                conditionBuild += ' && ';
              } else if (condition.OpSpecified && condition.Op === ConditionOP.or) {
                conditionBuild += ' || ';
              }

              conditionBuild += this.setCondition(form, condition);
            });

            conditionBuild += ')';
          });
        } else {
          conditionBuild = 'true';
        }
      }

      switch (eventType) {
        case EVENT_ON_CHANGE:
          // tslint:disable-next-line:no-eval
          if (Boolean(eval(conditionBuild))) {
            scripting.TrueActions.forEach(action => {
              if (configs.findIndex(r => ((action.FieldCode && action.FieldCode.Value === r.FieldCode) || (action.FormArray && action.FormArray.FormFieldCode.LineIdentifier === r.FieldCode))
                && (r.Settings.Visible.AlwaysTrue
                  || ((r.Settings.Visible.AllTypes || r.Settings.Visible.Types.indexOf(typeID) !== -1)
                    && (r.Settings.Visible.AllStates || r.Settings.Visible.States.indexOf(stateID) !== -1)
                    && (r.Settings.Visible.AllRoles || r.Settings.Visible.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
                && (r.Settings.Editable.AlwaysTrue
                  || ((r.Settings.Editable.AllTypes || r.Settings.Editable.Types.indexOf(typeID) !== -1)
                    && (r.Settings.Editable.AllStates || r.Settings.Editable.States.indexOf(stateID) !== -1)
                    && (r.Settings.Editable.AllRoles || r.Settings.Editable.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
              ) !== -1) {
                this.setAction(form, eventType, action, dataFilters, configs);
              }
            });
          } else {
            scripting.FalseActions.forEach(action => {
              if (configs.findIndex(r => ((action.FieldCode && action.FieldCode.Value === r.FieldCode) || (action.FormArray && action.FormArray.FormFieldCode.LineIdentifier === r.FieldCode))
                && (r.Settings.Visible.AlwaysTrue
                  || ((r.Settings.Visible.AllTypes || r.Settings.Visible.Types.indexOf(typeID) !== -1)
                    && (r.Settings.Visible.AllStates || r.Settings.Visible.States.indexOf(stateID) !== -1)
                    && (r.Settings.Visible.AllRoles || r.Settings.Visible.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
                && (r.Settings.Editable.AlwaysTrue
                  || ((r.Settings.Editable.AllTypes || r.Settings.Editable.Types.indexOf(typeID) !== -1)
                    && (r.Settings.Editable.AllStates || r.Settings.Editable.States.indexOf(stateID) !== -1)
                    && (r.Settings.Editable.AllRoles || r.Settings.Editable.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
                // && (r.Settings.Required.AlwaysTrue
                //   || ((r.Settings.Required.AllTypes || r.Settings.Required.Types.indexOf(typeID) !== -1)
                //     && (r.Settings.Required.AllStates || r.Settings.Required.States.indexOf(stateID) !== -1)
                //     && (r.Settings.Required.AllRoles || r.Settings.Required.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
                && ((!r.Settings.Required.AlwaysTrue
                  && ((!r.Settings.Required.AllTypes && r.Settings.Required.Types.indexOf(typeID) === -1)
                    && (!r.Settings.Required.AllStates && r.Settings.Required.States.indexOf(stateID) === -1)
                    && (!r.Settings.Required.AllRoles && r.Settings.Required.Roles.findIndex(val => roles.indexOf(val) !== -1) === -1))) || action.ActionType !== ActionActionType.SetOptional)
              ) !== -1) {
                this.setAction(form, eventType, action, dataFilters, configs);
              }
            });
          }
          break;
        case EVENT_ON_SUBMIT:
          // tslint:disable-next-line:no-eval
          return Boolean(eval(conditionBuild));
        case EVENT_ON_PAGE_LOAD:
          scripting.TrueActions.forEach(action => {
            if (configs.findIndex(r => ((action.FieldCode && action.FieldCode.Value === r.FieldCode) || (action.FormArray && action.FormArray.FormCode === r.FieldCode))
              && (r.Settings.Visible.AlwaysTrue
                || ((r.Settings.Visible.AllTypes || r.Settings.Visible.Types.indexOf(typeID) !== -1)
                  && (r.Settings.Visible.AllStates || r.Settings.Visible.States.indexOf(stateID) !== -1)
                  && (r.Settings.Visible.AllRoles || r.Settings.Visible.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
              && (r.Settings.Editable.AlwaysTrue
                || ((r.Settings.Editable.AllTypes || r.Settings.Editable.Types.indexOf(typeID) !== -1)
                  && (r.Settings.Editable.AllStates || r.Settings.Editable.States.indexOf(stateID) !== -1)
                  && (r.Settings.Editable.AllRoles || r.Settings.Editable.Roles.findIndex(val => roles.indexOf(val) !== -1) !== -1)))
            ) !== -1) {
              this.setAction(form, eventType, action, dataFilters, configs);
            }
          });
          break;
      }
    } catch { }
  }

  private setCondition(form: UntypedFormGroup, condition: any): boolean {
    switch (condition.ConditionType) {
      case ConditionConditionType.FieldHasValue:
        return form.get(condition.FieldCode.Value).value ? true : false;
      case ConditionConditionType.FieldIsEmpty:
        return form.get(condition.FieldCode.Value).value ? false : true;
      case ConditionConditionType.FieldValueEqualTo:
        if (condition.FieldCode) {
          return (form.get(condition.FieldCode.Value).value + '') === condition.FieldValue;
        } else if (condition.FormArray) {
          const control = <UntypedFormArray>form.controls[condition.FormArray.FormCode];

          let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(condition.FormArray.FormFieldCode.LineIdentifier).value + '') === condition.FormArray.FormFieldCode.LineIdentifierValue);
          // let formGroup: FormGroup = <FormGroup>control.controls.find((r: FormGroup) => {
          //   console.log(condition.FormArray.FormFieldCode.LineIdentifier);
          //   console.log(r.get(condition.FormArray.FormFieldCode.LineIdentifier));
          //   return (r.get(condition.FormArray.FormFieldCode.LineIdentifier).value + '') === condition.FormArray.FormFieldCode.LineIdentifierValue;
          // });

          if (formGroup) {
            return (formGroup.get(condition.FormArray.FormFieldCode.Value).value + '') === condition.FieldValue;
          } else {
            return false;
          }
        }
        return false;
      case ConditionConditionType.FieldValueNotEqualTo:
        if (condition.FieldCode) {
          return (form.get(condition.FieldCode.Value).value + '') !== condition.FieldValue;
        } else if (condition.FormArray) {
          const control = <UntypedFormArray>form.controls[condition.FormArray.FormCode];

          let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(condition.FormArray.FormFieldCode.LineIdentifier).value + '') === condition.FormArray.FormFieldCode.LineIdentifierValue);

          if (formGroup) {
            return (formGroup.get(condition.FormArray.FormFieldCode.Value).value + '') !== condition.FieldValue;
          } else {
            return false;
          }
        }
        return false;
      case ConditionConditionType.FieldValueContains:
        let values = condition.FieldValue.split(';');
        let result = false;

        if (condition.FieldCode) {
          // return (form.get(condition.FieldCode.Value).value + '').indexOf(condition.FieldValue) !== -1;
          values.forEach(value => {
            if (!result) {
              result = (form.get(condition.FieldCode.Value).value + '').search(new RegExp(value, 'g')) !== -1;
            }
          });
        } else if (condition.FormArray) {
          const control = <UntypedFormArray>form.controls[condition.FormArray.FormCode];

          let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(condition.FormArray.FormFieldCode.LineIdentifier).value + '') === condition.FormArray.FormFieldCode.LineIdentifierValue);

          if (formGroup) {
            // return (formGroup.get(condition.FormArray.FormFieldCode.Value).value + '').indexOf(condition.FieldValue) !== -1;
            values.forEach(value => {
              if (!result) {
                result = (formGroup.get(condition.FormArray.FormFieldCode.Value).value + '').search(new RegExp(value, 'g')) !== -1;
              }
            });
          }
        }
        return result;
      case ConditionConditionType.ListContainsValue:
        if (condition.FieldCode) {
          return true;
        } else if (condition.FormArray) {
          const control = <UntypedFormArray>form.controls[condition.FormArray.FormCode];

          return control.controls.find((r: UntypedFormGroup) => (r.get(condition.FormArray.FormFieldCode.Value).value + '') === condition.FieldValue) ? true : false;
        }
    }
  }

  private setAction(form: UntypedFormGroup, eventType: string, action: any, dataFilters: string[][], configs: any[]): void {
    switch (action.ActionType) {
      case ActionActionType.SetRequired:
        form.get(action.FieldCode.Value).setValidators([Validators.required]);
        if (eventType !== EVENT_ON_PAGE_LOAD) {
          form.get(action.FieldCode.Value).markAsTouched();
        }
        form.get(action.FieldCode.Value).updateValueAndValidity();
        break;
      case ActionActionType.SetOptional:
        form.get(action.FieldCode.Value).clearValidators();
        form.get(action.FieldCode.Value).updateValueAndValidity();
        break;
      case ActionActionType.SetEnabled:
        form.get(action.FieldCode.Value).enable();
        form.get(action.FieldCode.Value).updateValueAndValidity();
        break;
      case ActionActionType.SetDisabled:
        form.get(action.FieldCode.Value).disable();
        form.get(action.FieldCode.Value).updateValueAndValidity();
        break;
      case ActionActionType.SetValue:
        if (action.FieldValue) {
          if (action.FieldCode) {
            // Devolve a configuração do campo
            let config = configs.find(r => r.FieldCode === action.FieldCode.Value);

            form.get(action.FieldCode.Value).setValue(config ? this.convertFormat(action.FieldValue, config.FieldType) : action.FieldValue);
            form.get(action.FieldCode.Value).updateValueAndValidity();
          } else if (action.FormArray) {
            const control = <UntypedFormArray>form.controls[action.FormArray.FormCode];

            let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(action.FormArray.FormFieldCode.LineIdentifier).value + '') === action.FormArray.FormFieldCode.LineIdentifierValue);

            // Se não existir o conjunto, cria um conjunto vazio para atribuir o novo valor
            if (!formGroup) {
              formGroup = cloneAbstractControl(control.at(0)) as UntypedFormGroup;
              formGroup.reset();

              control.push(formGroup);
            }

            // Devolve a configuração do campo
            let config = configs.find(r => r.FieldCode === action.FormArray.FormFieldCode.Value);

            formGroup.get(action.FormArray.FormFieldCode.Value).setValue(config ? this.convertFormat(action.FieldValue, config.FieldType) : action.FieldValue);
            formGroup.get(action.FormArray.FormFieldCode.Value).updateValueAndValidity();
          }
        } else if (!action.FieldValue) {
          let value: string = '';
          action.SourceField.forEach(sourceField => {
            // se tiver o atributo Prefix
            if (sourceField.Prefix) {
              value += sourceField.Prefix;
            }

            let sourceFieldValue: string;

            if (sourceField.FieldCode && sourceField.StartIndex && sourceField.Length && sourceField.FieldCode.Value.length >= (+sourceField.StartIndex.Value + +sourceField.Length.Value)) {
              sourceFieldValue += form.get(sourceField.FieldCode.Value).value.substring(+sourceField.StartIndex.Value, +sourceField.Length.Value);
            } else {
              sourceFieldValue = form.get(sourceField.FieldCode.Value).value;
            }

            if (sourceField.Format && sourceField.Format.Value === Format.upper) {
              sourceFieldValue = sourceFieldValue.toUpperCase();
            } else if (sourceField.Format && sourceField.Format.Value === Format.lower) {
              sourceFieldValue = sourceFieldValue.toLowerCase();
            }

            value += sourceFieldValue;

            // se tiver o atributo Suffix
            if (sourceField.Suffix) {
              value += sourceField.Suffix;
            }
          });

          // Devolve a configuração do campo
          let config = configs.find(r => r.FieldCode === action.FieldCode.Value);

          form.get(action.FieldCode.Value).setValue(config ? this.convertFormat(value, config.FieldType) : value);
          form.get(action.FieldCode.Value).updateValueAndValidity();
        }
        break;
      case ActionActionType.SetVisible:
        if (action.FieldCode) {
          form.get(action.FieldCode.Value).enable();
          form.get(action.FieldCode.Value).updateValueAndValidity();
        } else if (action.FormArray) {
          const control = <UntypedFormArray>form.controls[action.FormArray.FormCode];

          let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(action.FormArray.FormFieldCode.LineIdentifier).value + '') === action.FormArray.FormFieldCode.LineIdentifierValue);

          // Se não existir o conjunto, ignora
          if (formGroup) {
            formGroup.get(action.FormArray.FormFieldCode.Value).enable();
            formGroup.get(action.FormArray.FormFieldCode.Value).updateValueAndValidity();
          }
        }
        break;
      case ActionActionType.SetHidden:
        if (action.FieldCode) {
          form.get(action.FieldCode.Value).disable();
          form.get(action.FieldCode.Value).setValue(null);
          form.get(action.FieldCode.Value).updateValueAndValidity();
        } else if (action.FormArray) {
          const control = <UntypedFormArray>form.controls[action.FormArray.FormCode];

          let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(action.FormArray.FormFieldCode.LineIdentifier).value + '') === action.FormArray.FormFieldCode.LineIdentifierValue);

          // Se não existir o conjunto, ignora
          if (formGroup) {
            formGroup.get(action.FormArray.FormFieldCode.Value).disable();
            formGroup.get(action.FormArray.FormFieldCode.Value).setValue(null);
            formGroup.get(action.FormArray.FormFieldCode.Value).updateValueAndValidity();
          }
        }
        break;
      case ActionActionType.FilterList:
        if (action.FieldCode) {
          dataFilters[action.FieldCode.Value] = action.FieldValue;
        } else if (action.FormArray) {
          dataFilters[action.FormArray.FormFieldCode.Value] = action.FieldValue;
        }
        break;
      case ActionActionType.CopyValue:
        if (action.FieldCode) {
          form.get(action.FieldCode.Value).setValue(form.get(action.FieldValue).value);
          form.get(action.FieldCode.Value).updateValueAndValidity();
        } else if (action.FormArray) {
          const control = <UntypedFormArray>form.controls[action.FormArray.FormCode];

          let formGroup: UntypedFormGroup = <UntypedFormGroup>control.controls.find((r: UntypedFormGroup) => (r.get(action.FormArray.FormFieldCode.LineIdentifier).value + '') === action.FormArray.FormFieldCode.LineIdentifierValue);

          // Se não existir o conjunto, cria um conjunto vazio para atribuir o novo valor
          if (!formGroup) {
            formGroup = cloneAbstractControl(control.at(0)) as UntypedFormGroup;
            formGroup.reset();

            control.push(formGroup);
          }

          formGroup.get(action.FormArray.FormFieldCode.Value).setValue(form.get(action.FieldValue).value);
          formGroup.get(action.FormArray.FormFieldCode.Value).updateValueAndValidity();
        }
        break;
    }
  }
  // FIM - Funções de usabilidade dos scripts

  // Função de conversão de string para o tipo correto
  private convertFormat(value: string, type: string): any {
    switch (type) {
      case GenericFieldType.BOOLEAN:
        return value === 'True' || value === 'true';
      case GenericFieldType.NUMBER:
      case GenericFieldType.STRING:
      default:
        return value;
    }
  }
}
