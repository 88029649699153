import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { ChooseModalComponent } from '../choose-modal/choose-modal.component';
import { UserService } from 'src/app/services/user.service';
import { CompanyService } from 'src/app/services/company.service';
import { PartnerIBAN } from 'src/app/models/partner-iban';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'iban-selector',
    templateUrl: './iban-selector.component.html',
    styles: [
    ]
})
export class IbanSelectorComponent implements ControlValueAccessor, OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();

    partnerIbans: PartnerIBAN[];
    selectedPartnerIban: PartnerIBAN;

    disabled: boolean;

    /** Empresa Owner (se não tiver valor vai usar a empresa da sessão) */
    @Input() ownerID: number;

    /** Empresa Partner (se não tiver valor vai apenas vai buscar os IBANs da empresa Owner) */
    @Input() partnerID: number = null;

    constructor(private _userService: UserService, private _companyService: CompanyService, @Optional() public ngControl: NgControl, private _translateService: TranslateService, private _dialog: MatDialog, private _authenticationService: AuthenticationService) {
        ngControl.valueAccessor = this;
    }

    async ngOnInit(): Promise<void> {
        // se tiver desativado o form (consulta) so vai buscar o registo guardado caso constrario vai buscar todos
        if (!this.partnerIbans && !this.disabled)
            await this.getPartnerIbans();

        if (this.selectedPartnerIban?.IBAN != null || this.selectedPartnerIban?.IBAN != undefined) {
            if (this.disabled) {
                const response = await this._companyService.getIbanByIban(this.selectedPartnerIban.IBAN, this.ownerID ?? this._authenticationService.session.company.ID, this.partnerID).toPromise();

                if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject)
                    this.selectedPartnerIban = response.ReturnStatus.ReturnObject;
            }
            else {
                this.selectedPartnerIban = this.partnerIbans.find(pc => pc.IBAN === this.selectedPartnerIban.IBAN);
            }
        }
    }

    async getPartnerIbans() {
        this.partnerIbans = await this._companyService.getIbans(this.ownerID ?? this._authenticationService.session.company.ID, this.partnerID).toPromise();

        if (!this.ngControl.control.value)
            this.setDefaultIban();
    }

    selectPartnerIban() {
        this._translateService.get(['SELECT_IBAN', 'IBAN', 'OBSERVATIONS', 'BY_DEFAULT']).subscribe(response => {
            let aoColumns = [
                { 'data': 'ID' },
                { 'data': 'IBAN', 'class': 'verticalMiddle', 'title': response['IBAN'] },
                { 'data': 'Observations', 'class': 'verticalMiddle', 'title': response['OBSERVATIONS'] },
                {
                    'data': 'isDefault', 'class': 'verticalMiddle text-center', 'title': response['BY_DEFAULT'],
                    'render': function (data: boolean, type: string, row: any, meta: any) {
                        return (data === true) ? '<span class="glyphicon glyphicon-ok"></span>' : '';
                    }
                },
            ];

            let columnDefs = [
                { 'targets': [0], 'visible': false }, // colocar como hidden
                { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
            ];

            const dialogRef = this._dialog.open(ChooseModalComponent, {
                data: new ChooseModalParam(this.partnerIbans, null, response['SELECT_IBAN'], aoColumns, columnDefs, null, 1, null),
                disableClose: true // nao permitir fechar modal com escape ou clique fora
            });

            dialogRef.afterClosed().subscribe((id: number) => {
                if (id) {
                    const found = this.partnerIbans.find(pc => pc.ID === id);
                    if (found) {
                        this.selectedPartnerIban = found;
                        this.ngControl.control.setValue(found.IBAN);
                    }
                }
            });
        });
    }

    setDefaultIban() {
        if (!this.partnerIbans || this.partnerIbans.length === 0)
            return;

        const defaultIban = this.partnerIbans.find(({ isDefault }) => isDefault);

        // se nao foi encontrado e o tamanho do array for 1 selecionar esse por defeito 
        if (!defaultIban) {
            if (this.partnerIbans.length === 1)
                this.ngControl.control.setValue(this.partnerIbans[0].IBAN);

            return;
        }

        this.ngControl.control.setValue(defaultIban.IBAN);
    }

    onChanged: (paymentConditionID: number) => void;
    onTouched: () => void;

    async writeValue(iban: string): Promise<void> {
        if (iban) {
            if (this.partnerIbans)
                this.selectedPartnerIban = this.partnerIbans?.find(pc => pc.IBAN === iban);
            else
                this.selectedPartnerIban = new PartnerIBAN(null, iban);
        } else if (iban == null) {
            this.selectedPartnerIban = null;
        }
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
