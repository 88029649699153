import { Directive, Optional, HostListener, ElementRef } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'td',
})
export class TdTooltipDirective {
  constructor(@Optional() private formControl: NgControl,
    public el: ElementRef) { }

  @HostListener('mouseover', ['$event']) mouseover(event: any) {
    if (event.path && event.path[0].clientWidth < event.path[0].scrollWidth) {
      this.el.nativeElement.title = event.target.innerText;
    } else {
      this.el.nativeElement.title = '';
    }
  }
}
