import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Directive({
    selector: 'input[format]'
})
export class NumberFormatDirective implements OnDestroy, OnChanges, OnInit {

    destroy$: Subject<boolean> = new Subject<boolean>();
    isFocused: boolean = false;
    _decimalPipe: DecimalPipe;
    sub: Subscription;

    /** Define quando cancela a subscricao ao formControl (no caos de haver o mesmo form control no cabeçalho e outro num modal e ambos teem esta diretiva) */
    @Input('cancelSubscription') cancelSubscription = false;

    /** Por defeito vai buscar aos validadores do formControl mas caso nao tenhas esses validadores pode-se mandar o numero de casas decimais por aqui */
    @Input('decimalCases') decimalCases: number;

    @Output() numberChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private ngControl: NgControl, private translateService: TranslateService, private commonService: CommonService) {
        this._decimalPipe = new DecimalPipe(this.translateService.currentLang);
    }

    ngOnInit(): void {
        this.subscribeToValueChanges();
        this.validateAndFormatControl()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cancelSubscription) {
            if (!this.cancelSubscription && !this.sub) {
                this.subscribeToValueChanges();
            } else {
                this.unSubscribe();
            }
        }
    }

    subscribeToValueChanges() {
        this.sub ??= this.ngControl.control?.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(change => {
            if (!this.isFocused)
                this.validateAndFormatControl();

            this.numberChange.emit(change);
        });
    }
    
    unSubscribe() {
        this.sub?.unsubscribe();
        this.sub = null;
    }

    @HostListener('focus')
    onFocus() {
        this.isFocused = true;
    }

    @HostListener('blur')
    onBlur() {
        this.isFocused = false;
        this.validateAndFormatControl();
    }

    validateAndFormatControl() {
        const value = this.revert(this.ngControl.value)

        if (value < 0)
            return;

        if (!this.decimalCases) {
            let errors = this.ngControl.control.validator && this.ngControl.control.validator(new UntypedFormControl('a'));
            if (errors && errors.hasOwnProperty('numberVal')) {
                errors = this.ngControl.control.validator(new UntypedFormControl(1.1234567895));
                if (errors && errors.hasOwnProperty('numberOfDecimalPlaces')) {
                    if (errors.hasOwnProperty('params')) {
                        let aux = JSON.parse(errors.params);
                        this.decimalCases = aux['0'];
                    }
                }
            }
        }

        if (this.decimalCases) {
            this.ngControl.control.setValue(this._decimalPipe.transform(value, "1." + this.decimalCases + "-" + this.decimalCases),{ onlySelf: true, emitEvent: false })
        }
    }

    revert(value: string): number {
        return value ? value.toString().revertDecimal() : 0;
    }

    ngOnDestroy(): void {
        this.unSubscribe();
    }
}
