import { AuthenticationService } from "../services/authentication.service";

export class Settings {
  protected authenticationService: AuthenticationService;
  protected context: string;

  constructor(authenticationService: AuthenticationService, context: string) {
      this.authenticationService = authenticationService;
      this.context = context;
  }

  
  /** Vai buscar o setting e atibui a uma variavel automaticamente
   * @param  {string} settingName Nome do Setting
   * @param  {T} defaultValue Valor por defeito do Setting (o tipo que for colocado aqui vai ser o que o metodo retorna a nao ser que o tipo seja enforçado)
   * @returns T Retorna o valor do setting se o encontrar se nao, o defaultValue com o tipo especificado
   * @example 
   *  getSetting('NomeSetting', false) // o tipo seria false...
   *  getSetting<boolean>('NomeSetting', false) // o tipo seria boolean
   *  getSetting('NomeSetting', ['texto', 'texto']) // se o defaultValue tiver um tipo nao e preciso colocar <>
   */
  getSetting<T>(settingName: string, defaultValue: T): T {
    return (this['__' + settingName] ??= this.authenticationService.getSettingPortal(this.context, settingName) ?? defaultValue) as T;
  }
}