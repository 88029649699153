
// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, NgZone, OnDestroy } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ***__***_________  MODELOS _________ ***__***
import { DeliveryNoteDetail, DeliveryNoteOtherReference } from '../../../models/deliveryNote';
import { ModelValidators } from '../../../models/validators/validators';
import { CommonService } from 'src/app/services/common.service';
import { Dropdown } from 'src/app/models/dropdown';
import { TranslateService } from '@ngx-translate/core';
import { ChooseModalComponent } from '../../shared/choose-modal/choose-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { Order } from 'src/app/models/order';
import { TransportVolume } from 'src/app/models/transport-volume';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './deliveryNoteDetails-modal.html'
})
export class LMDeliveryNoteDetailModalComponent implements OnInit, OnDestroy {

  public detailForm: UntypedFormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  noError: boolean = true;
  submitted: boolean = false;
  formErrors: Array<string> = new Array<string>(); // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean = false; // se tem permissoes de edicao
  model: DeliveryNoteDetail = null;
  selectedSSCC:TransportVolume;

  decimalPlaces3: number = 3;

  // arrays para options dos selects
  orderTaxes: Array<any> = new Array<any>();
  orderAllowances: Array<any> = new Array<any>();
  orderDiscounts: Array<any> = new Array<any>();

  detailTaxes: Array<any> = new Array<any>();
  detailUnitsG: Array<any> = new Array<any>();
  detailUnitsW: Array<any> = new Array<any>();
  detailUnitsV: Array<any> = new Array<any>();

  listaMoradasLocaisEntrega: Array<any> = new Array<any>();
  allCountries: Array<any> = new Array<any>();
  otherReferenceTypes: Dropdown[] = [];
  ordersList:Array<Order> = new Array<Order>();
  originalProductList: Map<string, Array<any>> = new Map<string, Array<any>>();
  productsList: Map<string, Array<any>> = new Map<string, Array<any>>();
  VolumesForm: UntypedFormGroup;

  showPackagingPanel: boolean;
  showPackageSequencePanel: boolean;
  showBuyerProductCode: boolean;
  showSupplierProductCode: boolean;
  showBatchNumber: boolean;
  showBatchExpiryDate: boolean;
  showReferencesPanel: boolean;
  showOthersReferencesTypes: boolean = false;
  isEditable: boolean;
  menu: string = '';
  submenu: string = '';
  oldValue: any;


  formDisabled: boolean; // para saber se os detalhes sales se podem editar ou sao so de consulta

  constructor(public dialogRef: MatDialogRef<LMDeliveryNoteDetailModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private zone: NgZone,
    private formBuilder: UntypedFormBuilder,private dialog: MatDialog,private translateService:TranslateService ,private commonService: CommonService) {
    this.model = data.detailModel;
    if (data.context === 'guiaVenda') { // guia de venda (as editaveis)
      this.isEditable = true;
      this.allowEdit = data.allowEdit;
      this.validationMessages = data.validationMessagesDetails;
      this.detailTaxes = data.detailTaxes;
      this.listaMoradasLocaisEntrega = data.locations;
      this.allCountries = data.allCountries;
      this.formDisabled = data.formDisabled;
      this.detailForm = data.detailForm;
      this.selectedSSCC = data.selectedSSCC;
      this.originalProductList = data.originalProductList;
      this.VolumesForm = data.VolumesForm;
      this.productsList = data.productsList;
      this.oldValue = JSON.parse(JSON.stringify(this.detailForm.get('Quantity').value));

    } else { // guia de compra (só de consulta)
      this.isEditable = false;
      this.allowEdit = false;

      this.detailForm = this.formBuilder.group({
        'ID': [this.model.ID],
        // Base
        'LineNumber': [this.model.LineNumber],
        'StandardProductCode': [this.model.StandardProductCode], // codigo EAN
        'ProductDescription': [this.model.ProductDescription],
        'BuyerProductCode': [this.model.BuyerProductCode],
        'SupplierProductCode': [this.model.SupplierProductCode],
        'Quantity': [this.model.Quantity_form],
        'QuantityUOMCode': [this.model.QuantityUOMCode],
        'QuantityUOMOther': [this.model.QuantityUOMOther],
        'BatchNumber': [this.model.BatchNumber],
        'BatchExpiryDate': [this.model.BatchExpiryDate],
        // Embalagens
        'PackQuantity': [this.model.PackQuantity_form],
        'PackQuantityUOMCode': [this.model.PackQuantityUOMCode],
        'PackSize': [this.model.PackSize_form],
        // Sequência de embalamento
        'PackSequenceQuantity': [this.model.PackSequenceQuantity],
        'PackSequenceQuantityUOMCode': [this.model.PackSequenceQuantityUOMCode],
        'PackQuantityUOMOther': [this.model.PackQuantityUOMOther], // não está na vista
        'PackSequenceTracingReference': [this.model.PackSequenceTracingReference],
        // Observações
        'FreeText': [this.model.FreeText],

        // Referências
        'BuyersOrderNumber': [null, Validators.required],
        'ProductSerialNumber': [this.model.ProductSerialNumber],
        'OtherReferences': this.formBuilder.array([]),

        'DeliveryNoteID': [this.model.DeliveryNoteID],
        'ProductID': [this.model.ProductID],
        'ReturnReason': [this.model.ReturnReason],
        'ReturnReasonOther': [this.model.ReturnReasonOther]

      });

 

      if (this.model.OtherReferences != null) {
        for (let ref of this.model.OtherReferences) {
          const controlT = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
          const refCtrl = this.initOtherReferences(ref);
          controlT.push(refCtrl);
        }
      }

      this.detailForm.disable();
    }
    data.orderNumbers.split(',').forEach(element => {
      let order:Order = new Order();
      order.DocumentNumber = element;
      this.ordersList.push(order)
    });

    
    

    this.detailUnitsG = data.detailUnitsG;
    this.detailUnitsW = data.detailUnitsW;
    this.detailUnitsV = data.detailUnitsV;
    this.showPackagingPanel = data.showPackagingPanel;
    this.showPackageSequencePanel = data.showPackageSequencePanel;
    this.showBuyerProductCode = data.showBuyerProductCode;
    this.showSupplierProductCode = data.showSupplierProductCode;
    this.showBatchNumber = data.showBatchNumber;
    this.showBatchExpiryDate = data.showBatchExpiryDate;
    this.showReferencesPanel = data.showReferencesPanel;
    this.showOthersReferencesTypes = data.showOthersReferencesTypes;

    // Verifica se é para mostrar os tipos de outras referências
    if (this.showOthersReferencesTypes) {
      // Tipos de Outras Referências
      this.commonService.otherReferenceTypes.pipe(takeUntil(this.destroy$)).subscribe(response => this.otherReferenceTypes = response);
    }
  }

  ngOnInit() {
    if (this.isEditable) {
      this.detailForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDetails(value);
      });

      let that = this;
      this.zone.onStable.pipe(first()).subscribe(() => {
        that.addOtherReference(false);
      });
    }

  }

  onQuantityChange(): void {
    let standardCode = this.detailForm.get('StandardProductCode').value;
    let productID = this.detailForm.get('ProductID').value;
    let value = this.detailForm.get('Quantity').value;


        let detail = this.detailForm;

        


        if (parseInt(detail.get('Quantity').value) != parseInt(this.oldValue)) {
          let productToReturn: any = this.originalProductList.get(this.VolumesForm.get('OrderNumber').value).find(x => x.ID === productID)
          if (parseInt(detail.get('OrderedQuantity').value) >  parseInt(this.oldValue) && parseInt(value) >= 0) {


            if (parseInt(detail.get('OrderedQuantity').value) - parseInt(value) > 0) {
              if (productToReturn.SatisfiedQuantity !== 0 && productToReturn.SatisfiedQuantity - parseInt(this.oldValue) >= 0 && parseInt(detail.get('Quantity').value) > parseInt(this.oldValue)) {
                productToReturn.SatisfiedQuantity =  productToReturn.SatisfiedQuantity - (parseInt(detail.get('Quantity').value) -  parseInt(value))

              }
              else {
                productToReturn.SatisfiedQuantity = parseInt(detail.get('Quantity').value) 
              }
              detail.get('Quantity').setValue(value);
              if (!this.productsList.get(this.VolumesForm.get('OrderNumber').value).find(x => x.ID === productID))
                this.productsList.get(this.VolumesForm.get('OrderNumber').value).push(productToReturn);
              else
                this.productsList.get(this.VolumesForm.get('OrderNumber').value).find(x => x.ID === productID).SatisfiedQuantity = parseInt(value)
            }
          }
          else {
            detail.get('Quantity').setValue(detail.get('OrderedQuantity').value);
            if (detail.get('Quantity') !== detail.get('OrderedQuantity').value) {
              if (!this.productsList.get(this.VolumesForm.get('OrderNumber').value).find(x => x.ID === productID))
                this.productsList.get(this.VolumesForm.get('OrderNumber').value).push(productToReturn);
              else
                this.productsList.get(this.VolumesForm.get('OrderNumber').value).find(x => x.ID === productID).SatisfiedQuantity = parseInt(value)
            }
          }
          if(parseInt(detail.get('OrderedQuantity').value) ===  parseInt(value)){
            let index = this.productsList.get(this.VolumesForm.get('OrderNumber').value).findIndex(x => x.ID === productID);
            this.productsList.get(this.VolumesForm.get('OrderNumber').value).splice(index,1);
          }
          productToReturn.SatisfiedQuantity = parseInt(value)
          detail.get('AvailableQuantity').setValue((productToReturn.DespatchedQuantity && productToReturn.DespatchedQuantity > 0 ? productToReturn.DespatchedQuantity : productToReturn.OrderedQuantity) - productToReturn.SatisfiedQuantity);
          
        }
      
  }

  // #region Detail
  onValueChangedDetails(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if(field === 'StandardProductCode' && form.get('OldStandardCode').value === null){
          form.get('OldStandardCode').setValue(form.get(field).value)
        }
        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  checkChanges() {
    this.onValueChangedDetails();
  }

  save() {
    if (this.allowEdit) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.checkChanges();
      this.onQuantityChange();

      if (this.noError) {
        this.dialogRef.close(this.detailForm); // nao pode enviar so o value por causa dos campos disabled
      }
      this.submitted = false;
    }
  }

  openDistribution() {
    alert('implementar');
  }

  
  selectOrderNumber() {
    // Só permite alterar os valores se o formulário for de leitura
 
      this.translateService.get(['SEARCH', 'CODE', 'NAME', 'ORDER']).subscribe(response => {
        let aoColumns = [
          { 'data': 'DocumentNumber', 'class': 'verticalMiddle', 'title': response['CODE'] },
          { 'data': 'Id' },
        ], columnDefs = [
          { 'targets': [1], 'visible': false } // colocar como hidden
        ], dialogRef = this.dialog.open(ChooseModalComponent, {
          data: new ChooseModalParam(this.ordersList, null,
            response['ORDER'], aoColumns, columnDefs, null, 0,null,null,null,null,'DocumentNumber'),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.detailForm.get('BuyersOrderNumber').setValue(result);
            

          }
        });
      });
    
  }

  // #endregion Detail

  // #region OtherReferences
  initOtherReferences(reference: DeliveryNoteOtherReference = null) {
    if (reference) {
      return this.formBuilder.group({
        'ReferenceType': [reference.ReferenceType],
        'ReferenceValue': [reference.ReferenceValue],
        'ReferenceDate': [reference.ReferenceDate, Validators.compose([ModelValidators.validDate])]
      });
    } else {
      return this.formBuilder.group({
        'ReferenceType': [''],
        'ReferenceValue': [''],
        'ReferenceDate': ['', Validators.compose([ModelValidators.validDate])]
      });
    }
  }

  addOtherReference(newInsert: boolean, objectInsert: DeliveryNoteOtherReference = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (control && control.value && control.value.length > 0) {
        // verificar se anterior esta em dados, caso esteja nao deixa meter outra linha
        let linhaAnterior = control.value[control.value.length - 1];
        if (linhaAnterior.ReferenceType.toString().length === 0 && linhaAnterior.ReferenceValue.toString().length === 0 && linhaAnterior.ReferenceDate.toString().length === 0) {
          return;
        }
      }
      const refeCtrl = this.initOtherReferences();
      control.push(refeCtrl);
      refeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedOtherReferences(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (objectInsert) { // usado quando faz reset
        const refeCtrl = this.initOtherReferences(objectInsert);
        control.push(refeCtrl);
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedOtherReferences(value);
          });
        }
      }
    }
  }

  removeOtherReference(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
    control.removeAt(i);
  }

  /* tslint:disable:member-ordering */
  formErrorsOtherReferences: Array<string> = new Array<string>();
  formErrorsOtherReferencesParam: Array<string> = new Array<string>();
  validationMessagesOtherReferences = {
    'ReferenceDate ': {
      'invalidDate': 'INVALID_DATE'
    },
  };
  /* tslint:enable:member-ordering */

  onValueChangedOtherReferences(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsOtherReferences = new Array<string>();
    this.formErrorsOtherReferencesParam = new Array<string>();
    for (const field in this.validationMessagesOtherReferences) {
      if (this.validationMessagesOtherReferences.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('OtherReferences');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesOtherReferences[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsOtherReferences.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsOtherReferencesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsOtherReferencesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion OtherReferences

  // #region Métodos Gerais e Comuns

  resetForm() { // reset do form (quando faz cancelar mantém valores da BD + criados não guardados)
    this.detailForm.reset(this.model);
    let initialValues = null;
    // colocar os arrays com os valores iniciais

    // outras referências
    const controlsO = <UntypedFormArray>this.detailForm.get('OtherReferences');
    while (controlsO.length) { // remover todos(as)
      controlsO.removeAt(controlsO.length - 1);
    }
    if (this.model) { // adicionar
      initialValues = this.model.OtherReferences;
      initialValues.forEach((reference: DeliveryNoteOtherReference) => {
        this.addOtherReference(false, reference);
      });
    }

  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
