// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateValueService } from '../../services/translate-value.service';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { QUANTITY_DECIMAL } from '../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { ModelValidators } from '../../models/validators/validators';
import { OrderDetail, TransformModel } from '../../models/order';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './responses-modal.component.html'
})
export class ResponsesModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  /* ***************************************************
   * *********** MODAL --> Responder a Encomenda *******
   * ***************************************************
   * */

  details: Array<any> = new Array<any>();
  responseStates: Array<number> = new Array<number>();

  form: UntypedFormGroup;

  open: boolean = true;
  noError: boolean = true;
  submitted: boolean = false;

  currentDate = new Date(); // data de hoje
  documentNumber: string;
  documentState: number;
  tranfModel = new TransformModel(); // necessario para usar as funcoes de configuração de hora e numeros

  showReturnedQuantity: boolean = false;
  validateDetailsDateOnSupplierResponse: boolean = false; // JJ - 08/10/2022 -> valida se a data nos detalhes é superior ou igual à data atual


  receptionReceivedQuantityDefaultValue: number = 0;

  validationMessages = {};
  validationMessagesDetails = {};

  constructor(public dialogRef: MatDialogRef<ResponsesModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private dialog: MatDialog, private formBuilder: UntypedFormBuilder,
    private translateValueService: TranslateValueService) {

    this.details = data.modelDetails;
    this.documentNumber = data.documentNumber;
    this.documentState = data.documentState;
    this.responseStates = data.responseStates;

    this.showReturnedQuantity = data.showReturnedQuantity;
    this.validateDetailsDateOnSupplierResponse = data.validateDetailsDateOnSupplierResponse;
    this.validationMessages = {
      'ReceptionNumber': {
        'lengthEquals': 'LENGTH_EQUALS',
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR',
        'charactersPosition': 'CHARACTRS_BEGIN'
      },
      'GuideNumber': {
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      }
    }
    
    this.validationMessagesDetails = {
      'EvalDateID': {
        'required': 'FIELD_REQUIRED_'
      },
      'EvalQtdID': {
        'required': 'FIELD_REQUIRED_'
      },
      'EvalProdID': {
        'required': 'FIELD_REQUIRED_'
      },
      'ReceivedQuantity': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Evaluation': {
        'required': 'FIELD_REQUIRED_'
      },
      'ReturnedQuantity': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
    }
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    // se for delegação ignorar as validações ao nº da receção
    // let isReceptionNumberRequired = this.isDelegation === false ? Validators.required : null;
    this.form = this.formBuilder.group({
      'DetailsList': this.formBuilder.array([])
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.addDetail();

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  /* tslint:disable:member-ordering */
  formErrors: Array<string> = new Array<string>();
  formErrorsParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        // if ((this.submitted && (control && !control.valid && control.enabled)) ||
        // (!this.submitted && (control && control.dirty && !control.valid))
        // ) {

        if (control && !control.valid && control.enabled && control.dirty) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  // #region Details
  initDetail(detail: OrderDetail) {
    detail = this.tranfModel.transformObjectDetail(detail, this.translateValueService.translateLanguage);
    //Coloca os valores default nos campos a preencher pelo Fornecedor (NetUnitPrice e DeliveryDate)
    if(!detail.SupplierDeliveryDate){
      if(detail.DeliveryEarliestAcceptableDate){
        detail.SupplierDeliveryDate = detail.DeliveryEarliestAcceptableDate;
      }
    }
    if(!detail.SupplierNetUnitPrice){
      detail.SupplierNetUnitPrice = detail.NetUnitPrice;
    }

    return this.formBuilder.group({
      'ID': [detail.ID],
      'LineNumber': [{ value: detail.LineNumber, disabled: true }],
      'Code': [{
        value: detail.BuyerProductCode ? detail.BuyerProductCode :
          detail.StandardProductCode ? detail.StandardProductCode :
            detail.SupplierProductCode ? detail.SupplierProductCode : '', disabled: true
      }],
      'SupplierProductCode': [{ value: detail.SupplierProductCode, disabled: true }],
      'OrderedQuantity': [{ value: detail.OrderedQuantity, disabled: true }], // quantidade encomendada
      'OrderedQuantityUOMCode' : [{ value: detail.OrderedQuantityUOMCode, disabled: true }],
      'RevisedQuantity': [detail.RevisedQuantity, Validators.required], // quantidade recebida anteriormente
      'DeliveryEarliestAcceptableDate': [{ value: detail.DeliveryEarliestAcceptableDate, disabled: true }], // quantidade devolvida
      'SupplierDeliveryDate': [detail.SupplierDeliveryDate, Validators.compose([ModelValidators.validDate, Validators.required])],
      'NetUnitPrice': [{ value: detail.NetUnitPrice, disabled: true }], // quantidade devolvida
      'SupplierNetUnitPrice': [detail.SupplierNetUnitPrice, Validators.required], // quantidade devolvida
    });
  }

  addDetail() {
    // inserir os dados que ja estao gravados
    if (this.details != null) {
      for (let detail of this.details) {
        const control = <UntypedFormArray>this.form.controls['DetailsList'];
        const ctrl = this.initDetail(detail);
        control.push(ctrl);
        ctrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          this.onValueChangedDetails(value);
        });
      }
    }
  }

  /* tslint:disable:member-ordering */
  formErrorsDetails: Array<string> = new Array<string>();
  formErrorsDetailsParam: Array<string> = new Array<string>();
 
  /* tslint:enable:member-ordering */

  onValueChangedDetails(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    // clear previous error message (if any)
    this.formErrorsDetails = new Array<string>();
    this.formErrorsDetailsParam = new Array<string>();
    for (const field in this.validationMessagesDetails) {
      if (this.validationMessagesDetails.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('DetailsList');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesDetails[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsDetails.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsDetailsParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsDetailsParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // endregion Details

  /**
   * Guarda a Resposta do Fornecedor
   * @param  {boolean} submit -> Se for enviado a True, indica que a Resposta do Fornecedor foi submetida (o Estado da Encomenda passa a ser alterado para Confirmada/Alterada Fornecedor)
   */
  save(submit: boolean = false) {
    /*
    *Esta modal tem o atributo 'cdkFocusInitial' nos detalhes para não abrir logo a apresentar erros
    *pq o 1º elemento selecionavel tem logo validações.
    *Problema: quando abria e fazia imedatamente "guardar" nao assumia os erros
    *Resolução: duas linhas que estão a seguir para o obrigar a lá ir
    * */

    this.submitted = true;
    this.noError = true;

    let validDetails = true;

    this.onValueChanged();
    this.onValueChangedDetails();
    
    let msg: string = '';
    
    if (this.noError) {
      const control = <UntypedFormArray>this.form.controls['DetailsList'];
      //Validações
      control.controls.forEach((detail: any, index: number) => {
        const RevisedQuantityControl = detail.get('RevisedQuantity');
        const SupplierNetUnitPriceControl = detail.get('SupplierNetUnitPrice');
        const SupplierDeliveryDate = detail.get('SupplierDeliveryDate');        
    
        if(this.validateDetailsDateOnSupplierResponse){
          //Valida se a Data selecionada é superior/igual à Data atual
          let newDate = DateTimePickerDirective.convertToString(new Date(), false);
          
          var dateParts = newDate.split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          var currentDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]); 
      
          dateParts = SupplierDeliveryDate.value.split("/");
          var selectedDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);  
      
          //Se a Data selecionada for inferior à Data atual, gera uma mensagem de erro
          if(selectedDate < currentDate){
            validDetails = false;
            msg += this.translateValueService.get('DATE_INFERIOR_THAN_CURR_DATE') + ' ' + this.translateValueService.get('ON_LINE') + ' ' + 
              '(' + detail.get('LineNumber').value + ')' + '<br/>';
          }
        }
        if(RevisedQuantityControl.value === null || RevisedQuantityControl.value === ''){
          validDetails = false;
          msg += this.translateValueService.get('REVISED_QUANTITY_REQUIRED') + ' ' + this.translateValueService.get('ON_LINE') + ' ' + 
            '(' + detail.get('LineNumber').value + ')' + '<br/>';
        }

        if(SupplierNetUnitPriceControl.value === null || SupplierNetUnitPriceControl.value === ''){
          validDetails = false;
          msg += this.translateValueService.get('NET_UNIT_PRICE_REQUIRED') + ' ' + this.translateValueService.get('ON_LINE') + ' ' + 
            '(' + detail.get('LineNumber').value + ')' + '<br/>';
        }
      });

      // o documento está num estado passível de confirmação de receção ?
      if (this.responseStates.findIndex((x: any) => (+x) === (+this.documentState)) === -1) {
        msg += this.translateValueService.get('INVALID_DOCUMENT_STATE') + '<br/>';
      }

      if (msg.length > 0) {
        Functions.gritter(msg, 'danger');
      } else {
        this.dialogRef.close([this.form.getRawValue(), submit]);
      }
    }
  }

  
  
  /**
   * Rejeita uma Linha da Encomenda
   * @param  {number} index -> Index do Detail
   */
   rejectDetail(index: number) {
    // Seleciona o detalhe para edição
    const formDetail = <UntypedFormGroup>(<UntypedFormArray>this.form.controls['DetailsList']).at(index);

    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        text: 'CONFIRM_REJECT_ORDER_DETAIL'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        formDetail.get('RevisedQuantity').setValue(0);
        formDetail.get('SupplierNetUnitPrice').setValue(0);
      }
    });
  }
  
  resetForm() {
    // this.form.reset(this.model);
  }

  ngOnDestroy() { }
}
