export class EmailModalParam {
  constructor(
    /// emails ou lista de emails
    public Emails?: string,
    // se tem assunto
    public Has_subject?: boolean,
    /// assunto do email - para usar o translate
    public Subject?: string,
    // parametros para enviar para a traducao do assunto ex: {'0': number}
    public Subject_params?: any,

  ) { }
}