import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterService } from './master.service';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL, CONTEXT_PRODUCT } from '../constants/global';
import { Workflow } from '../models/workflow';
import { ReturnStatusHtml } from '../models/returnStatus';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { GenericState } from '../models/generic-state';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class WorkflowService {
  static ActionType = {
    CHANGE_STEP: 1,
    ADD_STEPS_APPROVER: 2,
  };

  private _genericStates: GenericState[];
  private _productStates: GenericState[];

  private _controller: string = 'Workflow';

  // Settings
  private showEmailStates: number[];

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService) { }

  get(context: string, id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(context: string, model: Workflow): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('workflow', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(context: string, model: Workflow): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('workflow', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(context: string, id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getAll(context: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAll?context=' + context).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getStates(context: string): Observable<GenericState[]> {
    switch (context) {
      case CONTEXT_PRODUCT:
        if (this._productStates === undefined) {
          return this.http.get(SERVICE_URL + this._controller + '/GetStates?context=' + context).pipe(
            map(response => {
              try {
                let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                if (responseReturn.ReturnStatus.Successfull) {
                  this._productStates = responseReturn.ReturnStatus.ReturnObject as GenericState[];
                  return this._productStates;
                } else {
                  this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                }
              } catch (error) {
                this._masterService.handleError(error);
              }
            }));
        } else {
          return observableOf(this._productStates);
        }
      default:
        if (this._genericStates === undefined || this._genericStates.filter(r => r.Context === context).length === 0) {
          return this.http.get(SERVICE_URL + this._controller + '/GetStates?context=' + context).pipe(
            map(response => {
              try {
                let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                if (responseReturn.ReturnStatus.Successfull) {
                  if (this._genericStates)
                  {
                    this._genericStates = this._genericStates.concat(responseReturn.ReturnStatus.ReturnObject as GenericState[]);
                  }
                  else
                  {
                    this._genericStates = responseReturn.ReturnStatus.ReturnObject as GenericState[];
                  }
                  return this._genericStates.filter(r => r.Context === context).sort((a, b) => a.OrderBy - b.OrderBy);
                } else {
                  this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                }
              } catch (error) {
                this._masterService.handleError(error);
              }
            }));
        } else {
          return observableOf(this._genericStates.filter(r => r.Context === context).sort((a, b) => a.OrderBy - b.OrderBy));
        }
    }
  }

  getStateTransitionsLog(context: string, id: number, subcontext?: string): Observable<any[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetStateTransitionsLog?context=' + context + '&id=' + id + (subcontext ? '&subcontext=' + subcontext : '')).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getStateTransitionsTabs(context: string, id: number): Observable<any[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetStateTransitionsTabs?context=' + context + '&id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getTypes(context: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTypes?context=' + context).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateType(context: string, id: number, workflow?: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateType', { context: context, id: id, workflow: workflow }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Settings
  get_showEmailStates(defaultValue: number[]): number[] {
    if (typeof this.showEmailStates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('WorkflowChangeModal', 'ShowEmailStates');
      if (settingValue != null) {
        this.showEmailStates = settingValue;
      } else {
        this.showEmailStates = defaultValue;
      }
    }
    return this.showEmailStates;
  }
  // FIM - Settings
}
