// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, OnDestroy, Inject, OnInit } from '@angular/core';

// ***__***_________  MODALS _________ ***__***
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SERVICE_URL } from 'src/app/constants/global';
import { Media } from '../../../models/media';
import { ProcessService } from 'src/app/services/process/process.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  templateUrl: './process-modal.html'
})
export class ProcessModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  serverUrl: string = SERVICE_URL;
  texto: string = '';
  idImagem: any;
  context: string;
  defaultImage: Media = new Media(0, 0, null, true, 'assets/img/no-image.png');

  constructor(public dialogRef: MatDialogRef<ProcessModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private processService: ProcessService,) {
      this.texto = data.texto;
      this.idImagem = data.idImagem;
  }
  ngOnInit(): void {
    if (this.idImagem) {
      this.getImageSource(this.idImagem);
    }
  }



  getImageSource(mediaID: number, width?: number, height?: number) {
    let self = this;
    this.processService.getImageFile(mediaID, null, null).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response.type.indexOf('image') !== -1) {
        let reader: FileReader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = function () {
          self.defaultImage.FileContent = reader.result;
        };
      }
    });
  }

  ngOnDestroy() { }
}