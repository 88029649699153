import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { Dropdown } from '../models/dropdown';
import { GenericType } from 'src/app/models/generic-type';
import { GenericDocument } from 'src/app/models/generic-document';
import { GenericState } from 'src/app/models/generic-state';
import { HttpClientCustom } from './http-client';
import { MasterService } from './master.service';

// Contexto
export const CONTEXT_COMPANY_REGISTRATION = 'CompanyRegistration';

// Estados
export const enum CompanyRegistrationState { EM_APROVACAO = 550, APROVADO = 551, NAO_APROVADO = 552, INTEGRADO = 553 }



@Injectable()
export class RegisterSupplierService {
  private _controller: string = 'GenericDocument';
  // Listas
  public dropdownLists: Dropdown[][] = [];

  filter: any = {};
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[2, 'desc']], 0);

  datatableParametersPaymentPrevision: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null], 10, [[3, 'desc']], 0);

  datatableTicketsParameters: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[2, 'desc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions) { }

  get(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?context=' + CONTEXT_COMPANY_REGISTRATION + '&id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: GenericDocument, newFile?: File, newFile2?: File, withoutSession: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(CONTEXT_COMPANY_REGISTRATION));
    formData.append('entity', JSON.stringify(model));
    if (newFile) {
      formData.append('file', newFile, newFile.name); // vai adicionar ao formulario o ficheiro escolhido
    }

    if (newFile2) {
      formData.append('file', newFile2, newFile2.name); // vai adicionar ao formulario o ficheiro escolhido
    }
    formData.append('withoutSession', JSON.stringify(withoutSession));

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: GenericDocument): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(CONTEXT_COMPANY_REGISTRATION));
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?context=' + CONTEXT_COMPANY_REGISTRATION + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submit(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitDocument', { context: CONTEXT_COMPANY_REGISTRATION, id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
}
