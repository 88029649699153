import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL, CONTEXT_COMPANY } from '../constants/global';
import { Company } from '../models/company';
import { ReturnStatusHtml } from '../models/returnStatus';
import { WorkflowStep } from '../models/workflow-step';
import { CompanyParameter } from '../models/company-parameter';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { CompanyUser } from '../models/company-user';
import { TradingPartnerParam } from '../models/trading-partner-param';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';
import { Role } from '../models/role';
import { DataTableFunctions } from '../modules/datatables.module';
import { PartnerIdentity } from '../models/partner-identity';
import { CompanyServiceModule } from '../models/company-service-module';
import { GenericType } from '../models/generic-type';
import { Settings } from '../models/settings';
import { CommonService } from './common.service';
import { IncomeType } from '../models/income-types';
import { ServiceMethods } from './service-methods';
import { PartnerIBAN } from '../models/partner-iban';

@Injectable()
export class CompanyService extends ServiceMethods {
  private partnersAllowedRoles: Role[];
  private rolesByOwnerId: Role[];

  private _partners: Array<any>;
  private _companyIBANs: Array<any>;

  private _filterByRole: number = 0;
  private _filterServiceProviders: boolean;
  private _showOnlyServiceProviders: boolean;
  private _showOwnCompany: boolean;
  private _getParams: boolean;

  // Settings
  private parameters: any[];
  private showDeliveryScheduleTab: boolean;
  private ShowIdentitiesTab: boolean;
  private ShowHistoryChangeLog: boolean;
  private showPartnerIdentitiesMSDTab: boolean;
  private showSpeciesTab: boolean;
  private showSegmentsTab: boolean;
  private showRegionsTab: boolean;
  private showProvincesTab: boolean;
  private ShowWholesalerComboBox: boolean;
  private columnInVisible: number[];
  private useEDIWhenClient: boolean;
  private useEDIWhenSupplier: boolean;
  private partnerParameters: any[];
  private partnerDigitalArchive: boolean;
  private partnerPermissions: any[];
  private showCounty: boolean;
  private showFilters: boolean;
  private showMunicipality: boolean;
  private showDistrict: boolean;
  private partnerLabel: string;
  private partnersLabel: string;
  private newPartnerLabel: string;
  private showWarehouses: boolean;
  private showQueries: boolean;
  private showAlticePay: boolean;
  private showAllowChangeSSCCs: boolean;
  private orderMinValueRoleID: number;
  private showMyCompanyDigitalArchive: boolean;
  private showNewUserButton: boolean;
  private showSimplifiedDeliveryNotes: boolean;
  private canHaveMultipleIBAN: boolean;

  //Campos Genéricos Sanitop
  private showSectionApprovalCode: boolean; //Valida se mostra o Campo SectionApprovalCode
  private showLeadTime: boolean; //Valida se mostra o Campo LeadTime
  private showIBAN: boolean; //Valida se mostra o Campo IBAN
  private showSWIFT: boolean; //Valida se mostra o Campo SWIFT
  private showPaymentCondition: boolean; //Valida se mostra o Campo PaymentCondtion
  private defaultState: number; //Obtém o Valor do Estado Default do Parceiro
  private externalInternalID: boolean; // Valida se o InternalID é gerado no Backend automaticamente, caso sim o Campo não é mostrado

  private ignoreUpdatePartner: boolean; //Valida se mostra o Campo SWIFT
  private showWMSLabel: boolean; //Valida se mostra o Campo SWIFT

  private postalCodeRequired: boolean; //Valida se o Campo "PostalCode" é obrigatório
  private streetRequired: boolean; //Valida se o Campo "Street" é obrigatório
  private cityRequired: boolean; //Valida se o Campo "City" é obrigatório

  private postalCodeLength: number; //Tamanho máximo do campo "PostalCode"
  private streetLength: number; //Tamanho máximo do campo"Street"
  private cityLength: number; //Tamanho máximo do campo "City"


  private _types: Array<any> = [];
  private _partnerParams: TradingPartnerParam;

  private filterServiceProviders: boolean;
  private validateTaxNumber: boolean;
  private showExpiredDate: boolean;

  private showPartnerState: boolean; //Obtém a configuração para validar o Display dos Estados do Parceiro

  private hideAddressesTab: boolean; //Valida se é necessário esconder o separador "Locais/Moradas"

  private defaultSelectedRole: number; //Escolher um role por defeito no create

  private defaultCountry: string; //Escolher pais por defeito

  private afterSaveRemainOnSamePage: boolean; // MM - 28/09/2022 -> depois de salvar em vez de ser direcionado para a página da lista fica na mesma já pronta a editar

  private multipleIban: boolean;

  private validateTaxWithholding: boolean;

  private showAccountingCode: boolean;

  filterPartnersByRoles: number[] = [];
  filters: any = null;
  datatableParametersPartners: DatatableParameters = new DatatableParameters([null, null], 10, [[2, 'asc']], 0);
  datatableParametersSegmentsAndRegions: DatatableParameters = new DatatableParameters([null, null, null], 10, [[1, 'asc']], 0);
  datatableParametersCompaniesManager: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[3, 'asc']], 0);
  datatableParametersCims: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[2, 'asc']], 0);
  filterPartnersByIdentityCode: string = "";

  constructor(http: HttpClientCustom, _masterService: MasterService, _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService, private dataTableFunctions: DataTableFunctions, private commonService: CommonService) {
    super(_errorTreat, _masterService, http, 'Company')
  }

  /**
  * Obter info clientes/fornecedores
  * @param  {number} filterByRole
  * @param  {boolean} filterServiceProviders  Fornecedores -> search companies table for ServiceProvider == 0
  * @param  {boolean} showOwnCompany
  * @param  {boolean} filterByUserRoles -> search by user role
  * @param  {boolean} showOnlyServiceProviders Clientes -> search companies table for ServiceProvider == 1
  * @returns Observable
  * 
  */
  getPartners(filterByRole: number = null, filterServiceProviders: boolean = false, showOwnCompany: boolean = false, getParams: boolean = false, filterByUserRoles: boolean = false, showOnlyServiceProviders: boolean = false): Observable<Array<any>> {
    // if (this._clients === undefined) {
    if (this._partners === undefined
      || (this._partners && this._partners['\'' + filterByRole + '\''] === undefined)
      || this._filterByRole !== filterByRole
      || this._filterServiceProviders !== filterServiceProviders
      || this._showOnlyServiceProviders !== showOnlyServiceProviders
      || this._showOwnCompany !== showOwnCompany
      || this._getParams !== getParams) {
      this._filterByRole = filterByRole;
      this._filterServiceProviders = filterServiceProviders;
      this._showOwnCompany = showOwnCompany;
      return this._http.get(SERVICE_URL + this._controller + '/GetAllPartners?filterByRole=' + filterByRole + '&filterServiceProviders=' + filterServiceProviders + '&showOwnCompany=' + showOwnCompany + '&getParams=' + getParams + "&filterByUserRoles=" + filterByUserRoles + "&showOnlyServiceProviders=" + showOnlyServiceProviders).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              if (this._partners === undefined) {
                this._partners = [];
              }
              return this._partners['\'' + filterByRole + '\''] = responseReturn.ReturnStatus.ReturnObject;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._partners['\'' + filterByRole + '\'']);
    }
  }

  /* GM 2023-01-03 não parece estar a ser usado
  getServiceProviders(validatePortalAdmin: boolean = true): Observable<Array<any>> {
    // if (this._clients === undefined) {
    if (this._partners === undefined ) {
      return this._http.get(SERVICE_URL + this._controller + '/GetAllServiceProviders?validatePortalAdmin=' + validatePortalAdmin).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              if (this._partners === undefined) {
                this._partners = [];
              }
              return responseReturn.ReturnStatus.ReturnObject.aaData;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return null;
    }
  }
  */

  public cleanPartners(): void {
    this._partners = [];
  }

  get(id: number): Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/GetPartner?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: Company): Observable<ReturnStatusHtml> {
    return this._http.put(SERVICE_URL + this._controller + '/AddPartner', { partner: model, roles: model.roles }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: Company, parameters?: CompanyParameter[]): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/UpdatePartner', { partner: model, roles: model.roles, parameters: parameters }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this._http.delete(SERVICE_URL + this._controller + '/DeletePartner?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  verifyPartnerExistence(internalID: string, taxNumber: string = null): Observable<ReturnStatusHtml> {
    return this._http.get(SERVICE_URL + this._controller + '/VerifyPartnerExistence?internalID=' + internalID + '&taxNumber=' + taxNumber).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addOrUpdatePartnerParameters(entity: TradingPartnerParam): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/AddOrUpdatePartnerParameters', { entity: entity }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addOrUpdateCompanyParameter(entity: CompanyParameter): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/AddOrUpdateCompanyParameter', { entity: entity }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPartnerUsers(id: number): Observable<any> {
    return this._http.get(SERVICE_URL + 'Company/GetPartnerUsers?id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  updatePartnerUsers(id: number, entities: CompanyUser[]): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/UpdatePartnerUsers', { id: id, entities: entities }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPartnerIdentities(id: number, sessionIdDiferentOwnerId: boolean = false): Observable<any> {
    return this._http.post(SERVICE_URL + 'Company/GetPartnerIdentities/', { id: id, sessionIdDiferentOwnerId: sessionIdDiferentOwnerId }).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  getPartnerSegmentsAndRegionsAndSpecies(id: number, CompanyGroupType: string = null): Observable<any> {
    return this._http.post(SERVICE_URL + 'Company/GetPartnerSegmentsAndRegions/', { id: id, GroupType: CompanyGroupType }).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }


  GetWholesalers(): Observable<any> {
    return this._http.get(SERVICE_URL + this._controller + '/GetWholesalers').pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }


  getProvinces(ID: number): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/GetProvinces', { ID: ID }) // primeiro nome igual ao c#
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addPartnerIdentity(model: PartnerIdentity): Observable<ReturnStatusHtml> {
    return this._http.put(SERVICE_URL + this._controller + '/AddPartnerIdentity', { entity: model }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updatePartnerIdentity(id: number, model: PartnerIdentity): Observable<ReturnStatusHtml> {
    return this._http.put(SERVICE_URL + this._controller + '/UpdatePartnerIdentity', { ID: id, entity: model }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //Efetua um pedido à API para atualizar o Estado do Parceiro
  submit(id: number): Observable<ReturnStatusHtml> {
    return this._http.put(SERVICE_URL + this._controller + '/Submit', { ID: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deletePartnerIdentity(id: number): Observable<ReturnStatusHtml> {
    return this._http.delete(SERVICE_URL + this._controller + '/DeletePartnerIdentity?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  getPartnersAllowedRoles(): Observable<Role[]> {
    if (this.partnersAllowedRoles === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetPartnersAllowedRoles').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this.partnersAllowedRoles = responseReturn.ReturnStatus.ReturnObject as Role[];
              return this.partnersAllowedRoles;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this.partnersAllowedRoles);
    }
  }


  getPartnerRolesByOwnerId(id: number): Observable<Role[]> {
    if (this.rolesByOwnerId === undefined) {
      return this._http.get(SERVICE_URL + this._controller + '/GetPartnerRolesByOwnerId/' + id).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this.partnersAllowedRoles = responseReturn.ReturnStatus.ReturnObject as Role[];
              return this.partnersAllowedRoles;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this.partnersAllowedRoles);
    }
  }

  getCompanyServiceModulesByProvider(id: number): Observable<CompanyServiceModule[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetCompanyModulesByProvider/' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject as CompanyServiceModule[];
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));

  }

  // FIM - Listas de Valoresv

  /**
  * Download para Excel da lista de empresas
  */
  downloadExcelCompaniesList(params: any, filterByRoles?: any, isCompaniesMng?: boolean): Observable<any> {
    return this._http.postFile(SERVICE_URL + this._controller + '/DownloadExcelCompaniesList', { parameters: params, filterByRoles: filterByRoles, isCompaniesMng: isCompaniesMng });
  }


  //#region Workflows
  getWorkflowSteps(id: number): Observable<WorkflowStep[]> {
    return this._http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  changeWorkflowStep(id: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/ChangeWorkflowStep', { id: id, stepID: stepID, notes: notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this._http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /** Obter todos os IBANs da empresa
   * @returns Observable - com o array dos IBANs da empresa ou vazio
   */
  get companyIBANs(): Observable<PartnerIBAN[]> {
    return this.getData({ property: '_companyIBANs', url: 'GetIbans', params: { ownerID: this.authenticationService.session.company.ID } });
  }

  getIbans(ownerID: number, partnerID?: number): Observable<Array<PartnerIBAN>> {
    return this.getDataArray({ property: '_partnerIbans', key: { ownerID, partnerID }, url: 'GetIbans', params: { ownerID, partnerID }})
  }

  getIbanByIban(iban: string, ownerID: number, partnerID?: number) {
    return this.getComplements<PartnerIBAN>('GetIbanByIban', { iban, ownerID, partnerID });
  }

  get incomeTypes(): Observable<IncomeType[]> {
    return this.getData({ property: '_incomeTypes', url: 'GetIncomeTypes' });
  }

  getIncomeType(id: number) {
    return this.getComplements<IncomeType>('GetIncomeType', { id });
  }

  getWithHoldings(context = 'Company'): Observable<ReturnStatusHtml<WithHolding[]>> {
    return this.getComplements<WithHolding[]>('GetWithHoldings', { context });
  }

  //#endregion Workflows

  // Settings
  get_parameters(defaultValue: any[]): any[] {
    if (typeof this.parameters === 'undefined') { // verificar se ainda nao tem valor
      let settingValueSP = this.authenticationService.getSettingPortal('Company', 'Parameters');
      let settingValueNonSP = this.authenticationService.getSettingPortal('Company', 'ParametersNonSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0) {
        this.parameters = settingValueSP;
      } else if (!this.authenticationService.session.company.ServiceProvider && settingValueNonSP && settingValueNonSP.length > 0) {
        this.parameters = settingValueNonSP;
      } else {
        this.parameters = defaultValue;
      }
    }
    return this.parameters;
  }

  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {Array<number>} defaultValue - Valor por defeito do parametro
 * @param  {Array<string>} columnsNames - nomes das colunas
 * @returns Array
 */
  get_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partners', 'ColumVisible');
      this.columnInVisible = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible;
  }

  get_showDeliveryScheduleTab(defaultValue: boolean): boolean {
    if (typeof this.showDeliveryScheduleTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowDeliveryScheduleTab');
      if (settingValue != null) {
        this.showDeliveryScheduleTab = settingValue;
      } else {
        this.showDeliveryScheduleTab = defaultValue;
      }
    }
    return this.showDeliveryScheduleTab;
  }

  get_useEDIWhenClient(defaultValue: boolean): boolean {
    if (typeof this.useEDIWhenClient === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'Record') && this.authenticationService.getSettingPortal('Partner', 'Record').UseEDIWhenClient
        ? this.authenticationService.getSettingPortal('Partner', 'Record').UseEDIWhenClient : null;
      if (settingValue != null) {
        this.useEDIWhenClient = settingValue;
      } else {
        this.useEDIWhenClient = defaultValue;
      }
    }
    return this.useEDIWhenClient;
  }

  get_showWarehouses(defaultValue: boolean): boolean {
    if (typeof this.showWarehouses === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowWarehouses');
      if (settingValue != null) {
        this.showWarehouses = settingValue;
      }
      else {
        this.showWarehouses = defaultValue;
      }
    }
    return this.showWarehouses;
  }

  get_showQueries(defaultValue: boolean): boolean {
    if (typeof this.showQueries === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowQueries');
      if (settingValue != null) {
        this.showQueries = settingValue;
      }
      else {
        this.showQueries = defaultValue;
      }
    }
    return this.showQueries;
  }

  get_showAlticePay(defaultValue: boolean): boolean {
    if (typeof this.showAlticePay === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowAlticePay');
      if (settingValue != null) {
        this.showAlticePay = settingValue;
      }
      else {
        this.showAlticePay = defaultValue;
      }
    }
    return this.showAlticePay;
  }

  get_showAllowChangeSSCCs(defaultValue: boolean): boolean {
    if (typeof this.showAllowChangeSSCCs === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowAllowChangeSSCCs');
      if (settingValue != null) {
        this.showAllowChangeSSCCs = settingValue;
      }
      else {
        this.showAllowChangeSSCCs = defaultValue;
      }
    }
    return this.showAllowChangeSSCCs;
  }

  //#region GET Campos Genéricos Sanitop:

  get_showSectionApprovalCode(defaultValue: boolean): boolean {
    if (typeof this.showSectionApprovalCode === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowSectionApprovalCode');
      if (settingValue != null) {
        this.showSectionApprovalCode = settingValue;
      }
      else {
        this.showSectionApprovalCode = defaultValue;
      }
    }
    return this.showSectionApprovalCode;
  }

  get_showLeadTime(defaultValue: boolean): boolean {
    if (typeof this.showLeadTime === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowLeadTime');
      if (settingValue != null) {
        this.showLeadTime = settingValue;
      }
      else {
        this.showLeadTime = defaultValue;
      }
    }
    return this.showLeadTime;
  }

  get_showIBAN(defaultValue: boolean): boolean {
    if (typeof this.showIBAN === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowIBAN');
      if (settingValue != null) {
        this.showIBAN = settingValue;
      }
      else {
        this.showIBAN = defaultValue;
      }
    }
    return this.showIBAN;
  }

  get_showSWIFT(defaultValue: boolean): boolean {
    if (typeof this.showSWIFT === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowSWIFT');
      if (settingValue != null) {
        this.showSWIFT = settingValue;
      }
      else {
        this.showSWIFT = defaultValue;
      }
    }
    return this.showSWIFT;
  }

  get_showPaymentCondition(defaultValue: boolean): boolean {
    if (typeof this.showPaymentCondition === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowPaymentCondition');
      if (settingValue != null) {
        this.showPaymentCondition = settingValue;
      }
      else {
        this.showPaymentCondition = defaultValue;
      }
    }
    return this.showPaymentCondition;
  }

  get_showAccountingCode(defaultValue: boolean): boolean {
    if (typeof this.showAccountingCode === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowAccountingCode');
      if (settingValue != null) {
        this.showAccountingCode = settingValue;
      }
      else {
        this.showAccountingCode = defaultValue;
      }
    }
    return this.showAccountingCode;
  }

  get_defaultState(defaultValue: number): number {
    if (typeof this.defaultState === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'DefaultState');
      if (settingValue != null) {
        this.defaultState = settingValue;
      }
      else {
        this.defaultState = defaultValue;
      }
    }
    return this.defaultState;
  }

  //#endregion

  get_externalInternalID(defaultValue: boolean): boolean {
    if (typeof this.externalInternalID === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ExternalInternalID');
      if (settingValue != null) {
        this.externalInternalID = settingValue;
      }
      else {
        this.externalInternalID = defaultValue;
      }
    }
    return this.externalInternalID;
  }

  //#region Alterar Obrigatoriedade de Campos existentes

  get_postalCodeRequired(defaultValue: boolean): boolean {
    if (typeof this.postalCodeRequired === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'PostalCodeRequired');
      if (settingValue != null) {
        this.postalCodeRequired = settingValue;
      }
      else {
        this.postalCodeRequired = defaultValue;
      }
    }
    return this.postalCodeRequired;
  }

  get_streetRequired(defaultValue: boolean): boolean {
    if (typeof this.streetRequired === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'StreetRequired');
      if (settingValue != null) {
        this.streetRequired = settingValue;
      }
      else {
        this.streetRequired = defaultValue;
      }
    }
    return this.streetRequired;
  }

  get_cityRequired(defaultValue: boolean): boolean {
    if (typeof this.cityRequired === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'CityRequired');
      if (settingValue != null) {
        this.cityRequired = settingValue;
      }
      else {
        this.cityRequired = defaultValue;
      }
    }
    return this.cityRequired;
  }

  //#endregion Alterar Obrigatoriedade de Campos existentes


  //#region Alterar Tamanho Máximo de Campos existentes
  get_cityLength(defaultValue: number): number {
    if (typeof this.cityLength === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'CityLength');
      if (settingValue != null) {
        this.cityLength = settingValue;
      }
      else {
        this.cityLength = defaultValue;
      }
    }
    return this.cityLength;
  }

  get_postalCodeLength(defaultValue: number): number {
    if (typeof this.postalCodeLength === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'PostalCodeLength');
      if (settingValue != null) {
        this.postalCodeLength = settingValue;
      }
      else {
        this.postalCodeLength = defaultValue;
      }
    }
    return this.postalCodeLength;
  }

  get_streetLength(defaultValue: number): number {
    if (typeof this.streetLength === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'StreetLength');
      if (settingValue != null) {
        this.streetLength = settingValue;
      }
      else {
        this.streetLength = defaultValue;
      }
    }
    return this.streetLength;
  }


  //#endregion Alterar Tamanho Máximo de Campos existentes

  get_useEDIWhenSupplier(defaultValue: boolean): boolean {
    if (typeof this.useEDIWhenSupplier === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'Record') && this.authenticationService.getSettingPortal('Partner', 'Record').UseEDIWhenSupplier
        ? this.authenticationService.getSettingPortal('Partner', 'Record').UseEDIWhenSupplier : null;
      if (settingValue != null) {
        this.useEDIWhenSupplier = settingValue;
      } else {
        this.useEDIWhenSupplier = defaultValue;
      }
    }
    return this.useEDIWhenSupplier;
  }

  get_partnerParameters(defaultValue: any[]): any[] {
    if (typeof this.partnerParameters === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'Parameters');
      if (settingValue != null) {
        this.partnerParameters = settingValue;
      } else {
        this.partnerParameters = defaultValue;
      }
    }
    return this.partnerParameters;
  }

  get_showIdentitiesTab(defaultValue: boolean): boolean {
    if (typeof this.ShowIdentitiesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowIdentitiesTab');//seting conforme o back
      if (settingValue != null) {
        this.ShowIdentitiesTab = settingValue;
      } else {
        this.ShowIdentitiesTab = defaultValue;
      }
    }
    return this.ShowIdentitiesTab;
  }

  get_showHistoryChangeLog(defaultValue: boolean): boolean {
    if (typeof this.ShowHistoryChangeLog === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partners', 'ShowHistoryChangeLog');//seting conforme o back
      if (settingValue != null) {
        this.ShowHistoryChangeLog = settingValue;
      } else {
        this.ShowHistoryChangeLog = defaultValue;
      }
    }
    return this.ShowHistoryChangeLog;
  }

  get_showIdentitiesTabMSD(defaultValue: boolean): boolean {
    if (typeof this.showPartnerIdentitiesMSDTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowIdentitiesMSDTab');//seting conforme o back
      if (settingValue != null) {
        this.showPartnerIdentitiesMSDTab = settingValue;
      } else {
        this.showPartnerIdentitiesMSDTab = defaultValue;
      }
    }
    return this.showPartnerIdentitiesMSDTab;
  }

  get_showSpeciesTab(defaultValue: boolean): boolean {
    if (typeof this.showSpeciesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowSpecies');//seting conforme o back
      if (settingValue != null) {
        this.showSpeciesTab = settingValue;
      } else {
        this.showSpeciesTab = defaultValue;
      }
    }
    return this.showSpeciesTab;
  }

  get_showSegmentsTab(defaultValue: boolean): boolean {
    if (typeof this.showSegmentsTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowSegmentsTab');//seting conforme o back
      if (settingValue != null) {
        this.showSegmentsTab = settingValue;
      } else {
        this.showSegmentsTab = defaultValue;
      }
    }
    return this.showSegmentsTab;
  }

  get_showRegionsTab(defaultValue: boolean): boolean {
    if (typeof this.showRegionsTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowRegionsTab'); //seting conforme o back
      if (settingValue != null) {
        this.showRegionsTab = settingValue;
      } else {
        this.showRegionsTab = defaultValue;
      }
    }
    return this.showRegionsTab;
  }

  get_showProvincesTab(defaultValue: boolean): boolean {
    if (typeof this.showProvincesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowProvincesTab');//seting conforme o back
      if (settingValue != null) {
        this.showProvincesTab = settingValue;
      } else {
        this.showProvincesTab = defaultValue;
      }
    }
    return this.showProvincesTab;
  }

  get_showWholesalerComboBox(defaultValue: boolean): boolean {
    if (typeof this.ShowWholesalerComboBox === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowWholesalerComboBox');
      if (settingValue != null) {
        this.ShowWholesalerComboBox = settingValue;
      } else {
        this.ShowWholesalerComboBox = defaultValue;
      }
    }
    return this.ShowWholesalerComboBox;
  }

  get_partnerDigitalArchive(defaultValue: boolean): boolean {
    if (typeof this.partnerDigitalArchive === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'DigitalArchive');
      if (settingValue != null) {
        this.partnerDigitalArchive = settingValue;
      } else {
        this.partnerDigitalArchive = defaultValue;
      }
    }
    return this.partnerDigitalArchive;
  }

  get_myCompanyDigitalArchive(defaultValue: boolean): boolean {
    if (typeof this.showMyCompanyDigitalArchive === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('MyCompany', 'ShowDigitalArchive');
      if (settingValue != null) {
        this.showMyCompanyDigitalArchive = settingValue;
      } else {
        this.showMyCompanyDigitalArchive = defaultValue;
      }
    }
    return this.showMyCompanyDigitalArchive;
  }

  get_partnerPermissions(defaultValue: any[]): any[] {
    if (typeof this.partnerPermissions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'Permissions');
      if (settingValue != null) {
        this.partnerPermissions = settingValue;
      } else {
        this.partnerPermissions = defaultValue;
      }
    }
    return this.partnerPermissions;
  }

  get_showCounty(defaultValue: boolean): boolean {
    if (typeof this.showCounty === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Company', 'ShowCounty');
      if (settingValue !== null) {
        this.showCounty = settingValue;
      } else {
        this.showCounty = defaultValue;
      }
    }
    return this.showCounty;
  }

  get_showFilters(defaultValue: boolean): boolean {
    if (typeof this.showFilters === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partners', 'ShowFilters');
      if (settingValue !== null) {
        this.showFilters = settingValue;
      } else {
        this.showFilters = defaultValue;
      }
    }
    return this.showFilters;
  }

  get_showMunicipality(defaultValue: boolean): boolean {
    if (typeof this.showMunicipality === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowMunicipality');
      if (settingValue != null) {
        this.showMunicipality = settingValue;
      } else {
        this.showMunicipality = defaultValue;
      }
    }
    return this.showMunicipality;
  }

  get_showDistrict(defaultValue: boolean): boolean {
    if (typeof this.showDistrict === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowDistrict');
      if (settingValue != null) {
        this.showDistrict = settingValue;
      } else {
        this.showDistrict = defaultValue;
      }
    }
    return this.showDistrict;
  }

  get_partnerLabel(defaultValue: string): string {
    if (typeof this.partnerLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'PartnerLabel');
      if (settingValue != null) {
        this.partnerLabel = settingValue;
      } else {
        this.partnerLabel = defaultValue;
      }
    }
    return this.partnerLabel;
  }

  get_partnersLabel(defaultValue: string): string {
    if (typeof this.partnersLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partners', 'PartnersLabel');
      if (settingValue != null) {
        this.partnersLabel = settingValue;
      } else {
        this.partnersLabel = defaultValue;
      }
    }
    return this.partnersLabel;
  }

  get_filterServiceProviders(defaultValue: boolean): boolean {
    if (typeof this.filterServiceProviders === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partners', 'FilterServiceProviders');
      if (settingValue != null) {
        this.filterServiceProviders = settingValue;
      } else {
        this.filterServiceProviders = defaultValue;
      }
    }
    return this.filterServiceProviders;
  }

  get_newPartnerLabel(defaultValue: string): string {
    if (typeof this.newPartnerLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'NewPartnerLabel');
      if (settingValue != null) {
        this.newPartnerLabel = settingValue;
      } else {
        this.newPartnerLabel = defaultValue;
      }
    }
    return this.newPartnerLabel;
  }

  showOrderMinValueRoleID(defaultValue: number): number {
    if (typeof this.orderMinValueRoleID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'OrderMinValueRoleID');
      if (settingValue != null) {
        this.orderMinValueRoleID = settingValue;
      } else {
        this.orderMinValueRoleID = defaultValue;
      }
    }
    return this.orderMinValueRoleID;
  }


  getTypes(context: string): Observable<GenericType[]> {
    if (this._types.length == 0 && !this._types.find(e => e.context == context)) {
      return this._http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + context)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              let genericTypes = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              this._types.push({ context: context, types: genericTypes });

              return genericTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._types.find(e => e.context == context).types);
    }
  }

  getPartnerParams(): Observable<TradingPartnerParam> {
    if (this._partnerParams === undefined || !this._partnerParams) {
      return this._http.get(SERVICE_URL + 'Company/GetPartnerParams')
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._partnerParams = responseReturn.ReturnStatus.ReturnObject.aaData as TradingPartnerParam;
              return this._partnerParams;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._partnerParams);
    }
  }



  get_validateTaxNumber(defaultValue: boolean): boolean {
    if (typeof this.validateTaxNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ValidateTaxNumber');
      if (settingValue != null) {
        this.validateTaxNumber = settingValue;
      } else {
        this.validateTaxNumber = defaultValue;
      }
    }
    return this.validateTaxNumber;
  }

  get_showExpiredDate(defaultValue: boolean): boolean {
    if (typeof this.showExpiredDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowExpiredDate');
      if (settingValue != null) {
        this.showExpiredDate = settingValue;
      } else {
        this.showExpiredDate = defaultValue;
      }
    }
    return this.showExpiredDate;
  }


  get_showPartnerState(defaultValue: boolean): boolean {
    if (typeof this.showPartnerState === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partners', 'ShowPartnerState');
      if (settingValue != null) {
        this.showPartnerState = settingValue;
      } else {
        this.showPartnerState = defaultValue;
      }
    }
    return this.showPartnerState;
  }


  get_hideAddressesTab(defaultValue: boolean): boolean {
    if (typeof this.hideAddressesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'HideAddressesTab');
      if (settingValue != null) {
        this.hideAddressesTab = settingValue;
      } else {
        this.hideAddressesTab = defaultValue;
      }
    }
    return this.hideAddressesTab;
  }


  //#region Alterar Tamanho Máximo de Campos existentes
  get_defaultRole(defaultValue: number): number {
    if (typeof this.defaultSelectedRole === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'DefaultSelectedRole');
      if (settingValue != null) {
        this.defaultSelectedRole = settingValue;
      }
      else {
        this.defaultSelectedRole = defaultValue;
      }
    }
    return this.defaultSelectedRole;
  }

  //#region Alterar Tamanho Máximo de Campos existentes
  get_defaultCountry(defaultValue: string): string {
    if (typeof this.defaultCountry === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'DefaultCountry');
      if (settingValue != null) {
        this.defaultCountry = settingValue;
      }
      else {
        this.defaultCountry = defaultValue;
      }
    }
    return this.defaultCountry;
  }

  // FIM - Settings

  get_ignoreUpdatePartner(defaultValue: boolean): boolean {
    if (typeof this.ignoreUpdatePartner === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'IgnoreUpdatePartner');
      if (settingValue != null) {
        this.ignoreUpdatePartner = settingValue;
      }
      else {
        this.ignoreUpdatePartner = defaultValue;
      }
    }
    return this.ignoreUpdatePartner;
  }

  get_showWMSLabel(defaultValue: boolean): boolean {
    if (typeof this.showWMSLabel === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowWMSLabel');
      if (settingValue != null) {
        this.showWMSLabel = settingValue;
      }
      else {
        this.showWMSLabel = defaultValue;
      }
    }
    return this.showWMSLabel;
  }

  get_showNewUserButton(defaultValue: boolean): boolean {
    if (typeof this.showNewUserButton === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowNewUserButton');
      if (settingValue != null) {
        this.showNewUserButton = settingValue;
      }
      else {
        this.showNewUserButton = defaultValue;
      }
    }
    return this.showNewUserButton;
  }

  get_showSimplifiedDeliveryNotes(defaultValue: boolean): boolean {
    if (typeof this.showSimplifiedDeliveryNotes === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ShowSimplifiedDeliveryNotes');
      if (settingValue != null) {
        this.showSimplifiedDeliveryNotes = settingValue;
      }
      else {
        this.showSimplifiedDeliveryNotes = defaultValue;
      }
    }
    return this.showSimplifiedDeliveryNotes;
  }

  get_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      }
      else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }

  get_multipleIban(defaultValue: boolean): boolean {
    if (typeof this.multipleIban === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'MultipleIban');
      if (settingValue != null) {
        this.multipleIban = settingValue;
      }
      else {
        this.multipleIban = defaultValue;
      }
    }
    return this.multipleIban;
  }

  get_canHaveMultipleIBAN(defaultValue: boolean): boolean {
    if (typeof this.canHaveMultipleIBAN === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Company', 'CanHaveMultipleIBAN');
      if (settingValue != null) {
        this.canHaveMultipleIBAN = settingValue;
      } else {
        this.canHaveMultipleIBAN = defaultValue;
      }
    }
    return this.canHaveMultipleIBAN;
  }

  get_validateTaxWithholding(defaultValue: boolean): boolean {
    if (typeof this.validateTaxWithholding === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Partner', 'ValidateTaxWithholding');
      if (settingValue != null) {
        this.validateTaxWithholding = settingValue;
      }
      else {
        this.validateTaxWithholding = defaultValue;
      }
    }
    return this.validateTaxWithholding;
  }


  //#region paymentConditionByCompany
  private _paymentConditionByCompany: boolean;

  /** Indica se a payment condition é para ir buscar consoante o ID da empresa */
  public get paymentConditionByCompany() {
    this._paymentConditionByCompany = this.authenticationService.getBooleanSetting(false, this._paymentConditionByCompany, 'Partner', 'PaymentConditionByCompany');
    return this._paymentConditionByCompany;
  }

  //#endregion paymentConditionByCompany

  private _partnerSettings: PartnerSettings;
  public get PartnerSettings(): PartnerSettings {
    return this._partnerSettings ??= new PartnerSettings(this.authenticationService)
  }
}

export class PartnerSettings extends Settings {
  constructor(authenticationService: AuthenticationService) {
    super(authenticationService, 'Partner');
  }

  /** Esconde/Mostra o campo MarketType no Painel AccountingConfigs */
  get showMarketTypes(): boolean {
    return this.getSetting<boolean>('ShowMarketType', false);
  }

  /** Esconde/Mostra o campo TaxAreas no Painel AccountingConfigs */
  get showTaxAreas(): boolean {
    return this.getSetting<boolean>('ShowTaxAreas', false);
  }

  /** Mostrar novo campo de condicoes de pagamentos */
  get showNewPaymentCondition(): boolean {
    return this.getSetting<boolean>('ShowNewPaymentCondition', false);
  }
}