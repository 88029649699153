import { Component, Inject, OnInit, NgZone, OnDestroy } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProductService } from 'src/app/services/product.service';
import { ReturnStatusHtml } from 'src/app/models/returnStatus';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Currency } from 'src/app/models/currency';
import { DecimalPipe } from '@angular/common';
import { CURRENCY_DECIMAL, SERVICE_URL } from 'src/app/constants/global';
import { TranslateService } from '@ngx-translate/core';
import { ErrorTreatmentFunctions } from 'src/app/modules/treatments.module';
import { Category } from 'src/app/models/category';
import { Family } from 'src/app/models/family';
import { CommonService } from 'src/app/services/common.service';
import { FormArray, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { ChooseModalComponent } from '../choose-modal/choose-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './purchase-products-modal.html'
})
export class PurchaseProductsModalComponent implements OnInit, OnDestroy {
  serverUrl: string = SERVICE_URL;

  collPnPopup1: boolean = true;

  private parameters: any;
  private supplierID: number;
  private currencies: Currency[] = [];
  private decimalPipe: DecimalPipe;

  // Filtros
  reference: string = null;
  name: string = null;
  category: number = null;
  isFilterByPriceList: boolean = false;
  family: number = null;
  familyLv1: number = null;
  familyLv2: number = null;
  familyLv3: number = null;
  categories: Category[];
  families: Family[];
  familiesLv1: Family[];
  familiesLv2: Family[];
  familiesLv3: Family[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  products: any[] = [];
  addedProducts: number[] = [];
  addMethod: (data: any, component: any, quantity?: number, uomCode?: string) => any;
  component: any;
  showFilterProductsInPriceList: boolean = true;

  // Paginação
  page: number = 0;
  length: number = 100;
  totalPages: number = 0;

  quantities: any[] = [];
  quantity: number = 0;

  detailUnitsG: Array<UnitOfMeasure> = new Array<UnitOfMeasure>();
  ownProducts: boolean = false;

  filterSupplierID: number = 0;
  suppliers: Array<any> = new Array<any>();
  supplierName: string = null;

  showPurchaseProductsUomCode: boolean = false;
  hidePurchaseProductsSupplier: boolean = false;

  showAdditionalInfo: boolean = false;

  allowRepetedProducts: boolean = false;
  filterByProductTypes: string[] = null;
  hasRetention: boolean = false;

  constructor(public dialogRef: MatDialogRef<PurchaseProductsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private productService: ProductService,
    private authenticationService: AuthenticationService, private translateService: TranslateService, private zone: NgZone, private errorTreat: ErrorTreatmentFunctions,
    commonService: CommonService, private formBuilder: UntypedFormBuilder, private dialog: MatDialog, private translateValueService: TranslateValueService) {
    this.supplierID = data.supplierID;
    this.addMethod = data.addMethod;
    this.component = data.component;
    this.addedProducts = data.addedProducts ? data.addedProducts : [];
    this.showFilterProductsInPriceList = this.productService.getEDITOR_showFilterProductInPriceList(this.showFilterProductsInPriceList);
    this.detailUnitsG = data.detailUnitsG ? data.detailUnitsG : [];
    this.ownProducts = data.ownProducts;
    this.suppliers = data.providers;
    this.showPurchaseProductsUomCode = data.showPurchaseProductsUomCode;
    this.hidePurchaseProductsSupplier = data.hidePurchaseProductsSupplier;
    this.showAdditionalInfo = data.showAdditionalInfo;
    this.allowRepetedProducts = data.allowRepetedProducts;
    this.filterByProductTypes = data.filterByProductTypes;
    this.hasRetention = data.hasRetention;

    // Se for para aparecer o campo filtrar por price list, deve ser filtrado por defeito por price list
    if (this.showFilterProductsInPriceList) {
      this.isFilterByPriceList = true;
    }
    this.decimalPipe = new DecimalPipe(translateService.currentLang);
    this.serverUrl = SERVICE_URL;

    // Lista de parâmetros para simular a Datatables.net a fazer o pedido
    this.parameters = {
      draw: 0, start: (this.page * this.length), length: this.length, search: { regex: false, value: '' }, order: [{ column: 1, dir: 'asc' }], columns: [
        { data: 'PartnerProductCode', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'Name', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'FamilyID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'ID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'MinimumQuantity', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'NetUnitBuyPrice', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'NetUnitPrice', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'OwnerID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'PackSize', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'StandardCode', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'UOMCode', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'VATRate', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'PriceListID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'PriceListPrice', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'IntegrationID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'BuyCurrencyCode', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'CategoryID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'ProductMediaID', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
        { data: 'QuantityIncrement', name: '', orderable: true, search: { value: '', regex: false }, searchable: true },
      ]
    };

    // Preenchimento das listas de valores
    commonService.currencies.pipe(takeUntil(this.destroy$)).subscribe((response: Currency[]) => this.currencies = response);
    commonService.categories.pipe(takeUntil(this.destroy$)).subscribe((response: Category[]) => this.categories = response);
    commonService.families.pipe(takeUntil(this.destroy$)).subscribe((response: Family[]) => {
      this.families = response;
      this.familiesLv1 = this.families.filter(r => r.Level === 1);
    });
  }

  ngOnInit(): void {
    this.populateProducts();
  }

  // Construir a listagem de dados com os filtros selecionados
  populateProducts() {
    if (this.ownProducts) {
      this.productService.getOwnForPurchasesList(this.parameters, this.supplierID, true, this.reference, this.name, this.category, this.family, this.isFilterByPriceList, this.filterByProductTypes).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          this.products = response.ReturnStatus.ReturnObject.aaData;
          /*if(this.products != null && this.products.length > 0)
            this.initQuantities();*/
          this.totalPages = Math.ceil(response.ReturnStatus.ReturnObject.iTotalRecords / this.length);
          let quantities: Array<any> = [];


          this.products.forEach(product => {
            let price;

            if (product.PriceListPrice && product.PriceListPrice > 0) { // Se estiver numa lista de preços, usamos este valor
              price = product.PriceListPrice;
            } else if (product.OwnerID === this.authenticationService.session.company.ID) { // Se pertencer à empresa do utilizador autenticado é o meu preço de compra
              price = product.NetUnitBuyPrice;
            } else { // Senão é utilizado o preço do fornecedor (venda)
              price = product.NetUnitPrice;
            }

            //valores por defeito
            product.quantity = 1;
            product.uomCode =  product.UOMCode ? product.UOMCode : 'UN';
            product.price = (price ? this.decimalPipe.transform(price, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : '') + (product.BuyCurrencyCode && this.currencies && this.currencies.findIndex(r => r.Code === product.BuyCurrencyCode) !== -1 ? ' ' + this.currencies.find(r => r.Code === product.BuyCurrencyCode).Symbol : '');
          });

          this.zone.onStable.pipe(first()).subscribe(() => {
            Functions.pageGallery();
          });
        } else {
          this.products = [];
          this.totalPages = 0;

          this.errorTreat.treatErrorResponse(response);
        }
      });

    } else {
      this.productService.getAllForPurchasesList(this.parameters, this.supplierID, true, this.reference, this.name, this.category, this.family, this.isFilterByPriceList, this.hasRetention).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          this.products = response.ReturnStatus.ReturnObject.aaData;
          /*if(this.products != null && this.products.length > 0)
            this.initQuantities();*/
          this.totalPages = Math.ceil(response.ReturnStatus.ReturnObject.iTotalRecords / this.length);
          let quantities: Array<any> = [];


          this.products.forEach(product => {
            let price;

            if (product.PriceListPrice && product.PriceListPrice > 0) { // Se estiver numa lista de preços, usamos este valor
              price = product.PriceListPrice;
            } else if (product.OwnerID === this.authenticationService.session.company.ID) { // Se pertencer à empresa do utilizador autenticado é o meu preço de compra
              price = product.NetUnitBuyPrice;
            } else { // Senão é utilizado o preço do fornecedor (venda)
              price = product.NetUnitPrice;
            }

            //valores por defeito
            product.quantity = 1;
            product.uomCode = product.UOMCode ? product.UOMCode : 'UN';
            product.price = (price ? this.decimalPipe.transform(price, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : '') + (product.BuyCurrencyCode && this.currencies && this.currencies.findIndex(r => r.Code === product.BuyCurrencyCode) !== -1 ? ' ' + this.currencies.find(r => r.Code === product.BuyCurrencyCode).Symbol : '');
          });

          this.zone.onStable.pipe(first()).subscribe(() => {
            Functions.pageGallery();
          });
        } else {
          this.products = [];
          this.totalPages = 0;

          this.errorTreat.treatErrorResponse(response);
        }
      });
    }
  }

  // Selecionar o filtro da família
  changeFamily(level: number, $event: any) {
    switch (level) {
      case 1:
        this.familiesLv2 = this.families.filter(r => r.ParentID === +$event.value && r.Level === 2);
        this.familiesLv3 = [];
        this.family = +$event.value;
        break;
      case 2:
        this.familiesLv3 = this.families.filter(r => r.ParentID === +$event.value && r.Level === 3);
        this.family = +$event.value;
        break;
      case 3:
        this.family = +$event.value;
        break;
    }
  }

  // Filtrar a listagem de dados
  filter() {
    this.page = 0;
    this.parameters.start = this.page * this.length;

    this.populateProducts();
  }

  // Limpar os filtros e reconstruir a listagem de dados
  clearFilters() {
    this.reference = null;
    this.name = null;
    this.category = null;
    this.family = null;
    this.supplierName = null;
    this.supplierID = 0;

    this.familyLv1 = null;
    this.familyLv2 = null;
    this.familyLv3 = null;
    this.familiesLv2 = [];
    this.familiesLv3 = [];

    this.page = 0;
    this.parameters.start = this.page * this.length;

    this.populateProducts();
  }

  // Adicionar o produto ao componente que chamou este componente
  add(id: number, quantity: number, uomCode: string) {
    this.addMethod(this.products.find(r => r.ID === id), this.component, quantity, uomCode);
    this.addedProducts.push(id);
  }

  // Verificar se o produto já se encontra adicionado
  isProductAdded(id: number): boolean {
    return this.addedProducts.findIndex(r => r === id) !== -1;
  }

  // Navegar para a página anterior
  previousPage() {
    this.page--;
    this.parameters.start = this.page * this.length;

    this.populateProducts();
  }

  // Navegar para a página seguinte
  nextPage() {
    this.page++;
    this.parameters.start = this.page * this.length;

    this.populateProducts();
  }

/*
  initQuantities() {
    for (let i = 0; i < this.products.length; i++) {
      this.quantities.push({
        ID: this.products[i].ID,
        Quantity: 1,
        UomCode: this.products[i].UOMCode ? this.products[i].UOMCode : 'UN'
      });
    }
  }
*/

  selectSupplier() {
    this.translateService.get(['SELECT_SUPPLIER_TITLE', 'CODE', 'TAX_NUMBER', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'TaxNumber', 'class': 'verticalMiddle', 'title': response['TAX_NUMBER'] }, // 2 - nif
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.suppliers, null, response['SELECT_SUPPLIER_TITLE'], aoColumns, columnDefs, null, 1, null),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.suppliers.findIndex((r: any) => r.ID === resultID);
            this.supplierID = this.suppliers[index].ID;
            this.supplierName = this.suppliers[index].Name;
          }
        }
      });
    });
  }

  getAdditionalInfo(productID: number){
    let additionalInfo = this.products.find(x => x.ID == productID).InfoHtml;
    this.dialog.open(MessageModalComponent, { data: { title: 'ADDITIONAL_INFORMATION', message: additionalInfo } });
  }

  ngOnDestroy() { }
}
