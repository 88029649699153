
import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { AnalisysAxes } from '../models/analisysAxes';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';

@Injectable()
export class AnalisysAxesService {

  private _analisysAxes: Array<any>;

  private _controller: string = 'AnalisysAxes';
  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions) { }

  /**
   * Obter eixos de análise
   * @returns Observable
   */
  public get analisysAxes(): Observable<Array<any>> {
    if (this._analisysAxes === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetAll').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              return this._analisysAxes = responseReturn.ReturnStatus.ReturnObject;

            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._analisysAxes);
    }
  }

  /**
   * Alterar os eixos de análise
   * @returns Observable
   */
  public setAnalisysAxes(list: AnalisysAxes[]) {
    this._analisysAxes = list;
  }

  /**
   * obter eixo de analise por id
   * @param  {number} id
   * @returns Promise
   */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByID?id=' + id).pipe(
      map((response: any) => {
        return this._masterService.convertToReturnStatusHtml(response);
      }));
  }

  /**
   * Eliminar eixo de analise
   * @param  {number} id
   * @returns Promise
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => {
        return this._masterService.convertToReturnStatusHtml(response);
      }));
  }

  /**
   * Editar eixo de analise
   * @param  {AnalisysAxes} analisysAxes
   * @returns Promise
   */
  update(analisysAxes: AnalisysAxes): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', analisysAxes).pipe(
      map((response: any) => {
        return this._masterService.convertToReturnStatusHtml(response);
      }));
  }

  /**
   * Adicionar eixo de analise
   * @param  {AnalisysAxes} analisysAxes
   * @returns Promise
   */
  add(analisysAxes: AnalisysAxes): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', analisysAxes).pipe(
      map((response: any) => {
        return this._masterService.convertToReturnStatusHtml(response);
      }));
  }

  exportAnalisyAxesListToExcel(): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelAnalisyAxesList');
  }
}
