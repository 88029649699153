import { Injectable } from '@angular/core';
import { Observer, Observable } from 'rxjs';
import { ObserveOnSubscriber } from 'rxjs/internal/operators/observeOn';
import { GOOGLEMAPS_KEY, LANGUAGE } from '../constants/global';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';

// Replace this by anything without an ID_KEY
const getScriptSrc = (callbackName, googlemapsKey, language) => {
  // return `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=${callbackName}`;
  return `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googlemapsKey}&callback=${callbackName}&language=${language}`;
};

/**
 * GeocodingService class.
 * https://developers.google.com/maps/documentation/javascript/
 */
@Injectable() export class GeocodingService {
  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private geocoder: google.maps.Geocoder;
  private distance: google.maps.DistanceMatrixService;
  private scriptLoadingPromise: Promise<void>;
  private _errorTreat: ErrorTreatmentFunctions;

  constructor() {
    // Loading script
    this.loadScriptLoadingPromise();
    // Loading other components
    this.onReady().then(() => {
      try {
        this.geocoder = new google.maps.Geocoder()
        this.distance = new google.maps.DistanceMatrixService();
      } catch (error) {
        console.log(error)
      }

    });
  }

  onReady(): Promise<void> {
    return this.scriptLoadingPromise;
  }

  /**
   * Reverse geocoding by location.
   *
   * Wraps the Google Maps API geocoding service into an observable.
   *
   * @param latLng Location
   * @return An observable of GeocoderResult
   */
  geocode(latLng: google.maps.LatLng): Observable<google.maps.GeocoderResult[]> {
    return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
      this.onReady().then(() => {
        if (this.geocoder) {
          // Invokes geocode method of Google Maps API geocoding.
          this.geocoder.geocode({ 'location': latLng }, (
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
              if (status === google.maps.GeocoderStatus.OK) {
                observer.next(results);
                observer.complete();
              }
            })
          );
        }
      });
    });
  }

  /**
   * Geocoding services.
   *
   * Wraps the Google Maps API geocoding service into an observable.
   *
   * @param address The address to be searched
   * @return An observable of GeocoderResult
   */
  codeAddress(address: string): Observable<google.maps.GeocoderResult[]> {
    
    return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {

      this.onReady().then(() => {
        if (this.geocoder) {
          // Invokes geocode method of Google Maps API geocoding.
          this.geocoder.geocode({ 'address': address }, (
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
             
              if (status === google.maps.GeocoderStatus.OK) {
                observer.next(results);
                observer.complete();
              }

            })
          );
        }
      });
    });

  }

  /**
   * Distance Matrix API.
   *
   * Obter a distância entre dois pontos.
   *
   * @param origin The origin address
   * @param destination The destination address
   * @return An observable of DistanceMatrixResponse
   */
  calculateDistance(origin: any, destination: any): Observable<google.maps.DistanceMatrixResponse> {
    return new Observable((observer: Observer<google.maps.DistanceMatrixResponse>) => {
      this.onReady().then(() => {
        if (this.distance) {
          // Invokes distance matrix method of Google Maps API distance matrix.
          this.distance.getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,

          }, (
              (response: google.maps.DistanceMatrixResponse, status: google.maps.DistanceMatrixStatus) => {
                if (status === google.maps.DistanceMatrixStatus.OK) {
                  observer.next(response);
                  observer.complete();
                }
              })
          );
        }
      });
    });
  }

  async initMap(mapHtmlElement: HTMLElement, options: google.maps.MapOptions): Promise<google.maps.Map> {
    await this.onReady();

    this.map = new google.maps.Map(mapHtmlElement, options);

    let marker = new google.maps.Marker({
      position: options.center,
      map: this.map
    });

    return this.map;
  }

  private loadScriptLoadingPromise() {
    const script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    const callbackName: string = 'UNIQUE_NAME_HERE';
    script.src = getScriptSrc(callbackName, GOOGLEMAPS_KEY, LANGUAGE);
    try {
      
    
    this.scriptLoadingPromise = new Promise<void>((resolve: Function, reject: Function) => {
      (<any>window)[callbackName] = () => { resolve(); };

      script.onerror = (error: Event) => { reject(error); };
    });
  } catch (error) {
    this._errorTreat.treatErrorResponse(error);
  }
    window.document.body.appendChild(script);
  }
}
