import { Component, AfterViewInit, ViewChild, ElementRef, OnChanges, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GeocodingService } from '../../../services/geocoding.service';
import { Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'map',
  template: '<div #map class="full-width" [style.min-height]="getMinHeight()"></div>'
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() coordinates?: any = null;
  @Input() address?: string = null;
  @Input() minHeight?: string = '300';
  @ViewChild('map', { static: true }) mapRef: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private geocodingService: GeocodingService) { }

  ngAfterViewInit() {
    this.initMap();
  }

  ngOnChanges() {
    this.initMap();
  }

  private initMap() {
    if (this.coordinates && this.coordinates.latitude && this.coordinates.longitude) {
      this.geocodingService.initMap(this.mapRef.nativeElement, {
        center: { lat: +this.coordinates.latitude, lng: +this.coordinates.longitude },
        scrollwheel: true,
        zoom: 12
      });
    } else if (this.address && this.address.length > 0) {
      this.geocodingService.codeAddress(this.address).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.geocodingService.initMap(this.mapRef.nativeElement, {
          center: { lat: response[0].geometry.location.lat(), lng: response[0].geometry.location.lng() },
          scrollwheel: true,
          zoom: 12
        });
      });
    }
  }

  getMinHeight(): string {
    return this.minHeight + 'px';
  }

  ngOnDestroy() { }
}
