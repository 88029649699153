import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'template-order',
  templateUrl: './template-order.component.html',
})
export class TemplateOrderComponent {
  /** Templates a ordenar */
  @Input("templates") templates: { [key: string]: TemplateRef<any> };

  /** Ordem dos templates */
  @Input("items") items: string[] = [];
}
