import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { MasterService } from './master.service';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { Folder } from '../models/folders';


@Injectable()
export class FoldersService {

  private foldersLabel: string;
  name: string;

  filter_dataIni_P: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_P: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataIni_Create_P: string; // para manter os valores do filtro de data de inicio (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_Create_P: string; // para manter os valores do filtro de data de fim (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem

  datatableParametersFolders: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);

  columnNameSize: string;
  columnOption1Size: string;
  columnOption2Size: string;



  private _documents: Array<any>;
  private _foldersContexts: Array<any>;
  private columnInVisible: number[];

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService,
    private dataTableFunctions: DataTableFunctions) { }
  

  //Serviço comum para Folder

  //#region *** INICIO Folder ***


  private _controller: string = 'Folders';

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_AllFoldersWithWebService(folderID: number,folderIntegrationRoleID: number): Observable<ReturnStatusHtml>{
    return this.http.get(SERVICE_URL + this._controller + '/GetAllFoldersWithWebService?folderID='+folderID+ '&integrationRoleID='+folderIntegrationRoleID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_AllFolders(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllFolders' ).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_FolderDocuments(folderID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllFolderDocuments?id='+folderID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_AllFolderDocuments(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetFoldersDocumentsList').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  downloadFile(metaData: string,fileName:string,id:number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadFile?metaData=' + metaData+'&fileName='+fileName+
    '&folderID='+id);
  }

  visualizeFile(metaData: string,fileName:string,id:number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/VisualizeFile?metaData=' + metaData+'&fileName='+fileName+
    '&folderID='+id);
  }

  get foldersContexts(): Observable<Array<any>> {
    if (this._foldersContexts === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetFoldersContexts').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._foldersContexts = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._foldersContexts);
    }
  }

  addFolder(folder: Folder): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(folder));
    return this.http.put(SERVICE_URL + this._controller + '/AddFolder', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_ColumnVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Folders', 'ColumnsVisible');
      this.columnInVisible = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible;
  }


  get_ColumnNameSize(defaultValue: string): string {
    let settingValue = this.authenticationService.getSettingPortal('Folders', 'ColumnNameSize');
    if (settingValue != null) {
      this.columnNameSize = settingValue;
    } else {
      this.columnNameSize = defaultValue;
    }
    return this.columnNameSize; 
  }

  get_ColumnOption1Size(defaultValue: string): string {
    let settingValue = this.authenticationService.getSettingPortal('Folders', 'ColumnOption1Size');
    if (settingValue != null) {
      this.columnOption1Size = settingValue;
    } else {
      this.columnOption1Size = defaultValue;
    }
    return this.columnOption1Size; 
  }

  get_ColumnOption2Size(defaultValue: string): string {
    let settingValue = this.authenticationService.getSettingPortal('Folders', 'ColumnOption2Size');
    if (settingValue != null) {
      this.columnOption2Size = settingValue;
    } else {
      this.columnOption2Size = defaultValue;
    }
    return this.columnOption2Size; 
  }

}
