// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { ALIAS, LANGUAGE } from '../../constants/global';

// ***__***_________  MODELOS _________ ***__***
import { OrderWorkflowSteps } from '../../models/orderWorkflow';
import { UserService } from 'src/app/services/user.service';
import { ReplaySubject, Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './transaction-modal.html'
})
export class TransactionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  statesList: Array<any> = new Array<any>();
  costCentersList: Array<any> = new Array<any>();

  usersList: Array<any> = new Array<any>();
  approversList: Array<any> = new Array<any>();

  usersIDsList: Array<any> = new Array<any>();
  approversIDsList: Array<any> = new Array<any>();

  rolesList: Array<any> = new Array<any>();

  usersRolesList: Array<any> = new Array<any>();
  approversRolesList: Array<any> = new Array<any>();
  userGroupsList: Array<any> = new Array<any>(); //Armazena a Lista de Grupos de Utilizador

  usersRolesIDsList: Array<any> = new Array<any>();
  approversRolesIDsList: Array<any> = new Array<any>();

  subTypeList: Array<any> = new Array<any>(); //Permite especificar o Subtipo do Documento (p.e Faturas: SimplifiedInvoice -> Trip)

  transactionForm: UntypedFormGroup;
  model: OrderWorkflowSteps = null;
  tabelaUsers: any = null;
  tabelaAppovers: any = null;

  noError: boolean = true;
  submitted: boolean = false;
  formErrors: any = {
    'FromStateID': '',
    'ToStateID': '',
    'ActionVerb': '',
    'HasLimit': '',
    'MinimumLimit': '',
    'MaximumLimit': ''
  }; // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean; // se tem permissoes de edicao
  showExcessAmountFields: boolean = false; //setting para
  filteredToStateIDs: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  filteredFromStateIDs: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  filteredCostCenters: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  hideCostCenter: boolean = false;
  showCreationPopup: boolean = true;

  constructor(public dialogRef: MatDialogRef<TransactionModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private translateService: TranslateService, private formBuilder: UntypedFormBuilder, private userService: UserService) {
    this.statesList = data.states;
    this.costCentersList = data.costCenters;
    this.usersList = data.users;
    this.rolesList = data.roles;
    this.transactionForm = data.stepForm;
    this.model = data.stepModel;
    this.validationMessages = data.validationMessagesSteps;
    this.allowEdit = data.allowEdit;
    this.showExcessAmountFields = data.showExcessAmountFields;
    this.hideCostCenter = data.hideCostCenter;
    this.showCreationPopup = data.showCreationPopup;
    if(data.subTypeList){
      if(data.subTypeList.length > 0){
        this.subTypeList = data.subTypeList;
      }
    }
    if(data.userGroupsList && data.userGroupsList.length > 0)
    {
      this.userGroupsList = data.userGroupsList;
    }

    if (this.model != null) {
      // this.usersIDsList = Object.assign([], data.usersIDsList);
      if (this.usersList != null) {
        this.usersIDsList = JSON.parse(JSON.stringify(data.usersIDsList)) as any;
      }
      // this.approversIDsList = Object.assign([], data.approversIDsList);
      this.approversIDsList = JSON.parse(JSON.stringify(data.approversIDsList)) as any;

      if (data.usersRolesIDsList != null)
        this.usersRolesIDsList = JSON.parse(JSON.stringify(data.usersRolesIDsList)) as any;
      if (data.approversRolesIDsList != null)
        this.approversRolesIDsList = JSON.parse(JSON.stringify(data.approversRolesIDsList)) as any;
    }
    this.filteredToStateIDs.next(this.statesList.slice());
    this.filteredFromStateIDs.next(this.statesList.slice());
    this.filteredCostCenters.next(this.costCentersList.slice());
  }

  ngOnInit(): void {

    let that = this;
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    this.transactionForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChangedSteps(value);
    });

    this.transactionForm.get('ToStateIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
      this.filterArrays(val, this.statesList, this.filteredToStateIDs));

      this.transactionForm.get('FromStateIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
      this.filterArrays(val, this.statesList, this.filteredFromStateIDs));

      this.transactionForm.get('CostCenterIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
      this.filterArrays(val, this.costCentersList, this.filteredCostCenters));

  }

  ngAfterViewInit() {
    this.initUsersTable();
    this.initApproversTable();
    this.initApproversProfilesTable();
    this.initUsersProfilesTable();
  }

  // #region Step
  onValueChangedSteps(value?: any) {
    if (!this.transactionForm) { return; }
    const form = this.transactionForm;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  save() {
    if (this.allowEdit) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.onValueChangedSteps();

      if (this.noError) {
        this.transactionForm.setControl('Approvers', this.formBuilder.array(this.approversIDsList));
        this.transactionForm.setControl('Users', this.formBuilder.array(this.usersIDsList));
        this.transactionForm.setControl('ApproversRoles', this.formBuilder.array(this.approversRolesIDsList));
        this.transactionForm.setControl('UsersRoles', this.formBuilder.array(this.usersRolesIDsList));

        if (this.transactionForm.controls['HasLimit'].value) {
          if (this.transactionForm.controls['MaximumLimit'].value == null || this.transactionForm.controls['MaximumLimit'].value === '') {
            this.transactionForm.controls['MaximumLimit'].setValue(0);
          }
          if (this.transactionForm.controls['MinimumLimit'].value == null || this.transactionForm.controls['MinimumLimit'].value === '') {
            this.transactionForm.controls['MinimumLimit'].setValue(0);
          }
        }
        this.dialogRef.close(this.transactionForm); // nao pode enviar so o value por causa dos campos disabled
      }
      this.submitted = false;
    }
  }

  initUsersTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableUsers-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaUsers = Functions.datatablesWithDataSet('#tabelaWorkflowUsers', this.usersList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaUsers.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableUsers-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.usersIDsList.length > 0) {
              that.usersIDsList.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterUsersID(this.id);
            });
          }
        });
      });
  }

  initUsersProfilesTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableUsersProfiles-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaUsers = Functions.datatablesWithDataSet('#tabelaWorkflowUsersProfiles', this.rolesList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaUsers.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableUsersProfiles-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.usersRolesIDsList.length > 0) {
              that.usersRolesIDsList.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterUsersRolesID(this.id);
            });
          }
        });
      });
  }

  obterUsersID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.usersIDsList.length > 0) {
      let idEncontrado = this.usersIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.usersIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.usersIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.usersIDsList.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.usersIDsList.push(parseInt(id, null));
      }
    }
  }

  obterUsersRolesID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.usersRolesIDsList.length > 0) {
      let idEncontrado = this.usersRolesIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.usersRolesIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.usersRolesIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.usersRolesIDsList.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.usersRolesIDsList.push(parseInt(id, null));
      }
    }
  }

  initApproversTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="a' + id + '" type="checkbox" class="tableApprovers-choose"><label for="a' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaUsers = Functions.datatablesWithDataSet('#tabelaWorkflowApprovers', this.usersList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaUsers.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableApprovers-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.approversIDsList.length > 0) {
              that.approversIDsList.forEach((idLista: any) => {
                let idTabela = 'a' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterApproversID(this.id);
            });
          }
        });
      });
  }

  obterApproversID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "a" do id
    let id = idRecebido.split('a').join('');

    if (this.approversIDsList.length > 0) {
      let idEncontrado = this.approversIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.approversIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) { // "checou" novo approv
        this.approversIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) { // "des-checou" approv
        this.approversIDsList.splice(index, 1);
      }
    } else { // se a lista estiver vazia há de ser sempre adicionar
      if (element.checked) {
        this.approversIDsList.push(parseInt(id, null));
      }
    }
  }

  initApproversProfilesTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="a-p' + id + '" type="checkbox" class="tableApproversProfiles-choose"><label for="a-p' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } }
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        this.tabelaUsers = Functions.datatablesWithDataSet('#tabelaWorkflowApproversProfiles', this.rolesList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        this.tabelaUsers.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableApproversProfiles-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.approversRolesIDsList.length > 0) {
              that.approversRolesIDsList.forEach((idLista: any) => {
                let idTabela = 'a-p' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.obterApproversRolesID(this.id);
            });
          }
        });
      });
  }

  obterApproversRolesID(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "a-p" do id
    let id = idRecebido.split('a-p').join('');

    if (this.approversRolesIDsList.length > 0) {
      let idEncontrado = this.approversRolesIDsList.find((r: any) => r === parseInt(id, null));
      let index = this.approversRolesIDsList.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) { // "checou" novo approv
        this.approversRolesIDsList.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) { // "des-checou" approv
        this.approversRolesIDsList.splice(index, 1);
      }
    } else { // se a lista estiver vazia há de ser sempre adicionar
      if (element.checked) {
        this.approversRolesIDsList.push(parseInt(id, null));
      }
    }
  }

  // #endregion Step

  // #region Métodos Gerais e Comuns

  resetForm() {
    // reset às listas de id's (senão fica com os id's das checkbook selecionadas)
    this.transactionForm.reset(this.model);
  }

  // #endregion Métodos Gerais e Comuns

  private filterArrays(search: string, list: Array<any>, filteredList: ReplaySubject<any[]>) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => item.Name && item.Name.toLowerCase().includes(search))
    );
  }

  ngOnDestroy() { }
}
