import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class DashboardService {
  private _controller: string = 'Dashboard';

  // public year: number = new Date().getFullYear();

  datatableParametersBAGlassOrders: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[0, 'desc']], 0);

  // Settings
  baglassShotcuts: boolean;
  showNews: boolean;
  showNotifications: boolean;
  newsLabel: string;
  hideNewsLabel: boolean; //Define se é necessário esconder a Label de "News"

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService) { }

  /**
   * Obter o conteúdo do ficheiro do repositório
   * @param  {number} associatedFileID ID do ficheiro
   * @returns Observable
   */
  getAssociatedFile(associatedFileID: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/GetAssociatedFile?id=' + associatedFileID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getBanners(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetBanners')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getNews(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNews')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getSlideshow(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetSlideshow')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Devolver a lista de paineis de gráficos
   * @returns Observable
   */
  getDashboardPanels(displayArea: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetDashboardPanels?displayArea=' + displayArea)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  /**
   * Devolver os dados do gráfico
   * @param  {number} id
   * @returns Observable
   */
  getDashboardPanelChartData(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetDashboardPanelChartData?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // BAGlass
  getBAGlassLastOrders(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetBAGlassLastOrders')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  // FIM - BAGlass

  getDeliveryNotesNotifications(): Observable<ReturnStatusHtml>{
    return this.http.get(SERVICE_URL + this._controller + '/GetDeliveryNotesNotifications')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Settings
  get_baglassShotcuts(defaultValue: boolean): boolean {
    if (typeof this.baglassShotcuts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Dashboard', 'BaglassShotcuts');
      if (settingValue != null) {
        this.baglassShotcuts = settingValue;
      } else {
        this.baglassShotcuts = defaultValue;
      }
    }
    return this.baglassShotcuts;
  }

  get_showNews(defaultValue: boolean): boolean {
    if (typeof this.showNews === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Dashboard', 'ShowNews');
      if (settingValue != null) {
        this.showNews = settingValue;
      } else {
        this.showNews = defaultValue;
      }
    }
    return this.showNews;
  }

  get_showNotifications(defaultValue: boolean): boolean {
    if (typeof this.showNotifications === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Dashboard', 'ShowNotifications');
      if (settingValue != null) {
        this.showNotifications = settingValue;
      } else {
        this.showNotifications = defaultValue;
      }
    }
    return this.showNotifications;
  }

  get_NewsLabel(defaultValue: string): string {
    if (typeof this.newsLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Dashboard', 'NewsLabel');
      if (settingValue != null) {
        this.newsLabel = settingValue;
      } else {
        this.newsLabel = defaultValue;
      }
    }
    return this.newsLabel;
  }

  get_HideNewsLabel(defaultValue: boolean): boolean {
    if (typeof this.hideNewsLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Dashboard', 'HideNewsLabel');
      if (settingValue != null) {
        this.hideNewsLabel = settingValue;
      } else {
        this.hideNewsLabel = defaultValue;
      }
    }
    return this.hideNewsLabel;
  }
  // FIM - Settings
}
