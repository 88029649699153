import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONTEXT_PROCESS, CONTEXT_PROCESS_DOCUMENTS, SERVICE_URL } from '../constants/global';
import { DatatableParameters } from '../models/datatable-parameters';
import { PaymentPrevisionConfig } from '../models/payment-prevision-config';
import { ReturnStatusHtml } from '../models/returnStatus';
import { DataTableFunctions } from '../modules/datatables.module';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { AuthenticationService } from './authentication.service';
import { HttpClientCustom } from './http-client';
import { MasterService } from './master.service';
import { CONTEXT_TICKET } from './ticket/ticket.service';


@Injectable()
export class PaymentPrevisionConfigsService {
    //filtros datas ecrã dos processos
    filter_DataIni_P: string;
    filter_DataFim_P: string;

    //filtros datas ecrã dos documentos dos processos
    filter_DataIni_D: string;
    filter_DataFim_D: string;

    datatableParametersPaymentPrevisionConfigs: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[0, 'asc']], 0);

    private _controller = 'PaymentPrevisionEmailsConfig';
    private _states: any;
    private _types: any;
    private _countries: any;
    private _documentTypes: any;
    private _allDocumentTypes: any;
    private _documentStates: any;


    constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
        private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) {
    }


    get types(): Observable<any> {
        if (this._types === undefined) {
            return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_TICKET).pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._types = responseReturn.ReturnStatus.ReturnObject;
                            return this._types;
                        } else {
                            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._types);
        }

    }

    get documentStates(): Observable<any> {
        if (this._documentStates === undefined) {
            return this.http.get(SERVICE_URL + 'Common/GetGenericStates?context=' + CONTEXT_PROCESS_DOCUMENTS).pipe(
                map(response => {
                    try {
                        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
                        if (responseReturn.ReturnStatus.Successfull) {
                            this._documentStates = responseReturn.ReturnStatus.ReturnObject;
                            return this._documentStates;
                        } else {
                            this._errorTreat.treatErrorResponse(responseReturn);
                        }
                    } catch (error) {
                        this._masterService.handleError(error);
                    }
                }));
        } else {
            return observableOf(this._documentStates);
        }
    }


    /****************POST METHODS**********************/

    update(entity: PaymentPrevisionConfig): Observable<ReturnStatusHtml> {
        return this.http.post(SERVICE_URL + this._controller + '/Update', entity).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    create(entity: PaymentPrevisionConfig): Observable<ReturnStatusHtml> {
        return this.http.put(SERVICE_URL + this._controller + '/Add', entity).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    }

    /****************GET METHODS**********************/

    getListForDatatable(genericFieldID: number): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/GetListForDatatable?genericFieldID=' + genericFieldID).pipe(
            map((response: any) => this._masterService.convertToReturnStatusHtml(response))
        );
    }

    getPaymentPrevisionConfig(id: number): Observable<ReturnStatusHtml> {
        return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id)
          .pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
      }


    /****************DELETE METHODS**********************/
    /**
   * Eliminar configuração
   * @param  {number} id
   * @returns Promise
   */
    delete(id: number): Observable<ReturnStatusHtml> {
        return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
            map((response: any) => {
                return this._masterService.convertToReturnStatusHtml(response);
            }));
    }


}