import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { Company } from '../../../models/company';
import { CommonService } from '../../../services/common.service';
import { CURRENCY_SYMBOL } from '../../../constants/global';
import { CompanyService } from '../../../services/company.service';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './company-info-modal.html'
})
export class CompanyInfoModalComponent implements OnDestroy {
  model: Company;
  destroy$: Subject<boolean> = new Subject<boolean>();
  companyCountry: string;
  currency: string = CURRENCY_SYMBOL;
  // Settings
  showCounty: boolean = false;

  constructor(public dialogRef: MatDialogRef<CompanyInfoModalComponent>, private authenticationService: AuthenticationService, commonService: CommonService,
    companyService: CompanyService) {
    // Settings
    this.showCounty = companyService.get_showCounty(this.showCounty);

    this.model = this.authenticationService.session.company;

    // Países, se a empresa tiver o país definido
    if (this.model.Country) {
      commonService.countries.pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response) {
          this.companyCountry = response.filter(r => r.Code === this.model.Country)[0].Name;
        }
      });
    }
  }

  ngOnDestroy() { }
}
