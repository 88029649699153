export class TipologiaProdutoServico {
    constructor(
        public ID: number,
        public IntegrationID: string,
        public Name: string,
        public TipoTratamentoIva: string,
        public ParentIntegrationID: string,
        public ParentName: string,
        public LastUpdated: Date = null,
        public Active: boolean = true,
        public Exported: boolean = false,
        public ParentID?: number,
    ) { }
}