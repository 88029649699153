import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Method, TableRequest } from '../models/table-request';
import { AuthenticationService } from './authentication.service';
import { HttpClientCustom } from './http-client';
import { MasterService } from './master.service';
import { SERVICE_URL } from '../constants/global';
import { TableFilter, TableFilterRow } from '../models/table-column';

@Injectable()
export class GenericTableService {

    constructor(private http: HttpClientCustom, private authenticationService: AuthenticationService,
        private _masterService: MasterService) {
    }
    
    /**
   * Faz pedido com um tipo Generico
   * @param  {TableRequest} request
   * @returns Observable
   */
    request(request : TableRequest, filters?: TableFilter): Observable<ReturnStatusHtml>
    {
        
        if(filters){
            if(request.Method == Method.GET) {
                return this.http.request(request.Method, SERVICE_URL + request.Url + "?filter="+filters,{})
                .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)))
            }

            request.Body.filter = filters;
            
        }
            
        return this.http.request(request.Method, SERVICE_URL + request.Url, request.Body)
            .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)))
    }
}