import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { TranslateValueService } from '../translate-value.service';
import { Observable } from 'rxjs';

declare var Functions: any;

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService, private translateValueService: TranslateValueService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // chamar serviço para saber se login está efetuado
    return this.authenticationService.isAuthenticated().toPromise().then(response => {
      if (response.isAuthenticated && !this.authenticationService.session.user.PasswordExpired) {
        // comparação nao ter em atencao as maisculas e minuscula
        if (!route.data['menu'] || (route.data['menu'] && this.authenticationService.session.permissions.filter(r => r.Name.toLowerCase() === route.data['menu'].toLowerCase())[0])
          || (route.data['menu'] && route.params.context && (this.authenticationService.session.permissions.filter(r => r.Name.toLowerCase() === route.params.context.toLowerCase() + route.data['menu'].toLowerCase())[0] 
          || this.authenticationService.session.permissions.filter(r => r.Name.toLowerCase() === route.data['menu'].toLowerCase())[0] + route.params.context.toLowerCase() ))
          || (route.data.menu && route.params.id && this.authenticationService.session.permissions.filter(r => r.Name.toLowerCase() === route.data.menu.toLowerCase() + route.params.id)[0])
          || (route.params.menuid && this.authenticationService.session.permissions.find(r => r.Name.toLowerCase() === route.params.menuid.toLowerCase()))) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          Functions.gritter(this.translateValueService.get('NO_PERMISSION_FOR_PAGE_ACCESS'), 'danger');
          return false;
        }
      } else {
        // redirect the user before denying them access to this route
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, isExpired: this.authenticationService.session.user && this.authenticationService.session.user.PasswordExpired } });
        return false;
      }
    });
  }
}
