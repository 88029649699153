import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CONTEXT } from '../../../constants/global';

@Component({
  templateUrl: './entity-changes-modal.html'
})
export class EntityChangesModalComponent {
  context: string;
  changes: any[] = [];
  pageStateType: string;

  constructor(public dialogRef: MatDialogRef<EntityChangesModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, translateService: TranslateService, ) {
    this.context = data.context;
    this.changes = data.changes;
    // Traduções Iniciais
    translateService.get(CONTEXT[this.context].STATE_TYPE).subscribe(response => this.pageStateType = response);
  }
}
