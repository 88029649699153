
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { Observable, Subject } from 'rxjs';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Message } from '../models/message';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class MessageService {

  private _controller: string = 'Message';

  private _share = new Subject();
  share = this._share.asObservable();

  allowDeleteMessagesRoleID: number;
  sendNotificationLabel: string;
  showSendNotification: boolean;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private errorTreatmentFunctions: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) { }

  /**
   * Obter lista mensagens
   * @param  {string} context
   * @param  {number} entityID
   * @returns Observable
   */
  get(context: string, entityID: number, datai: string = null, dataf: string = null, partnerID: number = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAll', { context: context, entityID: entityID, datai: datai, dataf: dataf, partnerID: partnerID })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Contar a quantidade da lista mensagens
   * @param  {string} context
   * @param  {number} entityID
   * @returns Observable
   */
  count(context: string, entityID: number, datai: string = null, dataf: string = null, partnerID: number = null): Observable<number> {
    return this.get(context, entityID, datai, dataf, partnerID)
      .pipe(map((response: ReturnStatusHtml) => response.ReturnStatus.Successfull ? response.ReturnStatus.ReturnObject.Messages.length : 0, () => 0));
  }

  /**
   * Eliminar mensagem
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar mensagem
   * @param  {Message} entity
   * @returns Observable
   */
  add(entity: Message, datai: string = null, dataf: string = null, partnerID: number = null): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Create', { entity: entity, datai: datai, dataf: dataf, partnerID: partnerID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter lista mensagens
   * @param  {string} context
   * @param  {number} entityID
   * @returns Observable
   */
  getAllNotReadGroupedByOwner(): Observable<ReturnStatusHtml> {
    return this.http.getBackend(SERVICE_URL + this._controller + '/GetAllNotReadGroupedByOwner').pipe(
      map((response: any) => this.errorTreatmentFunctions.treatServerResponse(response)));
  }

  /**
 * Obter lista mensagens e partilhar o valor
 * @param  {string} context
 * @param  {number} entityID
 * @returns Observable
 */
  refreshNotifications() {
    this._share.next(this.http.getBackend(SERVICE_URL + this._controller + '/GetAllNotReadGroupedByOwner').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response))));
  }

  getAllowDeleteMessagesRoleID(defaultValue: number): number {
    if (typeof this.allowDeleteMessagesRoleID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Messages', 'AllowDeleteMessagesRoleID');
      if (settingValue != null) {
        this.allowDeleteMessagesRoleID = settingValue;
      } else {
        this.allowDeleteMessagesRoleID = defaultValue;
      }
    }
    return this.allowDeleteMessagesRoleID;
  }

  getSendNotificationLabel(defaultValue: string): string {
    if (typeof this.sendNotificationLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Messages', 'SendNotificationLabel');
      if (settingValue != null) {
        this.sendNotificationLabel = settingValue;
      } else {
        this.sendNotificationLabel = defaultValue;
      }
    }
    return this.sendNotificationLabel;
  }

  //#endregion SendNotificationLabel

  getShowSendNotification(defaultValue: boolean): boolean {
    if (typeof this.showSendNotification === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Messages', 'ShowSendNotification');
      if (settingValue != null) {
        this.showSendNotification = settingValue;
      } else {
        this.showSendNotification = defaultValue;
      }
    }
    return this.showSendNotification;
  }

  private defaultValueAndDisabledSendNot: boolean;
  get_defaultValueAndDisabledSendNot(defaultValue: boolean): boolean{
    if (typeof this.defaultValueAndDisabledSendNot === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Messages', 'DefaultValueAndDisabledSendNot');
      if (settingValue != null) {
        this.defaultValueAndDisabledSendNot = settingValue;
      } else {
        this.defaultValueAndDisabledSendNot = defaultValue;
      }
    }
    return this.defaultValueAndDisabledSendNot;
  }
  

}
