import { TemplateRef } from "@angular/core";
import { TranslateValueService } from "../services/translate-value.service";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { DecimalPipe } from "@angular/common";

export class InputColumns {
    constructor(
        /// Nome da Coluna
        public Name : string,
        /// Tipo de Input
        public InputType : InputTypes,
        //Lista de Opções para listar no Caso em que seja necessário Input da ComboBox
        //Se For necessario mostrar apenas os valores de objectos
        public ListOptionsCombobox? : ListOptionsCombobox,

        public Function?: (args: any, translateService? : TranslateValueService, dialog? : MatLegacyDialog, decimalPipe? : DecimalPipe) => void,
        /** Chamar so no AfterViewInit */
        public CustomTemplate? : TemplateRef<any>,

        public RouteOptions? : RouteOptions,

        //Por Defeito os valores da tabela vao alterar logo que se faz uma alteracao
        //Mas as vezes e necessario que o nao valor atualize logo 
        public BlockNgModel? : boolean,

        public MaxWidth?: string
    ) { }
}

export enum InputTypes {
    Text = "text",
    Number = "number",
    Date = "date",
    Combobox = "combobox",
    Icons = "icons",
    Function = "function",
    Custom= "custom",
    ReactiveForms = "reactiveForms",
    Route = "route",
    States = "states",
    NumberFormat = "numberFormat"
}

/* declare type OrderTypes = 'ASC' | 'DESC' | ''; */

export class ListOptionsCombobox{

    constructor(
        public ShowProperty : string,
        public ValueProperty: string,
        //Se tiver a Lista Coloca a Lista na Combobox
        public List? : any,
        //Se a lista for uma propriedade da Entidade, colocar o Nome da Propriedade
        public ListProperty? : string
    ) {}
}

export class RouteOptions{

    constructor(
        public RouteUrl : string,
        public Params? : {},
    ) {}
}