// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, AfterViewInit, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';

// ***__***_________  MODELOS _________ ***__***
import { ChooseModalParam } from 'src/app/models/choose-modal-param';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { ALIAS, SERVICE_URL, DATE_FORMAT, LANGUAGE } from '../../../constants/global';

// ***__***_________  VARIAVEIS JAVASCRIPT _________ ***__***
import { Filter, FiltersType, SelectElementData, FilterColumnTable } from '../../../modules/dockmodal.module';
import { TranslateValueService } from '../../../services/translate-value.service';
import { DatatableParameters } from '../../../models/datatable-parameters';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModelValidators } from 'src/app/models/validators/validators';
import { ProductService } from '../../../services/product.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare var Functions: any;

@Component({
  templateUrl: './product-choose-modal.html'
})
export class ProcuctChooseModalComponent implements AfterViewInit {

  list: Array<any> = new Array<any>(); // listagem de dados
  actionTable: string = null; // listagem de dados
  table: any = null;
  idSelec: number;
  indexSelec: number;
  modalTitle: string; // titulo da modal
  aoColumnsList: Array<any> = new Array<any>(); // colunas para a datatable
  columnDefsList: Array<any> = new Array<any>(); // definição colunas para a datatable
  filters: Array<Filter> = new Array<Filter>(); // array com o filtros dos dados para colocar os inputs - pode ir a null caso nao tenha filtros
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  filtersColumns: Array<FilterColumnTable> = new Array<FilterColumnTable>(); // array com o filtros das colunas, caso nao sejam inputs normais
  actionParams: Array<Filter> = null;
  checkID = null;
  allowNone = null;
  filterExpanded: boolean = false;
  filterDate: boolean = false;
  form: UntypedFormGroup;
  supplierID: number;


  columnID: string = 'IntegrationID';

  constructor(public dialogRef: MatDialogRef<ProcuctChooseModalComponent>, @Inject(MAT_DIALOG_DATA) data: ChooseModalParam, private _translateService: TranslateService,
    private _translateValueService: TranslateValueService, private formBuilder: UntypedFormBuilder, public productService: ProductService, public authenticationService: AuthenticationService) {
    
    if (!this.authenticationService.session.company.ServiceProvider) {
      this.supplierID = this.authenticationService.session.serviceProvider.ID;
    }
  }

  

  ngAfterViewInit(): void {
    this._translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'REFRESH', 'BUTTON.ALL', 'SERVER_TIMEOUT', 'SERVER_ERROR', 'FILTER', 'BUTTON.CONSULT', 'AUTHENTICATION_EXPIRED', 'EXPORT', 'EXCEL'])
      .subscribe(response => {
        let that = this;

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response.REFRESH,
          all: response['BUTTON.ALL'],
          serverTimeout: response.SERVER_TIMEOUT,
          serverError: response.SERVER_ERROR,
          search: response['FILTER'],
          authenticationExpired: response.AUTHENTICATION_EXPIRED
        }, aoColumns = [], columnDefs = [
          { 'targets': [0], 'visible': false }, // colocar como hidden o ID
          { 'targets': [0, -1], 'orderable': false }, // nao permitir ordenar pelas colunas
          { 'targets': [-1], 'width': '110px' }, // colocar a ultima coluna mais pequena
        ];
        aoColumns = [
          { 'data': 'ID' },
          { 'data': 'IntegrationID', 'class': 'verticalMiddle'},
          { 'data': 'Name', 'class': 'verticalMiddle' },
        ];

        let buttons = function (id: string): string {
          let checked = (that.checkID && id.toString() === that.checkID.toString()) ? 'checked' : '';
          let chooseThis = '<div style="text-align:center" class="md-radio btnTable-choose"><input id="' + id + '" type="radio" name="radioLocation" ' + checked + '><label for="' + id + '" class="" ></label></div>';
          return chooseThis;
        };

        aoColumns.push({ 'data': that.columnID, 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } });

        this.table = Functions.datatables('#tableChoose', SERVICE_URL + '/Product/GetAllForPurchasesList',
          translates, aoColumns, columnDefs, ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 'POST', this.setValues(), true, false, false, false, this.productService.datatableParametersBaglass);


        this.table.on('draw', function () {
          if (!that.list && that.table.ajax.json()) {
            let autenticado = that.table.ajax.json().IsAuthenticated;
            if (!autenticado) {
              // se tiver perdido a sessao tem de fechar a modal
              that.dialogRef.close(null);
            }
          }


          let btnsChoose = document.getElementsByClassName('btnTable-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            chooseThis.addEventListener('click', function (ev: any) {
              let linha = this.parentNode.parentNode;
              let row = that.table.row(linha);
              let rowData = row.data();
              let rowIndex = row.index();
              if (ev.target.tagName === 'LABEL') { // necessario por o check vai clicar tambem no input
                that.selecItem(rowData[that.columnID], rowIndex, linha);
              }
            });
          }
        });
      });
  }

  selecItem(idSelecionado: number, index: number, linha: any) {
    let rows = document.getElementsByClassName('btnTable-choose'); // todos os 'a' dos botoes
    if (rows.length > 0) {
      // retirar linha que possa estar selecionada, porque só pode selecionar uma
      [].forEach.call(rows, function (el: any) {
        let row = el.parentNode.parentNode; // linha "mãe" do botão
        row.classList.remove('td-selected');
      });
    }
    linha.classList.add('td-selected');
    this.idSelec = idSelecionado;
    if (!this.list) { // se os dados forem do controlador
      this.indexSelec = index; // vai colocar o index
    }

    this.save(); // se for para fechar a modal logo que escolhe uma opção
  }

  filterValues() {
    // ir buscar, caso existam os valores dos filtros alem dos filtros das coluna da datatable
    let filterValues = null;
    if (this.filters) { // se tiver inputs de filtros
      this.filters.forEach((filterData: Filter) => {
        let inputData = filterData.Type === FiltersType.Boolean ?
          (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
          (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;

        if ((filterData.Type === FiltersType.Boolean && inputData) || (filterData.Type !== FiltersType.Boolean && inputData.toString().length > 0)) {
          // se tiver valor é que vai adicionar ao objecto de filtros
          if (!filterValues) { // se ainda estiver a null
            filterValues = {};
          }
          filterValues[filterData.IDToFind] = inputData;
        }
      });
    }
    return filterValues;

  }

  onClickFilterDates()
  {
    let result = null
    if (this.actionParams) {
      if (!result) {
        result = {};
      }
      this.actionParams.forEach((filterData: Filter) => {
        let inputData = filterData.InitialValue;
        result[filterData.IDToFind] = inputData;
      });
    }
    if(this.filterDate && this.form.valid){
      if (!result) {
        result = {};
      }
      result.startDate = this.form.get("dataInicio").value;
      result.endDate = this.form.get("dataFim").value;
    }
    if(result)
      this.table.reloadTableParameters(result);
  }

  save() {
    let filterValues = this.filterValues();
    if (this.list) { // se for lista apenas vai devolver o id do selecionado
      if (this.idSelec != null || typeof this.idSelec === 'undefined') {
        // neste momento os valores dos filtros ainda nao sao necessarios por isso nao esta ser utilizado
        // let returnV = filterValues ? new ChooseModalReturn(this.idSelec, filterValues) : this.idSelec;
        let returnV = this.idSelec;
        this.dialogRef.close(returnV);
      }
    } else { // se tiver a ir buscar os dados da tabela ao controlador, vai enviar todos os dados da linha
      // validar se for selecionado algum, senao se fizer guardar sem selecionar, vai carregar o index 0 da lista
      if (typeof (this.indexSelec) !== 'undefined' && this.indexSelec >= 0) {
        // let returnV = filterValues ? new ChooseModalReturn(this.table.row(this.indexSelec).data(), filterValues) : this.table.row(this.indexSelec).data();

        let returnV = this.table.row(this.indexSelec).data();
        if (filterValues) {
          returnV.FiltersValues = filterValues;
        }
        if(this.filterDate && this.form.valid)
        {
          returnV.startDate = this.form.get("dataInicio").value;
          returnV.endDate = this.form.get("dataFim").value;
        }
        this.dialogRef.close(returnV);
      } else {
        // é para ignorar e não fazer nada
        // this.dialogRef.close(null);
      }
    }
  }

  setValues(): any {
    this.productService.filtersBaglass = new Object();
    this.productService.filtersBaglass.CategoryID = '';
    this.productService.filtersBaglass.HeightMin ='';
    this.productService.filtersBaglass.HeightMax = '';
    this.productService.filtersBaglass.CapacityMin = '';
    this.productService.filtersBaglass.CapacityMax = '';
    this.productService.filtersBaglass.WeightMin = '';
    this.productService.filtersBaglass.WeightMax = '';
    this.productService.filtersBaglass.DiameterMin = '';
    this.productService.filtersBaglass.DiameterMax = '';
    this.productService.filtersBaglass.Exclusivity = '';
    this.productService.filtersBaglass.Color = '';

    return { filters: this.productService.filtersBaglass, supplierID: this.supplierID };
  }


  cancel() {
    let returnV = this.filterValues() ? { FiltersValues: this.filterValues() } : null;
    this.dialogRef.close(returnV);
  }

  removerSeleccao() {
    // se permitir que nao esteja nenhum selecionado, vai apagar o que estiver selecionado
    if (this.allowNone) {
      this.checkID = null;
      this.idSelec = null;
      //vai enviar para o pai o valor -1 para indicar que não foi nada selecionado
      this.dialogRef.close(-1);
      // so ia precisar desta parte se não fosse fechar a janela e quissesse mostrar que nenhum fica selecionado
      // let radios = document.querySelectorAll('[name="radioLocation"]');
      // for (let i = 0; i < radios.length; i++) {
      //   let radio = <HTMLInputElement>radios[i];
      //   if (radio.checked) {
      //     radio.checked = false;
      //   }
      // }
    }

  }
}