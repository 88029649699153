import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ReturnStatusHtml } from '../models/returnStatus';
import { TranslateValueService } from '../services/translate-value.service';

declare var Functions: any;

@Injectable()
export class ErrorTreatmentFunctions {

  constructor(private _router: Router) { }

  /**
   * Tratamento a fazer se a resposta do servidor não for de sucesso
   * @param  {ReturnStatusHtml} serverResponse - resposta do servidor
   * @returns Observable
   */
  treatServerResponse(serverResponse: ReturnStatusHtml): ReturnStatusHtml {
    try {
      let response = serverResponse as ReturnStatusHtml;

      if (!response.IsAuthenticated) {
        document.location.href = document.location.origin;
      } else if (response.ReturnStatus.Successfull && response.ReturnStatus.WarningMessage) { // Warning
        Functions.gritter(response.ReturnStatus.WarningMessage, 'warning');
      } else if (!response.ReturnStatus.Successfull) { // Erro
        Functions.gritter(response.ReturnStatus.ErrorMessage ? response.ReturnStatus.ErrorMessage : 'Error.', 'danger');
      }

      return response as ReturnStatusHtml;
    } catch (error) {
      console.error('An error occurred', error); // for demo purposes only
      Functions.gritter(error, 'danger');
    }
  }

  /**
   * Tratamento a fazer se a resposta do controlador nao for de sucesso
   * @param  {ReturnStatusHtml} response - resposta do controlador
   * @param  {boolean} showGritter? - se null ou true vai mostar o alerta lateral a informar do erro
   * @param  {string} newRoute? - preencher se nao for para enviar para o login
   */
  treatErrorResponse(response: ReturnStatusHtml, showGritter?: boolean, newRoute?: string, returnUrl?: string, reloadRoute: boolean = false, lineBreak: string = null) {
    if (!response.IsAuthenticated) {
      if (newRoute) {
        if(reloadRoute){
          document.location.href = 'login?returnUrl=' + returnUrl;
        }else{
          this._router.navigate([newRoute], returnUrl ? {queryParams: {returnUrl: returnUrl} } : null);
        }
      } else {
        // this._router.navigate(['/login']);
        document.location.href = document.location.origin;
      }
    } else {
      showGritter = showGritter == null ? true : showGritter;
      // Verifica se permissão de mostrar popups
      if (showGritter) {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.WarningMessage) { // Warning
          Functions.gritter(response.ReturnStatus.WarningMessage, 'warning');
        } else { // Erro
          let error: string = "";
          if (lineBreak != null)
          {
            error = response.ReturnStatus.ErrorMessage.replaceAll('\n', lineBreak);
          }
          else
          {
            error = response.ReturnStatus.ErrorMessage;
          } 
          Functions.gritter(error, 'danger');
        }
      }
    }
  }

  /**
 * Tratamento a fazer se a resposta do controlador nao for de sucesso enviar para login ou array vazio
 * @param  {ReturnStatusHtml} response - resposta do controlador
 */
  treatErrorResponseSendEmpty(response: ReturnStatusHtml) {
    if (!response.IsAuthenticated) {
      // this._router.navigate(['/login']);
      document.location.href = document.location.origin;
    } else {
      return [];
    }
  }
}



@Injectable()
export class FileTreatmentFunctions {

  constructor(private _domSanitizer: DomSanitizer, private translateValueService: TranslateValueService) { }

  /**
    * Apos fazer o export do excel ou pdf, para criar o dom temporario e tratar erros
    * @param  {any} response - resposta do export
    * @param  {string} name - nome a dar ao ficheiro (extensao incluida)
    */
  afterDownloadFile(response: any, name: string) {
    // if (response.statusText && response.statusText !== 'OK') {
    //   Functions.gritter(response.statusText, 'danger');
    // } else if (response.ok) {
    //   if (response._body) {
    //     this.afterBlobFile(response._body, name);
    //   }
    // }
    this.afterBlobFile(response, name);
  }


  afterDownloadFileNewWindow(response: any, name: string) {
    // if (response.statusText && response.statusText !== 'OK') {
    //   Functions.gritter(response.statusText, 'danger');
    // } else if (response.ok) {
    //   if (response._body) {
    //     this.afterBlobFile(response._body, name);
    //   }
    // }
    this.afterBlobFileNewWindow(response, name);
  }

  afterBlobFile(blob: any, name: string) {
    if (!blob || blob.size === 0) {
      Functions.gritter(this.translateValueService.get('NO_INFORMATION_TO_EXPORT'), 'danger');
      return;
    }

    // criar elemento temporario
    let v : any = window.navigator;
    if (v && v.msSaveOrOpenBlob) {
      v.msSaveOrOpenBlob(blob, name);
    } else {

      let aTemp: HTMLAnchorElement = document.createElement('a'),
        url = window.URL.createObjectURL(blob);

      document.body.appendChild(aTemp);
      aTemp.style.display = 'none';
      aTemp.href = (<any>this._domSanitizer.bypassSecurityTrustUrl(url)).changingThisBreaksApplicationSecurity;
      aTemp.download = name;

      aTemp.target = '_blank'; // se for usado o utilizador tem de permitir popups bloqueadas

      aTemp.click();

      /*
       *****  se for para verificar se as janelas estao bloqueadas e enviar um alert  *****
      let newWin = window.open(url);
      if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
        alert('Verifique se os popups do browser estão bloqueados');
      }
      */

      window.URL.revokeObjectURL(url);
      aTemp.parentNode.removeChild(aTemp);

    }
  }

  afterBlobFileNewWindow(blob: any, name: string) {
    if (!blob || blob.size === 0) {
      Functions.gritter(this.translateValueService.get('NO_INFORMATION_TO_EXPORT'), 'danger');
      return;
    }

    // criar elemento temporario
    let v : any = window.navigator;
    if (v && v.msSaveOrOpenBlob) {
     v.msSaveOrOpenBlob(blob, name);
    } else {

      const url = window.URL.createObjectURL(blob);
      window.open(url,'_blank');
      
    }
  }

}
