import { of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';

@Injectable()
export class ServicesService {

  private _controller: string = 'Services';

  constructor(private http: HttpClientCustom, private _masterService: MasterService) { }

  manageDocument(id: string, action: string, stepid: string, locale: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ManageDocumentByApp', { id: id, action: action, stepid: stepid, locale: locale }).pipe(
      map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  viewDocument(id: string, documentType: string, locale: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/ViewDocumentByApp?id=' + id + '&documentType=' + documentType + '&local=' + locale, false)
      .pipe(catchError(err => observableOf(err)));
  }
}
