import { GenericFieldExtension } from './generic-field-extension';
import { LocationABCClassification } from './location-abc-classification';

export enum LocationType { IsShipToAddress = 1, IsPayFromAddress = 2, IsInvoiceToAddress = 3, IsWareHouse = 4 }

export class Location {
  public Address?: string;

  constructor(
    public ID: number,
    public OwnerID: number,
    public IntegrationID: string,
    public IsActive: boolean,
    public Name: string,
    public IsShipToAddress: boolean,
    public IsPayFromAddress: boolean,
    public IsInvoiceToAddress: boolean,
    public IsWareHouse: boolean,
    public GLNCode?: string,
    public GroupGLNCode?: string,
    public CountryCode?: string,
    public Street?: string,
    public City?: string,
    public PostalCode?: string,
    public Phone?: string,
    public PhoneAlt?: string,
    public Fax?: string,
    public Email?: string,
    public Notes?: string,
    public ShipToInstructions?: string,
    public LocationUsers?: any[],
    public TaxNumber?: string,
    // public LotNumber?: string,
    public Municipality?: string,
    // public Region?: string,
    // public PostalBox?: string,
    public CountryName?: string,
    public Latitude?: string,
    public Longitude?: string,
    public ProvisioningType?:number,
    public ProvisioningAlgorithm?:GenericFieldExtension ,
    public AverageCalculateDays?:GenericFieldExtension,
    public LeadTime?:GenericFieldExtension,
    public SafeStockFactor?:GenericFieldExtension,
    public IsDefault: boolean = false,
    public FieldsExtensions: GenericFieldExtension[] = [],
    // Propriedades Auxiliares
    public LocationAssociations: GenericFieldExtension[] = null,
    public ReferenceID: number = null,
    public Region: string = null,
    public PostalCodeDistrict: string = null, // readonly com base no código postal LocationsPostalCodes
    public PostalCodeRegion: string = null, // readonly com base no código postal LocationsPostalCodes
    public IntegrationCodes: GenericFieldExtension[] = null,
    public Aggregate: boolean = false,
    public HasWms: boolean = false,
    public ExportDeliveryNote: boolean = false,
    public WmsCode: string = null,
    public XDock: boolean = null,
    public WmsNoControlCode?:GenericFieldExtension ,
    public WmsWithControlCode?:GenericFieldExtension,
    public ClassificationConfigurations: LocationABCClassification[] = [],
    public ReplacementPeriod?: number,
    public Color? :string
  ) { }
}
