import { Optional } from '@angular/core';

export class IntegrationProfile {

  constructor(
    public ID: number = 0,
    @Optional() public Context: string = null,
    @Optional() public Name: string = null,
    @Optional() public InSP: string = null,
    @Optional() public OutSP: string = null,
    @Optional() public ProfileType: string = null,
    @Optional() public RequestMethod: string = null,
    @Optional() public URL: string = null,
    @Optional() public HeaderContentType: string = null,
    @Optional() public HeaderAuthorization: string = null,
    @Optional() public HeaderUsername: string = null,
    @Optional() public HeaderPass: string = null,
    @Optional() public CreationDate: string = null,
    @Optional() public LogsPath: string = null,
    @Optional() public CompanyID: number = null,
    @Optional() public Action: string = null,
    @Optional() public Authentication: boolean = null,
    @Optional() public AuthorizationType: string = null
  ) { }
}



