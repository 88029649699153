import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { MasterService } from './master.service';
import { ReturnStatusHtml } from '../models/returnStatus';
import { DatatableParameters } from '../models/datatable-parameters';
import { CompanyGroup } from '../models/company-group';
import { AuthenticationService } from './authentication.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';

@Injectable()
export class CompanyGroupService {

  private _controller: string = 'CompanyGroup';

  private showOnlyServiceProviders: boolean;
  private partnersLabel: string;
  private partnersListLabel: string;

  datatableParameters: DatatableParameters = new DatatableParameters([null, null], 10, [[1, 'asc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService, private _errorTreat: ErrorTreatmentFunctions) { }

  get(id: number, group: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&group=' + group)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getAll(groupType: string = null): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAll' + (groupType ? '?groupType=' + groupType : ''))
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: CompanyGroup): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', { entity: model })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: CompanyGroup): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', { entity: model })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number, group: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id + '&group=' + group)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getAllCompaniesGroups(groupType: string = null): Observable<CompanyGroup[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAll?groupType=' + groupType).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }



  //SETTINGS
  get_showOnlyServiceProviders(defaultValue: boolean): boolean {
    if (typeof this.showOnlyServiceProviders === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CompanyGroup', 'ShowOnlyServiceProviders');
      if (settingValue != null) {
        this.showOnlyServiceProviders = settingValue;
      } else {
        this.showOnlyServiceProviders = defaultValue;
      }
    }
    return this.showOnlyServiceProviders;
  }

  get_partnersLabel(defaultValue: string): string {
    if (typeof this.partnersLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CompanyGroup', 'PartnersLabel');
      if (settingValue != null) {
        this.partnersLabel = settingValue;
      } else {
        this.partnersLabel = defaultValue;
      }
    }
    return this.partnersLabel;
  }

  get_partnersListLabel(defaultValue: string): string {
    if (typeof this.partnersListLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('CompanyGroup', 'PartnersListLabel');
      if (settingValue != null) {
        this.partnersListLabel = settingValue;
      } else {
        this.partnersListLabel = defaultValue;
      }
    }
    return this.partnersListLabel;
  }

}
