import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { RecoveryComponent } from './components/login/recovery.component';
import { CustomErrorComponent } from './components/custom-error/custom-error.component';
import { ServiceComponent } from './components/service/service.component';
import { RegisterComponent } from './components/login/register.component';
import { TermsConditionsComponent } from './components/login/terms-conditions.component';
import { ApprovalScreenComponent } from './components/approval-screen/approval-screen.component';
import { RegisterSupplierComponent } from './components/login/registerSupplier.component';

export const AppComponentList = [
  LayoutComponent,
  LoginComponent,
  RecoveryComponent,
  RegisterComponent,
  CustomErrorComponent,
  ServiceComponent,
  TermsConditionsComponent,
  ApprovalScreenComponent,
  RegisterSupplierComponent
];

export const AppRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      //   ,{ path: '**', component: PageNotFoundComponent }
      // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { title: 'DASHBOARD', menu: 'Dashboard' } },
      // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { title: 'DASHBOARD' } },
      { path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'partner', loadChildren: () => import('./components/partner/partner.module').then(m => m.PartnerModule) },
      { path: 'user', loadChildren: () => import('./components/user/user.module').then(m => m.UserModule) },
      { path: 'email', loadChildren: () => import('./components/email/email.module').then(m => m.EmailModule) },
      { path: 'company', loadChildren: () => import('./components/company/company.module').then(m => m.CompanyModule) },
      { path: 'profile', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
      { path: 'location', loadChildren: () => import('./components/location/location.module').then(m => m.LocationModule) },
      { path: 'category', loadChildren: () => import('./components/category/category.module').then(m => m.CategoryModule) },
      { path: 'family', loadChildren: () => import('./components/family/family.module').then(m => m.FamilyModule) },
      { path: 'productEditor', loadChildren: () => import('./components/product/productEditor.module').then(m => m.ProductEditorModule) },
      { path: 'productViewer', loadChildren: () => import('./components/product/productViewer.module').then(m => m.ProductViewerModule) },
      { path: 'product-baglass', loadChildren: () => import('./components/product/baglass/product-baglass.module').then(m => m.ProductBaglassModule) },
      { path: 'brand', loadChildren: () => import('./components/brand/brand.module').then(m => m.BrandModule) },
      { path: 'invoicePurchase', loadChildren: () => import('./components/invoices/deprecated/invoiceP.module').then(m => m.InvoicePModule) },
      { path: 'invoice-purchase', loadChildren: () => import('./components/invoices/purchase/invoice-purchase.module').then(m => m.InvoicePurchaseModule) },
      { path: 'invoice-purchaseAllCompanies', loadChildren: () => import('./components/invoices/allCompanies/invoice-purchase.module').then(m => m.InvoicePurchaseAllCompaniesModule) },
      { path: 'invoiceSale', loadChildren: () => import('./components/invoices/invoiceS.module').then(m => m.InvoiceSModule) },
      { path: 'invoiceAT', loadChildren: () => import('./components/invoices/invoiceAT/invoice-AT.module').then(m => m.InvoiceATModule) },
      { path: 'applicationsAT', loadChildren: () => import('./components/invoices/applications/applications.module').then(m => m.ApplicationsModule) },
      { path: 'orderPurchase', loadChildren: () => import('./components/orders/orderPurchase.module').then(m => m.OrderPurchaseModule) },
      { path: 'orderPurchaseAllCompanies', loadChildren: () => import('./components/orders/allCompanies/orderPurchaseAllCompanies.module').then(m => m.OrderPurchaseAllCompaniesModule) },
      { path: 'orderSale', loadChildren: () => import('./components/orders/orderSale.module').then(m => m.OrderSaleModule) },
      { path: "orderTemp", loadChildren: () => import('./components/orders/ordersTemp/orderTemp.Module').then(m => m.OrderTempModule) },
      { path: 'orderPurchase-baglass', loadChildren: () => import('./components/orders/ordersBaglass/orderPurchase-baglass.module').then(m => m.OrderPurchaseBaglassModule) },
      { path: 'orderSale-baglass', loadChildren: () => import('./components/orders/ordersBaglass/orderSale-baglass.module').then(m => m.OrderSaleBaglassModule) },
      { path: 'consult', loadChildren: () => import('./components/orders/consultBaglass/consult.module').then(m => m.ConsultModule) },
      { path: 'consultAltri', loadChildren: () => import('./components/orders/consultAltri/consult.module').then(m => m.ConsultModule)},
      { path: 'consult-agri', loadChildren: () => import('./components/invoices/consultAgri/consult.module').then(m => m.ConsultModule) },
      { path: 'deliveryNotesPurchase', loadChildren: () => import('./components/deliveryNotes/deliveryNotesPurchase.module').then(m => m.DeliveryNotesPurchaseModule) },
      { path: 'deliveryNotesSale', loadChildren: () => import('./components/deliveryNotes/deliveryNotesSale.module').then(m => m.DeliveryNotesSaleModule) },
      { path: 'deliveryNotesSaleAT', loadChildren: () => import('./components/deliveryNotes/deliveryNotesAT/delivery-notes-AT.module').then(m => m.DeliveryNotesATModule) },
      { path: 'deliveryNotes', loadChildren: () => import('./components/deliveryNotes/deliveryNotes.module').then(m => m.DeliveryNotesModule) },
      { path: 'delivery-note-workflow', loadChildren: () => import('./components/deliveryNotes/workflow/delivery-note-workflow.module').then(m => m.DeliveryNoteWorkflowModule) },
      { path: 'budget', loadChildren: () => import('./components/budgets/budget.module').then(m => m.BudgetModule) },
      { path: 'costCenter', loadChildren: () => import('./components/costCenter/costCenter.module').then(m => m.CostCenterModule) },
      { path: 'divisionKeys', loadChildren: () => import('./components/divisionKeys/divisionKeys.module').then(m => m.DivisionKeysModule) },
      { path: 'analisysAxes', loadChildren: () => import('./components/analisysAxes/analisysAxes.module').then(m => m.AnalisysAxesModule) },
      { path: 'orderWorkflow', loadChildren: () => import('./components/orders/orderWorkflow.module').then(m => m.OrderWorkflowModule) },
      { path: 'orderDetailWorkflow', loadChildren: () => import('./components/orders/orderDetailWorkflow.module').then(m => m.OrderDetailWorkflowModule) },
      { path: 'notification', loadChildren: () => import('./components/notification/notification.module').then(m => m.NotificationModule) },
      { path: 'catalog', loadChildren: () => import('./components/catalog/catalog.module').then(m => m.CatalogModule) },
      { path: 'client-catalog', loadChildren: () => import('./components/client-catalog/client-catalog.module').then(m => m.ClientCatalogModule) },
      { path: 'cim', loadChildren: () => import('./components/cim/_cim.module').then(m => m.CIMModule) },
      { path: 'pim', loadChildren: () => import('./components/pim/pim.module').then(m => m.PIMModule) },
      { path: 'workflow', loadChildren: () => import('./components/workflow/workflow.module').then(m => m.WorkflowModule) },
      { path: 'generic-distribution-list', loadChildren: () => import('./components/generic-distribution-list/generic-distribution-list.module').then(m => m.GenericDistributionListModule) },
      { path: 'distribution-list', loadChildren: () => import('./components/distribution-list/distribution-list.module').then(m => m.DistributionListModule) },
      { path: 'sales', loadChildren: () => import('./components/sales/sales.module').then(m => m.SalesModule) },
      { path: 'dataimport', loadChildren: () => import('./components/data-imports/data-import.module').then(m => m.DataImportModule) },
      { path: 'report', loadChildren: () => import('./components/report/report.module').then(m => m.ReportModule) },
      { path: 'inventory', loadChildren: () => import('./components/inventory/inventory.module').then(m => m.InventoryModule) },
      { path: 'requests', loadChildren: () => import('./components/request/requests.module').then(m => m.RequestsModule), data: { title: 'REQUESTS', menu: 'ManageRequests' } },
      { path: 'script', loadChildren: () => import('./components/script/script.module').then(m => m.ScriptModule) },
      { path: 'controlPanelHigicol', loadChildren: () => import('./components/controlPanel/higicol/controlPanel.module').then(m => m.ControlPanelModule) },
      { path: 'controlPanel', loadChildren: () => import('./components/controlPanel/msd/controlPanel.module').then(m => m.ControlPanelModule) },
      { path: 'prices-list', loadChildren: () => import('./components/prices-list/prices-list.module').then(m => m.PricesListModule) },
      { path: 'fileSharingEditor', loadChildren: () => import('./components/fileSharing/fileSharingEditor.module').then(m => m.FileSharingEditorModule) },
      { path: 'fileSharingViewer', loadChildren: () => import('./components/fileSharing/fileSharingViewer.module').then(m => m.FileSharingViewerModule) },
      { path: 'news', loadChildren: () => import('./components/news/news.module').then(m => m.NewsModule) },
      { path: 'contacts', loadChildren: () => import('./components/contacts/contacts.module').then(m => m.ContactsModule) },
      { path: 'species', loadChildren: () => import('./components/species/species.module').then(m => m.SpeciesModule) },
      { path: 'requestWorkflow', loadChildren: () => import('./components/request/request-workflow.module').then(m => m.RequestWorkflowModule) },
      { path: 'invoiceWorkflow', loadChildren: () => import('./components/invoices/invoiceWorkflow.module').then(m => m.InvoiceWorkflowModule) },
      { path: 'integrationErrors', loadChildren: () => import('./components/integration-errors/integration-errors.module').then(m => m.IntegrationErrorsModule) },
      { path: 'contracts', loadChildren: () => import('./components/contracts/contracts.module').then(m => m.ContractsModule) },
      { path: 'contractWorkflow', loadChildren: () => import('./components/contracts/contractWorkflow.module').then(m => m.ContractWorkflowModule) },
      { path: 'convertRequests', loadChildren: () => import('./components/request/requests.module').then(m => m.RequestsModule), data: { title: 'CONVERT_REQUESTS', menu: 'ConvertRequests' } },
      { path: 'dropdown', loadChildren: () => import('./components/dropdown/_dropdown.module').then(m => m.DropDownModule) },
      { path: 'channel', loadChildren: () => import('./components/channel/channel.module').then(m => m.ChannelModule) },
      { path: 'company-group', loadChildren: () => import('./components/company-group/company-group.module').then(m => m.CompanyGroupModule) },
      { path: 'users-group', loadChildren: () => import('./components/users-group/users-group.module').then(m => m.UsersGroupModule) },
      { path: 'registration-request', loadChildren: () => import('./components/registration-request/registration-request.module').then(m => m.RegistrationRequestModule) },
      { path: 'value-added-tax', loadChildren: () => import('./components/value-added-tax/value-added-tax.module').then(m => m.ValueAddedTaxModule) },
      { path: 'quotationsWorkflow', loadChildren: () => import('./components/quotation/workflow/_quotation-workflow.module').then(m => m.QuotationWorkflowModule) },
      { path: 'scanned-document', loadChildren: () => import('./components/scanned-document/scanned-document.module').then(m => m.ScannedDocumentModule) },
      { path: 'activity-log', loadChildren: () => import('./components/activity-log/activity-log.module').then(m => m.ActivityLogModule) },
      { path: 'companyManager', loadChildren: () => import('./components/portal-management/company-manager/companyManager.module').then(m => m.CompanyManagerModule) },
      { path: 'dashboard-panel', loadChildren: () => import('./components/portal-management/dashboard-panel/dashboard-panel.module').then(m => m.DashboardPanelModule) },
      { path: 'service-provider', loadChildren: () => import('./components/portal-management/service-provider/service-provider.module').then(m => m.ServiceProviderModule) },
      { path: 'transport', loadChildren: () => import('./components/transport/transport.module').then(m => m.TransportModule) },
      { path: 'order-transnautica', loadChildren: () => import('./components/orders/transnautica/order-transnautica.module').then(m => m.OrderTransnauticaModule) },
      { path: 'pod', loadChildren: () => import('./components/pod/pod.module').then(m => m.PODModule) },
      { path: 'menu-option', loadChildren: () => import('./components/portal-management/menu-options/menu-option.module').then(m => m.MenuOptionModule) },
      { path: 'quotation', loadChildren: () => import('./components/quotation/_quotation.module').then(m => m.QuotationModule) },
      { path: 'payment', loadChildren: () => import('./components/payment/payment.module').then(m => m.PaymentModule) },
      { path: 'generic-documents/novadelta/material', loadChildren: () => import('./components/generic-documents/novadelta/material/_material.module').then(m => m.MaterialModule) },
      { path: 'warehouse/location', loadChildren: () => import('./components/warehouse/warehouse-location/_warehouse-location.module').then(m => m.WarehouseLocationModule) },
      { path: 'generic-field-config', loadChildren: () => import('./components/generic-field-config/_generic-field-config.module').then(m => m.GenericFieldConfigModule) },
      { path: 'generic-field-rule', loadChildren: () => import('./components/generic-field-rule/_generic-field-rule.module').then(m => m.GenericFieldRuleModule) },
      { path: 'generic-documents/altice/fine', loadChildren: () => import('./components/generic-documents/altice/fine/_fine.module').then(m => m.FineModule) },
      { path: 'generic-export', loadChildren: () => import('./components/generic-exports/generic-export.module').then(m => m.GenericExportModule) },
      { path: 'dynamic-lists/list', loadChildren: () => import('./components/dynamic-list/dynamic-list-execution/_dynamic-list-execution.module').then(m => m.DynamicListExecutionModule) },
      { path: 'dynamic-list-management', loadChildren: () => import('./components/dynamic-list/dynamic-list-management/_dynamic-list.module').then(m => m.DynamicListModule) },
      { path: 'altice/infraction', loadChildren: () => import('./components/generic-documents/altice/infraction/_infraction.module').then(m => m.InfractionModule) },
      { path: 'generic-scan', loadChildren: () => import('./components/generic-documents/altice/scan/generic-scan.module').then(m => m.GenericScanModule) },
      { path: 'postal-codes', loadChildren: () => import('./components/location/postal-code/postal-code.module').then(m => m.PostalCodeModule) },
      { path: 'task', loadChildren: () => import('./components/task/task.module').then(m => m.TaskModule) },
      { path: 'merchandise-type', loadChildren: () => import('./components/merchandise-type/merchandise-type.module').then(m => m.MerchandiseTypeModule) },
      { path: 'consultations', loadChildren: () => import('./components/consultations/consultations.module').then(m => m.ConsultationsModule)},
      { path: 'warehouse', loadChildren: () => import('./components/warehouse/higicol/_warehouse.module').then(m => m.WarehouseModule)},
      { path: 'forecast', loadChildren:() => import('./components/forecast/forecast.module').then(m => m.ForecastModule)},
      { path: 'salesin', loadChildren: () => import('./components/sales/sales-in/sales-in.module').then(m => m.SalesInModule) },
      { path: 'globalDeliveryNotes', loadChildren: () => import('./components/deliveryNotes/globalDeliveryNotes/global-delivery-notes.module').then(m => m.GlobalDeliveryNotesModule) },
      { path: 'accessoryDeliveryNotes', loadChildren: () => import('./components/deliveryNotes/accessoryDeliveryNotes/accessory-delivery-notes.module').then(m => m.AccessoryDeliveryNotesModule) },
	    { path: 'processes', loadChildren: () => import('./components/processes/processes.module').then(m => m.ProcessesModule) },
      { path: 'documents', loadChildren: () => import('./components/processes/processes-documents.module').then(m => m.ProcessesDocumentsModule) },
      { path: 'accounted-document-extract', loadChildren: () => import('./components/specific/teixeiraduarte/accounted-document-extract/accounted-document-extract.module').then(m => m.AccountedDocumentExtractModule)},
      { path: 'document-accounting', loadChildren: () => import('./components/specific/teixeiraduarte/document-accounting/document-accounting.module').then(m => m.DocumentAccountingModule)},
      { path: 'payment-statement', loadChildren: () => import('./components/specific/teixeiraduarte/payment-statement/payment-statement.module').then(m => m.PaymentStatementModule)},
      { path: 'supplier-registration', loadChildren: () => import('./components/specific/teixeiraduarte/supplier-registration/supplier-registration.module').then(m => m.SupplierRegistrationModule)},
      { path: 'generic-documents/gsk/cases', loadChildren: () => import('./components/generic-documents/gsk/cases/cases.module').then(m => m.CasesModule)},
      { path: 'LMdeliveryNotesSale', loadChildren: () => import('./components/deliveryNotes/LMDeliveryNotes/deliveryNotesSale.module').then(m => m.DeliveryNotesSaleModule)},
      { path: 'generic-documents/tickets', loadChildren: () => import('./components/generic-documents/ticket/ticket.module').then(m => m.TicketModule)},
      { path: 'emails-configurations', loadChildren: () => import('./components/specific/teixeiraduarte/tickets-configs/tickets-configs.module').then(m => m.TicketsConfigsModule)},
      { path: 'payment-prevision-configurations', loadChildren: () => import('./components/specific/teixeiraduarte/payment-prevision-configs/payment-prevision-configs.module').then(m => m.PaymentPrevisionConfigsModule)},
      { path: 'ca-requests', loadChildren: () => import('./components/request/ca/ca-requests.module').then(m => m.CARequestsModule), data: { title: 'REQUESTS', menu: 'ManageRequests' } },
      { path: 'tax-group', loadChildren: () => import('./components/tax-group/tax-group.module').then(m => m.TaxGroupModule) },
      { path: 'accounting-group', loadChildren: () => import('./components/accounting-group/accounting-group.module').then(m => m.AccountingGroupModule) },
      { path: 'product-service-typology', loadChildren: () => import('./components/product-service-typology/product-service-typology.module').then(m => m.ProductServiceTypologyModule) },
      { path: 'ca-requests-for-all-companies', loadChildren: () => import('./components/request/ca/allCompanies/ca-requests-for-all-companies.module').then(m => m.CARequestsForAllCompaniesModule), data: { title: 'REQUESTS', menu: 'ManageRequestsForAllCompanies' } },
      { path: 'quotation-contests', loadChildren: () => import('./components/quotation-contests/_quotation.module').then(m => m.QuotationModule), data: { title: 'QUOTATIONS', menu: 'QuotationsContests' } },
      { path: 'current-accounts', loadChildren: () => import('./components/specific/gulbenkian/current-accounts/current-accounts.module').then(m => m.CurrentAccountsModule) },
      { path: 'manage-registration', loadChildren: () => import('./components/generic-documents/manage-registrations/manage-registrations.module').then(m => m.ManageRegistrationModule)},
      { path: 'expense-notes', loadChildren: () => import('./components/expense-notes/expense-note.module').then(m => m.ExpenseNoteModule)},
      { path: 'expense-notes-workflows', loadChildren: () => import('./components/expense-notes/expense-notes-workflows/expense-notes-workflows.module').then(m => m.ExpenseNotesWorkflowsModule)},
      { path: 'expenses-workflows', loadChildren: () => import('./components/expense-notes/expenses-workflows/expenses-workflows.module').then(m => m.ExpensesWorkflowsModule)},
      { path: 'integration-profile', loadChildren: () => import('./components/integration-profiles/integration-profile.module').then(m=>m.IntegrationProfileModule)},
      { path: 'documents-folders', loadChildren: () => import('./components/folders/folders.module').then(m => m.FoldersModule)},
      { path: 'load-documents-purchase', loadChildren: () => import('./components/invoices/load-documents-purchase/load-document-purchase.module').then(m => m.LoadDocumentPurchaseModule)},
      { path: 'load-documents-sale', loadChildren: () => import('./components/invoices/load-documents-sale/load-document-sale.module').then(m => m.LoadDocumentSaleModule)},
      { path: 'payment-conditions', loadChildren: () => import('./components/payments/payment-conditions/payment-condition.module').then(m => m.PaymentConditionModule)},
      { path: 'suppliers-payments', loadChildren: () => import('./components/payments/suppliers-payments/suppliers-payments.module').then(m => m.SupplierPaymentsModule)},
      { path: 'payments-batches', loadChildren: () => import('./components/payments/payments-batches/payments-batches.module').then(m => m.PaymentsBatchesModule)},
      { path: 'current-account', loadChildren: () => import('./components/payments/current-account/current-account.module').then(m => m.CurrentAccountModule) },
      { path: 'internal-movements', loadChildren: () => import('./components/payments/internal-movements/internal-movement.module').then(m => m.InternalMovementsPurchaseModule) },
      { path: 'generic-documents/processes', loadChildren: () => import('./components/generic-documents/process/process.module').then(m => m.ProcessModule)},
      { path: 'logistic-orders/confirmation', loadChildren: () => import('./components/orders/logistic-orders/confirmations/ordersConfirmations.module').then(m => m.OrdersConfirmationsModule)},
      { path: 'logistic-orders', loadChildren: () => import('./components/orders/logistic-orders/logistic-order.module').then(m => m.LogisticOrderModule)},
      { path: 'document-matrices', loadChildren: () => import('./components/document-matrix/document-matrix.module').then(m => m.DocumentMatrixModule)},
      { path: 'invoiceDetailWorkflow', loadChildren: () => import('./components/invoices/invoice-detail-workflow/invoiceDetailWorkflow.module').then(m => m.InvoiceDetailWorkflowModule) },
      { path: 'expense-notes-payments', loadChildren: () => import('./components/payments/expense-notes-payments/expense-notes-payments.module').then(m => m.ExpenseNotesPaymentsModule)},

      // Sempre que se adicionar um novo modulo, tem de se adicionar aqui o respetivo routing do módulo
    ],
    // Parâmetro que determina como os eventos são disparados quando navegamos para o mesmo componente
    runGuardsAndResolvers: 'always'
  }
];

export const LoginRoutes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'recovery', component: RecoveryComponent, data: { title: 'PASSWORD_RECOVERY' } },
  { path: 'register', component: RegisterComponent, data: { title: 'REGISTER' } },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: { title: 'TERMS_CONDITIONS' } },
  { path: 'register-supplier', component: RegisterSupplierComponent, data: { title: 'REGISTER' } },
];

export const OtherPublicRoutes: Routes = [
  { path: 'error', component: CustomErrorComponent, data: { title: 'ERROR' } },
  { path: 'service', component: ServiceComponent, data: { title: 'SERVICE' } },
  { path: 'otis-public/quotation', loadChildren: () => import('./components/quotation/otis-public/_otis-public-quotation.module').then(m => m.OtisPublicQuotationModule) },
  { path: 'approval-screen', component: ApprovalScreenComponent, data: { title: 'ApprovalScreen' } }
];

export const routes: Routes = [
  ...AppRoutes,
  ...LoginRoutes,
  ...OtherPublicRoutes
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' });
