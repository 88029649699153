
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Budget } from '../models/budget'; // ALTERAR
import { MasterService } from './master.service';
import { Observable } from 'rxjs';

import { DatatableParameters } from '../models/datatable-parameters';

@Injectable()
export class BudgetService {

  private _controller: string = 'Budget';

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null], 10, [[0, 'asc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService) { }

  /**
   * Eliminar budget
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // editar budget
  /**
   * @param  {Budget} budget
   * @returns Observable
   */
  update(budget: Budget): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', budget).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // adicionar budget
  /**
   * @param  {Budget} budget
   * @returns Observable
   */
  add(budget: Budget): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', budget).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter budget por id
   * @param  {number} id
   * @returns Observable
   */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));

  }

  /**
   * Obter valor acumulado
   * @param  {number} id
   * @returns Observable
   */
  getBudgetOrderSum(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetBudgetOrderSum?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));

  }

  /**
   * Obter budgets
   * @param  {number} userID
   * @param  {number} locationID
   * @param  {number} costCenterID
   * @returns Observable
   */
  /*
  getBudgetByLocationUserCostCenter(userID: number, locationID: number, costCenterID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetBudgetByLocationUserCostCenter?userID=' + userID + '&locationID=' + locationID + '&costCenterID=' + costCenterID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }*/
  getBudgetByLocationUserCostCenter(userID: number, locationID: number, costCentersID: Array<number>): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetBudgetByLocationUserCostCenter', { userID, locationID, costCentersID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

}
