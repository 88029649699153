
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invoice } from '../models/invoice';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { CONTEXT_INTERNAL_MOVEMENTS, SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';

@Injectable()
export class InvoiceService {

  filter_dataIni_S: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_S: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataIni_Create_S: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_Create_S: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_produto_S: string; // para manter os valores do filtro do produto, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_isHistory_S: boolean;
  // filter_filters_S: Array<string>; // para manter os valores dos filtros das colunas, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  datatableParametersSales: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);

  filter_dataIni_P: string; // para manter os valores do filtro de data de inicio, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_P: string; // para manter os valores do filtro de data de fim, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataIni_Create_P: string; // para manter os valores do filtro de data de inicio (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_Create_P: string; // para manter os valores do filtro de data de fim (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_produto_P: string; // para manter os valores do filtro do produto, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_isHistory_P: boolean;
  filter_hasDetailActions: boolean;
  // filter_filters_P: Array<string>; // para manter os valores dos filtros das colunas, get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataIni_P_AllCompanies: string; // para manter os valores do filtro de data de inicio
  filter_dataFim_P_AllCompanies: string; // para manter os valores do filtro de data de fim
  filter_produto_P_AllCompanies: string; // para manter os valores do filtro do produto
  filter_dataIni_Create_P_AllCompanies: string; // para manter os valores do filtro de data de inicio (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_dataFim_Create_P_AllCompanies: string; // para manter os valores do filtro de data de fim (data de criação), get -> usado no build do form do componente da listagem, set -> usado no destroy do componente da listagem
  filter_isHistory_P_AllCompanies: boolean;
  filter_hasDetailActions_AllCompanies: boolean;

  datatableParametersPurchases: DatatableParameters = new DatatableParameters([null,null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersPurchasesAllCompanies: DatatableParameters = new DatatableParameters([null,null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersContractInvoices: DatatableParameters = new DatatableParameters([null,null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  
  private _invoiceTypes: Array<any>;
  private _invoiceSubTypes: Array<any>;
  private _invoiceStates: Array<any>;
  private _invoiceTaxesSubTypes: Array<any>;
  private _invoiceDetailStates: Array<any>;

  private _controller: string = 'Invoice';

  // showGenerateOrder
  private _showGenerateOrderFromInvoice: boolean;
  private showGenerateOrderFromInvoiceDV: boolean = false; // valor por defeito
  private _showNewPaymentCondition_Sale: boolean;
  private _showNewPaymentCondition_Purchase: boolean;
  showGuideNumber: boolean;
  showBarcode: boolean;

  private showPanelInvoiceWorkFlow: boolean
  

  constructor(private http: HttpClientCustom,
    private _authenticationService: AuthenticationService,
    private _masterService: MasterService,
    private _dataTableF: DataTableFunctions) {
    // this.filter_filters_S = new Array<string>(18).fill('');
    // this.filter_filters_P = new Array<string>(18).fill('');
  }

  /**
   * Obter os tipos de faturas, se der erro vem vazio
   * @returns Observable - com o array de tipos ou vazio
   */
  get invoiceTypes(): Observable<Array<any>> {
    if (this._invoiceTypes === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._invoiceTypes = response.ReturnStatus.ReturnObject.Types;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._invoiceTypes);
    }
  }

  /**
   * Obter sub tipos de faturas, se erro vem vazio
   * @returns Observable - com o array de sub tipos ou vazio
   */
  get invoiceSubTypes(): Observable<Array<any>> {
    if (this._invoiceSubTypes === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetSubTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._invoiceSubTypes = response.ReturnStatus.ReturnObject.SubTypes;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._invoiceSubTypes);
    }
  }

  /**
   * Obter sub tipos de faturas, se erro vem vazio
   * @returns Observable - com o array de sub tipos ou vazio
   */
  getInvoiceSubTypesByInvoiceType(invoiceType: string): Observable<Array<any>> {
    if (this._invoiceSubTypes === undefined) { // se ainda nao tiver ido buscar
      return this.http.get(SERVICE_URL + this._controller + '/GetSubTypes' + (invoiceType ? '?invoiceType=' + invoiceType : '')).pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response))).pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._invoiceSubTypes = response.ReturnStatus.ReturnObject.SubTypes;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._invoiceSubTypes);
    }
  }

  /**
   * Obter estados de faturas, se erro vem vazio
   * @returns Observable - com o array de estados ou vazio
   */
  get invoiceStates(): Observable<Array<any>> {
    if (this._invoiceStates === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._invoiceStates = response.ReturnStatus.ReturnObject.States;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._invoiceStates);
    }
  }

  public get invoiceDetailStates(): Observable<Array<any>> {
    if (this._invoiceDetailStates === undefined) {
      return this.getComplements('GetDetailStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._invoiceDetailStates = response.ReturnStatus.ReturnObject.States;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._invoiceDetailStates);
    }
  }

  /**
   * Obter sub tipos de impostos de faturas, se erro vem vazio
   * @returns Observable - com o array de sub tipos de impostos ou vazio
   */
  get invoiceTaxesSubTypes(): Observable<Array<any>> {
    if (this._invoiceTaxesSubTypes === undefined) { // se ainda nao tiver ido buscar
      return this.getComplements('GetTaxesSubTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
          return this._invoiceTaxesSubTypes = response.ReturnStatus.ReturnObject.TaxesSubTypes;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._invoiceTaxesSubTypes);
    }
  }

  GetChildsList(parentID: number, tipoTratamentoIva: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'TipologiaProdutoServico/GetChildsList?parentID=' + parentID + '&tipoTratamentoIva=' + tipoTratamentoIva)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region PURCHASE
  /**
 * Obter dados de uma fatura de compra através do id
 * @param  {string} id
 * @returns Observable
 */
  get_purchases(id: string, getCostCenterConfig: boolean, getDetailCostCenterConfig: boolean, getDetailAccountingConfig: boolean = false, getProductServiceType: boolean = false, getAccountingConfig: boolean = false,
    getDetailsCostCenterName: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetInvoicePurchases?id=' + id + '&getCostCenterConfig=' + getCostCenterConfig + '&getDetailCostCenterConfig=' + getDetailCostCenterConfig + '&getDetailAccountingConfig=' + getDetailAccountingConfig + '&getProductServiceType=' + getProductServiceType + '&getAccountingConfig=' + getAccountingConfig
      + '&getDetailsCostCenterName=' + getDetailsCostCenterName).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  /**
   * Apagar uma fatura de compra através do id
   * @param  {number} id
   * @returns Observable
   */
  delete_purchases(id: number, useAccountingConfig: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteInvoicePurchases?id=' + id + '&useAccountingConfig=' + useAccountingConfig).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion PURCHASE


  //#region SALE

  /**
    * Obter dados da referencia (guia) através do id
    * @param  {string} id
    * @returns Observable
    */
  get_deliveryNote(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetDeliveryNote/' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Obter dados da referencia (encomenda) através do id
  * @param  {string} id
  * @returns Observable
  */
  get_order(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrder/' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter dados de uma fatura de venda através do id
   * @param  {string} id
   * @returns Observable
   */
  get_sales(id: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetInvoiceSales/' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  /**
   * Apagar uma fatura de venda através do id
   * @param  {number} id
   * @returns Observable
   */
  delete_sales(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteInvoiceSales/' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#endregion SALE

  add(model: Invoice, useAccountingConfig: boolean = false, returnID: boolean = false): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    formData.append('useAccountingConfig', JSON.stringify(useAccountingConfig));
    formData.append('returnID',JSON.stringify(returnID))
    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  saveAndSubmit(model: Invoice, useAccountingConfig: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    formData.append('useAccountingConfig', JSON.stringify(useAccountingConfig));
    return this.http.post(SERVICE_URL + this._controller + '/SaveAndSubmit', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: Invoice, useAccountingConfig: boolean = false, menuName: string, changeInvoiceState: boolean = true): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    formData.append('useAccountingConfig', JSON.stringify(useAccountingConfig));
    formData.append('menuName', JSON.stringify(menuName));
    formData.append('changeInvoiceState', JSON.stringify(changeInvoiceState));
    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateOrderNumber(model: Invoice, menuName: string, isRemovingInvoiceFromOrder: boolean = false): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    formData.append('menuName', JSON.stringify(menuName));
    formData.append('isRemovingInvoiceFromOrder', JSON.stringify(isRemovingInvoiceFromOrder));
    return this.http.post(SERVICE_URL + this._controller + '/UpdateOrderNumber', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  sendEmail(id: string, emails: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SendEmail', { id: id, emails: emails }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  reprocess(id: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Reprocess', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getNextNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextNumber').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyWorkflowStep(docID: number, stepID: number, notes: string, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { docID: docID, stepID: stepID, notes: notes, forAllCompanies: forAllCompanies }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  changeForwardToUser(invoiceID: number, toUserID: number, userID: number, fromUserID: number = null, notes: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangeForwardToUser', { invoiceID: invoiceID, toUserID: toUserID, userID: userID, fromUserID: fromUserID, notes: notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submit(id: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Submit', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyDetailsWorkflows(invoicesIDs: number[], notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyDetailsWorkflowForInvoices', { invoicesIDs: invoicesIDs, notes: notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Devolve as faturas num determinado Estado
   * @param  {Array<number>} states - Estados das faturas 
   */
  getByStateID(states: Array<number>): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByStateID?states=' + states.join(',')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getDetailsByWorkflow(invoice: Invoice){
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(invoice));

    return this.http.post(SERVICE_URL + this._controller + '/GetDetailsWorkflows',formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyDetailsByWorkflow(invoice : Invoice): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(invoice));

    return this.http.post(SERVICE_URL + this._controller + '/ApplyDetailsWorkflow', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region EXPORTACAO
  exportExcel(id: string, getDetailsAccountingConfig: boolean = false): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/ExportExcel?id=' + id + '&getDetailsAccountingConfig=' + getDetailsAccountingConfig);
  }
  exportPDF(id: string, allCompanies: boolean = false): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/ExportPDF?id=' + id + '&allCompanies=' + allCompanies);
  }
  getFilePDF(id: string, via: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/ExportFilePDF?id=' + id + "&via=" + via);
  }
  /**
   * @param  {number} salePurchase - 1 = purchases; 2 = sales
   * @param  {string} dInicio
   * @param  {string} dFim
   * @param  {string} produto
   * @param  {string} nDoc?
   * @returns Observable
   */
  exportExcelList(salePurchase: number, parameters: DatatableParameters, dInicio: string, dFim: string, produto?: string, creationDInicio?: string, creationDFim?: string, isHistory?: boolean): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/ExportExcelInvoicesList',
      {
        salePurchase: salePurchase, parameters: parameters, firstdate: dInicio, lastdate: dFim, product: produto,
        beginCreationDate: creationDInicio, endCreationDate: creationDFim, isHistory: isHistory
      });
  }
  //#endregion EXPORTACAO



  //#region ---------------------- ***  VARIAVEIS SALE  *** ---------------------
  /* tslint:disable:member-ordering */
  SALES_columnTotalLiq_Label: string;
  SALES_columnInVisible: Array<number>;
  SALES_columnVisible: Array<number>;
  SALES_fillEndDate: boolean;
  SALES_showOptionSendEmail: boolean;
  SALES_ShowOptionsReprocessBtn: boolean;

  SALES_DinamycFieldsInOtherReferences: Array<number>
  SALES_allowDuplicate: boolean;

  SALES_showCreationDate: boolean;
  SALES_filterByHistory: boolean;
  SALES_showStateHistoryModalUserName: boolean;
  SALES_includeTimeInDates: boolean; //Valida se a Listagem mostra o Time nos DatePickers
  SALE_vatMandatoryAmount:boolean; //Valor da taxa obrigatório se for IVA
  private SALES_setBeginingOfTheYearAsStartDate: boolean; //Setting que coloca a Data Inicial do Header Filter como o 1º dia do ano
  private SALES_setTodayAsEndDate: boolean; //Setting que coloca a Data Atual como a Data Final
  private hideExcelExportButton_SALE: boolean; //JJ 14/09/2022 -> Esconde o Botão de Exportação Excel
  private SALES_hideExportDropdownMenu: boolean; //Setting para esconder o Menu de Exportações
  private SALES_hideExportExcelButton: boolean; //Setting para esconder o Botão de Exportação Excel
  private SALES_hideFilterModalButton: boolean; //Setting para esconder o Botão que abre a Modal OrderFilter

  /* tslint:enable:member-ordering */

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {string} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getSALES_columnTotalLiq_Label(defaultValue: string): string {
    if (typeof this.SALES_columnTotalLiq_Label === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'Label_TotalLiq');
      if (settingValue != null) {
        this.SALES_columnTotalLiq_Label = settingValue;
      } else {
        this.SALES_columnTotalLiq_Label = defaultValue;
      }
    }
    return this.SALES_columnTotalLiq_Label;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  getSALES_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALES_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ColumVisible');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoicesSales', 'ColumVisibleSP');
      this.SALES_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this._authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
    }
    return this.SALES_columnInVisible;
  }  /*
  getSALES_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALES_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ColumInvisible');
      if (settingValue != null) {
        let columns = settingValue.split(',');
        let indexes = '[';
        for (let column of columns) {
          indexes += columnsNames.indexOf(column.replace(/ /g, '')) + ', ';
        }
        indexes = indexes.substring(0, indexes.length - 2);

        this.SALES_columnInVisible = JSON.parse(indexes + ']');
      } else {
        this.SALES_columnInVisible = defaultValue;
      }
    }
    return this.SALES_columnInVisible;
  }*/

  getSALES_columnVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALES_columnVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ColumVisible');
      this.SALES_columnVisible = this._dataTableF.getVisibleColumns(defaultValue, columnsNames, settingValue);
    }
    return this.SALES_columnVisible;
  }
  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getSALES_fillEndDate(defaultValue: boolean): boolean {
    if (typeof this.SALES_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'FillEndDate');
      if (settingValue != null) {
        this.SALES_fillEndDate = settingValue;
      } else {
        this.SALES_fillEndDate = defaultValue;
      }
    }
    return this.SALES_fillEndDate;
  }

  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getSALES_showOptionSendEmail(defaultValue: boolean): boolean {
    if (typeof this.SALES_showOptionSendEmail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ShowOptionSendEmail');
      if (settingValue != null) {
        this.SALES_showOptionSendEmail = settingValue;
      } else {
        this.SALES_showOptionSendEmail = defaultValue;
      }
    }
    return this.SALES_showOptionSendEmail;
  }

  getSALES_showOptionsReprocessBtn(defaultValue: boolean): boolean {
    if (typeof this.SALES_ShowOptionsReprocessBtn === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ShowReprocessBtn');
      if (settingValue != null) {
        this.SALES_ShowOptionsReprocessBtn = settingValue;
      } else {
        this.SALES_ShowOptionsReprocessBtn = defaultValue;
      }
    }
    return this.SALES_ShowOptionsReprocessBtn;
  }



  getSALES_DinamycFieldsInOtherReferences(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALES_DinamycFieldsInOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DinamycFieldsNotInOtherReferences');
      this.SALES_DinamycFieldsInOtherReferences = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.SALES_DinamycFieldsInOtherReferences;
  }




  getSALES_showCreationDate(defaultValue: boolean): boolean {
    if (typeof this.SALES_showCreationDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ShowCreationDate');
      if (settingValue != null) {
        this.SALES_showCreationDate = settingValue;
      } else {
        this.SALES_showCreationDate = defaultValue;
      }
    }
    return this.SALES_showCreationDate;
  }


  getSALES_allowDuplicate(defaultValue: boolean): boolean {
    if (typeof this.SALES_allowDuplicate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'AllowDuplicate');
      if (settingValue != null) {
        this.SALES_allowDuplicate = settingValue;
      } else {
        this.SALES_allowDuplicate = defaultValue;
      }
    }
    return this.SALES_allowDuplicate;
  }


  get_SALES_filterByHistory(defaultValue: boolean): boolean {
    if (typeof this.SALES_filterByHistory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'FilterByHistory');
      if (settingValue != null) {
        this.SALES_filterByHistory = settingValue;
      } else {
        this.SALES_filterByHistory = defaultValue;
      }
    }
    return this.SALES_filterByHistory;
  }


  get_SALES_showStateHistoryModalUserName(defaultValue: boolean): boolean {
    if (typeof this.SALES_showStateHistoryModalUserName === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'ShowStateHistoryModalUserName');
      if (settingValue != null) {
        this.SALES_showStateHistoryModalUserName = settingValue;
      } else {
        this.SALES_showStateHistoryModalUserName = defaultValue;
      }
    }
    return this.SALES_showStateHistoryModalUserName;
  }


  get_SALES_includeTimeInDates(defaultValue: boolean): boolean {
    if (typeof this.SALES_includeTimeInDates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'IncludeTimeInDates');
      if (settingValue != null) {
        this.SALES_includeTimeInDates = settingValue;
      } else {
        this.SALES_includeTimeInDates = defaultValue;
      }
    }
    return this.SALES_includeTimeInDates;
  }
  

  // se mostra botao listagem produtos
  getSALE_vatMandatoryAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_vatMandatoryAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'VatMandatoryAmount');
      if (settingValue != null) {
        this.SALE_vatMandatoryAmount = settingValue;
      } else {
        this.SALE_vatMandatoryAmount = defaultValue;
      }
    }
    return this.SALE_vatMandatoryAmount;
  }

  get_setBeginingOfTheYearAsStartDate_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_setBeginingOfTheYearAsStartDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'SetBeginingOfTheYearAsStartDate');
      if (settingValue != null) {
        this.SALES_setBeginingOfTheYearAsStartDate = settingValue;
      } else {
        this.SALES_setBeginingOfTheYearAsStartDate = defaultValue;
      }
    }
    return this.SALES_setBeginingOfTheYearAsStartDate;
  }

   get_setTodayAsEndDate_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_setTodayAsEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'SetTodayAsEndDate');
      if (settingValue != null) {
        this.SALES_setTodayAsEndDate = settingValue;
      } else {
        this.SALES_setTodayAsEndDate = defaultValue;
      }
    }
    return this.SALES_setTodayAsEndDate;
  }
  
  get_hideExcelExportButton_SALE(defaultValue: boolean): boolean {
    if (typeof this.hideExcelExportButton_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HideExcelExportButton');
      if (settingValue != null) {
        this.hideExcelExportButton_SALE = settingValue;
      } else {
        this.hideExcelExportButton_SALE = defaultValue;
      }
    }
    return this.hideExcelExportButton_SALE;
  }

  get_hideFilterModalButton_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_hideFilterModalButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'HideFilterModalButton');
      if (settingValue != null) {
        this.SALES_hideFilterModalButton = settingValue;
      } else {
        this.SALES_hideFilterModalButton = defaultValue;
      }
    }
    return this.SALES_hideFilterModalButton;
  }

  get_hideExportDropdownMenu_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_hideExportDropdownMenu === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'HideExportDropdownMenu');
      if (settingValue != null) {
        this.SALES_hideExportDropdownMenu = settingValue;
      } else {
        this.SALES_hideExportDropdownMenu = defaultValue;
      }
    }
    return this.SALES_hideExportDropdownMenu;
  }

  get_hideExportExcelButton_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_hideExportExcelButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesSales', 'HideExportExcelButton');
      if (settingValue != null) {
        this.SALES_hideExportExcelButton = settingValue;
      } else {
        this.SALES_hideExportExcelButton = defaultValue;
      }
    }
    return this.SALES_hideExportExcelButton;
  }


  /* tslint:disable:member-ordering */
  SALE_ShowDigitalFileTab: boolean;
  SALE_ShowMessagesTab: boolean;
  SALE_invoiceSubTypeShow: boolean;
  SALE_suppliersDeliveryNoteNumberEditable: boolean;
  SALE_buyersOrderNumberEditable: boolean;
  SALE_suppliersDeliveryNoteNumberRequired: boolean;
  SALE_buyersOrderNumberRequired: boolean;
  SALE_originalInvoiceNumberRequired: boolean;
  SALE_originalInvoiceNumberEditable: boolean;
  SALE_AllowDeliveryNotesSelection: boolean;
  SALE_AllowOrdersSelection: boolean;
  SALE_AllowInvoicesSelection: boolean;
  SALE_ShowPanelTaxes: boolean;
  SALE_ShowPanelEDI: boolean;
  SALE_ShowPanelInvoiceTo: boolean;
  SALE_RequiredInvoiceTo: boolean;
  SALE_ShowPanelDiscounts: boolean;
  SALE_ShowPanelAllowances: boolean;
  SALE_PossibleTypes: Array<string>;
  SALE_ShowPanelPaymentConditions: boolean;
  SALE_AllowCreateEmptyDetail: boolean;
  SALE_BuyerFieldsEditable: boolean;
  SALE_DeliveryToFieldsEditable: boolean;
  SALE_InvoiceToFieldsEditable: boolean;
  SALE_UseOrderReceivedQuantity: boolean;
  SALE_DefaultValueAddedTax: number;

  SALE_columnInVisible_Details: Array<number>;
  SALE_columnInVisible_Details_SP: Array<number>;

  SALE_Details_All: boolean;

  SALE_msgCreate: boolean;
  SALE_msgDelete: boolean;
  SALE_digArcCreate: boolean;
  SALE_digArcDelete: boolean;
  SALE_digArcLimitNumberOfFiles: boolean;
  SALE_hideColumsTax: boolean;
  SALE_allowsRepeatedProducts: boolean;
  SALE_showButtonSelectProducts: boolean;
  SALE_groupVatExemption: boolean;
  PURCHASE_groupVatExemption: boolean;
  SALE_showOrdersTab: boolean;

  SALE_showBuyerTelephone: boolean;
  SALE_showBuyerFax: boolean;
  SALE_showBuyerElectronicMail: boolean;
  SALE_showBuyerDepartmentCode: boolean;
  SALE_showBuyerDepartmentName: boolean;
  SALE_showBuyerDivisionCode: boolean;
  SALE_showDeliveryToTelephone: boolean;
  SALE_showDeliveryToFax: boolean;
  SALE_showDeliveryToElectronicMail: boolean;
  SALE_showDeliveryLoadingLocation: boolean;
  SALE_showDeliveryLoadingDate: boolean;
  SALE_showDeliveryLocation: boolean;
  SALE_showDeliveryTransportID: boolean;
  SALE_showDeliveryConditionsValue: boolean;
  SALE_showDeliveryFreeText: boolean;
  SALE_showTotalChargeAmount: boolean;
  SALE_showTotalDiscountAmount: boolean;
  SALE_showTotalPackingCostAmount: boolean;
  SALE_showTotalPrePaymentAmount: boolean;
  SALE_showCurrencyExchange: boolean;
  SALE_showOthersReferencesTypes: boolean;
  SALE_UseOrderAndDeliveryNoteREFs: boolean;
  SALE_ShowBuyersCostCentre: boolean;
  SALE_ShowSuppliersDeliveryNoteNumber: boolean;
  SALE_ShowCertificateNumber: boolean;
  SALE_ShowTotalGrossAmount: boolean;
  SALE_showTotalTransportAmount: boolean;
  SALE_showTotalRetencaoIrsPercentage: boolean;
  SALE_ShowTotalRetencaoIrsAmount: boolean;
  SALE_ShowTotalEnvironmentalTaxAmount: boolean;
  SALE_ShowTotalNetWeight: boolean;
  SALE_ShowTotalNetWeightUOMCode: boolean;
  SALE_ShowTotalGrossWeight: boolean;
  SALE_ShowTotalGrossWeightUOMCode: boolean;
  SALE_ShowTotalNetVolume: boolean;
  SALE_ShowTotalNetVolumeUOMCode: boolean;
  SALE_showInvoiceTelephone: boolean;
  SALE_showInvoiceFax: boolean;
  SALE_showInvoiceElectronicMail: boolean;
  SALE_showPaymentConditionsInvoiceDueDate: boolean;
  SALE_showPaymentConditionsServicesTermDate: boolean;
  SALE_showPaymentConditionsList: boolean;
  SALE_showPaymentMethod: boolean;
  SALE_invoiceRepositoryDiskFileContext: string;
  SALE_validateTotalNetAmountWhen0: boolean;


  SALE_showInvoiceDueDays: boolean;
  SALE_maxPatmentMethods: number;
  SALE_ShowPanelPaymentInfo: boolean;
  SALE_disableTotalsPanel: boolean;
  SALE_disableTaxPanel: boolean;
  SALE_validateMaxNetUnitPrice: boolean;
  SALE_useCertficateNumberInOtherReferences: boolean;
  SALE_useCostCenterInOtherReferences: boolean;
  SALE_showPaymentCondition: boolean;
  SALE_showMeansOfPayment: boolean;

  SALE_afterSaveRemainOnSamePage: boolean; // MM - 28/09/2022 -> depois de salvar em vez de ser direcionado para a página da lista fica na mesma já pronta a editar
  SALE_redirectOnUpdate: boolean; // MM - 28/09/2022 -> depois de salvar em vez de ser direcionado para a página da lista fica na mesma já pronta a editar
  SALE_submitOnlyAfterCreate: boolean; //JJ - 07/10/2022 -> Obriga a que a Fatura tenha sido guardada antes de mostrar o botão "Submeter"
  SALE_isMultipleOrder: boolean;
  SALE_showNextDocumentNumber: boolean;
  SALE_showBuyerContractNumber: boolean;
  SALE_showButtonClearOrders: boolean;
  SALE_showCreationDate: boolean;
  SALE_showPaid: boolean;
  SALE_loadOrderPaidOnInvoiceCreate: boolean;
  SALE_showAttachesIsPublic: boolean;
  SALE_ATCUD: boolean;
  SALE_hideGuideNote: boolean;
  SALE_showInvoiceSerie: boolean;
  SALE_showInvoiceToStreet: boolean;
  SALE_showInvoiceToPostalCode: boolean;
  SALE_showInvoiceToCity: boolean;
  SALE_showInvoiceToCountryCode: boolean;
  SALE_submitMessageTranslateCode: string;
  SALE_showOrderListColumns: boolean;
  //JJ 05/09/2022 -> Define a Formatação p/ Default dos Date Pickers
  private defaultDateTimeFormat_SALE: number;
  SALE_allowedFileTypes: Array<string> = [];
  SALE_hideAttachmentDescription: boolean;
  SALE_hideAttachmentType: boolean;

  SALE_hideOtherReferences: boolean;
  SALE_hidePanelClient: boolean;
  SALE_hidePanelDelivery: boolean;
  SALE_hidePanelObservations: boolean;
  SALE_showPanelReferencesFirst: boolean;
  INTERNAL_MOVEMENT_PossibleTypes: Array<string>;

  DETAIL_SALE_showBacthNumber: boolean;
  DETAIL_SALE_showDeliveryDate: boolean;
  DETAIL_SALE_showBacthExpiryDate: boolean;
  DETAIL_SALE_BuyerProductCodeEditable: boolean;
  DETAIL_SALE_ShowPanelDiscounts: boolean;
  DETAIL_SALE_ShowPanelAllowances: boolean;
  DETAIL_SALE_ShowPanelReferences: boolean;
  DETAIL_SALE_showDetailBonusQuantity: boolean;
  DETAIL_SALE_showDetailBonusQuantityUOMCode: boolean;
  DETAIL_SALE_showDetailPackQuantity: boolean;
  DETAIL_SALE_showDetailPackQuantityUOMCode: boolean;
  DETAIL_SALE_showDetailPackSize: boolean;
  DETAIL_SALE_editDetailTotalNetAmount: boolean;
  DETAIL_SALE_editDetailGrossUnitPrice: boolean;
  DETAIL_SALE_editDetailTotalGrossAmount: boolean;
  DETAIL_SALE_InvoiceDueDateRequired: boolean;
  DETAIL_SALE_TaxNumberRequired: boolean;
  DETAIL_SALE_ValidateQuantityMaxAllowed: boolean;
  DETAIL_SALE_isRequiredQuantity: boolean;
  DETAIL_SALE_allowEditDocumentNumber: boolean;
  DETAIL_SALE_allowEditInvoiceDate: boolean;
  DETAIL_SALE_notCalculateQuantityWithOrderInvoiceToQuantity: boolean;
  DETAIL_SALE_showDetailGrossUnitPrice: boolean;
  DETAIL_SALE_showDetailTotalGrossAmount: boolean;
  DETAIL_SALE_showDetailTotalTransportAmount: boolean;
  DETAIL_SALE_showDetailBuyersCostCenter: boolean;
  DETAIL_SALE_useAccountingConfig: boolean;
  DETAIL_SALE_showDetailsNewProductButton: boolean;
  DETAIL_SALE_showDetailsProductsListButton: boolean;
  DETAIL_SALE_showDescriptionAsTextArea: boolean;
  DETAIL_SALE_showBuyerContractNumber: boolean;
  DETAIL_SALE_disableUOMCodes: boolean;
  DETAIL_SALE_validateTotalNetAmountInDetails: boolean;
  DETAIL_SALE_showOrderLineNumberOnProductPanel: boolean;
  DETAIL_SALE_hideEANCode: boolean;
  DETAIL_SALE_hideBuyerProductCode: boolean;
  DETAIL_SALE_hideSupplierProductCode: boolean;
  DETAIL_SALE_hidePanelObservations: boolean;
  DETAIL_SALE_quantityUOMCodeDisabled: boolean;
  DETAIL_SALE_totalNetPriceDisabled: boolean;
  DETAIL_SALE_descriptionDisabled: boolean;
  DETAIL_SALE_useHalfColumn: boolean;
  DETAIL_SALE_BuyersOrderLineNumberDisabled: boolean;




  /* tslint:enable:member-ordering */

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_ShowDigitalFileTab(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowDigitalFileTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDigitalFileTab');
      if (settingValue != null) {
        this.SALE_ShowDigitalFileTab = settingValue;
      } else {
        this.SALE_ShowDigitalFileTab = defaultValue;
      }
    }
    return this.SALE_ShowDigitalFileTab;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getSALE_ShowMessagesTab(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowMessagesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowMessagesTab');
      if (settingValue != null) {
        this.SALE_ShowMessagesTab = settingValue;
      } else {
        this.SALE_ShowMessagesTab = defaultValue;
      }
    }
    return this.SALE_ShowMessagesTab;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getSALE_InvoiceSubTypeShow(defaultValue: boolean): boolean {
    if (typeof this.SALE_invoiceSubTypeShow === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceSubType');
      if (settingValue != null) {
        this.SALE_invoiceSubTypeShow = settingValue;
      } else {
        this.SALE_invoiceSubTypeShow = defaultValue;
      }
    }
    return this.SALE_invoiceSubTypeShow;
  }
  /**
  * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
  * @param  {boolean} defaultValue - Valor por defeito do parametro
  * @returns boolean
  */
  getSALE_SuppliersDeliveryNoteNumberEditable(defaultValue: boolean): boolean {
    if (typeof this.SALE_suppliersDeliveryNoteNumberEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'SuppliersDeliveryNoteNumberEditable');
      if (settingValue != null) {
        this.SALE_suppliersDeliveryNoteNumberEditable = settingValue;
      } else {
        this.SALE_suppliersDeliveryNoteNumberEditable = defaultValue;
      }
    }
    return this.SALE_suppliersDeliveryNoteNumberEditable;
  }
  getSALE_SuppliersDeliveryNoteNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.SALE_suppliersDeliveryNoteNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'SuppliersDeliveryNoteNumberRequired');
      if (settingValue != null) {
        this.SALE_suppliersDeliveryNoteNumberRequired = settingValue;
      } else {
        this.SALE_suppliersDeliveryNoteNumberRequired = defaultValue;
      }
    }
    return this.SALE_suppliersDeliveryNoteNumberRequired;
  }
  getSALE_BuyersOrderNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.SALE_buyersOrderNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'BuyersOrderNumberRequired');
      if (settingValue != null) {
        this.SALE_buyersOrderNumberRequired = settingValue;
      } else {
        this.SALE_buyersOrderNumberRequired = defaultValue;
      }
    }
    return this.SALE_buyersOrderNumberRequired;
  }
  getSALE_OriginalInvoiceNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.SALE_originalInvoiceNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'OriginalInvoiceNumberRequired');
      if (settingValue != null) {
        this.SALE_originalInvoiceNumberRequired = settingValue;
      } else {
        this.SALE_originalInvoiceNumberRequired = defaultValue;
      }
    }
    return this.SALE_originalInvoiceNumberRequired;
  }
  getSALE_AllowDeliveryNotesSelection(defaultValue: boolean): boolean {
    if (typeof this.SALE_AllowDeliveryNotesSelection === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllowDeliveryNotesSelection');
      if (settingValue != null) {
        this.SALE_AllowDeliveryNotesSelection = settingValue;
      } else {
        this.SALE_AllowDeliveryNotesSelection = defaultValue;
      }
    }
    return this.SALE_AllowDeliveryNotesSelection;
  }
  getSALE_AllowOrdersSelection(defaultValue: boolean): boolean {
    if (typeof this.SALE_AllowOrdersSelection === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllowOrdersSelection');
      if (settingValue != null) {
        this.SALE_AllowOrdersSelection = settingValue;
      } else {
        this.SALE_AllowOrdersSelection = defaultValue;
      }
    }
    return this.SALE_AllowOrdersSelection;
  }
  getSALE_AllowInvoicesSelection(defaultValue: boolean): boolean {
    if (typeof this.SALE_AllowInvoicesSelection === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllowInvoicesSelection');
      if (settingValue != null) {
        this.SALE_AllowInvoicesSelection = settingValue;
      } else {
        this.SALE_AllowInvoicesSelection = defaultValue;
      }
    }
    return this.SALE_AllowInvoicesSelection;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_BuyersOrderNumberEditable(defaultValue: boolean): boolean {
    if (typeof this.SALE_buyersOrderNumberEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'BuyersOrderNumberEditable');
      if (settingValue != null) {
        this.SALE_buyersOrderNumberEditable = settingValue;
      } else {
        this.SALE_buyersOrderNumberEditable = defaultValue;
      }
    }
    return this.SALE_buyersOrderNumberEditable;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_OriginalInvoiceNumberEditable(defaultValue: boolean): boolean {
    if (typeof this.SALE_originalInvoiceNumberEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'OriginalInvoiceNumberEditable');
      if (settingValue != null) {
        this.SALE_originalInvoiceNumberEditable = settingValue;
      } else {
        this.SALE_originalInvoiceNumberEditable = defaultValue;
      }
    }
    return this.SALE_originalInvoiceNumberEditable;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_BuyerFieldsEditable(defaultValue: boolean): boolean {
    if (typeof this.SALE_BuyerFieldsEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'BuyerFieldsEditable');
      if (settingValue != null) {
        this.SALE_BuyerFieldsEditable = settingValue;
      } else {
        this.SALE_BuyerFieldsEditable = defaultValue;
      }
    }
    return this.SALE_BuyerFieldsEditable;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_DeliveryToFieldsEditable(defaultValue: boolean): boolean {
    if (typeof this.SALE_DeliveryToFieldsEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DeliveryToFieldsEditable');
      if (settingValue != null) {
        this.SALE_DeliveryToFieldsEditable = settingValue;
      } else {
        this.SALE_DeliveryToFieldsEditable = defaultValue;
      }
    }
    return this.SALE_DeliveryToFieldsEditable;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_InvoiceToFieldsEditable(defaultValue: boolean): boolean {
    if (typeof this.SALE_InvoiceToFieldsEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'InvoiceToFieldsEditable');
      if (settingValue != null) {
        this.SALE_InvoiceToFieldsEditable = settingValue;
      } else {
        this.SALE_InvoiceToFieldsEditable = defaultValue;
      }
    }
    return this.SALE_InvoiceToFieldsEditable;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_UseOrderReceivedQuantity(defaultValue: boolean): boolean {
    if (typeof this.SALE_UseOrderReceivedQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'UseOrderReceivedQuantity');
      if (settingValue != null) {
        this.SALE_UseOrderReceivedQuantity = settingValue;
      } else {
        this.SALE_UseOrderReceivedQuantity = defaultValue;
      }
    }
    return this.SALE_UseOrderReceivedQuantity;
  }
  getSALE_DefaultValueAddedTax(defaultValue: number): number {
    if (typeof this.SALE_DefaultValueAddedTax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DefaultValueAddedTax');
      if (settingValue != null) {
        this.SALE_DefaultValueAddedTax = settingValue;
      } else {
        this.SALE_DefaultValueAddedTax = defaultValue;
      }
    }
    return this.SALE_DefaultValueAddedTax;
  }
  getSALE_ShowPanelTaxes(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelTaxes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelTaxes');
      if (settingValue != null) {
        this.SALE_ShowPanelTaxes = settingValue;
      } else {
        this.SALE_ShowPanelTaxes = defaultValue;
      }
    }
    return this.SALE_ShowPanelTaxes;
  }
  getSALE_ShowPanelEDI(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelEDI === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelEDINonSP');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelEDI');
      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.SALE_ShowPanelEDI = settingValue;
      } else if (settingValueSP) {
        this.SALE_ShowPanelEDI = settingValueSP;
      } else {
        this.SALE_ShowPanelEDI = defaultValue;
      }
    }
    return this.SALE_ShowPanelEDI;
  }
  /**
  * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
  * @param  {boolean} defaultValue - Valor por defeito do parametro
  * @returns boolean
  */
  getSALE_ShowPanelInvoiceTo(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelInvoiceTo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelInvoiceTo');
      if (settingValue != null) {
        this.SALE_ShowPanelInvoiceTo = settingValue;
      } else {
        this.SALE_ShowPanelInvoiceTo = defaultValue;
      }
    }
    return this.SALE_ShowPanelInvoiceTo;
  }

  getSALE_RequiredInvoiceTo(defaultValue: boolean): boolean {
    if (typeof this.SALE_RequiredInvoiceTo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'RequiredInvoiceTo');
      if (settingValue != null) {
        this.SALE_RequiredInvoiceTo = settingValue;
      } else {
        this.SALE_RequiredInvoiceTo = defaultValue;
      }
    }
    return this.SALE_RequiredInvoiceTo;
  }

  getSALE_ShowPanelDiscounts(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelDiscounts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelDiscounts');
      if (settingValue != null) {
        this.SALE_ShowPanelDiscounts = settingValue;
      } else {
        this.SALE_ShowPanelDiscounts = defaultValue;
      }
    }
    return this.SALE_ShowPanelDiscounts;
  }

  getSALE_ShowPanelAllowances(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelAllowances === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelAllowances');
      if (settingValue != null) {
        this.SALE_ShowPanelAllowances = settingValue;
      } else {
        this.SALE_ShowPanelAllowances = defaultValue;
      }
    }
    return this.SALE_ShowPanelAllowances;
  }

  getSALE_PossibleTypes(defaultValue: Array<string>): Array<string> {
    if (typeof this.SALE_PossibleTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'PossibleTypes');
      if (settingValue != null) {
        this.SALE_PossibleTypes = settingValue;
      } else {
        this.SALE_PossibleTypes = defaultValue;
      }
    }
    return this.SALE_PossibleTypes;
  }

  getINTERNAL_MOVEMENTS_PossibleTypes(defaultValue: Array<string>): Array<string> {
    if (typeof this.INTERNAL_MOVEMENT_PossibleTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InternalMovements', 'PossibleTypes');
      if (settingValue != null) {
        this.INTERNAL_MOVEMENT_PossibleTypes = settingValue;
      } else {
        this.INTERNAL_MOVEMENT_PossibleTypes = defaultValue;
      }
    }
    return this.INTERNAL_MOVEMENT_PossibleTypes;
  }

  getSALE_ShowPanelPaymentConditions(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelPaymentConditions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelPaymentConditions');
      if (settingValue != null) {
        this.SALE_ShowPanelPaymentConditions = settingValue;
      } else {
        this.SALE_ShowPanelPaymentConditions = defaultValue;
      }
    }
    return this.SALE_ShowPanelPaymentConditions;
  }
  /**
  * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
  * @param  {boolean} defaultValue - Valor por defeito do parametro
  * @returns boolean
  */
  getSALE_AllowCreateEmptyDetail(defaultValue: boolean): boolean {
    if (typeof this.SALE_AllowCreateEmptyDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllowCreateEmptyDetail');
      if (settingValue != null) {
        this.SALE_AllowCreateEmptyDetail = settingValue;
      } else {
        this.SALE_AllowCreateEmptyDetail = defaultValue;
      }
    }
    return this.SALE_AllowCreateEmptyDetail;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue -Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nome das colunas
   * @returns Array
   */
  getSALE_ColumnInVisible_Details(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALE_columnInVisible_Details === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ColumVisible_Details');
      this.SALE_columnInVisible_Details = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.SALE_columnInVisible_Details;
  }
  /*
  getSALE_ColumnInVisible_Details(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALE_columnInVisible_Details === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ColumInvisible_Details');
      if (settingValue != null) {
        let columns = settingValue.split(',');
        let indexes = '[';
        for (let column of columns) {
          indexes += columnsNames.indexOf(column.replace(/ /g, '')) + ', ';
        }
        indexes = indexes.substring(0, indexes.length - 2);

        this.SALE_columnInVisible_Details = JSON.parse(indexes + ']');
      } else {
        this.SALE_columnInVisible_Details = defaultValue;
      }
    }
    return this.SALE_columnInVisible_Details;
  }
  */
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nome das colunas
   * @returns Array
   */
  getSALE_ColumnInVisible_Details_SP(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALE_columnInVisible_Details_SP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ColumVisible_Details_SP');
      this.SALE_columnInVisible_Details_SP = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.SALE_columnInVisible_Details_SP;
  }
  /*
  getSALE_ColumnInVisible_Details_SP(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALE_columnInVisible_Details_SP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ColumInvisible_Details_SP');
      if (settingValue != null) {
        let columns = settingValue.split(',');
        let indexes = '[';
        for (let column of columns) {
          indexes += columnsNames.indexOf(column.replace(/ /g, '')) + ', ';
        }
        indexes = indexes.substring(0, indexes.length - 2);

        this.SALE_columnInVisible_Details_SP = JSON.parse(indexes + ']');
      } else {
        this.SALE_columnInVisible_Details_SP = defaultValue;
      }
    }
    return this.SALE_columnInVisible_Details_SP;
  }
  */
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolea
   */
  getSALE_Details_All(defaultValue: boolean): boolean {
    if (typeof this.SALE_Details_All === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllDetailsEditable');
      if (settingValue != null) {
        this.SALE_Details_All = settingValue;
      } else {
        this.SALE_Details_All = defaultValue;
      }
    }
    return this.SALE_Details_All;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue -Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_MsgCreate(defaultValue: boolean): boolean {
    if (typeof this.SALE_msgCreate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'MessagesCreate');
      if (settingValue != null) {
        this.SALE_msgCreate = settingValue;
      } else {
        this.SALE_msgCreate = defaultValue;
      }
    }
    return this.SALE_msgCreate;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_MsgDelete(defaultValue: boolean): boolean {
    if (typeof this.SALE_msgDelete === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'MessagesDelete');
      if (settingValue != null) {
        this.SALE_msgDelete = settingValue;
      } else {
        this.SALE_msgDelete = defaultValue;
      }
    }
    return this.SALE_msgDelete;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_DigArcCreate(defaultValue: boolean): boolean {
    if (typeof this.SALE_digArcCreate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DigArchiveCreate');
      if (settingValue != null) {
        this.SALE_digArcCreate = settingValue;
      } else {
        this.SALE_digArcCreate = defaultValue;
      }
    }
    return this.SALE_digArcCreate;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico,caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getSALE_DigArcDelete(defaultValue: boolean): boolean {
    if (typeof this.SALE_digArcDelete === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DigArchiveDelete');
      if (settingValue != null) {
        this.SALE_digArcDelete = settingValue;
      } else {
        this.SALE_digArcDelete = defaultValue;
      }
    }
    return this.SALE_digArcDelete;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico,caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
   getSALE_DigArcLimitNumberOfFiles(defaultValue: boolean): boolean {
    if (typeof this.SALE_digArcLimitNumberOfFiles === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DigArcLimitNumberOfFiles');
      if (settingValue != null) {
        this.SALE_digArcLimitNumberOfFiles = settingValue;
      } else {
        this.SALE_digArcLimitNumberOfFiles = defaultValue;
      }
    }
    return this.SALE_digArcLimitNumberOfFiles;
  }

  getSALE_HideColumsTax(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideColumsTax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HideColumsTax');
      if (settingValue != null) {
        this.SALE_hideColumsTax = settingValue;
      } else {
        this.SALE_hideColumsTax = defaultValue;
      }
    }
    return this.SALE_hideColumsTax;
  }

  getSALE_GroupVatExemption(defaultValue: boolean): boolean {
    if (typeof this.SALE_groupVatExemption === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'GroupVatExemption');
      if (settingValue != null) {
        this.SALE_groupVatExemption = settingValue;
      } else {
        this.SALE_groupVatExemption = defaultValue;
      }
    }
    return this.SALE_groupVatExemption;
  }

  getPURCHASE_GroupVatExemption(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_groupVatExemption === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'GroupVatExemption');
      if (settingValue != null) {
        this.PURCHASE_groupVatExemption = settingValue;
      } else {
        this.PURCHASE_groupVatExemption = defaultValue;
      }
    }
    return this.PURCHASE_groupVatExemption;
  }

  get_SALE_showOrdersTab(defaultValue: boolean): boolean {
    if (typeof this.SALE_showOrdersTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowOrdersTab');
      if (settingValue != null) {
        this.SALE_showOrdersTab = settingValue;
      } else {
        this.SALE_showOrdersTab = defaultValue;
      }
    }
    return this.SALE_showOrdersTab;
  }

  // se produtos repetidos
  getSALE_allowsRepeatedProducts(defaultValue: boolean): boolean {
    if (typeof this.SALE_allowsRepeatedProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllowsRepeatedProducts');
      if (settingValue != null) {
        this.SALE_allowsRepeatedProducts = settingValue;
      } else {
        this.SALE_allowsRepeatedProducts = defaultValue;
      }
    }
    return this.SALE_allowsRepeatedProducts;
  }
  // se mostra botao listagem produtos
  getSALE_showButtonSelectProducts(defaultValue: boolean): boolean {
    if (typeof this.SALE_showButtonSelectProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowButtonSelectProducts');
      if (settingValue != null) {
        this.SALE_showButtonSelectProducts = settingValue;
      } else {
        this.SALE_showButtonSelectProducts = defaultValue;
      }
    }
    return this.SALE_showButtonSelectProducts;
  }

  getSALE_showBuyerTelephone(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerTelephone === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerTelephone');
      if (settingValue != null) {
        this.SALE_showBuyerTelephone = settingValue;
      } else {
        this.SALE_showBuyerTelephone = defaultValue;
      }
    }
    return this.SALE_showBuyerTelephone;
  }

  getSALE_showBuyerFax(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerFax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerFax');
      if (settingValue != null) {
        this.SALE_showBuyerFax = settingValue;
      } else {
        this.SALE_showBuyerFax = defaultValue;
      }
    }
    return this.SALE_showBuyerFax;
  }

  getSALE_showBuyerElectronicMail(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerElectronicMail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerElectronicMail');
      if (settingValue != null) {
        this.SALE_showBuyerElectronicMail = settingValue;
      } else {
        this.SALE_showBuyerElectronicMail = defaultValue;
      }
    }
    return this.SALE_showBuyerElectronicMail;
  }

  getSALE_showBuyerDepartmentCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerDepartmentCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerDepartmentCode');
      if (settingValue != null) {
        this.SALE_showBuyerDepartmentCode = settingValue;
      } else {
        this.SALE_showBuyerDepartmentCode = defaultValue;
      }
    }
    return this.SALE_showBuyerDepartmentCode;
  }

  getSALE_showBuyerDepartmentName(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerDepartmentName === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerDepartmentName');
      if (settingValue != null) {
        this.SALE_showBuyerDepartmentName = settingValue;
      } else {
        this.SALE_showBuyerDepartmentName = defaultValue;
      }
    }
    return this.SALE_showBuyerDepartmentName;
  }

  getSALE_showBuyerDivisionCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerDivisionCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerDivisionCode');
      if (settingValue != null) {
        this.SALE_showBuyerDivisionCode = settingValue;
      } else {
        this.SALE_showBuyerDivisionCode = defaultValue;
      }
    }
    return this.SALE_showBuyerDivisionCode;
  }

  getSALE_showDeliveryToTelephone(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryToTelephone === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryToTelephone');
      if (settingValue != null) {
        this.SALE_showDeliveryToTelephone = settingValue;
      } else {
        this.SALE_showDeliveryToTelephone = defaultValue;
      }
    }
    return this.SALE_showDeliveryToTelephone;
  }

  getSALE_showDeliveryToFax(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryToFax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryToFax');
      if (settingValue != null) {
        this.SALE_showDeliveryToFax = settingValue;
      } else {
        this.SALE_showDeliveryToFax = defaultValue;
      }
    }
    return this.SALE_showDeliveryToFax;
  }

  getSALE_showDeliveryToElectronicMail(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryToElectronicMail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryToElectronicMail');
      if (settingValue != null) {
        this.SALE_showDeliveryToElectronicMail = settingValue;
      } else {
        this.SALE_showDeliveryToElectronicMail = defaultValue;
      }
    }
    return this.SALE_showDeliveryToElectronicMail;
  }

  getSALE_showDeliveryLoadingLocation(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryLoadingLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryLoadingLocation');
      if (settingValue != null) {
        this.SALE_showDeliveryLoadingLocation = settingValue;
      } else {
        this.SALE_showDeliveryLoadingLocation = defaultValue;
      }
    }
    return this.SALE_showDeliveryLoadingLocation;
  }

  getSALE_showDeliveryLoadingDate(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryLoadingDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryLoadingDate');
      if (settingValue != null) {
        this.SALE_showDeliveryLoadingDate = settingValue;
      } else {
        this.SALE_showDeliveryLoadingDate = defaultValue;
      }
    }
    return this.SALE_showDeliveryLoadingDate;
  }

  getSALE_showDeliveryLocation(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryLocation');
      if (settingValue != null) {
        this.SALE_showDeliveryLocation = settingValue;
      } else {
        this.SALE_showDeliveryLocation = defaultValue;
      }
    }
    return this.SALE_showDeliveryLocation;
  }

  getSALE_showDeliveryTransportID(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryTransportID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryTransportID');
      if (settingValue != null) {
        this.SALE_showDeliveryTransportID = settingValue;
      } else {
        this.SALE_showDeliveryTransportID = defaultValue;
      }
    }
    return this.SALE_showDeliveryTransportID;
  }

  getSALE_showDeliveryConditionsValue(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryConditionsValue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryConditionsValue');
      if (settingValue != null) {
        this.SALE_showDeliveryConditionsValue = settingValue;
      } else {
        this.SALE_showDeliveryConditionsValue = defaultValue;
      }
    }
    return this.SALE_showDeliveryConditionsValue;
  }

  getSALE_showDeliveryFreeText(defaultValue: boolean): boolean {
    if (typeof this.SALE_showDeliveryFreeText === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowDeliveryFreeText');
      if (settingValue != null) {
        this.SALE_showDeliveryFreeText = settingValue;
      } else {
        this.SALE_showDeliveryFreeText = defaultValue;
      }
    }
    return this.SALE_showDeliveryFreeText;
  }

  getSALE_showTotalChargeAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_showTotalChargeAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalChargeAmount');
      if (settingValue != null) {
        this.SALE_showTotalChargeAmount = settingValue;
      } else {
        this.SALE_showTotalChargeAmount = defaultValue;
      }
    }
    return this.SALE_showTotalChargeAmount;
  }

  getSALE_showTotalDiscountAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_showTotalDiscountAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalDiscountAmount');
      if (settingValue != null) {
        this.SALE_showTotalDiscountAmount = settingValue;
      } else {
        this.SALE_showTotalDiscountAmount = defaultValue;
      }
    }
    return this.SALE_showTotalDiscountAmount;
  }

  getSALE_showTotalPackingCostAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_showTotalPackingCostAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalPackingCostAmount');
      if (settingValue != null) {
        this.SALE_showTotalPackingCostAmount = settingValue;
      } else {
        this.SALE_showTotalPackingCostAmount = defaultValue;
      }
    }
    return this.SALE_showTotalPackingCostAmount;
  }

  getSALE_showTotalPrePaymentAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_showTotalPrePaymentAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalPrePaymentAmount');
      if (settingValue != null) {
        this.SALE_showTotalPrePaymentAmount = settingValue;
      } else {
        this.SALE_showTotalPrePaymentAmount = defaultValue;
      }
    }
    return this.SALE_showTotalPrePaymentAmount;
  }

  getSALE_showCurrencyExchange(defaultValue: boolean): boolean {
    if (typeof this.SALE_showCurrencyExchange === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowCurrencyExchange');
      if (settingValue != null) {
        this.SALE_showCurrencyExchange = settingValue;
      } else {
        this.SALE_showCurrencyExchange = defaultValue;
      }
    }
    return this.SALE_showCurrencyExchange;
  }

  getSALE_showOthersReferencesTypes(defaultValue: boolean): boolean {
    if (typeof this.SALE_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowOthersReferencesTypes');
      if (settingValue != null) {
        this.SALE_showOthersReferencesTypes = settingValue;
      } else {
        this.SALE_showOthersReferencesTypes = defaultValue;
      }
    }
    return this.SALE_showOthersReferencesTypes;
  }

  getSALES_UseOrderAndDeliveryNoteREFs(defaultValue: boolean): boolean {
    if (typeof this.SALE_UseOrderAndDeliveryNoteREFs === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'UseOrderAndDeliveryNoteREFs');
      if (settingValue != null) {
        this.SALE_UseOrderAndDeliveryNoteREFs = settingValue;
      } else {
        this.SALE_UseOrderAndDeliveryNoteREFs = defaultValue;
      }
    }
    return this.SALE_UseOrderAndDeliveryNoteREFs;
  }

  getSALE_showBuyersCostCentre(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowBuyersCostCentre === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyersCostCentre');
      if (settingValue != null) {
        this.SALE_ShowBuyersCostCentre = settingValue;
      } else {
        this.SALE_ShowBuyersCostCentre = defaultValue;
      }
    }
    return this.SALE_ShowBuyersCostCentre;
  }

  getSALE_showCertificateNumber(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowCertificateNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowCertificateNumber');
      if (settingValue != null) {
        this.SALE_ShowCertificateNumber = settingValue;
      } else {
        this.SALE_ShowCertificateNumber = defaultValue;
      }
    }
    return this.SALE_ShowCertificateNumber;
  }

  getSALE_showTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalGrossAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.SALE_ShowTotalGrossAmount = settingValue;
      } else {
        this.SALE_ShowTotalGrossAmount = defaultValue;
      }
    }
    return this.SALE_ShowTotalGrossAmount;
  }

  getSALE_showTotalTransportAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_showTotalTransportAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalTransportAmount');
      if (settingValue != null) {
        this.SALE_showTotalTransportAmount = settingValue;
      } else {
        this.SALE_showTotalTransportAmount = defaultValue;
      }
    }
    return this.SALE_showTotalTransportAmount;
  }

  getSALE_showTotalRetencaoIrsPercentage(defaultValue: boolean): boolean {
    if (typeof this.SALE_showTotalRetencaoIrsPercentage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalRetencaoIrsPercentage');
      if (settingValue != null) {
        this.SALE_showTotalRetencaoIrsPercentage = settingValue;
      } else {
        this.SALE_showTotalRetencaoIrsPercentage = defaultValue;
      }
    }
    return this.SALE_showTotalRetencaoIrsPercentage;
  }

  getSALE_showTotalRetencaoIrsAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalRetencaoIrsAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalRetencaoIrsAmount');
      if (settingValue != null) {
        this.SALE_ShowTotalRetencaoIrsAmount = settingValue;
      } else {
        this.SALE_ShowTotalRetencaoIrsAmount = defaultValue;
      }
    }
    return this.SALE_ShowTotalRetencaoIrsAmount;
  }

  getSALE_showTotalEnvironmentalTaxAmount(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalEnvironmentalTaxAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalEnvironmentalTaxAmount');
      if (settingValue != null) {
        this.SALE_ShowTotalEnvironmentalTaxAmount = settingValue;
      } else {
        this.SALE_ShowTotalEnvironmentalTaxAmount = defaultValue;
      }
    }
    return this.SALE_ShowTotalEnvironmentalTaxAmount;
  }

  getSALE_showTotalNetWeight(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalNetWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalNetWeight');
      if (settingValue != null) {
        this.SALE_ShowTotalNetWeight = settingValue;
      } else {
        this.SALE_ShowTotalNetWeight = defaultValue;
      }
    }
    return this.SALE_ShowTotalNetWeight;
  }

  getSALE_showTotalNetWeightUOMCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalNetWeightUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalNetWeightUOMCode');
      if (settingValue != null) {
        this.SALE_ShowTotalNetWeightUOMCode = settingValue;
      } else {
        this.SALE_ShowTotalNetWeightUOMCode = defaultValue;
      }
    }
    return this.SALE_ShowTotalNetWeightUOMCode;
  }

  getSALE_showTotalGrossWeight(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalGrossWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalGrossWeight');
      if (settingValue != null) {
        this.SALE_ShowTotalGrossWeight = settingValue;
      } else {
        this.SALE_ShowTotalGrossWeight = defaultValue;
      }
    }
    return this.SALE_ShowTotalGrossWeight;
  }

  getSALE_showTotalGrossWeightUOMCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalGrossWeightUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalGrossWeightUOMCode');
      if (settingValue != null) {
        this.SALE_ShowTotalGrossWeightUOMCode = settingValue;
      } else {
        this.SALE_ShowTotalGrossWeightUOMCode = defaultValue;
      }
    }
    return this.SALE_ShowTotalGrossWeightUOMCode;
  }

  getSALE_showTotalNetVolume(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalNetVolume === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalNetVolume');
      if (settingValue != null) {
        this.SALE_ShowTotalNetVolume = settingValue;
      } else {
        this.SALE_ShowTotalNetVolume = defaultValue;
      }
    }
    return this.SALE_ShowTotalNetVolume;
  }

  getSALE_showTotalNetVolumeUOMCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowTotalNetVolumeUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowTotalNetVolumeUOMCode');
      if (settingValue != null) {
        this.SALE_ShowTotalNetVolumeUOMCode = settingValue;
      } else {
        this.SALE_ShowTotalNetVolumeUOMCode = defaultValue;
      }
    }
    return this.SALE_ShowTotalNetVolumeUOMCode;
  }

  getSALE_showInvoiceTelephone(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceTelephone === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceTelephone');
      if (settingValue != null) {
        this.SALE_showInvoiceTelephone = settingValue;
      } else {
        this.SALE_showInvoiceTelephone = defaultValue;
      }
    }
    return this.SALE_showInvoiceTelephone;
  }

  getSALE_showInvoiceFax(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceFax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceFax');
      if (settingValue != null) {
        this.SALE_showInvoiceFax = settingValue;
      } else {
        this.SALE_showInvoiceFax = defaultValue;
      }
    }
    return this.SALE_showInvoiceFax;
  }

  getSALE_showInvoiceElectronicMail(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceElectronicMail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceElectronicMail');
      if (settingValue != null) {
        this.SALE_showInvoiceElectronicMail = settingValue;
      } else {
        this.SALE_showInvoiceElectronicMail = defaultValue;
      }
    }
    return this.SALE_showInvoiceElectronicMail;
  }

  getSALE_showPaymentConditionsInvoiceDueDate(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPaymentConditionsInvoiceDueDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPaymentConditionsInvoiceDueDate');
      if (settingValue != null) {
        this.SALE_showPaymentConditionsInvoiceDueDate = settingValue;
      } else {
        this.SALE_showPaymentConditionsInvoiceDueDate = defaultValue;
      }
    }
    return this.SALE_showPaymentConditionsInvoiceDueDate;
  }

  getSALE_showPaymentConditionsServicesTermDate(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPaymentConditionsServicesTermDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPaymentConditionsServicesTermDate');
      if (settingValue != null) {
        this.SALE_showPaymentConditionsServicesTermDate = settingValue;
      } else {
        this.SALE_showPaymentConditionsServicesTermDate = defaultValue;
      }
    }
    return this.SALE_showPaymentConditionsServicesTermDate;
  }

  getSALE_showPaymentConditionsList(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPaymentConditionsList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPaymentConditionsList');
      if (settingValue != null) {
        this.SALE_showPaymentConditionsList = settingValue;
      } else {
        this.SALE_showPaymentConditionsList = defaultValue;
      }
    }
    return this.SALE_showPaymentConditionsList;
  }

  getSALE_showPaymentMethod(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPaymentMethod === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPaymentMethod');
      if (settingValue != null) {
        this.SALE_showPaymentMethod = settingValue;
      } else {
        this.SALE_showPaymentMethod = defaultValue;
      }
    }
    return this.SALE_showPaymentMethod;
  }

  getSALE_invoiceRepositoryDiskFileContext(defaultValue: string): string {
    if (typeof this.SALE_invoiceRepositoryDiskFileContext === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'InvoiceRepositoryDiskFileContext');
      if (settingValue != null) {
        this.SALE_invoiceRepositoryDiskFileContext = settingValue;
      } else {
        this.SALE_invoiceRepositoryDiskFileContext = defaultValue;
      }
    }
    return this.SALE_invoiceRepositoryDiskFileContext;
  }

  getSALE_validateTotalNetAmountWhen0(defaultValue: boolean): boolean {
    if (typeof this.SALE_validateTotalNetAmountWhen0 === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ValidateTotalNetAmountWhen0');
      if (settingValue != null) {
        this.SALE_validateTotalNetAmountWhen0 = settingValue;
      } else {
        this.SALE_validateTotalNetAmountWhen0 = defaultValue;
      }
    }
    return this.SALE_validateTotalNetAmountWhen0;
  }

  getSALE_showInvoiceDueDays(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceDueDays === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceDueDays');
      if (settingValue != null) {
        this.SALE_showInvoiceDueDays = settingValue;
      } else {
        this.SALE_showInvoiceDueDays = defaultValue;
      }
    }
    return this.SALE_showInvoiceDueDays;
  }

  getSALE_maxPaymentMethods(defaultValue: number): number {
    if (typeof this.SALE_maxPatmentMethods === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'MaxPaymentMethods');
      if (settingValue != null) {
        this.SALE_maxPatmentMethods = settingValue;
      } else {
        this.SALE_maxPatmentMethods = defaultValue;
      }
    }
    return this.SALE_maxPatmentMethods;
  }

  getSALE_ShowPanelPaymentInfo(defaultValue: boolean): boolean {
    if (typeof this.SALE_ShowPanelPaymentInfo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelPaymentInfo');
      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.SALE_ShowPanelPaymentInfo = settingValue;
      } else {
        this.SALE_ShowPanelPaymentInfo = defaultValue;
      }
    }
    return this.SALE_ShowPanelPaymentInfo;
  }

  getSALE_disableTotalsPanel(defaultValue: boolean): boolean {
    if (typeof this.SALE_disableTotalsPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DisableTotalsPanel');
      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.SALE_disableTotalsPanel = settingValue;
      } else {
        this.SALE_disableTotalsPanel = defaultValue;
      }
    }
    return this.SALE_disableTotalsPanel;
  }

  getSALE_disableTaxPanel(defaultValue: boolean): boolean {
    if (typeof this.SALE_disableTaxPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DisableTaxPanel');
      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.SALE_disableTaxPanel = settingValue;
      } else {
        this.SALE_disableTaxPanel = defaultValue;
      }
    }
    return this.SALE_disableTaxPanel;
  }

  getSALE_validateMaxNetUnitPrice(defaultValue: boolean): boolean {
    if (typeof this.SALE_validateMaxNetUnitPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ValidateMaxNetUnitPrice');
      if (settingValue != null) {
        this.SALE_validateMaxNetUnitPrice = settingValue;
      } else {
        this.SALE_validateMaxNetUnitPrice = defaultValue;
      }
    }
    return this.SALE_validateMaxNetUnitPrice;
  }

  getSALE_showPaymentCondition(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPaymentCondition === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPaymentCondition');
      if (settingValue != null) {
        this.SALE_showPaymentCondition = settingValue;
      } else {
        this.SALE_showPaymentCondition = defaultValue;
      }
    }
    return this.SALE_showPaymentCondition;
  }

  getSALE_showMeansOfPayment(defaultValue: boolean): boolean {
    if (typeof this.SALE_showMeansOfPayment === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowMeansOfPayment');
      if (settingValue != null) {
        this.SALE_showMeansOfPayment = settingValue;
      } else {
        this.SALE_showMeansOfPayment = defaultValue;
      }
    }
    return this.SALE_showMeansOfPayment;
  }

  getSALES_useCertficateNumberInOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.SALE_useCertficateNumberInOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'UseCertficateNumberInOtherReferences');
      if (settingValue != null) {
        this.SALE_useCertficateNumberInOtherReferences = settingValue;
      } else {
        this.SALE_useCertficateNumberInOtherReferences = defaultValue;
      }
    }
    return this.SALE_useCertficateNumberInOtherReferences;
  }

  getSALES_useCostCenterInOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.SALE_useCostCenterInOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'UseCostCenterInOtherReferences');
      if (settingValue != null) {
        this.SALE_useCostCenterInOtherReferences = settingValue;
      } else {
        this.SALE_useCostCenterInOtherReferences = defaultValue;
      }
    }
    return this.SALE_useCostCenterInOtherReferences;
  }
  
  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
   getSALES_allowedInvoicetypes(defaultValue: Array<string>): Array<string> {
    if (typeof this.SALES_allowedInvoiceTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'AllowedInvoiceTypes');
      if (settingValue != null) {
        this.SALES_allowedInvoiceTypes = settingValue;
      } else {
        this.SALES_allowedInvoiceTypes = defaultValue;
      }
    }
    return this.SALES_allowedInvoiceTypes;
  }

  getSALE_isMultipleOrder(defaultValue: boolean): boolean {
    if (typeof this.SALE_isMultipleOrder === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'IsMultipleOrder');
      if (settingValue != null) {
        this.SALE_isMultipleOrder = settingValue;
      } else {
        this.SALE_isMultipleOrder = defaultValue;
      }
    }
    return this.SALE_isMultipleOrder;
  }

  getSALE_showNextDocumentNumber(defaultValue: boolean): boolean {
    if (typeof this.SALE_showNextDocumentNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowNextDocumentNumber');
      if (settingValue != null) {
        this.SALE_showNextDocumentNumber = settingValue;
      } else {
        this.SALE_showNextDocumentNumber = defaultValue;
      }
    }
    return this.SALE_showNextDocumentNumber;
  }

  getSALE_showButtonClearOrders(defaultValue: boolean): boolean {
    if (typeof this.SALE_showButtonClearOrders === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowButtonClearOrders');
      if (settingValue != null) {
        this.SALE_showButtonClearOrders = settingValue;
      } else {
        this.SALE_showButtonClearOrders = defaultValue;
      }
    }
    return this.SALE_showButtonClearOrders;
  }

  getSALE_showBuyerContractNumber(defaultValue: boolean): boolean {
    if (typeof this.SALE_showBuyerContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowBuyerContractNumber');
      if (settingValue != null) {
        this.SALE_showBuyerContractNumber = settingValue;
      } else {
        this.SALE_showBuyerContractNumber = defaultValue;
      }
    }
    return this.SALE_showBuyerContractNumber;
  }


  getSALE_showPaid(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPaid === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPaid');
      if (settingValue != null) {
        this.SALE_showPaid = settingValue;
      } else {
        this.SALE_showPaid = defaultValue;
      }
    }
    return this.SALE_showPaid;
  }

  getSALE_showCreationDate(defaultValue: boolean): boolean {
    if (typeof this.SALE_showCreationDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowCreationDate');
      if (settingValue != null) {
        this.SALE_showCreationDate = settingValue;
      } else {
        this.SALE_showCreationDate = defaultValue;
      }
    }
    return this.SALE_showCreationDate;
  }

  getSALE_loadOrderPaidOnInvoiceCreate(defaultValue: boolean): boolean {
    if (typeof this.SALE_loadOrderPaidOnInvoiceCreate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'LoadOrderPaidOnInvoiceCreate');
      if (settingValue != null) {
        this.SALE_loadOrderPaidOnInvoiceCreate = settingValue;
      } else {
        this.SALE_loadOrderPaidOnInvoiceCreate = defaultValue;
      }
    }
    return this.SALE_loadOrderPaidOnInvoiceCreate;
  }

  getSALE_showAttachesIsPublic(defaultValue: boolean): boolean {
    if (typeof this.SALE_showAttachesIsPublic === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowAttachesIsPublic');
      if (settingValue != null) {
        this.SALE_showAttachesIsPublic = settingValue;
      } else {
        this.SALE_showAttachesIsPublic = defaultValue;
      }
    }
    return this.SALE_showAttachesIsPublic;
  }


  getSALE_showATCUD(defaultValue: boolean): boolean {
    if (typeof this.SALE_ATCUD === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowATCUD');
      if (settingValue != null) {
        this.SALE_ATCUD = settingValue;
      } else {
        this.SALE_ATCUD = defaultValue;
      }
    }
    return this.SALE_ATCUD;
  }

  getSALE_hideGuideNote(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideGuideNote === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HideGuideNote');
      if (settingValue != null) {
        this.SALE_hideGuideNote = settingValue;
      } else {
        this.SALE_hideGuideNote = defaultValue;
      }
    }
    return this.SALE_hideGuideNote;
  }

  getSALE_showInvoiceSerie(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceSerie === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceSerie');
      if (settingValue != null) {
        this.SALE_showInvoiceSerie = settingValue;
      } else {
        this.SALE_showInvoiceSerie = defaultValue;
      }
    }
    return this.SALE_showInvoiceSerie;
  }
  
  getSALE_defaultDateTimeFormat(defaultValue: number): number {
    if (typeof this.defaultDateTimeFormat_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'DefaultDateTimeFormat');
      if (settingValue != null) {
        this.defaultDateTimeFormat_SALE = settingValue;
      } else {
        this.defaultDateTimeFormat_SALE = defaultValue;
      }
    }
    return this.defaultDateTimeFormat_SALE;
  }

  getSALE_showInvoiceToStreet(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceToStreet === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceToStreet');
      if (settingValue != null) {
        this.SALE_showInvoiceToStreet = settingValue;
      } else {
        this.SALE_showInvoiceToStreet = defaultValue;
      }
    }
    return this.SALE_showInvoiceToStreet;
  }

  getSALE_showInvoiceToCity(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceToCity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceToCity');
      if (settingValue != null) {
        this.SALE_showInvoiceToCity = settingValue;
      } else {
        this.SALE_showInvoiceToCity = defaultValue;
      }
    }
    return this.SALE_showInvoiceToCity;
  }

  getSALE_showInvoiceToCountryCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceToCountryCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceToCountryCode');
      if (settingValue != null) {
        this.SALE_showInvoiceToCountryCode = settingValue;
      } else {
        this.SALE_showInvoiceToCountryCode = defaultValue;
      }
    }
    return this.SALE_showInvoiceToCountryCode;
  }

  getSALE_showInvoiceToPostalCode(defaultValue: boolean): boolean {
    if (typeof this.SALE_showInvoiceToPostalCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowInvoiceToPostalCode');
      if (settingValue != null) {
        this.SALE_showInvoiceToPostalCode = settingValue;
      } else {
        this.SALE_showInvoiceToPostalCode = defaultValue;
      }
    }
    return this.SALE_showInvoiceToPostalCode;
  }

  getSALE_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.SALE_afterSaveRemainOnSamePage === 'undefined') {
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.SALE_afterSaveRemainOnSamePage = settingValue;
      }
      else {
        this.SALE_afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.SALE_afterSaveRemainOnSamePage;
  }

  getSALE_submitOnlyAfterCreate(defaultValue: boolean): boolean {
    if (typeof this.SALE_submitOnlyAfterCreate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'SubmitOnlyAfterCreate');
      if (settingValue != null) {
        this.SALE_submitOnlyAfterCreate = settingValue;
      } else {
        this.SALE_submitOnlyAfterCreate = defaultValue;
      }
    }
    return this.SALE_submitOnlyAfterCreate;
  }


  getSALE_hideOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HideOtherReferences');
      if (settingValue != null) {
        this.SALE_hideOtherReferences = settingValue;
      } else {
        this.SALE_hideOtherReferences = defaultValue;
      }
    }
    return this.SALE_hideOtherReferences;
  }

  getSALE_hidePanelClient(defaultValue: boolean): boolean {
    if (typeof this.SALE_hidePanelClient === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HidePanelClient');
      if (settingValue != null) {
        this.SALE_hidePanelClient = settingValue;
      } else {
        this.SALE_hidePanelClient = defaultValue;
      }
    }
    return this.SALE_hidePanelClient;
  }

  getSALE_hidePanelDelivery(defaultValue: boolean): boolean {
    if (typeof this.SALE_hidePanelDelivery === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HidePanelDelivery');
      if (settingValue != null) {
        this.SALE_hidePanelDelivery = settingValue;
      } else {
        this.SALE_hidePanelDelivery = defaultValue;
      }
    }
    return this.SALE_hidePanelDelivery;
  }

  getSALE_hidePanelObservations(defaultValue: boolean): boolean {
    if (typeof this.SALE_hidePanelObservations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HidePanelObservations');
      if (settingValue != null) {
        this.SALE_hidePanelObservations = settingValue;
      } else {
        this.SALE_hidePanelObservations = defaultValue;
      }
    }
    return this.SALE_hidePanelObservations;
  }

  getSALE_showPanelReferencesFirst(defaultValue: boolean): boolean {
    if (typeof this.SALE_showPanelReferencesFirst === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowPanelReferencesFirst');
      if (settingValue != null) {
        this.SALE_showPanelReferencesFirst = settingValue;
      } else {
        this.SALE_showPanelReferencesFirst = defaultValue;
      }
    }
    return this.SALE_showPanelReferencesFirst;
  }

  getSALE_allowedFileTypes(defaultValue: Array<string>): Array<string> {
    if (this.SALE_allowedFileTypes.length == 0) { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'AllowedFileTypes');
      if(settingValue)
        settingValue = settingValue.split(",");  
      if (settingValue != null) {
        this.SALE_allowedFileTypes = settingValue;
      } else {
        this.SALE_allowedFileTypes = defaultValue;
      }
    }
    return this.SALE_allowedFileTypes;
  }

  getSALE_hideAttachmentDescription(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideAttachmentDescription === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HideAttachmentDescription');
      if (settingValue != null) {
        this.SALE_hideAttachmentDescription = settingValue;
      } else {
        this.SALE_hideAttachmentDescription = defaultValue;
      }
    }
    return this.SALE_hideAttachmentDescription;
  }

  getSALE_hideAttachmentType(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideAttachmentType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'HideAttachmentType');
      if (settingValue != null) {
        this.SALE_hideAttachmentType = settingValue;
      } else {
        this.SALE_hideAttachmentType = defaultValue;
      }
    }
    return this.SALE_hideAttachmentType;
  }

  //#endregion  ---------------------- ***  VARIAVEIS SALE  *** ---------------------

  //#region ---------------------- ***  VARIAVEIS DETAILS SALE  *** ---------------------
  get_DETAIL_SALE_ShowBacthNumber(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showBacthNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowBacthNumber');
      if (settingValue != null) {
        this.DETAIL_SALE_showBacthNumber = settingValue;
      } else {
        this.DETAIL_SALE_showBacthNumber = defaultValue;
      }
    }
    return this.DETAIL_SALE_showBacthNumber;
  }
  get_DETAIL_SALE_ShowDeliveryDate(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDeliveryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowDeliveryDate');
      if (settingValue != null) {
        this.DETAIL_SALE_showDeliveryDate = settingValue;
      } else {
        this.DETAIL_SALE_showDeliveryDate = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDeliveryDate;
  }
  get_DETAIL_SALE_ShowBacthExpiryDate(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showBacthExpiryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowBacthExpiryDate');
      if (settingValue != null) {
        this.DETAIL_SALE_showBacthExpiryDate = settingValue;
      } else {
        this.DETAIL_SALE_showBacthExpiryDate = defaultValue;
      }
    }
    return this.DETAIL_SALE_showBacthExpiryDate;
  }
  get_DETAIL_SALE_BuyerProductCodeEditable(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_BuyerProductCodeEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'BuyerProductCodeEditable');
      if (settingValue != null) {
        this.DETAIL_SALE_BuyerProductCodeEditable = settingValue;
      } else {
        this.DETAIL_SALE_BuyerProductCodeEditable = defaultValue;
      }
    }
    return this.DETAIL_SALE_BuyerProductCodeEditable;
  }

  get_DETAIL_SALE_ShowPanelDiscounts(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_ShowPanelDiscounts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPanelDiscounts');
      if (settingValue != null) {
        this.DETAIL_SALE_ShowPanelDiscounts = settingValue;
      } else {
        this.DETAIL_SALE_ShowPanelDiscounts = defaultValue;
      }
    }
    return this.DETAIL_SALE_ShowPanelDiscounts;
  }

  get_DETAIL_SALE_ShowPanelAllowances(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_ShowPanelAllowances === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPanelAllowances');
      if (settingValue != null) {
        this.DETAIL_SALE_ShowPanelAllowances = settingValue;
      } else {
        this.DETAIL_SALE_ShowPanelAllowances = defaultValue;
      }
    }
    return this.DETAIL_SALE_ShowPanelAllowances;
  }

  get_DETAIL_SALE_ShowPanelReferences(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_ShowPanelReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPanelReferences');
      if (settingValue != null) {
        this.DETAIL_SALE_ShowPanelReferences = settingValue;
      } else {
        this.DETAIL_SALE_ShowPanelReferences = defaultValue;
      }
    }
    return this.DETAIL_SALE_ShowPanelReferences;
  }

  get_DETAIL_SALE_ShowDetailBonusQuantity(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailBonusQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowBonusQuantity');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailBonusQuantity = settingValue;
      } else {
        this.DETAIL_SALE_showDetailBonusQuantity = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailBonusQuantity;
  }

  get_DETAIL_SALE_ShowDetailBonusQuantityUOMCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailBonusQuantityUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowBonusQuantityUOMCode');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailBonusQuantityUOMCode = settingValue;
      } else {
        this.DETAIL_SALE_showDetailBonusQuantityUOMCode = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailBonusQuantityUOMCode;
  }

  get_DETAIL_SALE_ShowDetailPackQuantity(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailPackQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPackQuantity');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailPackQuantity = settingValue;
      } else {
        this.DETAIL_SALE_showDetailPackQuantity = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailPackQuantity;
  }

  get_DETAIL_SALE_ShowDetailPackQuantityUOMCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailPackQuantityUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPackQuantityUOMCode');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailPackQuantityUOMCode = settingValue;
      } else {
        this.DETAIL_SALE_showDetailPackQuantityUOMCode = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailPackQuantityUOMCode;
  }

  get_DETAIL_SALE_ShowDetailPackSize(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailPackSize === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPackSize');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailPackSize = settingValue;
      } else {
        this.DETAIL_SALE_showDetailPackSize = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailPackSize;
  }

  get_DETAIL_SALE_EditDetailTotalNetAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_editDetailTotalNetAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'EditTotalNetAmount');
      if (settingValue != null) {
        this.DETAIL_SALE_editDetailTotalNetAmount = settingValue;
      } else {
        this.DETAIL_SALE_editDetailTotalNetAmount = defaultValue;
      }
    }
    return this.DETAIL_SALE_editDetailTotalNetAmount;
  }

  get_DETAIL_SALE_EditDetailGrossUnitPrice(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_editDetailGrossUnitPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'EditGrossUnitPrice');
      if (settingValue != null) {
        this.DETAIL_SALE_editDetailGrossUnitPrice = settingValue;
      } else {
        this.DETAIL_SALE_editDetailGrossUnitPrice = defaultValue;
      }
    }
    return this.DETAIL_SALE_editDetailGrossUnitPrice;
  }

  get_DETAIL_SALE_EditDetailTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_editDetailTotalGrossAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'EditTotalGrossAmount');
      if (settingValue != null) {
        this.DETAIL_SALE_editDetailTotalGrossAmount = settingValue;
      } else {
        this.DETAIL_SALE_editDetailTotalGrossAmount = defaultValue;
      }
    }
    return this.DETAIL_SALE_editDetailTotalGrossAmount;
  }

  get_DETAIL_SALE_showDetailGrossUnitPrice(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailGrossUnitPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowGrossUnitPrice');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailGrossUnitPrice = settingValue;
      } else {
        this.DETAIL_SALE_showDetailGrossUnitPrice = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailGrossUnitPrice;
  }

  get_DETAIL_SALE_showDetailTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailTotalGrossAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailTotalGrossAmount = settingValue;
      } else {
        this.DETAIL_SALE_showDetailTotalGrossAmount = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailTotalGrossAmount;
  }

  get_DETAIL_SALE_showDetailTotalTransportAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailTotalTransportAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowTotalTransportAmount');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailTotalTransportAmount = settingValue;
      } else {
        this.DETAIL_SALE_showDetailTotalTransportAmount = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailTotalTransportAmount;
  }

  get_DETAIL_SALE_showDetailBuyersCostCenter(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailBuyersCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowBuyersCostCenter');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailBuyersCostCenter = settingValue;
      } else {
        this.DETAIL_SALE_showDetailBuyersCostCenter = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailBuyersCostCenter;
  }



  get_DETAIL_SALE_InvoiceDueDateRequired(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_InvoiceDueDateRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'InvoiceDueDateRequired');
      if (settingValue != null) {
        this.DETAIL_SALE_InvoiceDueDateRequired = settingValue;
      } else {
        this.DETAIL_SALE_InvoiceDueDateRequired = defaultValue;
      }
    }
    return this.DETAIL_SALE_InvoiceDueDateRequired;
  }

  get_DETAIL_SALE_TaxNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_TaxNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'TaxNumberRequired');
      if (settingValue != null) {
        this.DETAIL_SALE_TaxNumberRequired = settingValue;
      } else {
        this.DETAIL_SALE_TaxNumberRequired = defaultValue;
      }
    }
    return this.DETAIL_SALE_TaxNumberRequired;
  }

  get_DETAIL_SALE_ValidateQuantityMaxAllowed(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_ValidateQuantityMaxAllowed === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ValidateQuantityMaxAllowed');
      if (settingValue != null) {
        this.DETAIL_SALE_ValidateQuantityMaxAllowed = settingValue;
      } else {
        this.DETAIL_SALE_ValidateQuantityMaxAllowed = defaultValue;
      }
    }
    return this.DETAIL_SALE_ValidateQuantityMaxAllowed;
  }

  get_DETAIL_SALE_isRequiredQuantity(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_isRequiredQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'isRequiredQuantity');
      if (settingValue != null) {
        this.DETAIL_SALE_isRequiredQuantity = settingValue;
      } else {
        this.DETAIL_SALE_isRequiredQuantity = defaultValue;
      }
    }
    return this.DETAIL_SALE_isRequiredQuantity;
  }

  get_DETAIL_SALEallowEditDocumentNumber(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_allowEditDocumentNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'AllowEditDocumentNumber');
      if (settingValue != null) {
        this.DETAIL_SALE_allowEditDocumentNumber = settingValue;
      } else {
        this.DETAIL_SALE_allowEditDocumentNumber = defaultValue;
      }
    }
    return this.DETAIL_SALE_allowEditDocumentNumber;
  }

  get_DETAIL_SALE_allowEditInvoiceDate(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_allowEditInvoiceDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'AllowEditInvoiceDate');
      if (settingValue != null) {
        this.DETAIL_SALE_allowEditInvoiceDate = settingValue;
      } else {
        this.DETAIL_SALE_allowEditInvoiceDate = defaultValue;
      }
    }
    return this.DETAIL_SALE_allowEditInvoiceDate;
  }

  get_DETAIL_SALEnotCalculateQuantityWithOrderInvoiceToQuantity(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_notCalculateQuantityWithOrderInvoiceToQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'NotCalculateQuantityWithOrderInvoiceToQuantity');
      if (settingValue != null) {
        this.DETAIL_SALE_notCalculateQuantityWithOrderInvoiceToQuantity = settingValue;
      } else {
        this.DETAIL_SALE_notCalculateQuantityWithOrderInvoiceToQuantity = defaultValue;
      }
    }
    return this.DETAIL_SALE_notCalculateQuantityWithOrderInvoiceToQuantity;
  }

  get_DETAIL_SALE_useAccountingConfig(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_useAccountingConfig === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'UseAccountingConfig');
      if (settingValue != null) {
        this.DETAIL_SALE_useAccountingConfig = settingValue;
      } else {
        this.DETAIL_SALE_useAccountingConfig = defaultValue;
      }
    }
    return this.DETAIL_SALE_useAccountingConfig;
  }

  get_DETAIL_SALE_showDetailsNewProductButton(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailsNewProductButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowDetailsNewProductButton');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailsNewProductButton = settingValue;
      } else {
        this.DETAIL_SALE_showDetailsNewProductButton = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailsNewProductButton;
  }

  get_DETAIL_SALE_showDetailsProductsListButton(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDetailsProductsListButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowDetailsProductsListButton');
      if (settingValue != null) {
        this.DETAIL_SALE_showDetailsProductsListButton = settingValue;
      } else {
        this.DETAIL_SALE_showDetailsProductsListButton = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDetailsProductsListButton;
  }

  get_DETAIL_SALE_showDescriptionAsTextArea(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showDescriptionAsTextArea === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowDescriptionAsTextArea');
      if (settingValue != null) {
        this.DETAIL_SALE_showDescriptionAsTextArea = settingValue;
      } else {
        this.DETAIL_SALE_showDescriptionAsTextArea = defaultValue;
      }
    }
    return this.DETAIL_SALE_showDescriptionAsTextArea;
  }

  get_DETAIL_SALE_showBuyerContractNumber(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showBuyerContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowBuyerContractNumber');
      if (settingValue !== null) {
        this.DETAIL_SALE_showBuyerContractNumber = settingValue;
      } else {
        this.DETAIL_SALE_showBuyerContractNumber = defaultValue;
      }
    }
    return this.DETAIL_SALE_showBuyerContractNumber;
  }

  get_DETAIL_SALE_disableUOMCodes(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_disableUOMCodes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'DisableUOMCodes');
      if (settingValue !== null) {
        this.DETAIL_SALE_disableUOMCodes = settingValue;
      } else {
        this.DETAIL_SALE_disableUOMCodes = defaultValue;
      }
    }
    return this.DETAIL_SALE_disableUOMCodes;
  }

  get_DETAIL_SALE_validateTotalNetAmountInDetails(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_validateTotalNetAmountInDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ValidateTotalNetAmountInDetails');
      if (settingValue !== null) {
        this.DETAIL_SALE_validateTotalNetAmountInDetails = settingValue;
      } else {
        this.DETAIL_SALE_validateTotalNetAmountInDetails = defaultValue;
      }
    }
    return this.DETAIL_SALE_validateTotalNetAmountInDetails;
  }

  get_DETAIL_SALE_showOrderLineNumberOnProductPanel(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showOrderLineNumberOnProductPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowOrderLineNumberOnProductPanel');
      if (settingValue !== null) {
        this.DETAIL_SALE_showOrderLineNumberOnProductPanel = settingValue;
      } else {
        this.DETAIL_SALE_showOrderLineNumberOnProductPanel = defaultValue;
      }
    }
    return this.DETAIL_SALE_showOrderLineNumberOnProductPanel;
  }

  getSALE_submitMessageTranslateCode(defaultValue: string): string {
    if (typeof this.SALE_submitMessageTranslateCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'SubmitMessageTranslateCode');
      if (settingValue != null) {
        this.SALE_submitMessageTranslateCode = settingValue;
      } else {
        this.SALE_submitMessageTranslateCode = defaultValue;
      }
    }
    return this.SALE_submitMessageTranslateCode;
  }
  getSALE_showOrderListColumns(): any {
    if (typeof this.SALE_showOrderListColumns === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ShowOrderListColumns');
      if (settingValue != null) {
        this.SALE_showOrderListColumns = settingValue;
      } else {
        this.SALE_showOrderListColumns = null;
      }
    }
    return this.SALE_showOrderListColumns;
  }

  get_DETAIL_SALE_hideEANCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_hideEANCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HideEANCode');
      if (settingValue !== null) {
        this.DETAIL_SALE_hideEANCode = settingValue;
      } else {
        this.DETAIL_SALE_hideEANCode = defaultValue;
      }
    }
    return this.DETAIL_SALE_hideEANCode;
  }

  get_DETAIL_SALE_hideBuyerProductCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_hideBuyerProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HideBuyerProductCode');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HideBuyerProductCodeSP');

      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.DETAIL_SALE_hideBuyerProductCode = settingValue;
      } else if (settingValueSP) {
        this.DETAIL_SALE_hideBuyerProductCode = settingValueSP;
      } else {
        this.DETAIL_SALE_hideBuyerProductCode = defaultValue;
      }
    }
    return this.DETAIL_SALE_hideBuyerProductCode;
  }
  get_DETAIL_SALE_hideSupplierProductCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_hideSupplierProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HideSupplierProductCode');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HideSupplierProductCode');

      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.DETAIL_SALE_hideSupplierProductCode = settingValue;
      } else if (settingValueSP) {
        this.DETAIL_SALE_hideSupplierProductCode = settingValueSP;
      } else {
        this.DETAIL_SALE_hideSupplierProductCode = defaultValue;
      }
    }
    return this.DETAIL_SALE_hideSupplierProductCode;
  }

  get_DETAIL_SALE_hidePanelObservations(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_hidePanelObservations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HidePanelObservations');
      if (settingValue !== null) {
        this.DETAIL_SALE_hidePanelObservations = settingValue;
      } else {
        this.DETAIL_SALE_hidePanelObservations = defaultValue;
      }
    }
    return this.DETAIL_SALE_hidePanelObservations;
  }

  get_DETAIL_SALE_quantityUOMCodeDisabled(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_quantityUOMCodeDisabled === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'QuantityUOMCodeDisabled');
      if (settingValue !== null) {
        this.DETAIL_SALE_quantityUOMCodeDisabled = settingValue;
      } else {
        this.DETAIL_SALE_quantityUOMCodeDisabled = defaultValue;
      }
    }
    return this.DETAIL_SALE_quantityUOMCodeDisabled;
  }

  get_DETAIL_SALE_totalNetPriceDisabled(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_totalNetPriceDisabled === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'TotalNetPriceDisabled');
      if (settingValue !== null) {
        this.DETAIL_SALE_totalNetPriceDisabled = settingValue;
      } else {
        this.DETAIL_SALE_totalNetPriceDisabled = defaultValue;
      }
    }
    return this.DETAIL_SALE_totalNetPriceDisabled;
  }

  get_DETAIL_SALE_descriptionDisabled(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_descriptionDisabled === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'DescriptionDisabled');
      if (settingValue !== null) {
        this.DETAIL_SALE_descriptionDisabled = settingValue;
      } else {
        this.DETAIL_SALE_descriptionDisabled = defaultValue;
      }
    }
    return this.DETAIL_SALE_descriptionDisabled;
  }

  get_DETAIL_SALE_useHalfColumn(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_useHalfColumn === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'UseHalfColumn');
      if (settingValue !== null) {
        this.DETAIL_SALE_useHalfColumn = settingValue;
      } else {
        this.DETAIL_SALE_useHalfColumn = defaultValue;
      }
    }
    return this.DETAIL_SALE_useHalfColumn;
  }

  //#endregion  ---------------------- ***  VARIAVEIS DETAILS SALE  *** ---------------------

  //#region ---------------------- ***  VARIAVEIS PURCHASES  *** ---------------------
  /* tslint:disable:member-ordering */
  PURCHASES_columnTotalLiq_Label: string;
  PURCHASES_columnInVisible: Array<number>;
  PURCHASES_columnVisibleNames: Array<string>;

  PURCHASES_columnInVisibleForAllCompanies: Array<number>;
  PURCHASES_fillEndDate: boolean;
  PURCHASES_columnVisible: Array<number>;
  PURCHASES_columnVisibleForAllCompanies: Array<number>;
  PURCHASES_allowedInvoiceTypes: Array<string>; //Setting que filtra os Tipos de Fatura permitidos na Filtragem;

  PURCHASE_ShowPanelInvoiceTo: boolean;
  PURCHASE_ShowPanelPaymentConditions: boolean;
  PURCHASE_ShowPanelTaxes: boolean;
  PURCHASE_ShowPanelEDI: boolean;
  PURCHASE_ShowDistributionButton: boolean;
  PURCHASE_ShowBuyerIntegrationID: boolean;
  PURCHASE_EnableSavingWhenChangingForm: boolean;
  
  EXPENSES_columnTotalLiq_Label: string;
  EXPENSES_columnInVisible: Array<number>;
  EXPENSES_columnVisible: Array<number>;
  EXPENSES_fillEndDate: boolean;
  EXPENSES_showCreationDate: boolean;
  EXPENSES_showColumnID: boolean;
  
  
  SALES_allowedInvoiceTypes: Array<string>; //Setting que filtra os Tipos de Fatura permitidos na Filtragem;


  PURCHASE_showSupplierTelephone: boolean;
  PURCHASE_showSupplierFax: boolean;
  PURCHASE_showSupplierElectronicMail: boolean;
  PURCHASE_showSupplierDepartmentCode: boolean;
  PURCHASE_showSupplierDepartmentName: boolean;
  PURCHASE_showSupplierDivisionCode: boolean;
  PURCHASE_showDeliveryToTelephone: boolean;
  PURCHASE_showDeliveryToFax: boolean;
  PURCHASE_showDeliveryToElectronicMail: boolean;
  PURCHASE_showDeliveryLoadingLocation: boolean;
  PURCHASE_showDeliveryLoadingDate: boolean;
  PURCHASE_showDeliveryLocation: boolean;
  PURCHASE_showDeliveryTransportID: boolean;
  PURCHASE_showDeliveryConditionsValue: boolean;
  PURCHASE_showDeliveryFreeText: boolean;
  PURCHASE_showTotalChargeAmount: boolean;
  PURCHASE_showTotalDiscountAmount: boolean;
  PURCHASE_showTotalPackingCostAmount: boolean;
  PURCHASE_showTotalPrePaymentAmount: boolean;
  PURCHASE_showInvoiceTelephone: boolean;
  PURCHASE_showInvoiceFax: boolean;
  PURCHASE_showInvoiceElectronicMail: boolean;
  PURCHASE_showPaymentConditionsInvoiceDueDate: boolean;
  PURCHASE_showPaymentConditionsServicesTermDate: boolean;
  PURCHASE_showPaymentConditionsList: boolean;
  PURCHASE_showPaymentMethod: boolean;
  PURCHASE_showPaid: boolean;
  PURCHASE_showValueInEuros: boolean;
  PURCHASE_UsersRolesToValidateInvoice: any[];
  PURCHASE_InvoiceStatesToValidate: any[];
  PURCHASE_InvoiceStatesToEditOrderNumber: any[];
  PURCHASE_invoiceRepositoryDiskFileContext: string;
  PURCHASE_usersRolesToAccountInvoice: any[];
  PURCHASE_invoiceStatesToAccount: any[];
  PURCHASE_showRetentionAccountingButton: boolean;
  PURCHASE_showRetentionCode: boolean;
  PURCHASE_getAccountingConfig: boolean;
  PURCHASE_showAuditCode: boolean;
  PURCHASE_isMultipleOrder: boolean;
  PURCHASE_showBuyerContractNumber: boolean;
  PURCHASE_showHeaderCreationDate: boolean;
  PURCHASE_showIdOnEditDetail: boolean;
  PURCHASE_showAttachesIsPublic: boolean;
  PURCHASE_showInvoiceToStreet: boolean;
  PURCHASE_showInvoiceToPostalCode: boolean;
  PURCHASE_showInvoiceToCity: boolean;
  PURCHASE_showInvoiceToCountryCode: boolean;
  PURCHASE_validateFreeTextMaxLength: boolean;
  PURCHASE_showCentralPayment: boolean;
  PURCHASE_showPaymentBlocked: boolean;
  PURCHASE_warnUserWhenUnsavedChanges: boolean;
  PURCHASE_redirectToOrderForm: boolean; //Apresenta o Botão que permite redirecionar para o Form da Encomenda associada à Fatura
  PURCHASE_showSectionApprovalCode: boolean; //Permite observar/alterar o ExtendedField 'ApprovalSection'
  PURCHASE_showPaymentPriority: boolean; //Permite observar/alterar o ExtendedField 'PaymentPriority'
  PURCHASE_showTotalAmountToPay: boolean; //Permite observar/alterar o valor a pagar na Fatura
  //JJ 14/09/2022 -> Esconde o Botão de Exportação Excel
  private hideExcelExportButton_PURCHASE: boolean;
  private hideColumsTax_PURCHASE: boolean;
  //JJ 05/09/2022 -> Define a Formatação p/ Default dos Date Pickers
  PURCHASE_defaultDateTimeFormat: number;

  PURCHASE_ShowDigitalFileTab: boolean;
  PURCHASE_ShowMessagesTab: boolean;
  PURCHASE_invoiceSubTypeShow: boolean;
  PURCHASE_suppliersDeliveryNoteNumberEditable: boolean;
  PURCHASE_buyersOrderNumberEditable: boolean;
  PURCHASE_originalInvoiceNumberEditable: boolean;
  PURCHASE_AllowDeliveryNotesSelection: boolean;
  PURCHASE_AllowOrdersSelection: boolean;
  PURCHASE_AllowInvoicesSelection: boolean;
  PURCHASE_suppliersDeliveryNoteNumberRequired: boolean;
  PURCHASE_buyersOrderNumberRequired: boolean;
  PURCHASE_originalInvoiceNumberRequired: boolean;
  PURCHASE_columnInVisible_Details: Array<number>;
  PURCHASE_columnInVisible_Details_SP: Array<number>;
  PURCHASE_Details_All: boolean;
  PURCHASE_AllowCreateEmptyDetail: boolean;
  PURCHASE_ShowPanelDiscounts: boolean;
  PURCHASE_ShowPanelAllowances: boolean;
  PURCHASE_PossibleTypes: Array<string>;
  PURCHASE_SupplierFieldsEditable: boolean;
  PURCHASE_DeliveryToFieldsEditable: boolean;
  PURCHASE_InvoiceToFieldsEditable: boolean;
  PURCHASE_msgCreate: boolean;
  PURCHASE_msgDelete: boolean;
  PURCHASE_digArcCreate: boolean;
  PURCHASE_digArcDelete: boolean;
  PURCHASE_digArcLimitNumberOfFiles: boolean;
  PURCHASE_allowsRepeatedProducts: boolean;
  PURCHASE_showButtonSelectProducts: boolean;
  PURCHASE_showCurrencyExchange: boolean;
  PURCHASE_showOthersReferencesTypes: boolean;
  PURCHASE_ShowOptionsReprocessBtn: boolean;
  PURCHASE_UseOrderAndDeliveryNoteREFs: boolean;
  PURCHASE_ShowBuyersCostCentre: boolean;
  PURCHASE_ShowSuppliersDeliveryNoteNumber: boolean;
  PURCHASE_ShowCertificateNumber: boolean;
  PURCHASE_ShowTotalGrossAmount: boolean;
  PURCHASE_showTotalTransportAmount: boolean;
  PURCHASE_showTotalRetencaoIrsPercentage: boolean;
  PURCHASE_ShowTotalRetencaoIrsAmount: boolean;
  PURCHASE_ShowTotalEnvironmentalTaxAmount: boolean;
  PURCHASE_ShowTotalNetWeight: boolean;
  PURCHASE_ShowTotalNetWeightUOMCode: boolean;
  PURCHASE_ShowTotalGrossWeight: boolean;
  PURCHASE_ShowTotalGrossWeightUOMCode: boolean;
  PURCHASE_ShowTotalNetVolume: boolean;
  PURCHASE_ShowTotalNetVolumeUOMCode: boolean;
  PURCHASE_useAccountingConfig: boolean;
  PURCHASE_showCreationDate: boolean;
  PURCHASE_showColumnID: boolean;
  PURCHASE_filterByHistory: boolean;
  PURCHASE_hideAssignActionsToAnotherUser: boolean;

  PURCHASE_hideOtherReferences: boolean;
  PURCHASE_hidePanelSupplier: boolean;
  PURCHASE_hidePanelDelivery: boolean;
  PURCHASE_hidePanelObservations: boolean;
  PURCHASE_showPanelReferencesFirst: boolean;

  PURCHASE_afterSaveRemainOnSamePage: boolean;
  PURCHASE_redirectOnUpdate: boolean;

  PURCHASE_allowedFileTypes = new Array<string>();

  //JJ 05/09/2022 -> Define a Formatação p/ Default dos Date Pickers
  PURCHASE_defaultDateTimeFormat_SALE: boolean;
  private PURCHASES_setBeginingOfTheYearAsStartDate: boolean; //Setting que coloca a Data Inicial do Header Filter como o 1º dia do ano
  private PURCHASES_setTodayAsEndDate: boolean; //Setting que coloca a Data Atual como a Data Final
  private PURCHASES_hideExportDropdownMenu: boolean; //Setting para esconder o Menu de Exportações
  private PURCHASES_hideExportExcelButton: boolean; //Setting para esconder o Botão de Exportação Excel
  private PURCHASES_hideFilterModalButton: boolean; //Setting para esconder o Botão que abre a Modal OrderFilter
  private PURCHASES_showFilterHasDetailActions: boolean;
  private columnReferenceName: string;


  DETAIL_PURCHASE_ShowPanelDiscounts: boolean;
  DETAIL_PURCHASE_ShowPanelAllowances: boolean;
  DETAIL_PURCHASE_ShowPanelReferences: boolean;
  DETAIL_PURCHASE_showBacthNumber: boolean;
  DETAIL_PURCHASE_showDeliveryDate: boolean;
  DETAIL_PURCHASE_showBacthExpiryDate: boolean;
  DETAIL_PURCHASE_BuyerProductCodeEditable: boolean;
  DETAIL_PURCHASE_showDetailBonusQuantity: boolean;
  DETAIL_PURCHASE_showDetailBonusQuantityUOMCode: boolean;
  DETAIL_PURCHASE_showDetailPackQuantity: boolean;
  DETAIL_PURCHASE_showDetailPackQuantityUOMCode: boolean;
  DETAIL_PURCHASE_showOrderLineNumberOnProductPanel: boolean;
  DETAIL_PURCHASE_showDetailPackSize: boolean;
  DETAIL_PURCHASE_editDetailTotalNetAmount: boolean;
  DETAIL_PURCHASE_editDetailGrossUnitPrice: boolean;
  DETAIL_PURCHASE_editDetailTotalGrossAmount: boolean;
  DETAIL_PURCHASE_showDetailGrossUnitPrice: boolean;
  DETAIL_PURCHASE_showDescriptionAsTextArea: boolean;
  DETAIL_PURCHASE_showDetailTotalGrossAmount: boolean;
  DETAIL_PURCHASE_showDetailTotalTransportAmount: boolean;
  DETAIL_PURCHASE_showDetailBuyersCostCenter: boolean;
  DETAIL_PURCHASE_showDetailDistributionButton: boolean;
  DETAIL_PURCHASE_useAccountingConfig: boolean;
  DETAIL_PURCHASE_getProductServiceType: boolean;
  DETAIL_PURCHASE_showDatesPanel: boolean;
  DETAIL_PURCHASE_distributionButtonLabel: string;
  DETAIL_PURCHASE_showLoadAccountingButton: boolean;
  DETAIL_PURCHASE_showApprovedProposalNumber: boolean;
  DETAIL_PURCHASE_maxSupplierProductCodeLength: number;
  DETAIL_PURCHASE_showBuyerContractNumber: boolean;
  DETAIL_PURCHASE_EnableEditOnInvalidState: boolean;
  DETAIL_PURCHASE_useProductCostCenter: boolean;
  DETAIL_PURCHASE_useProductReclassificationCode: boolean;
  DETAIL_PURCHASE_hideEANCode: boolean;
  DETAIL_PURCHASE_hideBuyerProductCode: boolean;
  DETAIL_PURCHASE_hideSupplierProductCode: boolean;
  DETAIL_PURCHASE_hidePanelObservations: boolean;
  DETAIL_SALE_disableSupplierProductCode: boolean;
  DETAIL_PURCHASE_useHalfColumn: boolean;
  PURCHASE_ShowATCUD: boolean;
  DETAIL_PURCHASE_showPriceUnit: boolean;
  DETAIL_SALE_showPriceUnit: boolean;
  DETAIL_SALE_disableTaxPanel: boolean;
  PURCHASE_hideGuideNote: boolean;
  PURCHASE_showPaymentCondition: boolean;
  PURCHASE_showMeansOfPayment: boolean;
  PURCHASE_hideAttachmentType: boolean;
  PURCHASE_hideAttachmentDescription: boolean;
  PURCHASE_showUserGroups: boolean;
  PURCHASE_FilterBySpecificUserGroup: string;
  PURCHASE_showCostCenter: boolean;
  showApprovTab: boolean;
  DETAIL_PURCHASE_showOrderProcess: boolean;
  DETAIL_PURCHASE_showOrderFraction: boolean;
  DETAIL_SALE_showOrderProcess: boolean;
  DETAIL_SALE_showOrderFraction: boolean;
  DETAIL_SALE_hideDetailOtherReferences: boolean;
  DETAIL_PURCHASE_hideDetailOtherReferences: boolean;
  DETAIL_PURCHASE_showCostCenter: boolean;
  blockInvoiceStates: any[];
  detailStatesBlock: number[];
  validateSupplierCapitalAndRegistration: boolean;

  /* tslint:enable:member-ordering */

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {string} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getPURCHASES_columnTotalLiq_Label(defaultValue: string): string {
    if (typeof this.PURCHASES_columnTotalLiq_Label === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'Label_TotalLiq');
      if (settingValue != null) {
        this.PURCHASES_columnTotalLiq_Label = settingValue;
      } else {
        this.PURCHASES_columnTotalLiq_Label = defaultValue;
      }
    }
    return this.PURCHASES_columnTotalLiq_Label;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  getPURCHASES_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASES_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ColumVisible');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ColumVisibleSP');
      this.PURCHASES_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this._authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);

      this.PURCHASES_columnVisibleNames = settingValue.split(",");
      }
    return this.PURCHASES_columnInVisible;
  }

  getPURCHASES_columnVisibleForAllCompanies(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASES_columnVisibleForAllCompanies === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchasesAllCompanies', 'ColumVisible');
      this.PURCHASES_columnVisibleForAllCompanies = this._dataTableF.getVisibleColumns(defaultValue, columnsNames, settingValue);
    }
    return this.PURCHASES_columnVisibleForAllCompanies;
  }

  IsColumnVisible(value: string): boolean {
    return this.PURCHASES_columnVisibleNames.some(x => x.trim() == value);
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  getPURCHASES_ColumnInVisibleForAllCompanies(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASES_columnInVisibleForAllCompanies === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchasesAllCompanies', 'ColumVisible');
      this.PURCHASES_columnInVisibleForAllCompanies = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.PURCHASES_columnInVisibleForAllCompanies;
  }

  getPURCHASES_columnVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASES_columnVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ColumVisible');
      this.PURCHASES_columnVisible = this._dataTableF.getVisibleColumns(defaultValue, columnsNames, settingValue);
    }
    return this.PURCHASES_columnVisible;
  }
  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getPURCHASES_fillEndDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'FillEndDate');
      if (settingValue != null) {
        this.PURCHASES_fillEndDate = settingValue;
      } else {
        this.PURCHASES_fillEndDate = defaultValue;
      }
    }
    return this.PURCHASES_fillEndDate;
  }
  
  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
   getPURCHASES_allowedInvoicetypes(defaultValue: Array<string>): Array<string> {
    if (typeof this.PURCHASES_allowedInvoiceTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'AllowedInvoiceTypes');
      if (settingValue != null) {
        this.PURCHASES_allowedInvoiceTypes = settingValue;
      } else {
        this.PURCHASES_allowedInvoiceTypes = defaultValue;
      }
    }
    return this.PURCHASES_allowedInvoiceTypes;
  }

  getPURCHASE_ShowPanelInvoiceTo(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowPanelInvoiceTo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelInvoiceTo');
      if (settingValue != null) {
        this.PURCHASE_ShowPanelInvoiceTo = settingValue;
      } else {
        this.PURCHASE_ShowPanelInvoiceTo = defaultValue;
      }
    }
    return this.PURCHASE_ShowPanelInvoiceTo;
  }
  getPURCHASE_ShowPanelPaymentConditions(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowPanelPaymentConditions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelPaymentConditions');
      if (settingValue != null) {
        this.PURCHASE_ShowPanelPaymentConditions = settingValue;
      } else {
        this.PURCHASE_ShowPanelPaymentConditions = defaultValue;
      }
    }
    return this.PURCHASE_ShowPanelPaymentConditions;
  }
  getPURCHASE_ShowPanelTaxes(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowPanelTaxes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelTaxes');
      if (settingValue != null) {
        this.PURCHASE_ShowPanelTaxes = settingValue;
      } else {
        this.PURCHASE_ShowPanelTaxes = defaultValue;
      }
    }
    return this.PURCHASE_ShowPanelTaxes;
  }
  getPURCHASE_ShowPanelEDI(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowPanelEDI === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelEDINonSP');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelEDI');
      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.PURCHASE_ShowPanelEDI = settingValue;
      } else if (settingValueSP) {
        this.PURCHASE_ShowPanelEDI = settingValueSP;
      } else {
        this.PURCHASE_ShowPanelEDI = defaultValue;
      }
    }
    return this.PURCHASE_ShowPanelEDI;
  }

  get_showPanelInvoiceWorkFlow(defaultValue: boolean): boolean {
    if (typeof this.showPanelInvoiceWorkFlow === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelInvoiceWorkFlow');
      if (settingValue != null) {
        this.showPanelInvoiceWorkFlow = settingValue;
      } else {
        this.showPanelInvoiceWorkFlow = defaultValue;
      }
    }
    return this.showPanelInvoiceWorkFlow;
  }

  getPURCHASE_ShowDistributionButton(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowDistributionButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDistributionButton');
      if (settingValue != null) {
        this.PURCHASE_ShowDistributionButton = settingValue;
      } else {
        this.PURCHASE_ShowDistributionButton = defaultValue;
      }
    }
    return this.PURCHASE_ShowDistributionButton;
  }
  getPURCHASE_ShowBuyerIntegrationID(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowBuyerIntegrationID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowBuyerIntegrationID');
      if (settingValue != null) {
        this.PURCHASE_ShowBuyerIntegrationID = settingValue;
      } else {
        this.PURCHASE_ShowBuyerIntegrationID = defaultValue;
      }
    }
    return this.PURCHASE_ShowBuyerIntegrationID;
  }

  getPURCHASE_EnableSavingWhenChangingForm(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_EnableSavingWhenChangingForm === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'EnableSavingWhenChangingForm');
      if (settingValue != null) {
        this.PURCHASE_EnableSavingWhenChangingForm = settingValue;
      } else {
        this.PURCHASE_EnableSavingWhenChangingForm = defaultValue;
      }
    }
    return this.PURCHASE_EnableSavingWhenChangingForm;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_ShowDigitalFileTab(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowDigitalFileTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDigitalFileTab');
      if (settingValue != null) {
        this.PURCHASE_ShowDigitalFileTab = settingValue;
      } else {
        this.PURCHASE_ShowDigitalFileTab = defaultValue;
      }
    }
    return this.PURCHASE_ShowDigitalFileTab;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_ShowMessagesTab(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowMessagesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowMessagesTab');
      if (settingValue != null) {
        this.PURCHASE_ShowMessagesTab = settingValue;
      } else {
        this.PURCHASE_ShowMessagesTab = defaultValue;
      }
    }
    return this.PURCHASE_ShowMessagesTab;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_InvoiceSubTypeShow(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_invoiceSubTypeShow === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceSubType');
      if (settingValue != null) {
        this.PURCHASE_invoiceSubTypeShow = settingValue;
      } else {
        this.PURCHASE_invoiceSubTypeShow = defaultValue;
      }
    }
    return this.PURCHASE_invoiceSubTypeShow;
  }
  /**
* Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
* @param  {boolean} defaultValue - Valor por defeito do parametro
* @returns boolean
*/
  getPURCHASE_SuppliersDeliveryNoteNumberEditable(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_suppliersDeliveryNoteNumberEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'SuppliersDeliveryNoteNumberEditable');
      if (settingValue != null) {
        this.PURCHASE_suppliersDeliveryNoteNumberEditable = settingValue;
      } else {
        this.PURCHASE_suppliersDeliveryNoteNumberEditable = defaultValue;
      }
    }
    return this.PURCHASE_suppliersDeliveryNoteNumberEditable;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_BuyersOrderNumberEditable(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_buyersOrderNumberEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'BuyersOrderNumberEditable');
      if (settingValue != null) {
        this.PURCHASE_buyersOrderNumberEditable = settingValue;
      } else {
        this.PURCHASE_buyersOrderNumberEditable = defaultValue;
      }
    }
    return this.PURCHASE_buyersOrderNumberEditable;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_OriginalInvoiceNumberEditable(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_originalInvoiceNumberEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'OriginalInvoiceNumberEditable');
      if (settingValue != null) {
        this.PURCHASE_originalInvoiceNumberEditable = settingValue;
      } else {
        this.PURCHASE_originalInvoiceNumberEditable = defaultValue;
      }
    }
    return this.PURCHASE_originalInvoiceNumberEditable;
  }
  getPURCHASE_AllowDeliveryNotesSelection(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_AllowDeliveryNotesSelection === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllowDeliveryNotesSelection');
      if (settingValue != null) {
        this.PURCHASE_AllowDeliveryNotesSelection = settingValue;
      } else {
        this.PURCHASE_AllowDeliveryNotesSelection = defaultValue;
      }
    }
    return this.PURCHASE_AllowDeliveryNotesSelection;
  }
  getPURCHASE_AllowOrdersSelection(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_AllowOrdersSelection === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllowOrdersSelection');
      if (settingValue != null) {
        this.PURCHASE_AllowOrdersSelection = settingValue;
      } else {
        this.PURCHASE_AllowOrdersSelection = defaultValue;
      }
    }
    return this.PURCHASE_AllowOrdersSelection;
  }
  getPURCHASE_AllowInvoicesSelection(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_AllowInvoicesSelection === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllowInvoicesSelection');
      if (settingValue != null) {
        this.PURCHASE_AllowInvoicesSelection = settingValue;
      } else {
        this.PURCHASE_AllowInvoicesSelection = defaultValue;
      }
    }
    return this.PURCHASE_AllowInvoicesSelection;
  }
  getPURCHASE_SuppliersDeliveryNoteNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_suppliersDeliveryNoteNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'SuppliersDeliveryNoteNumberRequired');
      if (settingValue != null) {
        this.PURCHASE_suppliersDeliveryNoteNumberRequired = settingValue;
      } else {
        this.PURCHASE_suppliersDeliveryNoteNumberRequired = defaultValue;
      }
    }
    return this.PURCHASE_suppliersDeliveryNoteNumberRequired;
  }
  getPURCHASE_BuyersOrderNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_buyersOrderNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'BuyersOrderNumberRequired');
      if (settingValue != null) {
        this.PURCHASE_buyersOrderNumberRequired = settingValue;
      } else {
        this.PURCHASE_buyersOrderNumberRequired = defaultValue;
      }
    }
    return this.PURCHASE_suppliersDeliveryNoteNumberRequired;
  }
  getPURCHASE_OriginalInvoiceNumberRequired(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_originalInvoiceNumberRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'OriginalInvoiceNumberRequired');
      if (settingValue != null) {
        this.PURCHASE_originalInvoiceNumberRequired = settingValue;
      } else {
        this.PURCHASE_originalInvoiceNumberRequired = defaultValue;
      }
    }
    return this.PURCHASE_originalInvoiceNumberRequired;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {Array<number>} defaultValue -Valor por defeito do parametro
 * @param  {Array<string>} columnsNames - nome das colunas
 * @returns Array
 */
  getPURCHASE_ColumnInVisible_Details(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASE_columnInVisible_Details === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ColumVisible_Details');
      this.PURCHASE_columnInVisible_Details = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.PURCHASE_columnInVisible_Details;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {Array<number>} defaultValue - Valor por defeito do parametro
 * @param  {Array<string>} columnsNames - nome das colunas
 * @returns Array
 */
  getPURCHASE_ColumnInVisible_Details_SP(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASE_columnInVisible_Details_SP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ColumVisible_Details_SP');
      this.PURCHASE_columnInVisible_Details_SP = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.PURCHASE_columnInVisible_Details_SP;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolea
 */
  getPURCHASE_Details_All(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_Details_All === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllDetailsEditable');
      if (settingValue != null) {
        this.PURCHASE_Details_All = settingValue;
      } else {
        this.PURCHASE_Details_All = defaultValue;
      }
    }
    return this.PURCHASE_Details_All;
  }
  /**
* Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
* @param  {boolean} defaultValue - Valor por defeito do parametro
* @returns boolean
*/
  getPURCHASE_AllowCreateEmptyDetail(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_AllowCreateEmptyDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllowCreateEmptyDetail');
      if (settingValue != null) {
        this.PURCHASE_AllowCreateEmptyDetail = settingValue;
      } else {
        this.PURCHASE_AllowCreateEmptyDetail = defaultValue;
      }
    }
    return this.PURCHASE_AllowCreateEmptyDetail;
  }
  getPURCHASE_ShowPanelDiscounts(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowPanelDiscounts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelDiscounts');
      if (settingValue != null) {
        this.PURCHASE_ShowPanelDiscounts = settingValue;
      } else {
        this.PURCHASE_ShowPanelDiscounts = defaultValue;
      }
    }
    return this.PURCHASE_ShowPanelDiscounts;
  }
  getPURCHASE_ShowPanelAllowances(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowPanelAllowances === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelAllowances');
      if (settingValue != null) {
        this.PURCHASE_ShowPanelAllowances = settingValue;
      } else {
        this.PURCHASE_ShowPanelAllowances = defaultValue;
      }
    }
    return this.PURCHASE_ShowPanelAllowances;
  }
  getPURCHASE_PossibleTypes(defaultValue: Array<string>): Array<string> {
    if (typeof this.PURCHASE_PossibleTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'PossibleTypes');
      if (settingValue != null) {
        this.PURCHASE_PossibleTypes = settingValue;
      } else {
        this.PURCHASE_PossibleTypes = defaultValue;
      }
    }
    return this.PURCHASE_PossibleTypes;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_SupplierFieldsEditable(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_SupplierFieldsEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'SupplierFieldsEditable');
      if (settingValue != null) {
        this.PURCHASE_SupplierFieldsEditable = settingValue;
      } else {
        this.PURCHASE_SupplierFieldsEditable = defaultValue;
      }
    }
    return this.PURCHASE_SupplierFieldsEditable;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_DeliveryToFieldsEditable(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_DeliveryToFieldsEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'DeliveryToFieldsEditable');
      if (settingValue != null) {
        this.PURCHASE_DeliveryToFieldsEditable = settingValue;
      } else {
        this.PURCHASE_DeliveryToFieldsEditable = defaultValue;
      }
    }
    return this.PURCHASE_DeliveryToFieldsEditable;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_InvoiceToFieldsEditable(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_InvoiceToFieldsEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'InvoiceToFieldsEditable');
      if (settingValue != null) {
        this.PURCHASE_InvoiceToFieldsEditable = settingValue;
      } else {
        this.PURCHASE_InvoiceToFieldsEditable = defaultValue;
      }
    }
    return this.PURCHASE_InvoiceToFieldsEditable;
  }
  /**
* Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
* @param  {boolean} defaultValue -Valor por defeito do parametro
* @returns boolean
*/
  getPURCHASE_MsgCreate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_msgCreate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'MessagesCreate');
      if (settingValue != null) {
        this.PURCHASE_msgCreate = settingValue;
      } else {
        this.PURCHASE_msgCreate = defaultValue;
      }
    }
    return this.PURCHASE_msgCreate;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getPURCHASE_MsgDelete(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_msgDelete === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'MessagesDelete');
      if (settingValue != null) {
        this.PURCHASE_msgDelete = settingValue;
      } else {
        this.PURCHASE_msgDelete = defaultValue;
      }
    }
    return this.PURCHASE_msgDelete;
  }
  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns boolean
 */
  getPURCHASE_DigArcCreate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_digArcCreate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'DigArchiveCreate');
      if (settingValue != null) {
        this.PURCHASE_digArcCreate = settingValue;
      } else {
        this.PURCHASE_digArcCreate = defaultValue;
      }
    }
    return this.PURCHASE_digArcCreate;
  }
  /**
   * Vai verificar se há foi definido algum valor na variavel do servico,caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
  getPURCHASE_DigArcDelete(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_digArcDelete === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'DigArchiveDelete');
      if (settingValue != null) {
        this.PURCHASE_digArcDelete = settingValue;
      } else {
        this.PURCHASE_digArcDelete = defaultValue;
      }
    }
    return this.PURCHASE_digArcDelete;
  }

/**
   * Vai verificar se há foi definido algum valor na variavel do servico,caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns boolean
   */
 getPURCHASE_DigArcLimitNumberOfFiles(defaultValue: boolean): boolean {
  if (typeof this.PURCHASE_digArcLimitNumberOfFiles === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'DigArcLimitNumberOfFiles');
    if (settingValue != null) {
      this.PURCHASE_digArcLimitNumberOfFiles = settingValue;
    } else {
      this.PURCHASE_digArcLimitNumberOfFiles = defaultValue;
    }
  }
  return this.PURCHASE_digArcLimitNumberOfFiles;
}

  // se produtos repetidos
  getPURCHASE_allowsRepeatedProducts(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_allowsRepeatedProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllowsRepeatedProducts');
      if (settingValue != null) {
        this.PURCHASE_allowsRepeatedProducts = settingValue;
      } else {
        this.PURCHASE_allowsRepeatedProducts = defaultValue;
      }
    }
    return this.PURCHASE_allowsRepeatedProducts;
  }
  // se mostra botao listagem produtos
  getPURCHASE_showButtonSelectProducts(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showButtonSelectProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowButtonSelectProducts');
      if (settingValue != null) {
        this.PURCHASE_showButtonSelectProducts = settingValue;
      } else {
        this.PURCHASE_showButtonSelectProducts = defaultValue;
      }
    }
    return this.PURCHASE_showButtonSelectProducts;
  }

  getPURCHASE_showSupplierTelephone(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSupplierTelephone === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSupplierTelephone');
      if (settingValue != null) {
        this.PURCHASE_showSupplierTelephone = settingValue;
      } else {
        this.PURCHASE_showSupplierTelephone = defaultValue;
      }
    }
    return this.PURCHASE_showSupplierTelephone;
  }

  getPURCHASE_showSupplierFax(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSupplierFax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSupplierFax');
      if (settingValue != null) {
        this.PURCHASE_showSupplierFax = settingValue;
      } else {
        this.PURCHASE_showSupplierFax = defaultValue;
      }
    }
    return this.PURCHASE_showSupplierFax;
  }

  getPURCHASE_showSupplierElectronicMail(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSupplierElectronicMail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSupplierElectronicMail');
      if (settingValue != null) {
        this.PURCHASE_showSupplierElectronicMail = settingValue;
      } else {
        this.PURCHASE_showSupplierElectronicMail = defaultValue;
      }
    }
    return this.PURCHASE_showSupplierElectronicMail;
  }

  getPURCHASE_showSupplierDepartmentCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSupplierDepartmentCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSupplierDepartmentCode');
      if (settingValue != null) {
        this.PURCHASE_showSupplierDepartmentCode = settingValue;
      } else {
        this.PURCHASE_showSupplierDepartmentCode = defaultValue;
      }
    }
    return this.PURCHASE_showSupplierDepartmentCode;
  }

  getPURCHASE_showSupplierDepartmentName(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSupplierDepartmentName === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSupplierDepartmentName');
      if (settingValue != null) {
        this.PURCHASE_showSupplierDepartmentName = settingValue;
      } else {
        this.PURCHASE_showSupplierDepartmentName = defaultValue;
      }
    }
    return this.PURCHASE_showSupplierDepartmentName;
  }

  getPURCHASE_showSupplierDivisionCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSupplierDivisionCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSupplierDivisionCode');
      if (settingValue != null) {
        this.PURCHASE_showSupplierDivisionCode = settingValue;
      } else {
        this.PURCHASE_showSupplierDivisionCode = defaultValue;
      }
    }
    return this.PURCHASE_showSupplierDivisionCode;
  }

  getPURCHASE_showInvoiceTelephone(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceTelephone === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceTelephone');
      if (settingValue != null) {
        this.PURCHASE_showInvoiceTelephone = settingValue;
      } else {
        this.PURCHASE_showInvoiceTelephone = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceTelephone;
  }

  getPURCHASE_showInvoiceFax(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceFax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceFax');
      if (settingValue != null) {
        this.PURCHASE_showInvoiceFax = settingValue;
      } else {
        this.PURCHASE_showInvoiceFax = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceFax;
  }

  getPURCHASE_showInvoiceElectronicMail(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceElectronicMail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceElectronicMail');
      if (settingValue != null) {
        this.PURCHASE_showInvoiceElectronicMail = settingValue;
      } else {
        this.PURCHASE_showInvoiceElectronicMail = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceElectronicMail;
  }

  getPURCHASE_defaultDateTimeFormat(defaultValue: number): number {
    if (typeof this.PURCHASE_defaultDateTimeFormat === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'DefaultDateTimeFormat');
      if (settingValue != null) {
        this.PURCHASE_defaultDateTimeFormat = settingValue;
      } else {
        this.PURCHASE_defaultDateTimeFormat = defaultValue;
      }
    }
    return this.PURCHASE_defaultDateTimeFormat;
  }

  getPURCHASE_showDeliveryLoadingLocation(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryLoadingLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryLoadingLocation');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryLoadingLocation = settingValue;
      } else {
        this.PURCHASE_showDeliveryLoadingLocation = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryLoadingLocation;
  }

  getPURCHASE_showDeliveryLoadingDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryLoadingDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryLoadingDate');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryLoadingDate = settingValue;
      } else {
        this.PURCHASE_showDeliveryLoadingDate = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryLoadingDate;
  }

  getPURCHASE_showDeliveryLocation(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryLocation');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryLocation = settingValue;
      } else {
        this.PURCHASE_showDeliveryLocation = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryLocation;
  }

  getPURCHASE_showDeliveryTransportID(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryTransportID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryTransportID');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryTransportID = settingValue;
      } else {
        this.PURCHASE_showDeliveryTransportID = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryTransportID;
  }

  getPURCHASE_showDeliveryConditionsValue(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryConditionsValue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryConditionsValue');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryConditionsValue = settingValue;
      } else {
        this.PURCHASE_showDeliveryConditionsValue = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryConditionsValue;
  }

  getPURCHASE_showDeliveryFreeText(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryFreeText === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryFreeText');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryFreeText = settingValue;
      } else {
        this.PURCHASE_showDeliveryFreeText = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryFreeText;
  }

  getPURCHASE_showTotalChargeAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalChargeAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalChargeAmount');
      if (settingValue != null) {
        this.PURCHASE_showTotalChargeAmount = settingValue;
      } else {
        this.PURCHASE_showTotalChargeAmount = defaultValue;
      }
    }
    return this.PURCHASE_showTotalChargeAmount;
  }

  getPURCHASE_showTotalDiscountAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalDiscountAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalDiscountAmount');
      if (settingValue != null) {
        this.PURCHASE_showTotalDiscountAmount = settingValue;
      } else {
        this.PURCHASE_showTotalDiscountAmount = defaultValue;
      }
    }
    return this.PURCHASE_showTotalDiscountAmount;
  }

  getPURCHASE_showTotalPackingCostAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalPackingCostAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalPackingCostAmount');
      if (settingValue != null) {
        this.PURCHASE_showTotalPackingCostAmount = settingValue;
      } else {
        this.PURCHASE_showTotalPackingCostAmount = defaultValue;
      }
    }
    return this.PURCHASE_showTotalPackingCostAmount;
  }

  getPURCHASE_showTotalPrePaymentAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalPrePaymentAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalPrePaymentAmount');
      if (settingValue != null) {
        this.PURCHASE_showTotalPrePaymentAmount = settingValue;
      } else {
        this.PURCHASE_showTotalPrePaymentAmount = defaultValue;
      }
    }
    return this.PURCHASE_showTotalPrePaymentAmount;
  }

  getPURCHASE_showCurrencyExchange(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showCurrencyExchange === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowCurrencyExchange');
      if (settingValue != null) {
        this.PURCHASE_showCurrencyExchange = settingValue;
      } else {
        this.PURCHASE_showCurrencyExchange = defaultValue;
      }
    }
    return this.PURCHASE_showCurrencyExchange;
  }

  getPURCHASE_showOthersReferencesTypes(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowOthersReferencesTypes');
      if (settingValue != null) {
        this.PURCHASE_showOthersReferencesTypes = settingValue;
      } else {
        this.PURCHASE_showOthersReferencesTypes = defaultValue;
      }
    }
    return this.PURCHASE_showOthersReferencesTypes;
  }

  getPURCHASE_UseOrderAndDeliveryNoteREFs(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_UseOrderAndDeliveryNoteREFs === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'UseOrderAndDeliveryNoteREFs');
      if (settingValue != null) {
        this.PURCHASE_UseOrderAndDeliveryNoteREFs = settingValue;
      } else {
        this.PURCHASE_UseOrderAndDeliveryNoteREFs = defaultValue;
      }
    }
    return this.PURCHASE_UseOrderAndDeliveryNoteREFs;
  }

  getPURCHASE_showBuyersCostCentre(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowBuyersCostCentre === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowBuyersCostCentre');
      if (settingValue != null) {
        this.PURCHASE_ShowBuyersCostCentre = settingValue;
      } else {
        this.PURCHASE_ShowBuyersCostCentre = defaultValue;
      }
    }
    return this.PURCHASE_ShowBuyersCostCentre;
  }

  getPURCHASE_showCertificateNumber(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowCertificateNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowCertificateNumber');
      if (settingValue != null) {
        this.PURCHASE_ShowCertificateNumber = settingValue;
      } else {
        this.PURCHASE_ShowCertificateNumber = defaultValue;
      }
    }
    return this.PURCHASE_ShowCertificateNumber;
  }

  getPURCHASE_showTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalGrossAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalGrossAmount = settingValue;
      } else {
        this.PURCHASE_ShowTotalGrossAmount = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalGrossAmount;
  }

  getPURCHASE_showTotalTransportAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalTransportAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalTransportAmount');
      if (settingValue != null) {
        this.PURCHASE_showTotalTransportAmount = settingValue;
      } else {
        this.PURCHASE_showTotalTransportAmount = defaultValue;
      }
    }
    return this.PURCHASE_showTotalTransportAmount;
  }

  getPURCHASE_showTotalRetencaoIrsPercentage(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalRetencaoIrsPercentage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalRetencaoIrsPercentage');
      if (settingValue != null) {
        this.PURCHASE_showTotalRetencaoIrsPercentage = settingValue;
      } else {
        this.PURCHASE_showTotalRetencaoIrsPercentage = defaultValue;
      }
    }
    return this.PURCHASE_showTotalRetencaoIrsPercentage;
  }

  getPURCHASE_showTotalRetencaoIrsAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalRetencaoIrsAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalRetencaoIrsAmount');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalRetencaoIrsAmount = settingValue;
      } else {
        this.PURCHASE_ShowTotalRetencaoIrsAmount = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalRetencaoIrsAmount;
  }

  getPURCHASE_showTotalEnvironmentalTaxAmount(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalEnvironmentalTaxAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalEnvironmentalTaxAmount');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalEnvironmentalTaxAmount = settingValue;
      } else {
        this.PURCHASE_ShowTotalEnvironmentalTaxAmount = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalEnvironmentalTaxAmount;
  }

  getPURCHASE_showTotalNetWeight(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalNetWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalNetWeight');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalNetWeight = settingValue;
      } else {
        this.PURCHASE_ShowTotalNetWeight = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalNetWeight;
  }

  getPURCHASE_showTotalNetWeightUOMCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalNetWeightUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalNetWeightUOMCode');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalNetWeightUOMCode = settingValue;
      } else {
        this.PURCHASE_ShowTotalNetWeightUOMCode = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalNetWeightUOMCode;
  }

  getPURCHASE_showTotalGrossWeight(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalGrossWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalGrossWeight');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalGrossWeight = settingValue;
      } else {
        this.PURCHASE_ShowTotalGrossWeight = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalGrossWeight;
  }

  getPURCHASE_showTotalGrossWeightUOMCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalGrossWeightUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalGrossWeightUOMCode');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalGrossWeightUOMCode = settingValue;
      } else {
        this.PURCHASE_ShowTotalGrossWeightUOMCode = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalGrossWeightUOMCode;
  }

  getPURCHASE_showTotalNetVolume(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalNetVolume === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalNetVolume');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalNetVolume = settingValue;
      } else {
        this.PURCHASE_ShowTotalNetVolume = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalNetVolume;
  }

  getPURCHASE_showTotalNetVolumeUOMCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowTotalNetVolumeUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalNetVolumeUOMCode');
      if (settingValue != null) {
        this.PURCHASE_ShowTotalNetVolumeUOMCode = settingValue;
      } else {
        this.PURCHASE_ShowTotalNetVolumeUOMCode = defaultValue;
      }
    }
    return this.PURCHASE_ShowTotalNetVolumeUOMCode;
  }

  getPURCHASE_showDeliveryToTelephone(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryToTelephone === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryToTelephone');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryToTelephone = settingValue;
      } else {
        this.PURCHASE_showDeliveryToTelephone = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryToTelephone;
  }

  getPURCHASE_showDeliveryToFax(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryToFax === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryToFax');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryToFax = settingValue;
      } else {
        this.PURCHASE_showDeliveryToFax = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryToFax;
  }

  getPURCHASE_showDeliveryToElectronicMail(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showDeliveryToElectronicMail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowDeliveryToElectronicMail');
      if (settingValue != null) {
        this.PURCHASE_showDeliveryToElectronicMail = settingValue;
      } else {
        this.PURCHASE_showDeliveryToElectronicMail = defaultValue;
      }
    }
    return this.PURCHASE_showDeliveryToElectronicMail;
  }

  getPURCHASE_showPaymentConditionsInvoiceDueDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentConditionsInvoiceDueDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentConditionsInvoiceDueDate');
      if (settingValue != null) {
        this.PURCHASE_showPaymentConditionsInvoiceDueDate = settingValue;
      } else {
        this.PURCHASE_showPaymentConditionsInvoiceDueDate = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentConditionsInvoiceDueDate;
  }

  getPURCHASE_showPaymentConditionsServicesTermDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentConditionsServicesTermDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentConditionsServicesTermDate');
      if (settingValue != null) {
        this.PURCHASE_showPaymentConditionsServicesTermDate = settingValue;
      } else {
        this.PURCHASE_showPaymentConditionsServicesTermDate = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentConditionsServicesTermDate;
  }

  getPURCHASE_showPaymentConditionsList(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentConditionsList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentConditionsList');
      if (settingValue != null) {
        this.PURCHASE_showPaymentConditionsList = settingValue;
      } else {
        this.PURCHASE_showPaymentConditionsList = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentConditionsList;
  }

  getPURCHASE_showPaymentMethod(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentMethod === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentMethod');
      if (settingValue != null) {
        this.PURCHASE_showPaymentMethod = settingValue;
      } else {
        this.PURCHASE_showPaymentMethod = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentMethod;
  }

  getPURCHASE_showPaid(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaid === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaid');
      if (settingValue != null) {
        this.PURCHASE_showPaid = settingValue;
      } else {
        this.PURCHASE_showPaid = defaultValue;
      }
    }
    return this.PURCHASE_showPaid;
  }

  getPURCHASE_showValueInEuros(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showValueInEuros === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowValueInEuros');
      if (settingValue != null) {
        this.PURCHASE_showValueInEuros = settingValue;
      } else {
        this.PURCHASE_showValueInEuros = defaultValue;
      }
    }
    return this.PURCHASE_showValueInEuros;
  }

  getPURCHASE_UsersRolesToValidateInvoice(defaultValue: any[]): any[] {
    if (typeof this.PURCHASE_UsersRolesToValidateInvoice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'UsersRolesToValidateInvoice');
      if (settingValue != null) {
        this.PURCHASE_UsersRolesToValidateInvoice = settingValue;
      } else {
        this.PURCHASE_UsersRolesToValidateInvoice = defaultValue;
      }
    }
    return this.PURCHASE_UsersRolesToValidateInvoice;
  }

  getPURCHASE_InvoiceStatesToValidate(defaultValue: any[]): any[] {
    if (typeof this.PURCHASE_InvoiceStatesToValidate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'InvoiceStatesToValidate');
      if (settingValue != null) {
        this.PURCHASE_InvoiceStatesToValidate = settingValue;
      } else {
        this.PURCHASE_InvoiceStatesToValidate = defaultValue;
      }
    }
    return this.PURCHASE_InvoiceStatesToValidate;
  }

  getPURCHASE_InvoiceStatesToEditInvoice(defaultValue: any[]): any[] {
    if (typeof this.PURCHASE_InvoiceStatesToEditOrderNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'InvoiceStatesToEditInvoice');
      if (settingValue != null) {
        this.PURCHASE_InvoiceStatesToEditOrderNumber = settingValue;
      } else {
        this.PURCHASE_InvoiceStatesToEditOrderNumber = defaultValue;
      }
    }
    return this.PURCHASE_InvoiceStatesToEditOrderNumber;
  }

  getPURCHASE_invoiceRepositoryDiskFileContext(defaultValue: string): string {
    if (typeof this.PURCHASE_invoiceRepositoryDiskFileContext === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'InvoiceRepositoryDiskFileContext');
      if (settingValue != null) {
        this.PURCHASE_invoiceRepositoryDiskFileContext = settingValue;
      } else {
        this.PURCHASE_invoiceRepositoryDiskFileContext = defaultValue;
      }
    }
    return this.PURCHASE_invoiceRepositoryDiskFileContext;
  }

  getPURCHASE_usersRolesToAccountInvoice(defaultValue: any[]): any[] {
    if (typeof this.PURCHASE_usersRolesToAccountInvoice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'UsersRolesToAccountInvoice');
      if (settingValue != null) {
        this.PURCHASE_usersRolesToAccountInvoice = settingValue;
      } else {
        this.PURCHASE_usersRolesToAccountInvoice = defaultValue;
      }
    }
    return this.PURCHASE_usersRolesToAccountInvoice;
  }

  getPURCHASE_invoiceStatesToAccount(defaultValue: any[]): any[] {
    if (typeof this.PURCHASE_invoiceStatesToAccount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'InvoiceStatesToAccount');
      if (settingValue != null) {
        this.PURCHASE_invoiceStatesToAccount = settingValue;
      } else {
        this.PURCHASE_invoiceStatesToAccount = defaultValue;
      }
    }
    return this.PURCHASE_invoiceStatesToAccount;
  }

  getPURCHASE_showRetentionAccountingButton(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showRetentionAccountingButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowRetentionAccountingButton');
      if (settingValue != null) {
        this.PURCHASE_showRetentionAccountingButton = settingValue;
      } else {
        this.PURCHASE_showRetentionAccountingButton = defaultValue;
      }
    }
    return this.PURCHASE_showRetentionAccountingButton;
  }

  /*
  getPURCHASE_showRetentionCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showRetentionCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowRetentionCode');
      if (settingValue != null) {
        this.PURCHASE_showRetentionCode = settingValue;
      } else {
        this.PURCHASE_showRetentionCode = defaultValue;
      }
    }
    return this.PURCHASE_showRetentionCode;
  }
  */
 
  getPURCHASE_getAccountingConfig(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_getAccountingConfig === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'GetAccountingConfig');
      if (settingValue != null) {
        this.PURCHASE_getAccountingConfig = settingValue;
      } else {
        this.PURCHASE_getAccountingConfig = defaultValue;
      }
    }
    return this.PURCHASE_getAccountingConfig;
  }

  getPURCHASE_showAuditCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showAuditCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowAuditCode');
      if (settingValue != null) {
        this.PURCHASE_showAuditCode = settingValue;
      } else {
        this.PURCHASE_showAuditCode = defaultValue;
      }
    }
    return this.PURCHASE_showAuditCode;
  }

  getPURCHASE_isMultipleOrder(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_isMultipleOrder === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'IsMultipleOrder');
      if (settingValue != null) {
        this.PURCHASE_isMultipleOrder = settingValue;
      } else {
        this.PURCHASE_isMultipleOrder = defaultValue;
      }
    }
    return this.PURCHASE_isMultipleOrder;
  }

  getPURCHASE_showBuyerContractNumber(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showBuyerContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowBuyerContractNumber');
      if (settingValue != null) {
        this.PURCHASE_showBuyerContractNumber = settingValue;
      } else {
        this.PURCHASE_showBuyerContractNumber = defaultValue;
      }
    }
    return this.PURCHASE_showBuyerContractNumber;
  }

  getPURCHASE_showHeaderCreationDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showHeaderCreationDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowHeaderCreationDate');
      if (settingValue != null) {
        this.PURCHASE_showHeaderCreationDate = settingValue;
      } else {
        this.PURCHASE_showHeaderCreationDate = defaultValue;
      }
    }
    return this.PURCHASE_showHeaderCreationDate;
  }

  get_PURCHASE_showIdOnEditDetail(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showIdOnEditDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowIdOnEditDetail');
      if (settingValue !== null) {
        this.PURCHASE_showIdOnEditDetail = settingValue;
      } else {
        this.PURCHASE_showIdOnEditDetail = defaultValue;
      }
    }
    return this.PURCHASE_showIdOnEditDetail;
  }

  get_PURCHASE_showAttachesIsPublic(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showAttachesIsPublic === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowAttachesIsPublic');
      if (settingValue !== null) {
        this.PURCHASE_showAttachesIsPublic = settingValue;
      } else {
        this.PURCHASE_showAttachesIsPublic = defaultValue;
      }
    }
    return this.PURCHASE_showAttachesIsPublic;
  }

  get_PURCHASE_showInvoiceToStreet(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceToStreet === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceToStreet');
      if (settingValue !== null) {
        this.PURCHASE_showInvoiceToStreet = settingValue;
      } else {
        this.PURCHASE_showInvoiceToStreet = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceToStreet;
  }

  get_PURCHASE_showInvoiceToCity(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceToCity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceToCity');
      if (settingValue !== null) {
        this.PURCHASE_showInvoiceToCity = settingValue;
      } else {
        this.PURCHASE_showInvoiceToCity = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceToCity;
  }

  get_PURCHASE_showInvoiceToCountryCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceToCountryCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceToCountryCode');
      if (settingValue !== null) {
        this.PURCHASE_showInvoiceToCountryCode = settingValue;
      } else {
        this.PURCHASE_showInvoiceToCountryCode = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceToCountryCode;
  }

  get_PURCHASE_showInvoiceToPostalCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showInvoiceToPostalCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowInvoiceToPostalCode');
      if (settingValue !== null) {
        this.PURCHASE_showInvoiceToPostalCode = settingValue;
      } else {
        this.PURCHASE_showInvoiceToPostalCode = defaultValue;
      }
    }
    return this.PURCHASE_showInvoiceToPostalCode;
  }

  get_PURCHASE_validateFreeTextMaxLength(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_validateFreeTextMaxLength === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ValidateFreeTextMaxLength');
      if (settingValue !== null) {
        this.PURCHASE_validateFreeTextMaxLength = settingValue;
      } else {
        this.PURCHASE_validateFreeTextMaxLength = defaultValue;
      }
    }
    return this.PURCHASE_validateFreeTextMaxLength;
  }

  get_PURCHASE_showCentralPayment(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showCentralPayment === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowCentralPayment');
      if (settingValue !== null) {
        this.PURCHASE_showCentralPayment = settingValue;
      } else {
        this.PURCHASE_showCentralPayment = defaultValue;
      }
    }
    return this.PURCHASE_showCentralPayment;
  }

  get_PURCHASE_showPaymentBlocked(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentBlocked === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentBlocked');
      if (settingValue !== null) {
        this.PURCHASE_showPaymentBlocked = settingValue;
      } else {
        this.PURCHASE_showPaymentBlocked = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentBlocked;
  }

  get_PURCHASE_warnUserWhenUnsavedChanges(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_warnUserWhenUnsavedChanges === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'WarnUserWhenUnsavedChanges');
      if (settingValue !== null) {
        this.PURCHASE_warnUserWhenUnsavedChanges = settingValue;
      } else {
        this.PURCHASE_warnUserWhenUnsavedChanges = defaultValue;
      }
    }
    return this.PURCHASE_warnUserWhenUnsavedChanges;
  }

  get_PURCHASE_redirectToOrderForm(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_redirectToOrderForm === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'RedirectToOrderForm');
      if (settingValue !== null) {
        this.PURCHASE_redirectToOrderForm = settingValue;
      } else {
        this.PURCHASE_redirectToOrderForm = defaultValue;
      }
    }
    return this.PURCHASE_redirectToOrderForm;
  }

  get_PURCHASE_showSectionApprovalCode(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showSectionApprovalCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowSectionApprovalCode');
      if (settingValue !== null) {
        this.PURCHASE_showSectionApprovalCode = settingValue;
      } else {
        this.PURCHASE_showSectionApprovalCode = defaultValue;
      }
    }
    return this.PURCHASE_showSectionApprovalCode;
  }

  get_PURCHASE_showPaymentPriority(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentPriority === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentPriority');
      if (settingValue !== null) {
        this.PURCHASE_showPaymentPriority = settingValue;
      } else {
        this.PURCHASE_showPaymentPriority = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentPriority;
  }

  get_PURCHASE_showTotalAmountToPay(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showTotalAmountToPay === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowTotalAmountToPay');
      if (settingValue !== null) {
        this.PURCHASE_showTotalAmountToPay = settingValue;
      } else {
        this.PURCHASE_showTotalAmountToPay = defaultValue;
      }
    }
    return this.PURCHASE_showTotalAmountToPay;
  }

  get_hideExcelExportButton_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.hideExcelExportButton_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HideExcelExportButton');
      if (settingValue != null) {
        this.hideExcelExportButton_PURCHASE = settingValue;
      } else {
        this.hideExcelExportButton_PURCHASE = defaultValue;
      }
    }
    return this.hideExcelExportButton_PURCHASE;
  }
  get_hideColumsTax_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.hideColumsTax_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HideColumsTax');
      if (settingValue != null) {
        this.hideColumsTax_PURCHASE = settingValue;
      } else {
        this.hideColumsTax_PURCHASE = defaultValue;
      }
    }
    return this.hideColumsTax_PURCHASE;
  }
  

  get_PURCHASE_InvoiceOrders(invoiceID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Invoice/GetInvoiceOrders?id=' + invoiceID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_PURCHASE_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_afterSaveRemainOnSamePage === 'undefined') {
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.PURCHASE_afterSaveRemainOnSamePage = settingValue;
      }
      else {
        this.PURCHASE_afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.PURCHASE_afterSaveRemainOnSamePage;
  }

  get_PURCHASE_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_redirectOnUpdate === 'undefined') {
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.PURCHASE_redirectOnUpdate = settingValue;
      }
      else {
        this.PURCHASE_redirectOnUpdate = defaultValue;
      }
    }
    return this.PURCHASE_redirectOnUpdate;
  }

  getPURCHASE_hideOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hideOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HideOtherReferences');
      if (settingValue != null) {
        this.PURCHASE_hideOtherReferences = settingValue;
      } else {
        this.PURCHASE_hideOtherReferences = defaultValue;
      }
    }
    return this.PURCHASE_hideOtherReferences;
  }

  getPURCHASE_hidePanelSupplier(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hidePanelSupplier === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HidePanelSupplier');
      if (settingValue != null) {
        this.PURCHASE_hidePanelSupplier = settingValue;
      } else {
        this.PURCHASE_hidePanelSupplier = defaultValue;
      }
    }
    return this.PURCHASE_hidePanelSupplier;
  }

  getPURCHASE_hidePanelDelivery(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hidePanelDelivery === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HidePanelDelivery');
      if (settingValue != null) {
        this.PURCHASE_hidePanelDelivery = settingValue;
      } else {
        this.PURCHASE_hidePanelDelivery = defaultValue;
      }
    }
    return this.PURCHASE_hidePanelDelivery;
  }

  getPURCHASE_hidePanelObservations(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hidePanelObservations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HidePanelObservations');
      if (settingValue != null) {
        this.PURCHASE_hidePanelObservations = settingValue;
      } else {
        this.PURCHASE_hidePanelObservations = defaultValue;
      }
    }
    return this.PURCHASE_hidePanelObservations;
  }

  getPURCHASE_showPanelReferencesFirst(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPanelReferencesFirst === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPanelReferencesFirst');
      if (settingValue != null) {
        this.PURCHASE_showPanelReferencesFirst = settingValue;
      } else {
        this.PURCHASE_showPanelReferencesFirst = defaultValue;
      }
    }
    return this.PURCHASE_showPanelReferencesFirst;
  }

  
  getPURCHASE_allowedFileTypes(defaultValue: Array<string>): Array<string> {
    if (this.PURCHASE_allowedFileTypes.length == 0) { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'AllowedFileTypes');
      if(settingValue)
        settingValue = settingValue.split(",");  
      if (settingValue != null) {
        this.PURCHASE_allowedFileTypes = settingValue;
      } else {
        this.PURCHASE_allowedFileTypes = defaultValue;
      }
    }
    return this.PURCHASE_allowedFileTypes;
  }
  //#endregion

  //#region ---------------------- ***  VARIAVEIS DETAILS PURCHASE  *** ---------------------
  get_DETAIL_PURCHASE_ShowPanelDiscounts(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_ShowPanelDiscounts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPanelDiscounts');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_ShowPanelDiscounts = settingValue;
      } else {
        this.DETAIL_PURCHASE_ShowPanelDiscounts = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_ShowPanelDiscounts;
  }

  get_DETAIL_PURCHASE_ShowPanelAllowances(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_ShowPanelAllowances === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPanelAllowances');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_ShowPanelAllowances = settingValue;
      } else {
        this.DETAIL_PURCHASE_ShowPanelAllowances = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_ShowPanelAllowances;
  }

  get_DETAIL_PURCHASE_ShowPanelReferences(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_ShowPanelReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPanelReferences');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_ShowPanelReferences = settingValue;
      } else {
        this.DETAIL_PURCHASE_ShowPanelReferences = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_ShowPanelReferences;
  }

  get_PURCHASE_ShowATCUD(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowATCUD === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowATCUD');
      if (settingValue != null) {
        this.PURCHASE_ShowATCUD = settingValue;
      } else {
        this.PURCHASE_ShowATCUD = defaultValue;
      }
    }
    return this.PURCHASE_ShowATCUD;
  }

  get_PURCHASE_showGuideNumber(defaultValue: boolean): boolean {
    if (typeof this.showGuideNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowGuideNumber');
      if (settingValue != null) {
        this.showGuideNumber = settingValue;
      } else {
        this.showGuideNumber = defaultValue;
      }
    }
    return this.showGuideNumber;
  }

  get_PURCHASE_showBarcode(defaultValue: boolean): boolean {
    if (typeof this.showBarcode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowBarcode');
      if (settingValue != null) {
        this.showBarcode = settingValue;
      } else {
        this.showBarcode = defaultValue;
      }
    }
    return this.showBarcode;
  }

  get_PURCHASE_hideGuideNote(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hideGuideNote === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HideGuideNote');
      if (settingValue != null) {
        this.PURCHASE_hideGuideNote = settingValue;
      } else {
        this.PURCHASE_hideGuideNote = defaultValue;
      }
    }
    return this.PURCHASE_hideGuideNote;
  }

  get_PURCHASE_showPaymentCondition(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showPaymentCondition === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowPaymentCondition');
      if (settingValue != null) {
        this.PURCHASE_showPaymentCondition = settingValue;
      } else {
        this.PURCHASE_showPaymentCondition = defaultValue;
      }
    }
    return this.PURCHASE_showPaymentCondition;
  }

  get_PURCHASE_showMeansOfPayment(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showMeansOfPayment === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowMeansOfPayment');
      if (settingValue != null) {
        this.PURCHASE_showMeansOfPayment = settingValue;
      } else {
        this.PURCHASE_showMeansOfPayment = defaultValue;
      }
    }
    return this.PURCHASE_showMeansOfPayment;
  }

  get_PURCHASE_hideAttachmentType(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hideAttachmentType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HideAttachmentType');
      if (settingValue != null) {
        this.PURCHASE_hideAttachmentType = settingValue;
      } else {
        this.PURCHASE_hideAttachmentType = defaultValue;
      }
    }
    return this.PURCHASE_hideAttachmentType;
  }

  get_PURCHASE_hideAttachmentDescription(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hideAttachmentDescription === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'HideAttachmentDescription');
      if (settingValue != null) {
        this.PURCHASE_hideAttachmentDescription = settingValue;
      } else {
        this.PURCHASE_hideAttachmentDescription = defaultValue;
      }
    }
    return this.PURCHASE_hideAttachmentDescription;
  }

  get_showApprovTab(defaultValue: boolean): boolean {
    if (typeof this.showApprovTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowApprovTab');
      if (settingValue != null) {
        this.showApprovTab = settingValue;
      } else {
        this.showApprovTab = defaultValue;
      }
    }
    return this.showApprovTab;
  }

  get_PURCHASE_showCostCenter(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowCostCenter');
      if (settingValue != null) {
        this.PURCHASE_showCostCenter = settingValue;
      } else {
        this.PURCHASE_showCostCenter = defaultValue;
      }
    }
    return this.PURCHASE_showCostCenter;
  }

  get_DETAIL_PURCHASE_showCostCenter(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowCostCenter');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showCostCenter = settingValue;
      } else {
        this.DETAIL_PURCHASE_showCostCenter = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showCostCenter;
  }

  get_DETAIL_PURCHASE_ShowBacthNumber(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showBacthNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowBacthNumber');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showBacthNumber = settingValue;
      } else {
        this.DETAIL_PURCHASE_showBacthNumber = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showBacthNumber;
  }

  get_DETAIL_PURCHASE_ShowDeliveryDate(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDeliveryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowDeliveryDate');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDeliveryDate = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDeliveryDate = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDeliveryDate;
  }

  get_DETAIL_PURCHASE_ShowBacthExpiryDate(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showBacthExpiryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowBacthExpiryDate');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showBacthExpiryDate = settingValue;
      } else {
        this.DETAIL_PURCHASE_showBacthExpiryDate = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showBacthExpiryDate;
  }

  get_DETAIL_PURCHASE_BuyerProductCodeEditable(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_BuyerProductCodeEditable === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'BuyerProductCodeEditable');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_BuyerProductCodeEditable = settingValue;
      } else {
        this.DETAIL_PURCHASE_BuyerProductCodeEditable = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_BuyerProductCodeEditable;
  }

  get_DETAIL_PURCHASE_ShowDetailBonusQuantity(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailBonusQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowBonusQuantity');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailBonusQuantity = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailBonusQuantity = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailBonusQuantity;
  }

  get_DETAIL_PURCHASE_ShowDetailBonusQuantityUOMCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailBonusQuantityUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowBonusQuantityUOMCode');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailBonusQuantityUOMCode = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailBonusQuantityUOMCode = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailBonusQuantityUOMCode;
  }

  get_DETAIL_PURCHASE_ShowDetailPackQuantity(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailPackQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPackQuantity');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailPackQuantity = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailPackQuantity = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailPackQuantity;
  }

  get_DETAIL_PURCHASE_ShowDetailPackQuantityUOMCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailPackQuantityUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPackQuantityUOMCode');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailPackQuantityUOMCode = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailPackQuantityUOMCode = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailPackQuantityUOMCode;
  }

  get_DETAIL_PURCHASE_showOrderLineNumberOnProductPanel(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showOrderLineNumberOnProductPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowOrderLineNumberOnProductPanel');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showOrderLineNumberOnProductPanel = settingValue;
      } else {
        this.DETAIL_PURCHASE_showOrderLineNumberOnProductPanel = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showOrderLineNumberOnProductPanel;
  }

  get_DETAIL_PURCHASE_ShowDetailPackSize(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailPackSize === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPackSize');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailPackSize = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailPackSize = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailPackSize;
  }

  get_DETAIL_PURCHASE_EditDetailTotalNetAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_editDetailTotalNetAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'EditTotalNetAmount');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_editDetailTotalNetAmount = settingValue;
      } else {
        this.DETAIL_PURCHASE_editDetailTotalNetAmount = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_editDetailTotalNetAmount;
  }

  get_DETAIL_PURCHASE_EditDetailGrossUnitPrice(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_editDetailGrossUnitPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'EditGrossUnitPrice');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_editDetailGrossUnitPrice = settingValue;
      } else {
        this.DETAIL_PURCHASE_editDetailGrossUnitPrice = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_editDetailGrossUnitPrice;
  }

  get_DETAIL_PURCHASE_EditDetailTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_editDetailTotalGrossAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'EditTotalGrossAmount');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_editDetailTotalGrossAmount = settingValue;
      } else {
        this.DETAIL_PURCHASE_editDetailTotalGrossAmount = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_editDetailTotalGrossAmount;
  }

  getPURCHASES_showOptionsReprocessBtn(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_ShowOptionsReprocessBtn === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ShowReprocessBtn');
      if (settingValue != null) {
        this.PURCHASE_ShowOptionsReprocessBtn = settingValue;
      } else {
        this.PURCHASE_ShowOptionsReprocessBtn = defaultValue;
      }
    }
    return this.PURCHASE_ShowOptionsReprocessBtn;
  }

  get_PURCHASE_useAccountingConfig(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_useAccountingConfig === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'UseAccountingConfig');
      if (settingValue != null) {
        this.PURCHASE_useAccountingConfig = settingValue;
      } else {
        this.PURCHASE_useAccountingConfig = defaultValue;
      }
    }
    return this.PURCHASE_useAccountingConfig;
  }

  get_PURCHASE_showCreationDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showCreationDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ShowCreationDate');
      if (settingValue != null) {
        this.PURCHASE_showCreationDate = settingValue;
      } else {
        this.PURCHASE_showCreationDate = defaultValue;
      }
    }
    return this.PURCHASE_showCreationDate;
  }

  get_PURCHASE_showColumnID(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showColumnID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ShowColumnID');
      if (settingValue != null) {
        this.PURCHASE_showColumnID = settingValue;
      } else {
        this.PURCHASE_showColumnID = defaultValue;
      }
    }
    return this.PURCHASE_showColumnID;
  }

  get_PURCHASE_filterByHistory(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_filterByHistory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'FilterByHistory');
      if (settingValue != null) {
        this.PURCHASE_filterByHistory = settingValue;
      } else {
        this.PURCHASE_filterByHistory = defaultValue;
      }
    }
    return this.PURCHASE_filterByHistory;
  }

  get_PURCHASE_hideAssignActionsToAnotherUser(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hideAssignActionsToAnotherUser === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'HideAssignActionsToAnotherUser');
      if (settingValue != null) {
        this.PURCHASE_hideAssignActionsToAnotherUser = settingValue;
      } else {
        this.PURCHASE_hideAssignActionsToAnotherUser = defaultValue;
      }
    }
    return this.PURCHASE_hideAssignActionsToAnotherUser;
  }

  get_hideFilterModalButton_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_hideFilterModalButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'HideFilterModalButton');
      if (settingValue != null) {
        this.PURCHASES_hideFilterModalButton = settingValue;
      } else {
        this.PURCHASES_hideFilterModalButton = defaultValue;
      }
    }
    return this.PURCHASES_hideFilterModalButton;
  }

  get_PURCHASE_includeTimeInDates(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_defaultDateTimeFormat_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'IncludeTimeInDates');
      if (settingValue != null) {
        this.PURCHASE_defaultDateTimeFormat_SALE = settingValue;
      } else {
        this.PURCHASE_defaultDateTimeFormat_SALE = defaultValue;
      }
    }
    return this.PURCHASE_defaultDateTimeFormat_SALE;
  }

  get_hideExportDropdownMenu_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_hideExportDropdownMenu === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'HideExportDropdownMenu');
      if (settingValue != null) {
        this.PURCHASES_hideExportDropdownMenu = settingValue;
      } else {
        this.PURCHASES_hideExportDropdownMenu = defaultValue;
      }
    }
    return this.PURCHASES_hideExportDropdownMenu;
  }

  get_hideExportExcelButton_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_hideExportExcelButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'HideExportExcelButton');
      if (settingValue != null) {
        this.PURCHASES_hideExportExcelButton = settingValue;
      } else {
        this.PURCHASES_hideExportExcelButton = defaultValue;
      }
    }
    return this.PURCHASES_hideExportExcelButton;
  }

  get_setBeginingOfTheYearAsStartDate_PURCHASES(defaultValue: boolean): boolean {
   if (typeof this.PURCHASES_setBeginingOfTheYearAsStartDate === 'undefined') { // verificar se ainda nao tem valor
     let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'SetBeginingOfTheYearAsStartDate');
     if (settingValue != null) {
       this.PURCHASES_setBeginingOfTheYearAsStartDate = settingValue;
     } else {
       this.PURCHASES_setBeginingOfTheYearAsStartDate = defaultValue;
     }
   }
   return this.PURCHASES_setBeginingOfTheYearAsStartDate;
 }

  get_setTodayAsEndDate_PURCHASES(defaultValue: boolean): boolean {
   if (typeof this.PURCHASES_setTodayAsEndDate === 'undefined') { // verificar se ainda nao tem valor
     let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'SetTodayAsEndDate');
     if (settingValue != null) {
       this.PURCHASES_setTodayAsEndDate = settingValue;
     } else {
       this.PURCHASES_setTodayAsEndDate = defaultValue;
     }
   }
   return this.PURCHASES_setTodayAsEndDate;
 }

 get_showFilterHasDetailActions(defaultValue: boolean): boolean {
  if (typeof this.PURCHASES_showFilterHasDetailActions === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ShowFilterHasDetailActions');
    if (settingValue != null) {
      this.PURCHASES_showFilterHasDetailActions = settingValue;
    } else {
      this.PURCHASES_showFilterHasDetailActions = defaultValue;
    }
  }
  return this.PURCHASES_showFilterHasDetailActions;
}

 get_columnReferenceName(defaultValue: string): string {
  if (typeof this.columnReferenceName === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this._authenticationService.getSettingPortal('InvoicesPurchases', 'ColumnReferenceName');
    if (settingValue != null) {
      this.columnReferenceName = settingValue;
    } else {
      this.columnReferenceName = defaultValue;
    }
  }
  return this.columnReferenceName;
}

 get_DETAIL_PURCHASE_showDescriptionAsTextArea(defaultValue: boolean): boolean {
  if (typeof this.DETAIL_PURCHASE_showDescriptionAsTextArea === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowDescriptionAsTextArea');
    if (settingValue != null) {
      this.DETAIL_PURCHASE_showDescriptionAsTextArea = settingValue;
    } else {
      this.DETAIL_PURCHASE_showDescriptionAsTextArea = defaultValue;
    }
  }
  return this.DETAIL_PURCHASE_showDescriptionAsTextArea;
}

  get_DETAIL_PURCHASE_showDetailGrossUnitPrice(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailGrossUnitPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowGrossUnitPrice');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailGrossUnitPrice = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailGrossUnitPrice = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailGrossUnitPrice;
  }

  get_DETAIL_PURCHASE_showDetailTotalGrossAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailTotalGrossAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailTotalGrossAmount = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailTotalGrossAmount = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailTotalGrossAmount;
  }

  get_DETAIL_PURCHASE_showDetailTotalTransportAmount(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailTotalTransportAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowTotalTransportAmount');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailTotalTransportAmount = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailTotalTransportAmount = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailTotalTransportAmount;
  }

  get_DETAIL_PURCHASE_enableEditOnInvalidState(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_EnableEditOnInvalidState === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'EnableEditOnInvalidState');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_EnableEditOnInvalidState = settingValue;
      } else {
        this.DETAIL_PURCHASE_EnableEditOnInvalidState = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_EnableEditOnInvalidState;
  }

  get_DETAIL_PURCHASE_showDetailBuyersCostCenter(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailBuyersCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowBuyersCostCenter');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailBuyersCostCenter = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailBuyersCostCenter = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailBuyersCostCenter;
  }

  get_DETAIL_PURCHASE_showDetailDistributionButton(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDetailDistributionButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowDistributionButton');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showDetailDistributionButton = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDetailDistributionButton = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDetailDistributionButton;
  }

  get_DETAIL_PURCHASE_showOrderProcess(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showOrderProcess === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowOrderProcess');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showOrderProcess = settingValue;
      } else {
        this.DETAIL_PURCHASE_showOrderProcess = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showOrderProcess;
  }

  get_DETAIL_PURCHASE_showOrderFraction(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showOrderFraction === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowOrderFraction');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showOrderFraction = settingValue;
      } else {
        this.DETAIL_PURCHASE_showOrderFraction = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showOrderFraction;
  }

  get_DETAIL_SALE_showOrderProcess(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showOrderProcess === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowOrderProcess');
      if (settingValue != null) {
        this.DETAIL_SALE_showOrderProcess = settingValue;
      } else {
        this.DETAIL_SALE_showOrderProcess = defaultValue;
      }
    }
    return this.DETAIL_SALE_showOrderProcess;
  }

  get_DETAIL_SALE_showOrderFraction(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showOrderFraction === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowOrderFraction');
      if (settingValue != null) {
        this.DETAIL_SALE_showOrderFraction = settingValue;
      } else {
        this.DETAIL_SALE_showOrderFraction = defaultValue;
      }
    }
    return this.DETAIL_SALE_showOrderFraction;
  }

  get_DETAIL_PURCHASE_showPriceUnit(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showPriceUnit === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowPriceUnit');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_showPriceUnit = settingValue;
      } else {
        this.DETAIL_PURCHASE_showPriceUnit = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showPriceUnit;
  }

  get_DETAIL_SALE_showPriceUnit(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_showPriceUnit === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'ShowPriceUnit');
      if (settingValue != null) {
        this.DETAIL_SALE_showPriceUnit = settingValue;
      } else {
        this.DETAIL_SALE_showPriceUnit = defaultValue;
      }
    }
    return this.DETAIL_SALE_showPriceUnit;
  }

  get_DETAIL_SALE_hideDetailOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_hideDetailOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'HideDetailOtherReferences');
      if (settingValue != null) {
        this.DETAIL_SALE_hideDetailOtherReferences = settingValue;
      } else {
        this.DETAIL_SALE_hideDetailOtherReferences = defaultValue;
      }
    }
    return this.DETAIL_SALE_hideDetailOtherReferences;
  }

  get_DETAIL_PURCHASE_hideDetailOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_hideDetailOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HideDetailOtherReferences');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_hideDetailOtherReferences = settingValue;
      } else {
        this.DETAIL_PURCHASE_hideDetailOtherReferences = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_hideDetailOtherReferences;
  }

  get_DETAIL_PURCHASE_useAccountingConfig(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_useAccountingConfig === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'UseAccountingConfig');
      if (settingValue != null) {
        this.DETAIL_PURCHASE_useAccountingConfig = settingValue;
      } else {
        this.DETAIL_PURCHASE_useAccountingConfig = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_useAccountingConfig;
  }

  get_DETAIL_PURCHASE_getProductServiceType(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_getProductServiceType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'GetProductServiceType');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_getProductServiceType = settingValue;
      } else {
        this.DETAIL_PURCHASE_getProductServiceType = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_getProductServiceType;
  }

  get_DETAIL_PURCHASE_showDatesPanel(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showDatesPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowDatesPanel');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_showDatesPanel = settingValue;
      } else {
        this.DETAIL_PURCHASE_showDatesPanel = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showDatesPanel;
  }

  get_DETAIL_PURCHASE_distributionButtonLabel(defaultValue: string): string {
    if (typeof this.DETAIL_PURCHASE_distributionButtonLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'DistributionButtonLabel');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_distributionButtonLabel = settingValue;
      } else {
        this.DETAIL_PURCHASE_distributionButtonLabel = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_distributionButtonLabel;
  }

  get_DETAIL_PURCHASE_showLoadAccountingButton(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showLoadAccountingButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowLoadAccountingButton');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_showLoadAccountingButton = settingValue;
      } else {
        this.DETAIL_PURCHASE_showLoadAccountingButton = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showLoadAccountingButton;
  }

  get_DETAIL_PURCHASE_showApprovedProposalNumber(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showApprovedProposalNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowApprovedProposalNumber');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_showApprovedProposalNumber = settingValue;
      } else {
        this.DETAIL_PURCHASE_showApprovedProposalNumber = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showApprovedProposalNumber;
  }

  get_DETAIL_PURCHASE_maxSupplierProductCodeLength(defaultValue: number): number {
    if (typeof this.DETAIL_PURCHASE_maxSupplierProductCodeLength === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'MaxSupplierProductCodeLength');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_maxSupplierProductCodeLength = settingValue;
      } else {
        this.DETAIL_PURCHASE_maxSupplierProductCodeLength = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_maxSupplierProductCodeLength;
  }

  get_DETAIL_PURCHASE_showBuyerContractNumber(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_showBuyerContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'ShowBuyerContractNumber');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_showBuyerContractNumber = settingValue;
      } else {
        this.DETAIL_PURCHASE_showBuyerContractNumber = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_showBuyerContractNumber;
  }

  get_DETAIL_PURCHASE_useProductCostCenter(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_useProductCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'UseProductCostCenter');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_useProductCostCenter = settingValue;
      } else {
        this.DETAIL_PURCHASE_useProductCostCenter = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_useProductCostCenter;
  }

  get_DETAIL_PURCHASE_useProductReclassificationCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_useProductReclassificationCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'UseProductReclassificationCode');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_useProductReclassificationCode = settingValue;
      } else {
        this.DETAIL_PURCHASE_useProductReclassificationCode = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_useProductReclassificationCode;
  }

  get_DETAIL_PURCHASE_hideEANCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_hideEANCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HideEANCode');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_hideEANCode = settingValue;
      } else {
        this.DETAIL_PURCHASE_hideEANCode = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_hideEANCode;
  }

  get_DETAIL_PURCHASE_hideBuyerProductCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_hideBuyerProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HideBuyerProductCode');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HideBuyerProductCodeSP');

      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.DETAIL_PURCHASE_hideBuyerProductCode = settingValue;
      } else if (settingValueSP) {
        this.DETAIL_PURCHASE_hideBuyerProductCode = settingValueSP;
      } else {
        this.DETAIL_PURCHASE_hideBuyerProductCode = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_hideBuyerProductCode;
  }
  get_DETAIL_PURCHASE_hideSupplierProductCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_hideSupplierProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HideSupplierProductCode');
      let settingValueSP = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HideSupplierProductCodeSP');

      if (!this._authenticationService.session.company.ServiceProvider && settingValue) {
        this.DETAIL_PURCHASE_hideSupplierProductCode = settingValue;
      } else if (settingValueSP) {
        this.DETAIL_PURCHASE_hideSupplierProductCode = settingValueSP;
      } else {
        this.DETAIL_PURCHASE_hideSupplierProductCode = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_hideSupplierProductCode;
  }

  get_DETAIL_SALE_disableSupplierProductCode(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_disableSupplierProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'DisableSupplierProductCode');
      if (settingValue !== null) {
        this.DETAIL_SALE_disableSupplierProductCode = settingValue;
      } else {
        this.DETAIL_SALE_disableSupplierProductCode = defaultValue;
      }
    }
    return this.DETAIL_SALE_disableSupplierProductCode;
  }

  get_DETAIL_PURCHASE_hidePanelObservations(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_hidePanelObservations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'HidePanelObservations');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_hidePanelObservations = settingValue;
      } else {
        this.DETAIL_PURCHASE_hidePanelObservations = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_hidePanelObservations;
  }

  get_DETAIL_PURCHASE_useHalfColumn(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_PURCHASE_useHalfColumn === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'UseHalfColumn');
      if (settingValue !== null) {
        this.DETAIL_PURCHASE_useHalfColumn = settingValue;
      } else {
        this.DETAIL_PURCHASE_useHalfColumn = defaultValue;
      }
    }
    return this.DETAIL_PURCHASE_useHalfColumn;
  }

  get_DETAIL_SALE_BuyersOrderLineNumberDisabled(defaultValue: boolean): boolean {
    if (typeof this.DETAIL_SALE_BuyersOrderLineNumberDisabled === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailSale', 'BuyersOrderLineNumberDisabled');
      if (settingValue != null) {
        this.DETAIL_SALE_BuyersOrderLineNumberDisabled = settingValue;
      } else {
        this.DETAIL_SALE_BuyersOrderLineNumberDisabled = defaultValue;
      }
    }
    return this.DETAIL_SALE_BuyersOrderLineNumberDisabled;
  }

  get_showUserGroups(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showUserGroups === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'ShowUserGroups');
      if (settingValue != null) {
        this.PURCHASE_showUserGroups = settingValue;
      } else {
        this.PURCHASE_showUserGroups = defaultValue;
      }
    }
    return this.PURCHASE_showUserGroups;
  }

  get_filterBySpecificUserGroup(defaultValue: string): string {
    if (typeof this.PURCHASE_FilterBySpecificUserGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoicePurchase', 'FilterBySpecificUserGroup');
      if (settingValue != null) {
        this.PURCHASE_FilterBySpecificUserGroup = settingValue;
      } else {
        this.PURCHASE_FilterBySpecificUserGroup = defaultValue;
      }
    }
    return this.PURCHASE_FilterBySpecificUserGroup;
  }

  get_blockInvoiceStates(defaultValue: any[]): any[]{
    if (typeof this.blockInvoiceStates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'BlockInvoiceStates');
      if (settingValue != null) {
        this.blockInvoiceStates = settingValue;
      } else {
        this.blockInvoiceStates = defaultValue;
      }
    }
    return this.blockInvoiceStates;
  }

  getInvoiceDetailWorkflowsToList(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller
      + '/GetInvoiceDetailWorkflowStepsToList?id=' + id)
      .pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_detailStatesBlock(defaultValue: any[]): any[]{
    if (typeof this.detailStatesBlock === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceDetailPurchase', 'DetailStatesBlock');
      if (settingValue != null) {
        this.detailStatesBlock = settingValue;
      } else {
        this.detailStatesBlock = defaultValue;
      }
    }
    return this.detailStatesBlock;
  }

  get_ValidateSupplierCapitalAndRegistration(defaultValue: boolean): boolean{
    if (typeof this.validateSupplierCapitalAndRegistration === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this._authenticationService.getSettingPortal('InvoiceSale', 'ValidateSupplierCapitalAndRegistration');
      if (settingValue != null) {
        this.validateSupplierCapitalAndRegistration = settingValue;
      } else {
        this.validateSupplierCapitalAndRegistration = defaultValue;
      }
    }
    return this.validateSupplierCapitalAndRegistration;
  }

  //#region showGenerateOrderFromInvoice
  /** Setting que define se aparece esta opcao no dropdown da lista de faturas */
  public get showGenerateOrderFromInvoice () {
    this._showGenerateOrderFromInvoice = this._authenticationService.getBooleanSetting(this.showGenerateOrderFromInvoiceDV, this._showGenerateOrderFromInvoice, 'InvoicesPurchases', 'ShowGenerateOrderFromInvoice');
    return this._showGenerateOrderFromInvoice;
  }

  public set showGenerateOrderFromInvoice (boolValue: boolean) {
    this.showGenerateOrderFromInvoiceDV = boolValue;
  }
  //#endregion showGenerateOrderFromInvoice

  //#region showNewPaymentCondition
  /** Indica se é para usar a nova condição de pagamento */
  public get showNewPaymentCondition_Sale () {
    this._showNewPaymentCondition_Sale = this._authenticationService.getBooleanSetting(false, this._showNewPaymentCondition_Sale, 'InvoiceSale', 'ShowNewPaymentCondition');
    return this._showNewPaymentCondition_Sale;
  }

  public get showNewPaymentCondition_Purchase () {
    this._showNewPaymentCondition_Purchase = this._authenticationService.getBooleanSetting(false, this._showNewPaymentCondition_Purchase, 'InvoicePurchase', 'ShowNewPaymentCondition');
    return this._showNewPaymentCondition_Purchase;
  }
  //#endregion showNewPaymentCondition

  //#region showSupplierDocumentNumber
  private _showSupplierDocumentNumberPurchase: boolean;
  
  /** Mostrar nr doc fornecedor (purchase) */
  public get showSupplierDocumentNumberPurchase () {
    return this._showSupplierDocumentNumberPurchase = this._authenticationService.getBooleanSetting(false, this._showSupplierDocumentNumberPurchase, 'InvoicePurchase', 'ShowSupplierDocumentNumber');
  }

  //#region showSupplierDocumentNumber
  private _showSupplierDocumentNumberSale: boolean;

  /** Mostrar nr doc fornecedor (sale) */
  public get showSupplierDocumentNumberSale () {
    return this._showSupplierDocumentNumberSale = this._authenticationService.getBooleanSetting(false, this._showSupplierDocumentNumberSale, 'InvoiceSale', 'ShowSupplierDocumentNumber');
  }
  
  //#endregion showSupplierDocumentNumber
  
  //#region showFiltersOnMessage
  private _showFiltersOnMessage: boolean;
  
  /** Apresentar filtros mensagens (purchase) */
  public get showFiltersOnMessage () {
  return this._showFiltersOnMessage = this._authenticationService.getBooleanSetting(false, this._showFiltersOnMessage, 'InvoicePurchase', 'ShowFiltersOnMessage');
  }
  
  //#endregion showFiltersOnMessage

    //#region messageOnlyLoggedCompany
   private _messageOnlyLoggedCompany: boolean;
  
  /** Permitir mensagens internas  */
  public get messageOnlyLoggedCompany_PURCHASE () {
  return this._messageOnlyLoggedCompany = this._authenticationService.getBooleanSetting(false, this._messageOnlyLoggedCompany, 'InvoicePurchase', 'MessageOnlyLoggedCompany');
  }
  
  //#endregion messageOnlyLoggedCompany
  
  //#endregion  ---------------------- ***  VARIAVEIS DETAILS PURCHASE  *** ---------------------
}
