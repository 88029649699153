// bibliotecas
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, AbstractControl, Validators, FormArray } from '@angular/forms';

// servicos
import { GenericExportService } from '../../../services/generic-export.service';
import { AuthenticationService } from '../../../services/authentication.service';

// modelos
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


// constantes
import { ALIAS, LANGUAGE, SERVICE_URL } from '../../../constants/global';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions, FileTreatmentFunctions } from '../../../modules/treatments.module';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { GenericExport, GenericExportField } from 'src/app/models/generic-export';
import { RepositoryDiskFile } from 'src/app/models/repository-disk-file';
import { DigitalArchiveService } from 'src/app/services/digitalArchive.service';
import { FineComponent } from '../../generic-documents/altice/fine/fine.component';
import { Dropdown } from 'src/app/models/dropdown';
import { CommonService } from 'src/app/services/common.service';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  selector: 'app-export-tab',
  templateUrl: './generic-export-tab.html'
})
export class GenericExportTabComponent implements OnInit, OnDestroy {

  @Input() ownerID: number = 0; // Client ID (Altice)
  @Input() context: string = null; // Context
  @Input() contextID: number = null; // EntityID
  @Input() contextStateID: number = null; // ID do Estado da Entidade
  @Input() allowEdit: boolean = false; // se vai permitir editar campos das exportações
  @Input() fieldsExtensions: GenericFieldExtension[] = []; // valores dos campos dinâmicos relativos ao contexto/entidade
  @Input() contextForm: UntypedFormGroup = null; // Valores do form da entidade para carregar valores dinâmicos
  @Input() objectRef: FineComponent = null; //DS: referencia do objeto para fazer reload aos dados das minutas
  @Input() dropdownsList: Map<string, Dropdown[]> = null;

  destroy$: Subject<boolean> = new Subject<boolean>();

  ckeditorConfig: any = { language: LANGUAGE, extraPlugins: 'divarea' };

  listExports: any[] = [];
  selectedExportID: number;
  selectedExportFilename: string;

  showSignPdfCheckBox: boolean = false;
  showCertificatePdfCheckBox: boolean = false;

  signPdfCheckBox: boolean;
  certificatePdfCheckBox: boolean;

  translateValues: Array<string> = null;

  fields: any[] = [];
  model: GenericFieldExtension[] = [];
  formFields: UntypedFormGroup = null;
  noError: boolean = true; // saber se o formulario tem erros

  //Altice create reference 
  canCreateReferenceField: GenericFieldExtension;
  alticePayKeyField: GenericFieldExtension;

  canCreateReference: boolean = false;
  alticePayKey: boolean = false;

  //AlticePayFields
  alticePayOldForm: string;
  alticePayForm: UntypedFormGroup;
  minValue: GenericFieldExtension = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "MinValue");
  maxValue: GenericFieldExtension = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "MaxValue");
  // value: GenericFieldExtension = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "Value");
  expireDate: GenericFieldExtension = new GenericFieldExtension(0, this.contextID, "AlticePay", DateTimePickerDirective.convertToString(new Date(new Date().setMonth(new Date().getMonth() + 6)), false), null, "ExpireDate");
  entity: GenericFieldExtension = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "Entity");
  reference: GenericFieldExtension = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "Reference");

  //Campo para saber que minuta o Município vai gerar uma referencia.
  referenceGeneratedByClient: boolean = false;


  constructor(private _translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private _exportService: GenericExportService,
    private _commonService: CommonService,
    private _dialog: MatDialog,
    private _errorTreat: ErrorTreatmentFunctions,
    private _fileTreat: FileTreatmentFunctions,
    private formBuilder: UntypedFormBuilder,
    private _digArcService: DigitalArchiveService,) { }


  ngOnInit() {

    //ShowCheckBoxs se:  
    //Processos têm de estar neste estado,
    // tem de ter role ID 407(assin)408(cert)           
    //411 - Decisão ou DP em Validação Cliente
    //431 - ECC Validação Cliente
    //427 - IJ Validação Cliente

    //DS: comentei porque a funcionalidade mudou de sitio 26-01-22

    // if (this.context == "AlticeFine" && (this.contextStateID === 411 || this.contextStateID === 427 || this.contextStateID === 431) && this._authenticationService.session.roles.includes(407)) //Mudar quando houver a role Assinante
    //   this.showSignPdfCheckBox = true;

    // if (this.context == "AlticeFine" && (this.contextStateID === 411 || this.contextStateID === 427 || this.contextStateID === 431) && this._authenticationService.session.roles.includes(408)) //Mudar quando houver a role Certificador
    //   this.showCertificatePdfCheckBox = true;

    if (this.context == 'AlticeFine') {

      this._commonService.getGenericFieldExtension("Partner", this.ownerID, "AlticePayKey").pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.alticePayKeyField = response.ReturnStatus.ReturnObject as GenericFieldExtension;
          if (this.alticePayKeyField != null) {
            if (this.alticePayKeyField.FieldValue.length > 0) {
              this.alticePayKey = true;
              this.initAlticePayFields();
              this.alticePayBuildForm();
            }
          }
        });

    }

    // Obter lista de exportações
    this._exportService.getAll(this.context, this.contextID).pipe(takeUntil(this.destroy$)).subscribe((responseService: ReturnStatusHtml) => {
      if (responseService.ReturnStatus.Successfull) {
        this.listExports = responseService.ReturnStatus.ReturnObject;
      } else { // o que acontece se der erro
        this._errorTreat.treatErrorResponse(responseService);
      }
    });
  }


  initAlticePayFields() {
    this.minValue = new GenericFieldExtension(0, this.contextID, "AlticePay", this.contextForm.get('ValorTotal').value.toString(), null, "MinValue");
    this.maxValue = new GenericFieldExtension(0, this.contextID, "AlticePay", this.contextForm.get('ValorTotal').value.toString(), null, "MaxValue");
    this.expireDate = new GenericFieldExtension(0, this.contextID, "AlticePay", DateTimePickerDirective.convertToString(new Date(new Date().setMonth(new Date().getMonth() + 6)), false), null, "ExpireDate");
    this.entity = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "Entity");
    this.reference = new GenericFieldExtension(0, this.contextID, "AlticePay", "", null, "Reference");
  }

  //buildform do alticepay
  alticePayBuildForm(): void {
    this.alticePayForm = this.formBuilder.group({
      'minValue': [this.minValue.FieldValue, [Validators.maxLength(5), Validators.required]],
      'maxValue': [this.maxValue.FieldValue, [Validators.maxLength(5), Validators.required]],
      // 'value': [this.value.FieldValue, [Validators.maxLength(64), Validators.required]],
      'expireDate': [DateTimePickerDirective.convertToString(this.expireDate.FieldValue, true), [Validators.maxLength(64), Validators.required]],
      'entity': [{ value: this.entity.FieldValue, disabled: true }, [Validators.maxLength(16), Validators.required]],
      'reference': [{ value: this.reference.FieldValue, disabled: true }, [Validators.maxLength(16), Validators.required]]
    });
    // this.alticePayForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onValueChanged(data));
    // this.onValueChanged();


  }

  generatePayment() {

    let minValue = Number((this.alticePayForm.get('minValue').value).replace(',', '.'));
    let maxValue = Number((this.alticePayForm.get('maxValue').value).replace(',', '.'));
    let valorTotal: number = this.contextForm.get('ValorTotal').value;

    if (isNaN(minValue) || minValue == 0) {
      Functions.gritter("O valor minimo tem de ser um numero válido.", 'danger');
      return;
    }
    if (isNaN(maxValue) || maxValue == 0) {
      Functions.gritter("O valor maximo tem de ser um numero válido.", 'danger');
    }
    if (isNaN(valorTotal)) {
      Functions.gritter("O valor total tem de ser um numero válido.", 'danger');
    }

    let expireDate = this.alticePayForm.get('expireDate').value;
    let date = DateTimePickerDirective.convertToDate(expireDate, false);

    if (minValue > maxValue) {
      Functions.gritter("O valor minimo tem de ser menor que o valor maximo.", 'danger');
      return;
    }
    if (maxValue > valorTotal) {
      Functions.gritter("O valor máximo não pode ser maior que o valor da coima.", 'danger');
      return;
    }

    if (date < new Date()) {
      Functions.gritter("A data de expiração não pode ser menor que a data atual.", 'danger');
      return;
    }


    this._exportService.generatePayment(
      minValue.toFixed(2).toString(),
      maxValue.toFixed(2).toString(),
      expireDate,
      this.ownerID,
      this.contextID,
      this.selectedExportID.toString(),
      this.contextForm.get('DocumentNumber').value
    ).pipe(takeUntil(this.destroy$)).subscribe(generatedPaymentResponse => {
      if (generatedPaymentResponse.ReturnStatus.Successfull) {
        let mb = generatedPaymentResponse.ReturnStatus.ReturnObject;

        this.alticePayForm.get('entity').setValue(mb.Entity);
        this.alticePayForm.get('reference').setValue(mb.Reference);

        Functions.gritter(generatedPaymentResponse.ReturnStatus.SuccessMessage, 'success');
        this.objectRef.onReload();
      }
    })
  }

  /**
   * Constroi o formulário da  exportação selecionada
   */
  onChangeExport(event: any) {

    this.formFields = null;

    // Ir buscar os dados da exportação
    this.selectedExportID = event.value;
    if (this.selectedExportID > 0) {

      //DS: 01/04/2022
      //Se o contexto for AlticeFine ir verificar se esta minuta permite criar referencia multibanco se sim , ir buscar os campos a bd 
      if (this.context == 'AlticeFine') {

        this.referenceGeneratedByClient = false;
        this._commonService.getGenericFieldExtension("AlticeFine", this.contextID, "ReferenceGeneratedByClient").pipe(takeUntil(this.destroy$))
          .subscribe(response => {
            let generatedByClient = response.ReturnStatus.ReturnObject as GenericFieldExtension;
            if (generatedByClient != null) {
              this.referenceGeneratedByClient = generatedByClient.FieldValue == this.selectedExportID.toString();
            }
          });

        this.canCreateReference = false;
        this._commonService.getGenericFieldExtension("GenericExport", this.selectedExportID, "CanCreateReference").pipe(takeUntil(this.destroy$))
          .subscribe(response => {
            this.canCreateReferenceField = response.ReturnStatus.ReturnObject as GenericFieldExtension;
            if (this.canCreateReferenceField != null) {

              this.canCreateReference = this.canCreateReferenceField.FieldValue == "True";

              if (this.canCreateReference) {


                this._commonService.getGenericFieldsExtension("AlticePay", this.contextID, "GenericExport_" + this.selectedExportID).pipe(takeUntil(this.destroy$))
                  .subscribe(response => {
                    let resObject = response.ReturnStatus.ReturnObject as GenericFieldExtension[];
                    this.initAlticePayFields();

                    if (resObject.length > 0) {
                      this.minValue = resObject.find(x => x.FieldCode == "MinValue") != undefined ? resObject.find(x => x.FieldCode == "MinValue") : this.minValue;
                      this.maxValue = resObject.find(x => x.FieldCode == "MaxValue") != undefined ? resObject.find(x => x.FieldCode == "MaxValue") : this.maxValue;
                      // this.value = resObject.find(x => x.FieldCode == "Value"); != undefined ? resObject.find(x => x.FieldCode == "Value");: // this;
                      this.expireDate = resObject.find(x => x.FieldCode == "ExpireDate") != undefined ? resObject.find(x => x.FieldCode == "ExpireDate") : this.expireDate;
                      this.entity = resObject.find(x => x.FieldCode == "Entity") != undefined ? resObject.find(x => x.FieldCode == "Entity") : this.entity;
                      this.reference = resObject.find(x => x.FieldCode == "Reference") != undefined ? resObject.find(x => x.FieldCode == "Reference") : this.reference;
                    }

                    this.minValue.Identifier = 'GenericExport_' + this.selectedExportID;
                    this.maxValue.Identifier = 'GenericExport_' + this.selectedExportID;
                    // this.value.Identifier = 'GenericExport_' + this.selectedExportID;
                    this.expireDate.Identifier = 'GenericExport_' + this.selectedExportID;
                    this.entity.Identifier = 'GenericExport_' + this.selectedExportID;
                    this.reference.Identifier = 'GenericExport_' + this.selectedExportID;

                    this.alticePayBuildForm();
                    this.alticePayOldForm = JSON.stringify(this.alticePayForm.getRawValue());

                  });
              }
            }

          });
      }

      // Devolve a exportação e o nome do ficheiro do template
      this._exportService.getFields(this.context, this.selectedExportID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {

          this.formFields = this.formBuilder.group({});

          const genericExport: GenericExport = response.ReturnStatus.ReturnObject.Entity;
          const repositoryFile: RepositoryDiskFile = response.ReturnStatus.ReturnObject.RepositoryFile;

          if (repositoryFile) {
            this.selectedExportFilename = repositoryFile.OriginalFileName.replace('.frx', '.pdf');
          }

          if (genericExport && genericExport.Fields && genericExport.Fields.length > 0) {

            this.fields = genericExport.Fields;

            for (let i = 0; i < this.fields.length; i++) {
              const field: GenericExportField = this.fields[i];

              // Obter o FieldExtension se já existir na entidade para obter o valor do respetivo campo
              const fieldExtension = this.fieldsExtensions.find(x =>
                x.FieldCode === field.FieldCode &&
                x.Identifier === 'GenericExport_' + this.selectedExportID.toString()
              );

              // carregar o campo
              let fieldValue: string = '';
              if (fieldExtension && fieldExtension.FieldValue && fieldExtension.FieldValue.length > 0) {
                //Se já foi registado um valor no FieldsExtensions
                fieldValue = fieldExtension.FieldValue;
              } else if (field && field.DefaultValueField && field.DefaultValueField.length > 0) {
                //Verificar se foi configurado um campo para valor carregar o valor por defeito e obter o valor do form desse mesmo campo
                const control: AbstractControl = this.contextForm.get(field.DefaultValueField);
                if (control) {
                  fieldValue = control.value;
                }
              }

              if (this.dropdownsList != null && field.FieldCode != undefined && field.FieldCode != null && fieldValue != '') {
                fieldValue = this.checkIfNeedToConvertCodeToText(field.FieldCode, fieldValue);
              }

              //Adicionar o control com o valor
              this.formFields.addControl(field.FieldCode,
                new UntypedFormControl({
                  value: fieldValue,
                  disabled: !this.allowEdit
                }));

            }// foreach field

            // tslint:disable-next-line:forin
            for (let instanceName in CKEDITOR.instances) {
              try {
                CKEDITOR.instances[instanceName].removeAllListeners();
                CKEDITOR.instances[instanceName].destroy(true);
                CKEDITOR.instances[instanceName] = null;
                // CKEDITOR.remove(instanceName);
              } catch (e) { }
              // CKEDITOR.replace(instanceName);
            }
          }



        } else {
          this._errorTreat.treatErrorResponse(response);
        }
      });

    }

  }

  onFieldChange() {

  };

  /**
   * Fazer o download do ficheiro do template selecionado para esta entidade
   * @param  {number} entityid
   * @param  {number} exportid
   */
  onDownloadPdf() {
    if (this.selectedExportID > 0) {
      // Verificar se é necessário gravar campos dinâmicos
      // Contruir o form e carregar valores na tabela GenericFieldsExtension em que:
      // Context = this.context
      // ReferenceID = this.contextID
      // Identifier = GenericExport_<this.selectedExportID>
      let valuesList: GenericFieldExtension[] = [];
      let hasChanges: boolean = false;
      // Percorrer os campos da exportação para carregar a lista valores e fazer update
      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i];

        // Obter o FieldExtension se já existir na entidade para obter o ID do respetivo campo
        const oldFieldExtension = this.fieldsExtensions.find(x =>
          x.FieldCode === field.FieldCode &&
          x.Identifier === 'GenericExport_' + this.selectedExportID
        );

        // Obter o valor do form
        const control: AbstractControl = this.formFields.controls[field.FieldCode];

        if (!oldFieldExtension || (oldFieldExtension && oldFieldExtension.FieldValue !== control.value)) {
          hasChanges = true;
          let newFieldExtension: GenericFieldExtension = new GenericFieldExtension(
            oldFieldExtension ? oldFieldExtension.ID : 0,
            this.contextID,
            this.context,
            control.value,
            null,
            field.FieldCode,
            null,
            'GenericExport_' + this.selectedExportID
          );

          valuesList.push(newFieldExtension);
        }
      } // for each field

      if (hasChanges) {
        // Fazer o update e só depois obter o pdf
        this._exportService.saveFields(valuesList).pipe(takeUntil(this.destroy$)).subscribe(saveResponse => {
          if (saveResponse.ReturnStatus.Successfull) {
            return this._exportService.getPdf(this.contextID, this.selectedExportID, this.signPdfCheckBox, this.certificatePdfCheckBox).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
              this._fileTreat.afterDownloadFile(response, this.selectedExportFilename);
            });
          } else {
            this._errorTreat.treatErrorResponse(saveResponse, true);
          }
        });
      } else {
        // Apenas obter o PDF
        return this._exportService.getPdf(this.contextID, this.selectedExportID, this.signPdfCheckBox, this.certificatePdfCheckBox).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this._fileTreat.afterDownloadFile(response, this.selectedExportFilename);
        });
      }
    }
  }

  /**
   * Guardar os valores dos campos do template selecionado para esta entidade
   * @param  {number} entityid
   * @param  {number} exportid
   */
  onSaveFields() {
    if (this.selectedExportID > 0) {
      // Verificar se é necessário gravar campos dinâmicos
      // Contruir o form e carregar valores na tabela GenericFieldsExtension em que:
      // Context = this.context
      // ReferenceID = this.contextID
      // Identifier = GenericExport_<this.selectedExportID>
      let valuesList: GenericFieldExtension[] = [];
      let hasChanges: boolean = false;
      // Percorrer os campos da exportação para carregar a lista valores e fazer update
      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i];

        // Obter o FieldExtension se já existir na entidade para obter o ID do respetivo campo
        const oldFieldExtension = this.fieldsExtensions.find(x =>
          x.FieldCode === field.FieldCode &&
          x.Identifier === 'GenericExport_' + this.selectedExportID
        );

        // Obter o valor do form
        const control: AbstractControl = this.formFields.controls[field.FieldCode];

        if (!oldFieldExtension || (oldFieldExtension && oldFieldExtension.FieldValue !== control.value)) {
          hasChanges = true;
          let newFieldExtension: GenericFieldExtension = new GenericFieldExtension(
            oldFieldExtension ? oldFieldExtension.ID : 0,
            this.contextID,
            this.context,
            control.value,
            null,
            field.FieldCode,
            null,
            'GenericExport_' + this.selectedExportID
          );

          valuesList.push(newFieldExtension);
        }
      } // for each field


      if (this.context == 'AlticeFine') {    

        let field = new GenericFieldExtension(0, this.contextID, "AlticeFine", this.selectedExportID.toString(), null, "ReferenceGeneratedByClient")       
        valuesList.push(field);
        hasChanges = true;

      }

      if (this.context == 'AlticeFine' && this.canCreateReference && this.alticePayOldForm != JSON.stringify(this.alticePayForm.getRawValue()) && this.alticePayForm.get('entity').value === "") {

        this.minValue.FieldValue = this.alticePayForm.get('minValue').value;
        this.maxValue.FieldValue = this.alticePayForm.get('maxValue').value;
        this.expireDate.FieldValue = this.alticePayForm.get('expireDate').value;
        this.entity.FieldValue = this.alticePayForm.get('entity').value;
        this.reference.FieldValue = this.alticePayForm.get('reference').value;

        valuesList.push(this.minValue);
        valuesList.push(this.maxValue);
        valuesList.push(this.expireDate);
        valuesList.push(this.entity);
        valuesList.push(this.reference);

        hasChanges = true;

      }


      if (hasChanges) {
        // Fazer o update e só depois obter o pdf      
        this._exportService.saveFields(valuesList).pipe(takeUntil(this.destroy$)).subscribe(saveResponse => {
          if (saveResponse.ReturnStatus.Successfull) {
            Functions.gritter(saveResponse.ReturnStatus.SuccessMessage, 'success');
            if (this.objectRef != null && this.context == "AlticeFine") {
              this.objectRef.onReload();
            }
          } else {
            this._errorTreat.treatErrorResponse(saveResponse, true);
          }
        });
      } else {
        Functions.gritter('Não houve alteração dos campos!', 'warning');
      }
    }
  }

  checkIfNeedToConvertCodeToText(dropDownID: string, dropDownCode: string) {

    //DS: ir buscar o map correspondente do dropdownID
    let dds: Dropdown[] = this.dropdownsList.get(dropDownID)
    if (dds != null) {
      //procurar no array de dropdowndata o index onde o codigo é igual ao codigo do campo (dropDownCode)
      let i = dds.findIndex(y => y.ItemCode == dropDownCode)
      // Se não existir vamos devolver o nome já associado ao campo
      return i > -1 ? dds[i].ItemName : dropDownCode;

    } else {
      return dropDownCode;
    }

  }

  editFile() {
    if (this.selectedExportID > 0) {
      const url: string = SERVICE_URL + 'FastReport/EditReport/' + this.selectedExportID;
      window.open(url);
    }
  }


  ngOnDestroy(): void {

  }

}
