import { DecimalPipe } from "@angular/common";
import {
  Component,
  OnInit,
  Inject,
  NgZone,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";
import { MatLegacyDialog, MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { TranslateService } from "@ngx-translate/core";
import { Expense } from "src/app/models/expenseNotes";
import { ErrorTreatmentFunctions, FileTreatmentFunctions } from "src/app/modules/treatments.module";

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import {
  UNITARY_PRICE_DECIMAL,
  CURRENCY_DECIMAL,
  CURRENCY_SYMBOL,
  QUANTITY_DECIMAL,
} from "../../constants/global";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { ChooseModalComponent } from "../shared/choose-modal/choose-modal.component";
import { ChooseModalParam } from "src/app/models/choose-modal-param";
import { TranslateValueService } from "src/app/services/translate-value.service";
import { ValueAddedTax } from "src/app/models/value-added-tax";
import { UnitOfMeasure } from "src/app/models/unit-of-measure";
import { FileItem, FileUploader } from "ng2-file-upload";
import { DigitalArchiveService } from "src/app/services/digitalArchive.service";
import { Subject } from "rxjs";
import { ExpenseService } from "src/app/services/expense.service";

declare var Functions: any;

@Component({
  templateUrl: "./expense-modal.component.html",
})
export class ExpenseModalComponent implements OnInit, OnDestroy {
  public expenseForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  formDisabled: boolean; // para saber se os detalhes sales se podem editar ou sao so de consulta;

  allowEdit: boolean = false; // se tem permissoes de edicao
  model: Expense = null; // valores inseridos na bd
  showArqTab_open: boolean = false;

  expenseValueAddedTaxes: Array<ValueAddedTax> = new Array<ValueAddedTax>();

  expenseUnMeasureG: Array<UnitOfMeasure>;

  uploader: FileUploader = new FileUploader({});

  _cur_symbol: string = CURRENCY_SYMBOL;

  private _decimalPipe: DecimalPipe;

  //#region Expenses Arrays
  expensesTypes: Array<any>;
  expensesStates: Array<any>;
  suppliersList: Array<any>; //Armazena o Array de Fornecedores
  //#endregion Expenses Arrays

  validationMessagesExpenses: any;
  fieldNamesExpenses: any;
  parameterValuesExpenses: any;

  currencyDecimalCases =  '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL;

  constructor(
    public _dialogRef: MatLegacyDialogRef<ExpenseModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: any,
    private _translateService: TranslateService,
    private translateValueService: TranslateValueService,
    private _dialog: MatLegacyDialog,
    private _digArcService: DigitalArchiveService,
    private _fileTreat: FileTreatmentFunctions,
    private _expenseService: ExpenseService
  ) {
    this._decimalPipe = new DecimalPipe(_translateService.currentLang);

    // ir buscar os dados que sao enviados no open da model
    this.expenseForm = data.expenseForm;
    this.model = data.model;
    this.allowEdit = data.allowEdit;
    this._cur_symbol = data._cur_symbol;
    this.formDisabled = data.formDisabled;
    this.expensesStates = data.expensesStates;
    this.expensesTypes = data.expensesTypes;
    this.suppliersList = data.suppliersList;
    this.expenseValueAddedTaxes = data.expenseValueAddedTaxes;
    this.expenseUnMeasureG = data.expenseUnMeasureG;
    this.fieldNamesExpenses = data.fieldNamesExpenses;
    this.parameterValuesExpenses = data.parameterValuesExpenses;

    // Se o formulário não for editável, então os detalhes também não são
    if (this.formDisabled) {
      this.expenseForm.disable();
    }
  }

  ngOnInit(): void {
    let that = this;

    if(this._expenseService.totalNetAmountOnModalDisabled && this.expenseForm.enabled)
      this.expenseForm.get('TotalNetAmount').disable();

    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 27) {
        // escape
        that._dialogRef.close(null);
      }
    });
  }

  save() {
    this.expenseForm.markAllAsTouched();
    if (this.expenseForm.valid) {
      if (this.allowEdit) {

        if (this.expenseForm.get("Quantity").value) {
          if (this.expenseForm.valid) {
            this._dialogRef.close({ expenseForm: this.expenseForm }); // nao pode enviar so o value porcausa dos campos disabled
          }
        } else {
          this._dialogRef.close({ expenseForm: this.expenseForm });
        }
      }
    }
  }

  resetForm() {
    this.expenseForm.reset(this.model);
  }

  /**
   * Valida se a taxa de iva deve estar editável consoante se o preço unitário e quantidade estão validos
   * @param expenseForm 
   */
  isTaxRateEnabled() {
    // apenas deixa mexer no campo do iva caso a quantidade e o preco esteja corretos
    const { TotalNetAmount: totalNetAmountControl, TaxRate: taxRateControl } = this.expenseForm.controls;

    if (totalNetAmountControl.invalid) {
      taxRateControl.disable();
      taxRateControl.setValue('');
    }
    else
      taxRateControl.enable();
  }

  /** Quando altera valor da percentagem na tabela de taxas */
  onChangePercentage() {
    const { TaxRate, TotalNetAmount, TaxAmount, TotalPayableAmount } = this.expenseForm.controls;

    if (TaxRate.invalid)
      return;

    let taxRate = this.revert(TaxRate.value);
    let totalNetAmount = this.revert(TotalNetAmount.value);

    let taxAmount = (taxRate / 100) * totalNetAmount;

    TaxAmount.setValue(this.formatCurrency(taxAmount));
    TotalPayableAmount.setValue(this.formatCurrency(totalNetAmount + taxAmount));
  }

  /*
   * Altera/Reverte a Despesa para Despesa Pontal
   */
  toggleRefund() {
    const refundControl = this.expenseForm.controls.Refund;

    refundControl.setValue(!refundControl.value);
  }

  onSelectTab(event: any): void {
    // Fecha ou minimiza todos os DockModals
    // verificar qual a tab selecionada, tem de ser por nome devidos as tabs que podem estar ocultas
    let tab = event.tab.content.viewContainerRef.element.nativeElement.getAttribute("id");

    this.showArqTab_open = false;

    if (tab) {
      switch (tab) {
        case "tabDigitalArchive":
          this.showArqTab_open = true;
          break;
        default:
          break;
      }
    }
  }

  // Abre modal para obter listagem de fornecedores para colocar no campo BuyerIdentifier
  openSupplier() {
    this._translateService.get(["SUPPLIERS", "NIF", "NAME"]).subscribe((response) => {
      let aoColumns = [
        { data: "ID" },
        { data: "TaxNumber", class: "verticalMiddle", title: response["NIF"], },
        { data: "Name", class: "verticalMiddle", title: response["NAME"] },
      ];

      let columnDefs = [
        { targets: [0], visible: false }, // colocar como hidden
        { targets: [-1], orderable: false }, // nao permitir ordenar pelas colunas
      ],
        dialogRef = this._dialog.open(ChooseModalComponent, {
          // dados que vai enviar para o componente da modal
          data: new ChooseModalParam(
            this.suppliersList,
            null,
            response["SUPPLIERS"],
            aoColumns,
            columnDefs,
            null,
            1,
            null
          ),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let index = this.suppliersList.findIndex(
            (r: any) => +r.ID === +result
          );
          this.setSupplier(index);
        }
      });
    });
  }

  /** Quando altera o valor da despesa, fazer efeito no calculo da despesa e nota de despesa */
  onChangeTotalNetAmount() {
    if (this._expenseService.totalNetAmountOnModalDisabled)
      return;

    const totalNetAmountControl = this.expenseForm.get('TotalNetAmount');

    if (totalNetAmountControl.invalid)
      return;

    const { TaxRate: taxRateControl, TaxAmount: taxAmountControl, TotalPayableAmount: totalPayableAmountControl, NetUnitPrice: netUnitPriceControl, Quantity: quantityControl } = this.expenseForm.controls;

    const totalNetAmount = this.revert(totalNetAmountControl.value);
    const quantity = this.revert(quantityControl.value);
    const taxRate = this.revert(taxRateControl.value);

    const taxAmount = (taxRate / 100) * totalNetAmount;
    const totalPayableAmount = totalNetAmount + taxAmount;

    taxAmountControl.setValue(this.formatCurrency(taxAmount));
    totalPayableAmountControl.setValue(this.formatCurrency(totalPayableAmount));

    if (quantity) {
      netUnitPriceControl.setValue(totalNetAmount / quantity)

      // para mostrar validacoes do form control
      if (netUnitPriceControl.untouched)
        netUnitPriceControl.markAsTouched();
    }

    this.calculateTaxAmount(taxRate, totalNetAmount, taxAmountControl, totalPayableAmountControl);
    this.isTaxRateEnabled();
  }

  onQuantityChange() {
    const quantityControl = this.expenseForm.get('Quantity');

    if (quantityControl.invalid)
      return;

    const { TaxRate: taxRateControl, TaxAmount: taxAmountControl, TotalPayableAmount: totalPayableAmountControl, NetUnitPrice: netUnitPriceControl, TotalNetAmount: totalNetAmountControl } = this.expenseForm.controls;

    const totalNetAmount = this.revert(totalNetAmountControl.value);
    const quantity = this.revert(quantityControl.value);
    const taxRate = this.revert(taxRateControl.value);

    if (totalNetAmountControl) {
      netUnitPriceControl.setValue(totalNetAmount / quantity)

      // para mostrar validacoes do form control
      if (netUnitPriceControl.untouched)
        netUnitPriceControl.markAsTouched();
    }

    this.calculateTaxAmount(taxRate, totalNetAmount, taxAmountControl, totalPayableAmountControl);
    this.isTaxRateEnabled();
  }

  onChangeNetUnitPrice() {
    if (!this._expenseService.totalNetAmountOnModalDisabled)
      return;

    const netUnitPriceControl = this.expenseForm.get('NetUnitPrice');

    if (netUnitPriceControl.invalid)
      return;

    const { TaxRate: taxRateControl, TaxAmount: taxAmountControl, TotalPayableAmount: totalPayableAmountControl, TotalNetAmount: totalNetAmountControl, Quantity: quantityControl } = this.expenseForm.controls;

    const totalNetAmount = this.revert(totalNetAmountControl.value);
    const quantity = this.revert(quantityControl.value);
    const taxRate = this.revert(taxRateControl.value);
    const netUnitPrice = this.revert(netUnitPriceControl.value)

    if (quantity && netUnitPrice) {
      totalNetAmountControl.setValue(quantity * netUnitPrice);

      // para mostrar validacoes do form control
      if (totalNetAmountControl.untouched)
        totalNetAmountControl.markAsTouched();
    }

    this.calculateTaxAmount(taxRate, totalNetAmount, taxAmountControl, totalPayableAmountControl);
    this.isTaxRateEnabled();
  }

  
  /** Calcular o valor da quantia de taxa a pagar
   * @param  {number} taxRate percentagem da taxa
   * @param  {number} totalNetAmount total em que a taxa incide
   * @param  {AbstractControl} taxAmountControl control da quantia da taxa
   * @param  {AbstractControl} totalPayableAmountControl control do valor valor total com a taxa
   */
  private calculateTaxAmount(taxRate: number, totalNetAmount: number, taxAmountControl: AbstractControl, totalPayableAmountControl: AbstractControl) {
    const taxAmount = (taxRate / 100) * totalNetAmount;
    const totalPayableAmount = totalNetAmount + taxAmount;

    taxAmountControl.setValue(this.formatCurrency(taxAmount));
    totalPayableAmountControl.setValue(totalPayableAmount);
  }

  /** Quando altera valor da percentagem na tabela de taxas
   * @param  {number} index index da lista de fornecedores
   */
  setSupplier(index: number) {
    this.expenseForm.patchValue({
      SupplierID: this.suppliersList[index].ID,
      SupplierIdentifier: this.suppliersList[index].InternalID,
      SupplierName: this.suppliersList[index].Name,
      SupplierTaxNumber: this.suppliersList[index].TaxNumber
    });
  }

  /**
   * Quando altera o campo do ficheiro, para adicionar o novo ficheiro ao modelo ( se já tiver vai ser substituido)
   * @param event
   */
  addFile(event: any, fileItem: FileItem = null) {
    let file: File;
    if (event) { // chegou aqui atraves da janela normal de adição do ficheiro
      let listFiles: FileList = event.target.files;
      if (listFiles.length > 0) { // se tiver algum ficheiro
        file = listFiles[0]; // so queremos o primeiro
      }
    } else if (fileItem) { // chegou aqui porque fez um drop do ficheiro
      file = fileItem._file;
    }
    if (file) { // se tiver algum ficheiro

      this.expenseForm.controls.FileName.setValue(file.name);
      this.expenseForm.controls.FileType.setValue(file.name.split('.')[1]);
      this.expenseForm.controls.File.setValue(file);

      this.expenseForm.markAsDirty();
      this.expenseForm.updateValueAndValidity();
    }
  }

  /**
   * Quando clica no botao de eliminar o ficheiro introduzido
   * @returns void
   */
  removeFile(): void {
    this.expenseForm.patchValue({
      FileID: 0,
      FileName: null,
      FileType: null,
      File: null
    });
  }

  /**
   * Fazer o download do ficheiro
   * @param  {number} id
   * @param  {string} filename
   */
  onDownloadFile(id: number, filename: string) {
    return this._digArcService.get(id).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this._fileTreat.afterDownloadFile(response, filename);
    });
  }

  
  /** Reverter valores que estao formatados para o seu valor original (1.023,400 -> 1023.4)
   * @param  {string} value valor formatado
   * @returns number valor desformatado
   */
  revert(value: string): number {
    return value ? value.toString().revertDecimal() : 0;
  }

  
  /** Formatar como dinheiro valor recebido
   * @param  {number} value valor a formatar
   * @returns {string} Valor formatado
   */
  formatCurrency(value: number): string {
    return value ? this._decimalPipe.transform(value, this.currencyDecimalCases) : '';
  }

  
  
  ngOnDestroy() {
    if(this._expenseService.totalNetAmountOnModalDisabled && this.expenseForm.enabled)
      this.expenseForm.get('TotalNetAmount').enable();
  }
}
