import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../http-client';
import { SERVICE_URL } from '../../constants/global';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { WorkflowStep } from '../../models/workflow-step';
import { MasterService } from '../master.service';
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';
import { DatatableParameters } from '../../models/datatable-parameters';
import { Dropdown } from '../../models/dropdown';
import { GenericType } from 'src/app/models/generic-type';
import { GenericDocument } from 'src/app/models/generic-document';
import { GenericState } from 'src/app/models/generic-state';
import { AuthenticationService } from '../authentication.service';
import { GenericFieldConfig } from 'src/app/models/generic-field-config';
import { RepositoryDiskFile } from 'src/app/models/repository-disk-file';
import { DataTableFunctions } from 'src/app/modules/datatables.module';

// Contexto
export const CONTEXT_PROCESS = 'Process';
// Permissões

// Estado
export const enum ProcessComplaintsState { SAVED = 474, SUBMITTED = 475 }
export const enum ProcessRequestsState { SUBMITTED = 486, UNDERANALYSIS = 487, AWSWERED = 478 }
// Detalhes
export const enum ProcessDetailState { REGISTERED = 480, PENDING = 490, TREATED = 491 }
// Tipo
export const enum ProcessType {
  ADMINISTRATIVE_OFFENSE = 140,
  SAVED
}


@Injectable()
export class ProcessService {
  private _controller: string = 'GenericDocument';
  private _types: GenericType[];
  private _states: GenericState[];
  private _genericFieldConfigurations: any[] = [];
  private showClassification: boolean;
  private showDepartment: boolean;
  private showDescription: boolean;
  private showSummary: boolean;
  private popupTime: number;
  private closedStateID: number;
  
  // Listas
  public dropdownLists: Dropdown[][] = [];

  filter: any = {};
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[2]], 0);

  datatableParametersPaymentPrevision: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null], 10, [[3, 'desc']], 0);

  context : string = ""
  showDisclaimer: boolean;

  //Lista de parametros por contexto {context: contexoto, parameters: DatatableParameters}
  datatableProcessesParameters: any[] = [];

  private columnInVisible: Array<any> = [];
  private ExtensionColumnsVisible: Array<any> = [];


  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) { }

  

  get(id: number, context: string): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?context=' + context + '&id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getCompany(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetCompany?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getGenericFieldsConfiguration(context: string, tipoDocumento: string, withoutSession: boolean = false): Observable<{ configurations: GenericFieldConfig[], languages: string[] }> {
      return this.http.get(SERVICE_URL + this._controller + '/GetGenericFieldsConfiguration?context=' + context 
         + '&withoutSession=' + withoutSession + '&tipoDocumento=' + tipoDocumento)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._genericFieldConfigurations[context] = responseReturn.ReturnStatus.ReturnObject as { configurations: GenericFieldConfig[], languages: string[] };
              return this._genericFieldConfigurations[context];
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
  }

  add(model: GenericDocument, files: Array<File>, filesModel: Array<RepositoryDiskFile>, context: string): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('entity', JSON.stringify(model));

    files.forEach(file => {
      if(file){
        formData.append('file', file, file.name)
      }
    });

    formData.append('fileModel', JSON.stringify(filesModel))



    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));


  }

  update(model: GenericDocument, context: string): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number, context: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?context=' + context + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submit(context: string, id: number = 0): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitDocument', { context: context, id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  close(context: string, id: number = 0): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/CloseDocument', { context: context, id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  submitSaveUpdate(context: string, model: GenericDocument, files: Array<File>, filesModel: Array<RepositoryDiskFile>): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('entity', JSON.stringify(model));

    files.forEach(file => {
      if(file){
        formData.append('file', file, file.name)
      }
    });

    formData.append('fileModel', JSON.stringify(filesModel))

    return this.http.post(SERVICE_URL + this._controller + '/SubmitSaveDocument', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  closeUpdate(context: string, model: GenericDocument){
    let formData: FormData = new FormData();
    formData.append('context', JSON.stringify(context));
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/CloseUpdateDocument', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  // Devolver excel dos documentos
  exportListToExcel(params: any, context: string): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelList', {
      context: context,
      parameters: params,
      beginDate: this.filter.BeginDate ? this.filter.BeginDate : null,
      endDate: this.filter.EndDate ? this.filter.EndDate : null,
    });
  }

  // Devolver excel de um documento
  exportToExcel(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcel?context=' + CONTEXT_PROCESS + '&id=' + id);
  }

  // Devolver pdf de um documento
  exportToPDF(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDF?context=' + CONTEXT_PROCESS + '&id=' + id);
  }

  getStates(context:string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Common/GetGenericStates?context=' + context)
      .pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  getActiveTypes(context:string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Common/GetGenericTypesActive?context=' + context)
      .pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
  }
 
  //#endregion Listas de Valores

  //#region Validações
  getAllByFieldValue(fieldCode: string, fieldValue: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllByFieldValue?context=' + CONTEXT_PROCESS + '&fieldCode=' + fieldCode + '&fieldValue=' + fieldValue)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Validações

  getFieldsChanges(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetFieldsChanges?context=' + CONTEXT_PROCESS + '&id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
    * Obter lista de utilizadores por perfil associados a um dado documento
    * @param  {number} filterByRole
    * @returns Observable
    */
  getDocumentUsers(documentID: number, filterRoleID: number = null): Observable<Array<any>> {

    let request: string = '/GetDocumentUsers?documentID=' + documentID;
    if (filterRoleID > 0) {
      request += '&filterRole=' + filterRoleID;
    }

    return this.http.get(SERVICE_URL + 'AlticeFine' + request).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  get_showDisclaimer(defaultValue: boolean): boolean {
    if (typeof this.showDisclaimer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Process', 'ShowDisclaimer');
      if (settingValue != null) {
        this.showDisclaimer = settingValue;
      } else {
        this.showDisclaimer = defaultValue;
      }
    }
    return this.showDisclaimer;
  }

  getWorkflowSteps(id: number, context: string): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?context=' + context + '&id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  applyWorkflowStep(id: number, context: string, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { context: context, id: id, stepID: stepID, notes: notes })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addStepsApprover(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  getImageFile(mediaID: number, width?: number, height?: number, mediaVersionID?: number, browserSupport?: boolean): Observable<any> {
    return this.http.getFile(SERVICE_URL + 'ImageRepository/GetImage?id=' + mediaID + '&width=' + width + '&height=' + height + (mediaVersionID ? '&mediaVersionID=' + mediaVersionID : '') + (browserSupport ? '&browserSupport=true' : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_showClassification(defaultValue: boolean, context: string): boolean {
      let settingValue = this.authenticationService.getSettingPortal(context, 'ShowClassification');
      if (settingValue != null) {
        this.showClassification = settingValue;
      } else {
        this.showClassification = defaultValue;
      }
      return this.showClassification; 
  }

  get_showDepartment(defaultValue: boolean, context: string): boolean {
    let settingValue = this.authenticationService.getSettingPortal(context, 'ShowDepartments');
    if (settingValue != null) {
      this.showDepartment = settingValue;
    } else {
      this.showDepartment = defaultValue;
    }
    return this.showDepartment; 
}

  get_showDescription(defaultValue: boolean, context: string): boolean {
      let settingValue = this.authenticationService.getSettingPortal(context, 'ShowDescription');
      if (settingValue != null) {
        this.showDescription = settingValue;
      } else {
        this.showDescription = defaultValue;
      }
    return this.showDescription; 
  } 

  get_showSummary(defaultValue: boolean, context: string): boolean {
    let settingValue = this.authenticationService.getSettingPortal(context, 'ShowSummary');
    if (settingValue != null) {
      this.showSummary = settingValue;
    } else {
      this.showSummary = defaultValue;
    }
    return this.showSummary; 
  } 

  get_popupTime(defaultValue: number, context: string): number {
    let settingValue = this.authenticationService.getSettingPortal(context, 'PopupTime');
    if (settingValue != null) {
      this.popupTime = settingValue;
    } else {
      this.popupTime = defaultValue;
    }
    return this.popupTime; 
  } 

  
  get_closedStateID(defaultValue: number, context: string): number {
    let settingValue = this.authenticationService.getSettingPortal(context, 'ClosedStateID');
    if (settingValue != null) {
      this.closedStateID = settingValue;
    } else {
      this.closedStateID = defaultValue;
    }
    return this.closedStateID; 
  } 

  get_invisibleColumns(defaultValue: Array<number>, columnsNames: Array<string>, context: string): Array<number> {

    //Validar se já existe o setting para este contexto
    let setting: any = this.columnInVisible.find(x=> x.context == context);
    if (!setting)
    {
      //O setting tem de guardar o contexto e o respetivo valor (context e value)
      setting = { context: context, value: '' };

      let settingValue = this.authenticationService.getSettingPortal(context, 'ColumnVisible');
      let settingValueSP = this.authenticationService.getSettingPortal(context, 'ColumnVisibleSP');

      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        setting.value = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValueSP);
      } else if (settingValue != null) {
        setting.value = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
      } else {
        setting.value = defaultValue;
      }

      //Adiconar o setting carregado à lista de settings para todos os contextos
      this.columnInVisible.push(setting);
    }
    return setting.value;
  }

  get_visibleExtensionColumns(defaultValue: any[], context: string): any[] {

    //Validar se já existe o setting para este contexto
    let setting: any = this.ExtensionColumnsVisible.find(x=> x.context == context);
    if (!setting)
    {
      //O setting tem de guardar o contexto e o respetivo valor (context e value)
      setting = { context: context, value: '' };

      let settingValue = this.authenticationService.getSettingPortal(context, 'ExtensionColumnsVisible');
      let settingValueSP = this.authenticationService.getSettingPortal(context, 'ExtensionColumnsVisibleSP');

      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        setting.value = settingValueSP;
      } else if (settingValue != null) {
        setting.value = settingValue;
      } else {
        setting.value = defaultValue;
      }

      //Adiconar o setting carregado à lista de settings para todos os contextos
      this.ExtensionColumnsVisible.push(setting);
    }
    return setting.value;
  }

  getUsers(){
    return this.http.get(SERVICE_URL + 'User/GetAll').pipe(map((response) => {
      try {
        let responseReturn = this._masterService.convertToReturnStatusHtml(response);
        if (responseReturn.ReturnStatus.Successfull) {
          return responseReturn.ReturnStatus.ReturnObject;
        } else {
          this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
        }
      } catch (error) {
        this._masterService.handleError(error);
      }
    }));
  }
}
