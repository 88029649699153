import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Dropdown } from '../../../models/dropdown';
import { DropdownSelectorModalComponent } from '../dropdown-selector-modal/dropdown-selector-modal.component';
import { NgControl, ControlValueAccessor, FormControl } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { drop } from 'lodash';
import { InventoryRoutingModule } from '../../inventory/inventory-routing.module';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './dropdown-selector-modal-html.html',
  selector: 'dropdown-selector-modal'
})
export class DropdownSelectorModalHtmlComponent implements ControlValueAccessor {

  dropdown: any;
  firstLoad: boolean = true;

  //Parametros de entrada   
  @Input() placeHolder: string;
  @Input() formControlName: string = "";
  @Input() DropDownID: string;
  @Input() dropdownTitle: string;
  @Input() showCode: boolean = true;
  @Input() showName: boolean = true;
  @Input() editMode: boolean = true;
  @Input() displayCodeInsteadOfDescription = false;
  @Input() dropdownList: Dropdown[];
  @Input() setDefaultValue: boolean;
  @Input() parentCode: string = null;
  @Input() allowClean: boolean = false;

  //Parametros de saida
  @Output() itemName: string;
  @Output() itemCode: string;

  //Event
  @Output() onChangeDropdown = new EventEmitter();

  protected onChange: any = Function.prototype;
  protected onTouched: any = Function.prototype;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private dialog: MatDialog,
    private commonService: CommonService,
    @Optional() public formControl: NgControl) {
    formControl.valueAccessor = this;
  }

  ngOnInit(): void {
  }

  public setItemName(value: string, DropDownID: string, parentCode: string = null) {
    //se o valor vier a null e não for para carregar um valor por defeito
    //permite programaticamente colocar null nas dropdowns
    if(!value && !this.setDefaultValue){
      this.itemName = '';
    }
    //se o valor for diferente de null e o dropdownId também
    else if (value && DropDownID) {
      this.commonService.getDropdownDataByCode(DropDownID, value, parentCode).subscribe(dropdown => {
        if (dropdown != null && !this.displayCodeInsteadOfDescription) {
          this.itemName = dropdown.ItemName;

        } else if (dropdown != null && this.displayCodeInsteadOfDescription) {
          this.itemName = dropdown.ItemCode;
        } else {
          this.itemName = '';
        }
      });
    } 
    //se o valor vier a null, mas a lista tiver apenas 1 elemento e a flag setDefaultValue estiver a true 
    //vamos colocar esse valor da lista por defeito
    else if (this.editMode && DropDownID && this.setDefaultValue) {
      this.commonService.getDropdownData(DropDownID, parentCode).subscribe(dropdown => {
        if (dropdown != null && dropdown.length === 1) {
          if (!this.displayCodeInsteadOfDescription) {
            this.itemName = dropdown[0].ItemName;
            this.formControl.control.setValue(dropdown[0].ItemCode);
          } else {
            this.itemName = dropdown[0].ItemCode;
            this.formControl.control.setValue(dropdown[0].ItemCode);            
          }

        }
      });
    } 
  }

  showDropDownSelectorModal() {
    if (this.dropdownList && this.dropdownList.length > 0) {
      let dialogRef = this.dialog.open(DropdownSelectorModalComponent, {
        width: '40%',
        data: {
          dropdown: this.dropdownList,
          title: this.dropdownTitle,
          enabled: true,
          showCode: this.showCode,
          showName: this.showName,
          dropdownValue: this.itemName,
          allowClean: this.allowClean
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let drop = result as Dropdown;
          this.itemCode = drop.ItemCode;
          this.displayCodeInsteadOfDescription != true ? this.itemName = drop.ItemName : this.itemName = drop.ItemCode;
          //desta forma ficamos com o valor guardado no form e não precisamos de emitir um evento para guardar o valor
          this.formControl.control.setValue(this.itemCode);
        }
        else if (result == '')
        {
          this.itemCode = null;
          this.itemName = null;
          this.formControl.control.setValue(null);
        }
      });
    } else {
      this.commonService.getDropdownsList(this.DropDownID, true, this.parentCode).pipe(takeUntil(this.destroy$)).subscribe((response: Dropdown[]) => {
        let dialogRef = this.dialog.open(DropdownSelectorModalComponent, {
          width: '40%',
          data: {
            dropdown: response,
            title: this.dropdownTitle,
            enabled: true,
            showCode: this.showCode,
            showName: this.showName,
            dropdownValue: this.itemName,
            allowClean: this.allowClean
          },
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            let drop = result as Dropdown;
            this.itemCode = drop.ItemCode;
            this.displayCodeInsteadOfDescription != true ? this.itemName = drop.ItemName : this.itemName = drop.ItemCode;
            //desta forma ficamos com o valor guardado no form e não precisamos de emitir um evento para guardar o valor
            this.formControl.control.setValue(this.itemCode);
          }
          else if (result == '')
          {
            this.itemCode = null;
            this.itemName = null;
            this.formControl.control.setValue(null);
          }
        });
      });
    }

  }

  writeValue(obj: any): void {
    //para garantir que temos o valor do DropDownID usamos o Timeout
    setTimeout(() => {
      this.setItemName(obj, this.DropDownID, this.parentCode);
      if (!this.firstLoad) {
        this.onChangeDropdown.emit(obj);
      } else {
        this.firstLoad = false;
      }
    });

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() { }

}
