import { Injectable } from "@angular/core";
import { HttpClientCustom } from "./http-client";
import { MasterService } from "./master.service";
import { ErrorTreatmentFunctions } from "../modules/treatments.module";
import { AuthenticationService } from "./authentication.service";
import { of as observableOf, Observable } from 'rxjs';
import { ReturnStatusHtml } from "../models/returnStatus";
import { SERVICE_URL } from "../constants/global";
import { map } from "rxjs/operators";
import { Role } from "../models/role";
import { InvoiceDetailWorkflow } from "../models/invoiceDetailWorkflow";

@Injectable()
export class InvoiceDetailWorkflowService {

  private _invoiceDetailWorkflowUsers: Array<any>;
  private _invoiceDetailWorkflowRoles: Role[];
  private _controller: string = 'InvoiceDetailWorkflow';
  showCreationPopup: boolean ;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) {
  }

  /**
   * Obter todos workflow's
   * @returns Observable
   */
  getAll(PurchasesOrSales: string, context?: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllWorkflows', { PurchasesOrSales: PurchasesOrSales, context: context }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Obter workflow por id
 * @param  {number} id
 * @returns Observable
 */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

    
  /**
   * Adicionar workflow
   * @param  {InvoiceDetailWorkflow} invoiceDetailWorkflow
   * @returns Observable
   */
  add(invoiceDetailWorkflow: InvoiceDetailWorkflow): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', invoiceDetailWorkflow).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Eliminar workflow
 * @param  {number} id
 * @returns Observable
 */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar workflow
   * @param  {InvoiceDetailWorkflow} invoiceDetailWorkflow
   * @returns Observable
   */
  update(invoiceDetailWorkflow: InvoiceDetailWorkflow): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', invoiceDetailWorkflow).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_showCreationPopup(defaultValue: boolean): boolean {
    if (typeof this.showCreationPopup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('InvoicesDetailWorkflow', 'ShowCreationPopup');
      if (settingValue != null) {
        this.showCreationPopup = settingValue;
      } else {
        this.showCreationPopup = defaultValue;
      }
    }
    return this.showCreationPopup;
  }

     /**
   * Obter utilizadores
   * @returns Observable
   */
  public get invoiceDetailWorkflowUsers(): Observable<Array<any>> {
    if (this._invoiceDetailWorkflowUsers === undefined) {
      return this.http.get(SERVICE_URL + 'User/GetAll').pipe(map((response) => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return this._invoiceDetailWorkflowUsers = responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._invoiceDetailWorkflowUsers);
    }
  }

  /**
   * Obter roles
   * @returns Observable
   */
  public get invoiceDetailWorkflowRoles(): Observable<Role[]> {
    if (this._invoiceDetailWorkflowRoles === undefined) {
      return this.http.get(SERVICE_URL + 'User/GetAllowedRoles').pipe(map((responseRaw: any) => {
        try {
          let response = this._masterService.convertToReturnStatusHtml(responseRaw);

          if (response.ReturnStatus.Successfull) {
            this._invoiceDetailWorkflowRoles = response.ReturnStatus.ReturnObject as Role[];
            return this._invoiceDetailWorkflowRoles.sort((n1: Role, n2: Role) => {
              if (n1.Name > n2.Name) {
                return 1;
              }

              if (n1.Name < n2.Name) {
                return -1;
              }

              return 0;
            });
          } else if (!response.IsAuthenticated) {
            document.location.href = document.location.origin;
          } else {
            return [];
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._invoiceDetailWorkflowRoles.sort((n1: Role, n2: Role) => {
        if (n1.Name > n2.Name) {
          return 1;
        }

        if (n1.Name < n2.Name) {
          return -1;
        }
        return 0;
      }));
    }
  }

}