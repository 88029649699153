import { Optional } from '@angular/core';
import { Message } from './message';

export class CostCenter {
  constructor(
    public ID: number = 0,
    public OwnerID: number = 0,
    @Optional() public Name: string = null,
    @Optional() public Active: boolean = null,
    @Optional() public Inactive: boolean = null,
    @Optional() public LastUpdated: string = null,
    @Optional() public Aggregate: number = null,
    @Optional() public AggregateCode: string = null,
    @Optional() public IntegrationID: string = null,
    public AnalisysAxeID: number = 0,
    // listas
    @Optional() public Locations: CostCenterLocation[] = null,
    @Optional() public Users: CostCenterUser[] = null,
    @Optional() public DocumentTypes: CostCenterDocumentType[] = null,

  ) { }
}

export class CostCenterLocation {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public CostCenterID: number = null,
    @Optional() public LocationID: number = null,
    @Optional() public LocationName: string = null,
    @Optional() public LocationAddress: string = null,
    @Optional() public LocationStreet: string = null,
    @Optional() public LocationPostalCode: string = null,
    @Optional() public LocationCity: string = null

  ) { }
}

export class CostCenterUser {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public CostCenterID: number = null,
    @Optional() public UserID: number = null,
    @Optional() public UserName: string = null,
    @Optional() public UserType: string = null

  ) { }
}

export class CostCenterDocumentType {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public CostCenterID: number = null,
    @Optional() public DocumentType: string = null,
    @Optional() public DocumentTypeName: string = null

  ) { }
}


