export class Media {
    constructor(
        // ID único e PK da entidade.
        // Incrementado automáticamente.
        public ID: number,

        // ID do Product ao qual a imagem pertence.
        public ProductID: number,

        // ID do Product ao qual a imagem pertence.
        public ProductIntegrationID: string,

        // Indicação de imagem por defeito.
        public Isdefault: boolean,

        // Conteudo da imagem Base64.
        public FileContent: any,

        // Extensão da imagem.
        public FileType?: string,

        // Nome original da imagem.
        public OriginalName?: string,

        public imageFile?: Blob,

        public Height?: number,

        public Width?: number,

        public Resolution?: number,

        public Size?: number,

        public Direction?: string,

        public Angle?: string,

        public InOrOut?: string,

        public InfoType?: string,

        // Indicação de imagem por defeito.
        public IsPublic?: boolean,

        // Indicação de imagem para exportar
        public IsExportable?: boolean,

        public LogisticTypeID?: number,

        public DistributionListID?: string,

        public imagePath?: string,

        public thumbnailPath?: string,

        public figure?: string,

        // O ID original do media caso seja uma versão
        public MediaID: number = null,

        // O ID da versão do produto caso seja uma versão
        public ProductVersionHistoryID: number = null
    ) { }
}
