import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';

import { InputColumns, InputTypes } from 'src/app/models/input-columns';
import { FooterRow, Formats, TableColumn, TableFilter, TableFilterRow } from 'src/app/models/table-column';
import { DTColumn, JQueryDataTableParamModel, TableRequest } from 'src/app/models/table-request';
import { TransportOrderControlType } from 'src/app/models/transport-order-control-types';
import { GenericTableService } from 'src/app/services/generic-table.service';
import { TranslateValueService } from 'src/app/services/translate-value.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';

const ELEMENT_DATA: TransportOrderControlType[] = [
    { ID: 1, OrderType: 'A', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 2, OrderType: 'B', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 3, OrderType: 'C', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
    { ID: 4, OrderType: 'D', PrefixProductCode: "", LocationID: "77", WmsControlCode: "teste" },
];


@Component({
    selector: 'generic-table',
    templateUrl: './generic-table.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    styles: [
        '.element-row td { border-bottom-width: 0;}',
        '.element-detail { overflow: hidden; display: flex;}',
        'tr.detail-row {height: 0;}',
        '.element-row-detail {padding-bottom: 0px; padding-top: 0px;}',
        '.w-75 {width: 75px;}',
        '.w-30 {width: 30px;}'
    ],
})


export class GenericTableComponent implements OnInit, AfterViewInit, OnDestroy,OnChanges {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    //Dados para mostrar na Tabela
    @Input("data") data: any;
    //Request para ser Executado no caso de não existir dataset
    @Input("request") request: TableRequest = null;
    //Colunas a serem apresentadas no ecrã
    @Input("displayedColumns") displayedColumns: TableColumn[] = null
    //Colunas que Irão conter Inputs
    @Input("inputColumns") inputColumns: InputColumns[] =
        [{ Name: "OrderType", InputType: InputTypes.Date, ListOptionsCombobox: { List: ELEMENT_DATA, ShowProperty: "OrderType", ValueProperty: "ID" } }];
    //Flag para ativar as checkboxes no inicio da tabela
    @Input("checkbox") checkbox: boolean = false;

    //Flag para ativar a tabela expansivel
    @Input("expandable") expandable: boolean = false;
    //Conteudo de um Ng-template passado pelo componente pai para ser apresentado quando é expandido uma row
    @Input("expandableContent") expandableContent: TemplateRef<any>;

    //Conteudo de um Ng-template passado pelo componente pai para ser apresentado na coluna das actions
    @Input("extraActions") extraActions: TemplateRef<any>;

    // Geral para toda a Tabela
    @Input("allowEdit") allowEdit: boolean = false;

    // Geral para toda a Tabela
    @Input("allowDelete") allowDelete: boolean = false;

    // Geral para toda a Tabela
    @Input("allowView") allowView: boolean = true;
    
    @Input("actions") actions: boolean = true;

    @Input("pageSize") pageSize: number = 25;

    /** colunas selecionadas por defeito quando a tabela é carregada, colocar o nome da coluna que vai ter um key unica, para que consiga selecionar as colunas corretas */
    @Input("selectedRows") alreadySelectedRows: { key?: string, alreadySelectedKeys: any[] } = { key: 'ID', alreadySelectedKeys: [] };

    @Input("footerRows") footerRows: FooterRow[] = [];

    @Input() formGroup: FormGroup;
    @Input() formArrayName: string;

    //Evento que irá ser chamado pelo componente pai para poder editar
    @Output("edit") editRow: EventEmitter<number> = new EventEmitter();
    
    // enviar a row inteira
    @Output("onClickEditRow") onClickEditRow: EventEmitter<any> = new EventEmitter();

    //Evento que irá ser chamado pelo componente pai para poder eliminar
    @Output("delete") deleteRow: EventEmitter<number> = new EventEmitter();

    // enviar a row inteira
    @Output("onClickDeleteRow") onClickDeleteRow: EventEmitter<any> = new EventEmitter();

    //Evento que irá ser chamado pelo componente pai para poder obter as linhas Selecionadas
    @Output("selectedRows") selectedRows: EventEmitter<any> = new EventEmitter();

    @Output("onChangeSelect") onChangeSelectBox: EventEmitter<any> = new EventEmitter();
    @Output("onChangeInput") onChangeInput: EventEmitter<any> = new EventEmitter();

    @Input() sortFunction: (data: any, sortHeaderId: string) => string | number;

    @Input() showSelectAllCheckbox: boolean = true;

    emptyData = new MatTableDataSource([{ empty: "row" }]);
    
    destroy$: Subject<boolean> = new Subject<boolean>();

    //Variavel que vai conter todos os Atributos associados à variavel array
    columnsOfObject: TableFilterRow[];
    //Variavel que vai conter os dados da table e suas definições
    dataSource: MatTableDataSource<any>;
    //Variavel que vai conter o Array das linhas selecionadas
    selection = new SelectionModel<any>(true, []);

    expandedElement: any | null;

    columnsValues: string[];

    filterServer: boolean

    form: FormGroup;

    _decimalPipe: DecimalPipe;

    constructor(private genericService: GenericTableService,
        public translateService: TranslateService ,
        public _translateValueService : TranslateValueService, 
        public _formBuilder : FormBuilder,
        public _dialog : MatDialog) {

        this._decimalPipe = new DecimalPipe(translateService.currentLang);
    }


     //Vai buscar os dados atuais da table para enviar para o BackEnd
    get tablefilterForRequest() : TableFilter{
        const filters = this.columnsOfObject.filter(filter => filter.FilterValue ||  filter.SortDirection);

        filters.forEach((filter, index, array) => array[index].FilterValue = array[index].FilterValue?.toString());

        return new TableFilter(this.paginator.pageIndex+1, this.paginator.pageSize, filters);
    }

    get tablefilterAllDataForRequest() : TableFilter{
        return new TableFilter(1, this.paginator.length, this.columnsOfObject.filter(filter => filter.FilterValue));
    }

    get noData() {
        return this.dataSource.filteredData?.length === 0;
    }


    async ngOnInit() {
        //Se não existir dataset e existir Request ele executa o Request para ir buscar os dados
        if (this.data == null && this.request != null) {
            this.filterServer = true;

            //Still Building...
            //this.request.JQueryDataTableParamModel = new JQueryDataTableParamModel(1, 0, 10, null, null, this.getDTColumns());
            let tableFilter = new TableFilter(1, this.pageSize, this.displayedColumns.filter(dc => dc.DefaultFilterValue).map(dc => new TableFilterRow(dc.ColumnValue, dc.DefaultFilterValue)) )

            let result = await this.genericService.request(this.request, tableFilter).toPromise();
            if (result.ReturnStatus.Successfull) {
                let infoPage = result.ReturnStatus.ReturnObject;
                if(infoPage){
                    this.data = infoPage.Items;
                    this.paginator.length = infoPage.TotalItems; 
                }
            }
            else
                console.warn(result.ReturnStatus.ErrorMessage);
        }
        
        //Para o Caso em que a Tabela nao tem dados preencher a entidade dos filtros
        if((!this.data || this.data.length == 0 || (this.data instanceof AbstractControl && (<FormArray>this.data).controls.length < 1)) && this.displayedColumns)
        {
            this.columnsOfObject = this.displayedColumns.map(e => 
            {
                return new TableFilterRow(e.ColumnValue, e.DefaultFilterValue ?? "",e.InputType, e.SelectList, e.AlignRight, e.Format);
            })

            //2024-01-18 GM para a tabela não rebentar se não tiver dados
            if (this.expandable)
            {
                this.dataSource = new MatTableDataSource([]);
            }
        }

        else if (this.data != null && this.data.length > 0) {
            //Primeiro valor do array que contem todas as propriedades para preencher as colunas
            let valueWithObjectKeys;
            if(this.data instanceof AbstractControl)
            {
                let rawValueForm = (<FormArray>this.data).getRawValue();
                valueWithObjectKeys = rawValueForm[0];
                this.dataSource = new MatTableDataSource(rawValueForm);

                this.form = this.formGroup ?? this._formBuilder.group({ array: this.data });
            }
            else
            {
                valueWithObjectKeys = this.data[0];
                this.dataSource = new MatTableDataSource(this.data);
            }

            if(valueWithObjectKeys)
            {
                this.displayedColumns.forEach(column => {
                    if(column.ColumnValue == 'actions' || column.ColumnValue == 'select' || column.ColumnValue == 'expand'){}
                    else{
                        let columnExist = Object.keys(valueWithObjectKeys).find(e => column.ColumnValue == e);
                        if(!columnExist)
                            console.warn("A coluna das displayedColumns "+column.ColumnValue+" não existe na entidade")
                    }
                })

                this.columnsOfObject = Object.keys(valueWithObjectKeys).map(e => {
                    let displayedColumn = this.displayedColumns.find(col => col.ColumnValue === e);
                    
                    if (displayedColumn)
                        return new TableFilterRow(e, displayedColumn.DefaultFilterValue ?? "", displayedColumn.InputType, displayedColumn.SelectList, displayedColumn.AlignRight,displayedColumn.Format);

                    return new TableFilterRow(e, "");
                });
            }



            let columnsToFormat = this.columnsOfObject.filter(co => co.Format);

            columnsToFormat.forEach(columnToFormat => {
                this.data.forEach(data => {

                    switch (columnToFormat.Format) {
                        case Formats.Date:
                            data[columnToFormat.ObjectKey] = DateTimePickerDirective.convertToString(data[columnToFormat.ObjectKey], false);
                            break;

                        case Formats.DateTime:
                            data[columnToFormat.ObjectKey] = DateTimePickerDirective.convertToString(data[columnToFormat.ObjectKey], true);
                            break;

                        case Formats.Boolean:
                            data[columnToFormat.ObjectKey] = data[columnToFormat.ObjectKey] && data[columnToFormat.ObjectKey].toLowerCase() == 'true' ? this._translateValueService.get('YES') : this._translateValueService.get('NO');
                            break;
                    }

                });
            })            

            if (this.actions && this.displayedColumns && !this.displayedColumns.find(e => e.ColumnValue == 'actions')) {
                //Adiciona a Coluna das Actions no Fim do Array
                this.displayedColumns.push({ ColumnValue: 'actions', ColumnName: 'actions' });
            }

            if (this.expandable && this.displayedColumns && !this.displayedColumns.find(e => e.ColumnValue == 'expand')) {
                //Adiciona a Coluna dos Icons no Inicio do Array
                this.displayedColumns.unshift({ ColumnValue: 'expand', ColumnName: 'expand' });
            }

            if (this.checkbox && this.displayedColumns && !this.displayedColumns.find(e => e.ColumnValue == 'select')) {
                //Adiciona a Coluna das Checkboxes no Inicio do Array
                this.displayedColumns.unshift({ ColumnValue: 'select', ColumnName: 'select' });
            }

            this.initCheckBoxSelection()


            this.generateFooterColumns();
        }

        this.generateFooterColumns();

        this.columnsValues = this.displayedColumns.map(e => e.ColumnValue);


        if (this.dataSource)
            this.dataSource.filterPredicate = this.filterPredicate();
        
        if (this.filterServer) {
            this.sort.sortChange.subscribe((sort: Sort) => {
                const sortedColumn = this.columnsOfObject.find(col => col.ObjectKey === sort.active);

                if (sortedColumn) {
                    this.columnsOfObject.forEach(col => col.SortDirection = null);
                    sortedColumn.SortDirection = sort.direction;

                    this.filter()
                }
            });
        }
    }

    private generateFooterColumns() {
        let footerRowIndex = 0;

        /* this.footerRows.push({
            Condition: !this.dataSource?.data?.length,
            FooterColumns: [{
                FooterColumnName: 'footer-column-no-data',
                Value: `<div class="textAlignCenter">${ this._translateValueService.get('NO_RESULTS_WERE_FOUND') }</div>`,
                Colspan: this.displayedColumns.length
            }],
        }) */

        this.footerRows.forEach((row) => {
            if(row.Condition)
            {
                row.FooterColumns.forEach((column) => {
                    column.FooterColumnName = 'footer-column-' + footerRowIndex;
                    footerRowIndex++;
                });
    
                row.ColumsOrder = row.FooterColumns.map((column) => column.FooterColumnName);
            }
        });
    }

    format(data: any) {
        if (data != null && data.length > 0) {
         
            let columnsToFormat = this.columnsOfObject.filter(co => co.Format);

            columnsToFormat.forEach(columnToFormat => {
                data.forEach(datax => {

                    switch (columnToFormat.Format) {
                        case Formats.Date:
                            datax[columnToFormat.ObjectKey] = DateTimePickerDirective.convertToString(datax[columnToFormat.ObjectKey], false);
                            break;
                        case Formats.Boolean:
                            datax[columnToFormat.ObjectKey] = datax[columnToFormat.ObjectKey] == 'true' ? this._translateValueService.get('YES') : this._translateValueService.get('NO');
                            break;
                    }

                });
            })
        }
    }

    ngAfterViewInit(): void {
        if (this.data != null && this.data.length) {
            if(this.paginator)
                this.paginator._intl.itemsPerPageLabel = this._translateValueService.get("RESULTS_PER_PAGE");
            this.dataSource.paginator = this.paginator;

            // coluna para filtrar por defeito
            const columnToSortByDefault = this.displayedColumns.find(dc => dc.DefaultSort);
            if (columnToSortByDefault)
            {
                this.sort.sort(({ id: columnToSortByDefault.ColumnValue, start: columnToSortByDefault.DefaultSort }) as MatSortable)
            }


            if(this.sortFunction) {
                this.dataSource.sortingDataAccessor = this.sortFunction;
            }
            

            this.dataSource.filter

            this.dataSource.sort = this.sort;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
            
        if(!this.request && changes.data && changes.data.previousValue && changes.data.currentValue && changes.data.currentValue.length > 0)
        {
            this.ngOnInit();
            this.ngAfterViewInit();
        }
        
        if (this.dataSource?.data && changes.alreadySelectedRows?.currentValue || changes.alreadySelectedRows?.previousValue) {
            const key = this.alreadySelectedRows.key;

            let currentSelected = changes.alreadySelectedRows?.currentValue as { key?: string, alreadySelectedKeys: any[] };

            // se estiverem a null inicializar
            if(!currentSelected?.alreadySelectedKeys)
                currentSelected = {key, alreadySelectedKeys: []};

            this.selection.clear();

            const unselected = this.selection.selected.filter(row => !currentSelected.alreadySelectedKeys.includes(row)) ;
            const selected = currentSelected.alreadySelectedKeys;

            unselected.forEach(selectedRow => {
                const row = this.dataSource?.data.find(data => data[key] === selectedRow[key]);
                if (row) { this.selection.deselect(row); }
            });

            selected.forEach(selectedRow => {
                const row = this.dataSource?.data.find(data => data[key] === selectedRow[key]);
                if (row) { this.selection.select(row); }
            });
        }
    }

    edit(row: any) {
        this.editRow.emit(row.ID);
        this.onClickEditRow.emit(row);
    }

    delete(tableRow: any) {
        if (this.form) {
            this.dataSource.data = this.dataSource.data.filter(row => row.ID != tableRow.ID);
        }

        this.deleteRow.emit(tableRow.ID);
        this.onClickDeleteRow.emit(tableRow);
    }

    filterPredicate() {
        return (row: any, filters: string): boolean => {
            let searchObjects = JSON.parse(filters);

            let objectEqualsFilter: boolean = false;

            searchObjects.forEach(searchObject => {
                if (searchObject["FilterValue"]) {
                    objectEqualsFilter = row[searchObject["ObjectKey"]].toString().trim().toLowerCase().indexOf(searchObject["FilterValue"].toString().trim().toLowerCase()) !== -1;
                }
            })

            return objectEqualsFilter;
        };
    }

    filter() {
        // validar se os filtros estao vazios
        let found = this.columnsOfObject.filter(filter => filter.FilterValue);

        if (this.data && this.data.length > 0 && !this.filterServer) {

            if (found?.length > 0) // se encontrou filtro filtrar
            {
                this.dataSource.filter = JSON.stringify(this.columnsOfObject);

                if(this.footerRows.length)
                {
                    let found = this.footerRows.find(row => row.FooterColumns.find(column => column.FooterColumnName == 'footer-column-no-data'));
                    if(found)
                        found.Condition = this.dataSource.filteredData.length === 0;
                }
            }
            else // se nao limpar filtros
                this.dataSource.filter = '';
        }
        else {
            if(found){
                this.genericService.request(this.request, this.tablefilterForRequest).pipe(takeUntil(this.destroy$))
                .subscribe(result =>{
                    if (result.ReturnStatus.Successfull) {
                        let infoPage = result.ReturnStatus.ReturnObject;
                        if(infoPage){
                            this.format(infoPage.Items);
                            this.dataSource.data = infoPage.Items;
                            this.paginator.length = infoPage.TotalItems; 
                        }
                    }

                    if(this.footerRows.length)
                    {
                        let found = this.footerRows.find(row => row.FooterColumns.find(column => column.FooterColumnName == 'footer-column-no-data'));
                        if(found)
                            found.Condition = this.dataSource.filteredData.length === 0;
                    }

                    this.initCheckBoxSelection()
                });
            }
            
        }
    }

    clearFilters(body? : any) {

        if(this.filterServer && this.request){
            this.request.Body = body
        }

        this.columnsOfObject.forEach((column) => {
            column.FilterValue = '';
        })
        this.filter();
    }

    searchButton(body){
        if(this.filterServer && this.request){
            this.request.Body = body
        }

        this.filter();
    }

    //valida se todas as linhas já foram selecionadas
    isAllSelected() {
        const numSelected = this.filterServer ? this.alreadySelectedRows.alreadySelectedKeys.length : this.selection.selected.length;
        const numRows = this.filterServer ? this.paginator.length : this.dataSource.data.length;
        return numSelected === numRows;
    }

    // Seleciona todas as linhas, se já tiverem todas selecionadas limpa
    async masterToggle() {
        if(this.filterServer){
            if(this.isAllSelected()){
                this.selection.clear()
                this.alreadySelectedRows.alreadySelectedKeys = [];
            }
            else{
                //Ir buscar sempre ao backend porque pode existir alteracoes
                // Nao guardar em cache
                let allData = await this.selectAllFromServer();

                //Percorrer todos os dados e adicionar no array AlreadySelectedRows
                allData.forEach(row => {
                    //Adicionar as selcionados no ecra atual
                    this.dataSource.data.forEach(rowInTable => {
                        this.selection.select(rowInTable);
                    })
                    if(this.alreadySelectedRows.alreadySelectedKeys && this.alreadySelectedRows.key && !this.alreadySelectedRows.alreadySelectedKeys.find(e => e[this.alreadySelectedRows.key] == row[this.alreadySelectedRows.key]))
                        this.alreadySelectedRows.alreadySelectedKeys.push(row);
                    }
                );
            }
        }
        else
            this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => {
                                                                this.selection.select(row)
                                                                if(this.alreadySelectedRows.alreadySelectedKeys && this.alreadySelectedRows.key && !this.alreadySelectedRows.alreadySelectedKeys.find(e => e[this.alreadySelectedRows.key] == row[this.alreadySelectedRows.key]))
                                                                    this.alreadySelectedRows.alreadySelectedKeys.push(row);
                                                            });


        //Enviar o evento para o Componente pai para avisar que foi selecionado
        this.selectedRows.emit({selectedRows: [...this.selection.selected], allData: this.dataSource.data});
    }

    //Evento que vai Selecionar a CheckBox
    onCheckBoxChange(row?: any) {
        this.selection.toggle(row);
        
        if(this.alreadySelectedRows.alreadySelectedKeys && this.alreadySelectedRows.key && !this.alreadySelectedRows.alreadySelectedKeys.find(e => e[this.alreadySelectedRows.key] == row[this.alreadySelectedRows.key]))
            this.alreadySelectedRows.alreadySelectedKeys.push(row);

        else if(this.alreadySelectedRows.alreadySelectedKeys && this.alreadySelectedRows.key && this.alreadySelectedRows.alreadySelectedKeys.find(e => e[this.alreadySelectedRows.key] == row[this.alreadySelectedRows.key]))
        {
            this.alreadySelectedRows.alreadySelectedKeys = this.alreadySelectedRows.alreadySelectedKeys.filter(e => e[this.alreadySelectedRows.key] != row[this.alreadySelectedRows.key]);
        }

        this.selectedRows.emit({selectedRows: [...this.selection.selected], allData: this.dataSource.data});
    }

    async selectAllFromServer() : Promise<any[]>{
        let result = await this.genericService.request(this.request,this.tablefilterAllDataForRequest).pipe(takeUntil(this.destroy$)).toPromise();
        if (result.ReturnStatus.Successfull) {
            let infoPage = result.ReturnStatus.ReturnObject;
            if(infoPage){
                return infoPage.Items;
            }
        }

        return [];
    }

    initCheckBoxSelection(){
        //Array Que vai guardar todos os selecionados
        if (this.alreadySelectedRows?.alreadySelectedKeys && this.alreadySelectedRows?.alreadySelectedKeys.length > 0) {
            const selectedKeys = this.alreadySelectedRows.alreadySelectedKeys;
            const key = this.alreadySelectedRows.key;

            if(selectedKeys){

                selectedKeys.forEach(select => {
                    if(this.dataSource.data.find(data => data[key] == select[key]) ){

                        //Quando vai ao Backend
                        //Limpar as referencias anteriormente selecionadas
                        //Para quando vier os novos dados voltar a selecionar
                        if(this.filterServer && this.selection.selected.find(e => e[key] == select[key])){
                            this.selection.deselect(this.dataSource.data.find(data => data[key] == select[key]));
                        }
                        
                        if(!this.selection.selected.find(data => data[key] == select[key]))
                            this.selection.select(this.dataSource.data.find(data => data[key] == select[key]));
                    }
                })

                this.selectedRows.emit({selectedRows: [...this.alreadySelectedRows.alreadySelectedKeys], allData: this.dataSource.data})
                
                if(!this.filterServer){
                    this.dataSource.data = [
                        ...this.dataSource.data.filter(value => this.selection.selected.includes(value)),
                        ...this.dataSource.data.filter(value => !this.selection.selected.includes(value))
                    ]
                }

            }
            
        }
    }


    getArrayType() {
        //Desconstroi o Array para obter o tipo
        type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
        let object = {} as ArrayElement<typeof ELEMENT_DATA>;

        return object;
    }

    getDTColumns() {
        let dtColumns: DTColumn[] = [];
        this.columnsValues.forEach(column => {
            dtColumns.push(new DTColumn(column, null, true, true, null));
        })

        return dtColumns;
    }

    reload(data: any) {

        if(data)
        {
            this.format(data);
        }

        if(!this.dataSource)
            this.dataSource = new MatTableDataSource(this.data);
           
        else if(data)
            this.dataSource.data = data;

    }

    isFormArray()
    {
        return this.data && this.data instanceof AbstractControl && (<FormArray>this.data).controls.length >= 1;
    }

    getFormControl(index,objectProperty)
    {
        let rowControl = (<FormArray>this.form.get(this.formArrayName ?? 'array')).controls[index]
        let propertyControlOfRow = rowControl.get(objectProperty);

        return propertyControlOfRow;
    }

    ngOnDestroy(): void {

    }

}
