import { Injectable, OnDestroy } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MasterService } from '../services/master.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig implements OnDestroy {

  private config: Object = null;
  private env: Object = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private _masterService: MasterService) { }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get('app/configs/env.json').pipe(
        catchError((error: any): any => {
          console.log('Configuration file "env.json" could not be read');
          resolve(true);
          return observableThrowError(error.error || 'Server error');
        }), takeUntil(this.destroy$)).subscribe((envResponse: any) => {
          this.env = envResponse;
          let request: Observable<Object> = null;

          switch (envResponse.env) {
            case 'production': {
              request = this.http.get<Object>('app/configs/config.' + envResponse.env + '.json');
            } break;

            case 'development': {
              this._masterService.enviromenDept = true;
              request = this.http.get<Object>('app/configs/config.' + envResponse.env + '.json');
            } break;

            case 'default': {
              console.error('Environment file is not set or invalid');
              resolve(true);
            } break;
          }

          if (request) {
            request
              .pipe(catchError((error: any) => {
                console.error('Error reading ' + envResponse.env + ' configuration file');
                resolve(error);
                return observableThrowError(error.error || 'Server error');
              }), takeUntil(this.destroy$)).subscribe((responseData: any) => {
                this.config = responseData;
                resolve(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });

    });
  }

  /**
   * Devolve a mensagem do rodapé no login
   * @returns string
   */
  getLoginFooterText(): string {
    let loginFooterText = this.getConfig('LOGIN_FOOTER_TEXT');
    if (!loginFooterText || loginFooterText.length === 0) {
      loginFooterText = '&copy; ' + new Date().getFullYear() + ' ID4Software';
    } else {
      loginFooterText = '&copy; ' + new Date().getFullYear() + ' ' + loginFooterText;
    }

    return loginFooterText;
  }

  /**
   * Devolve o url do rodapé no login
   * @returns string
   */
  getLoginFooterUrl(): string {
    let loginFooterUrl = this.getConfig('LOGIN_FOOTER_URL');
    if (!loginFooterUrl || loginFooterUrl.length === 0) {
      loginFooterUrl = 'http://www.id4software.com/';
    }

    return loginFooterUrl;
  }

  /**
   * Devolve o url dos termos e condições no login
   * @returns string
   */
   getTermsConditionsrUrl(): string {
    let termsConditionsUrl = this.getConfig('TERMS_AUTHENTICATION_URL');
    return termsConditionsUrl;
  }

  /**
   * Devolve se é suposto mostrar um texto de autenticação específico
   * @returns string
   */
   showAuthenticationText(): boolean {
    let showAuthenticationText = this.getConfig('SHOW_AUTHENTICATION_TEXT');
    return showAuthenticationText;
  }

  /**
   * Devolve se é suposto mostrar um texto de autenticação específico
   * @returns string
   */
   showAuthenticationMicrosoft(): boolean {
    let showAuthenticationMicrosoft = this.getConfig('SHOW_AUTHENTICATION_MICROSOFT');
    return showAuthenticationMicrosoft;
  }

  /**
   * Devolve se é suposto mostrar um texto de autenticação específico
   * @returns string
   */
   showAuthenticationGoogle(): boolean {
    let showAuthenticationGoogle = this.getConfig('SHOW_AUTHENTICATION_GOOGLE');
    return showAuthenticationGoogle;
  }

  /**
   * Devolve a label do sistema saml usado
   * @returns string
   */
   authenticationGoogleLabel(): string {
    let authenticationGoogleLabel = this.getConfig('AUTHENTICATION_GOOGLE_LABEL');
    return authenticationGoogleLabel;
  }
  

  ngOnDestroy() { }
}
