import { Component, OnDestroy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DashboardPanel } from 'src/app/models/dashboard-panel';

@Component({
  templateUrl: './graphics-modal.html'
})
export class GraphicsModalComponent implements OnDestroy {
  // Listas
  panels: DashboardPanel[] = [];
  // Paineis Colapsáveis
  collPnNavBarCharts: any[] = [];

  constructor(public dialogRef: MatDialogRef<GraphicsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    // Carregar paineis
    this.panels = data.panels;
  }

  ngOnDestroy() { }
}
