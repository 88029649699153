import { Directive, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[init]'
})
export class InitEventDirective implements OnInit {
    // evento que dispara quando o DOM é criado
    @Output() init: EventEmitter<any> = new EventEmitter<any>();
    public element: ElementRef;
    constructor(element: ElementRef) {
        this.element = element;
    }
    ngOnInit() {
        this.init.emit(this.element.nativeElement);
    }
}
