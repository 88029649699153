import { Injectable, EventEmitter, Output } from '@angular/core';

// para emitir um evento
@Injectable()
export class EventService {
    eventReloadSession: EventEmitter<any> = new EventEmitter();

     // constructor() { }

      /**
       * Vai emitir um evento para fazer o reload da session, utilizado na alteração da empresa e no layout
       * @param  {any} conteudo? - esta a ser utilizado para definir se é necessario fazer reload da janela
       */
      emitReloadSessionEvent(conteudo?: any) {
          this.eventReloadSession.emit(conteudo);
      }
      getReloadSessionEmitter() {
          return this.eventReloadSession;
      }
}
