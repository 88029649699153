import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModelValidators } from '../../../models/validators/validators';
import { TranslateValueService } from '../../../services/translate-value.service';
import { EmailModalParam } from 'src/app/models/email-modal-param';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './email-modal.html'
})
export class EmailModalComponent implements OnInit, OnDestroy {
  values: EmailModalParam = new EmailModalParam();
  submitted: boolean = false;
  noError: boolean = true;
  form: UntypedFormGroup;
  formErrors = {};
  validationMessages = {};
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public dialogRef: MatDialogRef<EmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: EmailModalParam,
    private _translateValueService: TranslateValueService,
    private _cdr: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder) {

      this.validationMessages = {
        'Subject': {
          'required': 'FIELD_REQUIRED_'
        },
        'Emails': {
          'required': 'FIELD_REQUIRED_',
          'email': 'EMAIL_INVALID'
        }
      }
     }

  ngOnInit(): void {
    this.values.Subject_params = this.data && this.data.Subject_params && this.data.Has_subject ? this.data.Subject_params : null;
    this.values.Subject = this.data && this.data.Subject && this.data.Has_subject ? this._translateValueService.translatePipe(this.data.Subject, this.values.Subject_params, this._cdr) : '';
    this.values.Emails = this.data && this.data.Emails ? this.data.Emails : '';
    this.buildForm();
  }

  buildForm(): void {
    this.form = this._formBuilder.group({
      'Subject': [this.values.Subject],
      'Emails': [this.values.Emails, Validators.compose([Validators.required, ModelValidators.emailFormat])]
    });
    if (this.data && this.data.Has_subject) { // só se tiver assunto é que vai ter a validação de ser obrigatorio
      let subject_control = this.form.get('Subject');
      subject_control.setValidators([Validators.compose([Validators.required])]);
      subject_control.updateValueAndValidity();
    }
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form
    this.onValueChanged(); // (re)set validation messages now
  }

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {
          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {
              this.formErrors[field] = messages[key] + ' ';
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  } // fim onValueChanged

  save() {
    this.submitted = true;
    this.noError = true;
    this.onValueChanged(this.form.valueChanges);

    if (this.noError) {
      this.dialogRef.close(this.form.getRawValue());
      return;
    }
  }

  ngOnDestroy() { }
}