
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { AuthenticationService } from './authentication.service';
import { Order, OrderPod, LogisticOrderType, OrderDetail } from '../models/order';
import { MasterService } from './master.service';
// ***__***_________  MODULOS _________ ***__***
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';
import { Role } from '../models/role';
import { OrderConfirmation } from '../models/orderConfirmation';
import { InvoiceDocumentState } from '../models/invoice';
import { Settings } from '../models/settings';

@Injectable()
export class OrderService {

  // Filtros Order Purchase
  dataIniPurchase: string;
  dataFimPurchase: string;
  isHistory: boolean;
  requestNumber: string;
  notReceivedOrders: boolean;
  hasDetailActions: boolean;

  // Filtros Order Purchase For All Companies
  dataIniPurchaseAllCompanies: string;
  dataFimPurchaseAllCompanies: string;
  isHistoryAllCompanies: boolean;
  requestNumberAllCompanies: string;
  notReceivedOrdersAllCompanies: boolean;
  hasDetailActionsAllCompanies: boolean;

  // Filtros Order Sale
  dataIniSale: string;
  dataFimSale: string;
  isHistorySale: boolean;

  datatableParametersPurchases: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersPurchasesAllCompanies: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersSales: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersConfirmations: DatatableParameters = new DatatableParameters([null, null, null, null], 4, null, 0);
  datatableParametersTransnauticaOrders: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[3, 'desc']], 0);
  datatableParametersContractOrders: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);

  filterTransnauticaOrders: any = {
    [LogisticOrderType.PREPARATION]: new Object(),
    [LogisticOrderType.RECEPTION]: new Object()
  };

  // panel's/tab's/ campos encomenda de compra
  private showSupplierPanel: boolean;
  private showOriginPanel: boolean;
  private showContracts: boolean;
  private hideDeliveryPanel: boolean;
  private showInvoiceToPanel: boolean;
  private showEDIMessagePanel: boolean;
  private showBudgetsTab: boolean;
  private showDigitalFileTab: boolean;
  private showMessagesTab: boolean;
  private getDigitalFileTabInfoBeforeOpenTab: boolean;
  private getMessagesTabInfoBeforeOpenTab: boolean;
  private showRequestsTab: boolean;
  private showTransferOrdersTab: boolean;
  private showShowOrderTrackingTab: boolean;
  private showDistributionButton: boolean;
  private showCostCenter: boolean;
  private editableDeliveryPanelFields: boolean;
  private editableInvoiceToPanelFields: boolean;
  private disableOrderType: boolean;
  private showBuyersTransportDocumentNumber_P: boolean;
  private showOrderedBy_P: boolean;
  private showBuyerDepartmentName_P: boolean;
  private showDeliveryTerms: boolean;
  private showBuyerDivisionCode: boolean;
  private showLatestAcceptableDate_P: boolean;
  private showTotalNetAmount_P: boolean;
  private showCurrencyCode_P: boolean;
  private allowsRepeatedProducts: boolean;
  private addPriceListDiscount_P: boolean;
  private useDeliveryDateSchedule: boolean;
  private isRequiredDeliveryPlaceGroup: boolean;
  private isRequiredInvoiceToPlaceGroup: boolean;
  private filterByHistory: boolean;
  private filterDockModalProductsByFamily: boolean;
  private filterDockModalProductsByCategory: boolean;
  private filterDockModalProductsByPricelist: boolean;
  private productQuantityValidations: boolean;
  private showQuantityArrows: boolean;
  private showIncoterm: boolean;
  private showSupplierIntegrationID: boolean;
  private showMeansOfPaymentOnSupplierPanel: boolean;
  private allowDeleteByAdmin: boolean;
  private filterByUser: boolean;
  private possibleStates_P: Array<number>;
  private receptionStates: Array<number>;
  private receptionRole: number;
  private isReceptionNumberInt: boolean;
  private isReceptionHasExtendedEvals: boolean;
  private otisReceptionNumberValidation: boolean;
  private mandatorySubmitRole: number;
  private allowUnblockAllUsers: boolean;
  private showReadDate: boolean;
  private columnInVisible_P: Array<number>;
  private columnInVisible_ForAllCompanies: Array<number>;
  private overwriteDetailDeliveryDate: boolean;
  private defaultOrderedQuantityUOMCode: string;
  private showEmails_P: boolean;
  private hideDeliveryDateNonSP: boolean;
  private deliveryDateRequiredSP: boolean;
  private deliveryDateMinDays: number;
  private showProductsDockModalPrice: boolean;
  private showProductsDockModalUnit: boolean;
  private showProductsWithImage: boolean;
  private showReturnedQuantity: boolean;
  private labelHeader: string;
  private labelDetails: string;
  private labelProductsList: string;
  private initTabIndex: number;
  private autoGenerateOrderNumber: boolean;
  private disablePrices: boolean;
  private showEarliestAcceptableDate: boolean;
  private showMeansOfPayment: boolean;
  private showDaysDue: boolean;
  private showTotalNetAmountDetailsTab: boolean;
  private filterActive: boolean;
  private showOpenDetail: boolean;
  private fillEndDate_P: boolean;
  private showCurrencyExchange_P: boolean;
  private informationMessage_P: string;
  private PURCHASE_showOthersReferencesTypes: boolean;
  private PURCHASE_hasQuantityIncrement: boolean;
  private defaultStartDate_P: number;
  private PURCHASE_FillNotesBasedOnNoteType: boolean;
  private PURCHASEallowEditDocumentNumber: boolean;
  private SALEallowEditDocumentNumber: boolean;
  private PURCHASErequestsUrl: string;
  private PURCHASEshowCARequests: boolean;
  private PURCHASEgetProductServiceType: boolean;
  private PURCHASEdistributionButtonLabel: string;
  private PURCHASEnetUnitPriceLabel: string;
  private showDescriptionAsTextArea_PURCHASE: boolean;
  private showPanelWorkFlow: boolean;
  private PURCHASEshowAccountingVatGroup: boolean;
  private PURCHASEmaxSupplierProductCodeLength: number;
  private PURCHASEshowDistributionVatTreatmentType: boolean;
  private PURCHASEshowDistributionCodMensualizacao: boolean;
  private showProductsAdditionalInfo: boolean;
  private showAttachesIsPublic: boolean;
  private showContractNumber: boolean;
  private showCentralPayment: boolean;
  private showDetailWorkflowCheckboxes: boolean;
  private defaultOrderType: number;
  //JJ 04/09/2022 -> Setting para Esconder a Checkbox "Pago"
  private hidePaidCheckbox: boolean;
  //JJ 05/09/2022 -> Define a Formatação p/ Default dos Date Pickers
  private defaultDateTimeFormat: number;
  //JJ 05/09/2022 -> Define a Formatação p/ Default dos Date Pickers
  private defaultDateTimeFormat_SALE: number;
  //JJ 14/09/2022 -> Esconde o Botão de Exportação Excel
  private hideExcelExportButton_PURCHASE: boolean;
  private departmentMandatory_PURCHASE: boolean;
  private showIsReceived: boolean;

  private SALEshowPanelPaymentConditions: boolean;
  private SALEshowAttachesIsPublic: boolean;
  private SALEshowTaxTypeCodeOther: boolean;
  otherRefFields_P: Array<any>;
  otherRefFields_S: Array<any>;
  private showNotReceivedButton: boolean;
  private showFilterUserHasActions: boolean;
  private recepetionFilterAprovedDetails: boolean;
  private disableDeliveredQuantityFieldBeforeExport: boolean;


  // panel's encomenda de venda
  private showOriginPanel_S: boolean;
  private hideDeliveryPanel_S: boolean;
  private showInvoiceToPanel_S: boolean;
  private showObservationsPanel_S: boolean;
  private showPaymentConditionsPanel_S: boolean;
  private getPaymentConditions_S: boolean;
  private getMeansOfPayment_S: boolean;
  private showTotalsPanel_S: boolean;
  private showEDIMessagePanel_S: boolean;
  private showBudgetsTab_S: boolean;
  private showDigitalFileTab_S: boolean;
  private showMessagesTab_S: boolean;
  private showEarliestAcceptableDate_S: boolean;
  private showLatestAcceptableDate_S: boolean;
  private showTotalNetAmount_S: boolean;
  private showCurrencyCode_S: boolean;
  private showBuyersTransportDocumentNumber_S: boolean;
  private showDeliveryTerms_S: boolean;
  private showBuyerDivisionCode_S: boolean;
  private showBuyerDepartmentCode_S: boolean;
  private showBuyerDepartmentName_S: boolean;
  private possibleStates_S: Array<number>;
  private columnInVisible_S: Array<number>;
  private showMeansOfPayment_S: boolean;
  private showOrderedBy_S: boolean;
  private fillEndDate_S: boolean;
  private showCurrencyExchange_S: boolean;
  private SALE_showOthersReferencesTypes: boolean;
  private AllowSubmitInSales: boolean;
  private AllowDeleteInSales: boolean;
  private SALE_FillNotesBasedOnNoteType: boolean;
  private showEarliestAcceptableDateInDocPanel_S: boolean;
  private showStateHistoryModalUserName_S: boolean;
  private showNewOrderColor: boolean;
  // Detalhes compra
  private showPackagingPanel_P: boolean;
  private showTaxesPanel_P: boolean;
  private showNotesPanel_P: boolean;
  private showAllowancesPanel_P: boolean;
  private showDiscountsPanel_P: boolean;
  private showReferencesPanel_P: boolean;
  private showStatePanel_P: boolean;
  private showObservationsPanel_P: boolean;
  private showFamily_P: boolean;

  private showOrderedQuantityUOMCode_P: boolean;
  private showBonusQuantity_P: boolean;
  private showBonusQuantityUOM_P: boolean;

  private showNetUnitPrice_P: boolean;
  private showTotalNetAmountDetail_P: boolean;

  private showGrossUnitPrice_P: boolean;
  private showTotalGrossAmount_P: boolean;
  private showPackQuantityUOM_P: boolean;
  private showPackUnitsPerLayer_P: boolean;
  private isRequiredDeliveryPlaceGroupDetail: boolean;

  private isRequiredBuyerProductCode: boolean;
  private deliveryDateRequiredDetail: boolean;
  private showDetailDeliveryPanel: boolean;
  private allowCreateEmptyDetail: boolean;
  private showProductsAutomatically: boolean;
  private showInvoiceToTaxNumber: boolean;
  private warnUserWhenUnsavedChanges: boolean;

  private isRequiredDeliveryDate: boolean;
  private isOrderedQuantityUOMCodeRequired: boolean;
  private columnInVisible_Details_P: Array<number>;
  private disableTaxes_P: boolean;
  //private showDiscountNonSP_P: boolean;
  //private showAllowancesNonSP_P: boolean;
  private discountsEditableNonSP_P: boolean;
  private noDetailsPagination: boolean;

  private showDeliveryStatus: boolean;
  private showOtherReferences: boolean;
  private showOtherReferences_S: boolean;
  private showConditionsSummary_P: boolean;
  private showConditionsSummary_S: boolean;
  //private showOtherReferencesCSV: boolean;
  private showTaxTypeCodeOther: boolean;
  private showWmsControlCode: boolean;
  private hideLineStatusOnEdition: boolean;

  // Detalhes venda
  private showPackagingPanel_S: boolean;
  private showDetailDeliveryPanel_S: boolean;
  private showTaxesPanel_S: boolean;
  private showNotesPanel_S: boolean;
  private showAllowancesPanel_S: boolean;
  private showDiscountsPanel_S: boolean;
  private showReferencesPanel_S: boolean;
  private showStatePanel_S: boolean;
  private showFamily_S: boolean;

  private showOrderedQuantityUOMCode_S: boolean;
  private showBonusQuantity_S: boolean;
  private showBonusQuantityUOM_S: boolean;
  private hideCodeClient_SALE: boolean;
  private hideCodeSupplier_SALE: boolean;

  private showNetUnitPrice_S: boolean;
  private showTotalNetAmountDetail_S: boolean;

  private showGrossUnitPrice_S: boolean;
  private showTotalGrossAmount_S: boolean;
  private showPackQuantityUOM_S: boolean;
  private showPackUnitsPerLayer_S: boolean;
  private showCostCenter_S: boolean;
  //private showDiscountNonSP_S: boolean;
  //private showAllowancesNonSP_S: boolean;
  //private showBlockedByCheckbox: boolean;
  private showReceptionReturnedQuantity: boolean;
  private hideReceptionNumber: boolean;
  private getProductServiceType: boolean;
  private useAccountingConfigInList: boolean;
  private receptionEvaluationDefaultValue: string;
  private showReceptionReceivedQuantityDefaultValue: boolean;
  private allowDuplicate: boolean;
  private hideAssignActionsToAnotherUser: boolean;
  private hideFilterModalButton: boolean; //Setting para esconder o Botão que abre a Modal OrderFilter
  private showReturnReasons: boolean;

  //Order Purchase Details - configurações painel de contabilização
  private useAccountingConfig: boolean;
  private showDistributionExpenseAccount: boolean;
  private showDistributionInitiativeCode: boolean;
  private showDistributionActivityCode: boolean;
  private showDistributionFixedAssetRecordType: boolean;
  private showDistributionAmortizationBookCode: boolean;
  private showDistributionVATaccountingGroup: boolean;
  private showDistributionRefactor: boolean;
  private showDistributionAccountingType: boolean;
  private showDistributionDetails: boolean;

  // OrderDetails
  private deliveryDateRequiredNonSP: boolean;
  private isRequiredDeliveryPlaceGroupDetailNonSP: boolean;
  private showDeliveryDateOnBasePanel: boolean;
  private showDeliveryDateOnBasePanel_S: boolean;
  private disableBuyerProductCodeSP: boolean;
  private disableSupplierProductCodeNonSP: boolean;
  private showInvoiceToTaxNumber_S: boolean;
  private showDescriptionAsTextArea_SALE: boolean;

  private disableStandardProductCodeNonSP: boolean;
  private disableProductDescriptionNonSP: boolean;
  private disableOrderedQuantityUomNonSP: boolean;
  private disableNetUnitPriceNonSP: boolean;
  private disableTotalNetAmountNonSP: boolean;
  private disableGrossUnitPriceNonSP: boolean;
  private disableTotalGrossAmountNonSP: boolean;

  private columnInVisible_Details_S: Array<number>;

  private allowDeleteAll: boolean;

  private _orderTypes: Array<any>;
  private _orderStates: Array<any>;
  private _orderDetailStates: Array<any>;
  private _receptionReturnReasons: any[];
  // private _orderSuppliers: Array<any>;
  // private _orderLocations: Array<any>;
  // private _orderTaxesTypes: Array<any>;

  private _repositoryFileAllowDeleteRole: number;

  private _controller: string = 'Order';


  private hideDocumentDateHours: boolean;
  private hideUnidentifiedProductIcon: boolean;
  private hideCreationDatePurchase: boolean;

  //JJ 30/05/2022 -> Responsável pela criação de uma Encomenda através de um Pedido de Cotação
  private createOrderFromQuotation: boolean;

  //JJ 03/06/2022 -> Define se esconde o Fornecedor ao adicionar Produtos à Encomenda
  private hidePurchaseProductsSupplier: boolean;

  //JJ 17/06/2022 -> Setting para adicionar um Button que dá display da imagem do Produto
  private productImageAsPopover: boolean;

  private useUserGroups: boolean; //Define se o Campo UserGroupID se encontra visível

  private filterBySpecificUserGroup: string; //Define se é necessário filtrar os UserGroups por algum valor específico

  private useSupplierLeadtime: boolean; //Popula o Campo 'EarliestAcceptableDate' com Data Atual + Leadtime do Fornecedor (Dias)

  private defaultEarliestDateWaitTime: number; //Popula o Campo 'EarliestAcceptableDate' com: Data Atual + defaultEarliestDateWaitTime (Dias) 

  private useProductCostCenter: boolean; //Setting que define se o Campo Opcional 'CostCenter' é utilizado
  private useProductReclassificationCode: boolean; //Setting que define se o Campo Opcional 'ReclassificationCode' é utilizado

  private validateSupplierAnswer: boolean; //Setting que permite validar a Resposta do Fornecedor

  private responseStates: Array<number>; //Armazena os States que permitem a Resposta do Fornecedor

  private allowedConfirmReceptionOrderTypes: Array<String>; //Armazena os OrderTypes permitidos para Confirmar a Recepção de Encomendas

  private viewSupplierConfirmationResponses: boolean; //Setting que permite analizar a Confirmação de Resposta do Fornecedor

  private popupRequired: boolean; //Setting para que se crie a validção se o popup foi aberto

  private showShippingDatePurchase: boolean // mostrar ou esconde data de remessa no contexto compra

  private showShippingDateSale: boolean // mostrar ou esconde data de remessa no contexto venda

  private showUnitPricePurchase: boolean // mostrar ou esconde unidade de preço no contexto compra

  private hideCodeSupplierPurchase: boolean //mostrar ou esconder codigo de fornecedor no contexto compra
  private hideCodeSupplierClient: boolean //mostrar ou esconder codigo de cliente no contexto compra

  private showUnitPriceSale: boolean // mostrar ou esconde unidade de preço no contexto venda

  private purchaseHideEanCode: boolean; // esconde campo de código ean
  private saleHideEanCode: boolean; // esconde campo de código ean


  //Setting para alterar a Quantidade a Validar/Mostrar para seguir uma Lógica de Hierarquia:
  //(Qtd. Expedida [DespatchedQuantity] -> Qtd. a Entregar [RevisedQuantity] -> Qtd. Encomendada [OrderedQuantity])
  private useHierarchicalOrderDetailsQuantities: boolean;

  private showDispatchedQuantity: boolean; //Adiciona a Coluna "DespatchedQuantity" ao Popup Confirmação Receção
  private showRevisedQuantity: boolean; //Adiciona a Coluna "RevisedQuantity" ao Popup Confirmação Receção
  private showCloseLine: boolean; //Adiciona a Coluna "Close" ao Popup para fechar linha da encomenda
  private hideOrderedQuantity: boolean; //Esconde a Coluna "OrderedQuantity" no Popup Confirmação Receção
  private includeTimeInDates: boolean; //Valida se a Listagem mostra o Time nos DatePickers
  private filterByLocation: boolean; //Valida se a Listagem mostra o Time nos DatePickers
  private includeTimeInDates_SALES: boolean; //Valida se a Listagem mostra o Time nos DatePickers
  private hideExcelExportButton_SALE: boolean; //JJ 14/09/2022 -> Esconde o Botão de Exportação Excel
  private showSupplierResponseButton: boolean; //JJ 11/10/2022 -> Ativa o Botão que permite ao Fornecedor responder à Encomenda

  //MM - 12/09/2022 -> label dos menus
  private labelOrderPurchaseTitle: string;
  private labelOrdersPurchasesTitle: string;

  private PURCHASES_setBeginingOfTheYearAsStartDate: boolean; //Setting que coloca a Data Inicial do Header Filter como o 1º dia do ano
  private PURCHASES_setTodayAsEndDate: boolean; //Setting que coloca a Data Atual como a Data Final
  private PURCHASES_hideExportDropdownMenu: boolean; //Setting para esconder o Menu de Exportações
  private PURCHASES_hideExportExcelButton: boolean; //Setting para esconder o Botão de Exportação Excel
  private allowSelectDeliveryNoteOnReception: boolean; //Setting que permite selecionar o Nº das Guias relacionadas com a Encomenda na receção da mesma

  private guideNotMandatory: boolean; //Setting para defenir como obrigatória a guia de encomenda.

  private SALES_setBeginingOfTheYearAsStartDate: boolean; //Setting que coloca a Data Inicial do Header Filter como o 1º dia do ano
  private SALES_setTodayAsEndDate: boolean; //Setting que coloca a Data Atual como a Data Final
  private SALES_hideExportDropdownMenu: boolean; //Setting para esconder o Menu de Exportações
  private SALES_hideExportExcelButton: boolean; //Setting para esconder o Botão de Exportação Excel
  private validateDetailsDateOnSupplierResponse: boolean; // JJ - 08/10/2022 -> valida se a data nos detalhes é superior ou igual à data atual

  //MM - 23/09/2022 -> esconder campo do tipo de encomenda
  private PURCHASE_hideType: boolean;
  private SALE_hideType: boolean;

  private netUnitPriceLabel_SALE: string;

  //MM - 23/09/2022 -> esconder campo do estado de encomenda
  //private PURCHASE_hideState: boolean;
  private SALE_hideState: boolean;
  private hideState_PURCHASE: boolean;

  //MM - 23/09/2022 -> esconder painel cliente
  private SALE_hideClientPanel: boolean;

  private labelOrderSaleTitle: string;
  private labelOrdersSalesTitle: string;

  private afterSaveRemainOnSamePage: boolean;
  private redirectOnUpdate: boolean;

  private removeSpacers_SALE: boolean;
  private removeSpacers_PURCHASE: boolean;
  private integrateOrders_PURCHASE: boolean;
  private showDetailGroupByCC: boolean;

  // mostrar campo Processo no detalhe
  private _showProcess: boolean;
  //private showProcessDV: boolean = false; // valor por defeito (consegue-se definir outro valor no component neste caso) this.orderService.showProcess = true

  // mostrar campo Fração no detalhe
  private _showFraction: boolean;
  //private showFractionDV: boolean = false; // valor por defeito
  //Esconder butão de confirmação de receção
  showButtonReception: boolean = true;


  validateDetailsDate: boolean; // JJ - 07/10/2022 -> valida se a data nos detalhes é superior ou igual à data atual

  private alwaysShowNIF_PURCHASE: boolean; // mostrar sempre o campo do contribuinte

  private sendNotificationToSupplierCheckBox: Array<number>; //mostrar checkbox para notificar fornecedor consoante o perfil

  private toggleFilterStates_PURCHASE: Array<number>; //mostrar o botao toggle que vai aprensentar os detalhes consoante os estados definidos

  private toggleFilterStates_SALE: Array<number>; //mostrar o botao toggle que vai aprensentar os detalhes consoante os estados definidos

  // estados das faturas que devem aparecer no select
  private _possibleInvoiceStatesOnSelect: Array<number>;

  private showAccountingCode_PURCHASE: boolean;
  private showAccountingCode_SALE: boolean;

  //#region showRequestData - PURCHASE & SALE --------------------------------------------------------------------

  //permitir envio de mensagens internas no contexto do cliente
  private allowInternalMessages_PURCHASE: boolean;

  private validateTaxWithholding_PURCHASE: boolean;
  private optionalDeliveryLocationIdentifier: boolean;
  private hasCrossDocking: boolean;
  private warehouseOriginByBuyerLocations: boolean;
  private warehouseDeliveryByBuyerLocations: boolean;
  private allowClientToCreatePreparationOrder: boolean;
  private showInvoicesTab_PURCHASE: boolean;
  private showCostCenterOnLineDetail_PURCHASE: boolean;
  private getDetailCostCenterName: boolean;
  private showCostCenterDetail: boolean;
  private orderOnlyCatalogProducts: boolean;
  private receiveAllButton: boolean;
  //private orderOnlyEmptyDetail: boolean;
  //private validateUserProfile: boolean;
  private showNewDetail: boolean;
  private showApprovTab: boolean;
  private showTaxArea: boolean;
  private showUOMCodeOnReception: boolean;
  hideOrderSource: boolean;
  allowQuantitiesSuperiorOrders: boolean;
  private mandatoryNotesOnCloseDetail: boolean; //Setting que permite selecionar o Nº das Guias relacionadas com a Encomenda na receção da mesma

  //So permite ReExportar Detalhes que estejam no Estado de Erro
  private reExportReceptionsOnDetailState: Array<number>;

  //#endregion showRequestData - PURCHASE & SALE -----------------------------------------------------------------

  constructor(private http: HttpClientCustom, private authenticationService: AuthenticationService,
    private _masterService: MasterService, private _dataTableF: DataTableFunctions) {
    // tamanho do array = ao nº de colunas no html correspondente
    // this.filter_filters = new Array<string>(20).fill('');
  }

  // #region Encomenda
  public get orderTypes(): Observable<Array<any>> {
    if (this._orderTypes === undefined) {
      return this.getComplements('GetAllTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._orderTypes = response.ReturnStatus.ReturnObject.Types;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._orderTypes);
    }
  }

  public get receptionReturnReasons(): Observable<Array<any>> {
    if (this._receptionReturnReasons === undefined) {
      return this.getComplements('GetAllOrderPodReturnReasons').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._receptionReturnReasons = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._receptionReturnReasons);
    }
  }

  public get orderStates(): Observable<Array<any>> {
    if (this._orderStates === undefined) {
      return this.getComplements('GetAllStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._orderStates = response.ReturnStatus.ReturnObject.States;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._orderStates);
    }
  }


  public get orderDetailStates(): Observable<Array<any>> {
    if (this._orderDetailStates === undefined) {
      return this.getComplements('GetAllDetailStates').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._orderDetailStates = response.ReturnStatus.ReturnObject.States;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._orderDetailStates);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getUrl(endpoint: string, params?: any): string {
    const url = SERVICE_URL + this._controller + '/' + endpoint;
    if (params) {
      const paramsString = Object.keys(params)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
      return url + '?' + paramsString;
    }
    return url;
  }

  getOrderInvoices(orderID: number) {
    return this.http.get(this.getUrl('GetOrderInvoices', { orderID })).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyDetailsWorkflows(ordersIDs: number[], notes: string, toStateIDs: number[]): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyDetailsWorkflowForOrders', { orderIDs: ordersIDs, notes: notes, toStateIDs: toStateIDs }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  /**
   * 
   * @param id 
   * @param isEdition 
   * @param getDetails 
   * @param getCostCenterConfig 
   * @param getDetailCostCenterConfig 
   * @param getSSCC 
   * @param context 
   * @param getProductServiceType 
   * @param getBuyerInfo 
   * @param getMinAndIncQuantities 
   * @param getTaxNumber 
   * @param getAccountingConfig 
   * @param getDetailsAccountingConfig 
   * @param getContractNumber 
   * @param getDetailsIntegrationState 
   * @param getRequestData Indica se na query que é para ir buscar o detalhe da encomenda se também vai buscar o campo de linha de requisição e o campo de numero de documento da requisicão
   * @returns 
   */
  get({ id, isEdition, getDetails, getCostCenterConfig, getDetailCostCenterConfig = false, getSSCC = false, context = null, getProductServiceType = false, getBuyerInfo = false, getMinAndIncQuantities = false, getTaxNumber = false, getAccountingConfig = false, getDetailsAccountingConfig = false, getContractNumber = false, getDetailsIntegrationState = false, getRequestData = false, getCrossDocking = false, getDetailCostCenterName = false, getPaymentCondition = false, getProductRetention = false, filterApprovedDetails: filterApprovedDetails = false, filterReceivedDetails = false, getMeansOfPayment = false}: 
    { id: number; isEdition: boolean; getDetails: boolean; getCostCenterConfig: boolean; getDetailCostCenterConfig?: boolean; getSSCC?: boolean; context?: string; getProductServiceType?: boolean; getBuyerInfo?: boolean; getMinAndIncQuantities?: boolean; getTaxNumber?: boolean; getAccountingConfig?: boolean; getDetailsAccountingConfig?: boolean; getContractNumber?: boolean; getDetailsIntegrationState?: boolean; getRequestData?: boolean; getCrossDocking?: boolean; getDetailCostCenterName?: boolean; getPaymentCondition?: boolean; getProductRetention?: boolean; filterApprovedDetails?: boolean; filterReceivedDetails?: boolean; getMeansOfPayment?: boolean }): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller 
      + '/Get?id=' + id 
      + '&isEdition=' + isEdition 
      + '&getDetails=' + getDetails 
      + '&getCostCenterConfig=' + getCostCenterConfig 
      + '&getDetailCostCenterConfig=' + getDetailCostCenterConfig 
      + '&getSSCC=' + getSSCC 
      + '&context=' + context 
      + '&getBuyerInfo=' + getBuyerInfo 
      + '&getMinAndIncQuantities=' + getMinAndIncQuantities 
      + '&getTaxNumber=' + getTaxNumber 
      + '&getProductServiceType=' + getProductServiceType 
      + '&getAccountingConfig=' + getAccountingConfig 
      + '&getDetailsAccountingConfig=' + getDetailsAccountingConfig 
      + '&getContractNumber=' + getContractNumber 
      + '&getDetailsIntegrationState=' + getDetailsIntegrationState
      + '&getRequestData=' + getRequestData
      + '&getCrossDockingLocation=' + getCrossDocking
      + '&getDetailCostCenterName=' + getDetailCostCenterName
      + '&getPaymentCondition=' + getPaymentCondition
      + '&getProductRetention=' + getProductRetention
      + '&filterApprovedDetails=' + filterApprovedDetails
      + '&filterReceivedDetails=' + filterReceivedDetails
      + '&getMeanOfPayment=' + getMeansOfPayment)
      .pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getOrderDetailWorkflowsToList(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller
      + '/GetOrderDetailWorkflowStepsToList?id=' + id)
      .pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getDetailsByWorkflow(order: any) {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.post(SERVICE_URL + this._controller + '/GetDetailsWorkflows', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getDetailsWorkflowByDetails(orderDetails: any) {
    let formData: FormData = new FormData();
    formData.append('details', JSON.stringify(orderDetails));

    return this.http.post(SERVICE_URL + this._controller + '/GetDetailsWorkflowsByDetails', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getRequestOrders(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetRequestOrders?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getByNumber(orderNumber: string, deliveryLocation: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderByNumber?orderNumber=' + orderNumber + '&deliveryLocation=' + deliveryLocation).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getOrderRequests(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderRequests?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  getOrderProducts(IDs: Array<string>): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllProductsInOrdersByID', { IDs: IDs }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter rececoes pelo id
   * @param  {number} id
   * @returns Observable
   */
  getAllPODsByID(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllPODsByID?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter rececoes pelo id
   * @param  {number} id
   * @returns Observable
   */
  reExportPODsByID(ids: Array<number>): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ReExportPODsByID', { ids: ids }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter numero documento seguinte
   * @param  {number} OwnerId
   * @param  {number} supplierID
   * @returns Observable
   */
  getNextDocumentNumber(OwnerId: number, supplierID: number, isRequest: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextDocumentNumber?OwnerId=' + OwnerId + '&supplierID=' + supplierID + '&isRequest=' + isRequest).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Para ir buscar a lista dos filhos consoante o pai e o tratamento de IVA
   * @param {number} parentID 
   * @param {string} tipoTratamentoIva 
   * @returns 
   */
  GetChildsList(parentID: number, tipoTratamentoIva: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'TipologiaProdutoServico/GetChildsList?parentID=' + parentID + '&tipoTratamentoIva=' + tipoTratamentoIva)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
     * Obter a lista de linhas de requisições associadas a uma dada linha de encomenda por ID
     * @param {number} orderDetailID 
     * @returns 
     */
  getDetailRequests(orderDetailID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetDetailRequests?detailID=' + orderDetailID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar encomenda
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number, useAccountingConfig: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteOrder?id=' + id + '&useAccountingConfig=' + useAccountingConfig).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar encomenda
   * @param  {Order} order
   * @returns Observable
   */
  update(order: Order, useAccountingConfig: boolean = false, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));
    formData.append('useAccountingConfig', JSON.stringify(useAccountingConfig));
    formData.append('forAllCompanies', JSON.stringify(forAllCompanies));
    return this.http.post(SERVICE_URL + this._controller + '/UpdateOrder', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateDeliveryDates(orderDetails: OrderDetail[], orderLatestAcceptableDate: Date = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('orderDetails', JSON.stringify(orderDetails));
    formData.append('orderLatestAcceptableDate', JSON.stringify(orderLatestAcceptableDate));
    return this.http.post(SERVICE_URL + this._controller + '/UpdateDeliveryDates', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Rejeita o Detalhe seleiconado
   * @param  {number} detailID
   * @param  {number} stateID
   * @returns Observable
   */
  rejectDetail(detailID: number, stateID: number): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('detailID', JSON.stringify(detailID));
    formData.append('stateID', JSON.stringify(stateID));
    return this.http.put(SERVICE_URL + this._controller + '/RejectDetail', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar encomenda
   * @param  {Order} order
   * @returns Observable
   */
  add(order: Order, useAccountingConfig: boolean = false): Observable<ReturnStatusHtml> {
    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));
    formData.append('useAccountingConfig', JSON.stringify(useAccountingConfig));
    return this.http.put(SERVICE_URL + this._controller + '/AddOrder', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  saveAndSubmit(order: Order, useAccountingConfig: boolean = false, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));
    formData.append('useAccountingConfig', JSON.stringify(useAccountingConfig));
    formData.append('forAllCompanies', JSON.stringify(forAllCompanies));
    return this.http.post(SERVICE_URL + this._controller + '/SaveAndSubmitOrder', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  AddForecastOrder(storeID: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddForecastOrder', { storeID: storeID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyWorkflowStep(docID: number, stepID: number, notes: string, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { docID: docID, stepID: stepID, notes: notes, forAllCompanies: forAllCompanies }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyWorkflowStepToOrders(ordersIDs: number[], action: string, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStepToOrders', { ordersIDs: ordersIDs, action: action, forAllCompanies: forAllCompanies }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addStepsApprover(entityID: number, userID: number, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApprover', { entityID: entityID, userID: userID, forAllCompanies: forAllCompanies }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Obter listagem com condições de pagamento
  * @returns Observable
  */
  getGenericMeansOfPayment(context: string, referenceID: number, listCode: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GenericMeansOfPayment?context=' + context + '&referenceID=' + referenceID +
      '&listCode=' + listCode).pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar BlockedBy
   * @param  {number} orderID
   * @param  {number} userID
   * @returns Observable
   */
  updateBlockedBy(orderID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateBlockedBy', { orderID: orderID, userID: userID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Submeter encomenda
   * @param  {number} id
   * @returns Observable
   */
  submit(id: number, forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Submit', { id: id, forAllCompanies: forAllCompanies }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Download de PDF da encomenda
   * @param  {number} id
   * @returns Observable
   */
  downloadPDFOrder(id: number, allCompanies: boolean = false): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFOrder?id=' + id + '&allCompanies=' + allCompanies);
  }

  /**
 * Download de Excel da encomenda
 * @param  {number} id
 * @returns Observable
 */
  downloadExcelOrder(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelOrder?id=' + id);
  }

  /**
 * Download para Excel da lista de encomendas
 * @param  {number} id
 * @returns Observable
 */
  downloadExcelOrdersList(saleOrPurchase: string, params: any, firstdate: string, lastdate: string, productCode: string, isHistory: boolean): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelOrdersList', { saleOrPurchase: saleOrPurchase, parameters: params, firstdate: firstdate, lastdate: lastdate, productCode: productCode, isHistory: isHistory });
  }

  /**
  * Confirmar receção encomenda
  * @param  {Order} order
  * @returns Observable
  */
  confirmReception(order: Order): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.put(SERVICE_URL + this._controller + '/ConfirmReception', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Confirmar receção encomenda
  * @param  {Order} order
  * @returns Observable
  */
  totalReception(order: Order): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.put(SERVICE_URL + this._controller + '/TotalReception', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Confirmar Resposta à encomenda
  * @param  {Order} order
  * @param  {boolean} submitAnswer -> Indica se o Fornecedor está a submeter a Resposta (alteração de estado da Encomenda)
  * @returns Observable
  */
  confirmResponse(order: Order, submitAnswer: boolean = false): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));
    formData.append('submitAnswer', JSON.stringify(submitAnswer));

    return this.http.put(SERVICE_URL + this._controller + '/ConfirmResponse', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Atualizar receção encomenda
  * @param  {OrderPod} orderPod
  * @returns Observable
  */
  updateReception(orderPod: OrderPod): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(orderPod));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateReception', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
    * Atualizar apenas o nº da guia de uma receção encomenda
    * @param  {OrderPod} orderPod
    * @returns Observable
    */
  updateReceptionTransportNumber(orderPod: OrderPod): Observable<ReturnStatusHtml> {

    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(orderPod));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateReceptionTrasnportNumber', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region Orders Confirmatiom
  getOrderConfirmationNextNumber(type: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderConfirmationNextNumber?type=' + type).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  createOrderConfirmation(orderConfirmation: OrderConfirmation, submit: boolean): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(orderConfirmation));
    formData.append('submit', JSON.stringify(submit));

    return this.http.put(SERVICE_URL + this._controller + '/CreateOrderConfirmation', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteOrderConfirmation(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteOrderConfirmation?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  getOrderConfirmation(orderConfirmationID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderConfirmation?id=' + orderConfirmationID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  updateOrderConfirmation(orderConfirmation: OrderConfirmation, submit: boolean): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(orderConfirmation));
    formData.append('submit', JSON.stringify(submit));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateOrderConfirmation', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  /**
 * Submeter confirmação de receção de encomenda
 * @param  {number} id
 * @returns Observable
 */
  submitOrderConfirmation(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitOrderConfirmation', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#endregion


  applyDetailsByWorkflow(order: Order): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.post(SERVICE_URL + this._controller + '/ApplyDetailsWorkflow', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateDetailsByWorkflow(order: Order): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateDetailCostCenterAndQuatity', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // #endregion Encomenda

  // #region Baglass
  sendRequestChanges(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SendRequestChanges', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  // #endregion Baglass

  // #region EncomendaCompra
  get_showSupplierPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showSupplierPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowSupplierPanel');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowSupplierPanelSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showSupplierPanel = settingValueSP;
      } else if (settingValue != null) {
        this.showSupplierPanel = settingValue;
      } else {
        this.showSupplierPanel = defaultValue;
      }
    }
    return this.showSupplierPanel;
  }

  get_orderOnlyCatalogProducts(defaultValue: boolean): boolean {
    if (typeof this.orderOnlyCatalogProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'OrderOnlyCatalogProducts');
      if (settingValue != null) {
        this.orderOnlyCatalogProducts = settingValue;
      } else {
        this.orderOnlyCatalogProducts = defaultValue;
      }
    }
    return this.orderOnlyCatalogProducts;
  }

  get_AllowSubmitInSales(defaultValue: boolean): boolean {
    if (typeof this.AllowSubmitInSales === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'AllowSubmitInSales');
      let settingValueSP = this.authenticationService.getSettingPortal('OrdersSales', 'AllowSubmitInSales');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0) {
        this.AllowSubmitInSales = settingValueSP;
      } else if (settingValue != null) {
        this.AllowSubmitInSales = settingValue;
      } else {
        this.AllowSubmitInSales = defaultValue;
      }
    }
    return this.AllowSubmitInSales;
  }


  get_AllowDeleteInSales(defaultValue: boolean): boolean {
    if (typeof this.AllowDeleteInSales === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'AllowDeleteInSales');
      let settingValueSP = this.authenticationService.getSettingPortal('OrdersSales', 'AllowDeleteInSales');

      if (this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0) {
        this.AllowDeleteInSales = settingValueSP;
      } else if (settingValue != null) {
        this.AllowDeleteInSales = settingValue;
      } else {
        this.AllowDeleteInSales = defaultValue;
      }
    }
    return this.AllowDeleteInSales;
  }

  get_showOriginPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showOriginPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOriginPanel');
      if (settingValue != null) {
        this.showOriginPanel = settingValue;
      } else {
        this.showOriginPanel = defaultValue;
      }
    }
    return this.showOriginPanel;
  }

  get_showContracts_P(defaultValue: boolean): boolean {
    if (typeof this.showContracts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowContracts');
      if (settingValue != null) {
        this.showContracts = settingValue;
      } else {
        this.showContracts = defaultValue;
      }
    }
    return this.showContracts;
  }

  get_hideDeliveryPanel_P(defaultValue: boolean): boolean {
    if (typeof this.hideDeliveryPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideDeliveryPanel');
      if (settingValue != null) {
        this.hideDeliveryPanel = settingValue;
      } else {
        this.hideDeliveryPanel = defaultValue;
      }
    }
    return this.hideDeliveryPanel;
  }

  get_showInvoiceToPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showInvoiceToPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowInvoiceToPanel');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowInvoiceToPanelSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showInvoiceToPanel = settingValueSP;
      } else if (settingValue != null) {
        this.showInvoiceToPanel = settingValue;
      } else {
        this.showInvoiceToPanel = defaultValue;
      }
    }
    return this.showInvoiceToPanel;
  }

  get_showEDIMessagePanel_P(defaultValue: boolean): boolean {
    if (typeof this.showEDIMessagePanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowEDIMessagePanelNonSP');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowEDIMessagePanel');
      if (!this.authenticationService.session.company.ServiceProvider && settingValue != null) {
        this.showEDIMessagePanel = settingValue;
      } else if (settingValueSP != null) {
        this.showEDIMessagePanel = settingValueSP;
      } else {
        this.showEDIMessagePanel = defaultValue;
      }
    }
    return this.showEDIMessagePanel;
  }

  get_showBudgetsTab_P(defaultValue: boolean): boolean {
    if (typeof this.showBudgetsTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowBudgetsTab');
      if (settingValue != null) {
        this.showBudgetsTab = settingValue;
      } else {
        this.showBudgetsTab = defaultValue;
      }
    }
    return this.showBudgetsTab;
  }

  get_showDigitalFileTab_P(defaultValue: boolean): boolean {
    if (typeof this.showDigitalFileTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDigitalFileTab');
      if (settingValue != null) {
        this.showDigitalFileTab = settingValue;
      } else {
        this.showDigitalFileTab = defaultValue;
      }
    }
    return this.showDigitalFileTab;
  }

  get_showMessagesTab_P(defaultValue: boolean): boolean {
    if (typeof this.showMessagesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowMessagesTab');
      if (settingValue != null) {
        this.showMessagesTab = settingValue;
      } else {
        this.showMessagesTab = defaultValue;
      }
    }
    return this.showMessagesTab;
  }

  get_DigitalFileTabInfoBeforeOpenTab(defaultValue: boolean): boolean {
    if (typeof this.getDigitalFileTabInfoBeforeOpenTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'getDigitalFileTabInfoBeforeOpenTab');
      if (settingValue != null) {
        this.getDigitalFileTabInfoBeforeOpenTab = settingValue;
      } else {
        this.getDigitalFileTabInfoBeforeOpenTab = defaultValue;
      }
    }
    return this.getDigitalFileTabInfoBeforeOpenTab;
  }

  get_MessagesTabInfoBeforeOpenTab(defaultValue: boolean): boolean {
    if (typeof this.getMessagesTabInfoBeforeOpenTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'getMessagesTabInfoBeforeOpenTab');
      if (settingValue != null) {
        this.getMessagesTabInfoBeforeOpenTab = settingValue;
      } else {
        this.getMessagesTabInfoBeforeOpenTab = defaultValue;
      }
    }
    return this.getMessagesTabInfoBeforeOpenTab;
  }

  getShowRequestsTab(defaultValue: boolean): boolean {
    if (typeof this.showRequestsTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowRequestsTab');
      if (settingValue != null) {
        this.showRequestsTab = settingValue;
      } else {
        this.showRequestsTab = defaultValue;
      }
    }
    return this.showRequestsTab;
  }

  getShowTransferOrdersTab(defaultValue: boolean): boolean {
    if (typeof this.showTransferOrdersTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowTransferOrders');
      if (settingValue != null) {
        this.showTransferOrdersTab = settingValue;
      } else {
        this.showTransferOrdersTab = defaultValue;
      }
    }
    return this.showTransferOrdersTab;
  }

  getShowShowOrderTrackingTab(defaultValue: boolean): boolean {
    if (typeof this.showShowOrderTrackingTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOrderTracking');
      if (settingValue != null) {
        this.showShowOrderTrackingTab = settingValue;
      } else {
        this.showShowOrderTrackingTab = defaultValue;
      }
    }
    return this.showShowOrderTrackingTab;
  }

  get_showDistributionButton_P(defaultValue: boolean): boolean {
    if (typeof this.showDistributionButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDistributionButton');
      if (settingValue != null) {
        this.showDistributionButton = settingValue;
      } else {
        this.showDistributionButton = defaultValue;
      }
    }
    return this.showDistributionButton;
  }

  get_showCostCenter_P(defaultValue: boolean): boolean {
    if (typeof this.showCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCostCenter');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCostCenterSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showCostCenter = settingValueSP;
      } else if (settingValue != null) {
        this.showCostCenter = settingValue;
      } else {
        this.showCostCenter = defaultValue;
      }
    }
    return this.showCostCenter;
  }

  get_editableDeliveryPanelFields_P(defaultValue: boolean): boolean {
    if (typeof this.editableDeliveryPanelFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'EditableDeliveryPanelFields');
      if (settingValue != null) {
        this.editableDeliveryPanelFields = settingValue;
      } else {
        this.editableDeliveryPanelFields = defaultValue;
      }
    }
    return this.editableDeliveryPanelFields;
  }

  get_editableInvoiceToPanelFields_P(defaultValue: boolean): boolean {
    if (typeof this.editableInvoiceToPanelFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'EditableInvoiceToPanelFields');
      if (settingValue != null) {
        this.editableInvoiceToPanelFields = settingValue;
      } else {
        this.editableInvoiceToPanelFields = defaultValue;
      }
    }
    return this.editableInvoiceToPanelFields;
  }

  get_ReExportReceptionsOnDetailState_P(defaultValue: Array<number>): Array<number> {
    if (typeof this.reExportReceptionsOnDetailState === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ReExportReceptionsOnDetailState');
      if (settingValue != null) {
        this.reExportReceptionsOnDetailState = settingValue;
      } else {
        this.reExportReceptionsOnDetailState = defaultValue;
      }
    }
    return this.reExportReceptionsOnDetailState;
  }

  get_disableOrderType_P(defaultValue: boolean): boolean {
    if (typeof this.disableOrderType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DisableOrderType');
      if (settingValue != null) {
        this.disableOrderType = settingValue;
      } else {
        this.disableOrderType = defaultValue;
      }
    }
    return this.disableOrderType;
  }

  get_showBuyersTransportDocumentNumber_P(defaultValue: boolean): boolean {
    if (typeof this.showBuyersTransportDocumentNumber_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowBuyersTransportDocumentNumber');
      if (settingValue != null) {
        this.showBuyersTransportDocumentNumber_P = settingValue;
      } else {
        this.showBuyersTransportDocumentNumber_P = defaultValue;
      }
    }
    return this.showBuyersTransportDocumentNumber_P;
  }

  get_showOrderedBy_P(defaultValue: boolean): boolean {
    if (typeof this.showOrderedBy_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOrderedBy');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOrderedBySP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showOrderedBy_P = settingValueSP;
      } else if (settingValue != null) {
        this.showOrderedBy_P = settingValue;
      } else {
        this.showOrderedBy_P = defaultValue;
      }
    }
    return this.showOrderedBy_P;
  }

  get_showOrderedBy_S(defaultValue: boolean): boolean {
    if (typeof this.showOrderedBy_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowOrderedBy');
      if (settingValue != null) {
        this.showOrderedBy_S = settingValue;
      } else {
        this.showOrderedBy_S = defaultValue;
      }
    }
    return this.showOrderedBy_S;
  }

  get_showBuyerDepartmentName_P(defaultValue: boolean): boolean {
    if (typeof this.showBuyerDepartmentName_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowBuyerDepartmentName');
      if (settingValue != null) {
        this.showBuyerDepartmentName_P = settingValue;
      } else {
        this.showBuyerDepartmentName_P = defaultValue;
      }
    }
    return this.showBuyerDepartmentName_P;
  }

  get_showDeliveryTerms_P(defaultValue: boolean): boolean {
    if (typeof this.showDeliveryTerms === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDeliveryTerms');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDeliveryTermsSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showDeliveryTerms = settingValueSP;
      } else if (settingValue != null) {
        this.showDeliveryTerms = settingValue;
      } else {
        this.showDeliveryTerms = defaultValue;
      }
    }
    return this.showDeliveryTerms;
  }

  get_showBuyerDivisionCode_P(defaultValue: boolean): boolean {
    if (typeof this.showBuyerDivisionCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowBuyerDivisionCode');
      if (settingValue != null) {
        this.showBuyerDivisionCode = settingValue;
      } else {
        this.showBuyerDivisionCode = defaultValue;
      }
    }
    return this.showBuyerDivisionCode;
  }

  get_showLatestAcceptableDate_P(defaultValue: boolean): boolean {
    if (typeof this.showLatestAcceptableDate_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowLatestAcceptableDate');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowLatestAcceptableDateSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showLatestAcceptableDate_P = settingValueSP;
      } else if (settingValue != null) {
        this.showLatestAcceptableDate_P = settingValue;
      } else {
        this.showLatestAcceptableDate_P = defaultValue;
      }
    }
    return this.showLatestAcceptableDate_P;
  }

  get_showTotalNetAmount_P(defaultValue: boolean): boolean {
    if (typeof this.showTotalNetAmount_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowTotalNetAmount');
      if (settingValue != null) {
        this.showTotalNetAmount_P = settingValue;
      } else {
        this.showTotalNetAmount_P = defaultValue;
      }
    }
    return this.showTotalNetAmount_P;
  }

  get_showCurrencyCode_P(defaultValue: boolean): boolean {
    if (typeof this.showCurrencyCode_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCurrencyCode');
      if (settingValue != null) {
        this.showCurrencyCode_P = settingValue;
      } else {
        this.showCurrencyCode_P = defaultValue;
      }
    }
    return this.showCurrencyCode_P;
  }

  get_showIncoterm(defaultValue: boolean): boolean {
    if (typeof this.showIncoterm === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowIncoterm');
      if (settingValue != null) {
        this.showIncoterm = settingValue;
      } else {
        this.showIncoterm = defaultValue;
      }
    }
    return this.showIncoterm;
  }

  get_receiveAllButton(defaultValue: boolean): boolean {
    if (typeof this.receiveAllButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ReceiveAllButton');
      if (settingValue != null) {
        this.receiveAllButton = settingValue;
      } else {
        this.receiveAllButton = defaultValue;
      }
    }
    return this.receiveAllButton;
  }

  get_ShowNotReceivedButton(defaultValue: boolean): boolean {
    if (typeof this.showNotReceivedButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowNotReceivedButton');
      if (settingValue != null) {
        this.showNotReceivedButton = settingValue;
      } else {
        this.showNotReceivedButton = defaultValue;
      }
    }
    return this.showNotReceivedButton;
  }

  get_showFilterHasDetailActions(defaultValue: boolean): boolean {
    if (typeof this.showFilterUserHasActions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowFilterHasDetailActions');
      if (settingValue != null) {
        this.showFilterUserHasActions = settingValue;
      } else {
        this.showFilterUserHasActions = defaultValue;
      }
    }
    return this.showFilterUserHasActions;
  }

  get_FilterApprovedDetails(defaultValue: boolean): boolean {
    if (typeof this.recepetionFilterAprovedDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'FilterApprovedDetails');
      if (settingValue != null) {
        this.recepetionFilterAprovedDetails = settingValue;
      } else {
        this.recepetionFilterAprovedDetails = defaultValue;
      }
    }
    return this.recepetionFilterAprovedDetails;
  }

  get_DisableDeliveredQuantityFieldBeforeExport(defaultValue: boolean): boolean {
    if (typeof this.disableDeliveredQuantityFieldBeforeExport === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'DisableDeliveredQuantityFieldBeforeExport');
      if (settingValue != null) {
        this.disableDeliveredQuantityFieldBeforeExport = settingValue;
      } else {
        this.disableDeliveredQuantityFieldBeforeExport = defaultValue;
      }
    }
    return this.disableDeliveredQuantityFieldBeforeExport;
  }

  get_showNewDetail(defaultValue: boolean): boolean {
    if (typeof this.showNewDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowNewDetail');
      if (settingValue != null) {
        this.showNewDetail = settingValue;
      } else {
        this.showNewDetail = defaultValue;
      }
    }
    return this.showNewDetail;
  }

  get_showApprovTab(defaultValue: boolean): boolean {
    if (typeof this.showApprovTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowApprovTab');
      if (settingValue != null) {
        this.showApprovTab = settingValue;
      } else {
        this.showApprovTab = defaultValue;
      }
    }
    return this.showApprovTab;
  }

  get_showSupplierIntegrationID(defaultValue: boolean): boolean {
    if (typeof this.showSupplierIntegrationID === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowSupplierIntegrationID');
      if (settingValue != null) {
        this.showSupplierIntegrationID = settingValue;
      } else {
        this.showSupplierIntegrationID = defaultValue;
      }
    }
    return this.showSupplierIntegrationID;
  }

  get_showMeansOfPaymentOnSupplierPanel(defaultValue: boolean): boolean {
    if (typeof this.showMeansOfPaymentOnSupplierPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowMeansOfPaymentOnSupplierPanel');
      if (settingValue != null) {
        this.showMeansOfPaymentOnSupplierPanel = settingValue;
      } else {
        this.showMeansOfPaymentOnSupplierPanel = defaultValue;
      }
    }
    return this.showMeansOfPaymentOnSupplierPanel;
  }

  get_allowDeleteByAdmin(defaultValue: boolean): boolean {
    if (typeof this.allowDeleteByAdmin === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AllowDeleteByAdmin');
      if (settingValue != null) {
        this.allowDeleteByAdmin = settingValue;
      } else {
        this.allowDeleteByAdmin = defaultValue;
      }
    }
    return this.allowDeleteByAdmin;
  }

  get_allowsRepeatedProducts_P(defaultValue: boolean): boolean {
    if (typeof this.allowsRepeatedProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AllowsRepeatedProducts');
      if (settingValue != null) {
        this.allowsRepeatedProducts = settingValue;
      } else {
        this.allowsRepeatedProducts = defaultValue;
      }
    }
    return this.allowsRepeatedProducts;
  }

  get_addPriceListDiscount_P(defaultValue: boolean): boolean {
    if (typeof this.addPriceListDiscount_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AddPriceListDiscount');
      if (settingValue != null) {
        this.addPriceListDiscount_P = settingValue;
      } else {
        this.addPriceListDiscount_P = defaultValue;
      }
    }
    return this.addPriceListDiscount_P;
  }

  get_useDeliveryDateSchedule(defaultValue: boolean): boolean {
    if (typeof this.useDeliveryDateSchedule === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'UseDeliveryDateSchedule');
      if (settingValue != null) {
        this.useDeliveryDateSchedule = settingValue;
      } else {
        this.useDeliveryDateSchedule = defaultValue;
      }
    }
    return this.useDeliveryDateSchedule;
  }

  // grupo de campos obrigatórios
  get_isRequiredDeliveryPlaceGroup(defaultValue: boolean): boolean {
    if (typeof this.isRequiredDeliveryPlaceGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'IsRequiredDeliveryPlaceGroup');
      if (settingValue != null) {
        this.isRequiredDeliveryPlaceGroup = settingValue;
      } else {
        this.isRequiredDeliveryPlaceGroup = defaultValue;
      }
    }
    return this.isRequiredDeliveryPlaceGroup;
  }

  // grupo de campos obrigatórios
  get_isRequiredInvoiceToPlaceGroup(defaultValue: boolean): boolean {
    if (typeof this.isRequiredInvoiceToPlaceGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'IsRequiredInvoiceToPlaceGroup');
      if (settingValue != null) {
        this.isRequiredInvoiceToPlaceGroup = settingValue;
      } else {
        this.isRequiredInvoiceToPlaceGroup = defaultValue;
      }
    }
    return this.isRequiredInvoiceToPlaceGroup;
  }

  get_showDeliveryStatus(defaultValue: boolean): boolean {
    if (typeof this.showDeliveryStatus === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDeliveryStatus');
      if (settingValue != null) {
        this.showDeliveryStatus = settingValue;
      } else {
        this.showDeliveryStatus = defaultValue;
      }
    }
    return this.showDeliveryStatus;
  }

  get_showOtherReferences(defaultValue: boolean): boolean {
    if (typeof this.showOtherReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOtherReferences');
      if (settingValue != null) {
        this.showOtherReferences = settingValue;
      } else {
        this.showOtherReferences = defaultValue;
      }
    }
    return this.showOtherReferences;
  }

  get_showOtherReferences_S(defaultValue: boolean): boolean {
    if (typeof this.showOtherReferences_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowOtherReferences');
      if (settingValue != null) {
        this.showOtherReferences_S = settingValue;
      } else {
        this.showOtherReferences_S = defaultValue;
      }
    }
    return this.showOtherReferences_S;
  }

  get_showConditionsSummary_P(defaultValue: boolean): boolean {
    if (typeof this.showConditionsSummary_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowConditionsSummary');
      if (settingValue != null) {
        this.showConditionsSummary_P = settingValue;
      } else {
        this.showConditionsSummary_P = defaultValue;
      }
    }
    return this.showConditionsSummary_P;
  }

  get_showConditionsSummary_S(defaultValue: boolean): boolean {
    if (typeof this.showConditionsSummary_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowConditionsSummary');
      if (settingValue != null) {
        this.showConditionsSummary_S = settingValue;
      } else {
        this.showConditionsSummary_S = defaultValue;
      }
    }
    return this.showConditionsSummary_S;
  }

  /*
  get_showOtherReferencesCSV(defaultValue: boolean): boolean {
    if (typeof this.showOtherReferencesCSV === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOtherReferencesCSV');
      if (settingValue != null) {
        this.showOtherReferencesCSV = settingValue;
      } else {
        this.showOtherReferencesCSV = defaultValue;
      }
    }
    return this.showOtherReferencesCSV;
  }
  */

  get_showTaxTypeCodeOther(defaultValue: boolean): boolean {
    if (typeof this.showTaxTypeCodeOther === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowTaxTypeCodeOther');
      if (settingValue != null) {
        this.showTaxTypeCodeOther = settingValue;
      } else {
        this.showTaxTypeCodeOther = defaultValue;
      }
    }
    return this.showTaxTypeCodeOther;
  }

  get_showWmsControlCode(defaultValue: boolean): boolean {
    if (typeof this.showWmsControlCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowWmsControlCode');
      if (settingValue != null) {
        this.showWmsControlCode = settingValue;
      } else {
        this.showWmsControlCode = defaultValue;
      }
    }
    return this.showWmsControlCode;
  }

  get_hideLineStatusOnEdition(defaultValue: boolean): boolean {
    if (typeof this.hideLineStatusOnEdition === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideLineStatusOnEdition');
      if (settingValue != null) {
        this.hideLineStatusOnEdition = settingValue;
      } else {
        this.hideLineStatusOnEdition = defaultValue;
      }
    }
    return this.hideLineStatusOnEdition;
  }

  get_filterByHistory(defaultValue: boolean): boolean {
    if (typeof this.filterByHistory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'FilterByHistory');
      if (settingValue != null) {
        this.filterByHistory = settingValue;
      } else {
        this.filterByHistory = defaultValue;
      }
    }
    return this.filterByHistory;
  }

  get_possibleStates_P(defaultValue: Array<number>): Array<number> {
    if (typeof this.possibleStates_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'PossibleStates');
      if (settingValue != null) {
        this.possibleStates_P = settingValue;
      } else {
        this.possibleStates_P = defaultValue;
      }
    }
    return this.possibleStates_P;
  }

  get_receptionStates(defaultValue: Array<number>): Array<number> {
    if (typeof this.receptionStates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ReceptionStates');
      if (settingValue != null) {
        this.receptionStates = settingValue;
      } else {
        this.receptionStates = defaultValue;
      }
    }
    return this.receptionStates;
  }


  get_allowedConfirmReceptionOrderTypes(defaultValue: Array<String>): Array<String> {
    if (typeof this.allowedConfirmReceptionOrderTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'AllowConfirmReceptionOrderTypes');
      if (settingValue != null) {
        this.allowedConfirmReceptionOrderTypes = settingValue;
      } else {
        this.allowedConfirmReceptionOrderTypes = defaultValue;
      }
    }
    return this.allowedConfirmReceptionOrderTypes;
  }

  /**
   * Obtém o Setting UseHierarchicalOrderDetailsQuantities que é responsável por garantir o Comportamento Hierárquico das Qtds na Receção da Encomenda
   * Hierarquia: Qtd. Expedida [DespatchedQuantity] -> Qtd. a Entregar [RevisedQuantity] -> Qtd. Encomendada [OrderedQuantity]
   * @param  {boolean} defaultValue
   * @returns boolean
   */
  get_useHierarchicalOrderDetailsQuantities(defaultValue: boolean): boolean {
    if (typeof this.useHierarchicalOrderDetailsQuantities === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'UseHierarchicalOrderDetailsQuantities');
      if (settingValue != null) {
        this.useHierarchicalOrderDetailsQuantities = settingValue;
      } else {
        this.useHierarchicalOrderDetailsQuantities = defaultValue;
      }
    }
    return this.useHierarchicalOrderDetailsQuantities;
  }


  get_showDispatchedQuantity(defaultValue: boolean): boolean {
    if (typeof this.showDispatchedQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowDispatchedQuantity');
      if (settingValue != null) {
        this.showDispatchedQuantity = settingValue;
      } else {
        this.showDispatchedQuantity = defaultValue;
      }
    }
    return this.showDispatchedQuantity;
  }


  get_showRevisedQuantity(defaultValue: boolean): boolean {
    if (typeof this.showRevisedQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowRevisedQuantity');
      if (settingValue != null) {
        this.showRevisedQuantity = settingValue;
      } else {
        this.showRevisedQuantity = defaultValue;
      }
    }
    return this.showRevisedQuantity;
  }


  get_showCloseLine(defaultValue: boolean): boolean {
    if (typeof this.showCloseLine === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowCloseLine');
      if (settingValue != null) {
        this.showCloseLine = settingValue;
      } else {
        this.showCloseLine = defaultValue;
      }
    }
    return this.showCloseLine;
  }


  get_hideOrderedQuantity(defaultValue: boolean): boolean {
    if (typeof this.hideOrderedQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideOrderedQuantity');
      if (settingValue != null) {
        this.hideOrderedQuantity = settingValue;
      } else {
        this.hideOrderedQuantity = defaultValue;
      }
    }
    return this.hideOrderedQuantity;
  }


  get_includeTimeInDates(defaultValue: boolean): boolean {
    if (typeof this.includeTimeInDates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'IncludeTimeInDates');
      if (settingValue != null) {
        this.includeTimeInDates = settingValue;
      } else {
        this.includeTimeInDates = defaultValue;
      }
    }
    return this.includeTimeInDates;
  }




  get_includeTimeInDates_SALES(defaultValue: boolean): boolean {
    if (typeof this.includeTimeInDates_SALES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'IncludeTimeInDates');
      if (settingValue != null) {
        this.includeTimeInDates_SALES = settingValue;
      } else {
        this.includeTimeInDates_SALES = defaultValue;
      }
    }
    return this.includeTimeInDates_SALES;
  }

  get_hideExcelExportButton_SALE(defaultValue: boolean): boolean {
    if (typeof this.hideExcelExportButton_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'HideExcelExportButton');
      if (settingValue != null) {
        this.hideExcelExportButton_SALE = settingValue;
      } else {
        this.hideExcelExportButton_SALE = defaultValue;
      }
    }
    return this.hideExcelExportButton_SALE;
  }

  get_showSupplierResponseButton(defaultValue: boolean): boolean {
    if (typeof this.showSupplierResponseButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowSupplierResponseButton');
      if (settingValue != null) {
        this.showSupplierResponseButton = settingValue;
      } else {
        this.showSupplierResponseButton = defaultValue;
      }
    }
    return this.showSupplierResponseButton;
  }

  get_responseStates(defaultValue: Array<number>): Array<number> {
    if (typeof this.responseStates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'ResponseStates');
      if (settingValue != null) {
        this.responseStates = settingValue;
      } else {
        this.responseStates = defaultValue;
      }
    }
    return this.responseStates;
  }

  get_receptionRole(defaultValue: number): number {
    if (typeof this.receptionRole === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ReceptionRole');
      if (settingValue != null) {
        this.receptionRole = settingValue;
      } else {
        this.receptionRole = defaultValue;
      }
    }
    return this.receptionRole;
  }

  get_isReceptionNumberInt(defaultValue: boolean): boolean {
    if (typeof this.isReceptionNumberInt === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'IsReceptionNumberInt');
      if (settingValue != null) {
        this.isReceptionNumberInt = settingValue;
      } else {
        this.isReceptionNumberInt = defaultValue;
      }
    }
    return this.isReceptionNumberInt;
  }

  get_isReceptionHasExtendedEvals(defaultValue: boolean): boolean {
    if (typeof this.isReceptionHasExtendedEvals === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ReceptionHasExtendedEvals');
      if (settingValue != null) {
        this.isReceptionHasExtendedEvals = settingValue;
      } else {
        this.isReceptionHasExtendedEvals = defaultValue;
      }
    }
    return this.isReceptionHasExtendedEvals;
  }

  get_otisReceptionNumberValidation(defaultValue: boolean): boolean {
    if (typeof this.otisReceptionNumberValidation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'OtisReceptionNumberValidation');
      if (settingValue != null) {
        this.otisReceptionNumberValidation = settingValue;
      } else {
        this.otisReceptionNumberValidation = defaultValue;
      }
    }
    return this.otisReceptionNumberValidation;
  }

  get_mandatorySubmitRole(defaultValue: number): number {
    if (typeof this.mandatorySubmitRole === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'MandatorySubmitRole');
      if (settingValue != null) {
        this.mandatorySubmitRole = settingValue;
      } else {
        this.mandatorySubmitRole = defaultValue;
      }
    }
    return this.mandatorySubmitRole;
  }

  get_allowUnblockAllUsers(defaultValue: boolean): boolean {
    if (typeof this.allowUnblockAllUsers === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'AllowUnblockAllUsers');
      if (settingValue != null) {
        this.allowUnblockAllUsers = settingValue;
      } else {
        this.allowUnblockAllUsers = defaultValue;
      }
    }
    return this.allowUnblockAllUsers;
  }

  get_showReadDate(defaultValue: boolean): boolean {
    if (typeof this.showReadDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowReadDate');
      if (settingValue != null) {
        this.showReadDate = settingValue;
      } else {
        this.showReadDate = defaultValue;
      }
    }
    return this.showReadDate;
  }

  /**
 * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
 * @param  {Array<number>} defaultValue - Valor por defeito do parametro
 * @param  {Array<string>} columnsNames - nomes das colunas
 * @returns Array
 */
  get_ColumnInVisible_P(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('OrdersPurchases', 'ColumVisibleSP');
      this.columnInVisible_P = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_P;
  }

  /**
* Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
* @param  {Array<number>} defaultValue - Valor por defeito do parametro
* @param  {Array<string>} columnsNames - nomes das colunas
* @returns Array
*/
  get_ColumnInVisible_ForAllCompanies(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_ForAllCompanies === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchasesAllCompanies', 'ColumnVisible');
      this.columnInVisible_ForAllCompanies = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_ForAllCompanies;
  }

  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  get_fillEndDate_P(defaultValue: boolean): boolean {
    if (typeof this.fillEndDate_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'FillEndDate');
      if (settingValue !== null) {
        this.fillEndDate_P = settingValue;
      } else {
        this.fillEndDate_P = defaultValue;
      }
    }
    return this.fillEndDate_P;
  }

  get_showDetailWorkflowCheckboxes_P(defaultValue: boolean): boolean {
    if (typeof this.showDetailWorkflowCheckboxes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowDetailWorkflowCheckboxes');
      if (settingValue != null) {
        this.showDetailWorkflowCheckboxes = settingValue;
      } else {
        this.showDetailWorkflowCheckboxes = defaultValue;
      }
    }
    return this.showDetailWorkflowCheckboxes;
  }

  get_defaultStartDays_P(defaultValue: number): number {
    if (typeof this.defaultStartDate_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'DefaultStartDays');
      if (settingValue != null) {
        this.defaultStartDate_P = settingValue;
      } else {
        this.defaultStartDate_P = defaultValue;
      }
    }
    return this.defaultStartDate_P;
  }

  get_ColumnInVisible_Details_P(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_Details_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ColumVisibleSP');
      this.columnInVisible_Details_P = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_Details_P;
  }

  get_optionalDeliveryLocationIdentifier(defaultValue: boolean): boolean {
    if (typeof this.optionalDeliveryLocationIdentifier === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LogisticOrders', 'OptionalDeliveryLocationIdentifier');
      if (settingValue != null) {
        this.optionalDeliveryLocationIdentifier = settingValue;
      } else {
        this.optionalDeliveryLocationIdentifier = defaultValue;
      }
    }
    return this.optionalDeliveryLocationIdentifier;
  }

  get_hasCrossDocking(defaultValue: boolean): boolean {
    if (typeof this.hasCrossDocking === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LogisticOrders', 'HasCrossDocking');
      if (settingValue != null) {
        this.hasCrossDocking = settingValue;
      } else {
        this.hasCrossDocking = defaultValue;
      }
    }
    return this.hasCrossDocking;
  }

  get_warehouseOriginByBuyerLocations(defaultValue: boolean): boolean {
    if (typeof this.warehouseOriginByBuyerLocations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LogisticOrders', 'WarehouseOriginByBuyerLocations');
      if (settingValue != null) {
        this.warehouseOriginByBuyerLocations = settingValue;
      } else {
        this.warehouseOriginByBuyerLocations = defaultValue;
      }
    }
    return this.warehouseOriginByBuyerLocations;
  }

  get_warehouseDeliveryByBuyerLocations(defaultValue: boolean): boolean {
    if (typeof this.warehouseDeliveryByBuyerLocations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LogisticOrders', 'WarehouseDeliveryByBuyerLocations');
      if (settingValue != null) {
        this.warehouseDeliveryByBuyerLocations = settingValue;
      } else {
        this.warehouseDeliveryByBuyerLocations = defaultValue;
      }
    }
    return this.warehouseDeliveryByBuyerLocations;
  }

  get_allowClientToCreatePreparationOrder(defaultValue: boolean): boolean {
    if (typeof this.allowClientToCreatePreparationOrder === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('LogisticOrders', 'AllowClientToCreatePreparationOrder');
      if (settingValue != null) {
        this.allowClientToCreatePreparationOrder = settingValue;
      } else {
        this.allowClientToCreatePreparationOrder = defaultValue;
      }
    }
    return this.allowClientToCreatePreparationOrder;
  }

  get_filterDockModalProductsByFamily_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsByFamily === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterDockModalProductsByFamily');
      if (settingValue != null) {
        this.filterDockModalProductsByFamily = settingValue;
      } else {
        this.filterDockModalProductsByFamily = defaultValue;
      }
    }
    return this.filterDockModalProductsByFamily;
  }

  get_filterDockModalProductsByCategory_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsByCategory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterDockModalProductsByCategory');
      if (settingValue != null) {
        this.filterDockModalProductsByCategory = settingValue;
      } else {
        this.filterDockModalProductsByCategory = defaultValue;
      }
    }
    return this.filterDockModalProductsByCategory;
  }

  get_filterDockModalProductsByPriceList_P(defaultValue: boolean): boolean {
    if (typeof this.filterDockModalProductsByPricelist === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterDockModalProductsByPricelist');
      if (settingValue != null) {
        this.filterDockModalProductsByPricelist = settingValue;
      } else {
        this.filterDockModalProductsByPricelist = defaultValue;
      }
    }
    return this.filterDockModalProductsByPricelist;
  }

  get_productQuantityValidations(defaultValue: boolean): boolean {
    if (typeof this.productQuantityValidations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ProductQuantityValidations');
      if (settingValue != null) {
        this.productQuantityValidations = settingValue;
      } else {
        this.productQuantityValidations = defaultValue;
      }
    }
    return this.productQuantityValidations;
  }

  get_showQuantityArrows(defaultValue: boolean): boolean {
    if (typeof this.showQuantityArrows === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowQuantityArrows');
      if (settingValue != null) {
        this.showQuantityArrows = settingValue;
      } else {
        this.showQuantityArrows = defaultValue;
      }
    }
    return this.showQuantityArrows;
  }

  get_filterLocationsByUser(defaultValue: boolean): boolean {
    if (typeof this.filterByUser === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterLocationByUser');
      let settingValueAdmin = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterLocationByUserAdmin');
      if (this.authenticationService.session.roles.findIndex(r => r === Role.ROLE_ADMIN) !== -1 && settingValueAdmin != null) {
        this.filterByUser = settingValueAdmin;
      }
      else if (settingValue != null) {
        this.filterByUser = settingValue;
      } else {
        this.filterByUser = defaultValue;
      }
    }
    return this.filterByUser;
  }

  get_Detail_overwriteDetailDeliveryDate(defaultValue: boolean): boolean {
    if (typeof this.overwriteDetailDeliveryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'OverwriteDetailDeliveryDate');
      if (settingValue != null) {
        this.overwriteDetailDeliveryDate = settingValue;
      } else {
        this.overwriteDetailDeliveryDate = defaultValue;
      }
    }
    return this.overwriteDetailDeliveryDate;
  }

  get_Detail_DefaultOrderedQuantityUOMCode(defaultValue: string): string {
    if (typeof this.defaultOrderedQuantityUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DefaultOrderedQuantityUOMCode');
      if (settingValue != null) {
        this.defaultOrderedQuantityUOMCode = settingValue;
      } else {
        this.defaultOrderedQuantityUOMCode = defaultValue;
      }
    }
    return this.defaultOrderedQuantityUOMCode;
  }

  get_showEmails_P(defaultValue: boolean): boolean {
    if (typeof this.showEmails_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowEmails');
      if (settingValue != null) {
        this.showEmails_P = settingValue;
      } else {
        this.showEmails_P = defaultValue;
      }
    }
    return this.showEmails_P;
  }

  get_showObservationsPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showObservationsPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowObservationsPanel');
      if (settingValue != null) {
        this.showObservationsPanel_P = settingValue;
      } else {
        this.showObservationsPanel_P = defaultValue;
      }
    }
    return this.showObservationsPanel_P;
  }

  get_repositoryFileAllowDeleteRole(defaultValue: number): number {
    if (typeof this._repositoryFileAllowDeleteRole === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'RepositoryFileAllowDeleteRole');
      if (settingValue != null) {
        this._repositoryFileAllowDeleteRole = settingValue;
      } else {
        this._repositoryFileAllowDeleteRole = defaultValue;
      }
    }
    return this._repositoryFileAllowDeleteRole;
  }

  get_hideDeliveryDateNonSP(defaultValue: boolean): boolean {
    if (typeof this.hideDeliveryDateNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideDeliveryDateNonSP');
      if (settingValue != null) {
        this.hideDeliveryDateNonSP = settingValue;
      } else {
        this.hideDeliveryDateNonSP = defaultValue;
      }
    }
    return this.hideDeliveryDateNonSP;
  }

  get_deliveryDateRequiredSP(defaultValue: boolean): boolean {
    if (typeof this.deliveryDateRequiredSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DeliveryDateRequiredSP');
      if (settingValue != null) {
        this.deliveryDateRequiredSP = settingValue;
      } else {
        this.deliveryDateRequiredSP = defaultValue;
      }
    }
    return this.deliveryDateRequiredSP;
  }

  get_deliveryDateMinDays(defaultValue: number): number {
    if (typeof this.deliveryDateMinDays === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DeliveryDateMinDays');
      if (settingValue != null) {
        this.deliveryDateMinDays = settingValue;
      } else {
        this.deliveryDateMinDays = defaultValue;
      }
    }
    return this.deliveryDateMinDays;
  }

  get_showProductsDockModalPrice(defaultValue: boolean): boolean {
    if (typeof this.showProductsDockModalPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowProductsDockModalPrice');
      if (settingValue != null) {
        this.showProductsDockModalPrice = settingValue;
      } else {
        this.showProductsDockModalPrice = defaultValue;
      }
    }
    return this.showProductsDockModalPrice;
  }

  get_showProductsDockModalUnit(defaultValue: boolean): boolean {
    if (typeof this.showProductsDockModalUnit === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowProductsDockModalUnit');
      if (settingValue != null) {
        this.showProductsDockModalUnit = settingValue;
      } else {
        this.showProductsDockModalUnit = defaultValue;
      }
    }
    return this.showProductsDockModalUnit;
  }

  get_showProductsWithImage_P(defaultValue: boolean): boolean {
    if (typeof this.showProductsWithImage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowProductsWithImage');
      if (settingValue !== null) {
        this.showProductsWithImage = settingValue;
      } else {
        this.showProductsWithImage = defaultValue;
      }
    }
    return this.showProductsWithImage;
  }

  get_showReturnedQuantity_P(defaultValue: boolean): boolean {
    if (typeof this.showReturnedQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowReturnedQuantity');
      if (settingValue !== null) {
        this.showReturnedQuantity = settingValue;
      } else {
        this.showReturnedQuantity = defaultValue;
      }
    }
    return this.showReturnedQuantity;
  }

  get_labelHeader_P(defaultValue: string): string {
    if (typeof this.labelHeader === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'LabelHeader');
      if (settingValue !== null) {
        this.labelHeader = settingValue;
      } else {
        this.labelHeader = defaultValue;
      }
    }
    return this.labelHeader;
  }

  get_labelDetails_P(defaultValue: string): string {
    if (typeof this.labelDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'LabelDetails');
      if (settingValue !== null) {
        this.labelDetails = settingValue;
      } else {
        this.labelDetails = defaultValue;
      }
    }
    return this.labelDetails;
  }

  get_labelProductsList_P(defaultValue: string): string {
    if (typeof this.labelProductsList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'LabelProductsList');
      if (settingValue !== null) {
        this.labelProductsList = settingValue;
      } else {
        this.labelProductsList = defaultValue;
      }
    }
    return this.labelProductsList;
  }

  get_initTabIndex_P(defaultValue: number): number {
    if (typeof this.initTabIndex === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'InitTabIndex');
      if (settingValue !== null) {
        this.initTabIndex = settingValue;
      } else {
        this.initTabIndex = defaultValue;
      }
    }
    return this.initTabIndex;
  }

  get_autoGenerateOrderNumber_P(defaultValue: boolean): boolean {
    if (typeof this.autoGenerateOrderNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AutoGenerateOrderNumber');
      if (settingValue !== null) {
        this.autoGenerateOrderNumber = settingValue;
      } else {
        this.autoGenerateOrderNumber = defaultValue;
      }
    }
    return this.autoGenerateOrderNumber;
  }

  get_disablePrices_P(defaultValue: boolean): boolean {
    if (typeof this.disablePrices === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DisablePrices');
      if (settingValue !== null) {
        this.disablePrices = settingValue;
      } else {
        this.disablePrices = defaultValue;
      }
    }
    return this.disablePrices;
  }

  get_showEarliestAcceptableDate_P(defaultValue: boolean): boolean {
    if (typeof this.showEarliestAcceptableDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowEarliestAcceptableDate');
      if (settingValue !== null) {
        this.showEarliestAcceptableDate = settingValue;
      } else {
        this.showEarliestAcceptableDate = defaultValue;
      }
    }
    return this.showEarliestAcceptableDate;
  }

  get_showMeansOfPayment_P(defaultValue: boolean): boolean {
    if (typeof this.showMeansOfPayment === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowMeansOfPayment');
      if (settingValue !== null) {
        this.showMeansOfPayment = settingValue;
      } else {
        this.showMeansOfPayment = defaultValue;
      }
    }
    return this.showMeansOfPayment;
  }

  get_showDaysDue_P(defaultValue: boolean): boolean {
    if (typeof this.showDaysDue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDaysDue');
      if (settingValue !== null) {
        this.showDaysDue = settingValue;
      } else {
        this.showDaysDue = defaultValue;
      }
    }
    return this.showDaysDue;
  }

  get_filterActive(defaultValue: boolean): boolean {
    if (typeof this.filterActive === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterActive');
      if (settingValue !== null) {
        this.filterActive = settingValue;
      } else {
        this.filterActive = defaultValue;
      }
    }
    return this.filterActive;
  }

  get_showTotalNetAmountDetailsTab_P(defaultValue: boolean): boolean {
    if (typeof this.showTotalNetAmountDetailsTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowTotalNetAmountDetailsTab');
      if (settingValue !== null) {
        this.showTotalNetAmountDetailsTab = settingValue;
      } else {
        this.showTotalNetAmountDetailsTab = defaultValue;
      }
    }
    return this.showTotalNetAmountDetailsTab;
  }

  get_showProductsAdditionalInfo(defaultValue: boolean): boolean {
    if (typeof this.showProductsAdditionalInfo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowProductsAdditionalInfo');
      if (settingValue != null) {
        this.showProductsAdditionalInfo = settingValue;
      } else {
        this.showProductsAdditionalInfo = defaultValue;
      }
    }
    return this.showProductsAdditionalInfo;
  }

  get_showAttachesIsPublic(defaultValue: boolean): boolean {
    if (typeof this.showAttachesIsPublic === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowAttachesIsPublic');
      if (settingValue != null) {
        this.showAttachesIsPublic = settingValue;
      } else {
        this.showAttachesIsPublic = defaultValue;
      }
    }
    return this.showAttachesIsPublic;
  }

  get_showContractNumber(defaultValue: boolean): boolean {
    if (typeof this.showContractNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowContractNumber');
      if (settingValue != null) {
        this.showContractNumber = settingValue;
      } else {
        this.showContractNumber = defaultValue;
      }
    }
    return this.showContractNumber;
  }

  get_showCentralPayment(defaultValue: boolean): boolean {
    if (typeof this.showCentralPayment === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCentralPayment');
      if (settingValue != null) {
        this.showCentralPayment = settingValue;
      } else {
        this.showCentralPayment = defaultValue;
      }
    }
    return this.showCentralPayment;
  }

  get_showOpenDetail_P(defaultValue: boolean): boolean {
    if (typeof this.showOpenDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowOpenDetail');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowOpenDetailSP');
      if (this.authenticationService.session.company.ServiceProvider && settingValueSP != null) {
        this.showOpenDetail = settingValueSP;
      } else if (settingValue !== null) {
        this.showOpenDetail = settingValue;
      } else {
        this.showOpenDetail = defaultValue;
      }
    }
    return this.showOpenDetail;
  }

  get_toggleFilterStates_PURCHASE(defaultValue: Array<number>): Array<number> {
    if (typeof this.toggleFilterStates_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ToggleFilterStates');
      if (settingValue != null) {
        this.toggleFilterStates_PURCHASE = settingValue;
      } else {
        this.toggleFilterStates_PURCHASE = defaultValue;
      }
    }
    return this.toggleFilterStates_PURCHASE;
  }

  get_toggleFilterStates_SALE(defaultValue: Array<number>): Array<number> {
    if (typeof this.toggleFilterStates_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ToggleFilterStates');
      if (settingValue != null) {
        this.toggleFilterStates_SALE = settingValue;
      } else {
        this.toggleFilterStates_SALE = defaultValue;
      }
    }
    return this.toggleFilterStates_SALE;
  }

  get_PaymentConditions_S(defaultValue: boolean): boolean {
    if (typeof this.getPaymentConditions_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'GetPaymentCondition');
      if (settingValue != null) {
        this.getPaymentConditions_S = settingValue;
      } else {
        this.getPaymentConditions_S = defaultValue;
      }
    }
    return this.getPaymentConditions_S;
  }

  get_MeansOfPayment_S(defaultValue: boolean): boolean {
    if (typeof this.getMeansOfPayment_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'GetMeanOfPayment');
      if (settingValue != null) {
        this.getMeansOfPayment_S = settingValue;
      } else {
        this.getMeansOfPayment_S = defaultValue;
      }
    }
    return this.getMeansOfPayment_S;
  }

  get_InformationMessage_P(defaultValue: string): string {
    if (typeof this.informationMessage_P === 'undefined' && !this.authenticationService.session.company.ServiceProvider) { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'InformationMessageNonSP');
      if (settingValue !== null) {
        this.informationMessage_P = settingValue;
      } else {
        this.informationMessage_P = defaultValue;
      }
    }
    return this.informationMessage_P;
  }

  get_showCurrencyExchange_P(defaultValue: boolean): boolean {
    if (typeof this.showCurrencyExchange_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCurrencyExchange');
      if (settingValue !== null) {
        this.showCurrencyExchange_P = settingValue;
      } else {
        this.showCurrencyExchange_P = defaultValue;
      }
    }
    return this.showCurrencyExchange_P;
  }

  getPURCHASE_showOthersReferencesTypes(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowOthersReferencesTypes');
      if (settingValue != null) {
        this.PURCHASE_showOthersReferencesTypes = settingValue;
      } else {
        this.PURCHASE_showOthersReferencesTypes = defaultValue;
      }
    }
    return this.PURCHASE_showOthersReferencesTypes;
  }

  getPURCHASE_hasQuantityIncrement(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hasQuantityIncrement === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HasQuantityIncrement');
      if (settingValue != null) {
        this.PURCHASE_hasQuantityIncrement = settingValue;
      } else {
        this.PURCHASE_hasQuantityIncrement = defaultValue;
      }
    }
    return this.PURCHASE_hasQuantityIncrement;
  }

  get_PURCHASEfillNotesBasedOnNoteType(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_FillNotesBasedOnNoteType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FillNotesBasedOnNoteType');
      if (settingValue != null) {
        this.PURCHASE_FillNotesBasedOnNoteType = settingValue;
      } else {
        this.PURCHASE_FillNotesBasedOnNoteType = defaultValue;
      }
    }
    return this.PURCHASE_FillNotesBasedOnNoteType;
  }


  get_PURCHASEallowEditDocumentNumber(defaultValue: boolean): boolean {
    if (typeof this.PURCHASEallowEditDocumentNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AllowEditDocumentNumber');
      if (settingValue != null) {
        this.PURCHASEallowEditDocumentNumber = settingValue;
      } else {
        this.PURCHASEallowEditDocumentNumber = defaultValue;
      }
    }
    return this.PURCHASEallowEditDocumentNumber;
  }

  get_PURCHASErequestsUrl(defaultValue: string): string {
    if (typeof this.PURCHASErequestsUrl === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'RequestsUrl');
      if (settingValue != null) {
        this.PURCHASErequestsUrl = settingValue;
      } else {
        this.PURCHASErequestsUrl = defaultValue;
      }
    }
    return this.PURCHASErequestsUrl;
  }
  get_PURCHASEshowCARequests(defaultValue: boolean): boolean {
    if (typeof this.PURCHASEshowCARequests === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCARequests');
      if (settingValue != null) {
        this.PURCHASEshowCARequests = settingValue;
      } else {
        this.PURCHASEshowCARequests = defaultValue;
      }
    }
    return this.PURCHASEshowCARequests;
  }


  get_createOrderFromQuotation(defaultValue: boolean): boolean {
    if (typeof this.createOrderFromQuotation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'CreateOrderFromQuotation');
      if (settingValue != null) {
        this.createOrderFromQuotation = settingValue;
      } else {
        this.createOrderFromQuotation = defaultValue;
      }
    }
    return this.createOrderFromQuotation;
  }

  get_hidePurchaseProductsSupplier(defaultValue: boolean): boolean {
    if (typeof this.hidePurchaseProductsSupplier === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HidePurchaseProductsSupplier');
      if (settingValue != null) {
        this.hidePurchaseProductsSupplier = settingValue;
      } else {
        this.hidePurchaseProductsSupplier = defaultValue;
      }
    }
    return this.hidePurchaseProductsSupplier;
  }

  get_productImageAsPopover(defaultValue: boolean): boolean {
    if (typeof this.productImageAsPopover === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ProductImageAsPopover');
      if (settingValue != null) {
        this.productImageAsPopover = settingValue;
      } else {
        this.productImageAsPopover = defaultValue;
      }
    }
    return this.productImageAsPopover;
  }

  get_useUserGroups(defaultValue: boolean): boolean {
    if (typeof this.useUserGroups === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'UseUserGroups');
      if (settingValue != null) {
        this.useUserGroups = settingValue;
      } else {
        this.useUserGroups = defaultValue;
      }
    }
    return this.useUserGroups;
  }

  get_filterBySpecificUserGroup(defaultValue: string): string {
    if (typeof this.filterBySpecificUserGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'FilterBySpecificUserGroup');
      if (settingValue != null) {
        this.filterBySpecificUserGroup = settingValue;
      } else {
        this.filterBySpecificUserGroup = defaultValue;
      }
    }
    return this.filterBySpecificUserGroup;
  }

  get_useSupplierLeadtime(defaultValue: boolean): boolean {
    if (typeof this.useSupplierLeadtime === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'UseSupplierLeadtime');
      if (settingValue != null) {
        this.useSupplierLeadtime = settingValue;
      } else {
        this.useSupplierLeadtime = defaultValue;
      }
    }
    return this.useSupplierLeadtime;
  }

  get_defaultEarliestDateWaitTime(defaultValue: number): number {
    if (typeof this.defaultEarliestDateWaitTime === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DefaultEarliestDateWaitTime');
      if (settingValue != null) {
        this.defaultEarliestDateWaitTime = settingValue;
      } else {
        this.defaultEarliestDateWaitTime = defaultValue;
      }
    }
    return this.defaultEarliestDateWaitTime;
  }

  //#region defaultLatestDateWaitTime
  private _defaultLatestDateWaitTime: boolean;

  /** Data Limite Entrega por defeito */
  public get defaultLatestDateWaitTime() {
    return this._defaultLatestDateWaitTime = this.authenticationService.getBooleanSetting(false, this._defaultLatestDateWaitTime, 'OrderPurchase', 'SetLatestDateAsEarliestDateByDefault');
  }

  //#endregion defaultLatestDateWaitTime

  get_defaultOrderType(defaultValue: number): number {
    if (typeof this.defaultOrderType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DefaultOrderType');
      if (settingValue != null) {
        this.defaultOrderType = settingValue;
      } else {
        this.defaultOrderType = defaultValue;
      }
    }
    return this.defaultOrderType;
  }

  get_hidePaidCheckbox(defaultValue: boolean): boolean {
    if (typeof this.hidePaidCheckbox === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HidePaidCheckbox');
      if (settingValue != null) {
        this.hidePaidCheckbox = settingValue;
      } else {
        this.hidePaidCheckbox = defaultValue;
      }
    }
    return this.hidePaidCheckbox;
  }

  get_defaultDateTimeFormat(defaultValue: number): number {
    if (typeof this.defaultDateTimeFormat === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DefaultDateTimeFormat');
      if (settingValue != null) {
        this.defaultDateTimeFormat = settingValue;
      } else {
        this.defaultDateTimeFormat = defaultValue;
      }
    }
    return this.defaultDateTimeFormat;
  }

  get_hideExcelExportButton_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.hideExcelExportButton_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideExcelExportButton');
      if (settingValue != null) {
        this.hideExcelExportButton_PURCHASE = settingValue;
      } else {
        this.hideExcelExportButton_PURCHASE = defaultValue;
      }
    }
    return this.hideExcelExportButton_PURCHASE;
  }

  get_departmentMandatory_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.departmentMandatory_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'DepartmentMandatory');
      if (settingValue != null) {
        this.departmentMandatory_PURCHASE = settingValue;
      } else {
        this.departmentMandatory_PURCHASE = defaultValue;
      }
    }
    return this.departmentMandatory_PURCHASE;
  }

  get_validateTaxWithholding_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.validateTaxWithholding_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ValidateTaxWithholding');
      if (settingValue != null) {
        this.validateTaxWithholding_PURCHASE = settingValue;
      } else {
        this.validateTaxWithholding_PURCHASE = defaultValue;
      }
    }
    return this.validateTaxWithholding_PURCHASE;
  }

  get_showInvoicesTab_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.showInvoicesTab_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowInvoicesTab');
      if (settingValue != null) {
        this.showInvoicesTab_PURCHASE = settingValue;
      } else {
        this.showInvoicesTab_PURCHASE = defaultValue;
      }
    }
    return this.showInvoicesTab_PURCHASE;
  }

  get_showIsReceived_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.showIsReceived === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowIsReceived');
      if (settingValue != null) {
        this.showIsReceived = settingValue;
      } else {
        this.showIsReceived = defaultValue;
      }
    }
    return this.showIsReceived;
  }

  /**
   * Obtém o nome do titulo da order
   * @param  {string} defaultValue
   */
  get_PURCHASElabelOrderTitle(defaultValue: string): string {
    if (typeof this.labelOrderPurchaseTitle === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'LabelOrderTitle');
      if (settingValue != null) {
        this.labelOrderPurchaseTitle = settingValue;
      } else {
        this.labelOrderPurchaseTitle = defaultValue;
      }
    }
    return this.labelOrderPurchaseTitle;
  }


  /**
   * Obtém o nome do titulo das orders
   * @param  {string} defaultValue
   */
  get_PURCHASElabelOrdersTitle(defaultValue: string): string {
    if (typeof this.labelOrdersPurchasesTitle === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'LabelOrdersTitle');
      if (settingValue != null) {
        this.labelOrdersPurchasesTitle = settingValue;
      } else {
        this.labelOrdersPurchasesTitle = defaultValue;
      }
    }
    return this.labelOrdersPurchasesTitle;
  }

  get_setBeginingOfTheYearAsStartDate_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_setBeginingOfTheYearAsStartDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'SetBeginingOfTheYearAsStartDate');
      if (settingValue != null) {
        this.PURCHASES_setBeginingOfTheYearAsStartDate = settingValue;
      } else {
        this.PURCHASES_setBeginingOfTheYearAsStartDate = defaultValue;
      }
    }
    return this.PURCHASES_setBeginingOfTheYearAsStartDate;
  }

  get_setTodayAsEndDate_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_setTodayAsEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'SetTodayAsEndDate');
      if (settingValue != null) {
        this.PURCHASES_setTodayAsEndDate = settingValue;
      } else {
        this.PURCHASES_setTodayAsEndDate = defaultValue;
      }
    }
    return this.PURCHASES_setTodayAsEndDate;
  }

  get_hideExportDropdownMenu_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_hideExportDropdownMenu === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideExportDropdownMenu');
      if (settingValue != null) {
        this.PURCHASES_hideExportDropdownMenu = settingValue;
      } else {
        this.PURCHASES_hideExportDropdownMenu = defaultValue;
      }
    }
    return this.PURCHASES_hideExportDropdownMenu;
  }

  get_hideExportExcelButton_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_hideExportExcelButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideExportExcelButton');
      if (settingValue != null) {
        this.PURCHASES_hideExportExcelButton = settingValue;
      } else {
        this.PURCHASES_hideExportExcelButton = defaultValue;
      }
    }
    return this.PURCHASES_hideExportExcelButton;
  }

  get_allowSelectDeliveryNoteOnReception(defaultValue: boolean): boolean {
    if (typeof this.allowSelectDeliveryNoteOnReception === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'AllowSelectDeliveryNoteOnReception');
      if (settingValue != null) {
        this.allowSelectDeliveryNoteOnReception = settingValue;
      } else {
        this.allowSelectDeliveryNoteOnReception = defaultValue;
      }
    }
    return this.allowSelectDeliveryNoteOnReception;
  }

  get_guideNotMandatory(defaultValue: boolean): boolean {
    if (typeof this.guideNotMandatory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'GuideNotMandatory');
      if (settingValue != null) {
        this.guideNotMandatory = settingValue;
      } else {
        this.guideNotMandatory = defaultValue;
      }
    }
    return this.guideNotMandatory;
  }

  get_showUOMCodeOnReception(defaultValue: boolean): boolean {
    if (typeof this.showUOMCodeOnReception === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowUOMCodeOnReception');
      if (settingValue != null) {
        this.showUOMCodeOnReception = settingValue;
      } else {
        this.showUOMCodeOnReception = defaultValue;
      }
    }
    return this.showUOMCodeOnReception;
  }

  get_hideType_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_hideType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideType');
      if (settingValue != null) {
        this.PURCHASE_hideType = settingValue;
      } else {
        this.PURCHASE_hideType = defaultValue;
      }
    }
    return this.PURCHASE_hideType;
  }

  get_hideState_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.hideState_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideState');
      if (settingValue != null) {
        this.hideState_PURCHASE = settingValue;
      } else {
        this.hideState_PURCHASE = defaultValue;
      }
    }
    return this.hideState_PURCHASE;
  }
  // #endregion EncomendaCompra


  //EAN CODE hide/show
  get_purchaseHideEanCode(defaultValue: boolean): boolean {
    if (typeof this.purchaseHideEanCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideEanCode');
      if (settingValue != null) {
        this.purchaseHideEanCode = settingValue;
      } else {
        this.purchaseHideEanCode = defaultValue;
      }
    }
    return this.purchaseHideEanCode;
  }

  get_saleHideEanCode(defaultValue: boolean): boolean {
    if (typeof this.saleHideEanCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'HideEanCode');
      if (settingValue != null) {
        this.saleHideEanCode = settingValue;
      } else {
        this.saleHideEanCode = defaultValue;
      }
    }
    return this.saleHideEanCode;
  }

  // #region EncomendaVenda
  get_showOriginPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showOriginPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowOriginPanel');
      if (settingValue != null) {
        this.showOriginPanel_S = settingValue;
      } else {
        this.showOriginPanel_S = defaultValue;
      }
    }
    return this.showOriginPanel_S;
  }

  get_hideDeliveryPanel_S(defaultValue: boolean): boolean {
    if (typeof this.hideDeliveryPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'HideDeliveryPanel');
      if (settingValue != null) {
        this.hideDeliveryPanel_S = settingValue;
      } else {
        this.hideDeliveryPanel_S = defaultValue;
      }
    }
    return this.hideDeliveryPanel_S;
  }

  get_showInvoiceToPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showInvoiceToPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowInvoiceToPanel');
      if (settingValue != null) {
        this.showInvoiceToPanel_S = settingValue;
      } else {
        this.showInvoiceToPanel_S = defaultValue;
      }
    }
    return this.showInvoiceToPanel_S;
  }

  get_showObservationsPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showObservationsPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowObservationsPanel');
      if (settingValue != null) {
        this.showObservationsPanel_S = settingValue;
      } else {
        this.showObservationsPanel_S = defaultValue;
      }
    }
    return this.showObservationsPanel_S;
  }

  get_showEDIMessagePanel_S(defaultValue: boolean): boolean {
    if (typeof this.showEDIMessagePanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowEDIMessagePanel');
      if (settingValue != null) {
        this.showEDIMessagePanel_S = settingValue;
      } else {
        this.showEDIMessagePanel_S = defaultValue;
      }
    }
    return this.showEDIMessagePanel_S;
  }

  get_showPaymentConditionsPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showPaymentConditionsPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowPaymentConditionsPanel');
      if (settingValue != null) {
        this.showPaymentConditionsPanel_S = settingValue;
      } else {
        this.showPaymentConditionsPanel_S = defaultValue;
      }
    }
    return this.showPaymentConditionsPanel_S;
  }

  get_showTotalsPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showTotalsPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowTotalsPanel');
      if (settingValue != null) {
        this.showTotalsPanel_S = settingValue;
      } else {
        this.showTotalsPanel_S = defaultValue;
      }
    }
    return this.showTotalsPanel_S;
  }

  get_showBudgetsTab_S(defaultValue: boolean): boolean {
    if (typeof this.showBudgetsTab_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowBudgetsTab');
      if (settingValue != null) {
        this.showBudgetsTab_S = settingValue;
      } else {
        this.showBudgetsTab_S = defaultValue;
      }
    }
    return this.showBudgetsTab_S;
  }

  get_showDigitalFileTab_S(defaultValue: boolean): boolean {
    if (typeof this.showDigitalFileTab_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowDigitalFileTab');
      if (settingValue != null) {
        this.showDigitalFileTab_S = settingValue;
      } else {
        this.showDigitalFileTab_S = defaultValue;
      }
    }
    return this.showDigitalFileTab_S;
  }

  get_showMessagesTab_S(defaultValue: boolean): boolean {
    if (typeof this.showMessagesTab_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowMessagesTab');
      if (settingValue != null) {
        this.showMessagesTab_S = settingValue;
      } else {
        this.showMessagesTab_S = defaultValue;
      }
    }
    return this.showMessagesTab_S;
  }

  get_showEarliestAcceptableDate_S(defaultValue: boolean): boolean {
    if (typeof this.showEarliestAcceptableDate_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowEarliestAcceptableDate');
      if (settingValue != null) {
        this.showEarliestAcceptableDate_S = settingValue;
      } else {
        this.showEarliestAcceptableDate_S = defaultValue;
      }
    }
    return this.showEarliestAcceptableDate_S;
  }

  get_showLatestAcceptableDate_S(defaultValue: boolean): boolean {
    if (typeof this.showLatestAcceptableDate_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowLatestAcceptableDate');
      if (settingValue != null) {
        this.showLatestAcceptableDate_S = settingValue;
      } else {
        this.showLatestAcceptableDate_S = defaultValue;
      }
    }
    return this.showLatestAcceptableDate_S;
  }

  get_showTotalNetAmount_S(defaultValue: boolean): boolean {
    if (typeof this.showTotalNetAmount_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowTotalNetAmount');
      if (settingValue != null) {
        this.showTotalNetAmount_S = settingValue;
      } else {
        this.showTotalNetAmount_S = defaultValue;
      }
    }
    return this.showTotalNetAmount_S;
  }

  get_showCurrencyCode_S(defaultValue: boolean): boolean {
    if (typeof this.showCurrencyCode_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowCurrencyCode');
      if (settingValue != null) {
        this.showCurrencyCode_S = settingValue;
      } else {
        this.showCurrencyCode_S = defaultValue;
      }
    }
    return this.showCurrencyCode_S;
  }

  get_showBuyersTransportDocumentNumber_S(defaultValue: boolean): boolean {
    if (typeof this.showBuyersTransportDocumentNumber_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowBuyersTransportDocumentNumber');
      if (settingValue != null) {
        this.showBuyersTransportDocumentNumber_S = settingValue;
      } else {
        this.showBuyersTransportDocumentNumber_S = defaultValue;
      }
    }
    return this.showBuyersTransportDocumentNumber_S;
  }

  get_showDeliveryTerms_S(defaultValue: boolean): boolean {
    if (typeof this.showDeliveryTerms_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowDeliveryTerms');
      if (settingValue != null) {
        this.showDeliveryTerms_S = settingValue;
      } else {
        this.showDeliveryTerms_S = defaultValue;
      }
    }
    return this.showDeliveryTerms_S;
  }

  get_showBuyerDivisionCode_S(defaultValue: boolean): boolean {
    if (typeof this.showBuyerDivisionCode_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowBuyerDivisionCode');
      if (settingValue != null) {
        this.showBuyerDivisionCode_S = settingValue;
      } else {
        this.showBuyerDivisionCode_S = defaultValue;
      }
    }
    return this.showBuyerDivisionCode_S;
  }

  get_showBuyerDepartmentCode_S(defaultValue: boolean): boolean {
    if (typeof this.showBuyerDepartmentCode_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowBuyerDepartmentCode');
      if (settingValue != null) {
        this.showBuyerDepartmentCode_S = settingValue;
      } else {
        this.showBuyerDepartmentCode_S = defaultValue;
      }
    }
    return this.showBuyerDepartmentCode_S;
  }

  get_showBuyerDepartmentName_S(defaultValue: boolean): boolean {
    if (typeof this.showBuyerDepartmentName_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowBuyerDepartmentName');
      if (settingValue != null) {
        this.showBuyerDepartmentName_S = settingValue;
      } else {
        this.showBuyerDepartmentName_S = defaultValue;
      }
    }
    return this.showBuyerDepartmentName_S;
  }

  get_showCostCenter_S(defaultValue: boolean): boolean {
    if (typeof this.showCostCenter_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowCostCenter');
      if (settingValue != null) {
        this.showCostCenter_S = settingValue;
      } else {
        this.showCostCenter_S = defaultValue;
      }
    }
    return this.showCostCenter_S;
  }

  get_possibleStates_S(defaultValue: Array<number>): Array<number> {
    if (typeof this.possibleStates_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'PossibleStates');
      if (settingValue != null) {
        this.possibleStates_S = settingValue;
      } else {
        this.possibleStates_S = defaultValue;
      }
    }
    return this.possibleStates_S;
  }

  get_showEarliestAcceptableDateInDocPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showEarliestAcceptableDateInDocPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowEarliestAcceptableDateInDoc');
      if (settingValue != null) {
        this.showEarliestAcceptableDateInDocPanel_S = settingValue;
      } else {
        this.showEarliestAcceptableDateInDocPanel_S = defaultValue;
      }
    }
    return this.showEarliestAcceptableDateInDocPanel_S;
  }

  /**
 * Setting de carregamento por defeito da data final
 * @param  {boolean} defaultValue - Valor por defeito do parametro
 * @returns string
 */
  get_fillEndDate_S(defaultValue: boolean): boolean {
    if (typeof this.fillEndDate_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'FillEndDate');
      if (settingValue !== null) {
        this.fillEndDate_S = settingValue;
      } else {
        this.fillEndDate_S = defaultValue;
      }
    }
    return this.fillEndDate_S;
  }

  get_showStateHistoryModalUserName_S(defaultValue: boolean): boolean {
    if (typeof this.showStateHistoryModalUserName_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'ShowStateHistoryModalUserName');
      if (settingValue !== null) {
        this.showStateHistoryModalUserName_S = settingValue;
      } else {
        this.showStateHistoryModalUserName_S = defaultValue;
      }
    }
    return this.showStateHistoryModalUserName_S;
  }

  get_showMeansOfPayment_S(defaultValue: boolean): boolean {
    if (typeof this.showMeansOfPayment_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowMeansOfPayment');
      if (settingValue != null) {
        this.showMeansOfPayment_S = settingValue;
      } else {
        this.showMeansOfPayment_S = defaultValue;
      }
    }
    return this.showMeansOfPayment_S;
  }

  get_showCurrencyExchange_S(defaultValue: boolean): boolean {
    if (typeof this.showCurrencyExchange_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowCurrencyExchange');
      if (settingValue != null) {
        this.showCurrencyExchange_S = settingValue;
      } else {
        this.showCurrencyExchange_S = defaultValue;
      }
    }
    return this.showCurrencyExchange_S;
  }

  getSALE_showOthersReferencesTypes(defaultValue: boolean): boolean {
    if (typeof this.SALE_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowOthersReferencesTypes');
      if (settingValue != null) {
        this.SALE_showOthersReferencesTypes = settingValue;
      } else {
        this.SALE_showOthersReferencesTypes = defaultValue;
      }
    }
    return this.SALE_showOthersReferencesTypes;
  }

  get_showInvoiceToTaxNumber_S(defaultValue: boolean): boolean {
    if (typeof this.showInvoiceToTaxNumber_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowInvoiceToTaxNumber');
      if (settingValue != null) {
        this.showInvoiceToTaxNumber_S = settingValue;
      } else {
        this.showInvoiceToTaxNumber_S = defaultValue;
      }
    }
    return this.showInvoiceToTaxNumber_S;
  }

  get_showDescriptionAsTextArea_SALE(defaultValue: boolean): boolean {
    if (typeof this.showDescriptionAsTextArea_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowDescriptionAsTextArea');
      if (settingValue != null) {
        this.showDescriptionAsTextArea_SALE = settingValue;
      } else {
        this.showDescriptionAsTextArea_SALE = defaultValue;
      }
    }
    return this.showDescriptionAsTextArea_SALE;
  }

  /**
* Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
* @param  {Array<number>} defaultValue - Valor por defeito do parametro
* @param  {Array<string>} columnsNames - nomes das colunas
* @returns Array
*/
  get_ColumnInVisible_S(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('OrdersSales', 'ColumVisibleSP');
      this.columnInVisible_S = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_S;
  }

  get_SALESfillNotesBasedOnNoteType(defaultValue: boolean): boolean {
    if (typeof this.SALE_FillNotesBasedOnNoteType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'FillNotesBasedOnNoteType');
      if (settingValue != null) {
        this.SALE_FillNotesBasedOnNoteType = settingValue;
      } else {
        this.SALE_FillNotesBasedOnNoteType = defaultValue;
      }
    }
    return this.SALE_FillNotesBasedOnNoteType;
  }

  get_SALEallowEditDocumentNumber(defaultValue: boolean): boolean {
    if (typeof this.SALEallowEditDocumentNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'AllowEditDocumentNumber');
      if (settingValue != null) {
        this.SALEallowEditDocumentNumber = settingValue;
      } else {
        this.SALEallowEditDocumentNumber = defaultValue;
      }
    }
    return this.SALEallowEditDocumentNumber;
  }

  get_SALEshowPanelPaymentConditions(defaultValue: boolean): boolean {
    if (typeof this.SALEshowPanelPaymentConditions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowPanelPaymentConditions');
      if (settingValue != null) {
        this.SALEshowPanelPaymentConditions = settingValue;
      } else {
        this.SALEshowPanelPaymentConditions = defaultValue;
      }
    }
    return this.SALEshowPanelPaymentConditions;
  }

  get_SALEshowAttachesIsPublic(defaultValue: boolean): boolean {
    if (typeof this.SALEshowAttachesIsPublic === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowAttachesIsPublic');
      if (settingValue != null) {
        this.SALEshowAttachesIsPublic = settingValue;
      } else {
        this.SALEshowAttachesIsPublic = defaultValue;
      }
    }
    return this.SALEshowAttachesIsPublic;
  }

  get_SALEshowTaxTypeCodeOther(defaultValue: boolean): boolean {
    if (typeof this.SALEshowTaxTypeCodeOther === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ShowTaxTypeCodeOther');
      if (settingValue != null) {
        this.SALEshowTaxTypeCodeOther = settingValue;
      } else {
        this.SALEshowTaxTypeCodeOther = defaultValue;
      }
    }
    return this.SALEshowTaxTypeCodeOther;
  }

  /**
   * Obtém o nome do titulo da order
   * @param  {string} defaultValue
   */
  get_SALElabelOrderTitle(defaultValue: string): string {
    if (typeof this.labelOrderSaleTitle === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'LabelOrderTitle');
      if (settingValue != null) {
        this.labelOrderSaleTitle = settingValue;
      } else {
        this.labelOrderSaleTitle = defaultValue;
      }
    }
    return this.labelOrderSaleTitle;
  }

  /**
   * Obtém o nome do titulo das orders
   * @param  {string} defaultValue
   */
  get_SALElabelOrdersTitle(defaultValue: string): string {
    if (typeof this.labelOrdersSalesTitle === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'LabelOrdersTitle');
      if (settingValue != null) {
        this.labelOrdersSalesTitle = settingValue;
      } else {
        this.labelOrdersSalesTitle = defaultValue;
      }
    }
    return this.labelOrdersSalesTitle;
  }

  get_hideType_SALE(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'HideType');
      if (settingValue != null) {
        this.SALE_hideType = settingValue;
      } else {
        this.SALE_hideType = defaultValue;
      }
    }
    return this.SALE_hideType;

  }

  get_netUnitPriceLabel_SALE(defaultValue: string): string {
    if (typeof this.netUnitPriceLabel_SALE === 'undefined') { // verificar se ainda nao tem valor
    } let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'NetUnitPriceLabel');
    if (settingValue != null) {
      this.netUnitPriceLabel_SALE = settingValue;
    } else {
      this.netUnitPriceLabel_SALE = defaultValue;
    }

    return this.netUnitPriceLabel_SALE;
  }

  get_hideState_SALE(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideState === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'HideState');
      if (settingValue != null) {
        this.SALE_hideState = settingValue;
      } else {
        this.SALE_hideState = defaultValue;
      }
    }
    return this.SALE_hideState;
  }

  get_hideClientPanel_SALE(defaultValue: boolean): boolean {
    if (typeof this.SALE_hideClientPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'HideClientPanel');
      if (settingValue != null) {
        this.SALE_hideClientPanel = settingValue;
      } else {
        this.SALE_hideClientPanel = defaultValue;
      }
    }
    return this.SALE_hideClientPanel;
  }


  get_showNewOrderColor(defaultValue: boolean): boolean {
    if (typeof this.showNewOrderColor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'ShowNewOrderColor');
      if (settingValue != null) {
        this.showNewOrderColor = settingValue;
      } else {
        this.showNewOrderColor = defaultValue;
      }
    }
    return this.showNewOrderColor;
  }
  // #endregion EncomendaVenda





  // #region DetalheEncomendaCompra
  get_showPackagingPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showPackagingPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowPackagingPanel');
      if (settingValue != null) {
        this.showPackagingPanel_P = settingValue;
      } else {
        this.showPackagingPanel_P = defaultValue;
      }
    }
    return this.showPackagingPanel_P;
  }

  get_showTaxesPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showTaxesPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowTaxesPanel');
      if (settingValue != null) {
        this.showTaxesPanel_P = settingValue;
      } else {
        this.showTaxesPanel_P = defaultValue;
      }
    }
    return this.showTaxesPanel_P;
  }

  get_showNotesPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showNotesPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowNotesPanel');
      if (settingValue != null) {
        this.showNotesPanel_P = settingValue;
      } else {
        this.showNotesPanel_P = defaultValue;
      }
    }
    return this.showNotesPanel_P;
  }

  get_showAllowancesPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showAllowancesPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValueSP = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowAllowancesPanelSP');
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowAllowancesPanelNonSP');
      if (!this.authenticationService.session.company.ServiceProvider && settingValue != null) {
        this.showAllowancesPanel_P = settingValue;
      } else if (settingValueSP != null) {
        this.showAllowancesPanel_P = settingValueSP;
      } else {
        this.showAllowancesPanel_P = defaultValue;
      }
    }
    return this.showAllowancesPanel_P;
  }

  get_showDiscountsPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showDiscountsPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValueSP = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDiscountsPanelSP');
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDiscountsPanelNonSP');
      if (!this.authenticationService.session.company.ServiceProvider && settingValue != null) {
        this.showDiscountsPanel_P = settingValue;
      } else if (settingValueSP != null) {
        this.showDiscountsPanel_P = settingValueSP;
      } else {
        this.showDiscountsPanel_P = defaultValue;
      }
    }
    return this.showDiscountsPanel_P;
  }

  get_showReferencesPanel_P(defaultValue: boolean): boolean {
    if (typeof this.showReferencesPanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowReferencesPanel');
      if (settingValue != null) {
        this.showReferencesPanel_P = settingValue;
      } else {
        this.showReferencesPanel_P = defaultValue;
      }
    }
    return this.showReferencesPanel_P;
  }

  get_showStatePanel_P(defaultValue: boolean): boolean {
    if (typeof this.showStatePanel_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowStatePanel');
      if (settingValue != null) {
        this.showStatePanel_P = settingValue;
      } else {
        this.showStatePanel_P = defaultValue;
      }
    }
    return this.showStatePanel_P;
  }

  get_isRequiredDeliveryDate_P(defaultValue: boolean): boolean {
    if (typeof this.isRequiredDeliveryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DeliveryDateRequired');
      if (settingValue != null) {
        this.isRequiredDeliveryDate = settingValue;
      } else {
        this.isRequiredDeliveryDate = defaultValue;
      }
    }
    return this.isRequiredDeliveryDate;
  }

  get_isOrderedQuantityUOMCodeRequired_P(defaultValue: boolean): boolean {
    if (typeof this.isOrderedQuantityUOMCodeRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'IsOrderedQuantityUOMCodeRequired');
      if (settingValue != null) {
        this.isOrderedQuantityUOMCodeRequired = settingValue;
      } else {
        this.isOrderedQuantityUOMCodeRequired = defaultValue;
      }
    }
    return this.isOrderedQuantityUOMCodeRequired;
  }

  // campo panel Base
  get_showFamily_P(defaultValue: boolean): boolean {
    if (typeof this.showFamily_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowFamily');
      if (settingValue != null) {
        this.showFamily_P = settingValue;
      } else {
        this.showFamily_P = defaultValue;
      }
    }
    return this.showFamily_P;
  }

  // campos panel Quantidades e Preços
  get_showOrderedQuantityUOMCode_P(defaultValue: boolean): boolean {
    if (typeof this.showOrderedQuantityUOMCode_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowOrderedQuantityUOMCode');
      if (settingValue != null) {
        this.showOrderedQuantityUOMCode_P = settingValue;
      } else {
        this.showOrderedQuantityUOMCode_P = defaultValue;
      }
    }
    return this.showOrderedQuantityUOMCode_P;
  }

  get_showBonusQuantity_P(defaultValue: boolean): boolean {
    if (typeof this.showBonusQuantity_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowBonusQuantity');
      if (settingValue != null) {
        this.showBonusQuantity_P = settingValue;
      } else {
        this.showBonusQuantity_P = defaultValue;
      }
    }
    return this.showBonusQuantity_P;
  }

  get_showBonusQuantityUOM_P(defaultValue: boolean): boolean {
    if (typeof this.showBonusQuantityUOM_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowBonusQuantityUOM');
      if (settingValue != null) {
        this.showBonusQuantityUOM_P = settingValue;
      } else {
        this.showBonusQuantityUOM_P = defaultValue;
      }
    }
    return this.showBonusQuantityUOM_P;
  }

  get_showNetUnitPriceDetail_P(defaultValue: boolean): boolean {
    if (typeof this.showNetUnitPrice_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowNetUnitPrice');
      if (settingValue != null) {
        this.showNetUnitPrice_P = settingValue;
      } else {
        this.showNetUnitPrice_P = defaultValue;
      }
    }
    return this.showNetUnitPrice_P;
  }

  get_showTotalNetAmountDetail_P(defaultValue: boolean): boolean {
    if (typeof this.showTotalNetAmountDetail_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowTotalNetAmount');
      if (settingValue != null) {
        this.showTotalNetAmountDetail_P = settingValue;
      } else {
        this.showTotalNetAmountDetail_P = defaultValue;
      }
    }
    return this.showTotalNetAmountDetail_P;
  }

  get_showGrossUnitPrice_P(defaultValue: boolean): boolean {
    if (typeof this.showGrossUnitPrice_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowGrossUnitPrice');
      if (settingValue != null) {
        this.showGrossUnitPrice_P = settingValue;
      } else {
        this.showGrossUnitPrice_P = defaultValue;
      }
    }
    return this.showGrossUnitPrice_P;
  }

  get_showTotalGrossAmount_P(defaultValue: boolean): boolean {
    if (typeof this.showTotalGrossAmount_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.showTotalGrossAmount_P = settingValue;
      } else {
        this.showTotalGrossAmount_P = defaultValue;
      }
    }
    return this.showTotalGrossAmount_P;
  }

  // produtos
  get_showButtonAddNewDetail_P(defaultValue: boolean): boolean {
    if (typeof this.allowCreateEmptyDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AllowCreateEmptyDetail');
      if (settingValue != null) {
        this.allowCreateEmptyDetail = settingValue;
      } else {
        this.allowCreateEmptyDetail = defaultValue;
      }
    }
    return this.allowCreateEmptyDetail;
  }

  get_showInvoiceToTaxNumber(defaultValue: boolean): boolean {
    if (typeof this.showInvoiceToTaxNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowInvoiceToTaxNumber');
      if (settingValue != null) {
        this.showInvoiceToTaxNumber = settingValue;
      } else {
        this.showInvoiceToTaxNumber = defaultValue;
      }
    }
    return this.showInvoiceToTaxNumber;
  }

  get_warnUserWhenUnsavedChanges(defaultValue: boolean): boolean {
    if (typeof this.warnUserWhenUnsavedChanges === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'WarnUserWhenUnsavedChanges');
      if (settingValue != null) {
        this.warnUserWhenUnsavedChanges = settingValue;
      } else {
        this.warnUserWhenUnsavedChanges = defaultValue;
      }
    }
    return this.warnUserWhenUnsavedChanges;
  }

  get_otherRefFields_P(defaultValue: Array<string>): Array<string> {
    if (typeof this.otherRefFields_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'OtherRefFields');
      if (settingValue != null) {
        this.otherRefFields_P = settingValue;
      } else {
        this.otherRefFields_P = defaultValue;
      }
    }
    return this.otherRefFields_P;
  }

  get_showPanelWorkFlow_P(defaultValue: boolean): boolean {
    if (typeof this.showPanelWorkFlow === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowPanelWorkFlow');
      if (settingValue != null) {
        this.showPanelWorkFlow = settingValue;
      } else {
        this.showPanelWorkFlow = defaultValue;
      }
    }
    return this.showPanelWorkFlow;
  }

  get_otherRefFields_S(defaultValue: Array<string>): Array<string> {
    if (typeof this.otherRefFields_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'OtherRefFields');
      if (settingValue != null) {
        this.otherRefFields_S = settingValue;
      } else {
        this.otherRefFields_S = defaultValue;
      }
    }
    return this.otherRefFields_S;
  }

  get_defaultDateTimeFormat_SALES(defaultValue: number): number {
    if (typeof this.defaultDateTimeFormat_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'DefaultDateTimeFormat');
      if (settingValue != null) {
        this.defaultDateTimeFormat_SALE = settingValue;
      } else {
        this.defaultDateTimeFormat_SALE = defaultValue;
      }
    }
    return this.defaultDateTimeFormat_SALE;
  }


  get_setBeginingOfTheYearAsStartDate_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_setBeginingOfTheYearAsStartDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'SetBeginingOfTheYearAsStartDate');
      if (settingValue != null) {
        this.SALES_setBeginingOfTheYearAsStartDate = settingValue;
      } else {
        this.SALES_setBeginingOfTheYearAsStartDate = defaultValue;
      }
    }
    return this.SALES_setBeginingOfTheYearAsStartDate;
  }

  get_setTodayAsEndDate_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_setTodayAsEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'SetTodayAsEndDate');
      if (settingValue != null) {
        this.SALES_setTodayAsEndDate = settingValue;
      } else {
        this.SALES_setTodayAsEndDate = defaultValue;
      }
    }
    return this.SALES_setTodayAsEndDate;
  }

  get_hideExportDropdownMenu_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_hideExportDropdownMenu === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'HideExportDropdownMenu');
      if (settingValue != null) {
        this.SALES_hideExportDropdownMenu = settingValue;
      } else {
        this.SALES_hideExportDropdownMenu = defaultValue;
      }
    }
    return this.SALES_hideExportDropdownMenu;
  }

  get_hideExportExcelButton_SALES(defaultValue: boolean): boolean {
    if (typeof this.SALES_hideExportExcelButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'HideExportExcelButton');
      if (settingValue != null) {
        this.SALES_hideExportExcelButton = settingValue;
      } else {
        this.SALES_hideExportExcelButton = defaultValue;
      }
    }
    return this.SALES_hideExportExcelButton;
  }

  get_validateDetailsDateOnSupplierResponse(defaultValue: boolean): boolean {
    if (typeof this.validateDetailsDateOnSupplierResponse === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersSales', 'ValidateDetailsDateOnSupplierResponse');
      if (settingValue != null) {
        this.validateDetailsDateOnSupplierResponse = settingValue;
      } else {
        this.validateDetailsDateOnSupplierResponse = defaultValue;
      }
    }
    return this.validateDetailsDateOnSupplierResponse;
  }

  //-----------------------------ORDER PURCHASE DETAILS--------------------------//
  get_showProductsAutomatically_P(defaultValue: boolean): boolean {
    if (typeof this.showProductsAutomatically === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowProductsAutomatically');
      if (settingValue != null) {
        this.showProductsAutomatically = settingValue;
      } else {
        this.showProductsAutomatically = defaultValue;
      }
    }
    return this.showProductsAutomatically;
  }

  // campos panel Embalagens
  get_showPackQuantityUOM_P(defaultValue: boolean): boolean {
    if (typeof this.showPackQuantityUOM_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowPackQuantityUOM');
      if (settingValue != null) {
        this.showPackQuantityUOM_P = settingValue;
      } else {
        this.showPackQuantityUOM_P = defaultValue;
      }
    }
    return this.showPackQuantityUOM_P;
  }

  get_showPackUnitsPerLayer_P(defaultValue: boolean): boolean {
    if (typeof this.showPackUnitsPerLayer_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowPackUnitsPerLayer');
      if (settingValue != null) {
        this.showPackUnitsPerLayer_P = settingValue;
      } else {
        this.showPackUnitsPerLayer_P = defaultValue;
      }
    }
    return this.showPackUnitsPerLayer_P;
  }

  // campos obrigatórios
  get_isRequiredBuyerProductCode(defaultValue: boolean): boolean {
    if (typeof this.isRequiredBuyerProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'IsRequiredBuyerProductCode');
      if (settingValue != null) {
        this.isRequiredBuyerProductCode = settingValue;
      } else {
        this.isRequiredBuyerProductCode = defaultValue;
      }
    }
    return this.isRequiredBuyerProductCode;
  }

  get_deliveryDateRequiredDetail(defaultValue: boolean): boolean {
    if (typeof this.deliveryDateRequiredDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DeliveryDateRequired');
      if (settingValue != null) {
        this.deliveryDateRequiredDetail = settingValue;
      } else {
        this.deliveryDateRequiredDetail = defaultValue;
      }
    }
    return this.deliveryDateRequiredDetail;
  }

  get_showDetailDeliveryPanel(defaultValue: boolean): boolean {
    if (typeof this.showDetailDeliveryPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDetailDeliveryPanel');
      if (settingValue != null) {
        this.showDetailDeliveryPanel = settingValue;
      } else {
        this.showDetailDeliveryPanel = defaultValue;
      }
    }
    return this.showDetailDeliveryPanel;
  }

  get_isRequiredDeliveryPlaceGroupDetail(defaultValue: boolean): boolean {
    if (typeof this.isRequiredDeliveryPlaceGroupDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'IsRequiredDeliveryPlaceGroupDetail');
      if (settingValue != null) {
        this.isRequiredDeliveryPlaceGroupDetail = settingValue;
      } else {
        this.isRequiredDeliveryPlaceGroupDetail = defaultValue;
      }
    }
    return this.isRequiredDeliveryPlaceGroupDetail;
  }

  get_deliveryDateRequiredNonSP(defaultValue: boolean): boolean {
    if (typeof this.deliveryDateRequiredNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DeliveryDateRequiredNonSP');
      if (settingValue != null) {
        this.deliveryDateRequiredNonSP = settingValue;
      } else {
        this.deliveryDateRequiredNonSP = defaultValue;
      }
    }
    return this.deliveryDateRequiredNonSP;
  }

  get_isRequiredDeliveryPlaceGroupDetailNonSP(defaultValue: boolean): boolean {
    if (typeof this.isRequiredDeliveryPlaceGroupDetailNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'IsRequiredDeliveryPlaceGroupDetailNonSP');
      if (settingValue != null) {
        this.isRequiredDeliveryPlaceGroupDetailNonSP = settingValue;
      } else {
        this.isRequiredDeliveryPlaceGroupDetailNonSP = defaultValue;
      }
    }
    return this.isRequiredDeliveryPlaceGroupDetailNonSP;
  }

  get_showDeliveryDateOnBasePanel(defaultValue: boolean): boolean {
    if (typeof this.showDeliveryDateOnBasePanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDeliveryDateOnBasePanel');
      if (settingValue != null) {
        this.showDeliveryDateOnBasePanel = settingValue;
      } else {
        this.showDeliveryDateOnBasePanel = defaultValue;
      }
    }
    return this.showDeliveryDateOnBasePanel;
  }

  get_showDeliveryDateOnBasePanel_S(defaultValue: boolean): boolean {
    if (typeof this.showDeliveryDateOnBasePanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowDeliveryDateOnBasePanel');
      if (settingValue != null) {
        this.showDeliveryDateOnBasePanel_S = settingValue;
      } else {
        this.showDeliveryDateOnBasePanel_S = defaultValue;
      }
    }
    return this.showDeliveryDateOnBasePanel_S;
  }

  get_disableBuyerProductCodeSP(defaultValue: boolean): boolean {
    if (typeof this.disableBuyerProductCodeSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableBuyerProductCodeSP');
      if (settingValue != null) {
        this.disableBuyerProductCodeSP = settingValue;
      } else {
        this.disableBuyerProductCodeSP = defaultValue;
      }
    }
    return this.disableBuyerProductCodeSP;
  }

  get_disableSupplierProductCodeNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableSupplierProductCodeNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableSupplierProductCodeNonSP');
      if (settingValue != null) {
        this.disableSupplierProductCodeNonSP = settingValue;
      } else {
        this.disableSupplierProductCodeNonSP = defaultValue;
      }
    }
    return this.disableSupplierProductCodeNonSP;
  }

  get_disableStandardProductCodeNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableStandardProductCodeNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableStandardProductCodeNonSP');
      if (settingValue != null) {
        this.disableStandardProductCodeNonSP = settingValue;
      } else {
        this.disableStandardProductCodeNonSP = defaultValue;
      }
    }
    return this.disableStandardProductCodeNonSP;
  }

  get_disableProductDescriptionNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableProductDescriptionNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableProductDescriptionNonSP');
      if (settingValue != null) {
        this.disableProductDescriptionNonSP = settingValue;
      } else {
        this.disableProductDescriptionNonSP = defaultValue;
      }
    }
    return this.disableStandardProductCodeNonSP;
  }

  get_disableOrderedQuantityUomNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableOrderedQuantityUomNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableOrderedQuantityUomNonSP');
      if (settingValue != null) {
        this.disableOrderedQuantityUomNonSP = settingValue;
      } else {
        this.disableOrderedQuantityUomNonSP = defaultValue;
      }
    }
    return this.disableOrderedQuantityUomNonSP;
  }

  get_disableNetUnitPriceNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableNetUnitPriceNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableNetUnitPriceNonSP');
      if (settingValue != null) {
        this.disableNetUnitPriceNonSP = settingValue;
      } else {
        this.disableNetUnitPriceNonSP = defaultValue;
      }
    }
    return this.disableNetUnitPriceNonSP;
  }

  get_disableTotalNetAmountNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableTotalNetAmountNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableTotalNetAmountNonSP');
      if (settingValue != null) {
        this.disableTotalNetAmountNonSP = settingValue;
      } else {
        this.disableTotalNetAmountNonSP = defaultValue;
      }
    }
    return this.disableTotalNetAmountNonSP;
  }

  get_disableGrossUnitPriceNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableGrossUnitPriceNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableGrossUnitPriceNonSP');
      if (settingValue != null) {
        this.disableGrossUnitPriceNonSP = settingValue;
      } else {
        this.disableGrossUnitPriceNonSP = defaultValue;
      }
    }
    return this.disableGrossUnitPriceNonSP;
  }

  get_disableTotalGrossAmountNonSP(defaultValue: boolean): boolean {
    if (typeof this.disableTotalGrossAmountNonSP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableTotalGrossAmountNonSP');
      if (settingValue != null) {
        this.disableTotalGrossAmountNonSP = settingValue;
      } else {
        this.disableTotalGrossAmountNonSP = defaultValue;
      }
    }
    return this.disableTotalGrossAmountNonSP;
  }

  get_disableTaxes_P(defaultValue: boolean): boolean {
    if (typeof this.disableTaxes_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableTaxes');
      if (settingValue != null) {
        this.disableTaxes_P = settingValue;
      } else {
        this.disableTaxes_P = defaultValue;
      }
    }
    return this.disableTaxes_P;
  }

  /*
  get_showDiscountNonSP_P(defaultValue: boolean): boolean {
    if (typeof this.showDiscountNonSP_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDiscountNonSP');
      if (settingValue != null) {
        this.showDiscountNonSP_P = settingValue;
      } else {
        this.showDiscountNonSP_P = defaultValue;
      }
    }
    return this.showDiscountNonSP_P;
  }

  get_showAllowancesNonSP_P(defaultValue: boolean): boolean {
    if (typeof this.showAllowancesNonSP_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowAllowancesNonSP');
      if (settingValue != null) {
        this.showAllowancesNonSP_P = settingValue;
      } else {
        this.showAllowancesNonSP_P = defaultValue;
      }
    }
    return this.showAllowancesNonSP_P;
  }
  */
  get_discountsEditableNonSP_P(defaultValue: boolean): boolean {
    if (typeof this.discountsEditableNonSP_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DiscountsEditableNonSP');
      if (settingValue != null) {
        this.discountsEditableNonSP_P = settingValue;
      } else {
        this.discountsEditableNonSP_P = defaultValue;
      }
    }
    return this.discountsEditableNonSP_P;
  }

  get_noDetailsPagination(defaultValue: boolean): boolean {
    if (typeof this.noDetailsPagination === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'NoDetailsPagination');
      if (settingValue != null) {
        this.noDetailsPagination = settingValue;
      } else {
        this.noDetailsPagination = defaultValue;
      }
    }
    return this.noDetailsPagination;
  }

  get_showDistributionExpenseAccount(defaultValue: boolean): boolean {
    if (typeof this.showDistributionExpenseAccount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionExpenseAccount');
      if (settingValue !== null) {
        this.showDistributionExpenseAccount = settingValue;
      } else {
        this.showDistributionExpenseAccount = defaultValue;
      }
    }
    return this.showDistributionExpenseAccount;
  }

  get_showDistributionInitiativeCode(defaultValue: boolean): boolean {
    if (typeof this.showDistributionInitiativeCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionInitiativeCode');
      if (settingValue !== null) {
        this.showDistributionInitiativeCode = settingValue;
      } else {
        this.showDistributionInitiativeCode = defaultValue;
      }
    }
    return this.showDistributionInitiativeCode;
  }

  get_showDistributionActivityCode(defaultValue: boolean): boolean {
    if (typeof this.showDistributionActivityCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionActivityCode');
      if (settingValue !== null) {
        this.showDistributionActivityCode = settingValue;
      } else {
        this.showDistributionActivityCode = defaultValue;
      }
    }
    return this.showDistributionActivityCode;
  }

  get_showDistributionFixedAssetRecordType(defaultValue: boolean): boolean {
    if (typeof this.showDistributionFixedAssetRecordType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionFixedAssetRecordType');
      if (settingValue != null) {
        this.showDistributionFixedAssetRecordType = settingValue;
      } else {
        this.showDistributionFixedAssetRecordType = defaultValue;
      }
    }
    return this.showDistributionFixedAssetRecordType;
  }

  get_showDistributionAmortizationBookCode(defaultValue: boolean): boolean {
    if (typeof this.showDistributionAmortizationBookCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionAmortizationBookCode');
      if (settingValue != null) {
        this.showDistributionAmortizationBookCode = settingValue;
      } else {
        this.showDistributionAmortizationBookCode = defaultValue;
      }
    }
    return this.showDistributionAmortizationBookCode;
  }

  get_showDistributionVATaccountingGroup(defaultValue: boolean): boolean {
    if (typeof this.showDistributionVATaccountingGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionVATaccountingGroup');
      if (settingValue != null) {
        this.showDistributionVATaccountingGroup = settingValue;
      } else {
        this.showDistributionVATaccountingGroup = defaultValue;
      }
    }
    return this.showDistributionVATaccountingGroup;
  }

  get_showDistributionRefactor(defaultValue: boolean): boolean {
    if (typeof this.showDistributionRefactor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionRefactor');
      if (settingValue != null) {
        this.showDistributionRefactor = settingValue;
      } else {
        this.showDistributionRefactor = defaultValue;
      }
    }
    return this.showDistributionRefactor;
  }

  get_showDistributionAccountingType(defaultValue: boolean): boolean {
    if (typeof this.showDistributionAccountingType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionAccountingType');
      if (settingValue != null) {
        this.showDistributionAccountingType = settingValue;
      } else {
        this.showDistributionAccountingType = defaultValue;
      }
    }
    return this.showDistributionAccountingType;
  }

  get_useAccountingConfig(defaultValue: boolean): boolean {
    if (typeof this.useAccountingConfig === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'UseAccountingConfig');
      if (settingValue != null) {
        this.useAccountingConfig = settingValue;
      } else {
        this.useAccountingConfig = defaultValue;
      }
    }
    return this.useAccountingConfig;
  }

  getShowDistributionDetails(defaultValue: boolean): boolean {
    if (typeof this.showDistributionDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionDetails');
      if (settingValue != null) {
        this.showDistributionDetails = settingValue
      } else {
        this.showDistributionDetails = defaultValue;
      }
    }
    return this.showDistributionDetails;
  }

  get_PURCHASEgetProductServiceType(defaultValue: boolean): boolean {
    if (typeof this.PURCHASEgetProductServiceType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'GetProductServiceType');
      if (settingValue !== null) {
        this.PURCHASEgetProductServiceType = settingValue;
      } else {
        this.PURCHASEgetProductServiceType = defaultValue;
      }
    }
    return this.PURCHASEgetProductServiceType;
  }

  get_PURCHASEdistributionButtonLabel(defaultValue: string): string {
    if (typeof this.PURCHASEdistributionButtonLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DistributionButtonLabel');
      if (settingValue !== null) {
        this.PURCHASEdistributionButtonLabel = settingValue;
      } else {
        this.PURCHASEdistributionButtonLabel = defaultValue;
      }
    }
    return this.PURCHASEdistributionButtonLabel;
  }

  get_PURCHASEnetUnitPriceLabel(defaultValue: string): string {
    if (typeof this.PURCHASEnetUnitPriceLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'NetUnitPriceLabel');
      if (settingValue !== null) {
        this.PURCHASEnetUnitPriceLabel = settingValue;
      } else {
        this.PURCHASEnetUnitPriceLabel = defaultValue;
      }
    }
    return this.PURCHASEnetUnitPriceLabel;
  }

  get_showDescriptionAsTextArea_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.showDescriptionAsTextArea_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDescriptionAsTextArea');
      if (settingValue !== null) {
        this.showDescriptionAsTextArea_PURCHASE = settingValue;
      } else {
        this.showDescriptionAsTextArea_PURCHASE = defaultValue;
      }
    }
    return this.showDescriptionAsTextArea_PURCHASE;
  }

  get_PURCHASEshowAccountingVatGroup(defaultValue: boolean): boolean {
    if (typeof this.PURCHASEshowAccountingVatGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowAccountingVatGroup');
      if (settingValue != null) {
        this.PURCHASEshowAccountingVatGroup = settingValue;
      } else {
        this.PURCHASEshowAccountingVatGroup = defaultValue;
      }
    }
    return this.PURCHASEshowAccountingVatGroup;
  }

  get_PURCHASEmaxSupplierProductCodeLength(defaultValue: number): number {
    if (typeof this.PURCHASEmaxSupplierProductCodeLength === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'MaxSupplierProductCodeLength');
      if (settingValue != null) {
        this.PURCHASEmaxSupplierProductCodeLength = settingValue;
      } else {
        this.PURCHASEmaxSupplierProductCodeLength = defaultValue;
      }
    }
    return this.PURCHASEmaxSupplierProductCodeLength;
  }

  get_PURCHASEshowDistributionVatTreatmentType(defaultValue: boolean): boolean {
    if (typeof this.PURCHASEshowDistributionVatTreatmentType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionVatTreatmentType');
      if (settingValue != null) {
        this.PURCHASEshowDistributionVatTreatmentType = settingValue;
      } else {
        this.PURCHASEshowDistributionVatTreatmentType = defaultValue;
      }
    }
    return this.PURCHASEshowDistributionVatTreatmentType;
  }

  get_PURCHASEshowDistributionCodMensualizacao(defaultValue: boolean): boolean {
    if (typeof this.PURCHASEshowDistributionCodMensualizacao === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowDistributionCodMensualizacao');
      if (settingValue != null) {
        this.PURCHASEshowDistributionCodMensualizacao = settingValue;
      } else {
        this.PURCHASEshowDistributionCodMensualizacao = defaultValue;
      }
    }
    return this.PURCHASEshowDistributionCodMensualizacao;
  }

  get_useProductCostCenter(defaultValue: boolean): boolean {
    if (typeof this.useProductCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'UseProductCostCenter');
      if (settingValue != null) {
        this.useProductCostCenter = settingValue;
      } else {
        this.useProductCostCenter = defaultValue;
      }
    }
    return this.useProductCostCenter;
  }

  get_showCostCenterDetail(defaultValue: boolean): boolean {
    if (typeof this.showCostCenterDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowCostCenterDetail');
      if (settingValue != null) {
        this.showCostCenterDetail = settingValue;
      } else {
        this.showCostCenterDetail = defaultValue;
      }
    }
    return this.showCostCenterDetail;
  }

  get_useProductReclassificationCode(defaultValue: boolean): boolean {
    if (typeof this.useProductReclassificationCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'UseProductReclassificationCode');
      if (settingValue != null) {
        this.useProductReclassificationCode = settingValue;
      } else {
        this.useProductReclassificationCode = defaultValue;
      }
    }
    return this.useProductReclassificationCode;
  }
  get_validateSupplierAnswer(defaultValue: boolean): boolean {
    if (typeof this.validateSupplierAnswer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ValidateSupplierAnswer');
      if (settingValue != null) {
        this.validateSupplierAnswer = settingValue;
      } else {
        this.validateSupplierAnswer = defaultValue;
      }
    }
    return this.validateSupplierAnswer;
  }

  get_viewSupplierConfirmationResponses(defaultValue: boolean): boolean {
    if (typeof this.viewSupplierConfirmationResponses === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ViewSupplierConfirmationResponses');
      if (settingValue != null) {
        this.viewSupplierConfirmationResponses = settingValue;
      } else {
        this.viewSupplierConfirmationResponses = defaultValue;
      }
    }
    return this.viewSupplierConfirmationResponses;
  }

  get_popupRequired(defaultValue: boolean): boolean {
    if (typeof this.popupRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'PopupRequired');
      if (settingValue != null) {
        this.popupRequired = settingValue;
      } else {
        this.popupRequired = defaultValue;
      }
    }
    return this.popupRequired;
  }

  get_showShippingDatePurchase(defaultValue: boolean): boolean {
    if (typeof this.showShippingDatePurchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowShippingDate');
      if (settingValue != null) {
        this.showShippingDatePurchase = settingValue;
      } else {
        this.showShippingDatePurchase = defaultValue;
      }
    }
    return this.showShippingDatePurchase;
  }

  get_showPriceUnitPurchase(defaultValue: boolean): boolean {
    if (typeof this.showUnitPricePurchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowPriceUnit');
      if (settingValue != null) {
        this.showUnitPricePurchase = settingValue;
      } else {
        this.showUnitPricePurchase = defaultValue;
      }
    }
    return this.showUnitPricePurchase;
  }

  get_hideCodeClientPurchase(defaultValue: boolean): boolean {
    if (typeof this.hideCodeSupplierClient === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'HideCodeClient');
      if (settingValue != null) {
        this.hideCodeSupplierClient = settingValue;
      } else {
        this.hideCodeSupplierClient = defaultValue;
      }
    }
    return this.hideCodeSupplierClient;
  }

  get_hideCodeSupplierPurchase(defaultValue: boolean): boolean {
    if (typeof this.hideCodeSupplierPurchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'HideCodeSupplier');
      if (settingValue != null) {
        this.hideCodeSupplierPurchase = settingValue;
      } else {
        this.hideCodeSupplierPurchase = defaultValue;
      }
    }
    return this.hideCodeSupplierPurchase;
  }

  get_showPriceUnitSale(defaultValue: boolean): boolean {
    if (typeof this.showUnitPriceSale === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowPriceUnit');
      if (settingValue != null) {
        this.showUnitPriceSale = settingValue;
      } else {
        this.showUnitPriceSale = defaultValue;
      }
    }
    return this.showUnitPriceSale;
  }

  // #endregion DetalheEncomendaCompra

  // #region DetalheEncomendaVenda
  get_showPackagingPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showPackagingPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowPackagingPanel');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowPackagingPanelSP');

      if (this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0) {
        this.showPackagingPanel_S = settingValueSP;
      } else if (settingValue != null) {
        this.showPackagingPanel_S = settingValue;
      } else {
        this.showPackagingPanel_S = defaultValue;
      }
    }
    return this.showPackagingPanel_S;
  }

  get_showDetailDeliveryPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showDetailDeliveryPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowDetailDeliveryPanel');
      if (settingValue != null) {
        this.showDetailDeliveryPanel_S = settingValue;
      } else {
        this.showDetailDeliveryPanel_S = defaultValue;
      }
    }
    return this.showDetailDeliveryPanel_S;
  }

  get_showTaxesPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showTaxesPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowTaxesPanel');
      if (settingValue != null) {
        this.showTaxesPanel_S = settingValue;
      } else {
        this.showTaxesPanel_S = defaultValue;
      }
    }
    return this.showTaxesPanel_S;
  }

  get_showNotesPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showNotesPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowNotesPanel');
      if (settingValue != null) {
        this.showNotesPanel_S = settingValue;
      } else {
        this.showNotesPanel_S = defaultValue;
      }
    }
    return this.showNotesPanel_S;
  }

  get_showAllowancesPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showAllowancesPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValueSP = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowAllowancesPanelSP');
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowAllowancesPanelNonSP');
      if (!this.authenticationService.session.company.ServiceProvider && settingValue != null) {
        this.showAllowancesPanel_S = settingValue;
      } else if (settingValueSP != null) {
        this.showAllowancesPanel_S = settingValueSP;
      } else {
        this.showAllowancesPanel_S = defaultValue;
      }
    }
    return this.showAllowancesPanel_S;
  }

  get_showDiscountsPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showDiscountsPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValueSP = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowDiscountsPanelSP');
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowDiscountsPanelNonSP');
      if (!this.authenticationService.session.company.ServiceProvider && settingValue != null) {
        this.showDiscountsPanel_S = settingValue;
      } else if (settingValueSP != null) {
        this.showDiscountsPanel_S = settingValueSP;
      } else {
        this.showDiscountsPanel_S = defaultValue;
      }
    }
    return this.showDiscountsPanel_S;
  }

  get_showReferencesPanel_S(defaultValue: boolean): boolean {
    if (typeof this.showReferencesPanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowReferencesPanel');
      if (settingValue != null) {
        this.showReferencesPanel_S = settingValue;
      } else {
        this.showReferencesPanel_S = defaultValue;
      }
    }
    return this.showReferencesPanel_S;
  }

  get_showStatePanel_S(defaultValue: boolean): boolean {
    if (typeof this.showStatePanel_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowStatePanel');
      if (settingValue != null) {
        this.showStatePanel_S = settingValue;
      } else {
        this.showStatePanel_S = defaultValue;
      }
    }
    return this.showStatePanel_S;
  }

  // campos panel Base
  get_showFamily_S(defaultValue: boolean): boolean {
    if (typeof this.showFamily_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowFamily');
      if (settingValue != null) {
        this.showFamily_S = settingValue;
      } else {
        this.showFamily_S = defaultValue;
      }
    }
    return this.showFamily_S;
  }

  // campos panel Quantidades e Preços
  get_showOrderedQuantityUOMCode_S(defaultValue: boolean): boolean {
    if (typeof this.showOrderedQuantityUOMCode_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowOrderedQuantityUOMCode');
      if (settingValue != null) {
        this.showOrderedQuantityUOMCode_S = settingValue;
      } else {
        this.showOrderedQuantityUOMCode_S = defaultValue;
      }
    }
    return this.showOrderedQuantityUOMCode_S;
  }

  get_showBonusQuantity_S(defaultValue: boolean): boolean {
    if (typeof this.showBonusQuantity_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowBonusQuantity');
      if (settingValue != null) {
        this.showBonusQuantity_S = settingValue;
      } else {
        this.showBonusQuantity_S = defaultValue;
      }
    }
    return this.showBonusQuantity_S;
  }

  get_showBonusQuantityUOM_S(defaultValue: boolean): boolean {
    if (typeof this.showBonusQuantityUOM_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowBonusQuantityUOM');
      if (settingValue != null) {
        this.showBonusQuantityUOM_S = settingValue;
      } else {
        this.showBonusQuantityUOM_S = defaultValue;
      }
    }
    return this.showBonusQuantityUOM_S;
  }
  get_hideCodeClient_SALE(defaultValue: boolean): boolean {
    if (typeof this.hideCodeClient_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'HideCodeClient');
      if (settingValue != null) {
        this.hideCodeClient_SALE = settingValue;
      } else {
        this.hideCodeClient_SALE = defaultValue;
      }
    }
    return this.hideCodeClient_SALE;
  }
  get_hideCodeSupplier_SALE(defaultValue: boolean): boolean {
    if (typeof this.hideCodeSupplier_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'HideCodeSupplier');
      if (settingValue != null) {
        this.hideCodeSupplier_SALE = settingValue;
      } else {
        this.hideCodeSupplier_SALE = defaultValue;
      }
    }
    return this.hideCodeSupplier_SALE;
  }

  get_showNetUnitPriceDetail_S(defaultValue: boolean): boolean {
    if (typeof this.showNetUnitPrice_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowNetUnitPrice');
      if (settingValue != null) {
        this.showNetUnitPrice_S = settingValue;
      } else {
        this.showNetUnitPrice_S = defaultValue;
      }
    }
    return this.showNetUnitPrice_S;
  }

  get_showTotalNetAmountDetail_S(defaultValue: boolean): boolean {
    if (typeof this.showTotalNetAmountDetail_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowTotalNetAmount');
      if (settingValue != null) {
        this.showTotalNetAmountDetail_S = settingValue;
      } else {
        this.showTotalNetAmountDetail_S = defaultValue;
      }
    }
    return this.showTotalNetAmountDetail_S;
  }

  get_showGrossUnitPrice_S(defaultValue: boolean): boolean {
    if (typeof this.showGrossUnitPrice_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowGrossUnitPrice');
      if (settingValue != null) {
        this.showGrossUnitPrice_S = settingValue;
      } else {
        this.showGrossUnitPrice_S = defaultValue;
      }
    }
    return this.showGrossUnitPrice_S;
  }

  get_showTotalGrossAmount_S(defaultValue: boolean): boolean {
    if (typeof this.showTotalGrossAmount_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowTotalGrossAmount');
      if (settingValue != null) {
        this.showTotalGrossAmount_S = settingValue;
      } else {
        this.showTotalGrossAmount_S = defaultValue;
      }
    }
    return this.showTotalGrossAmount_S;
  }

  // campos panel Embalagens
  get_showPackQuantityUOM_S(defaultValue: boolean): boolean {
    if (typeof this.showPackQuantityUOM_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowPackQuantityUOM');
      if (settingValue != null) {
        this.showPackQuantityUOM_S = settingValue;
      } else {
        this.showPackQuantityUOM_S = defaultValue;
      }
    }
    return this.showPackQuantityUOM_S;
  }

  get_showPackUnitsPerLayer_S(defaultValue: boolean): boolean {
    if (typeof this.showPackUnitsPerLayer_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowPackUnitsPerLayer');
      if (settingValue != null) {
        this.showPackUnitsPerLayer_S = settingValue;
      } else {
        this.showPackUnitsPerLayer_S = defaultValue;
      }
    }
    return this.showPackUnitsPerLayer_S;
  }

  get_getProductServiceType(defaultValue: boolean): boolean {
    if (typeof this.getProductServiceType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'GetProductServiceType');
      if (settingValue !== null) {
        this.getProductServiceType = settingValue;
      } else {
        this.getProductServiceType = defaultValue;
      }
    }
    return this.getProductServiceType;
  }

  get_ColumnInVisible_Details_S(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_Details_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'ColumVisible');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderSale', 'ColumVisibleSP');
      this.columnInVisible_Details_S = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_Details_S;
  }

  get_allowDeleteAll(defaultValue: boolean): boolean {
    if (typeof this.allowDeleteAll === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'AllowDeleteAll');
      if (settingValue != null) {
        this.allowDeleteAll = settingValue;
      } else {
        this.allowDeleteAll = defaultValue;
      }
    }
    return this.allowDeleteAll;
  }

  get_showReceptionReturnedQuantity(defaultValue: boolean): boolean {
    if (typeof this.showReceptionReturnedQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowReceptionReturnedQuantity');
      if (settingValue != null) {
        this.showReceptionReturnedQuantity = settingValue;
      } else {
        this.showReceptionReturnedQuantity = defaultValue;
      }
    }
    return this.showReceptionReturnedQuantity;
  }

  get_showReceptionReturnReasons(defaultValue: boolean): boolean {
    if (typeof this.showReturnReasons === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowReceptionReturnReasons');
      if (settingValue != null) {
        this.showReturnReasons = settingValue;
      } else {
        this.showReturnReasons = defaultValue;
      }
    }
    return this.showReturnReasons;
  }

  get_hideReceptionNumber(defaultValue: boolean): boolean {
    if (typeof this.hideReceptionNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideReceptionNumber');
      if (settingValue != null) {
        this.hideReceptionNumber = settingValue;
      } else {
        this.hideReceptionNumber = defaultValue;
      }
    }
    return this.hideReceptionNumber;
  }

  //para usar na lista das encomendas
  get_useAccountingConfigInList(defaultValue: boolean): boolean {
    if (typeof this.useAccountingConfigInList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'UseAccountingConfig');
      if (settingValue != null) {
        this.useAccountingConfigInList = settingValue;
      } else {
        this.useAccountingConfigInList = defaultValue;
      }
    }
    return this.useAccountingConfigInList;
  }

  get_receptionEvaluationDefaultValue(defaultValue: string): string {
    if (typeof this.receptionEvaluationDefaultValue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ReceptionEvaluationDefaultValue');
      if (settingValue != null) {
        this.receptionEvaluationDefaultValue = settingValue;
      } else {
        this.receptionEvaluationDefaultValue = defaultValue;
      }
    }
    return this.receptionEvaluationDefaultValue;
  }

  get_showReceptionReceivedQuantityDefaultValue(defaultValue: boolean): boolean {
    if (typeof this.showReceptionReceivedQuantityDefaultValue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'ShowReceptionReceivedQuantityDefaultValue');
      if (settingValue != null) {
        this.showReceptionReceivedQuantityDefaultValue = settingValue;
      } else {
        this.showReceptionReceivedQuantityDefaultValue = defaultValue;
      }
    }
    return this.showReceptionReceivedQuantityDefaultValue;
  }

  get_allowDuplicate(defaultValue: boolean): boolean {
    if (typeof this.allowDuplicate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'AllowDuplicate');
      if (settingValue != null) {
        this.allowDuplicate = settingValue;
      } else {
        this.allowDuplicate = defaultValue;
      }
    }
    return this.allowDuplicate;
  }

  get_hideAssignActionsToAnotherUser(defaultValue: boolean): boolean {
    if (typeof this.hideAssignActionsToAnotherUser === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideAssignActionsToAnotherUser');
      if (settingValue != null) {
        this.hideAssignActionsToAnotherUser = settingValue;
      } else {
        this.hideAssignActionsToAnotherUser = defaultValue;
      }
    }
    return this.hideAssignActionsToAnotherUser;
  }

  get_hideFilterModalButton(defaultValue: boolean): boolean {
    if (typeof this.hideFilterModalButton === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideFilterModalButton');
      if (settingValue != null) {
        this.hideFilterModalButton = settingValue;
      } else {
        this.hideFilterModalButton = defaultValue;
      }
    }
    return this.hideFilterModalButton;
  }

  getAllProductsInOrdersByOrderNumber(number: string, deliveryLocationID: number, transportID: number, deliveryNoteID: number, filterByPrductsAlreadyAdded: boolean): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllProductsInOrdersByOrderNumber', {
      number: number, deliveryLocationID: deliveryLocationID, transportID: transportID, deliveryNoteID: deliveryNoteID,
      filterByPrductsAlreadyAdded: filterByPrductsAlreadyAdded
    }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getOrderTransferOrders(POorderId: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderTransferOrders?orderId=' + POorderId).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /*
  get_showDiscountNonSP_S(defaultValue: boolean): boolean {
    if (typeof this.showDiscountNonSP_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowDiscountNonSP');
      if (settingValue != null) {
        this.showDiscountNonSP_S = settingValue;
      } else {
        this.showDiscountNonSP_S = defaultValue;
      }
    }
    return this.showDiscountNonSP_S;
  }

  get_showAllowancesNonSP_S(defaultValue: boolean): boolean {
    if (typeof this.showAllowancesNonSP_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowAllowancesNonSP');
      if (settingValue != null) {
        this.showAllowancesNonSP_S = settingValue;
      } else {
        this.showAllowancesNonSP_S = defaultValue;
      }
    }
    return this.showAllowancesNonSP_S;
  }
  */

  get_purchaseHideEanCodeSale(defaultValue: boolean): boolean {
    if (typeof this.purchaseHideEanCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'purchaseHideEanCode');
      if (settingValue != null) {
        this.purchaseHideEanCode = settingValue;
      } else {
        this.purchaseHideEanCode = defaultValue;
      }
    }
    return this.purchaseHideEanCode;
  }
  // #endregion DetalheEncomendaVenda

  // #region Logistic Orders - PREPARATIONS AND RECEPTIONS
  /**
   * Devolver excel das encomendas de logística
   * @param  {any} params Parâmetros da Datatable
  //  * @param  {string} preparationOrReception preparation ou reception
   * @param  {string} moreFilters filtros alem das colunas da datatable
   * @returns Observable
   */
  exportLogisticOrdersListToExcel(params: any, moreFilters: any = {}): Observable<any> {
    moreFilters.parameters = params;
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelLogisticOrderList', moreFilters);
  }

  /**
   * Devolver excel de uma encomenda de logística
   * @param  {number} id ID do documento
   * @param  {string} preparationOrReception preparation ou reception
   * @returns Observable
   */
  exportLogisticOrderToExcel(id: number, preparationOrReception: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelLogisticOrder?id=' + id + '&preparationOrReception=' + preparationOrReception);
  }

  /**
   * Devolver pdf de uma encomenda de logística
   * @param  {number} id ID do documento
   * @param  {string} preparationOrReception preparation ou reception
   * @returns Observable
   */
  exportLogisticOrderToPDF(id: number, preparationOrReception: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFLogisticOrder?id=' + id + '&preparationOrReception=' + preparationOrReception);
  }

  transnauticaClearDatatableParams() {
    this.datatableParametersTransnauticaOrders = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[3, 'desc']], 0);
    // this.filterTransnauticaOrders = {
    //   [LogisticOrderType.PREPARATION]:  new Object(),
    //   [LogisticOrderType.RECEPTION]: new Object()
    // };
  }

  /**
   * Validar entrega da encomenda
   * @param  {Order} order
   * @returns Observable
   */
  validateDelivery(order: Order): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.post(SERVICE_URL + this._controller + '/ValidateDelivery', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  /**
   * Confirmar agendamento da encomenda
   * @param  {Order} order
   * @returns Observable
   */
  confirmSchedule(order: Order): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(order));

    return this.http.post(SERVICE_URL + this._controller + '/ConfirmDelivery', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  // #endregion Logistic Orders - PREPARATIONS AND RECEPTIONS

  //#region SANITOP

  /**
   * Obtém os Códigos Centro Custo consoante os Parâmetros enviados
   * @param  {string} companyID - ID da Empresa
   * @param  {string} accountingCode - Código Contabilístico do Artigo selecionado
   * @param  {number} taxVAT - Taxa de IVA do Artigo selecionado
   * @param  {string} supplierInternalID - InternalID do Supplier selecionado
   */
  getCostCenterClassification(accountingCode: string, taxVAT: number, supplierInternalID: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + '/Sanitop/GetCostCenterClassification?accountingCode=' + accountingCode +
      '&taxVAT=' + taxVAT + '&supplierInternalID=' + supplierInternalID).pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obtém os Códigos de Reclassificação consoante os parâmetros enviados
   * @param  {string} companyID - ID da Empresa
   * @param  {string} accountingCode - Código Contabilístico do Artigo selecionado
   * @param  {number} taxVAT - Taxa de IVA do Artigo selecionado
   * @param  {number} costCenter - Código Centro de Custo
   * @param  {string} supplierInternalID - InternalID do Supplier selecionado
   */
  getReclassificationCodeClassification(accountingCode: string, taxVAT: number, costCenter: string, supplierInternalID: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + '/Sanitop/GetReclassificationCodeClassification?accountingCode=' + accountingCode +
      '&taxVAT=' + taxVAT + '&costCenter=' + costCenter + '&supplierInternalID=' + supplierInternalID).pipe(
        map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion
  get_hideDocumentDateHours(defaultValue: boolean): boolean {
    if (typeof this.hideDocumentDateHours === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideDocumentDateHours');
      if (settingValue != null) {
        this.hideDocumentDateHours = settingValue;
      } else {
        this.hideDocumentDateHours = defaultValue;
      }
    }
    return this.hideDocumentDateHours;
  }

  get_hideUnidentifiedProductIcon(defaultValue: boolean): boolean {
    if (typeof this.hideUnidentifiedProductIcon === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideUnidentifiedProductIcon');
      if (settingValue != null) {
        this.hideUnidentifiedProductIcon = settingValue;
      } else {
        this.hideUnidentifiedProductIcon = defaultValue;
      }
    }
    return this.hideUnidentifiedProductIcon;
  }

  get_hideCreationDatePurchase(defaultValue: boolean): boolean {
    if (typeof this.hideCreationDatePurchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'HideCreationDate');
      if (settingValue != null) {
        this.hideCreationDatePurchase = settingValue;
      } else {
        this.hideCreationDatePurchase = defaultValue;
      }
    }
    return this.hideCreationDatePurchase;
  }


  get_filterByLocation(defaultValue: boolean): boolean {
    if (typeof this.filterByLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'FilterByLocation');
      if (settingValue != null) {
        this.filterByLocation = settingValue;
      } else {
        this.filterByLocation = defaultValue;
      }
    }
    return this.filterByLocation;
  }

  get_showShippingDateSale(defaultValue: boolean): boolean {
    if (typeof this.showShippingDateSale === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowShippingDate');
      if (settingValue != null) {
        this.showShippingDateSale = settingValue;
      } else {
        this.showShippingDateSale = defaultValue;
      }
    }
    return this.showShippingDateSale;
  }

  get_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      }
      else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }

  get_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.redirectOnUpdate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.redirectOnUpdate = settingValue;
      } else {
        this.redirectOnUpdate = defaultValue;
      }
    }
    return this.redirectOnUpdate;
  }

  get_validateDetailsDate(defaultValue: boolean): boolean {
    if (typeof this.validateDetailsDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ValidateDetailsDate');
      if (settingValue != null) {
        this.validateDetailsDate = settingValue;
      } else {
        this.validateDetailsDate = defaultValue;
      }
    }
    return this.validateDetailsDate;
  }

  get_removeSpacers_SALE(defaultValue: boolean): boolean {
    if (typeof this.removeSpacers_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderSale', 'RemoveSpacers');
      if (settingValue != null) {
        this.removeSpacers_SALE = settingValue;
      } else {
        this.removeSpacers_SALE = defaultValue;
      }
    }
    return this.removeSpacers_SALE;
  }

  get_removeSpacers_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.removeSpacers_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'RemoveSpacers');
      if (settingValue != null) {
        this.removeSpacers_PURCHASE = settingValue;
      } else {
        this.removeSpacers_PURCHASE = defaultValue;
      }
    }
    return this.removeSpacers_PURCHASE;
  }

  get_IntegrateOrders_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.integrateOrders_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'IntegrateOrders');
      if (settingValue != null) {
        this.integrateOrders_PURCHASE = settingValue;
      } else {
        this.integrateOrders_PURCHASE = defaultValue;
      }
    }
    return this.integrateOrders_PURCHASE;
  }

  get_showDetailGroupByCC(defaultValue: boolean): boolean {
    if (typeof this.showDetailGroupByCC === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowDetailGroupByCC');
      if (settingValue != null) {
        this.showDetailGroupByCC = settingValue;
      } else {
        this.showDetailGroupByCC = defaultValue;
      }
    }
    return this.showDetailGroupByCC;
  }

  get_SendNotificationToSupplierCheckBox_PURCHASE(defaultValue: Array<number>): Array<number> {
    if (typeof this.sendNotificationToSupplierCheckBox === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'SendNotificationToSupplierCheckBox');
      if (settingValue != null) {
        this.sendNotificationToSupplierCheckBox = settingValue;
      } else {
        this.sendNotificationToSupplierCheckBox = defaultValue;
      }
    }
    return this.sendNotificationToSupplierCheckBox;
  }

  //#region notifySupplierByDefault
  private _notifySupplierByDefault: boolean;

  /** Notificar fornecedor por defeito */
  public get notifySupplierByDefault() {
    return this._notifySupplierByDefault = this.authenticationService.getBooleanSetting(false, this._notifySupplierByDefault, 'OrderPurchase', 'NotifySupplierByDefault');
  }

  //#endregion notifySupplierByDefault

  //#region profilesToEditLatestAcceptableDate
  private _profilesToEditLatestAcceptableDate: number[];

  /** Perfis que permitem alterar LatestAcceptableDate em qualquer estado */
  public get profilesToEditLatestAcceptableDate() {
    if (typeof this._profilesToEditLatestAcceptableDate === 'undefined') { // verificar se ainda nao tem valor
      return this._profilesToEditLatestAcceptableDate = this.authenticationService.getSettingPortal('OrderPurchase', 'ProfilesToEditLatestAcceptableDate');
    }
    else {
      return this._profilesToEditLatestAcceptableDate;
    }
  }
  //#endregion profilesToEditLatestAcceptableDate

  private _allowNegativeValues: boolean;
  get_allowNegativeValues_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this._allowNegativeValues === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'AllowNegativeValues');
      if (settingValue != null) {
        this._allowNegativeValues = settingValue;
      } else {
        this._allowNegativeValues = defaultValue;
      }
    }
    return this._allowNegativeValues;
  }

  get_showAccountingCode_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.showAccountingCode_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'ShowAccountingCode');
      if (settingValue != null) {
        this.showAccountingCode_PURCHASE = settingValue;
      } else {
        this.showAccountingCode_PURCHASE = defaultValue;
      }
    }
    return this.showAccountingCode_PURCHASE;
  }

  get_showAccountingCode_SALE(defaultValue: boolean): boolean {
    if (typeof this.showAccountingCode_SALE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailSale', 'ShowAccountingCode');
      if (settingValue != null) {
        this.showAccountingCode_SALE = settingValue;
      } else {
        this.showAccountingCode_SALE = defaultValue;
      }
    }
    return this.showAccountingCode_SALE;
  }

  get_allowInternalMessages_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.allowInternalMessages_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AllowInternalMessages');
      if (settingValue != null) {
        this.allowInternalMessages_PURCHASE = settingValue;
      } else {
        this.allowInternalMessages_PURCHASE = defaultValue;
      }
    }
    return this.allowInternalMessages_PURCHASE;
  }

  get_showCostCenterOnLineDetail_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.showCostCenterOnLineDetail_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowCostCenterOnLineDetail');
      if (settingValue != null) {
        this.showCostCenterOnLineDetail_PURCHASE = settingValue;
      } else {
        this.showCostCenterOnLineDetail_PURCHASE = defaultValue;
      }
    }
    return this.showCostCenterOnLineDetail_PURCHASE;
  }


  get_detailCostCenterName(defaultValue: boolean): boolean {
    if (typeof this.getDetailCostCenterName === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'GetDetailCostCenterName');
      if (settingValue != null) {
        this.getDetailCostCenterName = settingValue;
      } else {
        this.getDetailCostCenterName = defaultValue;
      }
    }
    return this.getDetailCostCenterName;
  }

  get_AlwaysShowNIF_PURCHASE(defaultValue: boolean): boolean {
    if (typeof this.alwaysShowNIF_PURCHASE === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'AlwaysShowNIF');
      if (settingValue != null) {
        this.alwaysShowNIF_PURCHASE = settingValue;
      } else {
        this.alwaysShowNIF_PURCHASE = defaultValue;
      }
    }
    return this.alwaysShowNIF_PURCHASE;
  }

  get_hideOrderSource(defaultValue: boolean): boolean {
    if (typeof this.hideOrderSource === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'HideOrderSource');
      if (settingValue != null) {
        this.hideOrderSource = settingValue;
      } else {
        this.hideOrderSource = defaultValue;
      }
    }
    return this.hideOrderSource;
  }

  get_allowQuantitiesSuperiorOrders(defaultValue: boolean): boolean {
    if (typeof this.allowQuantitiesSuperiorOrders === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'AllowQuantitiesSuperiorOrders');
      if (settingValue != null) {
        this.allowQuantitiesSuperiorOrders = settingValue;
      } else {
        this.allowQuantitiesSuperiorOrders = defaultValue;
      }
    }
    return this.allowQuantitiesSuperiorOrders;
  }

  get_showTaxArea(defaultValue: boolean): boolean {
    if (typeof this.showTaxArea === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ShowTaxArea');
      if (settingValue != null) {
        this.showTaxArea = settingValue;
      } else {
        this.showTaxArea = defaultValue;
      }
    }
    return this.showTaxArea;
  }

  private _disableProductDescription: boolean;
  get_disableProductDescription(defaultValue: boolean): boolean {
    if (typeof this._disableProductDescription === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableProductDescription');
      if (settingValue != null) {
        this._disableProductDescription = settingValue;
      } else {
        this._disableProductDescription = defaultValue;
      }
    }
    return this._disableProductDescription;
  }
  private _disableSupplierProductCode: boolean;
  get_disableSupplierProductCode(defaultValue: boolean): boolean {
    if (typeof this._disableSupplierProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'DisableSupplierProductCode');
      if (settingValue != null) {
        this._disableSupplierProductCode = settingValue;
      } else {
        this._disableSupplierProductCode = defaultValue;
      }
    }
    return this._disableSupplierProductCode;
  }

  private _typesDisableOrderedQuantityUOMCode: string = '';
  get_typesDisableOrderedQuantityUOMCode(): Array<string> {
    this._typesDisableOrderedQuantityUOMCode = this.authenticationService.getSettingPortal('OrderDetailPurchase', 'TypesDisableOrderedQuantityUOMCode');
    if (this._typesDisableOrderedQuantityUOMCode) {
      return this._typesDisableOrderedQuantityUOMCode.split(',');
    }
  }

  get_mandatoryNotesOnCloseDetail(defaultValue: boolean): boolean {
    if (typeof this.mandatoryNotesOnCloseDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrdersPurchases', 'MandatoryNotesOnCloseDetail');
      if (settingValue != null) {
        this.mandatoryNotesOnCloseDetail = settingValue;
      } else {
        this.mandatoryNotesOnCloseDetail = defaultValue;
      }
    }
    return this.mandatoryNotesOnCloseDetail;
  }
  
  //#region showProcess

  /** Mostrar campo de Processo no detalhe - DV: false */
  public get showProcess() {
    this._showProcess = this.authenticationService.getBooleanSetting(false, this._showProcess, 'OrderDetailPurchase', 'ShowProcess');
    return this._showProcess;
  }
  //#endregion showProcess

  //#region showFraction
  /** Mostrar campo de Fração no detalhe - DV: false */
  public get showFraction() {
    this._showFraction = this.authenticationService.getBooleanSetting(false, this._showFraction, 'OrderDetailPurchase', 'ShowFraction');
    return this._showFraction;
  }
  //#endregion showFraction

  //#region possibleInvoiceStatesOnSelect
  /**  */
  public get possibleInvoiceStatesOnSelect() {
    this._possibleInvoiceStatesOnSelect = this.authenticationService.getArrayNumberSetting([InvoiceDocumentState.STATE_SUBMETIDA], this._possibleInvoiceStatesOnSelect, 'OrderPurchase', 'PossibleInvoiceStatesOnSelect');
    return this._possibleInvoiceStatesOnSelect;
  }

  //#endregion possibleInvoiceTypesOnSelect

  //#region showRequestData_Purchase
  private _showRequestData_Purchase: boolean;

  /** Mostrar campos no detalhe da encomenda de requisição (campo de linha de requisiçao e campo que identifica de onde a encomenda foi criada) */
  public get showRequestData_Purchase() {
    this._showRequestData_Purchase = this.authenticationService.getBooleanSetting(false, this._showRequestData_Purchase, 'OrderDetailPurchase', 'ShowRequestData');
    return this._showRequestData_Purchase;
  }

  //#endregion showRequestData_Purchase

  //#region useCustomDocumentNumberFormat_Purchase
  private _useCustomDocumentNumberFormat_Purchase: boolean;

  /** Usar um formato para o numero de documento especificado no backend */
  public get useCustomDocumentNumberFormat_Purchase() {
    return this._useCustomDocumentNumberFormat_Purchase = this.authenticationService.getBooleanSetting(false, this._useCustomDocumentNumberFormat_Purchase, 'OrderPurchase', 'UseCustomDocumentNumberFormat');
  }

  //#endregion useCustomDocumentNumberFormat_Purchase

  //#region useCustomDocumentNumberFormat_Sale
  private _useCustomDocumentNumberFormat_Sale: boolean;

  /** Usar um formato para o numero de documento especificado no backend */
  public get useCustomDocumentNumberFormat_Sale() {
    return this._useCustomDocumentNumberFormat_Sale = this.authenticationService.getBooleanSetting(false, this._useCustomDocumentNumberFormat_Sale, 'OrderSale', 'UseCustomDocumentNumberFormat');
  }

  //#endregion useCustomDocumentNumberFormat_Sale

  //#region paymentConditionRequired
  private _paymentConditionRequired_Purchase: boolean;

  /** Mostrar campos no detalhe da encomenda de requisição (campo de linha de requisiçao e campo que identifica de onde a encomenda foi criada) */
  public get paymentConditionRequired() {
    return this._paymentConditionRequired_Purchase = this.authenticationService.getBooleanSetting(false, this._paymentConditionRequired_Purchase, 'OrderPurchase', 'PaymentConditionRequired');
  }
  //#endregion paymentConditionRequired

  //#region dontAllowToEditProducts
  private _dontAllowToEditProducts: boolean;

  /** Não permitir editar produtos nas requisições */
  public get dontAllowToEditProducts() {
    return this._dontAllowToEditProducts = this.authenticationService.getBooleanSetting(false, this._dontAllowToEditProducts, 'RequestsDetails', 'DontAllowToEditProducts');
  }

  //#endregion dontAllowToEditProducts

  //#region dontAllowToAddProducts
  private _dontAllowToAddProducts: boolean;

  /** Não permitir adicionar produtos nas requisições */
  public get dontAllowToAddProducts() {
    return this._dontAllowToAddProducts = this.authenticationService.getBooleanSetting(false, this._dontAllowToAddProducts, 'RequestsDetails', 'DontAllowToAddProducts');
  }

  //#endregion dontAllowToAddProducts
  //#region allowEditCostCenterInAnyState
  private _allowEditCostCenterInAnyState_RequestsDetails: boolean;

  /** Permitir editar o centro de custo em qualquer estado */
  public get allowEditCostCenterInAnyState_RequestsDetails() {
    return this._allowEditCostCenterInAnyState_RequestsDetails = this.authenticationService.getBooleanSetting(false, this._allowEditCostCenterInAnyState_RequestsDetails, 'RequestsDetails', 'AllowEditCostCenterInAnyState');
  }

  //#endregion allowEditCostCenterInAnyState

  //#region 
  private _allowEditOrderedQuantityInAnyState_RequestsDetails: boolean;

  /** Permitir editar o a qtd encomendada em qualquer estado */
  public get allowEditOrderedQuantityInAnyState_RequestsDetails() {
    return this._allowEditOrderedQuantityInAnyState_RequestsDetails = this.authenticationService.getBooleanSetting(false, this._allowEditOrderedQuantityInAnyState_RequestsDetails, 'RequestsDetails', 'AllowEditOrderedQuantityInAnyState');
  }

  //#region ShowAttachesIsPublic
  private _showAttachesIsPublic: boolean;

  /** Mostrar opção isPublic nos anexos das requisições */
  public get showAttachesIsPublic_Requests() {
    return this._showAttachesIsPublic = this.authenticationService.getBooleanSetting(false, this._showAttachesIsPublic, 'Requests', 'ShowAttachesIsPublic');
  }

  //#endregion ShowAttachesIsPublic

  //#endregion 

  //#region notesMaxLength
  private _maxNotesLength_RequestsDetails: boolean;

  /** Definir o tamanho maximo de caracteres nas observações nas linhas de requisição */
  public get maxNotesLength_RequestsDetails() {
    return this._maxNotesLength_RequestsDetails = this.authenticationService.getSettingPortal('RequestsDetails', 'MaxNotesDetailsLength');
  }

  //#endregion notesMaxLength

  //#region showInternalObservationsPanel_Order
  private _showInternalObservationsPanel_Order: boolean;

  /** Mostrar painel de observações internas nas encomendas */
  public get showInternalObservationsPanel_Order() {
    return this._showInternalObservationsPanel_Order = this.authenticationService.getBooleanSetting(false, this._showInternalObservationsPanel_Order, 'OrderPurchase', 'ShowInternalObservationsPanel');
  }

  //#endregion showInternalObservationsPanel_Order



  //#region notesMaxLength
  private _maxNotesLength_OrdersDetails: boolean;

  /** Definir o tamanho maximo de caracteres nas observações nas linhas da encomenda */
  public get maxNotesLength_OrdersDetails() {
    return this._maxNotesLength_OrdersDetails = this.authenticationService.getSettingPortal('OrderPurchase', 'MaxNotesDetailsLength');
  }

  //#endregion notesMaxLength

  //#region allowEditDocumentTypeOnSavedOrder
  private _allowEditDocumentTypeOnSavedOrder: boolean;

  /** Define se pode alterar o tipo de documento após salvar a encomenda */
  public get allowEditDocumentTypeOnSavedOrder() {
    return this._allowEditDocumentTypeOnSavedOrder = this.authenticationService.getSettingPortal('OrderPurchase', 'AllowEditDocumentTypeOnSavedOrder');
  }

  //#endregion allowEditDocumentTypeOnSavedOrder

  //#region mandatoryEarliestAcceptableDateOnLOrderineDetail
  private _mandatoryEarliestAcceptableDateOnOrderLineDetail: boolean;

  /** Data de entrega obrigatória nas linhas do detalhe da encomenda */
  public get mandatoryEarliestAcceptableDateOnOrderLineDetail() {
    return this._mandatoryEarliestAcceptableDateOnOrderLineDetail = this.authenticationService.getBooleanSetting(false, this._mandatoryEarliestAcceptableDateOnOrderLineDetail, 'OrderPurchase', 'MandatoryEarliestAcceptableDateOnLineDetail');
  }

  //#endregion mandatoryEarliestAcceptableDateOnOrderLineDetail

  //#region mandatoryEarliestAcceptableDateOnRequestLineDetail
  private _mandatoryEarliestAcceptableDateOnRequestLineDetail: boolean;

  /** Data de entrega obrigatória nas linhas do detalhe da encomenda */
  public get mandatoryEarliestAcceptableDateOnRequestLineDetail() {
    return this._mandatoryEarliestAcceptableDateOnRequestLineDetail = this.authenticationService.getBooleanSetting(false, this._mandatoryEarliestAcceptableDateOnRequestLineDetail, 'RequestsDetails', 'MandatoryEarliestAcceptableDateOnLineDetail');
  }

  //#endregion mandatoryEarliestAcceptableDateOnRequestLineDetail

  private _deliveryDateEditRoles: Array<number> = [];

  public get deliveryDateEditRoles() {
    return this._deliveryDateEditRoles = this.authenticationService.getSettingPortal('OrdersPurchases', 'DeliveryDateEditRoles');
  }

  private _deliveryDateEditStates: Array<number> = [];

  public get deliveryDateEditStates() {
    return this._deliveryDateEditStates = this.authenticationService.getSettingPortal('OrdersPurchases', 'DeliveryDateEditStates');
  }


  private _orderPurchaseSettings: OrderPurchaseSettings;
  public get OrderPurchaseSettings(): OrderPurchaseSettings {
    return this._orderPurchaseSettings ??= new OrderPurchaseSettings(this.authenticationService)
  }

  private _orderSaleSettings: OrderSaleSettings;
  public get OrderSaleSettings(): OrderSaleSettings {
    return this._orderSaleSettings ??= new OrderSaleSettings(this.authenticationService)
  }


  private _orderDetailPurchaseSettings: OrderDetailPurchaseSettings;
  public get OrderDetailPurchaseSettings(): OrderDetailPurchaseSettings {
    return this._orderDetailPurchaseSettings ??= new OrderDetailPurchaseSettings(this.authenticationService)
  }

  private _orderDetailSaleSettings: OrderDetailSaleSettings;
  public get OrderDetailSaleSettings(): OrderDetailSaleSettings {
    return this._orderDetailSaleSettings ??= new OrderDetailSaleSettings(this.authenticationService)
  }

  /** Ir buscar sempre a base de dados as moradas */
  private _alwaysGetLocations: boolean;
  public get alwaysGetLocations() {
    return this._alwaysGetLocations = this.authenticationService.getBooleanSetting(true, this._alwaysGetLocations, 'OrderPurchase', 'AlwaysGetLocations');
  }


}

//#region OrderSettings

/** Settings da encomenda gerais (purchase e sale) */
class OrderSettings extends Settings {
  constructor(authenticationService: AuthenticationService, context: string) {
    super(authenticationService, context);
  }

  /** Mostra painel de Totais */
  get showTotalsPanel(): boolean {
    return this.getSetting<boolean>('ShowTotalsPanel', false);
  }

  get disableCurrencyCode(): boolean {
    return this.getSetting<boolean>('DisableCurrencyCode', false);
  }

  get showReverseCharge(): boolean {
    return this.getSetting<boolean>('ShowReverseCharge', false);
  }

  /** Mostrar campo de condicoes de pagamento que usa a tabela DropDown */
  get showDropDownPaymentCondition(): boolean {
    return this.getSetting<boolean>('ShowDropDownPaymentCondition', true);
  }

  /** Mostrar campo de condicoes de pagamento que usa a sua propia tabela de PaymentConditions  */
  get showNewPaymentCondition(): boolean {
    return this.getSetting<boolean>('ShowNewPaymentCondition', false);
  }

  /** Indica se o campo de condicao de pagemento e obrigatorio  */
  get newPaymentConditionMandatory(): boolean {
    return this.getSetting<boolean>('NewPaymentConditionMandatory', false);
  }

  get showMeansOfPaymentDropdown(): boolean {
    return this.getSetting<boolean>('ShowMeansOfPaymentDropdown', false);
  }

  

}

/** Settings especificos para as encomendas no contexto Purchase */
export class OrderPurchaseSettings extends OrderSettings {
  constructor(authenticationService: AuthenticationService) {
    super(authenticationService, 'OrderPurchase');
  }

  get totalsPanelFieldsOrder(): string[] {
    return this.getSetting('TotalsPanelFieldsOrder', ['TotalGrossAmount', 'TotalDiscountAmount', 'TotalNetAmount', 'RetentionPercentage', 'RetentionIncidence', 'TotalRetentionAmount', 'TotalTaxAmount', 'TotalPayableAmount', 'CurrencyCode']);
  }
}


/** Settings especificos para as encomendas no contexto Sale */
export class OrderSaleSettings extends OrderSettings {
  constructor(authenticationService: AuthenticationService) {
    super(authenticationService, 'OrderSale');
  }

  get totalsPanelFieldsOrderSale(): string[] {
    return this.getSetting('TotalsPanelFieldsOrder', ['TotalGrossAmount', 'TotalDiscountAmount', 'TotalNetAmount', 'RetentionPercentage', 'RetentionIncidence', 'TotalRetentionAmount', 'TotalTaxAmount', 'TotalPayableAmount', 'CurrencyCode']);
  }
}

//#endregion OrderSettings

//#region OrderDetailSettings

/** Settings dos detalhes da encomenda gerais (purchase e sale) */
/**
 * @param  {AuthenticationService} authenticationService
 * @param  {string} context
 */
class OrderDetailSettings extends Settings {
  constructor(authenticationService: AuthenticationService, context: string) {
    super(authenticationService, context);
  }

  /** Desativar campo de Preco Unitario Liquido */
  get disableNetUnitPrice(): boolean {
    return this.getSetting<boolean>('DisableNetUnitPrice', false);
  }

  /** Desativar campo de Preco Unitario Liquido */
  get disableGrossUnitPrice(): boolean {
    return this.getSetting<boolean>('DisableGrossUnitPrice', false);
  }

  /** Desativar campo de Preco Unitario Liquido */
  get disableTotalNetAmount(): boolean {
    return this.getSetting<boolean>('DisableTotalNetAmount', false);
  }

  /** Desativar campo de Preco Unitario Liquido */
  get disableTotalGrossAmount(): boolean {
    return this.getSetting<boolean>('DisableTotalGrossAmount', false);
  }

  /** Mostrar Painel de preços */
  get showPanelPrices(): boolean {
    return this.getSetting<boolean>('ShowPanelPrices', true);
  }

  /** Label de total liquido no campo de total liquido do detalhe da encomenda */
  get totalNetAmountLabel(): string {
    return this.getSetting('TotalNetAmountLabel', 'TOTAL_VALUE');
  }

  get showAccountingVat(): Boolean {
    return this.getSetting<boolean>('ShowAccountingVat', false);
  }
  get showVatExemptReason(): Boolean {
    return this.getSetting<boolean>('ShowVatExemptReason', false);
  }

  /** Calcular totais a partir do preco unitario bruto */
  get calculatePricesFromGrossUnitPrice(): boolean {
    return this.getSetting<boolean>('CalculatePricesFromGrossUnitPrice', false);
  }
}

/** Settings especificos para os detalhes no contexto purchase */
export class OrderDetailPurchaseSettings extends OrderDetailSettings {
  constructor(authenticationService: AuthenticationService) {
    super(authenticationService, 'OrderDetailPurchase');
  }
}

/** Settings especificos para os detalhes no contexto purchase */
export class OrderDetailSaleSettings extends OrderDetailSettings {
  constructor(authenticationService: AuthenticationService) {
    super(authenticationService, 'OrderDetailSale');
  }
}

//#endregion OrderDetailSettings