import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ALIAS, LANGUAGE } from 'src/app/constants/global';
import { Role } from 'src/app/models/role';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var Functions: any;

@Component({
  templateUrl: './detailWorkflow-modal.html'
})
export class DetailWorkflowModalcomponent implements OnInit, AfterViewInit{

  destroy$: Subject<boolean> = new Subject<boolean>();

  form: FormGroup;
  data: any;
  usersList: any[] = [];
  rolesList: Role[] = [];

  statesList: Array<any> = new Array<any>();
  filteredFromStatesList: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  filteredToStatesList: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  costCentersList: Array<any> = new Array<any>();
  filteredCostCentersList: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  showCreationPopup: boolean = true;

  constructor(
    private _formBuilder: FormBuilder, private translateService: TranslateService, private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<DetailWorkflowModalcomponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.data = data;
  }
  ngOnInit(): void {
    if (this.data) {

      this.statesList = this.data.statesList;
      this.costCentersList = this.data.costCentersList;
      this.rolesList = this.data.rolesList;
      this.usersList = this.data.usersList;

      if (this.data.activeFiltersSteps)
      {
        //this.form = this.data.activeFiltersSteps;
        this.form = this._formBuilder.group({
          FromStateID: this.data.activeFiltersSteps.FromStateID,
          ToStateID: this.data.activeFiltersSteps.ToStateID,
          ActionVerb: this.data.activeFiltersSteps.ActionVerb,
          MinimumLimit: this.data.activeFiltersSteps.MinimumLimit,
          MaximumLimit: this.data.activeFiltersSteps.MaximumLimit,
          CostCenterID: this.data.activeFiltersSteps.CostCenterID,
          FromStateIDFilter: null,
          ToStateIDFilter: null,
          CostCenterIDFilter: null,
          rolesAproversSelected: this._formBuilder.array(this.data.activeFiltersSteps.rolesAproversSelected),
          rolesCreatorsSelected: this._formBuilder.array(this.data.activeFiltersSteps.rolesCreatorsSelected),
          usersAproversSelected: this._formBuilder.array(this.data.activeFiltersSteps.usersAproversSelected),
          usersCreatorsSelected: this._formBuilder.array(this.data.activeFiltersSteps.usersCreatorsSelected)
        });
      }
      else
      {
        this.form = this._formBuilder.group({
          FromStateID: null,
          ToStateID: null,
          ActionVerb: null,
          MinimumLimit: null,
          MaximumLimit: null,
          CostCenterID: null,
          FromStateIDFilter: null,
          ToStateIDFilter: null,
          CostCenterIDFilter: null,
          rolesAproversSelected: this._formBuilder.array([]),
          rolesCreatorsSelected: this._formBuilder.array([]),
          usersAproversSelected: this._formBuilder.array([]),
          usersCreatorsSelected: this._formBuilder.array([])
        });
      }

    }

    this.get_showCreationPopup();

    this.filteredFromStatesList.next(this.statesList.slice());
    this.filteredToStatesList.next(this.statesList.slice());
    this.filteredCostCentersList.next(this.costCentersList.slice());

    this.form.get('FromStateIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
    this.filterArrays(val, this.statesList, this.filteredFromStatesList));

    this.form.get('ToStateIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
    this.filterArrays(val, this.statesList, this.filteredToStatesList));

    this.form.get('CostCenterIDFilter').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) =>
    this.filterArrays(val, this.costCentersList, this.filteredCostCentersList));
  }


  ngAfterViewInit() {
    this.initRolesAproversTable();
    this.initUsersAproversTable();
    this.initRolesCreatorsTable();
    this.initUsersCreatorsTable();
  }

  /**
   * Filtrar selects
   * @param  {string} search - Pesquisa do valor introduzido pelo utilizador
   * @param  {Array<any>} list - Lista total do select (onde vai ser pesquisado o valor)
   * @param  {ReplaySubject<any[]>} filteredList - Select que vai ser "devolvido" já filtrado
   */
  private filterArrays(search: string, list: Array<any>, filteredList: ReplaySubject<any[]>) {
    if (!list) {
      return;
    }
    // Verifica se a keyword está preenchida
    if (!search) {
      filteredList.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filtra os itens
    filteredList.next(
      list.filter(item => item.Name && item.Name.toLowerCase().includes(search))
    );
  }

  /**
   * Carregar tabelas das roles dos criadores da transição
   */
  initRolesCreatorsTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableCreatorsRoles-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) {
              return buttons(data);
            }}
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        let tabela = Functions.datatablesWithDataSet('#tabelaCreatorsRoles', that.rolesList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        tabela.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableCreatorsRoles-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.form.controls.rolesCreatorsSelected.value.length > 0) {
              that.form.controls.rolesCreatorsSelected.value.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.SelectCreatorsRoles(this.id);
            });
          }
          tabela.on('init', function () {
            // Necessário para o width do header da tabela não continuar encolhido depois de descolapsar
            var elementRoles: any = document.querySelector("#collPnPopup2 .dataTables_scrollHeadInner");
            elementRoles.style.width='100%';
            var elementRoles: any = document.querySelector("#collPnPopup2 .dataTables_scrollHeadInner .table");
            elementRoles.style.width='100%';
          });
        });
      });
  }

  /**
   * Carregar tabelas dos users dos criadores da transição
   */
  initUsersCreatorsTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableCreatorsUsers-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) {
              return buttons(data);
            }}
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        let tabela = Functions.datatablesWithDataSet('#tabelaCreatorsUsers', that.usersList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        tabela.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableCreatorsUsers-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.form.controls.usersCreatorsSelected.value.length > 0) {
              that.form.controls.usersCreatorsSelected.value.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.SelectCreatorsUsers(this.id);
            });
          }
          
          tabela.on('init', function () {
            // Necessário para o width do header da tabela não continuar encolhido depois de descolapsar
            var elementUsers: any = document.querySelector("#collPnPopup3 .dataTables_scrollHeadInner");
            elementUsers.style.width='100%';
            var elementUsers: any = document.querySelector("#collPnPopup3 .dataTables_scrollHeadInner .table");
            elementUsers.style.width='100%';
          });
        });
      });
  }

  /**
   * Carregar tabelas das roles dos aprovadores da transição
   */
  initRolesAproversTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableAproversRoles-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) {
              return buttons(data);
            }}
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        let tabela = Functions.datatablesWithDataSet('#tableAproversRoles', that.rolesList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        tabela.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableAproversRoles-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.form.controls.rolesAproversSelected.value.length > 0) {
              that.form.controls.rolesAproversSelected.value.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.SelectAproversRoles(this.id);
            });
          }

        });
        tabela.on('init', function () {
          // Necessário para o width do header da tabela não continuar encolhido depois de descolapsar
          var elementRoles: any = document.querySelector("#collPnPopup5 .dataTables_scrollHeadInner");
          elementRoles.style.width='100%';
          var elementRoles: any = document.querySelector("#collPnPopup5 .dataTables_scrollHeadInner .table");
          elementRoles.style.width='100%';
        });

      });
  }



  /**
   * Carregar tabelas dos users dos aprovadores da transição
   */
  initUsersAproversTable() {
    this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'UNIDENTIFIED_PRODUCT'])
      .toPromise().then((response: string[]) => {
        let that = this;

        let buttons = function (id: string): string {
          let chooseThis = '<div class="md-checkbox" style="height: 17px"><input id="u' + id + '" type="checkbox" class="tableAproversUsers-choose"><label for="u' + id + '"></label></div>';
          return chooseThis;
        };

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        },
          aoColumns = [
            { 'data': 'Name', 'class': 'verticalMiddle' },
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) {
              return buttons(data);
            }}
          ],
          columnDefs = [
            { 'targets': [-1], 'orderable': false },
            { 'targets': [-1], 'width': '110px' } // colocar a ultima coluna mais pequena
          ];

        let tabela = Functions.datatablesWithDataSet('#tableAproversUsers', that.usersList, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 0, false, false, true, false, 'asc'); // ultimo campo para alterar DOM

        tabela.on('draw', function () {
          let btnsChoose = document.getElementsByClassName('tableAproversUsers-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            let id = chooseThis.id;
            // selecionar checkbox que estiverem na lista
            if (that.form.controls.usersAproversSelected.value.length > 0) {
              that.form.controls.usersAproversSelected.value.forEach((idLista: any) => {
                let idTabela = 'u' + idLista;
                if (idTabela === id) {
                  let element = <HTMLInputElement>document.getElementById(id);
                  element.checked = true;
                }
              });
            }
            // adicionar à checkbox o evento click
            chooseThis.addEventListener('click', function (ev: any) {
              that.SelectAproversUsers(this.id);
            });
          }

          tabela.on('init', function () {
            // Necessário para o width do header da tabela não continuar encolhido depois de descolapsar
            var elementUsers: any = document.querySelector("#collPnPopup6 .dataTables_scrollHeadInner");
            elementUsers.style.width='100%';
            var elementUsers: any = document.querySelector("#collPnPopup6 .dataTables_scrollHeadInner .table");
            elementUsers.style.width='100%';
          });
        });
      });
  }

  /**
   * Selecionar ou desselecionar checkboxs dos users aprovadores
   * @param  {string} idRecebido - id a ser selecionado/desselecionado
   */
  SelectAproversUsers(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.form.controls.usersAproversSelected.value.length > 0) {
      let idEncontrado = this.form.controls.usersAproversSelected.value.find((r: any) => r === parseInt(id, null));
      let index = this.form.controls.usersAproversSelected.value.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.form.controls.usersAproversSelected.value.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.form.controls.usersAproversSelected.value.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.form.controls.usersAproversSelected.value.push(parseInt(id, null));
      }
    }
  }

  /**
   * Selecionar ou desselecionar checkboxs das roles dos aprovadores
   * @param  {string} idRecebido - id a ser selecionado/desselecionado
   */
  SelectAproversRoles(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.form.controls.rolesAproversSelected.value.length > 0) {
      let idEncontrado = this.form.controls.rolesAproversSelected.value.find((r: any) => r === parseInt(id, null));
      let index = this.form.controls.rolesAproversSelected.value.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.form.controls.rolesAproversSelected.value.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.form.controls.rolesAproversSelected.value.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.form.controls.rolesAproversSelected.value.push(parseInt(id, null));
      }
    }
  }

  /**
   * Selecionar ou desselecionar checkboxs dos users criadores
   * @param  {string} idRecebido - id a ser selecionado/desselecionado
   */
  SelectCreatorsUsers(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.form.controls.usersCreatorsSelected.value.length > 0) {
      let idEncontrado = this.form.controls.usersCreatorsSelected.value.find((r: any) => r === parseInt(id, null));
      let index = this.form.controls.usersCreatorsSelected.value.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.form.controls.usersCreatorsSelected.value.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.form.controls.usersCreatorsSelected.value.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.form.controls.usersCreatorsSelected.value.push(parseInt(id, null));
      }
    }
  }

  /**
   * Selecionar ou desselecionar checkboxs dos roles dos criadores
   * @param  {string} idRecebido - id a ser selecionado/desselecionado
   */
  SelectCreatorsRoles(idRecebido: any) {
    // verificar 1º se o id em que clicou ficou com checked a true, caso contrário é porque "des-selecionou"
    let element = <HTMLInputElement>document.getElementById(idRecebido);
    // retirar "u" do id
    let id = idRecebido.split('u').join('');

    if (this.form.controls.rolesCreatorsSelected.value.length > 0) {
      let idEncontrado = this.form.controls.rolesCreatorsSelected.value.find((r: any) => r === parseInt(id, null));
      let index = this.form.controls.rolesCreatorsSelected.value.findIndex((r: any) => r === parseInt(id, null));

      if (element.checked && !idEncontrado) {
        this.form.controls.rolesCreatorsSelected.value.push(parseInt(id, null));
      }
      if (!element.checked && idEncontrado) {
        this.form.controls.rolesCreatorsSelected.value.splice(index, 1);
      }
    } else {
      if (element.checked) {
        this.form.controls.rolesCreatorsSelected.value.push(parseInt(id, null));
      }
    }
  }

  /**
   * Receber a setting que define a existerncia da tabela dos criadores/criação
   */
  get_showCreationPopup(){
    let settingValue = this.authenticationService.getSettingPortal('ContractWorkflow', 'ShowCreationPopup');
    if (settingValue != null) {
      this.showCreationPopup = settingValue;
    }
  }
}
